import React from "react";
import { RouteComponentProps } from "@reach/router";

import { ResendSharingInvitation } from "domains/liquidity";

interface PropsResendSharingLoggedInPage extends RouteComponentProps {}

const ResendSharingLoggedInPage: React.FC<PropsResendSharingLoggedInPage> = (): React.ReactElement | null => {
  return <ResendSharingInvitation />;
};

export default ResendSharingLoggedInPage;
