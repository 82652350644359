import React from "react";

export const lazyLoadAddClient = React.lazy(() =>
  import("pages/main/Dashboard/AddClientPage/AddClientPage")
);

export const lazyLoadClients = React.lazy(() =>
  import("pages/main/Dashboard/ClientsPage/ClientsPage")
);

export const lazyLoadClient = React.lazy(() =>
  import("pages/main/Dashboard/ClientPage/ClientPage")
);

export const lazyLoadNewLiquidity = React.lazy(() =>
  import("pages/main/Dashboard/NewLiquidityPage/NewLiquidityPage")
);
