import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/react";
import { Row, Col, Select as AntSelect } from "antd";

import { Options, Spinner, Select } from "common/lib/components";

import {
  getClients,
  getLRCount,
  changeClientsRequestParams,
  getAlerts,
} from "domains/clients/store/clients.actions";
import {
  Client,
  TypeClientsParamSortDirection,
  TypeGetClientsParams,
  TypeClientsParamSortField,
  TypeGettingClientsOptionData,
} from "domains/clients/shared/types";
import { ClientCard } from "domains/clients/components";
import {
  useClientListData,
  useLiquidityRequestCountsData,
  useClientsAlertsData,
  useClientsRequestParamsData,
} from "domains/clients/hooks";
import { GettingClientsOptionsMap } from "domains/clients/shared/constants";

interface PropsClientsList {
  isBlockedRoute: boolean;
}

const { Option } = AntSelect;

const ClientsList: React.FC<PropsClientsList> = (props): React.ReactElement | null => {
  const dispatch = useDispatch();

  const { clientsLoading, list } = useClientListData();
  const { params } = useClientsRequestParamsData();
  const { items: liquidityRequestCounts } = useLiquidityRequestCountsData();
  const { items: alertsMap } = useClientsAlertsData();

  const { isBlockedRoute } = props;

  // first load of the page request
  // cashing for all sortFields except last_viewed_date
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isBlockedRoute || (list.ids.length > 0 && params["sort[field]"] !== "last_viewed_date")) {
      return;
    }
    dispatch(getClients(params));
  }, []);

  useEffect(() => {
    if (isBlockedRoute) {
      return;
    }
    if (list.ids.length) {
      dispatch(getAlerts());
      dispatch(getLRCount({ accounts: list.ids }));
    }
  }, [dispatch, list.ids.length, isBlockedRoute]);

  const handleSelectChange = (value: string) => {
    const valueData = value.split("|");
    const sortField: TypeClientsParamSortField = valueData[0] as TypeClientsParamSortField;
    const sortDirection: TypeClientsParamSortDirection = valueData[1] as TypeClientsParamSortDirection;

    const newParams: TypeGetClientsParams = {
      ...params,
      "sort[field]": sortField,
      "sort[direction]": sortDirection,
    };

    dispatch(changeClientsRequestParams(newParams));
    dispatch(getClients(newParams));
  };

  const isClientsExist = Boolean(list.ids.length);
  const selectedOption = `${params["sort[field]"]}|${params["sort[direction]"]}`;

  return (
    <Fragment>
      <div className="ben-panel-header">
        <Row gutter={30}>
          <Col xs={10} sm={12} md={16}>
            <h3>
              <Trans id="dashboard.sub.title">Clients</Trans>
            </h3>
          </Col>
          {list.ids.length > 1 && (
            <Col xs={14} sm={12} md={8}>
              <Select
                showSearch={true}
                optionFilterProp="children"
                filterOption={Options.filterByStartsWith}
                defaultValue={selectedOption}
                style={{ width: "100%" }}
                onChange={handleSelectChange}
                disabled={clientsLoading}
              >
                {Object.values(GettingClientsOptionsMap).map(
                  (option: TypeGettingClientsOptionData) => {
                    return (
                      <Option key={option.value} value={option.value}>
                        {option.title}
                      </Option>
                    );
                  }
                )}
              </Select>
            </Col>
          )}
        </Row>
      </div>
      {clientsLoading ? (
        <Spinner />
      ) : (
        isClientsExist && (
          <Row gutter={30}>
            {list.ids.map((id: string, i) => {
              const client: Client = list.entitiesMap[id];
              return (
                <Col xs={24} sm={12} md={8} key={client.accountName + i}>
                  <ClientCard
                    account={client}
                    countLR={liquidityRequestCounts[client.accountId]}
                    hasAlert={alertsMap[client.accountId] || false}
                  />
                </Col>
              );
            })}
          </Row>
        )
      )}
    </Fragment>
  );
};

export default ClientsList;
