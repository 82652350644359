import React from "react";
import { Trans } from "@lingui/macro";

export const SessionModalContent = (
  <Trans id="sessionModal.content">
    Your session is about to expire due to inactivity.
    <br /> Select Continue if you would like to keep working.
  </Trans>
);
export const SessionModalTimerText = (
  <Trans id="sessionModal.timer.text">You will be logged out in</Trans>
);
export const SessionModalTimerTime = <Trans id="sessionModal.timer.time">seconds</Trans>;
