import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";

import { BenCardContent } from "common/lib/components";

import { TypeLiquidityStatusCode } from "domains/liquidity/shared/types";

import { getTransactionDocuments } from "domains/document/store/document.actions";
import { useTransactionDocumentsData } from "domains/document/hooks";
import { TransactionDocumentsDataProps } from "domains/document/shared/types";
import { TransactionDocuments } from "domains/document/components";

import { PropsTransactionSection } from "./types";

const Title = <Trans id="transactionSection.title">Transaction</Trans>;

const TransactionSection: React.FC<PropsTransactionSection> = (
  props
): React.ReactElement | null => {
  const dispatch = useDispatch();

  const liquidityStatus: TypeLiquidityStatusCode = props.liquidity
    ? props.liquidity.status
    : "in_proposal";

  useEffect(() => {
    if (!props.accountId || !props.requestId) {
      return;
    }
    dispatch(getTransactionDocuments({ accountId: props.accountId, requestId: props.requestId }));
  }, [dispatch, props.accountId, props.requestId]);

  const {
    loading: transactionDocumentsLoading,
    standardDocuments,
    otherDocuments,
    otherAppianDocuments,
  }: TransactionDocumentsDataProps = useTransactionDocumentsData();

  const { accountId, liquidityLoading, liquidity } = props;

  return (
    <BenCardContent title={Title} isLoading={liquidityLoading || transactionDocumentsLoading}>
      {liquidity ? (
        <TransactionDocuments
          accountId={accountId}
          liquidityStatus={liquidityStatus}
          liquidityRequestId={liquidity.id}
          standardDocuments={standardDocuments}
          otherDocuments={otherDocuments}
          otherAppianDocuments={otherAppianDocuments}
        />
      ) : null}
    </BenCardContent>
  );
};

export default TransactionSection;
