import { notification } from "antd";
import { ReactNode } from "react";

import localeService from "common/shared/services/locale/locale.service";
import MessageConfig from "common/shared/interfaces/message-config";

export class MessageService {
  readonly duration = 15;
  public success(
    content: string | MessageConfig | ReactNode,
    duration = this.duration,
    title?: string | ReactNode
  ) {
    const { description, message } = this.translateMessage(
      content,
      title || "toaster.success.title"
    );

    notification.success({ message, description, duration });
  }

  public info(content: string | ReactNode, duration = this.duration, title?: string | ReactNode) {
    const { description, message } = this.translateMessage(content, title || "toaster.info.title");
    notification.info({ message, description, duration });
  }
  public error(content: string | ReactNode, duration = this.duration, title?: string | ReactNode) {
    const { description, message } = this.translateMessage(content, title || "toaster.error.title");
    notification.error({ message, description, duration });
  }
  public warn(content: string | ReactNode, duration = this.duration, title?: string | ReactNode) {
    const { description, message } = this.translateMessage(content, title || "toaster.warn.title");
    notification.warn({ message, description, duration });
  }

  private translateMessage(
    description: string | MessageConfig | ReactNode,
    message: string | ReactNode
  ) {
    if (typeof description === "string") {
      description = localeService.i18n._(description);
    }

    if (description instanceof MessageConfig) {
      description = localeService.i18n._(description.id, description.getParamsForTrans());
    }

    if (typeof message === "string") {
      message = localeService.i18n._(message);
    }
    return { description, message };
  }
}

const messageService = new MessageService();
export default messageService;
