import { useSelector } from "react-redux";
import { portalEnvConstants } from "common/shared";

interface UserSettingsDataProps {
  locale: string;
  lang: string | null;
  timezone: string;
  isCompletedUserRequest: boolean;
}

function useUserSettingsData(): UserSettingsDataProps {
  const result = useSelector(
    (state: any): UserSettingsDataProps => {
      const locale = (state.clients.user && state.clients.user.locale) || null;
      const timezone = (state.clients.user && state.clients.user.timeZoneAbbreviation) || null;

      return {
        locale,
        lang: portalEnvConstants.getLanguageFromLocale(locale),
        timezone,
        isCompletedUserRequest: state.clients.isCompletedUserRequest,
      };
    }
  );

  return result;
}

export default useUserSettingsData;
