import React from "react";
import { Trans } from "@lingui/macro";

import { PropsSigninValidationErrorMessage } from "./types";

const SigninValidationErrorMessage: React.FC<PropsSigninValidationErrorMessage> = (
  props
): React.ReactElement | null => {
  const { isUserLocked, isHasError } = props;

  if (isUserLocked) {
    return (
      <p className="ben-error-text">
        <Trans id="signin.validationError.user.locked">
          You have been temporarily locked out. Please use the Contact Us options to get in touch
          with us.
        </Trans>
      </p>
    );
  }

  if (isHasError) {
    return (
      <p className="ben-error-text">
        <Trans id="signin.validationError">The Username or Password is incorrect.</Trans>
      </p>
    );
  }

  return null;
};

export default SigninValidationErrorMessage;
