import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/react";

import { Collapse, Button } from "antd";

import { DomUtils } from "common/shared/utils";
import { useForm } from "common/shared-hooks";
import { sessionService } from "common/shared";
import PasswordChanges from "common/shared/interfaces/password-changes";
import downIcon from "common/assets/images/icons/collapse-arrow-down.svg";
import upIcon from "common/assets/images/icons/collapse-arrow-up.svg";

import { rolesService, PERMISSION_TYPES } from "common/shared/services/roles/roles.service";

import { changePassword } from "domains/clients/store/clients.actions";
import { SecurityInfo, ClientControls } from "domains/clients/components";
import {
  ChangePasswordForm,
  ChangePasswordFormSchema,
  changePasswordValidationSchema,
  changePasswordValidationSchemaDeps,
  impersonateChangePasswordDisabledConfig,
} from "domains/clients/forms";
import { useLoadingUpdatePassData } from "domains/clients/hooks";

interface PropsProfileSecuritySection {
  shouldTurnOffEditMode: boolean;
  handleOnChangeMode: (isEditMode: boolean) => void;
}

const securityTitle = <Trans id="profile.security.view.title" />;
const { Panel } = Collapse;
const currentCollapseKey = "1";

const ProfileSecuritySection = (props: PropsProfileSecuritySection): React.ReactElement => {
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [isEditMode, setEditMode] = useState(false);
  const panelRef = useRef<any>();

  const { shouldTurnOffEditMode, handleOnChangeMode } = props;
  const { loading } = useLoadingUpdatePassData();

  // Possibility from the parent to change edit mode
  useEffect(() => {
    if (isEditMode && shouldTurnOffEditMode) {
      setEditMode(false);
    }
  }, [isEditMode, shouldTurnOffEditMode]);

  const formDisableConfig =
    (sessionService.isAppian && impersonateChangePasswordDisabledConfig) || {};
  const formSchema = new ChangePasswordFormSchema(formDisableConfig);
  const formConfig = useForm(
    formSchema,
    changePasswordValidationSchema,
    submit,
    changePasswordValidationSchemaDeps
  );

  const toggleEditMode = useCallback(() => {
    handleOnChangeMode(!isEditMode);
    setEditMode((isEdit: boolean) => !isEdit);
    formConfig.resetForm();
  }, [handleOnChangeMode, isEditMode, formConfig]);

  function submit(data: PasswordChanges) {
    if (sessionService.isAppian) {
      return;
    }

    dispatch(changePassword(data));
  }

  function onSubmit() {
    if (!isDisabled()) {
      formConfig.handleOnSubmit();
    }
  }

  const isDisabled = (): boolean => {
    return (formConfig.isDirty && !formConfig.isValid) || !formConfig.isDirty || loading;
  };

  const onEditClick = () => {
    toggleEditMode();
    setActiveKey([currentCollapseKey]);
  };

  const toggleCollapse = useCallback(() => {
    setActiveKey(activeKey.length === 0 ? [currentCollapseKey] : []);
  }, [activeKey.length]);

  const handleCollapseClick = useCallback(() => {
    if (activeKey.length) {
      DomUtils.scrollIntoView(panelRef.current);
    }

    if (isEditMode) {
      setEditMode(false);
    }

    toggleCollapse();
  }, [toggleCollapse, activeKey.length, isEditMode]);

  return (
    <div className="ben-panel ben-pb-0 ben-mb-4" ref={panelRef}>
      <div className="ben-client-details">
        <Collapse expandIconPosition="right" activeKey={activeKey}>
          <Panel
            header={<span className="ben-header">{securityTitle}</span>}
            extra={
              rolesService.hasPermission(PERMISSION_TYPES.ChangePassword) && (
                <div className="ben-d-sm-none">
                  {/* TODO: button is hidden by class - "ben-d-sm-none" (for mobile state), also need to remove DIV */}
                  <ClientControls
                    isDisabled={isDisabled()}
                    isDisabledCancel={loading}
                    isEdit={isEditMode}
                    btnId="SecuritySection"
                    handleClickSave={onSubmit}
                    handleClickCancel={toggleEditMode}
                    handleClickEdit={onEditClick}
                  />
                </div>
              )
            }
            showArrow={false}
            key={currentCollapseKey}
          >
            <div style={{ paddingBottom: 21 }}>
              {isEditMode ? (
                <ChangePasswordForm
                  handlePassEditMode={() => setEditMode(false)}
                  config={formConfig}
                  loading={loading}
                />
              ) : (
                <SecurityInfo />
              )}
            </div>
          </Panel>
        </Collapse>
        <Button
          id="benSecuritySectionBtnCollapse"
          size="small"
          className="collapse"
          type="primary"
          shape="circle"
          onClick={handleCollapseClick}
        >
          <img src={activeKey.length === 0 ? downIcon : upIcon} alt="Logo" />
        </Button>
      </div>
    </div>
  );
};

export default ProfileSecuritySection;
