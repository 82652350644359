import React from "react";
import { Col, Row } from "antd";

import { IconCustom } from "common/lib/components";

import { UploadingText } from "domains/document/components";

import { PropsUploadingDocumentRow } from "./types";

const UploadingDocumentRow: React.FC<PropsUploadingDocumentRow> = (
  props
): React.ReactElement | null => {
  const {
    fileName,
    documentName,
    isShowUploadingName,
    uploadProgress,
    isCancelEnabled,
    handleCancelUpload,
  } = props;
  if (!fileName) {
    return null;
  }

  if (!isShowUploadingName) {
    return (
      <UploadingText
        uploadProgress={uploadProgress}
        isCancelEnabled={isCancelEnabled}
        handleCancelUpload={handleCancelUpload}
      />
    );
  }

  return (
    <Row type="flex" className="uploading-document-row">
      {!documentName ? (
        <Col md={1} xs={2}>
          <IconCustom type="pending" style={{ fontSize: 20 }} />
        </Col>
      ) : null}

      <Col md={9} xs={9} style={{ paddingLeft: "5px" }} className="ben-document-title-wrapper">
        <strong className="ben-document-title">{documentName || fileName}</strong>
      </Col>
      <Col md={14} xs={13} className="ben-d-flex ben-justify-content-end">
        <UploadingText
          uploadProgress={uploadProgress}
          isCancelEnabled={isCancelEnabled}
          handleCancelUpload={handleCancelUpload}
        />
      </Col>
    </Row>
  );
};

export default UploadingDocumentRow;
