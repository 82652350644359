import { Error500Data } from "common/shared/interfaces/error500-data";

export class Error500Store {
  private error500Data: null | Error500Data = null;

  public setError(data: Error500Data | null) {
    this.error500Data = data;
  }

  public getError(): Error500Data | null {
    return this.error500Data ? { ...this.error500Data } : null;
  }
}

const error500Store: Error500Store = new Error500Store();
export default error500Store;
