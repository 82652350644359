import { useSelector } from "react-redux";

import { BreadcrumbItem } from "../shared/interfaces/breadcrumb";
import { BreadcrumbStore } from "../shared-store/reducers/breadcrumb";

function useBreadcrumbItems(): { data: BreadcrumbItem[] } {
  const result = useSelector((state: any): { data: BreadcrumbItem[] } => {
    const breadcrumb: BreadcrumbStore = state.shared.breadcrumb;
    return {
      data: breadcrumb.items,
    };
  });

  return { ...result };
}

export default useBreadcrumbItems;
