export class BrowsersUtils {
  constructor() {
    this.runIESpecificCode();
  }

  public setIESpecificClass(): void {
    // set IE/Edge specific css class
    if (this.isIEorEdge()) {
      const currentClass = document.body.getAttribute("class") || "";
      document.body.setAttribute("class", `${currentClass} ben-edge`);
    }
  }

  public isIEorEdge(): boolean {
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
  }

  public isIE(): boolean {
    return /msie\s|trident\//i.test(window.navigator.userAgent);
  }

  public isSafari(): boolean | string {
    return navigator.vendor && navigator.vendor.indexOf("Apple") > -1;
  }

  public isMobile(): boolean {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }

  private runIESpecificCode(): void {
    if (
      "-ms-scroll-limit" in document.documentElement.style &&
      "-ms-ime-align" in document.documentElement.style
    ) {
      // detect it's IE11
      this.fixHashSignInUrl();
    }
  }

  /**
   * Fix for:
   * the page is not refreshed when there is a # in the URL (anchor) and the current protocol is https.
   * White screen is shown.
   * https://support.dataart.com/browse/BEN-2788
   */
  private fixHashSignInUrl(): void {
    if (window.location.hash) {
      /* eslint-disable no-self-assign */
      window.location.hash = window.location.hash;
    }
  }
}

const browsersUtils = new BrowsersUtils();
export default browsersUtils;
