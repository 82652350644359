import React, { useEffect, useState, Fragment, useCallback } from "react";
import { Icon } from "antd";
import { useDispatch } from "react-redux";
import { BenAlertMessage, BackBtn } from "common/lib/components";
import { ArrayUtils } from "common/shared/utils";
import { sessionService } from "common/shared";

import {
  ROLE_TYPES,
  rolesService,
  PERMISSION_TYPES,
} from "common/shared/services/roles/roles.service";

import {
  getSingleLiquidityAlerts,
  readSingleLiquidityAlert,
} from "domains/liquidity/store/liquidity.actions";
import { useSingleLiquidityAlertsData } from "domains/liquidity/hooks";
import {
  TypeLiquidityTypeCode,
  TypeLiquidityStatusCode,
  LiquidityRequestAlertItem,
} from "domains/liquidity/shared/types";
import { LiquidityRequestCard } from "domains/liquidity/components";
import { ProcessModal } from "domains/liquidity/modals";
import liquidityService from "domains/liquidity/shared/liquidity.service";

import { PropsLiquidityRequestDetails } from "./types";
import { TitleLiquidityRequestDetails, PlaceholderClientName } from "./constants";

const LiquidityRequestDetails: React.FC<PropsLiquidityRequestDetails> = (
  props
): React.ReactElement | null => {
  const [modalProcessId, setModalProcessId] = useState<TypeLiquidityTypeCode | null>(null);
  const dispatch = useDispatch();

  const { items: alertItems } = useSingleLiquidityAlertsData();

  const { requestId, clientDisplayName, liquidity, liquidityLoading } = props;

  const accountName = liquidity ? liquidity.accountName : null;
  const accountId = liquidity ? liquidity.accountId : null;
  const isClient = props.sessionRole !== ROLE_TYPES.ADVISOR;

  // Request for alerts
  useEffect(() => {
    if (!props.requestId) {
      return;
    }

    dispatch(getSingleLiquidityAlerts({ requestId: props.requestId }));
  }, [dispatch, props.requestId]);

  const handleModalVisibility = useCallback(
    (value: TypeLiquidityTypeCode | null) => {
      return (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setModalProcessId(value);
      };
    },
    [setModalProcessId]
  );

  const handleOnAfterCloseLiquidityAlert = useCallback(
    (alertId: number) => {
      return (): void => {
        if (!rolesService.hasPermission(PERMISSION_TYPES.MarkLrAlertsAsRead)) {
          return;
        }
        if (!alertId || !alertItems || !ArrayUtils.isValidArrayData(alertItems)) {
          return;
        }

        const updatedAlertItems = alertItems.filter((item: LiquidityRequestAlertItem) => {
          return item.alertId !== alertId;
        });

        if (!sessionService.isAppian) {
          dispatch(readSingleLiquidityAlert({ alertId, items: updatedAlertItems }));
        }
      };
    },
    [dispatch, alertItems]
  );

  if (!requestId) {
    return null;
  }

  let id;
  let status: TypeLiquidityStatusCode | null = null;
  let previousStatus: TypeLiquidityStatusCode | null = null;
  const currentAlert =
    (alertItems && ArrayUtils.isValidArrayData(alertItems) && alertItems[0]) || null;
  if (liquidity) {
    id = liquidity.id;
    status = liquidity.status;
    previousStatus = liquidity.previousStatus;
  }

  const { statusSettings, displaySettings } = liquidityService.getProgressBarSettings(
    status,
    previousStatus
  );

  const currentProcessId: TypeLiquidityTypeCode | null = statusSettings
    ? statusSettings.type
    : null;

  return (
    <Fragment>
      {liquidity && id ? (
        <header className="ben-main-section-header">
          <h1>
            <BackBtn type="link" className="ben-btn-reset ben-d-none ben-d-sm-block">
              <Icon type="left" style={{ fontSize: 22 }} />
            </BackBtn>
            {liquidity.displayId}{" "}
            {!isClient &&
              `(${clientDisplayName || accountName || accountId || PlaceholderClientName})`}
          </h1>
        </header>
      ) : null}

      {currentAlert && (
        <BenAlertMessage
          key={currentAlert.alertId}
          message={liquidityService.getAlertMessageDisplaySettings(currentAlert.alertType).title}
          handleOnAfterClose={handleOnAfterCloseLiquidityAlert(currentAlert.alertId)}
        />
      )}

      <LiquidityRequestCard
        cardTitle={TitleLiquidityRequestDetails}
        requestId={id || null}
        holdings={null}
        isShowAlert={false}
        handleModalVisibility={handleModalVisibility}
        step={displaySettings ? displaySettings.step : 0}
        withLink={false}
        showSharedInfo={null}
        isLoading={liquidityLoading}
        currentStepCode={currentProcessId}
      />

      {modalProcessId ? (
        <ProcessModal
          handleModalClose={handleModalVisibility(null)}
          currentProcessId={modalProcessId}
        />
      ) : null}
    </Fragment>
  );
};

export default LiquidityRequestDetails;
