export const ActionTypes = {
  SelectLocale: "[Locale] Select Locale",
};

export const selectLocale = (locale: string) => {
  return {
    type: ActionTypes.SelectLocale,
    payload: locale,
  };
};
