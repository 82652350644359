import { useSelector } from "react-redux";
import { IdentityState } from "../store/identity.reducer";

function useLogoutData(): boolean {
  const result = useSelector((state: { identity: IdentityState }): boolean => {
    return Boolean(state.identity && state.identity.loggingOut);
  });

  return result;
}

export default useLogoutData;
