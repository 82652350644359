import React from "react";
import { Button, Icon, Tooltip } from "antd";

import { Trans } from "@lingui/react";

import { PropsDontHaveButton } from "./types";

export const DoNotHaveBtnTooltip = <Trans id="documents.donthave">I Don't Have This</Trans>;

const DontHaveButton: React.FC<PropsDontHaveButton> = (props): React.ReactElement => {
  const { disableDontHaveIcon, uniqueBtnId, handleOnClick } = props;

  return (
    <Tooltip
      placement="left"
      title={DoNotHaveBtnTooltip}
      {...(disableDontHaveIcon && { visible: false })}
    >
      <Button
        type="link"
        id={uniqueBtnId}
        onClick={handleOnClick}
        disabled={disableDontHaveIcon}
        className="ben-btn-reset"
      >
        <Icon type="file-unknown" style={{ fontSize: 20 }} />
      </Button>
    </Tooltip>
  );
};

export default DontHaveButton;
