import { Constants } from "common/lib/constants";
import { ArrayUtils } from "common/shared/utils";
import { CurrencyListResponseData, CurrencyItemData } from "common/shared/interfaces/currency";

interface CurrencyItemDataRaw {
  currency_code: string;
  currency_name: string;
  currency_sign: string;
}

export function parseCurrencyRequestsListResponseData(data: any): CurrencyListResponseData {
  const result: CurrencyListResponseData = {
    items: [],
  };

  if (!data || typeof data !== "object") {
    return result;
  }

  const isItemsExist = ArrayUtils.isValidArrayData(data.items);
  if (!isItemsExist) {
    return result;
  }

  //  USD should be at the top of the list
  const defaultCurrency = data.items.find((item: CurrencyItemDataRaw) => {
    return item.currency_code === Constants.DEFAULT_CURRENCY.currencyCode;
  });
  const currencies = data.items.filter((item: CurrencyItemDataRaw) => {
    return item.currency_code !== Constants.DEFAULT_CURRENCY.currencyCode;
  });
  currencies.unshift(defaultCurrency);

  const parsedItems: CurrencyItemData[] = currencies.map((item: CurrencyItemDataRaw) => {
    return {
      currencyCode: item.currency_code,
      currencyName: item.currency_name,
      currencySign: item.currency_sign,
    };
  });

  result.items = parsedItems;

  return result;
}
