export const CROSS_TAB_EVENTS: { [key: string]: string } = {
  OPEN_SESSION_MODAL: "OPEN_SESSION_MODAL",
  NEW_TAB_OPENED: "NEW_TAB_OPENED",
  SEND_SESSION_DATA: "SEND_SESSION_DATA",
  USER_ACTIVITY: "USER_ACTIVITY",
  UPDATE_SESSION: "UPDATE_SESSION",
  CLOSE_SESSION: "CLOSE_SESSION",
  LOGIN: "LOGIN",
  MAINTENANCE: "MAINTENANCE",
  VERSION_UPDATED: "VERSION_UPDATED",
  REFRESH: "REFRESH",
  UNPROTECTED_PAGE_INACTIVE: "UNPROTECTED_PAGE_INACTIVE",
};
