import React from "react";

import { Trans } from "@lingui/macro";

export const TitleLiquidityRequestDetails = (
  <Trans id="liquidityRequestDetails.progressBar.title">Overview</Trans>
);

export const PlaceholderClientName = (
  <Trans id="liquidityRequestDetails.placeholderClientName">Client</Trans>
);
