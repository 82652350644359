import React from "react";
import { Col, Row, Button } from "antd";

import { TypeFileValidationError } from "common/shared/services/file/file.service";
import { IconCustom } from "common/lib/components";

import { UploadingText } from "domains/document/components";

import { PropsDocument } from "./types";
import "./document.scss";

const Document: React.FC<PropsDocument> = (props): React.ReactElement | null => {
  const {
    fileName,
    fileId,
    fileErrors,
    progress,
    isCancelEnabled,
    isProcessStarted,
    isProcessCompleted,
    maxUploadingProgress,
    handleCancelUpload,
    handleRemoveFile,
  } = props;

  let iconType = "attention";
  if (progress && progress < maxUploadingProgress) {
    iconType = "pending";
  } else if (progress && progress === maxUploadingProgress) {
    iconType = "complete";
  } else if (fileErrors) {
    iconType = "declined";
  }

  return (
    <Row type="flex" className="uploading-directory--document">
      <Col md={1} xs={2}>
        <IconCustom type={iconType} style={{ fontSize: 20 }} />
      </Col>

      <Col md={13} xs={13} style={{ paddingLeft: "5px" }} className="ben-document-title-wrapper">
        <strong className="ben-document-title">{fileName}</strong>
        {fileErrors ? (
          <div>
            {fileErrors.map((item: TypeFileValidationError) => {
              return (
                <p key={item.id} className="ben-error-text">
                  {item.message}
                </p>
              );
            })}
          </div>
        ) : null}
      </Col>

      <Col md={10} xs={9} className="ben-d-flex ben-justify-content-end">
        {progress > 0 && progress < maxUploadingProgress ? (
          <UploadingText
            uploadProgress={progress}
            isCancelEnabled={isCancelEnabled}
            handleCancelUpload={handleCancelUpload}
          />
        ) : null}
        {progress === maxUploadingProgress ? (
          <IconCustom type="save" style={{ fontSize: 20 }} />
        ) : null}
        {!isProcessCompleted && !isProcessStarted ? (
          <Button
            id={`benRemoveUploadedFile_${fileId}`}
            type="link"
            onClick={handleRemoveFile(fileId)}
          >
            <IconCustom type="trash" style={{ fontSize: 20 }} />
          </Button>
        ) : null}
      </Col>
    </Row>
  );
};

export default Document;
