import React, { Fragment } from "react";

import {
  STANDARD_INVESTMENT_DOCUMENT_LIST,
  DocumentTypeUploadConfigs,
} from "domains/document/shared/constants";
import { TypeDocumentType } from "domains/document/shared/types";
import documentService from "domains/document/shared/document.service";
import { BenDocument } from "domains/document/components";

import { PropsStandardHoldingDocuments } from "./types";

const StandardHoldingDocuments: React.FC<PropsStandardHoldingDocuments> = (
  props
): React.ReactElement | null => {
  const {
    holding,
    disableUpload,
    disableDontHaveIcon,
    onUploadSuccess,
    disableActions,
    onUploadError,
    onUploadStart,
    onUploadCancel,
  } = props;

  return (
    <Fragment>
      {STANDARD_INVESTMENT_DOCUMENT_LIST.map((docType: TypeDocumentType) => {
        const standardDoc = holding.standardDocuments[docType];
        if (standardDoc) {
          return null;
        }

        const { typeSettings: docSettings } = documentService.getDisplaySettingsByType(docType);

        return (
          <BenDocument
            withDragger={true}
            openFileDialogOnClick={true}
            key={docType}
            isPlaceholder={true}
            title={docSettings.title}
            statusIcon="attention"
            size="small"
            disableUpload={disableUpload}
            disableDontHaveIcon={disableDontHaveIcon}
            uploadConfig={{
              accountId: holding.accountId,
              liquidityRequestId: holding.liquidityRequestId,
              investmentId: holding.investmentId,
              assetId: holding.assetId,
              ...DocumentTypeUploadConfigs[docType],
            }}
            onUploadSuccess={onUploadSuccess}
            onUploadError={onUploadError}
            disableActions={disableActions}
            onUploadStart={onUploadStart}
            onUploadCancel={onUploadCancel}
          />
        );
      })}
    </Fragment>
  );
};

export default StandardHoldingDocuments;
