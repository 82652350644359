import React from "react";
import { Button, Tooltip } from "antd";

import { Trans } from "@lingui/react";

import { IconCustom } from "common/lib/components";

import { PropsDownloadButton } from "./types";

export const DownloadBtnTooltip = <Trans id="tooltip.btn.download">Download</Trans>;

const DownloadButton: React.FC<PropsDownloadButton> = (props): React.ReactElement => {
  const { uniqueBtnId, handleOnClick } = props;

  return (
    <Tooltip placement="left" title={DownloadBtnTooltip}>
      <Button id={uniqueBtnId} className="ben-btn-reset" type="link" onClick={handleOnClick}>
        <IconCustom type="download" style={{ fontSize: 20 }} />
      </Button>
    </Tooltip>
  );
};

export default DownloadButton;
