import React, { Fragment, useCallback, useEffect, useState } from "react";

import { Button, Icon, Row, Col } from "antd";
import { Trans } from "@lingui/macro";

import { Constants } from "common/lib/constants";
import { Spinner, LinkCustom, BenFormRowInfo } from "common/lib/components";
import { sessionService } from "common/shared";

import { AssetCard } from "domains/liquidity";
import { AssetScheme } from "domains/liquidity/components/asset-card/types";
import { TypeGetUpdateAssetParams } from "domains/liquidity/shared/types";

import { PropsAddHoldingForm } from "./types";
import {
  getNewAssetDefault,
  isDisabledFormSubmit,
  isShowGeneralFormValidation,
  getParamsCreateAssets,
} from "./helpers";

const AddHoldingForm: React.FC<PropsAddHoldingForm> = (props): React.ReactElement | null => {
  const [assets, setAssets] = useState<AssetScheme[]>([getNewAssetDefault()]);
  const [isDisabledConnection, setDisabledConnection] = useState(false);

  /**
   *  This effect is for cases when a PC is disconnected from the Internet.
   *  Before that we had a situation when several LR was created at a time.
   */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!window.navigator.onLine) {
      setDisabledConnection(true);
    }
    const timer = setTimeout(() => {
      setDisabledConnection(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [window.navigator.onLine, isDisabledConnection]);

  const getUpdateAsset = useCallback(
    (id: number) => (params: TypeGetUpdateAssetParams) => {
      const newAssets = assets.map((asset: AssetScheme) => {
        return asset.id === id ? { ...asset, ...params } : asset;
      });
      setAssets(newAssets);
    },
    [setAssets, assets]
  );

  const getDeleteAsset = (id: number) => () => {
    if (sessionService.isAppian) {
      return;
    }

    const newAssets = assets.filter((a: AssetScheme) => a.id !== id);
    setAssets(newAssets);
  };

  const addAsset = () => {
    if (sessionService.isAppian) {
      return;
    }

    setAssets([...assets, getNewAssetDefault()]);
  };

  const {
    submitCallback,
    isLiquiditiesExist,
    isClient,
    isLoading,
    currentClientLRPage,
    cancelCallback,
  } = props;

  const handleCancelOnEmptyLiquidities = useCallback(() => {
    setAssets([getNewAssetDefault()]);
    if (cancelCallback) {
      cancelCallback();
    }
  }, []);

  const submitHandler = useCallback(() => {
    submitCallback(getParamsCreateAssets(assets));
  }, [assets]);

  const isDisabledForm = isDisabledFormSubmit(sessionService.isAppian, assets);
  const isGeneralFormValidationShown = isShowGeneralFormValidation(assets);
  const hasDirtyEntity = assets.some((asset: AssetScheme) => asset.isDirty === true);

  return (
    <div className="add-holding-form-wrapper">
      <div className="ben-panel ben-pb-2">
        <div className="ben-panel-header">
          <h3>
            <Trans id="liquidityRequest.new.subtitle">Holdings</Trans>
          </h3>
        </div>

        <div className="ben-pb-2">
          <p>
            <strong className="ben-required-label">
              <Trans id="required.helper" />
            </strong>
          </p>
        </div>

        <BenFormRowInfo
          type="validation"
          classNames={
            `ben-form-top-validation ` +
            (isGeneralFormValidationShown ? "ben-form-top-validation-show" : "")
          }
        />

        <Spinner spinning={isLoading}>
          <Fragment>
            {
              <div className="ben-assets ben-border-bottom">
                {assets.map((asset: AssetScheme, id: number) => (
                  <AssetCard
                    key={asset.id}
                    idAttr={`newLiquidityRequestCard_${id}`}
                    onStateChange={getUpdateAsset(asset.id)}
                    onDelete={getDeleteAsset(asset.id)}
                    deleteDisabled={assets.length === 1}
                  />
                ))}
              </div>
            }

            <div className="ben-pt-2 ben-pb-2 ben-border-bottom">
              <Button
                id="benNewLiquidityRequestBtnAddAsset"
                type="link"
                onClick={addAsset}
                disabled={isDisabledForm}
                className="ben-btn-reset"
              >
                <Icon type="plus" style={{ fontSize: 20 }} />{" "}
                <Trans id="liquidityRequest.new.add.asset" />
              </Button>
            </div>
            <Row gutter={30} className="ben-mt-4 ben-form-actions">
              <Col md={{ span: 12 }} xs={{ span: 24 }} className="ben-pb-2">
                {currentClientLRPage ? (
                  !isClient || (isClient && isLiquiditiesExist) ? (
                    <LinkCustom
                      type="primary"
                      shape="round"
                      size="large"
                      ghost={true}
                      className="ben-w-100"
                      to={`${currentClientLRPage}#${Constants.SCROLL_TO_LR}`}
                    >
                      <Trans id="liquidityRequest.new.cancel">Cancel</Trans>
                    </LinkCustom>
                  ) : null
                ) : null}

                {!currentClientLRPage || (isClient && !isLiquiditiesExist) ? (
                  <Button
                    id="benNewLiquidityBtnCancel"
                    type="primary"
                    shape="round"
                    size="large"
                    className="ben-w-100"
                    disabled={!hasDirtyEntity}
                    onClick={handleCancelOnEmptyLiquidities}
                  >
                    <Trans id="liquidityRequest.new.cancel" />
                  </Button>
                ) : null}
              </Col>
              <Col md={{ span: 12 }} xs={{ span: 24 }} className="ben-pb-2">
                <Button
                  id="benNewLiquidityBtnSubmit"
                  type="primary"
                  shape="round"
                  size="large"
                  className="ben-w-100"
                  disabled={isDisabledForm || isDisabledConnection}
                  onClick={submitHandler}
                >
                  <Trans id="liquidityRequest.new.submit">Submit</Trans>
                </Button>
              </Col>
            </Row>
          </Fragment>
        </Spinner>
      </div>
    </div>
  );
};

export default AddHoldingForm;
