import React, { useEffect } from "react";
import { Form, Input, Tooltip } from "antd";

import { localeService, getValidationErrors } from "common/shared";
import { Spinner, PasswordHelpMessage, BenFormRowInfo } from "common/lib/components";
import { actions$ } from "common/shared-store/actions/actionsStream";

import { ActionTypes } from "domains/clients/store/clients.actions";

interface ChangePasswordFormProps {
  handlePassEditMode: (editMode: boolean) => void;
  loading: boolean;
  config: any;
}

function ChangePasswordForm(props: ChangePasswordFormProps): React.ReactElement {
  const { handlePassEditMode, config, loading } = props;
  const { state, resetForm, handleOnChange, handleOnBlur, isDirty, hasDirtyInvalidField } = config;

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.ChangePasswordFail).subscribe(() => {
        resetForm();
      }),
      actions$.ofType(ActionTypes.ChangePasswordSuccess).subscribe(() => {
        handlePassEditMode(false);
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [handlePassEditMode, resetForm]);

  return loading ? (
    <Spinner />
  ) : (
    <div className="ben-form-holder ben-form-editable-mode">
      <Form>
        <BenFormRowInfo type="required" />
        <BenFormRowInfo
          type="validation"
          classNames={
            `ben-form-top-validation ben-bottom-border ` +
            (isDirty && hasDirtyInvalidField() ? "ben-form-top-validation-show" : "")
          }
        />

        <div>
          <Form.Item
            className="ben-required-field ben-form-label-field"
            label={localeService.i18n._("changepass.currentPassword.label")}
            {...getValidationErrors(
              state.currentPassword.errors,
              localeService.i18n._("changepass.currentPassword.label"),
              "validation.password"
            )}
          >
            <Input
              name="currentPassword"
              type="password"
              value={state.currentPassword.value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              autoFocus={false}
              disabled={state.currentPassword.isDisabled}
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            className="ben-required-field ben-form-label-field"
            label={localeService.i18n._("changepass.newPassword.label")}
            {...getValidationErrors(
              state.newPassword.errors,
              localeService.i18n._("changepass.newPassword.label"),
              "validation.password"
            )}
          >
            <Input
              type="password"
              name="newPassword"
              value={state.newPassword.value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              disabled={state.newPassword.isDisabled}
              suffix={
                <Tooltip title={<PasswordHelpMessage />}>
                  <span className="ben-icon icon-info" />
                </Tooltip>
              }
            />
          </Form.Item>
        </div>
        <div>
          <Form.Item
            className="ben-required-field ben-form-label-field"
            label={localeService.i18n._("changepass.confirmPassword.label")}
            {...getValidationErrors(
              state.confirmPassword.errors,
              localeService.i18n._("changepass.confirmPassword.error.label")
            )}
          >
            <Input
              type="password"
              name="confirmPassword"
              value={state.confirmPassword.value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
              disabled={state.confirmPassword.isDisabled}
            />
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default ChangePasswordForm;
