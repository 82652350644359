export class TokenDecodeUtils {
  public decodeResetPassToken(token: string) {
    try {
      const decodedTokenData: any = this.decodeJwt(token);
      return {
        userEmail: decodedTokenData["custom:email"],
        expirationTime: decodedTokenData.exp,
      };
    } catch (e) {
      return null;
    }
  }

  public decodeAuthToken(token: string): { clientId?: string } {
    try {
      const decodedTokenData: any = this.decodeJwt(token);
      return {
        clientId: decodedTokenData.client_id,
      };
    } catch (e) {
      return {};
    }
  }

  public isTokenExpired(token: string): boolean {
    const tokenData = this.decodeResetPassToken(token);
    return !tokenData || tokenData.expirationTime * 1000 < new Date().getTime();
  }

  public decodeJwt(token: string) {
    if (typeof token === "string") {
      const base64Url = token.split(".")[1];
      if (!base64Url) {
        return "";
      }

      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    }

    return "";
  }
}

const tokenDecodeUtils = new TokenDecodeUtils();
export default tokenDecodeUtils;
