import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";

import { Urls } from "common/lib/constants";

import { rolesService } from "common/shared/services/roles/roles.service";

import AdvisorDashboardWrapper from "../AdvisorDashboardWrapper";
import ClientDashboardPage from "../ClientDashboardPage";
import { DashboardHeader } from "pages/components";

interface PropsDashboardWrapper extends RouteComponentProps {}

function DashboardWrapper(props: PropsDashboardWrapper) {
  if (!rolesService.isAdvisor() && !rolesService.isClient()) {
    return <Redirect from="" to={Urls.PROTECTED.PROFILE} noThrow={true} />;
  }

  return (
    <section className="ben-main-section">
      <DashboardHeader />

      {rolesService.isAdvisor() && <AdvisorDashboardWrapper />}
      {rolesService.isClient() && <ClientDashboardPage {...props} />}
    </section>
  );
}

export default DashboardWrapper as React.ComponentType<PropsDashboardWrapper>;
