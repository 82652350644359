import React from "react";
import { Trans } from "@lingui/react";

import { messageService } from "common/shared";
import MessageConfig from "common/shared/interfaces/message-config";
import { DocumentTypeUploadConfigs } from "domains/document/shared/constants";
import { DocumentTypeUpload, TypeReferenceType } from "domains/document/shared/types";

export const TitleAddDocument = (
  <Trans id="transactionDocuments.addDocument.btn.title">Add Document</Trans>
);

export const TitleErrorSameFileNameUpload = (
  <Trans id="documents.SamefileNameUpload.fail">
    A document with this name already exists. Please try a different name or press Cancel if you
    have already uploaded this file.
  </Trans>
);

export const showMessageFileUploadSuccess = (documentName?: string): void => {
  messageService.success(
    new MessageConfig("toaster.fileUpload.success", [{ name: "fileName", value: documentName }])
  );
};

export const TitleBtnSave = <Trans id="actionButtons.save">Save</Trans>;
export const TitleBtnCancel = <Trans id="actionButtons.cancel">Cancel</Trans>;

export const ReferenceTypeToUploadConfigsMap: {
  [key in TypeReferenceType]: DocumentTypeUpload;
} = {
  investment: DocumentTypeUploadConfigs.custom_other_investment,
  liquidity_request: DocumentTypeUploadConfigs.custom_other_liquidity_request,
  // Default values for unused referenceType
  asset: DocumentTypeUploadConfigs.custom_other_investment,
  account: DocumentTypeUploadConfigs.custom_other_investment,
};
