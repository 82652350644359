import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Collapse } from "antd";

import { sessionService } from "common/shared";
import { useForm } from "common/shared-hooks";
import { PatchRequestItemData } from "common/shared/interfaces/requests";
import { Spinner, BenFormRowInfo } from "common/lib/components";

import { rolesService, PERMISSION_TYPES } from "common/shared/services/roles/roles.service";
import ProfileApiTransformHelper from "domains/clients/shared/profile.api.transform.helper";

import {
  AddressForm,
  AddressFormSchema,
  addressValidationSchema,
  AddressFormReturnedData,
  impersonateAddressDisabledConfig,
  PersonalForm,
  PersonalFormSchema,
  personalValidationSchema,
  PersonalFormReturnedData,
  impersonatePersonalDisabledConfig,
  PhoneFormSchema,
  phoneValidationSchema,
  PhoneFormReturnedData,
  impersonatePhoneDisabledConfig,
  phoneValidationSchemaDeps,
} from "domains/clients/forms";

import { PersonalInfo, ClientControls, AddressInfo } from "domains/clients/components";
import { editPrincipalClient } from "domains/clients/store/clients.actions";

import { PropsPrincipalInformation } from "./types";
import {
  PrincipalInformationDisabledFields,
  PrincipalInformationInputPlaceholder,
} from "./constants";

const { Panel } = Collapse;

const PrincipalInformation: React.FC<PropsPrincipalInformation> = (
  props
): React.ReactElement | null => {
  const {
    client,
    isEdit,
    isUpdating,
    isOwnProfile,
    activeKey,
    handleSwitchUpdateStatus,
    handleSwitchEditMode,
    handleOnEditClick,
    currentCollapseKey,
    header,
    isShowCombinedAddress,
    isShowCombinedName,
  } = props;
  const dispatch = useDispatch();

  let personalData: PersonalFormReturnedData | null = null;
  const getPersonalData = (data: PersonalFormReturnedData) => {
    personalData = { ...data };
  };

  let addressData: AddressFormReturnedData | null = null;
  const getAddressData = (data: AddressFormReturnedData) => {
    addressData = { ...data };
  };

  let phoneData: PhoneFormReturnedData | null = null;
  const getPhoneData = (data: PhoneFormReturnedData) => {
    phoneData = { ...data };
  };

  const personalFormDisableConfig = sessionService.isAppian
    ? impersonatePersonalDisabledConfig
    : PrincipalInformationDisabledFields;
  const personalFormSchema = new PersonalFormSchema(client, personalFormDisableConfig);
  const personalFormConfig = useForm(personalFormSchema, personalValidationSchema, getPersonalData);

  const addressFormDisableConfig = sessionService.isAppian
    ? impersonateAddressDisabledConfig
    : PrincipalInformationDisabledFields;
  const addressFormSchema = new AddressFormSchema(client, addressFormDisableConfig);
  const addressFormConfig = useForm(addressFormSchema, addressValidationSchema, getAddressData);

  const phoneFormDisableConfig = sessionService.isAppian
    ? impersonatePhoneDisabledConfig
    : PrincipalInformationDisabledFields;
  const phoneFormSchema = new PhoneFormSchema(client, phoneFormDisableConfig);
  const phoneFormConfig = useForm(
    phoneFormSchema,
    phoneValidationSchema,
    getPhoneData,
    phoneValidationSchemaDeps
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    addressFormConfig.resetForm();
    personalFormConfig.resetForm();
    phoneFormConfig.resetForm();
  }, [isEdit]);

  const isDisabled = (): boolean => {
    return (
      sessionService.isAppian ||
      isUpdating ||
      (addressFormConfig.isDirty && addressFormConfig.isDisabledSubmit()) ||
      (personalFormConfig.isDirty && personalFormConfig.isDisabledSubmit()) ||
      (phoneFormConfig.isDirty && phoneFormConfig.isDisabledSubmit()) ||
      (!addressFormConfig.isStateChanged() &&
        !personalFormConfig.isStateChanged() &&
        !phoneFormConfig.isStateChanged())
    );
  };

  const isGeneralFormValidationShown = () => {
    return (
      (personalFormConfig.isDirty && personalFormConfig.hasDirtyInvalidField()) ||
      (addressFormConfig.isDirty && addressFormConfig.hasDirtyInvalidField()) ||
      (phoneFormConfig.isDirty && phoneFormConfig.hasDirtyInvalidField())
    );
  };

  const handleCancelForm = useCallback(() => {
    handleSwitchEditMode();
  }, [handleSwitchEditMode, addressFormConfig, personalFormConfig, phoneFormConfig]);

  function onSubmit() {
    if (sessionService.isAppian) {
      return;
    }

    if (
      !personalFormConfig.isDisabledSubmit() &&
      !addressFormConfig.isDisabledSubmit() &&
      !phoneFormConfig.isDisabledSubmit()
    ) {
      personalFormConfig.handleOnSubmit();
      addressFormConfig.handleOnSubmit();
      phoneFormConfig.handleOnSubmit();

      const editedClient: { [key: string]: string } = {
        ...personalData,
        ...addressData,
        ...phoneData,
      };

      let patch:
        | PatchRequestItemData[]
        | null = ProfileApiTransformHelper.preparePrincipalEditPatch(editedClient, client);
      if (!patch) {
        // TODO: No changes detected - add action here
        patch = [];
      }
      update(patch);
    }
  }

  const update = (data: PatchRequestItemData[]) => {
    handleSwitchUpdateStatus(true);
    dispatch(editPrincipalClient({ data, accountId: client.accountId, userId: client.userId }));
  };

  return (
    <Collapse expandIconPosition="right" activeKey={activeKey}>
      <Panel
        header={
          <span className="ben-header">{header || `Client ID: ${client.accountDisplayId}`}</span>
        }
        extra={
          (isOwnProfile || rolesService.hasPermission(PERMISSION_TYPES.EditAccount)) && (
            <ClientControls
              isDisabled={isDisabled()}
              isDisabledCancel={isUpdating}
              isEdit={isEdit}
              btnId="PrincipalInformation"
              handleClickSave={onSubmit}
              handleClickCancel={handleCancelForm}
              handleClickEdit={handleOnEditClick}
            />
          )
        }
        showArrow={false}
        key={currentCollapseKey}
      >
        {isEdit ? (
          <Spinner spinning={isUpdating}>
            <div className="ben-form-holder ben-form-editable-mode account-edit">
              <BenFormRowInfo type="changePrimary" />
              <BenFormRowInfo
                type="validation"
                classNames={
                  `ben-form-top-validation ben-bottom-border ` +
                  (isGeneralFormValidationShown() ? "ben-form-top-validation-show" : "")
                }
              />
              <PersonalForm
                config={personalFormConfig}
                placeholder={PrincipalInformationInputPlaceholder}
              />
              <AddressForm
                config={addressFormConfig}
                placeholder={PrincipalInformationInputPlaceholder}
              />
              {/* <PhoneForm
                config={phoneFormConfig}
                placeholder={PrincipalInformationInputPlaceholder}
              /> */}
            </div>
          </Spinner>
        ) : (
          <div className="info-section">
            <PersonalInfo
              user={client}
              isShowCombinedName={isShowCombinedName}
              placeholder={PrincipalInformationInputPlaceholder}
            />
            <AddressInfo
              user={client}
              isShowCombinedAddress={isShowCombinedAddress}
              placeholder={PrincipalInformationInputPlaceholder}
            />
            {/* <PhoneInfo
              user={client}
              isShowCombinedPhone={isShowCombinedPhone}
              placeholder={PrincipalInformationInputPlaceholder}
            /> */}
          </div>
        )}
      </Panel>
    </Collapse>
  );
};

export default PrincipalInformation;
