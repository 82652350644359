import React, { useEffect, useState, useCallback, useMemo } from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/react";
import { AnchoredMenu, Sticky, Spinner } from "common/lib/components";

import { DashboardHeader } from "pages/components";
import { Constants } from "common/lib/constants";
import { localeService } from "common/shared";
import {
  InstitutionSectionWrapper,
  ProfilePersonalSection,
  ProfileSecuritySection,
  useProfileData,
} from "domains/clients";

import "./ProfilePage.scss";

export const anchoredIdsInitial = {
  profileInfo: "profile-info",
  security: "security",
  institution: "institution",
};

function ProfilePage() {
  /* eslint-disable react-hooks/exhaustive-deps */
  const MENU = useMemo(
    () => ({
      PROFILE: {
        id: anchoredIdsInitial.profileInfo,
        title: localeService.i18n._("profile.menu.profile"),
      },
      SECURITY: {
        id: anchoredIdsInitial.security,
        title: localeService.i18n._("profile.menu.security"),
      },
      INSTITUTION: {
        id: anchoredIdsInitial.institution,
        title: localeService.i18n._("profile.menu.institution"),
      },
    }),
    []
  );
  const menuItemsInitial = useMemo(() => [MENU.PROFILE, MENU.SECURITY], []);

  const [anchoredIds, setAnchoredIds] = useState(anchoredIdsInitial);
  const [menuItems, setMenuItems] = useState(menuItemsInitial);
  const [openedCard, setOpenedCard] = useState<string | null>(null);

  const { user, loading } = useProfileData();

  useEffect(() => {
    const isPrincipalUser =
      user &&
      (user.userType === Constants.USER_TYPES.ADVISOR ||
        user.userType === Constants.USER_TYPES.EMPLOYEE);

    if (isPrincipalUser) {
      setMenuItems((items) => {
        const institutionItem = items.find((item) => item.id === "institution");
        return institutionItem ? items : [MENU.PROFILE, MENU.INSTITUTION, MENU.SECURITY];
      });
      setAnchoredIds((ids) => ({ ...ids, institution: "institution" }));
    }
  }, [user]);

  const handleOnChangeMode = useCallback(
    (cardName: string) => (isEditMode: boolean) => {
      if (isEditMode) {
        setOpenedCard(cardName);
      } else {
        setOpenedCard(null);
      }
    },
    []
  );

  return (
    <div className="ben-sidebar-container">
      <Sticky topOffset={Constants.HEADER_HEIGHT}>
        <div className="ben-sidebar">
          <AnchoredMenu items={menuItems} />
        </div>
      </Sticky>
      <div className="ben-content">
        <section className="ben-main-section">
          <DashboardHeader />

          <header className="ben-main-section-header">
            <h1>
              <Trans id="profile.title">Account</Trans>
            </h1>
          </header>

          {loading && <Spinner />}
          {user ? (
            <div className="ben-user-profile">
              <div id={anchoredIds.profileInfo}>
                <ProfilePersonalSection
                  user={user}
                  shouldTurnOffEditMode={Boolean(openedCard !== anchoredIds.profileInfo)}
                  handleOnChangeMode={handleOnChangeMode(anchoredIds.profileInfo)}
                />
              </div>
              <div id={anchoredIds.institution}>
                <InstitutionSectionWrapper
                  accountId={user.accountId}
                  shouldTurnOffEditMode={Boolean(openedCard !== anchoredIds.institution)}
                  handleOnChangeMode={handleOnChangeMode(anchoredIds.institution)}
                  userType={user.userType}
                />
              </div>
              <div id={anchoredIds.security}>
                <ProfileSecuritySection
                  shouldTurnOffEditMode={Boolean(openedCard !== anchoredIds.security)}
                  handleOnChangeMode={handleOnChangeMode(anchoredIds.security)}
                />
              </div>
            </div>
          ) : null}
        </section>
      </div>
    </div>
  );
}

export default ProfilePage as React.ComponentType<RouteComponentProps>;
