import React, { useState, useEffect, Fragment } from "react";

import { LiquidityRequestGroup } from "domains/liquidity/components";
import { ProcessModal } from "domains/liquidity/modals";
import {
  LiquidityRequestItemData,
  TypeLiquidityGroup,
  TypeLiquidityTypeCode,
} from "domains/liquidity/shared/types";
import { LiquidityRequestGroupTitlesMap } from "domains/liquidity/shared/constants";
import liquidityService from "domains/liquidity/shared/liquidity.service";
import { useClientLiquiditiesAlertsData } from "domains/liquidity/hooks";

import { PropsLiquidityRequestList } from "./types";

const LiquidityRequestList: React.FC<PropsLiquidityRequestList> = (
  props
): React.ReactElement | null => {
  const [modalProcessId, setModalProcessId] = useState<TypeLiquidityTypeCode | null>(null);
  const [activeGroup, setActiveGroup] = useState<LiquidityRequestItemData[] | null>(null);
  const [archivedGroup, setArchivedGroup] = useState<LiquidityRequestItemData[] | null>(null);
  const [closedGroup, setClosedGroup] = useState<LiquidityRequestItemData[] | null>(null);
  const { itemsMapped: alertItems } = useClientLiquiditiesAlertsData();

  useEffect(() => {
    if (!props.list || !props.groupsToShow) {
      return;
    }

    const filteredData = liquidityService.filterListByGroups(props.list, alertItems);
    if (!filteredData) {
      return;
    }

    props.groupsToShow.forEach((groupName: TypeLiquidityGroup) => {
      if (groupName === "active") {
        setActiveGroup(filteredData.active);
      } else if (groupName === "archived") {
        setArchivedGroup(filteredData.archived);
      } else if (groupName === "closed") {
        setClosedGroup(filteredData.closed);
      }
    });
  }, [props.list, props.groupsToShow, alertItems]);

  // Request for alerts (NOT DISPLAYING ON UI SO REMOVING THIS FUNCTIONALITY)
  // useEffect(() => {
  //   if (!props.list || !props.list.length || !props.currentAccountId) {
  //     return;
  //   }

  //   dispatch(getClientLiquiditiesAlerts({ accountId: props.currentAccountId }));
  // }, [dispatch, props.list, props.currentAccountId]);

  const { groupsToShow, currentUserId, sharedByObjectType, sessionRole } = props;

  const handleModalVisibility = React.useCallback(
    (currentProcessId: TypeLiquidityTypeCode | null) => {
      return (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setModalProcessId(currentProcessId);
      };
    },
    [setModalProcessId]
  );

  if (!groupsToShow || !liquidityService.checkGroupsToShowExists(groupsToShow)) {
    return null;
  }

  return (
    <Fragment>
      <LiquidityRequestGroup
        title={LiquidityRequestGroupTitlesMap.active}
        items={activeGroup}
        handleModalVisibility={handleModalVisibility}
        sharedByObjectType={sharedByObjectType}
        currentUserId={currentUserId}
        sessionRole={sessionRole}
      />
      <LiquidityRequestGroup
        title={LiquidityRequestGroupTitlesMap.closed}
        items={closedGroup}
        handleModalVisibility={handleModalVisibility}
        sharedByObjectType={sharedByObjectType}
        currentUserId={currentUserId}
        sessionRole={sessionRole}
      />
      <LiquidityRequestGroup
        title={LiquidityRequestGroupTitlesMap.archived}
        items={archivedGroup}
        handleModalVisibility={handleModalVisibility}
        sharedByObjectType={sharedByObjectType}
        currentUserId={currentUserId}
        sessionRole={sessionRole}
      />
      {modalProcessId ? (
        <ProcessModal
          handleModalClose={handleModalVisibility(null)}
          currentProcessId={modalProcessId}
        />
      ) : null}
    </Fragment>
  );
};

export default LiquidityRequestList;
