import { ofType } from "redux-observable";
import { from } from "rxjs";
import { mergeMap, map, catchError, switchMap } from "rxjs/operators";

import {
  impersonateService,
  messageService,
  sessionService,
  crossTabMessageService,
  CROSS_TAB_EVENTS,
  logAPIError,
  TypeSessionData,
  verifyInvestorService,
} from "common/shared";
import { sessionActivityWatcherService } from "common/shared/services/sessionActivityWatcher/sessionActivityWatcher.service";
import Tokens from "common/shared/interfaces/tokens";

import identityService from "../shared/identity.api.service";
import { rolesService } from "common/shared/services/roles/roles.service";
import {
  ActionTypes,
  getIdentity,
  createRegistrationSuccess,
  createRegistrationFail,
  getIdentitySuccess,
  getIdentityFail,
  getPermissionsSuccess,
  getPermissionsFail,
  getPermissions,
  logoutSuccess,
  logoutFail,
} from "./identity.actions";
import { ParamsLogoutAction } from "../shared/types";

const { LOGIN, SEND_SESSION_DATA, CLOSE_SESSION } = CROSS_TAB_EVENTS;

export const getIdentityEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.GetIdentity),
    map((action: { type: string; payload: string }) => action.payload),
    switchMap((username: string) =>
      from(identityService.getIdentity(username)).pipe(
        switchMap((data: any) => {
          sessionService.setSessionIds(data);
          return [getIdentitySuccess(data), getPermissions(data.role)];
        }),
        catchError(() => [getIdentityFail()])
      )
    )
  );

export const registrationEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.CreateRegistrationRequest),
    map((action: any) => action.payload),

    switchMap(async (data: any) => {
      try {
        await identityService.createRegistrationRequest(data);
        messageService.success(
          " We have sent you an email with instructions for accessing Ben Alt Access"
        );
        return createRegistrationSuccess(data);
      } catch (error) {
        if (error.response.status === 409) {
          messageService.success(
            "We have sent you an email with instructions for accessing Ben Alt Access"
          );
        } else {
          messageService.error(error.response.data);
        }
        return createRegistrationFail();
      }
    })
  );

export const getPermissionsEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.GetPermissions),
    map((action: { type: string; payload: string }) => action.payload),
    mergeMap(async (role: string) => {
      try {
        const permissions = await identityService.getPermissions(role);
        rolesService.setPermissions({ permissions, role });
        return getPermissionsSuccess(permissions);
      } catch (error) {
        return getPermissionsFail();
      }
    })
  );

export const initEpic = (actions$: any) =>
  actions$.pipe(
    ofType(ActionTypes.Init),

    mergeMap(() => {
      const username = sessionService.getUserName();
      sessionActivityWatcherService.init();

      // Send info about login in one tab to other tabs
      const sessionData: TypeSessionData | null = sessionService.getSessionData();
      if (sessionData) {
        crossTabMessageService.emitEvent(SEND_SESSION_DATA, JSON.stringify(sessionData));
        crossTabMessageService.emitEvent(LOGIN);
      }

      if (sessionService.isAppian) {
        const impersonatePayloadSaved = sessionService.getImpersonatePayloadFromStore();
        if (impersonatePayloadSaved) {
          impersonateService.setImpersonatePayload(impersonatePayloadSaved);
        }
      }
      return [getIdentity(username)];
    })
  );

export const logoutEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.Logout),
    map((action: { type: string; payload?: ParamsLogoutAction }) => action.payload),
    mergeMap(async (payload?: ParamsLogoutAction) => {
      let urlToRedirectTo;
      let emitEventToTabs: boolean | undefined = true;
      let sendLogoutRequest: boolean | undefined = false;
      if (payload && payload.urlToRedirectTo) {
        urlToRedirectTo = payload.urlToRedirectTo;
      }
      if (payload && payload.hasOwnProperty("emitEventToTabs")) {
        emitEventToTabs = payload.emitEventToTabs;
      }
      if (payload && payload.hasOwnProperty("sendLogoutRequest")) {
        sendLogoutRequest = payload.sendLogoutRequest;
      }

      const tokens: Tokens | null = sessionService.getTokens();
      const email = sessionService.getUserName();

      try {
        if (sendLogoutRequest && tokens) {
          await identityService.logout(email);
        }
      } catch (error) {
        messageService.error("toaster.logout.fail");
        logAPIError(error);
        return logoutFail(urlToRedirectTo);
      } finally {
        sessionService.clearSession();
        rolesService.clearAll();
        sessionActivityWatcherService.reset();
        verifyInvestorService.reset();

        if (emitEventToTabs) {
          crossTabMessageService.emitEvent(CLOSE_SESSION, `caller - logoutEpic`);
          localStorage.clear();
        }
      }

      return logoutSuccess(urlToRedirectTo);
    })
  );
