import React from "react";
import { Tabs } from "antd";

import { ArrayUtils } from "common/shared/utils";

import { PropsBenTabs, BenTabItem } from "./types";
import "./ben-tabs.scss";

const { TabPane } = Tabs;

const BenTabs: React.FC<PropsBenTabs> = (props): React.ReactElement | null => {
  const { items, defaultActiveKey, activeKey, onTabClickHandler } = props;

  if (!ArrayUtils.isValidArrayData(items) || !activeKey) {
    return null;
  }

  return (
    <div className="ben-tabs-content">
      <Tabs
        type="card"
        defaultActiveKey={defaultActiveKey || activeKey}
        activeKey={activeKey}
        onTabClick={onTabClickHandler}
      >
        {items.map((item: BenTabItem) => {
          return (
            <TabPane tab={item.title} key={item.key}>
              {item.content}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
};

export default BenTabs;
