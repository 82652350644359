import React, { Fragment } from "react";
import { Trans } from "@lingui/macro";

import { Logo, Footer } from "common/lib/components";
import { Urls } from "common/lib/constants";

import logo from "common/assets/images/ben-logo-header.svg";
import logoMobile from "common/assets/images/ben-logo-header-m.svg";

interface PropsPublicPageWrapper {
  children: JSX.Element | null;
  isPanelStyle: boolean;
}
const title = <Trans id="public.page.header.title">Welcome to the BEN Alt Access</Trans>;

const PublicPageWrapper = (props: PropsPublicPageWrapper) => {
  const { isPanelStyle } = props;

  return (
    <Fragment>
      <header className="ben-header-main">
        <div className="ben-container">
          <div className="ben-align-self-center ben-pb-2">
            <div className="ben-d-sm-none">
              <Logo
                to={Urls.AUTH.SIGN_IN}
                src={logo}
                width={275}
                alt="Ben, The Beneficient Company Group, LP"
              />
            </div>
            <div className="ben-d-none ben-d-sm-block">
              <Logo
                to={Urls.AUTH.SIGN_IN}
                src={logoMobile}
                width={100}
                alt="Ben, The Beneficient Company Group, LP"
              />
            </div>
          </div>
        </div>
      </header>

      <div className="ben-main-container">
        <div className="ben-container">
          <section className="ben-main-section">
            <header className="ben-main-section-header">
              <h1>{title}</h1>
            </header>
            <div className={`${isPanelStyle ? "ben-panel" : ""} ben-pb-2 ben-mb-3`}>
              {props.children}
            </div>
          </section>
        </div>
      </div>

      <Footer logoUrl={Urls.AUTH.SIGN_IN} />
    </Fragment>
  );
};

export default PublicPageWrapper;
