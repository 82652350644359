import React, { useEffect, useState } from "react";
import { RouteComponentProps, navigate } from "@reach/router";

import {
  crossTabMessageService,
  CROSS_TAB_EVENTS,
  TypeSessionData,
  sessionService,
} from "common/shared";
import { Urls } from "common/lib/constants";
import { useGetAppVersion } from "common/shared-hooks";

import { LoginInfoCard } from "domains/authentication";

import { PublicPageWrapper } from "pages/components";

const { LOGIN } = CROSS_TAB_EVENTS;

const LogoutPage: React.FC<RouteComponentProps> = (props): React.ReactElement => {
  const [pathToNavigate, setPathToNavigate] = useState<string>("");

  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  useEffect(() => {
    const sessionData: TypeSessionData | null = sessionService.getSessionData();
    if (sessionData) {
      navigate(Urls.PROTECTED.BASE);
    }
  }, []);

  useEffect(() => {
    crossTabMessageService.addListener(LOGIN, () => {
      setPathToNavigate(Urls.PROTECTED.BASE);
    });

    return function cleanup() {
      crossTabMessageService.removeListeners(LOGIN);
    };
  }, []);

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <LoginInfoCard pathToNavigate={pathToNavigate} />
    </PublicPageWrapper>
  );
};

export default LogoutPage;
