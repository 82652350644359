import { pushAction } from "common/shared-store/actions/actionsStream";
import {
  ParamsAcceptIdentity,
  AcceptIdentityResponseData,
  ParamsResendWelcomeEmail,
  ParamsRefreshAccessToken,
  ResponseDataRefreshAccessToken,
  ParamsAcceptResetPassword,
  AcceptResetPasswordResponse,
} from "domains/authentication/shared/types";

export const ActionTypes = {
  SignIn: "[Authentication] Sign In",
  SignInSuccess: "[Authentication] Sign In Sucsess",
  SignInFail: "[Authentication] Sign In Fail",
  SignInFailUserLocked: "[Authentication] Sign In Fail User Locked",
  ResetLoginError: "[Authentication] Reset Login Error",
  ForgotPassword: "[Authentication] Forgot Password",
  ForgotPasswordFail: "[Authentication] Forgot Password Fail",
  ForgotPasswordSuccess: "[Authentication] Forgot Password Success",
  ResetPassword: "[Authentication] Reset Password",
  ResetPasswordFail: "[Authentication] Reset Password Fail",
  ResetPasswordSuccess: "[Authentication] Reset Password Success",
  AcceptIdentity: "[Authentication] AcceptIdentity",
  AcceptIdentityFail: "[Authentication] AcceptIdentity Fail",
  AcceptIdentitySuccess: "[Authentication] AcceptIdentity Success",
  ResendWelcomeEmail: "[Authentication] ResendWelcomeEmail",
  ResendWelcomeEmailFail: "[Authentication] ResendWelcomeEmail Fail",
  ResendWelcomeEmailSuccess: "[Authentication] ResendWelcomeEmail Success",
  RefreshAccessToken: "[Authentication] RefreshAccessToken",
  RefreshAccessTokenFail: "[Authentication] RefreshAccessToken Fail",
  RefreshAccessTokenSuccess: "[Authentication] RefreshAccessToken Success",
  AcceptResetPassword: "[Authentication] AcceptResetPassword",
  AcceptResetPasswordFail: "[Authentication] AcceptResetPassword Fail",
  AcceptResetPasswordSuccess: "[Authentication] AcceptResetPassword Success",
};

export const signIn = (data: any) => {
  return {
    type: ActionTypes.SignIn,
    payload: data,
  };
};

export const signInSuccess = (tokens: any) => {
  const action = {
    type: ActionTypes.SignInSuccess,
    payload: tokens,
  };
  pushAction(action);
  return action;
};

export const signInFail = () => {
  const action = {
    type: ActionTypes.SignInFail,
  };
  pushAction(action);
  return action;
};

export const signInFailUserLocked = () => {
  const action = {
    type: ActionTypes.SignInFailUserLocked,
  };
  pushAction(action);
  return action;
};

export const resetLoginError = () => {
  return {
    type: ActionTypes.ResetLoginError,
  };
};

export const forgotPassword = (data: any) => {
  const action = {
    type: ActionTypes.ForgotPassword,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const forgotPasswordSuccess = () => {
  const action = {
    type: ActionTypes.ForgotPasswordSuccess,
  };
  pushAction(action);

  return action;
};

export const forgotPasswordFail = () => {
  const action = {
    type: ActionTypes.ForgotPasswordFail,
  };
  pushAction(action);

  return action;
};

export const resetPassword = (data: any) => {
  return {
    type: ActionTypes.ResetPassword,
    payload: data,
  };
};

export const resetPasswordSuccess = () => {
  const action = {
    type: ActionTypes.ResetPasswordSuccess,
  };
  pushAction(action);
  return action;
};

export const resetPasswordFail = () => {
  const action = {
    type: ActionTypes.ResetPasswordFail,
  };
  pushAction(action);
  return action;
};

// POST AcceptIdentity
export const acceptIdentity = (params: ParamsAcceptIdentity) => {
  const action = {
    type: ActionTypes.AcceptIdentity,
    payload: params,
  };
  pushAction(action);

  return action;
};

export const acceptIdentitySuccess = (data: AcceptIdentityResponseData) => {
  const action = {
    type: ActionTypes.AcceptIdentitySuccess,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const acceptIdentityFail = (error: any) => {
  const action = {
    type: ActionTypes.AcceptIdentityFail,
    payload: error,
  };
  pushAction(action);

  return action;
};

// POST resendWelcomeEmail
export const resendWelcomeEmail = (params: ParamsResendWelcomeEmail) => {
  const action = {
    type: ActionTypes.ResendWelcomeEmail,
    payload: params,
  };
  pushAction(action);

  return action;
};

export const resendWelcomeEmailSuccess = () => {
  const action = {
    type: ActionTypes.ResendWelcomeEmailSuccess,
  };
  pushAction(action);

  return action;
};

export const resendWelcomeEmailFail = () => {
  const action = {
    type: ActionTypes.ResendWelcomeEmailFail,
  };
  pushAction(action);

  return action;
};

export const refreshAccessToken = (data: ParamsRefreshAccessToken) => {
  return {
    type: ActionTypes.RefreshAccessToken,
    payload: data,
  };
};

export const refreshAccessTokenSuccess = (response: ResponseDataRefreshAccessToken) => {
  const action = {
    type: ActionTypes.RefreshAccessTokenSuccess,
    payload: response,
  };
  pushAction(action);
  return action;
};

export const refreshAccessTokenFail = () => {
  const action = {
    type: ActionTypes.RefreshAccessTokenFail,
  };
  pushAction(action);
  return action;
};

// POST AcceptResetPassword
export const acceptResetPassword = (params: ParamsAcceptResetPassword) => {
  return {
    type: ActionTypes.AcceptResetPassword,
    payload: params,
  };
};

export const acceptResetPasswordSuccess = (data: AcceptResetPasswordResponse) => {
  const action = {
    type: ActionTypes.AcceptResetPasswordSuccess,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const acceptResetPasswordFail = (error: any) => {
  const action = {
    type: ActionTypes.AcceptResetPasswordFail,
    payload: error,
  };
  pushAction(action);

  return action;
};
