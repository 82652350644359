import React from "react";
import { Form, Radio } from "antd";

import { Options } from "common/lib/components";
import { useOnLocaleChange } from "common/shared-hooks";

import { PropsExternalAccountTypeRadio } from "./types";
import "./external-account-type-radio.scss";

const ExternalAccountTypeRadio = (props: PropsExternalAccountTypeRadio): React.ReactElement => {
  const { handleChange, activeAccountType } = props;

  const accountTypes = useOnLocaleChange(Options.externalAccountTypesRadio);

  return (
    <Form.Item className="external-account-type-radio">
      <Radio.Group
        name="accountTypeRadio"
        className="external-account-type-radio-group"
        defaultValue={activeAccountType}
        onChange={handleChange}
      >
        {accountTypes}
      </Radio.Group>
    </Form.Item>
  );
};
export default ExternalAccountTypeRadio;
