import React, { Fragment } from "react";
import { Trans } from "@lingui/macro";

import { TextRowInfo } from "common/lib/components/index";

const SecurityInfo = () => {
  return (
    <Fragment>
      <div className="ben-required-text" style={{ paddingTop: 19, paddingBottom: 19 }}>
        <p>
          <Trans id="profile.security.help">
            You will use your email address to sign in to Ben
          </Trans>
        </p>
      </div>
      <TextRowInfo label="profile.security.password">
        <Fragment>********</Fragment>
      </TextRowInfo>
    </Fragment>
  );
};

export default SecurityInfo;
