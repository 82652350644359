import { TypeActionEpicParam } from "common/shared/interfaces/redux";
import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";

import { currencyAPIService } from "common/shared";

import {
  ActionTypes,
  getCurrenciesSuccess,
  getCurrenciesFail,
} from "common/shared-store/actions/currency";
import { CurrencyListResponseData } from "common/shared/interfaces/currency";

export const getCurrenciesEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.GetCurrencies),
    mergeMap(async () => {
      try {
        const response: CurrencyListResponseData = await currencyAPIService.getCurrencies();
        return getCurrenciesSuccess(response);
      } catch (error) {
        return getCurrenciesFail();
      }
    })
  );
};
