import { TypeFileValidationError } from "common/shared/services/file/file.service";
import { TypeLiquidityStatusCode } from "domains/liquidity/shared/types";
import { HoldingItemData } from "domains/liquidity/shared/types";

export type TypeReferenceType = "investment" | "asset" | "account" | "liquidity_request";

export type TypeDocumentType =
  | "private_placement_memorandum"
  | "annual_report"
  | "quarterly_report"
  | "limited_partnership_agreement"
  | "subscription_document"
  | "k1_tax"
  | "capital_call_notice"
  | "distribution_notice"
  | "bottom_up_model"
  | "barq_model"
  | "top_down_model"
  | "z_score_model"
  | "commitment_packet"
  | "term_sheet"
  | "purchase_and_sale"
  | "liquid_trust"
  | "lp_capital_statement"
  | "other"
  | "custom_other_investment"
  | "custom_other_liquidity_request"
  | "unknown"
  | "audited_financials"
  | "exalt_plan";

export type TypeDocumentSource = "appian" | "client_portal" | "system";

export type TypeFilesListUploadingResult =
  | "inProcess"
  | "allSuccess"
  | "allErrors"
  | "hasSomeErrors"
  | "allCancelled";

export enum DocumentTypes {
  private_placement_memorandum,
  annual_report,
  quarterly_report,
  limited_partnership_agreement,
  subscription_document,
  k1_tax,
  capital_call_notice,
  distribution_notice,
  other,
  bottom_up_model,
  barq_model,
  top_down_model,
  z_score_model,
  commitment_packet,
  term_sheet,
  purchase_and_sale,
  liquid_trust,
}

export type TypeDocumentStatus = "success";

export interface DisplaySettingsParams {
  documentType: TypeDocumentType;
  liquidityStatus: TypeLiquidityStatusCode;
}

// Backend responses data
export interface DocumentListResponseData {
  total: number;
  page: {
    index: number;
    size: number;
  };
  items: DocumentItemData[];
}

export interface DocumentItemData {
  id: string;
  investmentId: string;
  assetId: string;
  liquidityRequestId: string;
  dateProvided: string;
  referenceType: TypeReferenceType;
  documentType: TypeDocumentType;
  documentName: string;
  fileName?: string;
  periodEndingDate: string;
  doNotHaveThis: boolean;
  source: TypeDocumentSource;
  displayId: string;
}

export interface TypeUploadDirectoryDocument {
  fileName: string;
  fileId: string;
  fileErrors: TypeFileValidationError[] | null;
}

export interface TypeUploadingFileItemData {
  progress: number;
  isCancelled: boolean;
  errors: TypeFileValidationError[] | null;
  createdData: CreateDocumentResponseData | null;
}

export interface TypeUploadingFileData {
  [key: string]: TypeUploadingFileItemData;
}

export interface DocumentUrlResponseData {
  documentId: string;
  version: number;
  fileName: string;
  url: string;
}

export interface CreateDocumentResponseData {
  documentId: string;
  versionId: string;
  uploadUrl?: string;
  documentName?: string;
}

export interface DocumentTypeDisplaySetting {
  title: string | React.ReactElement;
}

export interface DocumentStatusDisplaySetting {
  iconPath: string;
  text: string | React.ReactElement;
  status?: string | React.ReactElement;
}

/********** Store **********/

export interface DocumentStore {
  transactionDocument: {
    data: DocumentListResponseData;
    loading: boolean;
  };
  holdingDocuments: {
    data: DocumentListResponseData | null;
    loading: boolean;
  };
  uncategorizedDocuments: {
    data: DocumentListResponseData | null;
    loading: boolean;
  };
}

/********** Parameters **********/
export interface ParamsGetDocuments {
  accountId: string;
  requestId: string;
}

export interface ParamsDocumentsByHoldings {
  accountId: string;
  assetIds: string[];
}

export interface ParamsAddTransactionDocument {
  newItem: DocumentItemData;
}

export interface ParamsGetDocumentUrl {
  accountId: string;
  documentId: string;
  currentUserId?: string;
  userDisplayId?: string;
  currentUserFullName?: string;
  currentUserIp?: string;
  locale?: string;
  timezone?: string;
  version?: "latest" | string; // latest, v1, v2, etc.
}

export interface ParamsGetPublicDocumentUrl {
  fileName: string;
  currentUserId: string;
  userDisplayId: string;
  currentUserFullName: string;
  currentUserIp: string;
  locale: string;
  timezone: string;
  version: string;
}

export interface DocumentTypeUpload {
  referenceType?: TypeReferenceType | "";
  documentType?: TypeDocumentType | "";
}

export interface ParamsCreateDocument extends DocumentTypeUpload {
  documentName?: string;
  fileName?: string;
  fileSize?: number;
  investmentId?: string | null;
  assetId?: string | null;
  liquidityRequestId?: string | null;
  accountId: string | null;
  newVersionOfUniqueDocumentConfirmed?: boolean;
  documentId?: string;
  replaceDocumentId?: string;
  doNotHaveThisDocument?: boolean;
  filePath?: string;
}

export interface ParamsConfirmUpload {
  accountId: string | null;
  documentId: string;
  versionId: string;
  reject?: boolean;
  doNotHaveThisDocument?: boolean;
  fileId?: string;
}

// useTransactionDocumentsData
export interface TransactionDocumentsDataProps extends NormalizedDocuments {
  loading: boolean;
}

// useUncategorizedDocumentsData
export interface UncategorizedDocumentsDataProps {
  loading: boolean;
  documents: DocumentItemData[];
}

// useHoldingDocumentsData
export interface HoldingsDocumentsDataProps {
  holdings: Holding[];
  loading: boolean;
}

export interface NormalizedDocuments {
  otherDocuments: DocumentItemData[];
  standardDocuments: {
    [key in TypeDocumentType]: DocumentItemData;
  };
  otherAppianDocuments: DocumentItemData[];
}

// useHoldings
export interface Holding extends HoldingItemData, NormalizedDocuments {
  liquidityRequestId: string;
  accountId: string;
}

// generate Watermark params
export interface TypeGetWatermarkURLParams {
  currentUserId?: string;
  currentUserFullName?: string;
  currentUserIp?: string;
  userDisplayId?: string;
  locale?: string;
  timezone?: string;
}
