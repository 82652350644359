import { useSelector } from "react-redux";
import { UncategorizedDocumentsDataProps } from "domains/document/shared/types";

function useUncategorizedDocumentsData(): UncategorizedDocumentsDataProps {
  const result = useSelector(
    (state: any): UncategorizedDocumentsDataProps => {
      const {
        data: { items },
        loading,
      } = state.document.uncategorizedDocuments;

      return {
        loading,
        documents: items,
      };
    }
  );

  return result;
}

export default useUncategorizedDocumentsData;
