import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "antd";

import { Layout } from "common/lib/constants";
import { Spinner } from "common/lib/components";
import { actions$ } from "common/shared-store/actions/actionsStream";
import { portalEnvConstants } from "common/shared";

import { UncategorizedDocuments } from "domains/document/documents-list";
import { useHoldingDocumentsData, useUncategorizedDocumentsData } from "domains/document/hooks";
import {
  DocumentItemData,
  HoldingsDocumentsDataProps,
  UncategorizedDocumentsDataProps,
} from "domains/document/shared/types";
import { ActionTypes, getDocumentUrl } from "domains/document/store/document.actions";

import { PropsHoldingsDocumentsModal } from "./types";
import { EmptyListTitle, ModalBtnClose, ModalTitle, TriggerBtnTitle } from "./constants";
import "./holdings-documents-modal.scss";
import { getParsedCategorizedDocuments } from "./helpers";

const HoldingsDocumentsModal: React.FC<PropsHoldingsDocumentsModal> = (
  props
): React.ReactElement | null => {
  const dispatch = useDispatch();

  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const {
    accountId,
    isLiquidityLoading,
    currentUserId,
    userDisplayId,
    currentUserFullName,
    currentUserIp,
    locale,
  } = props;

  const handleModalOpen = useCallback((): void => {
    setModalOpen(true);
  }, []);

  const handleModalClose = useCallback((): void => {
    setModalOpen(false);
  }, []);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.GetDocumentUrlFail, ActionTypes.GetDocumentUrlSuccess])
        .subscribe(() => {
          setLoading(false);
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const {
    documents: uncategorizedDocuments,
    loading: uncategorizedDocumentsLoading,
  }: UncategorizedDocumentsDataProps = useUncategorizedDocumentsData();

  const {
    holdings: holdingsWithDocuments,
    loading: isHoldingDocumentsLoading,
  }: HoldingsDocumentsDataProps = useHoldingDocumentsData();

  const handleUncategorizedDownload = useCallback(
    (documentId: string) => () => {
      if (!documentId) {
        return;
      }
      setLoading(true);
      dispatch(
        getDocumentUrl({
          accountId,
          documentId,
          version: "latest",
        })
      );
    },
    [dispatch, accountId]
  );

  const handleCategorizedDownload = useCallback(
    (documentId: string) => () => {
      setLoading(true);

      const params = {
        accountId,
        documentId,
        currentUserId,
        userDisplayId,
        currentUserFullName,
        currentUserIp,
        locale,
        timezone: portalEnvConstants.defaultTimezone,
        version: "latest",
      };
      dispatch(getDocumentUrl(params));
    },
    [dispatch, accountId, currentUserId, userDisplayId, currentUserFullName, currentUserIp, locale]
  );

  const categorizedDocuments: DocumentItemData[] = getParsedCategorizedDocuments(
    holdingsWithDocuments
  );
  const documentsNumber = uncategorizedDocuments.length + categorizedDocuments.length;
  const triggerBtnSuffix = ` (${documentsNumber})`;

  return (
    <Fragment>
      <Button
        id="holdingsDocumentsModalTrigger"
        type="link"
        className="ben-light-btn"
        disabled={uncategorizedDocumentsLoading || isLiquidityLoading || isHoldingDocumentsLoading}
        onClick={handleModalOpen}
      >
        {TriggerBtnTitle} {triggerBtnSuffix}
      </Button>

      <Modal
        title={ModalTitle}
        visible={isModalOpen}
        centered={true}
        width={"100%"}
        style={{
          maxWidth: Layout.MaxWidthModalWindow.standard,
        }}
        wrapClassName="ben-modal-container holdings-documents-modal"
        footer={null}
        onCancel={handleModalClose}
        destroyOnClose={true}
        maskClosable={false}
      >
        <Spinner spinning={isLoading}>
          <Fragment>
            <div className="ben-modal-content ben-pt-0">
              <div className="holdings-documents-content">
                {documentsNumber ? (
                  <Fragment>
                    <UncategorizedDocuments
                      documents={uncategorizedDocuments}
                      handleDownload={handleUncategorizedDownload}
                    />
                    <UncategorizedDocuments
                      documents={categorizedDocuments}
                      handleDownload={handleCategorizedDownload}
                    />
                  </Fragment>
                ) : (
                  <p className="info-message">{EmptyListTitle}</p>
                )}
              </div>
            </div>

            <Button
              type="primary"
              shape="round"
              size="large"
              className="ben-w-100 ben-mb-3"
              onClick={handleModalClose}
              disabled={false}
              id="holdingsDocumentsModalSubmit"
            >
              {ModalBtnClose}
            </Button>
          </Fragment>
        </Spinner>
      </Modal>
    </Fragment>
  );
};

export default HoldingsDocumentsModal;
