import React, { Fragment } from "react";

import { DocumentItemData } from "domains/document/shared/types";
import { BenDocument } from "domains/document/components";

import { PropsOtherAppianTransactionDocuments } from "./types";

const OtherAppianTransactionDocuments: React.FC<PropsOtherAppianTransactionDocuments> = (
  props
): React.ReactElement | null => {
  const { otherAppianDocuments, handleDownload } = props;

  return (
    <Fragment>
      {otherAppianDocuments.map((item: DocumentItemData) => {
        return (
          <BenDocument
            withDragger={false}
            openFileDialogOnClick={false}
            key={item.documentName}
            documentId={item.id}
            title={item.documentName}
            size="small"
            statusIcon="complete"
            handleDownload={handleDownload(item.id)}
          />
        );
      })}
    </Fragment>
  );
};

export default OtherAppianTransactionDocuments;
