import React from "react";
import { Button, Modal } from "antd";

import { sessionService } from "common/shared";
import { Layout } from "common/lib/constants";
import { Spinner } from "common/lib/components";

import { PropsUploadModal } from "./types";
import "./upload-modal.scss";
import {
  HoldingsModalBtnCancel,
  HoldingsModalBtnClose,
  HoldingsModalBtnOk,
  HoldingsModalMessage,
  HoldingsModalTitle,
} from "./constants";

const UploadModal: React.FC<PropsUploadModal> = (props): React.ReactElement | null => {
  const {
    children,
    isModalOpen,
    isDocumentsExist,
    isProcessCompleted,
    isProcessStarted,
    isGlobalCancellationLoading,
    listErrorMessage,
    handleModalCancel,
    handleOnSubmit,
    handleModalCloseAfterCompletion,
    handleGlobalCancelUpload,
  } = props;

  const isLoading = isProcessStarted && !isProcessCompleted;

  let onCancel = handleModalCancel;
  if (isLoading) {
    onCancel = handleGlobalCancelUpload;
  } else if (isProcessCompleted) {
    onCancel = handleModalCloseAfterCompletion;
  }

  return (
    <Modal
      title={HoldingsModalTitle}
      visible={isModalOpen}
      centered={true}
      width={"100%"}
      style={{
        maxWidth: Layout.MaxWidthModalWindow.standard,
      }}
      wrapClassName="ben-modal-container"
      footer={null}
      onCancel={onCancel}
      destroyOnClose={true}
      maskClosable={false}
      closable={!isGlobalCancellationLoading}
    >
      <Spinner spinning={isGlobalCancellationLoading}>
        <div>
          <div className="ben-modal-content ben-pt-0 ben-file-upload-wrapper">
            <div className="ben-required-text ben-mb-3">
              <p className="ben-mb-1">
                <strong className="ben-required-label">{HoldingsModalMessage}</strong>
              </p>
              {listErrorMessage ? <p className="ben-error-text">{listErrorMessage}</p> : null}
            </div>

            <div className="upload-directory--upload-modal-files">{children}</div>
          </div>

          <Button
            type="primary"
            shape="round"
            size="large"
            className="ben-w-100 ben-mb-3"
            onClick={isProcessCompleted ? handleModalCloseAfterCompletion : handleOnSubmit}
            disabled={sessionService.isAppian || !isDocumentsExist || Boolean(listErrorMessage)}
            id="uploadDirectoryModalSubmit"
            loading={isLoading}
          >
            {isProcessCompleted ? HoldingsModalBtnClose : HoldingsModalBtnOk}
          </Button>

          {!isProcessCompleted ? (
            <Button
              type="primary"
              shape="round"
              ghost={true}
              size="large"
              className="ben-w-100"
              onClick={onCancel}
              id="uploadDirectoryModalCancel"
            >
              {HoldingsModalBtnCancel}
            </Button>
          ) : null}
        </div>
      </Spinner>
    </Modal>
  );
};

export default UploadModal;
