import React from "react";

import { Trans } from "@lingui/react";
import { Urls } from "common/lib/constants";
import { NavLink, IconCustom } from "common/lib/components";
import { PropsMainNav } from "./types";

const MainNav: React.FC<PropsMainNav> = (props): React.ReactElement | null => {
  const { closeDrawerOnLInk, mainUrl } = props;

  return (
    <nav className="ant-tabs-nav">
      <NavLink to={mainUrl} className="ant-tabs-tab ben-link" onClick={closeDrawerOnLInk}>
        <IconCustom type="dashboard" />
        <Trans id="pages.main.nav.dashboard">Dashboard</Trans>
      </NavLink>
      <NavLink
        to={Urls.PROTECTED.RESOURCES}
        className="ant-tabs-tab ben-link"
        onClick={closeDrawerOnLInk}
      >
        <IconCustom type="books" />
        <Trans id="pages.main.nav.resources">Resources</Trans>
      </NavLink>
      <NavLink
        to={Urls.PROTECTED.PROFILE}
        className="ant-tabs-tab ben-link"
        onClick={closeDrawerOnLInk}
      >
        <IconCustom type="settings" />
        <Trans id="pages.main.nav.profile">Account</Trans>
      </NavLink>
      <NavLink
        to={Urls.PROTECTED.HELP}
        className="ant-tabs-tab ben-link"
        onClick={closeDrawerOnLInk}
      >
        <IconCustom type="question" />
        <Trans id="pages.main.nav.help">Help</Trans>
      </NavLink>
    </nav>
  );
};

export default MainNav;
