import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";
import { t } from "@lingui/macro";

import { actions$ } from "common/shared-store/actions/actionsStream";
import { messageService, localeService } from "common/shared";
import { BenCollapseLoaderStub } from "common/lib/components";
import { DomUtils } from "common/shared/utils";

import { useClientData } from "domains/clients/hooks";
import Institution from "domains/clients/store/institution.model";
import User from "domains/clients/store/user.model";

import { ActionTypes, getClient } from "domains/clients/store/clients.actions";
import PrincipalInformation from "../principal-information";
import InstitutionInformation from "../institution-information/institution-information";

import { PropsClientDetailsPanel } from "./types";

import downIcon from "common/assets/images/icons/collapse-arrow-down.svg";
import upIcon from "common/assets/images/icons/collapse-arrow-up.svg";

const currentCollapseKey = "1";

function ClientDetailsPanel({ accountId, isExpanded }: PropsClientDetailsPanel) {
  const [activeKey, setActiveKey] = useState<string[]>(isExpanded ? [currentCollapseKey] : []);
  const [isEdit, setEditMode] = useState(false);
  const [isUpdating, setUpdateStatus] = useState(false);
  const panelRef = useRef<any>();

  const dispatch = useDispatch();
  const { client, loading } = useClientData();
  const clientAccountId = (client && client.accountId) || null;

  useEffect(() => {
    if (clientAccountId && clientAccountId === accountId) {
      return;
    }
    dispatch(getClient({ accountId }));
  }, [dispatch, accountId, clientAccountId]);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([
          ActionTypes.EditInstitutionalClientFail,
          ActionTypes.EditPrincipalClientFail,
          ActionTypes.EditInstitutionalPrimaryFail,
        ])
        .subscribe((action: any) => {
          if (action.type === ActionTypes.EditPrincipalClientFail && !action.payload.response) {
            messageService.error(localeService.i18n._(t`client.update.error`));
          }
          setUpdateStatus(false);
        }),
      actions$
        .ofType([
          ActionTypes.EditInstitutionalClientSuccess,
          ActionTypes.EditPrincipalClientSuccess,
          ActionTypes.EditInstitutionalPrimarySuccess,
        ])
        .subscribe((action: any) => {
          setUpdateStatus(false);
          setEditMode(false);
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const toggleCollapse = useCallback(() => {
    setActiveKey(activeKey.length === 0 ? [currentCollapseKey] : []);
  }, [activeKey.length]);
  const toggleEditMode = () => setEditMode((editMode: boolean) => !editMode);
  const handleCollapseClick = useCallback(() => {
    if (activeKey.length) {
      DomUtils.scrollIntoView(panelRef.current);
    }
    toggleCollapse();
  }, [toggleCollapse, activeKey.length]);
  const onEditClick = () => {
    toggleEditMode();
    setActiveKey([currentCollapseKey]);
  };

  const isInstitution = client instanceof Institution;
  const isNecessaryDataExist = Boolean(client && !loading);

  return (
    <div className="ben-panel ben-pb-0 ben-mb-4" ref={panelRef}>
      <div className="ben-client-details">
        {isNecessaryDataExist &&
          (isInstitution ? (
            <InstitutionInformation
              client={client as Institution}
              isEdit={isEdit}
              isUpdating={isUpdating}
              handleSwitchUpdateStatus={setUpdateStatus}
              handleSwitchEditMode={toggleEditMode}
              handleOnEditClick={onEditClick}
              activeKey={activeKey}
              currentCollapseKey={currentCollapseKey}
            />
          ) : (
            <PrincipalInformation
              client={client as User}
              isEdit={isEdit}
              isUpdating={isUpdating}
              handleSwitchUpdateStatus={setUpdateStatus}
              handleSwitchEditMode={toggleEditMode}
              handleOnEditClick={onEditClick}
              activeKey={activeKey}
              currentCollapseKey={currentCollapseKey}
              isShowCombinedAddress={true}
              isShowCombinedName={true}
              isShowCombinedPhone={true}
            />
          ))}

        {!isNecessaryDataExist && <BenCollapseLoaderStub />}

        <Button
          id="benClienIdBtnCollapse"
          size="small"
          className="collapse"
          type="primary"
          shape="circle"
          onClick={handleCollapseClick}
          disabled={!isNecessaryDataExist}
        >
          <img src={activeKey.length === 0 ? downIcon : upIcon} alt="" />
        </Button>
      </div>
    </div>
  );
}

export default ClientDetailsPanel;
