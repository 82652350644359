import React from "react";
import { Trans } from "@lingui/macro";

import { CardHelp, ContactInfo } from "common/lib/components";
import { BenInfo } from "common/lib/constants";
import { portalEnvConstants } from "common/shared";
import { useGetAppVersion } from "common/shared-hooks";

const PortalHelpPage: React.FC = (): React.ReactElement | null => {
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  const titlePage = <Trans id="portalHelp.titlePage">Help</Trans>;
  const subtitle = (
    <Trans id="portalHelp.subtitle">
      For assistance with submitting or managing a Liquidity Request, please contact your
      Originations Representative. You may also contact us via:
    </Trans>
  );

  return (
    <section className="ben-main-section">
      <header className="ben-main-section-header">
        <h1>{titlePage}</h1>
      </header>
      <CardHelp subtitle={subtitle}>
        <ContactInfo
          isFullPhoneLabel={false}
          isFullEmailLabelto={false}
          tel={portalEnvConstants.defaultPhoneNumber}
          email={BenInfo.EMAILS.portalHelp}
        />
      </CardHelp>
    </section>
  );
};

export default PortalHelpPage;
