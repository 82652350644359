import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Spinner, BenCardContent } from "common/lib/components";
import { ArrayUtils } from "common/shared/utils";
import { sessionService } from "common/shared";
import { actions$ } from "common/shared-store/actions/actionsStream";
import {
  setAcceptedRequestSharingData,
  setNavigateToAfterLogin,
} from "common/shared-store/actions/bootstrap";
import { useBootstrapSharingData } from "common/shared-hooks";

import { ROLE_TYPES } from "common/shared/services/roles/roles.service";

import {
  ActionTypes,
  createLiquiditySharing,
  getLiquiditySharings,
  updateLiquiditySharing,
} from "domains/liquidity/store/liquidity.actions";
import { InviteItem } from "domains/liquidity/components";
import { InviteModal } from "domains/liquidity/modals";
import { useCreateLiquiditySharingData, useLiquiditySharingsData } from "domains/liquidity/hooks";
import {
  TypeInvitedObject,
  PossibleInvitedAccount,
  ParamsCreateLiquiditySharing,
  CreateLiquiditySharingDataProps,
  LiquiditySharingsDataProps,
  SharingItemData,
} from "domains/liquidity/shared/types";
import { AcceptRequestSharingData } from "domains/liquidity";

import { PropsInvitesList } from "./types";
import { TitleInvitesList } from "./constants";

const InvitesList: React.FC<PropsInvitesList> = (props): React.ReactElement | null => {
  const {
    accountId,
    requestId,
    liquidityLoading,
    possibleInvitedAccounts,
    possibleInvitedAccountsLoading,
    userFullName,
    userLoading,
    sessionRole,
    requestAuthorName,
  } = props;
  const [chosenInvitedAccount, setChosenInvitedAccount] = useState<PossibleInvitedAccount | null>(
    null
  );
  const dispatch = useDispatch();
  const isClient = sessionRole !== ROLE_TYPES.ADVISOR;

  const acceptRequestSharingData: AcceptRequestSharingData | null = useBootstrapSharingData();
  const {
    loading: createSharingLoading,
  }: CreateLiquiditySharingDataProps = useCreateLiquiditySharingData();

  const {
    sharings,
    loading: sharingsLoading,
  }: LiquiditySharingsDataProps = useLiquiditySharingsData();

  const isAlreadyShared = ArrayUtils.isValidArrayData(sharings);
  const acceptedSharings =
    sharings &&
    isAlreadyShared &&
    sharings.filter((sharing: SharingItemData) => {
      return sharing.status === "accepted";
    });
  const isAcceptedSharingExist = ArrayUtils.isValidArrayData(acceptedSharings);

  // send accept invitation request
  useEffect(() => {
    if (!acceptRequestSharingData || !isClient) {
      dispatch(setAcceptedRequestSharingData(null));
      dispatch(setNavigateToAfterLogin({ path: "" }));
      return;
    }

    // TODO: reset all bootstrap
    dispatch(updateLiquiditySharing(acceptRequestSharingData));
    dispatch(setAcceptedRequestSharingData(null));
  }, [dispatch, acceptRequestSharingData, isClient]);

  // Runs after sending request
  useEffect(() => {
    if (!requestId || sharingsLoading) {
      return;
    }
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.UpdateLiquiditySharingFail, ActionTypes.UpdateLiquiditySharingSuccess])
        .subscribe(() => {
          if (isAcceptedSharingExist) {
            return;
          }
          dispatch(getLiquiditySharings({ requestId }));
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [dispatch, requestId, sharingsLoading, isAcceptedSharingExist]);

  useEffect(() => {
    if (!requestId) {
      return;
    }
    dispatch(getLiquiditySharings({ requestId }));
  }, [dispatch, requestId]);

  const handleModalVisibility = useCallback(
    (account: PossibleInvitedAccount | null) => {
      return (): void => {
        setChosenInvitedAccount(account);
      };
    },
    [setChosenInvitedAccount]
  );

  const handleSubmitInvitation = useCallback(() => {
    if (sessionService.isAppian) {
      return;
    }

    if (!chosenInvitedAccount || !requestId) {
      return;
    }

    const invitePayload: ParamsCreateLiquiditySharing = {
      requestId,
      sharedWithAccountId: chosenInvitedAccount.accountId,
      sharedWithUserId: chosenInvitedAccount.id,
      sharedWithUserName: chosenInvitedAccount.displayName,
      sharedWithUserEmail: chosenInvitedAccount.email,
      status: "submitted",
    };
    dispatch(createLiquiditySharing(invitePayload));
    setChosenInvitedAccount(null);
  }, [dispatch, setChosenInvitedAccount, chosenInvitedAccount, requestId]);

  if ((!accountId && !liquidityLoading) || !requestId) {
    return null;
  }

  const isSharingAvailable = Boolean(
    ArrayUtils.isValidArrayData(possibleInvitedAccounts) && !isClient
  );
  const invitedObjectType: TypeInvitedObject = isClient ? "advisor" : "client";

  return (
    <BenCardContent
      title={TitleInvitesList}
      isLoading={
        liquidityLoading || possibleInvitedAccountsLoading || userLoading || sharingsLoading
      }
    >
      <Spinner spinning={createSharingLoading}>
        <div className="invites-list">
          {userFullName && <InviteItem title={userFullName} invitedObjectType="you" />}

          {requestAuthorName &&
            isAcceptedSharingExist &&
            acceptedSharings &&
            acceptedSharings.map((item: SharingItemData) => {
              return (
                <InviteItem
                  key={item.createdAt}
                  title={isClient ? requestAuthorName : item.sharedWithUserName}
                  invitedObjectType={invitedObjectType}
                  handleInitSharing={
                    isSharingAvailable && possibleInvitedAccounts
                      ? handleModalVisibility(possibleInvitedAccounts[0])
                      : undefined
                  }
                />
              );
            })}

          {!isAcceptedSharingExist &&
            isSharingAvailable &&
            possibleInvitedAccounts &&
            possibleInvitedAccounts.map((item: PossibleInvitedAccount) => {
              return (
                <InviteItem
                  key={item.id}
                  title={item.displayName}
                  invitedObjectType={invitedObjectType}
                  handleInitSharing={handleModalVisibility(item)}
                />
              );
            })}
        </div>
      </Spinner>
      <InviteModal
        handleModalClose={handleModalVisibility(null)}
        isVisible={Boolean(chosenInvitedAccount)}
        invitedObjectType={invitedObjectType}
        invitedAccount={chosenInvitedAccount}
        handleSubmit={handleSubmitInvitation}
        disableSubmit={sessionService.isAppian}
      />
    </BenCardContent>
  );
};

export default InvitesList;
