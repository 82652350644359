import React, { useCallback, useEffect, useState } from "react";
import { Upload, Button, Icon } from "antd";
import { RcFile } from "antd/lib/upload";
import { Trans } from "@lingui/macro";

import { DragFileHelpMessage } from "domains/document/components";

import { PropsUploadTrigger } from "./types";

const BtnTitle = (
  <Trans id="documents.uploadDirectory.trigger.btn.title">
    Documentation: drag and drop files or folders
  </Trans>
);

const UploadTrigger: React.FC<PropsUploadTrigger> = (props): React.ReactElement | null => {
  const [files, setFiles] = useState<RcFile[]>([]);
  const [isUpdating, setUpdating] = useState<boolean>(false);
  const [isFinish, setFinish] = useState<boolean>(false);

  const { handleModalOpen, uniqueId, isModalOpen } = props;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isModalOpen && !isUpdating) {
      setFinish(false);
      setFiles([]);
      setUpdating(false);
    }
    // isFinish is set to true when the last item is uploaded
    if (!isFinish || isModalOpen || !files.length) {
      return;
    }
    handleModalOpen(files);
  }, [isFinish, isModalOpen]);

  const onBeforeUploadHandler = useCallback(
    (currentFile: RcFile, fileList: RcFile[]) => {
      if (fileList.length === 1) {
        // 1. Dragger flow
        setUpdating(true);
        setFiles((oldFiles: RcFile[]) => [...oldFiles, currentFile]);
        setUpdating(false);

        // Check for calling the last file
        setTimeout(() => {
          setUpdating((current) => {
            if (!current) {
              setFinish(true);
            }
            return current;
          });
        }, 300);

        return false;
      } else {
        // 2. Click flow
        // This function is being called for each file.
        // In this flow we have all files in the first call.
        if (currentFile.uid !== fileList[0].uid) {
          return false;
        }

        handleModalOpen(fileList);

        return false;
      }
    },
    [handleModalOpen]
  );

  return (
    <Upload.Dragger
      directory={true}
      showUploadList={false}
      className={"ben-upload-document ben-dragged-content upload-directory--upload-trigger"}
      disabled={false}
      beforeUpload={onBeforeUploadHandler}
      multiple={true}
    >
      <Button
        id={`uploadDirectoryTriggerBtn_${uniqueId}`}
        type="link"
        size="small"
        className="ben-upload-link ben-btn-reset ben-dragged-trigger"
        disabled={false}
      >
        <Icon type="plus" style={{ fontSize: 20 }} /> {BtnTitle}{" "}
        <DragFileHelpMessage withClickAction={false} />
      </Button>
    </Upload.Dragger>
  );
};

export default UploadTrigger;
