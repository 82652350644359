import { TypeActionEpicParam } from "common/shared/interfaces/redux";
import { ofType } from "redux-observable";
import { mergeMap } from "rxjs/operators";

import { apiService } from "common/shared";

import {
  ActionTypes,
  getVersionSuccess,
  getVersionFail,
} from "common/shared-store/actions/version";

export const getVersionEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.GetVersion),
    mergeMap(async () => {
      try {
        const response: any = await apiService.getVersion();

        return getVersionSuccess(response);
      } catch (error) {
        return getVersionFail();
      }
    })
  );
};
