import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { Action } from "redux";
import { ArrayUtils } from "common/shared/utils";

const ActionSubjectConstructor = Subject as any;
export const actions$ = new ActionSubjectConstructor();
// const subscription = actions$.subscribe((a: Action) => a);

export const pushAction = (action: Action) => {
  actions$.next(action);
};

ActionSubjectConstructor.prototype.ofType = function(type: string | string[]) {
  if (ArrayUtils.isValidArrayData(type)) {
    return this.pipe(filter((action: Action) => type.indexOf(action.type) >= 0));
  }

  return this.pipe(filter((action: Action) => action.type === type));
};
