import React, { Fragment } from "react";
import { Trans } from "@lingui/react";

const AgreementContent: React.FC = (): React.ReactElement => {
  return (
    <Fragment>
      <p className="agreement-textIndent">
        <Trans id="AgreementModal.new.Row1">
          THIS CLICK-THROUGH LICENSE, MUTUAL NON-DISCLOSURE AND APPOINTMENT AGREEMENT (“AGREEMENT”)
          IS A BINDING LEGAL AGREEMENT BY AND BETWEEN (I) YOU AND THE INDIVIDUAL, ENTITY OR ENTITIES
          ON WHOSE BEHALF YOU ARE ACCESSING OR USING THE ALTACCESS PORTAL (AS DEFINED BELOW) OR
          PROVIDING OR RECEIVING ANY CONFIDENTIAL INFORMATION (AS DEFINED BELOW), ON THE ONE HAND
          (“YOU,” “YOUR” OR, THE “CUSTOMER”), AND (II) THE BENEFICIENT COMPANY GROUP, L.P. (“BEN,”
          TOGETHER WITH THE CUSTOMER, THE “PARTIES”).
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="AgreementModal.new.Row2">
          BY CLICKING “I AGREE”, YOU REPRESENT AND WARRANT YOU HAVE FULL LEGAL AUTHORITY TO ENTER
          INTO THIS AGREEMENT ON BEHALF OF THE CUSTOMER. THIS AGREEMENT IS A MATERIAL CONSIDERATION
          FOR EACH PARTY PROVIDING THE OTHER PARTY ANY CONFIDENTIAL INFORMATION SO PLEASE READ THIS
          AGREEMENT CAREFULLY BEFORE CLICKING “I AGREE.” IF YOU DO NOT CLICK “I AGREE,” YOU MAY NOT
          ACCESS THE ALTACCESS PORTAL OR BEN’S CONFIDENTIAL INFORMATION. IF YOU DO NOT AGREE WITH
          ANY PROVISION IN THIS AGREEMENT, DO NOT CLICK “I AGREE.”
        </Trans>
      </p>
      <br />
      <p
        className="agreement-textIndent"
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}
      >
        <Trans id="AgreementModal.new.Row2.1">RECITALS</Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row3.Heading">WHEREAS,</Trans>
        </span>
        <Trans id="AgreementModal.new.Row4">
          in connection with evaluating a potential liquidity transaction or series of liquidity
          transactions (the “Transaction”) between the Parties, you desire to be granted access and
          use of the AltAccess Portal and to appoint Ben (including its subsidiaries) as
          Administrator (as defined below) and Document Custodian (as defined below) with respect to
          certain data and materials you may deliver to Ben subject to the terms and conditions of
          this Agreement, and Ben desires to grant you access and use of the AltAccess Portal and
          accept its appointment as Administrator and Document Custodian;
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row4.Heading">WHEREAS,</Trans>
        </span>
        <Trans id="AgreementModal.new.Row5">
          in order to facilitate the evaluation of the Transaction, each Party (in such capacity,
          the “Disclosing Party”) can share Confidential Information (as defined below) with the
          other Party (in such capacity, the “Receiving Party”), and as a condition to each
          Disclosing Party’s release of such Confidential Information, the Parties wish to enter
          into this Agreement; and
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row5.Heading">NOW, THEREFORE,</Trans>
        </span>
        <Trans id="AgreementModal.new.Row6">
          in consideration of the mutual promises herein, the Parties agree as follows:
        </Trans>
      </p>
      <br />
      <p
        className="agreement-textIndent"
        style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}
      >
        <Trans id="AgreementModal.new.Row3.1">AGREEMENT</Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        1.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row6.Heading">License to Use Ben AltAccess Portal.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row7">
          “AltAccess Portal” means the user interface and object code form of computer programs
          hosted by Ben and made available to you. Subject to your compliance with the terms and
          conditions of this Agreement, Ben grants you a limited, non-exclusive, non-transferable,
          license to access, execute and use the AltAccess Portal for purposes of the Transaction
          and to upload and download Confidential Information and other documentation. The results
          generated and displayed by the AltAccess Portal will be considered the Confidential
          Information of Ben, except to the extent they incorporate your own Confidential
          Information. You may not, directly or indirectly, sell, sublicense, display, timeshare,
          loan, lease, distribute, or create derivative works of the AltAccess Portal or the results
          generated by the AltAccess Portal. You will not, and will not allow any third party to,
          tamper with, modify, decompile, disassemble, derive the source code of, reverse engineer,
          or attempt to obtain the internal design of the AltAccess Portal or its pricing tools for
          any purpose whatsoever. Use of the AltAccess Portal may be suspended by Ben, in its sole
          discretion, if you exceed the scope of your license, or you materially breach any term of
          this Agreement.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        2.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row7.Heading">Confidential Information.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row8">
          For purposes of this Agreement, “Confidential Information” means all information
          concerning the Disclosing Party or any of its Representatives (as defined below) obtained
          by the Receiving Party or its Representatives, in connection with the Transaction, and
          irrespective of form or medium of communication, including all written, oral and
          electronic communications, together with any notes, analyses, reports, models,
          projections, compilations, studies, interpretations, demonstrations, documents or records
          only to the extent containing, referring, or derived from such information, in whole or in
          part. Notwithstanding the foregoing, Confidential Information does not include information
          that (i) is published or generally available other than through an action or inaction by
          the Receiving Party or its Representatives in breach of this Agreement, (ii) was within
          the Receiving Party’s or its Representative’s possession prior to the date of this
          Agreement from a source other than the Disclosing Party or its Representatives, or (iii)
          is received by the Receiving Party or its Representatives from a source other than the
          Disclosing Party or any of its Representatives; provided that, in the case of clauses (ii)
          and (iii) above, the Receiving Party does not have knowledge that such source is bound by
          a confidentiality agreement with, or other obligation of secrecy to, the Disclosing Party
          or otherwise prohibited from transmitting the information to the Receiving Party by law,
          contractual obligation, fiduciary duty or otherwise. The term “Representative” shall mean,
          with respect to any person, such person’s affiliates and its affiliates’ directors,
          officers, managers, employees, financial advisors, attorneys, agents, accountants, and
          consultants
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        3.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row8.Heading">
            Appointment of Administrator and Document Custodian
          </Trans>
        </span>
        <Trans id="AgreementModal.newn.Row9">
          Subject to the terms of this Agreement, you hereby: (i) appoints Ben as your administrator
          (“Administrator”) with respect to certain administrative services necessary or attendant
          to the evaluation of the Transaction and (ii) document custodian (“Document Custodian”)
          with respect to your Confidential Information that you provide to Ben that is necessary or
          attendant to the evaluation of the Transaction. You hereby represent and warrant that you
          have the full power and authority (including obtaining all necessary consents and
          authorizations) to provide your Confidential Information to Ben.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        4.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row9.Heading">
            Use and Disclosure of Confidential Information.
          </Trans>
        </span>
        <Trans id="AgreementModal.new.Row10">
          The Receiving Party and its Representatives shall use the Confidential Information in
          connection with the Transaction and not for any other means or purpose. The Receiving
          Party agrees to maintain the confidentiality of Confidential Information using the same
          degree of care as the Receiving Party would use to safeguard its own confidential
          information of like importance; provided that, in no event shall the Receiving Party
          exercise less than a reasonable degree of care to protect the Confidential Information,
          and the Receiving Party agrees to not disclose, or cause or permit to be disclosed in any
          manner whatsoever, to any person or entity, Confidential Information without the prior
          written consent of the Disclosing Party; provided further that, the Receiving Party may
          disclose Confidential Information to its Representatives who (i) need to know the
          Confidential Information in connection with the Transaction and (ii) are directed by the
          Receiving Party to comply with the terms of this Agreement as if they were parties hereto.
          The Receiving Party shall (i) take commercially reasonable measures, and cause its
          Representatives to take commercially reasonable measures, to protect the confidentiality
          of the Confidential Information and, (ii) be responsible for any violation of this
          Agreement by its Representatives; and (iii) take commercially reasonable measures to
          restrain its Representatives from prohibited or unauthorized disclosure or use of the
          Confidential Information. Each Party will promptly notify the other Party upon discovery
          of any unauthorized use or disclosure of the Confidential Information, or any other breach
          of this Agreement by such Party or any of its Representatives, and shall reasonably
          cooperate with the other Party (at the other Party’s sole expense) to help the other Party
          regain possession of the Confidential Information and prevent its unauthorized use or
          further disclosure.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        5.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row10.Heading">Compelled Disclosure.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row11">
          In the event that the Receiving Party or its Representatives are compelled to disclose all
          or part of the Confidential Information by applicable law, regulation, order or decree, or
          where the failure to disclose could subject the Receiving Party or any of its
          Representatives to contempt or other sanction, the Receiving Party agrees to (i) promptly
          notify the Disclosing Party (to the extent legally permissible) of the existence, terms
          and circumstances surrounding such requirement in order to enable the Disclosing Party (at
          the Disclosing Party’s sole expense) to seek an appropriate protective order or other
          remedy or waive compliance, in whole or in part, with the non-disclosure terms of this
          Agreement as to such required disclosure; (ii) reasonably consult and cooperate with the
          Disclosing Party to the fullest extent permitted by law with respect to taking legally
          available steps to resist or narrow such (at the Disclosing Party’s sole expense); and
          (iii) in the absence of a protective order, disclose to such court or governmental body
          only such portion of the Confidential Information, which upon the advice of counsel, is
          legally required to be disclosed and exercise its reasonable best efforts to obtain an
          order or other reliable assurance that confidential treatment will be accorded to such
          portion of the disclosed information which the Disclosing Party so designates.
          Notwithstanding the foregoing, notice to the Disclosing Party shall not be required where
          disclosure is made in connection with an audit, investigation or examination by a
          regulatory or self-regulatory authority of the Receiving Party.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        6.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row11.Heading">Return or Destruction of Documents.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row12">
          Upon written notice from the Disclosing Party, the Receiving Party and its Representatives
          will destroy all copies of the Confidential Information in the Receiving Party’s or its
          Representative’s possession or control; provided that, the Receiving Party and its
          Representatives (i) shall not be required to delete, erase or destroy any
          electronically-stored Confidential Information contained in an archived computer backup
          system stored as a result of automated backup procedures in the ordinary course; (ii) may
          retain copies of the Confidential Information to the extent and for so long as such
          retention and access by the Receiving Party is required by federal, state, or local law,
          by regulation, or by its policies or procedures; and (iii) shall not be required to
          destroy any Confidential Information (including valuation models and other financial or
          risk analysis) developed for internal valuation, underwriting and risk management.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        7.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row12.Heading">Title and Proprietary Rights.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row13">
          All right, title and interest in and to the AltAccess Portal, any proprietary methods,
          algorithms, and results generated via the AltAccess Portal, and any derivative works
          thereof, whether created by Ben, you or a third party, will remain at all times solely and
          exclusively owned by Ben. In accordance with the other provisions of this Agreement, you
          hereby grant a perpetual and non-exclusive license to Ben to copy, use and modify any
          documentation, materials and information you upload to the AltAccess Portal for purposes
          of the Transaction. Notwithstanding the disclosure of any Confidential Information by the
          Disclosing Party to the Receiving Party, the Disclosing Party shall retain title and all
          intellectual property and proprietary rights thereto, and the Receiving Party will have no
          rights, by license or otherwise, to use the Confidential Information except as expressly
          provided herein.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        8.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row13.Heading">
            No Obligation, Representation or Warranty.
          </Trans>
        </span>
        <Trans id="AgreementModal.new.Row14">
          The AltAccess Portal is provided “AS-IS,” with no express or implied warranties of any
          kind, including the warranties of merchantability, fitness for a particular purpose, data
          integrity, or non-infringement. Except as may be set forth in a definitive written
          agreement providing for the Transaction executed and delivered by the Parties, the
          Receiving Party understands and acknowledges that neither the Disclosing Party nor any of
          its Representatives has made or is making, and the Receiving Party is not relying on, any
          representation or warranty, express or implied, as to the timeliness, accuracy or
          completeness of the AltAccess Portal or the Confidential Information. The Receiving Party
          further agrees that neither the Disclosing Party nor any of its Representatives shall have
          any liability to the Receiving Party or to any of its Representatives relating to or
          resulting from the use or non-use of the AltAccess Portal or the Confidential Information
          or any errors therein, and neither the Receiving Party nor its Representatives will assert
          any claims whatsoever against such persons, with respect to, or arising out of, the
          AltAccess Portal or the Confidential Information. Each Party agrees that unless and until
          a definitive written agreement between the Parties providing for the Transaction has been
          executed and delivered, neither Party nor any of its Representatives is under any legal,
          fiduciary or other duty or obligation to the other Party of any kind whatsoever with
          respect to the Transaction by virtue of this Agreement except as specifically provided
          herein.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        9.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row14.Heading">Remedies for Breach.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row15">
          Each Party acknowledges that money damages and remedies at law may not be a sufficient
          remedy for any breach or threatened breach of this Agreement by the other Party or any of
          its Representatives, and each Party agrees that the injured Party may be irreparably
          harmed in the case of any such breach or threatened breach, and each Party agrees that the
          injured Party will be entitled to seek specific performance and injunctive or other
          equitable relief. Such remedies shall not be deemed to be the exclusive remedies for a
          breach or threatened breach but shall be in addition to all other remedies available at
          law or equity to the injured Party.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        10.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row15.Heading">Governing Law.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row16">
          This Agreement is to be governed by, and construed and enforced in accordance with, the
          internal laws of the State of Delaware, without regard to its rules of conflict of laws.
          The Parties consent to personal jurisdiction in the state and federal courts located in
          the State of Delaware and voluntarily and irrevocably submit to the personal jurisdiction
          of the courts of the State of Delaware in any action or proceeding with respect to this
          Agreement. EACH OF THE PARTIES HEREBY IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL BY
          JURY IN CONNECTION WITH ANY DISPUTE CLAIM OR CONTROVERSY ARISING OUT OF OR RELATING TO
          THIS AGREEMENT.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        11.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row16.Heading">Severability.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row17">
          The provisions of this Agreement shall be severable in the event that any of the
          provisions hereof are held by a court or arbitrator of competent jurisdiction to be
          invalid, void or otherwise unenforceable, and the remaining provisions shall remain
          enforceable to the fullest extent permitted by law.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        12.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row17.Heading">Assignment.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row18">
          This Agreement may not be assigned by either Party without the prior written consent of
          the other Party; provided that, Ben may assign this Agreement, without your written
          consent, to its parent company, a company wholly owned by or controlled by or under the
          common control with Ben, or an entity acquiring all or substantially all of the assets of
          Ben.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        13.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row18.Heading">Securities Laws.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row19">
          You acknowledge that the Confidential Information of Ben may contain material, non-public
          information. You further acknowledge and are aware that the U.S. securities laws prohibit
          any person or firm who has material, non-public information from purchasing or selling
          securities in reliance on such information, or from communicating such information to any
          other person or firm under circumstances in which it is reasonably foreseeable that such
          person or firm is likely to purchase or sell securities of such corporation in reliance on
          such information.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        14.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row19.Heading">No Waiver of Rights.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row20">
          It is understood and agreed that no failure or delay by either Party in exercising any
          right, power or privilege hereunder shall operate as a waiver thereof, nor shall any
          single or partial exercise thereof preclude any other or future exercise thereof or the
          exercise of any other right, power or privilege.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        15.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row20.Heading">Termination Upon Public Disclosure.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row21">
          This Agreement shall terminate with no further action on the part of you or Ben once there
          has been public disclosure of the Confidential Information in a manner that complies with
          the requirements of Regulation FD under the Securities Exchange Act of 1934, as amended.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        16.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row21.Heading">Entire Agreement.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row22">
          This Agreement contains the entire agreement between the Parties concerning the subject
          matter of this Agreement, and no provision of this Agreement may be amended or modified,
          in whole or in part, nor any waiver or consent given, unless approved in writing by the
          Parties in the case of an amendment or modification or by the Party to be charged in the
          case of a waiver or consent, which writing specifically refers to this Agreement and the
          provision so amended or modified or for which such waiver or consent is given.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        17.
        <span className="ben-important-text section-Margin">
          <Trans id="AgreementModal.new.Row22.Heading">Construction.</Trans>
        </span>
        <Trans id="AgreementModal.new.Row23">
          The Parties agree that any rule of construction to the effect that ambiguities are to be
          resolved against the drafting Party shall not be applied in the construction or
          interpretation of this Agreement.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="AgreementModal.new.Row24">
          This Agreement shall become effective and binding on you and Ben immediately upon you
          clicking “I Agree” below.
        </Trans>
      </p>
    </Fragment>
  );
};

export default AgreementContent;
