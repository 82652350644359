import React from "react";
import { Trans } from "@lingui/react";

import { PropsDragFileHelpMessage } from "./types";

const DragFileHelpMessage: React.FC<PropsDragFileHelpMessage> = (props): React.ReactElement => {
  const { withClickAction } = props;

  if (withClickAction) {
    return (
      <span className="ben-document-title-draggable-info">
        <Trans id="documents.draggableClickableZone.tooltip">
          Click or drag file to this area to upload.
        </Trans>
      </span>
    );
  }

  return (
    <span className="ben-document-title-draggable-info">
      <Trans id="holdings.draggableZone.tooltip">Click to add folders.</Trans>
    </span>
  );
};

export default DragFileHelpMessage;
