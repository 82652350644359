import React, { Fragment } from "react";
import { Button, Row, Col } from "antd";

import Spinner from "../spinner";

interface CardErrorSectionProps {
  title?: string | React.ReactElement;
  message?: string | React.ReactElement;
  loading: boolean;
  handleOnClick?: () => void;
  isBtnDisabled?: boolean;
  btnText?: string | React.ReactElement;
  image?: string;
  noPanel?: boolean;
}

const CardErrorSection: React.FC<CardErrorSectionProps> = (props): React.ReactElement | null => {
  const { title, message, loading, handleOnClick, isBtnDisabled, btnText, image, noPanel } = props;

  const withoutPanel = (
    <Fragment>
      {image && (
        <div className=" ben-pt-4 ben-pb-3">
          <img src={image} alt="" />
        </div>
      )}
      <div className="ben-pb-5 ben-mb-4 ben-border-bottom">
        {title && <h1 className="ben-mb-1">{title}</h1>}

        {message && (
          <p className="ben-mb-4" style={{ fontSize: 12 }}>
            {message}
          </p>
        )}
      </div>
      {btnText && (
        <Row gutter={30}>
          <Col md={{ span: 12, offset: 6 }} sm={{ span: 12, offset: 6 }} className="ben-pb-2">
            <Spinner spinning={loading}>
              <Button
                type="primary"
                shape="round"
                size="large"
                className="ben-w-100 resend-sharing-invitation-btn"
                onClick={handleOnClick}
                disabled={isBtnDisabled}
                id="cardErrorBtnSubmit"
              >
                {btnText}
              </Button>
            </Spinner>
          </Col>
        </Row>
      )}
    </Fragment>
  );

  const withPanel = (
    <section className="ben-main-section ben-pt-5 ben-main-section-bp">
      <div className="ben-panel ben-pb-2 ben-text-center">{withoutPanel}</div>
    </section>
  );

  const getContent = () => {
    return noPanel ? withoutPanel : withPanel;
  };

  return <div className="ben-text-center">{getContent()}</div>;
};

export default CardErrorSection;
