import { createStore, compose, applyMiddleware } from "redux";
import { createEpicMiddleware } from "redux-observable";

import rootReducer from "./rootReducer";
import { rootEpic } from "./rootEpic";

export const epicMiddleware = createEpicMiddleware();
export const analytics = () => (next: any) => (action: any) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: action.type,
  });
  return next(action);
};

const store = createStore(rootReducer, compose(applyMiddleware(epicMiddleware, analytics)));
epicMiddleware.run(rootEpic);

export default store;
