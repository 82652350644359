import React from "react";
import { Trans } from "@lingui/macro";

import { InvitedObjectsTitlesMap } from "domains/liquidity/shared/constants";
import { IconCustom } from "common/lib/components";

import { PropsSharedByItem } from "./types";
import "./shared-by-item.scss";

const SharedByItem: React.FC<PropsSharedByItem> = (props): React.ReactElement | null => {
  const { title, sharedByObjectType } = props;
  if (!title || !sharedByObjectType) {
    return null;
  }

  return (
    <div className="ben-shared-by-item">
      <IconCustom type="invite-user" style={{ fontSize: 22 }} className="ben-mr-1" />

      <strong>
        <Trans id="sharedByItem.title.prefix">Shared by</Trans> {title} (
        {InvitedObjectsTitlesMap[sharedByObjectType] && InvitedObjectsTitlesMap[sharedByObjectType]}
        )
      </strong>
    </div>
  );
};

export default SharedByItem;
