import React, { useEffect, useState } from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useDispatch } from "react-redux";
import { useQueryParams, StringParam } from "use-query-params";
import { Trans } from "@lingui/macro";

import { BenVerifyScreen } from "common/lib/components";
import { Urls } from "common/lib/constants";
import { tokenDecodeUtils, sessionService } from "common/shared";
import { actions$ } from "common/shared-store/actions/actionsStream";
import {
  setAcceptedIdentityData,
  setAcceptIdentityLinkWasUsed,
} from "common/shared-store/actions/bootstrap";

import {
  ActionTypes,
  acceptIdentity,
  AcceptIdentityData,
  ResendWelcomeEmailData,
} from "domains/authentication";
import { AcceptIdentityResponseData } from "domains/authentication/shared/types";

import { TypeRedirectedFrom } from "pages/SetPasswordPage/types";

interface PropsVerifyIdentityPage extends RouteComponentProps {}
const verifyTitle = (
  <Trans id="verifyIdentityPage.verification.message.process">Verifying...</Trans>
);
const redirectedFrom: TypeRedirectedFrom = "verifyIdentityPage";

const VerifyIdentityPage: React.FC<PropsVerifyIdentityPage> = (): React.ReactElement | null => {
  const [query] = useQueryParams({ token: StringParam });
  const [redirectTo, setRedirectTo] = useState("");
  const dispatch = useDispatch();
  const { token } = query;

  const decodedTokenData = tokenDecodeUtils.decodeJwt(token || "");
  const email = (decodedTokenData && decodedTokenData["custom:email"]) || null;
  const isUserLoggedIn = sessionService.isAuthenticated();
  const isTokenExpired = token && tokenDecodeUtils.isTokenExpired(token);
  const acceptedAgreementValue =
    (decodedTokenData && decodedTokenData["custom:accepted_agreement"]) || null;

  /**
   *  We should not detect changes of email, token, isTokenExpired.
   *  They are all from param "token". "token" is used during redirection to
   *  Urls.AUTH.SET_PASSWORD. But these two tokens are different.
   */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!token || isTokenExpired || !email) {
      return;
    }

    // if user already logged in we do not send a request
    if (isUserLoggedIn) {
      return;
    }

    // send request to accept user identity
    const payload: AcceptIdentityData = { email, token };
    dispatch(acceptIdentity(payload));
  }, [dispatch, isUserLoggedIn]);

  useEffect(() => {
    // save incoming data to use it after redirection in Urls.AUTH.RESEND_WELCOME_EMAIL
    if (email) {
      const payload: ResendWelcomeEmailData = { email };
      dispatch(setAcceptedIdentityData(payload));
    }
  }, [dispatch, email]);

  useEffect(() => {
    const subscriptions: any[] = [];
    const { AcceptIdentityFail, AcceptIdentitySuccess } = ActionTypes;

    subscriptions.push(
      actions$.ofType([AcceptIdentityFail]).subscribe(({ payload }: { payload: any }) => {
        // if error status 401, token was already used
        if (
          payload &&
          payload.response &&
          payload.response.status &&
          payload.response.status === 401
        ) {
          setAcceptIdentityLinkWasUsed({ wasUsed: true });
        }

        // For all others error statuses we do not have cases, so redirect too
        setRedirectTo(Urls.AUTH.RESEND_WELCOME_EMAIL);
      })
    );

    subscriptions.push(
      actions$
        .ofType([AcceptIdentitySuccess])
        .subscribe(({ payload }: { payload: AcceptIdentityResponseData }) => {
          const { tokenForPass } = payload;

          // identity exists if there is no tokenForPass
          if (!tokenForPass && isUserLoggedIn) {
            setRedirectTo(Urls.PROTECTED.DASHBOARD);
            return;
          }
          if (!tokenForPass && !isUserLoggedIn) {
            setRedirectTo(Urls.AUTH.SIGN_IN);
            return;
          }
          if (tokenForPass) {
            setRedirectTo(
              `${Urls.AUTH.SET_PASSWORD}?token=${tokenForPass}&preventSendingEmail=true${
                acceptedAgreementValue ? `&acceptedAgreementValue=${acceptedAgreementValue}` : ""
              }&redirectedFrom=${redirectedFrom}`
            );
            return;
          }
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [isUserLoggedIn]);

  if (!token || !email) {
    // redirect to error page. The path was not specified for this case.
    return <Redirect to={Urls.AUTH.ERROR_404} noThrow={true} />;
  }

  if (isUserLoggedIn) {
    return <Redirect to={Urls.PROTECTED.DASHBOARD} noThrow={true} />;
  }
  if (isTokenExpired) {
    return <Redirect to={Urls.AUTH.RESEND_WELCOME_EMAIL} noThrow={true} />;
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} noThrow={true} />;
  }
  // Process of verifying
  return <BenVerifyScreen title={verifyTitle} />;
};

export default VerifyIdentityPage;
