import React, { useEffect, useState } from "react";
import { globalHistory, Redirect, Router } from "@reach/router";
import { useDispatch } from "react-redux";
import { QueryParamProvider } from "use-query-params";
import { Helmet } from "react-helmet";

import {
  ErrorBoundry,
  ProtectedRoute,
  GoogleTagManagerTracker,
  RefreshModal,
  MaintenancePage,
} from "common/lib/components";
import { Urls } from "common/lib/constants";
import { BrowsersUtils } from "common/shared/utils";
import { sessionService, portalEnvConstants } from "common/shared";
import { sessionActivityWatcherService } from "common/shared/services/sessionActivityWatcher/sessionActivityWatcher.service";
import { lazyLoadAddClient, lazyLoadNewLiquidity } from "common/shared/lazy/pages";
import { useHtmlTitle } from "common/shared-hooks";
import useIsMountedRef from "common/shared-hooks/use-is-mounted-ref";
import { selectLocale } from "common/shared-store/actions/locale";
import "common/assets/styles/main.scss";

import { rolesService, PERMISSION_TYPES } from "common/shared/services/roles/roles.service";
import { logout } from "domains/identity";

import {
  DashboardWrapper,
  LogoutPage,
  MainWrapper,
  ProfilePage,
  ResendPasswordLinkPage,
  ResetPasswordPage,
  ResetPasswordExpiredPage,
  SetPasswordPage,
  SigninPage,
  LiquidityRequestPage,
  VerifySharingPage,
  ResendSharingLoggedInPage,
  ResendSharingLoggedOutPage,
  ResendWelcomeEmailPage,
  ErrorPage,
  PublicErrorPage,
  Error404Page,
  PublicError404Page,
  LoginHelpPage,
  AddClientDocPage,
  PortalHelpPage,
  VerifyIdentityPage,
  VerifyImpersonatePage,
  VerifyResetPasswordPage,
  ResourcesPage,
} from "pages";
import { LocaleWrapper } from "domains/common/components";

// set IE/Edge specific css class
BrowsersUtils.setIESpecificClass();
sessionActivityWatcherService.init();

const App: React.FC<any> = ({ location }) => {
  /**
   * Fix: isMountedRef - React state update on an unmounted component
   */
  const isMountedRef = useIsMountedRef();
  const [token, setToken] = useState(sessionService.getTokens());
  sessionService.token$.subscribe((tokens) => {
    if (!isMountedRef.current) {
      return;
    }
    setToken(tokens);
  });

  // Get setting from the system
  portalEnvConstants.envSubscription$.subscribe(() => {
    dispatch(selectLocale(portalEnvConstants.defaultLocale));
  });

  const dispatch = useDispatch();

  /* only once on load */
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!token) {
      dispatch(logout());
    }
  }, []);

  useEffect(() => {
    return globalHistory.listen((params) => {
      // Scrolls to the top when there is a movement to another page
      if (params.action === "PUSH") {
        window.scrollTo(0, 0);
      }
      // remove all css attributes after closing interactive components (Modals, etc.)
      document.body.removeAttribute("style");
    });
  }, []);

  const title = useHtmlTitle(location.pathname);

  return (
    <LocaleWrapper>
      <QueryParamProvider reachHistory={globalHistory}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        <GoogleTagManagerTracker location={location} />
        <RefreshModal />
        <ErrorBoundry>
          <Router primary={false}>
            <SigninPage path={Urls.AUTH.SIGN_IN} />
            <ResendPasswordLinkPage path={Urls.AUTH.RESEND_PASSWORD_LINK} />
            <ResetPasswordPage path={Urls.AUTH.RESET_PASSWORD} />
            <ResetPasswordExpiredPage path={Urls.AUTH.RESET_PASSWORD_EXPIRED} />
            <SetPasswordPage path={Urls.AUTH.SET_PASSWORD} />
            <VerifySharingPage path={Urls.AUTH.VERIFY_SHARING} />
            <VerifyIdentityPage path={Urls.AUTH.VERIFY_IDENTITY} />
            <VerifyImpersonatePage path={Urls.AUTH.VERIFY_IMPERSONATE} />
            <VerifyResetPasswordPage path={Urls.AUTH.VERIFY_RESET_PASSWORD} />
            <ResendSharingLoggedOutPage path={Urls.AUTH.RESEND_SHARING_INVITATION_OUT} />
            <ResendWelcomeEmailPage path={Urls.AUTH.RESEND_WELCOME_EMAIL} />
            <LogoutPage path={Urls.AUTH.LOGOUT} />
            <PublicErrorPage path={Urls.AUTH.ERROR} />
            <PublicError404Page path={Urls.AUTH.ERROR_404} />
            <LoginHelpPage path={Urls.AUTH.HELP} />
            <MaintenancePage path={Urls.PUBLIC.MAINTENANCE} />
            <MainWrapper path={Urls.AUTH.BASE} checkAuth={() => !!token}>
              <ResendSharingLoggedInPage path={Urls.AUTH.RESEND_SHARING_INVITATION_IN} />
              <Redirect from={Urls.AUTH.BASE} to={Urls.PROTECTED.BASE} noThrow={true} />
              <ProtectedRoute
                path={Urls.PROTECTED.PROFILE}
                checkAuth={() => !!token}
                checkPermissions={() => true}
                Component={ProfilePage}
              />
              <ProtectedRoute
                checkAuth={() => !!token}
                checkPermissions={() => true}
                path={`${Urls.PROTECTED.LIQUIDITY_REQUEST}/:requestId`}
                Component={LiquidityRequestPage}
              />
              <ProtectedRoute
                checkAuth={() => !!token}
                checkPermissions={() => rolesService.hasPermission(PERMISSION_TYPES.CreateAccount)}
                path={Urls.PROTECTED.ADD_CLIENT}
                lazyLoadComponent={lazyLoadAddClient}
              />
              <ProtectedRoute
                checkAuth={() => !!token}
                checkPermissions={() => rolesService.hasPermission(PERMISSION_TYPES.CreateLr)}
                path={`${Urls.PROTECTED.NEW_LIQUIDITY}/:accountId`}
                lazyLoadComponent={lazyLoadNewLiquidity}
              />
              <ProtectedRoute
                checkAuth={() => !!token}
                checkPermissions={() => true}
                path={`${Urls.PROTECTED.DASHBOARD}/*`}
                Component={DashboardWrapper}
              />
              <ProtectedRoute
                checkAuth={() => !!token}
                checkPermissions={() => true}
                path={`${Urls.PROTECTED.HELP}/*`}
                Component={PortalHelpPage}
              />
              <ProtectedRoute
                checkAuth={() => !!token}
                checkPermissions={() => true}
                path={`${Urls.PROTECTED.RESOURCES}/*`}
                Component={ResourcesPage}
              />
              <ProtectedRoute
                checkAuth={() => true}
                checkPermissions={() => true}
                path={Urls.PROTECTED.ERROR}
                Component={ErrorPage}
              />
              <ProtectedRoute
                checkAuth={() => !!token}
                checkPermissions={() => true}
                path={`${Urls.PROTECTED.ADD_CLIENT_DOC}/:accountId/:requestId`}
                Component={AddClientDocPage}
              />
              <Error404Page path={Urls.PROTECTED.ERROR_404} />
              <Redirect from="*" to={Urls.PROTECTED.ERROR_404} noThrow={true} />
            </MainWrapper>
          </Router>
        </ErrorBoundry>
      </QueryParamProvider>
    </LocaleWrapper>
  );
};

export default App;
