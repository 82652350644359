import React, { useCallback, Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Row, Col } from "antd";
import { useQueryParam, StringParam } from "use-query-params";

import image from "common/assets/images/404-resend.svg";

import { Spinner } from "common/lib/components";
import { useBootstrapSharingData, useBootstrapTokenForPassData } from "common/shared-hooks";
import { actions$ } from "common/shared-store/actions/actionsStream";
import { CommonUrlParams } from "common/lib/constants/params";
import { CommonMessages } from "common/lib/constants/messages";

import { AcceptRequestSharingData } from "domains/liquidity";
import { resendSharingInvitation, ActionTypes } from "domains/liquidity/store/liquidity.actions";
import { ParamsResendSharingInvitation } from "domains/liquidity/shared/types";

import { PropsResendSharingInvitation } from "./types";
import {
  TitleSentSuccess,
  MessageSentSuccess,
  TitleInvalidData,
  MessageInvalidData,
  TitleGetNewInvitation,
  TitleExpired,
  MessageExpired,
  TitleBtn,
} from "./constants";

const ResendSharingInvitation: React.FC<PropsResendSharingInvitation> = (
  props
): React.ReactElement | null => {
  const dispatch = useDispatch();
  const [withCommonExpiredMessage] = useQueryParam(
    CommonUrlParams.withCommonExpiredMessage,
    StringParam
  );

  const acceptRequestSharingData: AcceptRequestSharingData | null = useBootstrapSharingData();
  const { isTokenForPassWasUsed } = useBootstrapTokenForPassData();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const requestId = (acceptRequestSharingData && acceptRequestSharingData.requestId) || null;
  const sharingId = (acceptRequestSharingData && acceptRequestSharingData.sharingId) || null;
  const isResendSharingInvitationDisabled = !Boolean(requestId && sharingId);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType([ActionTypes.ResendSharingInvitation]).subscribe(() => {
        setLoading(true);
      })
    );
    subscriptions.push(
      actions$.ofType([ActionTypes.ResendSharingInvitationSuccess]).subscribe(() => {
        setLoading(false);
        setSuccess(true);
      })
    );
    subscriptions.push(
      actions$.ofType([ActionTypes.ResendSharingInvitationFail]).subscribe(() => {
        setLoading(false);
        setSuccess(false);
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const handleResendSharingInvitation = useCallback(() => {
    if (!requestId || !sharingId) {
      return;
    }
    const invitePayload: ParamsResendSharingInvitation = {
      requestId,
      sharingId,
    };
    dispatch(resendSharingInvitation(invitePayload));
  }, [dispatch, requestId, sharingId]);

  const { noPanel } = props;

  let title;
  let message;
  if (isSuccess) {
    title = TitleSentSuccess;
    message = MessageSentSuccess;
  } else if (isResendSharingInvitationDisabled) {
    title = TitleInvalidData;
    message = MessageInvalidData;
  } else if (isTokenForPassWasUsed) {
    title = TitleGetNewInvitation;
  } else if (withCommonExpiredMessage) {
    title = CommonMessages.expiredLink;
  } else if (!isSuccess) {
    title = TitleExpired;
    message = MessageExpired;
  }

  const withoutPanel = (
    <Fragment>
      <div className=" ben-pt-4 ben-pb-3">
        <img src={image} alt="Your invitation has expired." />
      </div>
      <div className="ben-pb-5 ben-mb-4 ben-border-bottom">
        {title && (
          <h1 className="ben-mb-1" id="resendSharingInvitationTitle">
            {title}
          </h1>
        )}
        {message && (
          <p className="ben-mb-4" id="resendSharingInvitationMessage" style={{ fontSize: 12 }}>
            {message}
          </p>
        )}
      </div>

      <Row gutter={30}>
        <Col md={{ span: 12, offset: 6 }} sm={{ span: 12, offset: 6 }} className="ben-pb-2">
          {!isSuccess && (
            <Spinner spinning={isLoading}>
              <Button
                type="primary"
                shape="round"
                size="large"
                className="ben-w-100 resend-sharing-invitation-btn"
                onClick={handleResendSharingInvitation}
                disabled={isResendSharingInvitationDisabled}
                id="resendSharingInvitationPageActionSubmit"
              >
                {TitleBtn}
              </Button>
            </Spinner>
          )}
        </Col>
      </Row>
    </Fragment>
  );

  const withPanel = (
    <section id="resendSharingInvitationPanel" className="ben-main-section ben-pt-5 ben-pb-5">
      <div className="ben-panel ben-pb-2 ben-text-center">{withoutPanel}</div>
    </section>
  );

  return <div className="ben-text-center">{noPanel ? withoutPanel : withPanel}</div>;
};

export default ResendSharingInvitation;
