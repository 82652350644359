import { useSelector } from "react-redux";

import UserLight from "domains/clients/store/user.light.model";

interface AccountUsersDataProps {
  accountUsers: UserLight[];
  accountUsersLoading: boolean;
}

function useAccountUsersData(): AccountUsersDataProps {
  const result = useSelector(
    (state: any): AccountUsersDataProps => {
      const { accountUsers, accountUsersLoading } = state.clients;
      return {
        accountUsers,
        accountUsersLoading,
      };
    }
  );

  return { ...result };
}

export default useAccountUsersData;
