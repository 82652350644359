import React, { useCallback, useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Modal, Button } from "antd";

import { Spinner } from "common/lib/components";
import { Urls, Layout } from "common/lib/constants";
import {
  sessionService,
  crossTabMessageService,
  CROSS_TAB_EVENTS,
  TypeSessionData,
} from "common/shared";
import { useIsMountedRef } from "common/shared-hooks";
import { sessionActivityWatcherService } from "common/shared/services/sessionActivityWatcher/sessionActivityWatcher.service";
import { actions$ } from "common/shared-store";

import { logout } from "domains/identity";
import { SessionModalMessage } from "domains/authentication/components";
import { refreshAccessToken, ActionTypes } from "domains/authentication/store/auth.actions";
import {
  ParamsRefreshAccessToken,
  ActionRefreshAccessTokenSuccess,
} from "domains/authentication/shared/types";

import { SessionModalTitle, SessionModalBtnContinue, SessionModalBtnLogOut } from "./constants";
import { PropsSessionModal } from "./types";

const { OPEN_SESSION_MODAL, SEND_SESSION_DATA, UPDATE_SESSION, CLOSE_SESSION } = CROSS_TAB_EVENTS;

const SessionModal: React.FC<PropsSessionModal> = (): React.ReactElement | null => {
  const dispatch = useDispatch();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<null | number>(null);
  /**
   * Fix: isMountedRef - React state update on an unmounted component
   */
  const isMountedRef = useIsMountedRef();

  const handleLogout = useCallback(() => {
    dispatch(
      logout({ urlToRedirectTo: Urls.AUTH.LOGOUT, emitEventToTabs: true, sendLogoutRequest: true })
    );
    setModalOpen(false);
  }, [dispatch]);

  const handleContinue = useCallback(async () => {
    setLoading(true);
    const payload: ParamsRefreshAccessToken = { refreshToken: sessionService.getRefreshToken() };
    dispatch(refreshAccessToken(payload));
  }, [dispatch]);

  const handleFiniteTime = useCallback(() => {
    dispatch(
      logout({ urlToRedirectTo: Urls.AUTH.LOGOUT, emitEventToTabs: true, sendLogoutRequest: false })
    );
    setModalOpen(false);
  }, [dispatch]);

  const handleCountdownInterval = useCallback(() => {
    const startCountdownTime = Date.now();
    return window.setInterval(() => {
      setCountdown((countdownInState: null | number) => {
        if (countdownInState === 0) {
          handleFiniteTime();
        }
        if (!countdownInState || !startCountdownTime) {
          return null;
        }

        // Check for sleeping browser when session modal is open
        if (sessionService.isSessionExpired()) {
          handleFiniteTime();
          return null;
        }

        const expiredIn = sessionService.getSessionExpiredIn();
        return expiredIn;
      });
    }, 1000);
  }, [handleFiniteTime]);

  useEffect(() => {
    let interval: number | undefined;
    const handleClearInterval = () => {
      if (interval) {
        clearInterval(interval);
        interval = 0;
      }
    };

    crossTabMessageService.addListener(OPEN_SESSION_MODAL, (message?: string) => {
      if (!isMountedRef.current) {
        return null;
      }
      const expiredInSeconds = Number(message);
      setCountdown(expiredInSeconds);
      if (!interval) {
        interval = handleCountdownInterval();
      }
      setModalOpen(true);
    });

    crossTabMessageService.addListener(UPDATE_SESSION, () => {
      setCountdown(null);
      handleClearInterval();
      setModalOpen(false);
    });

    crossTabMessageService.addListener(CLOSE_SESSION, () => {
      handleClearInterval();
    });

    // After reload the page or open new tab when expiration process is running
    const expiredIn = sessionService.getSessionExpiredIn();
    if (expiredIn) {
      setCountdown(expiredIn);
      sessionActivityWatcherService.handleContinueInactivityAction(expiredIn);
      interval = handleCountdownInterval();
      setModalOpen(true);
    }

    return function cleanup() {
      handleClearInterval();
    };
  }, [handleCountdownInterval, isMountedRef]);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.RefreshAccessTokenSuccess])
        .subscribe((action: ActionRefreshAccessTokenSuccess) => {
          sessionService.updateAccessToken(action.payload.accessToken);
          const sessionData: TypeSessionData | null = sessionService.getSessionData();

          if (!sessionData) {
            handleLogout();
            return;
          }

          crossTabMessageService.emitEvent(SEND_SESSION_DATA, JSON.stringify(sessionData));
          crossTabMessageService.emitEvent(UPDATE_SESSION, "", true);
          setLoading(false);
        })
    );

    subscriptions.push(
      actions$.ofType([ActionTypes.RefreshAccessTokenFail]).subscribe(() => {
        setLoading(false);
        setModalOpen(false);
        handleLogout();
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [handleLogout]);

  return (
    <div>
      <Modal
        title={SessionModalTitle}
        visible={isModalOpen}
        closable={false}
        footer={null}
        centered={true}
        width={"100%"}
        style={{
          maxWidth: Layout.MaxWidthModalWindow.standard,
        }}
        wrapClassName="ben-modal-container"
      >
        <Spinner spinning={isLoading}>
          <Fragment>
            <SessionModalMessage countdown={countdown} />
            <Button
              type="primary"
              shape="round"
              size="large"
              className="ben-w-100 ben-mb-3"
              onClick={handleContinue}
              id="benSessionModalActionContinue"
            >
              {SessionModalBtnContinue}
            </Button>
            <Button
              type="primary"
              shape="round"
              ghost={true}
              size="large"
              className="ben-w-100"
              onClick={handleLogout}
              id="benSessionModalActionLogOut"
            >
              {SessionModalBtnLogOut}
            </Button>
          </Fragment>
        </Spinner>
      </Modal>
    </div>
  );
};

export default SessionModal;
