import React from "react";

import MessageConfig from "common/shared/interfaces/message-config";

import validatorTypes from "common/shared/services/validation/validators";
import messageService from "common/shared/services/message/message.service";
import { Trans } from "@lingui/macro";

export interface TypeFileValidationError {
  id: string;
  message: string | React.ReactElement;
}

export default class FileService {
  static readonly defaultDocFormats = ["pdf"];

  static validateFile(
    file: File,
    validationConfig: { maxSize: number; formats: string[]; validateName: boolean }
  ) {
    const { name, size } = file;
    const { maxSize, formats, validateName } = validationConfig;

    if (validateName && !this.validateFileNameShowError(name)) {
      return false;
    }
    if (!this.validateSizeShowError(size, maxSize)) {
      return false;
    }
    if (!this.validateFileFormatShowError(name, formats)) {
      return false;
    }

    return true;
  }

  static getValidationFileErrors(
    file: File,
    validationConfig: { maxSize: number; formats: string[]; validateName: boolean }
  ): TypeFileValidationError[] | null {
    const { name, size } = file;
    const { maxSize, formats, validateName } = validationConfig;
    const errors = [];

    if (validateName) {
      const fileNameError = this.validateFileNameGetError(name);
      if (fileNameError) {
        errors.push({ id: "fileNameError", message: fileNameError });
      }
    }

    const fileSizeError = this.validateSizeGetError(size, maxSize);
    if (fileSizeError) {
      errors.push({ id: "fileSizeError", message: fileSizeError });
    }

    const fileFormatError = this.validateFileFormatGetError(name, formats);
    if (fileFormatError) {
      errors.push({ id: "fileFormatError", message: fileFormatError });
    }

    if (!errors.length) {
      return null;
    }

    return errors;
  }

  static isValidFileName(name: string): boolean {
    if (validatorTypes.documentNamePattern(name)) {
      return false;
    }

    return true;
  }

  static validateFileNameShowError(name: string) {
    if (!this.isValidFileName(name)) {
      messageService.error("file.validation.error.name");
      return false;
    }

    return true;
  }

  static validateFileNameGetError(name: string): React.ReactElement | null {
    if (!this.isValidFileName(name)) {
      return <Trans id="file.validation.error.name" />;
    }

    return null;
  }

  static getRawFileName(name: string): string | null {
    if (!name) {
      return null;
    }
    const parts = name.split(".");
    if (!parts || !parts[0]) {
      return null;
    }
    return parts[0];
  }

  static isValidFileFormat(name: string, formats: string[]): boolean {
    const ext = name.split(".").pop();
    const isValidFormat = !!ext && formats.indexOf(ext.toLowerCase()) >= 0;
    if (!isValidFormat) {
      return false;
    }

    return true;
  }

  static validateFileFormatShowError(name: string, formats: string[]) {
    const isValid = this.isValidFileFormat(name, formats);
    if (!isValid) {
      messageService.error(
        new MessageConfig("file.validation.error.format", [
          { name: "formats", value: formats.join(", ") },
        ])
      );
      return false;
    }

    return true;
  }

  static validateFileFormatGetError(name: string, formats: string[]): React.ReactElement | null {
    const isValid = this.isValidFileFormat(name, formats);

    if (!isValid) {
      return <Trans id="file.validation.error.format" values={{ formats: formats.join(", ") }} />;
    }

    return null;
  }

  static isValidSize(size: number, requestedSize: number): boolean {
    return size / 1024 / 1024 < requestedSize;
  }

  static validateSizeShowError(size: number, requestedSize: number): boolean {
    const isValid = this.isValidSize(size, requestedSize);
    if (!isValid) {
      messageService.error(
        new MessageConfig("file.validation.error.size", [{ name: "size", value: requestedSize }])
      );
      return false;
    }

    return true;
  }

  static validateSizeGetError(size: number, requestedSize: number): React.ReactElement | null {
    const isValid = this.isValidSize(size, requestedSize);

    if (!isValid) {
      return <Trans id="file.validation.error.size" values={{ size: requestedSize }} />;
    }

    return null;
  }
}
