import React from "react";
import { Trans } from "@lingui/macro";
import { Link } from "@reach/router";

import { Urls } from "common/lib/constants";

import "./login-footer.scss";

interface LoginFooterProps {
  alignLeft?: boolean;
}

function LoginFooter({ alignLeft }: LoginFooterProps) {
  const classes = "ben-login-footer " + (alignLeft ? "ben-text-left" : "ben-text-right");

  return (
    <div className={classes}>
      <Link to={Urls.AUTH.HELP} className="ben-link">
        <Trans id="login.footer.login_help_link">Login Help</Trans>
      </Link>
    </div>
  );
}

export default LoginFooter;
