import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useQueryParam, StringParam } from "use-query-params";

import image from "common/assets/images/404-lock.svg";
import { actions$ } from "common/shared-store/actions/actionsStream";
import { CardErrorSection } from "common/lib/components";
import { useBootstrapAcceptIdentityData } from "common/shared-hooks";

import { resendWelcomeEmail, ActionTypes } from "domains/authentication/store/auth.actions";
import { ParamsResendWelcomeEmail } from "domains/authentication/shared/types";
import { CommonUrlParams } from "common/lib/constants/params";
import { CommonMessages } from "common/lib/constants/messages";

import { PropsResendWelcomeEmail } from "./types";
import {
  TitleSentSuccess,
  MessageSentSuccess,
  TitleInvalidData,
  MessageInvalidData,
  TitleGetNewInvitation,
  TitleExpired,
  MessageExpired,
  TitleBtnResend,
} from "./constants";

const ResendWelcomeEmail: React.FC<PropsResendWelcomeEmail> = (): React.ReactElement | null => {
  const dispatch = useDispatch();
  const [withCommonExpiredMessage] = useQueryParam(
    CommonUrlParams.withCommonExpiredMessage,
    StringParam
  );

  const { email, isAcceptIdentityLinkWasUsed } = useBootstrapAcceptIdentityData();
  const [isLoading, setLoading] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const isResendWelcomeEmailDisabled = !Boolean(email);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType([ActionTypes.ResendWelcomeEmail]).subscribe(() => {
        setLoading(true);
      })
    );
    subscriptions.push(
      actions$.ofType([ActionTypes.ResendWelcomeEmailSuccess]).subscribe(() => {
        setLoading(false);
        setSuccess(true);
      })
    );
    subscriptions.push(
      actions$.ofType([ActionTypes.ResendWelcomeEmailFail]).subscribe(() => {
        setLoading(false);
        setSuccess(false);
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  let title;
  let message;
  if (isSuccess) {
    title = TitleSentSuccess;
    message = MessageSentSuccess;
  } else if (isResendWelcomeEmailDisabled) {
    title = TitleInvalidData;
    message = MessageInvalidData;
  } else if (isAcceptIdentityLinkWasUsed) {
    title = TitleGetNewInvitation;
  } else if (withCommonExpiredMessage) {
    title = CommonMessages.expiredLink;
  } else if (!isSuccess) {
    title = TitleExpired;
    message = MessageExpired;
  }

  const handleResendWelcomeEmail = useCallback(() => {
    if (!email) {
      return;
    }
    const payload: ParamsResendWelcomeEmail = { email };
    dispatch(resendWelcomeEmail(payload));
  }, [dispatch, email]);

  const btnText = isSuccess ? "" : TitleBtnResend;

  return (
    <CardErrorSection
      title={title}
      message={message}
      loading={isLoading}
      handleOnClick={handleResendWelcomeEmail}
      isBtnDisabled={isResendWelcomeEmailDisabled}
      btnText={btnText}
      image={image}
      noPanel={false}
    />
  );
};

export default ResendWelcomeEmail;
