import React from "react";
import { Trans } from "@lingui/react";

export const HeaderUploadDocumentsList = (
  <Trans id="documentsList.holdings.upload.title">Upload Required Documents</Trans>
);
export const HeaderViewDocumentsList = (
  <Trans id="documentsList.holdings.title">View or Replace Documents</Trans>
);
export const HeaderEmptyDocumentsList = (
  <Trans id="documentsList.holdings.empty.title">Add Documents</Trans>
);
