import React, { useEffect } from "react";
import { Redirect, navigate } from "@reach/router";
import { StringParam, useQueryParams } from "use-query-params";

import { ResetPasswordForm } from "domains/authentication";
import { PublicPageWrapper } from "../components";
import { Urls } from "common/lib/constants";
import {
  tokenDecodeUtils,
  crossTabMessageService,
  CROSS_TAB_EVENTS,
  sessionService,
} from "common/shared";
import { useGetAppVersion } from "common/shared-hooks";
import { unprotectedActivityWatcherService } from "common/shared/services/unprotectedActivityWatcher/unprotectedActivityWatcher.service";
import { DomUtils } from "common/shared/utils";

import { getRedirectionPath } from "./helpers";
import { PropsSetPasswordPage } from "./types";

const { UNPROTECTED_PAGE_INACTIVE } = CROSS_TAB_EVENTS;

const SetPasswordPage: React.FC<PropsSetPasswordPage> = (): React.ReactElement | null => {
  const [query] = useQueryParams({
    token: StringParam,
    preventSendingEmail: StringParam,
    acceptedAgreementValue: StringParam,
    redirectedFrom: StringParam,
  });
  const { token: resetToken, preventSendingEmail, acceptedAgreementValue, redirectedFrom } = query;
  const isUserLoggedIn = sessionService.isAuthenticated();

  let isAcceptedTermsAgreement = null;
  if (acceptedAgreementValue) {
    isAcceptedTermsAgreement = acceptedAgreementValue === "true" ? true : false;
  }

  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  useEffect(() => {
    /**
     * Start tracking the inactivity only if we have a token in the URL
     * AND user was redirected from the expected pages
     * AND user is not logged in
     */
    if (resetToken && redirectedFrom && !isUserLoggedIn) {
      unprotectedActivityWatcherService.init();
    }

    return function cleanup() {
      unprotectedActivityWatcherService.reset();
    };
  }, [resetToken, isUserLoggedIn, redirectedFrom]);

  useEffect(() => {
    crossTabMessageService.addListener(UNPROTECTED_PAGE_INACTIVE, (message?: string) => {
      const path = getRedirectionPath({ redirectedFrom, resetToken });
      if (path) {
        navigate(path);
        return;
      }

      DomUtils.reload();
    });

    return function cleanup() {
      crossTabMessageService.removeListeners(UNPROTECTED_PAGE_INACTIVE);
    };
  }, [resetToken, redirectedFrom, isUserLoggedIn]);

  // check token expiration
  // redirect to resendLink if expired
  if (tokenDecodeUtils.isTokenExpired(resetToken || "")) {
    return (
      <Redirect
        from=""
        to={`${Urls.AUTH.RESET_PASSWORD_EXPIRED}?token=${resetToken}`}
        noThrow={true}
      />
    );
  }

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <ResetPasswordForm
        resetMode={false}
        resetToken={resetToken || null}
        preventSendingEmail={Boolean(preventSendingEmail)}
        isAcceptedTermsAgreement={isAcceptedTermsAgreement}
      />
    </PublicPageWrapper>
  );
};

export default SetPasswordPage;
