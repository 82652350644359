import { useSelector } from "react-redux";
import { ClientToLiquidityRequestCountsMap } from "domains/clients/shared/types";

interface LiquidityRequestCountsDataProps {
  items: ClientToLiquidityRequestCountsMap;
}

function useLiquidityRequestCountsData(): LiquidityRequestCountsDataProps {
  const response = useSelector(
    (state: any): LiquidityRequestCountsDataProps => {
      const {
        list: { liquidityRequestCounts },
      } = state.clients;
      return {
        items: liquidityRequestCounts,
      };
    }
  );

  return response;
}

export default useLiquidityRequestCountsData;
