import React, { Fragment } from "react";

import { ArrayUtils } from "common/shared/utils";

import { DocumentItemData } from "domains/document/shared/types";
import { BenDocument } from "domains/document/components";

import { PropsUncategorizedDocuments } from "./types";

const UncategorizedDocuments: React.FC<PropsUncategorizedDocuments> = (
  props
): React.ReactElement | null => {
  const { documents, handleDownload } = props;

  if (!ArrayUtils.isValidArrayData(documents)) {
    return null;
  }

  return (
    <Fragment>
      {documents.map((item: DocumentItemData) => {
        return (
          <BenDocument
            withDragger={false}
            openFileDialogOnClick={false}
            key={item.displayId}
            title={item.fileName || item.documentName}
            statusIcon="complete"
            size="small"
            handleDownload={handleDownload(item.id)}
          />
        );
      })}
    </Fragment>
  );
};

export default UncategorizedDocuments;
