import { ofType } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";

import {
  ActionTypes,
  getTransactionDocumentsSuccess,
  getTransactionDocumentsFail,
  getDocumentUrlFail,
  getDocumentUrlSuccess,
  getDocumentsByHoldingFail,
  getDocumentsByHoldingSuccess,
  confirmUploadSuccess,
  confirmUploadFail,
  setIdontHaveThisSuccess,
  setIdontHaveThisFail,
  getPublicDocumentUrlSuccess,
  getPublicDocumentUrlFail,
  getUncategorizedDocumentsSuccess,
  getUncategorizedDocumentsFail,
} from "./document.actions";
import documentAPIService from "../shared/document.api.service";
import {
  ParamsConfirmUpload,
  ParamsGetDocuments,
  DocumentListResponseData,
  ParamsGetDocumentUrl,
  DocumentUrlResponseData,
  ParamsDocumentsByHoldings,
  ParamsGetPublicDocumentUrl,
} from "../shared/types";

export const getTransactionDocumentsEpic = (action$: any) => {
  return action$.pipe(
    ofType(ActionTypes.GetTransactionDocuments),
    map((action: { type: string; payload: ParamsGetDocuments }) => {
      return action.payload;
    }),
    mergeMap(async (params: ParamsGetDocuments) => {
      try {
        const response: DocumentListResponseData = await documentAPIService.getTransactionDocuments(
          params
        );
        return getTransactionDocumentsSuccess(response);
      } catch (error) {
        return getTransactionDocumentsFail();
      }
    })
  );
};

export const getUncategorizedDocumentsEpic = (action$: any) => {
  return action$.pipe(
    ofType(ActionTypes.GetUncategorizedDocuments),
    map((action: { type: string; payload: ParamsGetDocuments }) => {
      return action.payload;
    }),
    mergeMap(async (params: ParamsGetDocuments) => {
      try {
        const response: DocumentListResponseData = await documentAPIService.getUncategorizedDocuments(
          params
        );

        return getUncategorizedDocumentsSuccess(response);
      } catch (error) {
        return getUncategorizedDocumentsFail();
      }
    })
  );
};

export const getDocumentsByHoldingEpic = (action$: any) => {
  return action$.pipe(
    ofType(ActionTypes.GetDocumentsByHolding),
    map((action: { type: string; payload: ParamsDocumentsByHoldings }) => {
      return action.payload;
    }),
    mergeMap(async (params: ParamsDocumentsByHoldings) => {
      try {
        const response: DocumentListResponseData = await documentAPIService.getDocumentsByHoldings(
          params
        );
        return getDocumentsByHoldingSuccess(response);
      } catch (error) {
        return getDocumentsByHoldingFail();
      }
    })
  );
};

export const getDocumentUrlEpic = (action$: any) => {
  return action$.pipe(
    ofType(ActionTypes.GetDocumentUrl),
    map((action: { type: string; payload: ParamsGetDocumentUrl }) => {
      return action.payload;
    }),
    mergeMap(async (params: ParamsGetDocumentUrl) => {
      try {
        const response: DocumentUrlResponseData = await documentAPIService.getDocumentUrl(params);
        document.location.assign(response.url);
        return getDocumentUrlSuccess(response);
      } catch (error) {
        return getDocumentUrlFail();
      }
    })
  );
};

export const getPublicDocumentUrlEpic = (action$: any) => {
  return action$.pipe(
    ofType(ActionTypes.GetPublicDocumentUrl),
    map((action: { type: string; payload: ParamsGetPublicDocumentUrl }) => {
      return action.payload;
    }),
    mergeMap(async (params: ParamsGetPublicDocumentUrl) => {
      try {
        const response: DocumentUrlResponseData = await documentAPIService.getPublicDocumentUrl(
          params
        );
        document.location.assign(response.url);
        return getPublicDocumentUrlSuccess(response);
      } catch (error) {
        return getPublicDocumentUrlFail();
      }
    })
  );
};

export const confirmUploadEpic = (action$: any) => {
  return action$.pipe(
    ofType(ActionTypes.ConfirmUpload),
    map((action: { type: string; payload: ParamsConfirmUpload }) => {
      return action.payload;
    }),
    mergeMap(async (params: ParamsConfirmUpload) => {
      try {
        await documentAPIService.confirmUpload(params);
        return confirmUploadSuccess({ cancelConfirmation: params.reject, fileId: params.fileId });
      } catch (error) {
        return confirmUploadFail({ cancelConfirmation: params.reject, fileId: params.fileId });
      }
    })
  );
};

export const setIdontHaveThisEpic = (action$: any) => {
  return action$.pipe(
    ofType(ActionTypes.SetIdontHaveThis),
    map((action: { type: string; payload: ParamsConfirmUpload }) => {
      return action.payload;
    }),
    mergeMap(async (params: any) => {
      try {
        await documentAPIService.createDocument({
          ...params,
          doNotHaveThisDocument: true,
        });
        return setIdontHaveThisSuccess(params.assetId, params.documentType);
      } catch (error) {
        return setIdontHaveThisFail(params.assetId, params.documentType);
      }
    })
  );
};
