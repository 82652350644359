import { useSelector } from "react-redux";
import { LiquiditiesListDataProps } from "../shared/types";

function useLiquiditiesListData(): LiquiditiesListDataProps {
  const result = useSelector(
    (state: any): LiquiditiesListDataProps => {
      const {
        data: { items },
        loading
      } = state.liquidity.list;
      return {
        list: items,
        loading
      };
    }
  );

  return { ...result };
}

export default useLiquiditiesListData;
