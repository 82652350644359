import FormInput from "common/shared/interfaces/form-input";
import { validatorTypes } from "common/shared";

export class AssetFormSchema {
  public fundName = new FormInput();
  public sponsor = new FormInput();

  constructor(data: any, disabledFields?: { [key: string]: boolean }) {
    this.fundName.value = data.fundName || "";
    this.sponsor.value = data.sponsor || "";

    if (disabledFields) {
      this.fundName.isDisabled = disabledFields.fundName || false;
      this.sponsor.isDisabled = disabledFields.sponsor || false;
    }
  }
}

export const assetValidationSchema = {
  fundName: [validatorTypes.required, validatorTypes.fundNamePattern],
  sponsor: [validatorTypes.required, validatorTypes.sponsorNamePattern],
};

export const impersonateAssetDisabledConfig = {
  fundName: true,
  sponsor: true,
};
