import { Constants } from "common/lib/constants";
import User from "../store/user.model";
import { ArrayUtils } from "common/shared/utils";
import {
  GetClientsAlertsResponseData,
  GetClientsResponseData,
  Client,
} from "domains/clients/shared/types";
import ProfileApiTransformHelper from "./profile.api.transform.helper";

export default class ClientsApiParser {
  static prepareBackendLikeIndividual(account: any) {
    return {
      account_name: `${account.firstName} ${account.lastName}`,
      account_type: "individual",
      access_granted: false,
      relationship_type: "client",
      status: "prospective",
    };
  }

  static prepareBackendLikePhone(data: any) {
    if (data.phoneNumber) {
      data.phones = [
        {
          phone_number: data.phoneNumber,
          phone_type: data.phoneType,
          country_code: data.countryCode,
          is_default: true,
        },
      ];

      delete data.phoneNumber;
      delete data.phoneType;
      delete data.countryCode;

      return [...data.phones];
    }

    return [];
  }

  static prepareBackendLikeAddress(data: any) {
    data.addresses = [
      {
        street1: data.street1,
        street2: data.street2,
        city: data.city,
        region: data.region,
        country: data.country,
        postal_code: data.postalCode,
        is_default: true,
      },
    ];

    delete data.street1;
    delete data.street2;
    delete data.city;
    delete data.region;
    delete data.country;
    delete data.postalCode;

    return [...data.addresses];
  }

  static prepareBackendLikeInstitution(institution: any) {
    const backendLikeInstitution = { ...institution };
    this.prepareBackendLikeAddress(backendLikeInstitution);
    // default hardcoded - required for BE
    backendLikeInstitution.institution_type = "asset_management_firm";
    delete backendLikeInstitution.name;
    return {
      account_name: institution.name,
      account_type: "institution",
      /*** default hardcoded - required for BE ***/
      access_granted: false,
      relationship_type: "client",
      status: "prospective",
      /*** --- ***/
      institution_data: backendLikeInstitution,
    };
  }

  static prepareInstitutionUserData(user: User) {
    const parsedUser = this.prepareUserData(user);
    return { ...parsedUser, user_type: Constants.USER_TYPES.EMPLOYEE };
  }

  static prepareIndividualUserData(user: User) {
    const filteredData = ProfileApiTransformHelper.filterClientData(user as any);
    const addresses = this.prepareBackendLikeAddress(filteredData);
    const parsedUser = this.prepareUserData(filteredData);
    return {
      ...parsedUser,
      addresses,
      user_type: Constants.USER_TYPES.PRINCIPAL,
    };
  }

  static prepareUserData(user: User) {
    const phones = this.prepareBackendLikePhone(user);
    return {
      first_name: user.firstName,
      middle_name: user.middleName,
      last_name: user.lastName,
      nick_name: user.nickName,
      time_zone_abbreviation: user.timeZoneAbbreviation || null,
      suffix: user.suffix || null,
      phones,
      email_addresses: [
        {
          email: user.email,
          is_default: true,
        },
      ],
    };
  }

  static parseClientUserResponseData(response: any): any {
    const result: any = {
      firstName: response.first_name,
      middleName: response.middle_name,
      lastName: response.last_name,
      nickName: response.nick_name,
      suffix: response.suffix,
      timeZoneAbbreviation: response.time_zone_abbreviation,
      street1: response.addresses[0] ? response.addresses[0].street1 : "",
      street2: response.addresses[0] ? response.addresses[0].street2 : "",
      city: response.addresses[0] ? response.addresses[0].city : "",
      region: response.addresses[0] ? response.addresses[0].region : "",
      postalCode: response.addresses[0] ? response.addresses[0].postal_code : "",
      countryCode: response.phones[0] ? response.phones[0].country_code : "",
      country: response.addresses[0] ? response.addresses[0].country : "",
      phoneNumber: response.phones[0] ? response.phones[0].phone_number : "",
      phoneType: response.phones[0] ? response.phones[0].phone_type : "",
      email: response.email_addresses[0].email,
      accountId: response.account_id,
      accountName: response.account_name || "",
      accountType: response.account_type || "",
      userType: response.user_type,
      userId: response.user_id,
      userDisplayId: response.user_display_id,
      accountDisplayId: response.account_display_id,
    };

    return result;
  }

  static parseClientInstitutionResponseData(response: any): any {
    if (!response.institution_data) {
      return null;
    }
    const institutionData = response.institution_data;
    const address = ArrayUtils.getFirstElement(institutionData.addresses);
    const pcUser = institutionData.primary_contact_user;
    const primaryContactUser = pcUser
      ? {
          id: pcUser.user_id,
          firstName: pcUser.first_name,
          middleName: pcUser.middle_name,
          lastName: pcUser.last_name,
          phoneNumber: pcUser.phone_number,
          email: pcUser.email,
          countryCode: pcUser.country_code,
        }
      : null;
    const institution = {
      accountId: response.account_id,
      accountDisplayId: response.display_id,
      accountName: response.account_name,
      accountType: response.account_type,
      name: response.account_name,
      country: address ? address.country : "",
      street1: address ? address.street1 : "",
      street2: address ? address.street2 : "",
      city: address ? address.city : "",
      region: address ? address.region : "",
      postalCode: address ? address.postal_code : "",
      primaryContactUser,
    };

    return institution;
  }

  static parseClientsList(clients: any[]): GetClientsResponseData {
    const processedClientsList = {
      ids: [...clients.map((client: any) => client.accountId)],
      entitiesMap: clients.reduce(
        (acc: object, client: Client) => ({
          ...acc,
          [client.accountId]: client,
        }),
        {}
      ),
    };
    return processedClientsList;
  }

  static getClientToAlertMap(data: any): GetClientsAlertsResponseData {
    const result: GetClientsAlertsResponseData = {
      items: {},
    };

    if (!data || !data.items || !ArrayUtils.isValidArrayData(data.items)) {
      return result;
    }

    data.items.forEach((item: any) => {
      if (!result.items[item.accountId]) {
        result.items[item.accountId] = !item.hasBeenRead;
      }
    });

    return result;
  }
}
