import React, { Fragment } from "react";

import { PropsLiquidityProcessDescription } from "./types";

const LiquidityProcessDescription: React.FC<PropsLiquidityProcessDescription> = (
  props
): React.ReactElement | null => {
  const { text } = props;

  if (!text) {
    return null;
  }

  return (
    <Fragment>
      <p>{text}</p>
    </Fragment>
  );
};

export default LiquidityProcessDescription;
