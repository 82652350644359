import { useSelector } from "react-redux";
import { TransactionDocumentsDataProps } from "../shared/types";
import documentService from "../shared/document.service";
import { STANDARD_LIQUIDITY_DOCUMENT_LIST } from "../shared/constants";

function useTransactionDocumentsData(): TransactionDocumentsDataProps {
  const result = useSelector(
    (state: any): TransactionDocumentsDataProps => {
      const {
        data: { items },
        loading,
      } = state.document.transactionDocument;

      const {
        standardDocuments,
        otherDocuments,
        otherAppianDocuments,
      } = documentService.getNormalizedDocuments(items, STANDARD_LIQUIDITY_DOCUMENT_LIST);

      return {
        loading,
        standardDocuments,
        otherDocuments,
        otherAppianDocuments,
      };
    }
  );

  return result;
}

export default useTransactionDocumentsData;
