import { useSelector } from "react-redux";

function usePermissionsRequest(): { loading: boolean; isCompletedRequest: boolean } {
  const result = useSelector((state: any): { loading: boolean; isCompletedRequest: boolean } => {
    return {
      loading: state.identity.loading,
      isCompletedRequest: state.identity.isCompletedRequest,
    };
  });

  return result;
}

export default usePermissionsRequest;
