import React, { useState, useCallback, Fragment } from "react";
import { Trans } from "@lingui/macro";

import { TermsOfUseModal, PrivacyModal } from "domains/authentication/modals";

import { PropsPrivacyTermsInfo } from "./types";
import "./privacy-terms-info.scss";

const PrivacyTermsInfo: React.FC<PropsPrivacyTermsInfo> = (props): React.ReactElement => {
  const [isTermsOfUseModalVisible, setTermsOfUseModalVisible] = useState<boolean>(false);
  const [isPrivacyModalVisible, setPrivacyModalVisible] = useState<boolean>(false);

  const { withUnderline } = props;

  const handleTermsOfUseModalVisibility = useCallback(
    (value: boolean) => () => {
      setTermsOfUseModalVisible(value);
    },
    []
  );

  const handlePrivacyModalVisibility = useCallback(
    (value: boolean) => () => {
      setPrivacyModalVisible(value);
    },
    []
  );

  const triggerClassName = withUnderline ? " ben-important-text" : "";

  return (
    <Fragment>
      <span
        className={`privacy-terms-info-modal-trigger${triggerClassName}`}
        id="termOfUseModalHeader"
        onClick={handleTermsOfUseModalVisibility(true)}
      >
        <Trans id="privacyTermsInfo.termsOfUse.trigger.title">Terms of Use</Trans>
      </span>{" "}
      <Trans id="privacyTermsInfo.title.and">and</Trans>{" "}
      <span
        className={`privacy-terms-info-modal-trigger${triggerClassName}`}
        id="privacyPolicyHeader"
        onClick={handlePrivacyModalVisibility(true)}
      >
        <Trans id="privacyTermsInfo.privacyPolicy.trigger.title">Privacy Policy</Trans>
      </span>
      <TermsOfUseModal
        isTermsOfUseModalVisible={isTermsOfUseModalVisible}
        handleTermsOfUseModalVisibility={handleTermsOfUseModalVisibility}
      />
      <PrivacyModal
        isPrivacyModalVisible={isPrivacyModalVisible}
        handlePrivacyModalVisibility={handlePrivacyModalVisibility}
      />
    </Fragment>
  );
};

export default PrivacyTermsInfo;
