import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/react";

import { actions$ } from "common/shared-store/actions/actionsStream";
import { BenCardContent, Spinner } from "common/lib/components";

import { SimpleDocument } from "domains/document/components";
import { ActionTypes, getPublicDocumentUrl } from "domains/document/store/document.actions";
import { ParamsGetPublicDocumentUrl } from "domains/document/shared/types";

import { PropsPublicDocumentsList, TypePublicDocument } from "./types";
import "./public-documents-list.scss";

const uniqueBtnIdPrefix = "publicDocumentsList_";

export const documentList: TypePublicDocument[] = [
  {
    id: `${uniqueBtnIdPrefix}ppm`,
    title: <Trans id="publicDocumentsList.document.ppm.title">Private Placement Memorandum</Trans>,
    fileName: "ben-ppm-liquidity-bond.pdf",
  },
];

const PublicDocumentsList: React.FC<PropsPublicDocumentsList> = (
  props
): React.ReactElement | null => {
  const {
    anchoredIds,
    currentUserFullName,
    currentUserId,
    userDisplayId,
    currentUserIp,
    locale,
    timezone,
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.GetPublicDocumentUrlFail, ActionTypes.GetPublicDocumentUrlSuccess])
        .subscribe(() => {
          setLoading(false);
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const handleDownload = useCallback(
    (fileName: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
      if (!fileName) {
        return;
      }
      setLoading(true);

      const params: ParamsGetPublicDocumentUrl = {
        fileName,
        currentUserId,
        userDisplayId,
        currentUserFullName,
        currentUserIp,
        locale,
        timezone,
        version: "latest",
      };

      dispatch(getPublicDocumentUrl(params));
    },
    [dispatch, currentUserFullName, currentUserId, userDisplayId, currentUserIp, locale, timezone]
  );

  return (
    <div id={anchoredIds.documents}>
      <BenCardContent className="public-documents-list">
        <Spinner spinning={isLoading}>
          <div style={{ display: "none" }}>
            {documentList.map((item: TypePublicDocument) => {
              return (
                <SimpleDocument
                  title={item.title}
                  key={item.id}
                  uniqueBtnId={item.id}
                  handleDownload={handleDownload(item.fileName)}
                />
              );
            })}
          </div>
        </Spinner>
      </BenCardContent>
    </div>
  );
};

export default PublicDocumentsList;
