import React from "react";
import { Button, Tooltip } from "antd";

import { Trans } from "@lingui/react";

import { IconCustom } from "common/lib/components";

import { PropsUploadButton } from "./types";

export const UploadBtnTooltip = <Trans id="tooltip.btn.upload">Upload</Trans>;

const UploadButton: React.FC<PropsUploadButton> = (props): React.ReactElement => {
  const { uniqueBtnId, handleOnClick, disableUpload, isTooltipHidden } = props;

  return (
    <Tooltip
      placement="left"
      title={UploadBtnTooltip}
      {...(disableUpload && { visible: false })}
      {...(isTooltipHidden && { visible: false })}
    >
      <Button
        type="link"
        className="ben-upload-link ben-btn-reset"
        disabled={disableUpload}
        id={uniqueBtnId}
        onClick={handleOnClick}
      >
        <IconCustom type="upload" style={{ fontSize: 20, marginTop: -3 }} />
      </Button>
    </Tooltip>
  );
};

export default UploadButton;
