import React from "react";
import { Trans } from "@lingui/react";

import { Modal } from "antd";

import { PrivacyContent } from "domains/authentication/components";

import { PropsPrivacyModal } from "./types";

const privacyPolicy = <Trans id="PrivacyPolicy.Modal.title">Privacy Policy </Trans>;

const PrivacyModal: React.FC<PropsPrivacyModal> = (props): React.ReactElement => {
  const { isPrivacyModalVisible, handlePrivacyModalVisibility } = props;
  return (
    <Modal
      centered={true}
      title={privacyPolicy}
      visible={isPrivacyModalVisible}
      width={"100%"}
      style={{
        maxWidth: "620px",
      }}
      footer={false}
      wrapClassName="ben-modal-container"
      onCancel={handlePrivacyModalVisibility(false)}
    >
      <PrivacyContent />
    </Modal>
  );
};

export default PrivacyModal;
