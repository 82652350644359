import React, { Fragment, useCallback, useState } from "react";
import { Trans } from "@lingui/macro";
import { Button, Col, Form, Input, Row } from "antd";
import { RadioChangeEvent } from "antd/lib/radio";
import { useDispatch } from "react-redux";

import { getValidationErrors, localeService, sessionService } from "common/shared";
import { useForm } from "common/shared-hooks";
import { BenFormRowInfo } from "common/lib/components";
import { Constants } from "common/lib/constants";
import { createRegistrationRequest } from "domains/identity/store/identity.actions";

import { ExternalAccountTypeRadio } from "domains/clients";

import { PropsRegistrationForm } from "./types";
import {
  IndividualValidationSchema,
  InstitutionValidationSchema,
  RegistrationDisabledConfig,
  RegistrationFormSchema,
  RegistrationValidationSchemaDeps,
} from "./registration.form.config";

const BtnTitle = <Trans id="registrationForm.btn.submit">Submit</Trans>;
const TitleForm = <Trans id="registrationForm.form.title">Request Access</Trans>;
const defaultAccountType = Constants.EXTERNAL_ACCOUNT_TYPES.INDIVIDUAL;

const formDisableConfig = (sessionService.isAppian && RegistrationDisabledConfig) || {};
const formSchema = new RegistrationFormSchema(
  { accountType: defaultAccountType },
  formDisableConfig
);

const RegistrationForm: React.FC<PropsRegistrationForm> = (props): React.ReactElement | null => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [activeAccountType, setActiveAccountType] = useState<string>(defaultAccountType);
  const dispatch = useDispatch();

  const submitCallback = useCallback(
    (data: any) => {
      setLoading(true);
      data = {
        first_name: data.firstName,
        last_name: data.lastName,
        account_name: data.name,
        email: data.email,
        account_type: data.accountType,
        accepted_terms: false,
        white_label_instance: "default",
      };
      dispatch(createRegistrationRequest(data));
    },
    [dispatch]
  );

  const isCompanyAccountType = useCallback(() => {
    return activeAccountType === Constants.EXTERNAL_ACCOUNT_TYPES.INSTITUTION;
  }, [activeAccountType]);

  const getValidationSchema = useCallback(() => {
    return isCompanyAccountType() ? InstitutionValidationSchema : IndividualValidationSchema;
  }, [isCompanyAccountType]);

  const registrationFormConfig = useForm(
    formSchema,
    getValidationSchema(),
    submitCallback,
    RegistrationValidationSchemaDeps
  );

  const {
    state,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    isDisabledSubmit,
    isDirty,
    hasDirtyInvalidField,
  } = registrationFormConfig;

  const isGeneralFormValidationShown = useCallback(() => {
    return isDirty && hasDirtyInvalidField();
  }, [isDirty, hasDirtyInvalidField]);

  // handleOnChange is removed from dependency array
  /* eslint-disable react-hooks/exhaustive-deps */
  const handleChangeAccountType = useCallback((e: RadioChangeEvent) => {
    handleOnChange({ target: { name: "accountType", value: e.target.value } });
    setActiveAccountType(e.target.value);
  }, []);

  return (
    <Fragment>
      <div className="ben-panel-header">
        <h3 id="titleRegistrationForm">{TitleForm}</h3>
        {isGeneralFormValidationShown() ? (
          <BenFormRowInfo
            type="validation"
            classNames="ben-form-top-validation ben-form-top-validation-show"
          />
        ) : null}
      </div>
      {!isLoading ? (
        <Form>
          <div className="ben-border-bottom ben-mb-4">
            <Row gutter={30}>
              <Col sm={24} md={24}>
                <ExternalAccountTypeRadio
                  handleChange={handleChangeAccountType}
                  activeAccountType={activeAccountType}
                />
              </Col>
              <Col sm={24} md={24}>
                <Form.Item
                  className="ben-required-field"
                  {...getValidationErrors(
                    state.firstName.errors,
                    localeService.i18n._("form.personal.firstName")
                  )}
                >
                  <Input
                    name="firstName"
                    value={state.firstName.value}
                    placeholder={localeService.i18n._("form.personal.firstName")}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    disabled={state.firstName.isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={24}>
                <Form.Item
                  className="ben-required-field"
                  {...getValidationErrors(
                    state.lastName.errors,
                    localeService.i18n._("form.personal.lastName")
                  )}
                >
                  <Input
                    name="lastName"
                    value={state.lastName.value}
                    placeholder={localeService.i18n._("form.personal.lastName")}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    disabled={state.lastName.isDisabled}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={24}>
                <Form.Item
                  className="ben-required-field"
                  {...getValidationErrors(
                    state.email.errors,
                    localeService.i18n._("form.personal.email")
                  )}
                >
                  <Input
                    name="email"
                    value={state.email.value}
                    placeholder={localeService.i18n._("form.personal.email")}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    disabled={state.email.isDisabled}
                  />
                </Form.Item>
              </Col>
              {isCompanyAccountType() ? (
                <Col sm={24} md={24}>
                  <Form.Item
                    className="ben-required-field"
                    {...getValidationErrors(
                      state.name.errors,
                      localeService.i18n._("form.institution.name")
                    )}
                  >
                    <Input
                      name="name"
                      value={state.name.value}
                      placeholder={localeService.i18n._("form.institution.name")}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                      disabled={state.name.isDisabled}
                    />
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </div>
          <Row gutter={30}>
            <Col md={{ span: 24 }} className="ben-pb-2">
              <Button
                id="benRegistrationFormBtnSubmit"
                className="ben-w-100"
                shape="round"
                size="large"
                type="primary"
                disabled={sessionService.isAppian || isDisabledSubmit()}
                onClick={handleOnSubmit}
              >
                {BtnTitle}
              </Button>
            </Col>
          </Row>
        </Form>
      ) : (
        <p>
          Thank you for your request. We have sent you an email with instructions for accessing Ben
          Alt Access.
        </p>
      )}
    </Fragment>
  );
};

export default RegistrationForm;
