import React from "react";
import { RouteComponentProps } from "@reach/router";

import { PublicPageWrapper } from "../components";
import { ResendWelcomeEmail } from "domains/authentication";
import { useGetAppVersion } from "common/shared-hooks";

interface PropsResendWelcomeEmailPage extends RouteComponentProps {}

const ResendWelcomeEmailPage: React.FC<PropsResendWelcomeEmailPage> = (): React.ReactElement | null => {
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <ResendWelcomeEmail />
    </PublicPageWrapper>
  );
};

export default ResendWelcomeEmailPage;
