import React from "react";
import { Button } from "antd";

import { PropsRequestStatusesItem } from "./types";
import "./request-status-item.scss";

const RequestStatusesItem: React.FC<PropsRequestStatusesItem> = (
  props
): React.ReactElement | null => {
  const { currentStep, title, itemStep, isCancelled, isBase, handleModalVisibility } = props;

  if (!title) {
    return null;
  }

  let cancelClassName = "";
  let activeClassName = "";
  let currentClassName = "";

  if (isCancelled && isBase && itemStep <= currentStep) {
    cancelClassName = "cancel-base";
  }

  if (isCancelled && !isBase) {
    cancelClassName = "cancel-final";
    currentClassName = "current";
  }

  if (!isCancelled && itemStep <= currentStep) {
    activeClassName = "active";
  }

  if (!isCancelled && itemStep === currentStep) {
    currentClassName = "current";
  }

  return (
    <Button
      id={`requestStatusesItem-${itemStep}`}
      type="link"
      className={`request-statuses-item ${cancelClassName} ${activeClassName} ${currentClassName}`}
      onClick={handleModalVisibility}
    >
      {title}
    </Button>
  );
};

export default RequestStatusesItem;
