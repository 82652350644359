import React, { Fragment } from "react";
import { Typography, Icon } from "antd";

import { DragFileHelpMessage } from "domains/document/components";

import { PropsDocumentTitle } from "./types";

const { Paragraph } = Typography;

const DocumentTitle: React.FC<PropsDocumentTitle> = (props): React.ReactElement | null => {
  const {
    isUploadError,
    isShowFileHelpMessage,
    withClickAction,
    isDragger,
    title,
    statusTitle,
    text,
  } = props;

  return (
    <Fragment>
      {isDragger ? (
        <span className={"ben-document-title-drag-icon"}>
          <Icon type="plus" style={{ fontSize: 20 }} />
        </span>
      ) : null}

      <strong className={`ben-document-title ${isUploadError && "ben-document-title-error"}`}>
        {title} {statusTitle}
      </strong>
      {isShowFileHelpMessage ? <DragFileHelpMessage withClickAction={withClickAction} /> : null}
      {text && <Paragraph className="ben-document-text">{text}</Paragraph>}
    </Fragment>
  );
};

export default DocumentTitle;
