import React from "react";
import { Button, Tooltip } from "antd";
import { Trans } from "@lingui/react";

import { IconCustom } from "common/lib/components";

import { PropsUploadingText } from "./types";

const cancelBtnTooltip = <Trans id="tooltip.btn.cancel">Сancel</Trans>;

const UploadingText: React.FC<PropsUploadingText> = (props): React.ReactElement | null => {
  const { uploadProgress, isCancelEnabled, handleCancelUpload } = props;
  const isCancelDisabled = uploadProgress === 100 || !isCancelEnabled;

  if (!uploadProgress) {
    return null;
  }

  return (
    <div className="ben-d-flex ben-align-items-center ben-uploading-text">
      <span style={{ fontSize: 12 }}>
        <Trans id="documents.anotherDocument.uploadProgress" values={{ uploadProgress }}>
          Uploading {uploadProgress}
        </Trans>
      </span>
      <span className="ben-divider">|</span>
      <Tooltip
        placement="left"
        title={cancelBtnTooltip}
        {...(isCancelDisabled && { visible: false })}
      >
        <Button
          id="benUploadBtnCancel"
          type="link"
          disabled={isCancelDisabled}
          className="ben-btn-reset"
          onClick={handleCancelUpload}
        >
          <IconCustom type="cancel" style={{ fontSize: 20 }} />
        </Button>
      </Tooltip>
    </div>
  );
};

export default UploadingText;
