import React from "react";
import { Form } from "antd";

import { Options, Select } from "common/lib/components";
import { localeService } from "common/shared";
import { useOnLocaleChange } from "common/shared-hooks";

interface PropsClientTypeSelector {
  handleChange: (e: string) => void;
  activeAccountType: string;
}

const ClientTypeSelector = (props: PropsClientTypeSelector): React.ReactElement => {
  const { handleChange, activeAccountType } = props;

  const accountTypes = useOnLocaleChange(Options.accountTypes);

  const valueProps = activeAccountType ? { value: activeAccountType } : {};
  // props "key" on Select is used for refreshing the selected value
  return (
    <Form.Item
      className="ben-required-field ben-form-label-field"
      label={localeService.i18n._("clientType.label")}
    >
      <Select
        showSearch={true}
        optionFilterProp="children"
        filterOption={Options.filterByStartsWith}
        key={activeAccountType ? 1 : 2}
        placeholder={localeService.i18n._("clientType.placeholder")}
        style={{ width: "100%" }}
        onChange={handleChange}
        {...valueProps}
      >
        {accountTypes}
      </Select>
    </Form.Item>
  );
};
export default ClientTypeSelector;
