import React from "react";

import { RequestStatusesItem } from "domains/liquidity/components";
import { LiquidityRequestDisplayWithNameMap } from "domains/liquidity/shared/constants";
import { LiquidityRequestDisplayWithNameSetting } from "domains/liquidity/shared/types";

import { PropsRequestStatusesList } from "./types";
import "./request-statuses-list.scss";

const RequestStatusesList: React.FC<PropsRequestStatusesList> = (
  props
): React.ReactElement | null => {
  const { currentStep, currentStepCode, handleModalVisibility } = props;

  const { base, final, fallback } = LiquidityRequestDisplayWithNameMap;
  const baseSteps = Object.keys(base);
  let isCancelled = false;
  let finalStep: LiquidityRequestDisplayWithNameSetting;

  // Identify the final step to be displayed
  if (!currentStepCode) {
    finalStep = fallback;
  } else if (base[currentStepCode]) {
    finalStep = final.closed;
  } else if (final[currentStepCode]) {
    finalStep = final[currentStepCode];
  } else {
    finalStep = fallback;
  }

  if (currentStepCode === "cancelled") {
    isCancelled = true;
  }

  return (
    <div className="request-statuses-list">
      {baseSteps.map((status: string) => {
        const itemStep = LiquidityRequestDisplayWithNameMap.base[status].step;

        return (
          <RequestStatusesItem
            key={status}
            currentStep={currentStep}
            title={LiquidityRequestDisplayWithNameMap.base[status].title}
            itemStep={itemStep}
            isCancelled={isCancelled}
            isBase={true}
            handleModalVisibility={handleModalVisibility(
              LiquidityRequestDisplayWithNameMap.base[status].code
            )}
          />
        );
      })}

      <RequestStatusesItem
        currentStep={currentStep}
        title={finalStep.title}
        itemStep={finalStep.step}
        isCancelled={isCancelled}
        isBase={false}
        handleModalVisibility={handleModalVisibility(finalStep.code)}
      />
    </div>
  );
};

export default RequestStatusesList;
