import React, { Fragment } from "react";

import { CardHelpProps } from "./types";
import IconCustom from "../icon-custom";
import "./card-help.scss";

const CardHelp: React.FC<CardHelpProps> = (props): React.ReactElement | null => {
  const { title, subtitle, lastUpdate, children, noPanel } = props;

  const withoutPanel = (
    <div className="ben-card-help">
      {title && (
        <div className="ben-panel-header">
          <h3>{title}</h3>
        </div>
      )}

      {subtitle && (
        <div className="ben-card-help-info">
          <strong className="ben-card-help-subtitle">
            <IconCustom type="attention" />
            {subtitle}
          </strong>
          {lastUpdate && <em className="ben-card-help-date">{lastUpdate}</em>}
        </div>
      )}

      {children && <div className="ben-card-help-content">{children}</div>}
    </div>
  );

  const withPanel = <div className="ben-panel ben-pb-2">{withoutPanel}</div>;

  const getContent = () => {
    return noPanel ? withoutPanel : withPanel;
  };

  return <Fragment>{getContent()}</Fragment>;
};

export default CardHelp;
