import { CrossTabMessageService } from "common/shared/services/crossTabMessage/crossTabMessage.service";
import { ArrayUtils } from "common/shared/utils";

export interface TypeMessageEventsListenersMap {
  [key: string]: () => void;
}

export abstract class BaseActivityWatcherService {
  protected readonly maxInactivity: number = 60 * 30;
  protected readonly maxFreezeInactivityFor: number = 30;
  protected messageEventsListenersMap: TypeMessageEventsListenersMap = {};
  protected messageService: CrossTabMessageService;

  private lastActivityTime: number = 0;
  private activityEvents: string[] = ["mousedown", "keydown", "touchstart"];
  private timerCheckInactivity: number | null = null;
  private isStartedInactivityAction: boolean = false;
  private isEventsSet: boolean = false;
  private subscribedMessageEvents: string[] = [];

  constructor(messageService: CrossTabMessageService) {
    this.messageService = messageService;
  }

  public init() {
    this.isStartedInactivityAction = false;
    this.resetLastActivityTime();
    this.setUpEvents();
    this.setUpInactivityChecker();
    this.setUpListeners();
    this.setLastActivityTime(Date.now());
  }

  public reset() {
    this.removeInactivityChecker();
    this.resetLastActivityTime();
    this.removeEvents();
  }

  public handleContinueInactivityAction(expiredIn: number): void {
    this.isStartedInactivityAction = true;
    this.reset();
    this.makeActionOnContinueInactivity(expiredIn);
  }

  protected abstract makeActionOnSessionExpired(): void;
  protected abstract makeActionOnActivity(): void;
  protected abstract makeActionOnStartInactivity(expiredIn: number): void;
  protected abstract makeActionOnContinueInactivity(expiredIn: number): void;

  // reset the  lastActivityTime
  protected resetLastActivityTime(): void {
    this.setLastActivityTime(Date.now());
  }

  // The function that will be called whenever a user is active
  private activity() {
    this.resetLastActivityTime();
    this.makeActionOnActivity();
  }

  private setLastActivityTime(time: number): void {
    this.lastActivityTime = time;
  }

  // method to run every second to check inactivity
  private checkInactivity(): boolean {
    const secondsSinceLastActivity = Math.round((Date.now() - this.lastActivityTime) / 1000);

    // Check for sleeping browser when session modal is NOT open
    if (this.isSessionExpired()) {
      this.makeActionOnSessionExpired();
      this.reset();
      return false;
    }

    if (this.canStartInactivityAction(secondsSinceLastActivity)) {
      this.handleStartInactivityAction(this.getExpiredInCalculated(secondsSinceLastActivity));
      return false;
    }

    return true;
  }

  private setUpEvents(): boolean {
    if (this.isEventsSet) {
      return false;
    }

    this.activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, this.activity.bind(this), true);
    });
    this.isEventsSet = true;
    return true;
  }

  private removeEvents(): boolean {
    if (!this.isEventsSet) {
      return false;
    }
    this.activityEvents.forEach((eventName) => {
      document.removeEventListener(eventName, this.activity.bind(this), true);
    });
    this.isEventsSet = false;
    return true;
  }

  private setUpListeners(): boolean {
    if (ArrayUtils.isValidArrayData(this.subscribedMessageEvents)) {
      return false;
    }

    for (const event of Object.keys(this.messageEventsListenersMap)) {
      this.messageService.addListener(event, this.messageEventsListenersMap[event].bind(this));
      this.subscribedMessageEvents.push(event);
    }
    return true;
  }

  private handleStartInactivityAction(expiredIn: number): void {
    this.isStartedInactivityAction = true;
    this.reset();
    this.makeActionOnStartInactivity(expiredIn);
  }

  private canStartInactivityAction(secondsSinceLastActivity: number): boolean {
    return (
      !this.isStartedInactivityAction &&
      secondsSinceLastActivity > this.maxInactivity &&
      secondsSinceLastActivity < this.maxInactivity + this.maxFreezeInactivityFor
    );
  }

  private getExpiredInCalculated(secondsSinceLastActivity: number): number {
    return this.maxInactivity + this.maxFreezeInactivityFor + 1 - secondsSinceLastActivity;
  }

  private setUpInactivityChecker(): boolean {
    if (this.timerCheckInactivity) {
      return false;
    }
    this.timerCheckInactivity = window.setInterval(this.checkInactivity.bind(this), 1000);
    return true;
  }

  private removeInactivityChecker(): boolean {
    if (this.timerCheckInactivity) {
      clearInterval(this.timerCheckInactivity);
    }
    this.timerCheckInactivity = null;
    return true;
  }

  private isSessionExpired(): boolean {
    const timeSinceLastActivity = Math.round(
      (Date.now() - this.lastActivityTime - this.maxFreezeInactivityFor) / 1000
    );

    return timeSinceLastActivity > this.maxInactivity + this.maxFreezeInactivityFor;
  }
}
