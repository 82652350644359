import { ActionTypes } from "./identity.actions";
import { UserIdentity } from "common/shared/interfaces/user-identity";

export interface IdentityState {
  permissions: null | any;
  identity: null | UserIdentity;
  loading: boolean;
  isCompletedRequest: boolean;
  loggingOut: boolean;
}

export const initialState: IdentityState = {
  permissions: null,
  identity: null,
  loading: true,
  isCompletedRequest: false,
  loggingOut: false,
};

export const identity = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.Init:
    case ActionTypes.GetIdentity:
      return {
        ...state,
        loading: true,
        isCompletedRequest: false,
      };
    case ActionTypes.GetIdentitySuccess:
      return {
        ...state,
        identity: action.payload,
      };
    case ActionTypes.GetIdentityFail:
      return {
        ...state,
        loading: false,
        isCompletedRequest: true,
      };
    // case ActionTypes.CreateRegistrationRequest:
    //   return{
    //     ...state,
    //     loading: false,
    //   };
    case ActionTypes.CreateRegistrationSuccess:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.CreateRegistrationFail:
      return {
        ...state,
        loading: false,
      };
    case ActionTypes.GetPermissionsSuccess:
      return {
        ...state,
        loading: false,
        permissions: action.payload,
        isCompletedRequest: true,
      };
    case ActionTypes.GetPermissionsFail:
      return {
        ...state,
        loading: false,
        isCompletedRequest: true,
      };
    case ActionTypes.RemovePermissions:
      return {
        ...state,
        loading: false,
        permissions: null,
        isCompletedRequest: true,
      };
    case ActionTypes.Logout:
      return {
        ...state,
        loggingOut: true,
      };
    case ActionTypes.LogoutFail:
      return {
        ...state,
        loggingOut: false,
      };
    case ActionTypes.LogoutSuccess:
      return {
        ...initialState,
        isCompletedRequest: true,
        loggingOut: false,
      };

    default:
      return state;
  }
};
