import {
  getTransactionDocumentsEpic,
  getUncategorizedDocumentsEpic,
  getDocumentUrlEpic,
  getDocumentsByHoldingEpic,
  confirmUploadEpic,
  setIdontHaveThisEpic,
  getPublicDocumentUrlEpic,
} from "./store/document.epics";
import { ParamsGetDocuments } from "domains/document/shared/types";
import {
  PublicDocumentsList,
  SimpleDocument,
  TransactionSection,
  HoldingsDocumentsModal,
  InstructionalVerbiage,
} from "./components";

export {
  TransactionSection,
  getTransactionDocumentsEpic,
  getUncategorizedDocumentsEpic,
  getDocumentUrlEpic,
  getDocumentsByHoldingEpic,
  confirmUploadEpic,
  setIdontHaveThisEpic,
  PublicDocumentsList,
  SimpleDocument,
  getPublicDocumentUrlEpic,
  HoldingsDocumentsModal,
  InstructionalVerbiage,
};

// Example of re-export interface
export interface ParamsGetDocuments extends ParamsGetDocuments {}
