import { RcFile } from "antd/lib/upload/interface";

import { CreateDocumentResponseData } from "domains/document/shared/types";

import apiService from "common/shared/services/api.service/api.service";
import fileService from "common/shared/services/file/file.service";
import documentAPIService from "domains/document/shared/document.api.service";
import messageService from "common/shared/services/message/message.service";

import MessageConfig from "common/shared/interfaces/message-config";

import {
  ParamUploadFileRequest,
  ParamUploadDirectoryDocumentRequest,
  ParamUploadDocumentRequest,
  ParamValidateFile,
} from "./fileUploader.types";
import { CANCEL_MSG, MAX_FILE_SIZE, MIN_TIMEOUT, SPEED } from "./fileUploader.constants";

export default class FileUploaderService {
  static async uploadFileRequest({
    file,
    headers,
    onProgress,
    onError,
    uploadUrl,
    fileExtension,
    cancelToken,
    isCustomFlow,
    cancelEnablingHandler,
    onCustomFlowSuccessCallbackHandler,
    onSuccessCallbackHandler,
    onCancelUploadError,
  }: ParamUploadFileRequest) {
    const binaryFile = new Blob([file], { type: fileExtension || "" });

    if (!uploadUrl) {
      return;
    }

    if (cancelEnablingHandler) {
      cancelEnablingHandler(true);
    }

    try {
      await apiService.putResource(uploadUrl, binaryFile, {
        headers,
        // disable interceptor default headers
        handlerEnabled: false,
        onUploadProgress: (e: any) => {
          onProgress({ percent: (e.loaded / e.total) * 100 }, file);
        },
        cancelToken,
        timeout: file.size / SPEED + MIN_TIMEOUT,
      });

      if (isCustomFlow && onCustomFlowSuccessCallbackHandler) {
        onCustomFlowSuccessCallbackHandler();
        return;
      }

      onSuccessCallbackHandler();
    } catch (error) {
      if (FileUploaderService.isErrorOnCancelUpload(error)) {
        onCancelUploadError();
        return;
      }
      return onError(error);
    }
  }

  static async updateDocumentRequest({
    config,
    file,
    setCreatedDocumentHandler,
    uploadErrorHandler,
    cancelToken,
  }: ParamUploadDocumentRequest) {
    const { name, size } = file;

    try {
      const response = await documentAPIService.updateDocument(
        {
          fileName: name,
          fileSize: size,
          ...config,
        },
        {
          cancelToken,
        }
      );
      setCreatedDocumentHandler(response);
    } catch (error) {
      if (FileUploaderService.isErrorOnCancelUpload(error)) {
        return;
      }

      uploadErrorHandler();
    }
  }

  static async createDocumentRequest({
    config,
    file,
    setCreatedDocumentHandler,
    uploadErrorHandler,
    cancelToken,
  }: ParamUploadDocumentRequest) {
    const { name, size } = file;

    try {
      const response: CreateDocumentResponseData | null = await documentAPIService.createDocument(
        {
          fileName: name,
          fileSize: size,
          liquidityRequestId: config.liquidityRequestId,
          replaceDocumentId: config.documentId,
          ...config,
        },
        {
          cancelToken,
        }
      );
      setCreatedDocumentHandler(response);
    } catch (error) {
      if (FileUploaderService.isErrorOnCancelUpload(error)) {
        return;
      }
      uploadErrorHandler();
    }
  }

  static async createDocumentRequestWithResponse({
    config,
    file,
    uploadErrorHandler,
    cancelToken,
  }: ParamUploadDirectoryDocumentRequest) {
    const { name, size } = file;

    try {
      const response: CreateDocumentResponseData | null = await documentAPIService.createDocument(
        {
          fileName: name,
          fileSize: size,
          liquidityRequestId: config.liquidityRequestId,
          replaceDocumentId: config.documentId,
          ...config,
        },
        {
          cancelToken,
        }
      );

      return response;
    } catch (error) {
      if (FileUploaderService.isErrorOnCancelUpload(error)) {
        return;
      }

      uploadErrorHandler();
    }
  }

  static getExtensionFromFile(file: File): string {
    const { name } = file;
    let extension = name.split(".").pop() || "";
    extension = extension.toLowerCase();
    return extension;
  }

  static canUploadFile({ file, docFormats, isValidateName }: ParamValidateFile): boolean {
    if (
      !fileService.validateFile(file, {
        maxSize: MAX_FILE_SIZE,
        formats: docFormats || fileService.defaultDocFormats,
        validateName: isValidateName,
      })
    ) {
      return false;
    }
    return true;
  }

  static isSystemFile(file: RcFile): boolean {
    const parts = file.name.split(".");
    if (parts[0]) {
      return false;
    }

    return true;
  }

  static showFileUploadSuccessMessage(fileName: string | React.ReactElement): void {
    messageService.success(
      new MessageConfig("toaster.fileUpload.success", [{ name: "fileName", value: fileName }])
    );
  }

  static showFileUploadCanceledMessage = (fileName: string | null): void => {
    messageService.info(
      new MessageConfig("toaster.fileUpload.canceled", [{ name: "fileName", value: fileName }])
    );
  };

  static isErrorOnCancelUpload(e: Error): boolean {
    return Boolean(e && e.message && e.message === CANCEL_MSG);
  }
}
