import React from "react";
import { Trans } from "@lingui/react";

import { BenPhone } from "common/lib/components";
import { BenInfo } from "common/lib/constants";

const descriptionForm = (
  <Trans id="signin.form.description">
    Ben provide private trust solutions, including a unique suite of lending and liquidity products,
    to bring liquidity to owners of alternative assets. If you would like help navigating Ben
    Online, please contact your Originations Representative. You can also contact us by phone at
  </Trans>
);

export const labelORDescriptionForm = <Trans id="label.OR">or via email at</Trans>;

interface PropsDescriptionSignin {}

const DescriptionSignin: React.FC<PropsDescriptionSignin> = (): React.ReactElement => {
  return (
    <p id="descriptionSigninForm">
      {descriptionForm} <BenPhone /> {labelORDescriptionForm} {[BenInfo.EMAILS.portalHelp]}
    </p>
  );
};

export default DescriptionSignin;
