import {
  ClientBtnNew,
  ClientControls,
  ClientDetailsPanel,
  ClientDetailsTitle,
  ClientsList,
  ClientTypeSelector,
  GreetingProfile,
  InstitutionSectionWrapper,
  ProfilePersonalSection,
  ProfileSecuritySection,
  ExternalAccountTypeRadio,
} from "./components/";
import {
  AddressFormReturnedData,
  IndividualForm,
  PhoneFormReturnedData,
  InstitutionalForm,
} from "domains/clients/forms";

import { ActionTypes, changePassword, getClient } from "./store/clients.actions";
import User from "./store/user.model";
import { useClientData, useClientListData, useProfileData, useUserSettingsData } from "./hooks";
import { TypeClientCombinedItem } from "./shared/types";

export interface AddressFormReturnedData extends AddressFormReturnedData {}
export interface PhoneFormReturnedData extends PhoneFormReturnedData {}
export interface ClientCombinedItem extends TypeClientCombinedItem {}

export {
  ActionTypes,
  changePassword,
  getClient,
  ClientBtnNew,
  ClientControls,
  ClientDetailsPanel,
  ClientDetailsTitle,
  ClientsList,
  ClientTypeSelector,
  IndividualForm,
  InstitutionalForm,
  GreetingProfile,
  useClientData,
  useClientListData,
  useProfileData,
  useUserSettingsData,
  User,
  InstitutionSectionWrapper,
  ProfilePersonalSection,
  ProfileSecuritySection,
  ExternalAccountTypeRadio,
};
