import { Subject } from "rxjs";
import { navigate } from "@reach/router";

import Tokens from "common/shared/interfaces/tokens";
import { UserIdentity } from "common/shared/interfaces/user-identity";
import authService from "domains/authentication/shared/authentication.service";
import { tokenDecodeUtils, ImpersonatePayloadType } from "common/shared";
import { Urls } from "common/lib/constants";
import { BrowserSessionService } from "../browserSession/browserSession.service";
import { SessionExpirationService } from "../sessionExpiration/sessionExpiration.service";

export interface TypeSessionData {
  tokens: Tokens | null;
  userName: string;
  envClientId: string;
  sessionExpiredAt: string | null;
  sessionId: string | null;
}

export const storageEventKeys = {
  SESSION_USERNAME: "SESSION_USERNAME",
  CREDENTIALS_TOKEN: "CREDENTIALS_TOKEN",
  REQUESTING_SHARED_CREDENTIALS: "REQUESTING_SHARED_CREDENTIALS",
  CREDENTIALS_SHARING: "CREDENTIALS_SHARING",
  CREDENTIALS_FLUSH: "CREDENTIALS_FLUSH",
  SESSION_IS_APPIAN: "SESSION_IS_APPIAN",
  IMPERSONATE_PAYLOAD: "IMPERSONATE_PAYLOAD",
  SESSION_EXPIRED_AT: "SESSION_EXPIRED_AT",
  SESSION_ID: "SESSION_ID",
};

export class SessionService {
  public token$: Subject<any> = new Subject();
  private tokens: Tokens | null;
  private userName = "";
  private userId = "";
  private accountId = "";
  private envClientId = "";
  private _isAppian = false;
  private _isMaintenance = false;
  private _version = "";
  private timerForRefreshAccessToken: number | null = null;
  private expirationService: SessionExpirationService;
  private browserSessionService: BrowserSessionService;

  constructor(browserService: BrowserSessionService, expirationService: SessionExpirationService) {
    this.tokens = this.getTokensFromStore();
    this.userName = this.getUserNameFromStore();
    this.isAppian = this.getIsAppianFromStore();
    this.browserSessionService = browserService;
    this.expirationService = expirationService;
    this._isMaintenance = false;
    this._version = "";

    // Runs after load page process
    this.initRefreshAccessToken();
    this.token$.next(this.tokens);
  }

  public setTokens(tokens: Tokens, envClientId: string, browserSessionId: string) {
    this.tokens = tokens;
    this.tokens.created_at = this.getCurrentUnixTime();
    const { clientId } = tokenDecodeUtils.decodeAuthToken(tokens.access_token);
    this.isAppian = Boolean(envClientId && clientId !== envClientId);
    this.setEnvClientId(envClientId);
    this.browserSessionService.setSessionId(browserSessionId);

    this.token$.next(this.tokens);

    window.sessionStorage.setItem(storageEventKeys.CREDENTIALS_TOKEN, JSON.stringify(tokens));
    // Runs after sign in process
    if (this.tokens) {
      this.initRefreshAccessToken();
    }
  }

  public getTokens() {
    return this.tokens;
  }

  set isAppian(value: boolean) {
    this._isAppian = value;
    window.localStorage.setItem(
      storageEventKeys.SESSION_IS_APPIAN,
      (value && value.toString()) || ""
    );
  }

  get isAppian(): boolean {
    return this._isAppian;
  }

  public setIsMaintenance(value: boolean) {
    this._isMaintenance = value;
  }

  public isMaintenance() {
    return this._isMaintenance;
  }

  public setVersion(version: string) {
    this._version = version;
  }

  public getVersion(): string {
    return this._version;
  }
  public setUserName(userName: string) {
    this.userName = userName;
    window.sessionStorage.setItem(storageEventKeys.SESSION_USERNAME, userName);
  }

  public getUserName(): string {
    return this.userName;
  }

  public syncBrowserSessionId(): boolean {
    return this.browserSessionService.setOnWindowSessionId();
  }

  public getGeneratedBrowserSessionId(): string {
    return this.browserSessionService.getGeneratedSessionId();
  }

  public isSameUserSession(): boolean {
    return this.browserSessionService.isSameBrowserSession();
  }

  public setImpersonatePayloadToStore(tokens: ImpersonatePayloadType) {
    window.sessionStorage.setItem(storageEventKeys.IMPERSONATE_PAYLOAD, JSON.stringify(tokens));
  }

  public getImpersonatePayloadFromStore() {
    const stringifiedTokens = window.sessionStorage.getItem(storageEventKeys.IMPERSONATE_PAYLOAD);

    return this.stringifyTokens(stringifiedTokens);
  }

  public setSessionIds({ accountId, userId }: UserIdentity) {
    this.userId = userId;
    this.accountId = accountId;
  }

  public getSessionIds(): { userId: string; accountId: string } {
    return { accountId: this.accountId, userId: this.userId };
  }

  public clearSession() {
    this.tokens = null;
    this.userName = "";
    this.isAppian = false;
    this.setEnvClientId("");

    // Clear sessionStorage
    window.sessionStorage.removeItem(storageEventKeys.CREDENTIALS_TOKEN);
    window.sessionStorage.removeItem(storageEventKeys.SESSION_USERNAME);
    window.sessionStorage.removeItem(storageEventKeys.IMPERSONATE_PAYLOAD);
    // Clear localStorage
    window.localStorage.removeItem(storageEventKeys.SESSION_IS_APPIAN);

    this.browserSessionService.clearBrowserSession();
    this.expirationService.stopExpirationProcess();

    if (this.timerForRefreshAccessToken) {
      clearTimeout(this.timerForRefreshAccessToken);
    }

    this.token$.next(this.tokens);
  }

  public isAuthenticated = (): boolean => {
    const tokens = this.getTokens();
    if (tokens && tokens.access_token && !tokenDecodeUtils.isTokenExpired(tokens.access_token)) {
      return true;
    }

    return false;
  };

  public getSessionData(): TypeSessionData | null {
    const tokens = this.getTokens();
    const envClientId = this.getEnvClientId();
    const userName = this.getUserName();
    const sessionExpiredAt = this.expirationService.getExpiredAt() || "";
    const sessionId = this.browserSessionService.getSessionId();

    if (!tokens || !userName) {
      return null;
    }
    const sessionData = {
      tokens,
      userName,
      envClientId,
      sessionExpiredAt: String(sessionExpiredAt),
      sessionId,
    };

    return sessionData;
  }

  public setSessionData(sessionData: TypeSessionData | null): boolean {
    if (!sessionData) {
      return false;
    }
    const { tokens, envClientId, userName, sessionExpiredAt, sessionId } = sessionData;
    if (!tokens || !userName || !sessionId) {
      return false;
    }
    this.setUserName(userName);
    this.setTokens(tokens, envClientId, sessionId);
    if (sessionExpiredAt) {
      this.expirationService.setExpiredAt(sessionExpiredAt);
    }

    return true;
  }

  public setSessionDataFromString(data?: string): boolean {
    if (!data) {
      return false;
    }
    const sessionData = this.stringifyTokens(data);

    return this.setSessionData(sessionData);
  }

  public startExpirationProcess(remainingSeconds: number): boolean {
    return this.expirationService.startExpirationProcess(remainingSeconds);
  }

  public stopExpirationProcess(): void {
    this.expirationService.stopExpirationProcess();
  }

  public isSessionExpired(): boolean {
    return this.expirationService.isSessionExpired();
  }

  public getSessionExpiredIn(): number | null {
    return this.expirationService.getExpiredInSeconds();
  }

  public getRefreshToken(): string {
    if (!this.tokens || !this.tokens.refresh_token) {
      return "";
    }
    return this.tokens.refresh_token;
  }

  public updateAccessToken(accessToken: string) {
    if (!accessToken || !this.tokens) {
      this.closeSession();
      return;
    }

    this.tokens.access_token = accessToken;
    this.tokens.created_at = this.getCurrentUnixTime();
    window.sessionStorage.setItem(storageEventKeys.CREDENTIALS_TOKEN, JSON.stringify(this.tokens));
    this.initRefreshAccessToken();
  }

  private getEnvClientId(): string {
    return this.envClientId;
  }

  private setEnvClientId(envClientId: string): void {
    this.envClientId = envClientId;
  }

  private async runRefreshAccessToken() {
    if (!this.tokens || !this.tokens.refresh_token) {
      this.closeSession();
      return;
    }

    const payload = { refreshToken: this.tokens.refresh_token };
    let result;
    try {
      result = await authService.refreshAccessToken(payload);
    } catch (e) {
      this.closeSession();
      navigate(Urls.AUTH.SIGN_IN);
      return;
    }

    if (!result || !result.accessToken) {
      this.closeSession();
      return;
    }
    this.updateAccessToken(result.accessToken);
  }

  /**
   * Set up a function to be executed after some time
   */
  private initRefreshAccessToken() {
    if (!this.tokens || !this.tokens.expires_in || !this.tokens.created_at) {
      this.closeSession();
      return;
    }
    const minSecondsToRefresh = this.tokens.expires_in * 0.9;
    const currentTime = this.getCurrentUnixTime();
    const secondsFromLastRefresh = currentTime - this.tokens.created_at;
    const nextUpdateIn = (this.tokens.created_at + minSecondsToRefresh - currentTime) * 1000;

    // case when page is being reloaded and token was not updated
    if (secondsFromLastRefresh > minSecondsToRefresh) {
      this.runRefreshAccessToken();
    }

    this.timerForRefreshAccessToken = window.setTimeout(
      () => this.runRefreshAccessToken(),
      nextUpdateIn
    );
  }

  private getCurrentUnixTime() {
    return Math.round(new Date().getTime() / 1000);
  }

  private closeSession() {
    this.clearSession();
  }

  private getUserNameFromStore() {
    return window.sessionStorage.getItem(storageEventKeys.SESSION_USERNAME) || "";
  }

  private getIsAppianFromStore() {
    return !!window.localStorage.getItem(storageEventKeys.SESSION_IS_APPIAN);
  }

  private getTokensFromStore() {
    const stringifiedTokens = window.sessionStorage.getItem(storageEventKeys.CREDENTIALS_TOKEN);

    return this.stringifyTokens(stringifiedTokens);
  }

  private stringifyTokens(stringifiedTokens: string | null) {
    let tokens;

    try {
      tokens = stringifiedTokens && JSON.parse(stringifiedTokens);
    } catch {
      tokens = null;
    }

    return tokens;
  }
}

export const sessionService = new SessionService(
  new BrowserSessionService(storageEventKeys.SESSION_ID),
  new SessionExpirationService(storageEventKeys.SESSION_EXPIRED_AT)
);
