import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Spinner } from "common/lib/components";
import { actions$ } from "common/shared-store/actions/actionsStream";
import { sessionService } from "common/shared";

import { VerifyInvestorModal } from "domains/authentication";

import { DocumentItemData, CreateDocumentResponseData } from "domains/document/shared/types";
import {
  getDocumentUrl,
  ActionTypes,
  getTransactionDocuments,
  addTransactionDocument,
} from "domains/document/store/document.actions";
import { AddDocument } from "domains/document/components";
import {
  OtherTransactionDocuments,
  AppianTransactionDocuments,
} from "domains/document/documents-list";

import { PropsTransactionDocuments } from "./types";
import "./styles.scss";
import { HeaderBenSection, HeaderClientSection, DocumentsVerbiage } from "./constants";

const TransactionDocuments: React.FC<PropsTransactionDocuments> = (
  props
): React.ReactElement | null => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDocUploading, setDocUploading] = useState<boolean>(false);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.GetDocumentUrlFail, ActionTypes.GetDocumentUrlSuccess])
        .subscribe(() => {
          setLoading(false);
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const getDocuments = useCallback(() => {
    if (!props.accountId || !props.liquidityRequestId) {
      return;
    }

    dispatch(
      getTransactionDocuments({ accountId: props.accountId, requestId: props.liquidityRequestId })
    );
  }, [dispatch, props.accountId, props.liquidityRequestId]);

  const handleUploadSuccess = ({ documentName, documentId }: CreateDocumentResponseData) => {
    setDocUploading(false);

    if (!documentId || !documentName) {
      return;
    }

    const newItem: DocumentItemData = {
      assetId: "",
      dateProvided: "",
      doNotHaveThis: false,
      documentName,
      documentType: "other",
      id: documentId,
      investmentId: "",
      liquidityRequestId: props.liquidityRequestId,
      periodEndingDate: "",
      referenceType: "liquidity_request",
      source: "client_portal",
      displayId: "",
    };

    dispatch(addTransactionDocument({ newItem }));
  };

  const onReUploadSuccess = () => {
    setDocUploading(false);
    getDocuments();
  };

  const onReUploadStart = () => {
    setDocUploading(true);
  };

  const onReUploadCancel = () => {
    setDocUploading(false);
  };

  const onReUploadError = () => {
    setDocUploading(false);
    setLoading(false);
  };

  const onUploadAddDocumentError = () => {
    setDocUploading(false);
  };

  const handleDownload = useCallback(
    (documentId: string) => () => {
      if (!documentId || !props.accountId) {
        return;
      }
      setLoading(true);
      dispatch(
        getDocumentUrl({
          accountId: props.accountId,
          documentId,
          version: "latest",
        })
      );
    },
    [dispatch, props.accountId]
  );

  const {
    accountId,
    liquidityRequestId,
    liquidityStatus,
    standardDocuments,
    otherDocuments,
    otherAppianDocuments,
  } = props;

  const isDisabledUpload = sessionService.isAppian || isDocUploading;

  return (
    <div className="ben-documents-content">
      <h4>{HeaderBenSection}</h4>
      {isLoading && (
        <div className="ben-documents-content-backdrop">
          <Spinner />
        </div>
      )}

      <AppianTransactionDocuments
        standardDocuments={standardDocuments}
        otherAppianDocuments={otherAppianDocuments}
        liquidityStatus={liquidityStatus}
        handleDownload={handleDownload}
      />

      <h4 className="ben-pt-2">{HeaderClientSection}</h4>
      <h4 className="ben-pt-2 ben-required-label">{DocumentsVerbiage}</h4>

      <OtherTransactionDocuments
        otherDocuments={otherDocuments}
        accountId={accountId}
        liquidityRequestId={liquidityRequestId}
        handleDownload={handleDownload}
        disableUpload={isDisabledUpload}
        disableActions={!!sessionService.isAppian}
        onReUploadSuccess={onReUploadSuccess}
        onReUploadError={onReUploadError}
        onReUploadStart={onReUploadStart}
        onReUploadCancel={onReUploadCancel}
      />

      <AddDocument
        onUploadSuccess={handleUploadSuccess}
        onUploadStart={onReUploadStart}
        onUploadCancel={onReUploadCancel}
        onUploadError={onUploadAddDocumentError}
        accountId={accountId}
        investmentId={null}
        assetId={null}
        liquidityRequestId={liquidityRequestId}
        disableUpload={isDisabledUpload}
        referenceType="liquidity_request"
      />

      <VerifyInvestorModal />
    </div>
  );
};

export default TransactionDocuments;
