import { useSelector } from "react-redux";
import { LiquidityStore, SingleLiquidityAlertsDataProps } from "../shared/types";

function useSingleLiquidityAlertsData(): SingleLiquidityAlertsDataProps {
  const result = useSelector(
    (state: any): SingleLiquidityAlertsDataProps => {
      const liquidityState: LiquidityStore = state.liquidity;
      const {
        singleAlerts: { data, loading },
      } = liquidityState;

      if (!data || !data.items) {
        return {
          items: null,
          loading,
        };
      }

      return {
        items: data.items,
        loading,
      };
    }
  );

  return result;
}

export default useSingleLiquidityAlertsData;
