import { ActionTypes } from "./auth.actions";
import { ActionTypes as identityActions } from "domains/identity";

export interface AuthState {
  forgotPassLoading: boolean;
  loading: boolean;
  loginError: boolean;
}

export const initialState: AuthState = {
  forgotPassLoading: false,
  loading: false,
  loginError: false,
};

export const authentication = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.SignIn:
      return {
        ...state,
        loading: true,
        loginError: false,
      };

    case ActionTypes.SignInSuccess:
      return {
        ...state,
        loading: false,
        loginError: false,
      };
    case ActionTypes.SignInFail:
    case ActionTypes.SignInFailUserLocked:
      return {
        ...state,
        loading: false,
        loginError: true,
      };
    case ActionTypes.ResetLoginError:
      return {
        ...state,
        loginError: false,
      };
    case ActionTypes.ForgotPassword:
    case ActionTypes.ResetPassword:
      return {
        ...state,
        forgotPassLoading: true,
      };
    case ActionTypes.ForgotPasswordSuccess:
    case ActionTypes.ForgotPasswordFail:
    case ActionTypes.ResetPasswordSuccess:
    case ActionTypes.ResetPasswordFail:
      return {
        ...state,
        forgotPassLoading: false,
      };
    case identityActions.Logout:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};
