import React from "react";
import { Trans } from "@lingui/react";

import { portalEnvConstants } from "common/shared";
import { useSelectedLocaleData } from "common/shared-hooks";
import { DateUtils } from "common/shared/utils";
import { useProfileData } from "domains/clients";

import { useLastLogin } from "domains/identity/hooks";

import "./login-profile-data.scss";

const LoginProfileData: React.FC = (): React.ReactElement | null => {
  const { user } = useProfileData();
  const { lang } = useSelectedLocaleData();
  const lastLogin = useLastLogin();

  const dateToDisplay = DateUtils.formatDate(lastLogin, lang);
  const timeToDisplay = DateUtils.formatTime(lastLogin, lang);

  if (!user) {
    return null;
  }

  return (
    <div className="ben-account">
      <strong className="ben-account-number">
        <Trans id="dashboard.header.account" /> {user.accountDisplayId}
      </strong>
      <span className="ben-divider">|</span>
      <Trans id="dashboard.header.last.login" /> {dateToDisplay}{" "}
      <Trans id="dashboard.header.last.login.atTime">at</Trans> {timeToDisplay}{" "}
      {portalEnvConstants.defaultTimezone}
    </div>
  );
};

export default LoginProfileData;
