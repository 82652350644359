import React from "react";
import { Trans } from "@lingui/macro";

const Title = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.title">
    Please provide as many of the following documents as you can for each investment.
  </Trans>
);
const DocumentPPM = <Trans id="documents.uploadDirectory.instructionalVerbiage.PPM">PPM</Trans>;
const DocumentSubscriptionAgreement = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.subscriptionAgreement">
    Subscription agreement
  </Trans>
);
const DocumentLPA = <Trans id="documents.uploadDirectory.instructionalVerbiage.LPA">LPA</Trans>;
const DocumentK1 = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.K1">K-1/1099’s</Trans>
);
const DocumentAudited = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.audited">
    Last two years of audited financials
  </Trans>
);
const DocumentQuarterlyStatements = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.quarterlyStatements">
    Last four quarters of quarterly statements
  </Trans>
);
const DocumentCapitalStatements = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.capitalStatements">
    Last four quarters of capital statements
  </Trans>
);
const DocumentOther = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.other">
    Any other documentation available
  </Trans>
);

const ModalTitle = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.modal.title">
    Asset Documentation
  </Trans>
);
const ModalBtnOk = (
  <Trans id="documents.uploadDirectory.instructionalVerbiage.modal.btn.ok">OK</Trans>
);

export const Messages = {
  Title,
  DocumentPPM,
  DocumentSubscriptionAgreement,
  DocumentLPA,
  DocumentK1,
  DocumentAudited,
  DocumentQuarterlyStatements,
  DocumentCapitalStatements,
  DocumentOther,
  ModalTitle,
  ModalBtnOk,
};
