import { useSelector } from "react-redux";

import { TypeGetClientsParams } from "domains/clients/shared/types";

interface ClientsRequestParamsDataProps {
  params: TypeGetClientsParams;
}

function useClientsRequestParamsData(): ClientsRequestParamsDataProps {
  const response = useSelector(
    (state: any): ClientsRequestParamsDataProps => {
      const { list } = state.clients;
      return {
        params: list.requestParams,
      };
    }
  );

  return response;
}

export default useClientsRequestParamsData;
