import React from "react";
import { Select as AntSelect } from "antd";
import classnames from "classnames";

import "./select.scss";

import downIcon from "common/assets/images/icons/caret_down.svg";

const Select = (props: any) => {
  const { children, className } = props;
  const classes = classnames("ben-select", {
    [`${className}`]: className,
  });

  return (
    <AntSelect suffixIcon={<img src={downIcon} alt="" />} {...props} className={classes}>
      {children}
    </AntSelect>
  );
};

export default Select;
