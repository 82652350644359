import { setupI18n } from "@lingui/core";
import { catalogs } from "common/locales/catalogs";

interface PluralProps {
  value: number | null;
  one: string;
  other: string;
  zero?: string;
}

export class LocaleService {
  public i18n = setupI18n({ catalogs });

  // temporary fix, need further investigation in future
  // js-lingui issue https://github.com/lingui/js-lingui/issues/437
  // i18n.plural is undefined in production mode
  public plural = ({ value, one, other, zero }: PluralProps) => {
    if (value === 0 && zero) {
      return zero;
    }

    if (!value) {
      return null;
    }

    if (value === 1 && one) {
      return one;
    }

    return other;
  };
}

const localeService = new LocaleService();
export default localeService;
