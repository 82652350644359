import React, { useState, useEffect, useCallback } from "react";

import { ArrayUtils, DomUtils, FunctionUtils } from "common/shared/utils";
import { Constants } from "common/lib/constants";

import { PropsAnchoredMenu, TypeAnchorLink } from "./types";
import "./anchored-menu.scss";

const AnchoredMenu: React.FC<PropsAnchoredMenu> = ({ items }): React.ReactElement | null => {
  const [itemsList, setItemsList] = useState(items);
  const [activeItem, setActiveItem] = useState(items && items[0] ? items[0].id : null);

  useEffect(() => {
    setItemsList(items);
  }, [items]);

  const handleScrollDebounced = useCallback(
    FunctionUtils.debounce(() => {
      itemsList.some((anchor: TypeAnchorLink) => {
        const el: HTMLElement | null = document.getElementById(anchor.id);
        if (!el) {
          return false;
        }
        const options = {
          // 115 is hardcoded header height
          pageYOffsetDeflection: Constants.HEADER_HEIGHT,
          // suppose the element is out of view if half of it's height is out of view
          heightDeflection: -el.offsetHeight + el.offsetHeight / 2,
        };

        if (DomUtils.elementInViewport(anchor.id, options)) {
          setActiveItem(anchor.id);
          return true;
        }

        return false;
      });
    }, 100),
    [itemsList]
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScrollDebounced);
    return () => {
      window.removeEventListener("scroll", handleScrollDebounced);
    };
  }, [handleScrollDebounced]);

  if (!ArrayUtils.isValidArrayData(items)) {
    return null;
  }

  // offsetTop is a padding-top from .ben-main-container
  function scrollToTargetAdjusted(id: string, offsetTop: number) {
    const element: HTMLElement | null = document.getElementById(id);

    if (element) {
      const bodyRect: number = document.body.getBoundingClientRect().top;
      const elementRect: number = element.getBoundingClientRect().top;

      const elementPosition: number = elementRect - bodyRect;
      const offsetPosition: number = elementPosition - offsetTop;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  }

  return (
    <div className="ben-sidebar-menu">
      <div className="ant-anchor-wrapper">
        <div className="ant-anchor">
          {itemsList.map((item: TypeAnchorLink) => (
            <div className="ant-anchor-link" key={item.id}>
              <a
                href={`#${item.id}`}
                id={`benNavAnchorLink${item.id}`}
                className={`ant-anchor-link-title ${
                  item.id === activeItem ? "ant-anchor-link-title-active" : ""
                }`}
                onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                  e.preventDefault();
                  // don't handle click if item is already active
                  if (item.id !== activeItem) {
                    setActiveItem(item.id);
                    scrollToTargetAdjusted(item.id, 125);
                  }
                }}
              >
                {item.title}
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AnchoredMenu;
