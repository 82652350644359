import { AcceptRequestSharingData } from "domains/liquidity";
import { ResendWelcomeEmailData } from "domains/authentication";

export const ActionTypes = {
  SetNavigateToAfterLogin: "[Bootstrap] Set NavigateToAfterLogin",
  SetAcceptedRequestSharingData: "[Bootstrap] Set AcceptedRequestSharingData",
  SetTokenForPass: "[Bootstrap] Set TokenForPass",
  SetAcceptedIdentityData: "[Bootstrap] Set AcceptedIdentityData",
  SetAcceptIdentityLinkWasUsed: "[Bootstrap] Set AcceptIdentityLinkWasUsed",
  AddRoutesToRedirect: "[Bootstrap] AddRoutesToRedirect",
  UpdateRoutesToRedirect: "[Bootstrap] UpdateRoutesToRedirect",
};

export const setNavigateToAfterLogin = (data: { path: string }) => {
  return {
    type: ActionTypes.SetNavigateToAfterLogin,
    payload: data,
  };
};

export const setAcceptedRequestSharingData = (data: AcceptRequestSharingData | null) => {
  return {
    type: ActionTypes.SetAcceptedRequestSharingData,
    payload: data,
  };
};

export const setAcceptedIdentityData = (data: ResendWelcomeEmailData | null) => {
  return {
    type: ActionTypes.SetAcceptedIdentityData,
    payload: data,
  };
};

export const setTokenForPass = (data: {
  token: string | null;
  success: boolean;
  wasUsed?: boolean;
}) => {
  return {
    type: ActionTypes.SetTokenForPass,
    payload: data,
  };
};

export const setAcceptIdentityLinkWasUsed = (data: { wasUsed: boolean }) => {
  return {
    type: ActionTypes.SetAcceptIdentityLinkWasUsed,
    payload: data,
  };
};

export const addRoutesToRedirect = (data: { [key: string]: string }) => {
  return {
    type: ActionTypes.AddRoutesToRedirect,
    payload: data,
  };
};

export const updateRoutesToRedirect = (data: { [key: string]: string }) => {
  return {
    type: ActionTypes.UpdateRoutesToRedirect,
    payload: data,
  };
};
