import React from "react";
import { Link } from "@reach/router";

const NavLink = (props: any) => (
  <Link
    {...props}
    getProps={({ isCurrent, isPartiallyCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      const classes =
        isCurrent || isPartiallyCurrent ? `${props.className} ben-active-link` : props.className;

      return { className: classes };
    }}
  />
);

export default NavLink;
