import { BaseActivityWatcherService } from "../baseActivityWatcher/baseActivityWatcher.service";
import { crossTabMessageService } from "common/shared/services/crossTabMessage/crossTabMessage.service";
import { CROSS_TAB_EVENTS } from "common/shared/services/crossTabMessage/constants";

const { UNPROTECTED_PAGE_INACTIVE } = CROSS_TAB_EVENTS;

export class UnprotectedActivityWatcherService extends BaseActivityWatcherService {
  protected readonly maxInactivity: number = 60 * 30;
  protected readonly maxFreezeInactivityFor: number = 0;

  protected makeActionOnSessionExpired() {
    this.messageService.emitEvent(UNPROTECTED_PAGE_INACTIVE, "makeActionOnSessionExpired", true);
  }

  protected makeActionOnActivity() {
    return;
  }

  protected makeActionOnStartInactivity() {
    return;
  }

  protected makeActionOnContinueInactivity() {
    return;
  }
}

export const unprotectedActivityWatcherService = new UnprotectedActivityWatcherService(
  crossTabMessageService
);
