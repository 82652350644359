import { validatorTypes } from "common/shared";
import FormInput from "common/shared/interfaces/form-input";

export class SignInFormSchema {
  public username: FormInput = {
    value: "",
    isDirty: false,
    errors: {
      required: true,
    },
  };

  public password: FormInput = {
    value: "",
    isDirty: false,
    errors: {
      required: true,
    },
  };
}

export const signInValidationSchema = {
  username: [validatorTypes.required, validatorTypes.emailPattern],
  password: [validatorTypes.required, validatorTypes.passMinLength, validatorTypes.passMaxLength],
};
