import { useSelector } from "react-redux";
import { LiquidityDataProps, LiquidityStore } from "../shared/types";

function useLiquidityDetailsData(): LiquidityDataProps {
  const result = useSelector(
    (state: any): LiquidityDataProps => {
      const liquidityState: LiquidityStore = state.liquidity;
      const {
        single: { data, loading },
      } = liquidityState;

      if (!data || !data.id) {
        return {
          liquidity: null,
          loading,
        };
      }

      const response = {
        liquidity: {
          ...data,
        },
        loading,
      };

      return response;
    }
  );

  return result;
}

export default useLiquidityDetailsData;
