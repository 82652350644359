import React from "react";

import { Collapse } from "antd";

import { Spinner } from "common/lib/components";
import { PropsBenCollapseLoaderStub } from "./types";

const { Panel } = Collapse;

const BenCollapseLoaderStub: React.FC<PropsBenCollapseLoaderStub> = (): React.ReactElement | null => {
  return (
    <Collapse expandIconPosition="right" activeKey={"0"}>
      <Panel
        header={
          <span className="ben-header">
            <Spinner />
          </span>
        }
        showArrow={false}
        key={"0"}
      />
    </Collapse>
  );
};

export default BenCollapseLoaderStub;
