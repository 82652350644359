import { validatorTypes } from "common/shared/index";
import FormInput from "common/shared/interfaces/form-input";

export class ChangePasswordFormSchema {
  public currentPassword = new FormInput();
  public newPassword = new FormInput();
  public confirmPassword = new FormInput();

  constructor(disabledFields?: { [key: string]: boolean }) {
    if (disabledFields) {
      this.currentPassword.isDisabled = disabledFields.currentPassword || false;
      this.newPassword.isDisabled = disabledFields.newPassword || false;
      this.confirmPassword.isDisabled = disabledFields.confirmPassword || false;
    }
  }
}

export const changePasswordValidationSchema = {
  currentPassword: [validatorTypes.required, validatorTypes.passPattern],
  newPassword: [
    validatorTypes.required,
    validatorTypes.passPattern,
    validatorTypes.differValue("currentPassword"),
  ],
  confirmPassword: [validatorTypes.confirm("newPassword")],
};

export const changePasswordValidationSchemaDeps = {
  newPassword: ["confirmPassword"],
  currentPassword: ["newPassword"],
};

export const impersonateChangePasswordDisabledConfig = {
  currentPassword: true,
  newPassword: true,
  confirmPassword: true,
};
