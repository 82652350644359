export const InstitutionInformationDisabledFields: { [key: string]: boolean } = {
  accountName: true,
  email: true,
  firstName: true,
  middleName: true,
  lastName: true,
  suffix: true,
};

export const InstitutionInformationInputPlaceholder = "-";
