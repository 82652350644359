import React from "react";

import { AssetTitle, LiquidityAssetInfo } from "domains/liquidity/components";

import { GroupedLiquidityHoldingRows, ParamsGetLiquidityHoldingsRows } from "./types";
import { Holding } from "domains/document/shared/types";

export const getLiquidityHoldingsHeaders = (quantity: number) => {
  const columns = [
    {
      title: <AssetTitle quantity={quantity} />,
      dataIndex: "asset",
      key: "asset",
      width: 500,
    },
  ];

  return columns;
};

export const getLiquidityHoldingsRows = (
  params: ParamsGetLiquidityHoldingsRows
): GroupedLiquidityHoldingRows => {
  const {
    holdings,
    toggleActiveAssetCollapse,
    activeAssetId,
    activeAmount = 1,
    currentUserId,
    userDisplayId,
    currentUserFullName,
    currentUserIp,
    locale,
  } = params;

  const result: GroupedLiquidityHoldingRows = {
    activeRows: null,
    collapsedRows: null,
  };

  if (!holdings || !holdings.length) {
    return result;
  }

  const preparedHoldings = holdings.map((item: Holding) => {
    return {
      key: item.investmentId,
      asset: (
        <LiquidityAssetInfo
          toggleActiveAssetCollapse={toggleActiveAssetCollapse}
          activeAssetId={activeAssetId}
          holdingData={item}
          currentUserId={currentUserId}
          userDisplayId={userDisplayId}
          currentUserFullName={currentUserFullName}
          currentUserIp={currentUserIp}
          locale={locale}
        />
      ),
    };
  });

  result.activeRows = preparedHoldings.slice(0, activeAmount);
  result.collapsedRows = preparedHoldings.slice(activeAmount);

  return result;
};
