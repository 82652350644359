import { useSelector } from "react-redux";

import { AuthState } from "domains/authentication/store/auth.reducer";

interface LoginData {
  loading: boolean;
  loginError: boolean;
  forgotPassLoading: boolean;
}

function useLoginData(): LoginData {
  const authentication: AuthState = useSelector((storeState: any) => {
    return storeState.authentication;
  });

  return {
    loading: authentication.loading,
    loginError: authentication.loginError,
    forgotPassLoading: authentication.forgotPassLoading,
  };
}

export default useLoginData;
