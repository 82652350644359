import React, { Fragment } from "react";

import { DateUtils } from "common/shared/utils";

import { DocumentItemData } from "domains/document/shared/types";
import { SimpleDocument } from "domains/document/components";

import { PropsOtherCategorizedHoldingDocuments } from "./types";

const OtherCategorizedHoldingDocuments: React.FC<PropsOtherCategorizedHoldingDocuments> = (
  props
): React.ReactElement | null => {
  const { otherDocuments, handleDownload, lang } = props;

  return (
    <Fragment>
      {otherDocuments.map((doc: DocumentItemData) => (
        <SimpleDocument
          key={doc.displayId}
          uniqueBtnId={doc.displayId}
          title={doc.documentName}
          date={DateUtils.formatDate(doc.periodEndingDate, lang)}
          handleDownload={handleDownload(doc.id)}
        />
      ))}
    </Fragment>
  );
};

export default OtherCategorizedHoldingDocuments;
