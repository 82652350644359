import { IdUtils } from "common/shared/utils";
import { apiEnvs, apiService } from "common/shared";
import { ApiEnv } from "common/shared/interfaces/apiEnv";
import { TypeResponseData } from "common/shared/interfaces/requests";
import { BenInfo } from "common/lib/constants";
import {
  ParamsRefreshAccessToken,
  ResponseDataRefreshAccessToken,
  RequestBodyRefreshAccessToken,
  ParamsAcceptIdentity,
  AcceptIdentityResponseData,
  ParamsResendWelcomeEmail,
  ResendWelcomeEmailResponseData,
  ParamsAcceptResetPassword,
  AcceptResetPasswordResponse,
} from "./types";
import {
  prepareRefreshAccessTokenRequestBody,
  parseRefreshAccessTokenResponseData,
  prepareUserLoginData,
  parseAcceptIdentityResponseData,
  parseAcceptResetPasswordResponseData,
} from "./authentication.parser";

export class AuthenticationService {
  login = async (data: any) => {
    return apiEnvs.getApiEnv().then(async (envs: any) => {
      const { authAPI, oAuthClientId } = envs;
      const loginData = prepareUserLoginData(data, oAuthClientId);
      const customConfigs = {
        headers: {
          [BenInfo.CUSTOM_HTTP_HEADERS.shouldNotRedirect]: "yes",
        },
      };
      const tokens = await apiService.postResource(
        `${authAPI}/v1/oauth2/token`,
        loginData,
        customConfigs
      );
      // pass clientId to check impersonation in sessionService
      return { tokens, clientId: oAuthClientId };
    });
  };

  forgotPassword = async (data: any) => {
    return apiEnvs.getApiEnv().then((envs: any) => {
      const { resetMode, userEmail } = data;
      const passUrl = resetMode ? "forgot_password" : "send_password_notification";

      // request_id - any random guid for logging on Backend
      const postData = { request_id: IdUtils.uuidv4() };
      const { identityAPI } = envs;

      return apiService.postResource(
        `${identityAPI}/v1/identity/${userEmail}/${passUrl}`,
        postData
      );
    });
  };

  resetPassword = async (data: any) => {
    const { resetMode, resetToken, userEmail, preventSendingEmail = false } = data;
    const passUrl = resetMode ? "confirm_forgot_password" : "password";
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { identityAPI } = envs;

    return apiService.postResource(
      `${identityAPI}/v1/identity/${userEmail}/${passUrl}`,
      {
        password: data.password,
        prevent_sending_email: preventSendingEmail,
      },
      { headers: { Authorization: `Bearer ${resetToken}` } }
    );
  };

  refreshAccessToken = async (
    data?: ParamsRefreshAccessToken
  ): Promise<ResponseDataRefreshAccessToken> => {
    if (!data || !data.refreshToken) {
      return this.getErrorResponse();
    }

    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { authAPI, oAuthClientId } = envs;
    const requestBody: RequestBodyRefreshAccessToken = prepareRefreshAccessTokenRequestBody(
      data,
      oAuthClientId
    );

    const response: TypeResponseData = await apiService.postResource(
      `${authAPI}/v1/oauth2/token`,
      requestBody
    );

    if (!response) {
      return this.getErrorResponse();
    }

    const responseParsed: ResponseDataRefreshAccessToken = parseRefreshAccessTokenResponseData(
      response
    );
    return responseParsed;
  };

  /**
   * @description - API call to accept Identity
   */
  acceptIdentity = async (data?: ParamsAcceptIdentity): Promise<AcceptIdentityResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { identityAPI } = envs;
    const { email, token } = data;
    if (!email || !token) {
      return this.getErrorResponse();
    }

    const customConfigs = {
      headers: {
        Authorization: `Bearer ${token}`,
        [BenInfo.CUSTOM_HTTP_HEADERS.shouldSaveSession]: "yes",
      },
    };

    const response: TypeResponseData = await apiService.postResource(
      `${identityAPI}/v1/identity/${email}/accept`,
      null,
      customConfigs
    );

    const responseParsed: AcceptIdentityResponseData = parseAcceptIdentityResponseData(response);
    return responseParsed;
  };

  /**
   * @description - API call to accept reset password
   */
  acceptResetPassword = async (
    data?: ParamsAcceptResetPassword
  ): Promise<AcceptResetPasswordResponse> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { identityAPI } = envs;
    const { email, token } = data;
    if (!email || !token) {
      return this.getErrorResponse();
    }

    const customConfigs = {
      headers: {
        Authorization: `Bearer ${token}`,
        [BenInfo.CUSTOM_HTTP_HEADERS.shouldNotRedirect]: "yes",
      },
    };

    const response: TypeResponseData = await apiService.postResource(
      `${identityAPI}/v1/identity/${email}/accept_forgot_password`,
      null,
      customConfigs
    );

    const responseParsed: AcceptResetPasswordResponse = parseAcceptResetPasswordResponseData(
      response
    );
    return responseParsed;
  };

  /**
   * @description - API call to resend Welcome Email
   */
  resendWelcomeEmail = async (
    data?: ParamsResendWelcomeEmail
  ): Promise<ResendWelcomeEmailResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { identityAPI } = envs;
    const { email } = data;
    if (!email) {
      return this.getErrorResponse();
    }

    await apiService.postResource(`${identityAPI}/v1/identity/${email}/send_invitation`, {});
    return { ok: true };
  };

  // TODO: this method can be moved to some general service
  private getErrorResponse(): Promise<any> {
    return new Promise((resolve) => {
      resolve(undefined);
    });
  }
}

const authService = new AuthenticationService();
export default authService;
