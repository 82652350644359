import React from "react";
import { Trans } from "@lingui/react";

import { TypeGetClientsParams, TypeGettingClientsOptionsMap } from "./types";

export const GettingClientsParamsDefault: TypeGetClientsParams = {
  "sort[field]": "last_viewed_date",
  "sort[direction]": "desc",
  "page[size]": "300",
};

export const GettingClientsOptionsMap: TypeGettingClientsOptionsMap = {
  recentlyViewed: {
    value: "last_viewed_date|desc",
    title: <Trans id="clients.sort.account.last_viewed_date.desc">Recently viewed</Trans>,
  },
  alphabetically: {
    value: "account_name|asc",
    title: <Trans id="clients.sort.account.alphabetically.asc">Alphabetically</Trans>,
  },
};
