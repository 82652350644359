import React from "react";
import { Col, Row, Button } from "antd";

import { IconCustom } from "common/lib/components";

import { PropsUploadedDocumentRow } from "./types";

const UploadedDocumentRow: React.FC<PropsUploadedDocumentRow> = (
  props
): React.ReactElement | null => {
  const { createdDocument, handleRemoveFile } = props;
  return (
    <Row type="flex" className="ben-document-content ben-document-content-small">
      <Col md={1} xs={2}>
        <IconCustom type="complete" style={{ fontSize: 20 }} />
      </Col>

      {createdDocument && (
        <Col md={13} xs={18} style={{ paddingLeft: "5px" }}>
          <strong className="ben-document-title">{createdDocument.documentName}</strong>
        </Col>
      )}

      <Col md={10} xs={4} className="ben-document-actions ben-d-sm-none">
        {/* TODO: button is hidden by class - "ben-d-sm-none" (for mobile state) */}
        <Button id="BenRemoveUploadedFile" type="link" onClick={handleRemoveFile}>
          <IconCustom type="trash" style={{ fontSize: 20 }} />
        </Button>
      </Col>
    </Row>
  );
};

export default UploadedDocumentRow;
