type TypeBrowserSessionId = string | null;

export class BrowserSessionService {
  private sessionId: TypeBrowserSessionId = null;
  private storageKey: string = "BrowserSessionIdKey_default";

  constructor(storageKey: string) {
    this.setStorageKey(storageKey);
    this.setSessionId(this.getSessionIdFromStore());
  }

  public setSessionId(sessionId: TypeBrowserSessionId): boolean {
    this.sessionId = sessionId;
    if (!sessionId) {
      return false;
    }

    window.sessionStorage.setItem(this.storageKey, sessionId);
    return true;
  }

  public getSessionId(): TypeBrowserSessionId {
    return this.sessionId;
  }

  public setStorageKey(storageKey: string): boolean {
    this.storageKey = storageKey;
    return true;
  }

  public setOnWindowSessionId(): boolean {
    if (!this.sessionId) {
      return false;
    }
    window.name = this.sessionId;
    return true;
  }

  public getGeneratedSessionId(): string {
    return String(Date.now());
  }

  public isSameBrowserSession(): boolean {
    const sessionId = this.getSessionId();
    if (!window.name && !sessionId) {
      return false;
    }

    if (window.name && sessionId && window.name !== sessionId) {
      return false;
    }

    if (!window.name && sessionId) {
      return false;
    }
    return true;
  }

  public clearBrowserSession() {
    this.sessionId = null;
    // Clear sessionStorage
    window.sessionStorage.removeItem(this.storageKey);
    // Clear data about sessionId on window object
    window.name = "";
  }

  private getSessionIdFromStore(): string | null {
    return window.sessionStorage.getItem(this.storageKey);
  }
}
