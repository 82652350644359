import React from "react";

import "./error-indicator.scss";

const ErrorIndicator = () => {
  return (
    <div className="ben-error-indicator">
      <span className="ben-error-indicator-title">BOOM!</span>
      <span>something has gone terribly wrong</span>
      <span>we are doing our best to fix it</span>
    </div>
  );
};

export default ErrorIndicator;
