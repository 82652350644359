export type TypeSessionExpiredAt = string | null;

export class SessionExpirationService {
  private expiredAt: TypeSessionExpiredAt = null;
  private storageKey: string = "SessionExpirationIdKey_default";

  constructor(storageKey: string) {
    this.setStorageKey(storageKey);
    this.recover();
  }

  public startExpirationProcess(remainingSeconds: number): boolean {
    this.expiredAt = this.getGeneratedExpiredAt(remainingSeconds);
    this.setExpiredAt(this.expiredAt);
    return true;
  }

  public stopExpirationProcess() {
    this.expiredAt = null;
    window.sessionStorage.removeItem(this.storageKey);
  }

  public setExpiredAt(expiredAt: TypeSessionExpiredAt): boolean {
    this.expiredAt = expiredAt;
    if (this.expiredAt) {
      window.sessionStorage.setItem(this.storageKey, this.expiredAt);
    }

    return true;
  }

  public getExpiredAt(): TypeSessionExpiredAt {
    return this.expiredAt;
  }

  public isSessionExpired(): boolean {
    const expiredAt = this.getExpiredAt();
    if (!expiredAt) {
      return false;
    }
    return Boolean(Date.now() > Number(expiredAt));
  }

  public getExpiredInSeconds(): number | null {
    const expiredInSeconds = Math.round((Number(this.expiredAt) - Date.now()) / 1000);
    if (expiredInSeconds < 0) {
      return null;
    }
    return expiredInSeconds;
  }

  public setStorageKey(storageKey: string): boolean {
    this.storageKey = storageKey;
    return true;
  }

  private recover(): boolean {
    const expiredAtSaved = this.getExpiredAtFromStore();
    if (!expiredAtSaved) {
      return false;
    }
    this.setExpiredAt(expiredAtSaved);
    return true;
  }

  private getExpiredAtFromStore(): string | null {
    return window.sessionStorage.getItem(this.storageKey);
  }

  private getGeneratedExpiredAt(remainingSeconds: number): string {
    return String(Date.now() + remainingSeconds * 1000);
  }
}
