import { ArrayUtils } from "common/shared/utils";

interface TransParam {
  name: string;
  value: any;
}

export default class MessageConfig {
  public id: string = "";
  public params: TransParam[] = [];

  constructor(id: string, params: TransParam[] = []) {
    this.id = id;
    this.params = [...params];
  }

  getParamsForTrans() {
    if (ArrayUtils.isValidArrayData(this.params)) {
      const paramsObj = this.params.reduce((params, param) => {
        return { ...params, [param.name]: param.value };
      }, {});

      return paramsObj;
    }

    return {};
  }
}
