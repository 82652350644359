import React from "react";
import { Collapse } from "antd";
import { Trans } from "@lingui/react";

import { InstitutionContent, ClientControls } from "domains/clients/components";

import { PropsInstitutionSection } from "./types";

const { Panel } = Collapse;
const institutionTitle = <Trans id="profile.account.view.title" />;

const InstitutionSection: React.FC<PropsInstitutionSection> = (props): React.ReactElement => {
  const {
    activeKey,
    isUpdating,
    isEditMode,
    handleCancelForm,
    onEditClick,
    currentCollapseKey,
    institution,
    editInstitutionFormConfig,
  } = props;

  const { handleOnSubmit, isValid, isDirty, isStateChanged } = editInstitutionFormConfig;

  const isDisabled = (): boolean => {
    return (isDirty && !isValid) || !isStateChanged() || isUpdating;
  };

  return (
    <Collapse expandIconPosition="right" activeKey={activeKey}>
      <Panel
        header={<span className="ben-header">{institutionTitle}</span>}
        extra={
          <div className="ben-d-sm-none">
            {/* TODO: button is hidden by class - "ben-d-sm-none" (for mobile state), also need to remove DIV */}
            <ClientControls
              isDisabled={isDisabled()}
              isDisabledCancel={isUpdating}
              isEdit={isEditMode}
              btnId="InstitutionSection"
              handleClickSave={handleOnSubmit}
              handleClickCancel={handleCancelForm}
              handleClickEdit={onEditClick}
            />
          </div>
        }
        showArrow={false}
        key={currentCollapseKey}
      >
        <InstitutionContent
          institution={institution}
          isEditMode={isEditMode}
          isUpdating={isUpdating}
          editInstitutionFormConfig={editInstitutionFormConfig}
        />
      </Panel>
    </Collapse>
  );
};

export default InstitutionSection;
