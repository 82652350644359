import {
  RequestBodyRefreshAccessToken,
  ParamsRefreshAccessToken,
  ResponseDataRefreshAccessToken,
  ParamsUserLogin,
  AcceptIdentityResponseData,
  AcceptResetPasswordResponse,
} from "./types";

export function prepareUserLoginData(data: ParamsUserLogin, clientId: string) {
  return {
    client_id: clientId,
    grant_type: "password",
    user_name: data.username,
    password: data.password,
  };
}

export function prepareRefreshAccessTokenRequestBody(
  data: ParamsRefreshAccessToken,
  clientId: string
): RequestBodyRefreshAccessToken {
  const { refreshToken } = data;
  const requestBody = {
    client_id: clientId,
    grant_type: "refresh_token",
    refresh_token: refreshToken,
  };

  return requestBody;
}

export function parseRefreshAccessTokenResponseData(data: any): ResponseDataRefreshAccessToken {
  const result: ResponseDataRefreshAccessToken = {
    accessToken: "",
  };

  if (!data || typeof data !== "object" || !data.access_token) {
    return result;
  }

  result.accessToken = data.access_token;

  return result;
}

export function parseAcceptIdentityResponseData(data: any): AcceptIdentityResponseData {
  const result: AcceptIdentityResponseData = {
    tokenForPass: null,
  };

  if (!data || typeof data !== "object" || !data.token) {
    return result;
  }

  result.tokenForPass = data.token;

  return result;
}

export function parseAcceptResetPasswordResponseData(data: any): AcceptResetPasswordResponse {
  const result: AcceptResetPasswordResponse = {
    tokenForPass: null,
    accepted_terms: false,
  };

  if (!data || typeof data !== "object" || !data.token) {
    return result;
  }

  result.tokenForPass = data.token;
  result.accepted_terms = data.accepted_terms;

  return result;
}
