import React from "react";
import { Row, Col } from "antd";
import { LinkCustom } from "common/lib/components";
import { Messages } from "domains/document/components/instructional-verbiage/constants";
import { Urls } from "common/lib/constants";
import { Trans } from "@lingui/react";
import Module from "domains/document/modules/upload-directory/module/module";
import { PropsModule } from "domains/document/modules/upload-directory/module/types";

const AddIntialDocument: React.FC<PropsModule> = (props): React.ReactElement | null => {
  const { accountId, documentsConfig, onUploadSuccess, uniqueId } = props;
  return (
    <div className="add-holding-form-wrapper">
      <div className="ben-panel ben-pb-2">
        <div className="ben-panel-header">
          <h3>
            <Trans id="AddIntialDocuments.new.title"> Asset Documentation </Trans>
          </h3>
        </div>
        <div>
          <div className="ben-modal-content ben-pt-0">
            <div className="ben-required-text ben-mb-3">
              <p className="ben-mb-1">
                <strong className="ben-required-label">{Messages.Title}</strong>
              </p>
            </div>

            <ul>
              <li>{Messages.DocumentPPM}</li>
              <li>{Messages.DocumentSubscriptionAgreement}</li>
              <li>{Messages.DocumentLPA}</li>
              <li>{Messages.DocumentK1}</li>
              <li>{Messages.DocumentAudited}</li>
              <li>{Messages.DocumentQuarterlyStatements}</li>
              <li>{Messages.DocumentCapitalStatements}</li>
              <li>{Messages.DocumentOther}</li>
            </ul>
          </div>
          <Module
            accountId={accountId}
            documentsConfig={documentsConfig}
            onUploadSuccess={onUploadSuccess}
            uniqueId={uniqueId}
          />
          <Row gutter={30} className="ben-mt-4 ben-form-actions">
            <Col md={{ span: 24 }} xs={{ span: 24 }} className="ben-pb-2">
              <LinkCustom
                id=""
                type="primary"
                shape="round"
                size="large"
                ghost={true}
                className="ben-w-100"
                to={`${Urls.PROTECTED.LIQUIDITY_REQUEST}/${documentsConfig.liquidityRequestId}`}
              >
                <Trans id="AddIntialDocuments.close.btn"> Close and View Request </Trans>
              </LinkCustom>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AddIntialDocument;
