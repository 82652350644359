import React from "react";
import { Trans } from "@lingui/macro";

export const TitleSentSuccess = (
  <Trans id="resendSharingInvitation.title.sentSuccess">Your invitation has been sent.</Trans>
);
export const MessageSentSuccess = (
  <Trans id="resendSharingInvitation.message.sentSuccess">Please check your mailbox.</Trans>
);

export const TitleInvalidData = (
  <Trans id="resendSharingInvitation.title.invalidData">Invalid data received.</Trans>
);
export const MessageInvalidData = (
  <Trans id="resendSharingInvitation.message.invalidData">
    Something wrong with data. Please click the invitation link again.
  </Trans>
);

export const TitleGetNewInvitation = (
  <Trans id="resendSharingInvitation.title.getNewInvitation">
    Please Click the Resend button to receive a new Sharing Invitation.
  </Trans>
);

export const TitleExpired = (
  <Trans id="resendSharingInvitation.title">Your invitation has expired.</Trans>
);
export const MessageExpired = (
  <Trans id="resendSharingInvitation.message.info">
    To resend the invitation please click the link below. Your new request will expire after 48
    hours.
  </Trans>
);

export const TitleBtn = (
  <Trans id="resendSharingInvitation.action.submit.title">Resend Invitation</Trans>
);
