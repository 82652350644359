import { AxiosResponse } from "axios";
import { navigate } from "@reach/router";

import { Urls, BenInfo } from "common/lib/constants";
import { messageService, sessionService, getAuthorizationHeader } from "common/shared";
import { crossTabMessageService, CROSS_TAB_EVENTS } from "common/shared";
import { error500Store } from "common/shared-store";

import { rolesService } from "common/shared/services/roles/roles.service";

import { Error500Data } from "common/shared/interfaces/error500-data";
import { translateError } from "common/shared/services/validation/validationErrors";
import { isHandlerEnabled, isPublic } from "common/shared/services/api.helpers/api.helpers";
import { VERSION_URL } from "common/shared/services/api.service/api.service";

const requestHandler = (request: any) => {
  const requestCopy = { ...request };

  if (isHandlerEnabled(requestCopy)) {
    requestCopy.headers["Content-Type"] = "application/json";
    requestCopy.headers = { ...requestCopy.headers };

    if (!isPublic(requestCopy)) {
      requestCopy.headers = { ...requestCopy.headers, ...getAuthorizationHeader() };
    }

    if (sessionService.isAppian) {
      requestCopy.headers.Appian = sessionService.getUserName();
    }
  }
  return requestCopy;
};

const errorHandler = (error: any) => {
  const response = error && error.response;
  const status = response && response.status;
  const browserErrorMessage = error && error.message;

  if (status === 404) {
    navigate(Urls.PROTECTED.ERROR_404);
    return Promise.reject({ ...error });
  }

  if (status === 403) {
    const shouldNotRedirect =
      response.config.headers[BenInfo.CUSTOM_HTTP_HEADERS.shouldNotRedirect] ||
      response.config.url.includes(VERSION_URL);
    if (shouldNotRedirect) {
      return Promise.reject({ ...error });
    }

    navigate(Urls.PROTECTED.BASE);
  }

  if (status === 500 || status === 502) {
    // dispatch data for Error500 page for autotests
    const error500Data: Error500Data = prepareError500Data(response);
    error500Store.setError(error500Data);
    navigate(Urls.PROTECTED.ERROR);
    return Promise.reject({ ...error });
  }

  if (status === 503) {
    sessionService.setIsMaintenance(true);
    crossTabMessageService.emitEvent(CROSS_TAB_EVENTS.MAINTENANCE, "", true);
    return Promise.reject({ ...error });
  }

  if (status === 401) {
    const shouldSaveSession =
      response.config.headers[BenInfo.CUSTOM_HTTP_HEADERS.shouldSaveSession];
    const shouldNotRedirect =
      response.config.headers[BenInfo.CUSTOM_HTTP_HEADERS.shouldNotRedirect];

    if (shouldSaveSession || shouldNotRedirect) {
      return Promise.reject({ ...error });
    }
    sessionService.clearSession();
    rolesService.clearAll();
    navigate(Urls.AUTH.SIGN_IN);
  } else {
    // add general toaster to show errors

    const errorData = response && response.data;
    const errorDataFields = errorData && errorData.fields;
    const errorDetailsCode =
      errorData && errorData.error_details && errorData.error_details.error_code;
    const errorMessage = errorData && errorData.message;
    if (errorDataFields) {
      Object.keys(errorDataFields).forEach((key) => {
        messageService.error(translateError(errorDataFields[key]));
      });
    } else if (errorDetailsCode) {
      // messageService.error(translateError(errorData.error_details) || errorMessage);
    } else if (errorMessage) {
      //intentionally keep commented for default message shown from API call
      //messageService.error(errorMessage);
    }
  }

  return Promise.reject({ ...error, browserErrorMessage });
};

const successHandler = (response: AxiosResponse): any => {
  return response.data;
};

function prepareError500Data(response: any): Error500Data {
  const config = response && response.config;
  return {
    url: config && config.url,
    method: config && config.method,
    data: response && response.data && JSON.stringify(response.data),
  };
}

export { requestHandler, errorHandler, successHandler };
