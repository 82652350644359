import { Client } from "domains/clients/shared/types";
import { useSelector } from "react-redux";

interface ClientListDataProps {
  list: {
    ids: string[];
    entitiesMap: {
      [key: string]: Client;
    };
  };
  clientsLoading: boolean;
}

function useClientListData(): ClientListDataProps {
  const clientData = useSelector(
    (state: any): ClientListDataProps => {
      const { clientsLoading, list } = state.clients;
      return {
        clientsLoading,
        list,
      };
    }
  );

  return clientData;
}

export default useClientListData;
