import { ClientToAlertMap } from "domains/clients/shared/types";
import { useSelector } from "react-redux";

interface ClientsAlertsDataProps {
  items: ClientToAlertMap;
}

function useClientsAlertsData(): ClientsAlertsDataProps {
  const response = useSelector(
    (state: any): ClientsAlertsDataProps => {
      const {
        list: { alerts },
      } = state.clients;
      return {
        items: alerts,
      };
    }
  );

  return response;
}

export default useClientsAlertsData;
