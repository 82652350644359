import React from "react";

import { ArrayUtils } from "common/shared/utils";

import { ROLE_TYPES } from "common/shared/services/roles/roles.service";

import {
  LiquidityRequestItemData,
  TypeLiquidityTypeCode,
  SharedByMinInfo,
} from "domains/liquidity/shared/types";
import liquidityService from "domains/liquidity/shared/liquidity.service";
import { LiquidityRequestCard } from "domains/liquidity/components";

import { PropsLiquidityRequestGroup } from "./types";

const LiquidityRequestGroup: React.FC<PropsLiquidityRequestGroup> = (
  props
): React.ReactElement | null => {
  const {
    items,
    title,
    handleModalVisibility,
    sharedByObjectType,
    currentUserId,
    sessionRole,
  } = props;

  if (!title || !items || !ArrayUtils.isValidArrayData(items)) {
    return null;
  }

  return (
    <section>
      <header className="ben-main-section-header">
        <h1>{title}</h1>
      </header>
      {items.map((item: LiquidityRequestItemData) => {
        if (!item.status) {
          return null;
        }

        const prevStatus = item.previousStatus || null;
        const { statusSettings, displaySettings } = liquidityService.getProgressBarSettings(
          item.status,
          prevStatus
        );
        const currentProcessId: TypeLiquidityTypeCode | null = statusSettings
          ? statusSettings.type
          : null;

        let sharedInfo: SharedByMinInfo | null = null;
        const canShowSharedByInfo = Boolean(sessionRole !== ROLE_TYPES.ADVISOR);
        if (
          canShowSharedByInfo &&
          currentUserId &&
          item.createdBy &&
          currentUserId !== item.createdBy
        ) {
          sharedInfo = {
            createdByName: item.createdByName,
            sharedByObjectType,
          };
        }

        return displaySettings ? (
          <LiquidityRequestCard
            key={item.id}
            cardTitle={item.displayId}
            requestId={item.id}
            holdings={item.holdings}
            isShowAlert={Boolean(item.alertData)}
            handleModalVisibility={handleModalVisibility}
            step={displaySettings.step}
            withLink={true}
            showSharedInfo={sharedInfo}
            currentStepCode={currentProcessId}
          />
        ) : null;
      })}
    </section>
  );
};

export default LiquidityRequestGroup;
