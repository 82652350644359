import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { navigate } from "@reach/router";

import { BenAppSpinner } from "common/lib/components";
import { BrowsersUtils } from "common/shared/utils";
import {
  crossTabMessageService,
  CROSS_TAB_EVENTS,
  sessionService,
  TypeSessionData,
} from "common/shared";
import { logout } from "domains/identity";
import { Urls } from "common/lib/constants";
import { useIsMountedRef } from "common/shared-hooks";

interface PropsBootstrap {
  children: React.ReactElement;
}

const { NEW_TAB_OPENED, SEND_SESSION_DATA, CLOSE_SESSION, MAINTENANCE } = CROSS_TAB_EVENTS;

const Bootstrap: React.FC<PropsBootstrap> = (props): React.ReactElement | null => {
  const isStartedInitial: boolean = BrowsersUtils.isIEorEdge()
    ? false
    : Boolean(sessionService.getTokens());
  const [isStarted, setStarted] = useState<boolean>(isStartedInitial);
  const dispatch = useDispatch();
  /**
   * Fix: isMountedRef - React state update on an unmounted component
   */
  const isMountedRef = useIsMountedRef();

  const handleBrowserSession = useCallback(() => {
    const isSameUserSession = sessionService.isSameUserSession();
    if (!isSameUserSession) {
      dispatch(
        logout({
          emitEventToTabs: false,
          sendLogoutRequest: false,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    crossTabMessageService.addListener(NEW_TAB_OPENED, () => {
      const sessionData: TypeSessionData | null = sessionService.getSessionData();
      if (!sessionData) {
        return;
      }
      crossTabMessageService.emitEvent(SEND_SESSION_DATA, JSON.stringify(sessionData));
    });

    crossTabMessageService.addListener(SEND_SESSION_DATA, (message?: string) => {
      const result = sessionService.setSessionDataFromString(message);
      if (result) {
        sessionService.syncBrowserSessionId();
        // clean up message from localStorage after reading it
        crossTabMessageService.emitEvent(SEND_SESSION_DATA, "");
      }
    });

    crossTabMessageService.addListener(CLOSE_SESSION, () => {
      dispatch(
        logout({
          urlToRedirectTo: Urls.AUTH.LOGOUT,
          emitEventToTabs: false,
          sendLogoutRequest: false,
        })
      );
    });

    crossTabMessageService.emitEvent(NEW_TAB_OPENED);

    return function cleanup() {
      crossTabMessageService.removeAllListeners();
    };
  }, [dispatch]);

  useEffect(() => {
    crossTabMessageService.addListener(MAINTENANCE, () => {
      sessionService.setIsMaintenance(true);
      if (sessionService.getTokens()) {
        dispatch(
          logout({
            urlToRedirectTo: Urls.PUBLIC.MAINTENANCE,
            emitEventToTabs: false,
            sendLogoutRequest: false,
          })
        );

        return;
      }

      navigate(Urls.PUBLIC.MAINTENANCE);
    });

    return function cleanup() {
      crossTabMessageService.removeAllListeners();
    };
  }, [dispatch]);

  // Waiting for conversation with CrossTabMessageService
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isStarted) {
      return;
    }
    setTimeout(
      () => {
        if (!isMountedRef.current) {
          return;
        }
        setStarted(true);
      },
      BrowsersUtils.isIE() ? 1500 : 1000
    );
  }, []);

  useEffect(() => {
    if (!isStarted) {
      return;
    }
    // For every opened tab
    handleBrowserSession();
  }, [isStarted]);
  return isStarted ? props.children : <BenAppSpinner />;
};

export default Bootstrap;
