import { ActionTypes } from "./liquidity.actions";
import { LiquidityStore } from "../shared/types";

export const initialState: LiquidityStore = {
  list: {
    data: {
      items: [],
      page: {
        index: 0,
        size: 0,
      },
      total: 0,
    },
    loading: true,
  },
  single: {
    data: {
      id: "",
      displayId: "",
      accountId: "",
      accountName: "",
      accountDisplayId: "",
      createdAt: "",
      createdBy: "",
      createdByName: "",
      cancellationReason: null,
      status: "closed",
      previousStatus: null,
      isActive: false,
      bottomUpPricing: 0,
      barqPricing: 0,
      topDownPricing: 0,
      finalAdvanceRate: 0,
      holdings: [],
    },
    loading: true,
  },
  singleSharings: {
    data: {
      requestId: "",
      sharings: [],
    },
    loading: true,
  },
  singleAlerts: {
    data: {
      items: [],
    },
    loading: true,
  },
  creation: {
    isCreating: false,
  },
  creationSharing: {
    data: {
      id: "",
    },
    loading: false,
  },
  resendSharingInvitation: {
    loading: false,
  },
  clientAlerts: {
    data: {
      itemsMapped: null,
    },
    loading: false,
  },
};

export const liquidity = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.GetLiquidities:
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
          },
          loading: true,
        },
      };
    case ActionTypes.GetLiquiditiesSuccess:
      return {
        ...state,
        list: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.GetLiquiditiesFail:
      return {
        ...state,
        list: {
          data: { ...state.list.data },
          loading: false,
        },
      };

    case ActionTypes.GetLiquidity:
      return {
        ...state,
        single: {
          data: {
            ...state.single.data,
          },
          loading: true,
        },
      };
    case ActionTypes.GetLiquiditySuccess:
      return {
        ...state,
        single: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.GetLiquidityFail:
      return {
        ...state,
        single: {
          data: { ...state.single.data },
          loading: false,
        },
      };

    case ActionTypes.CreateLiquidityRequest:
      return {
        ...state,
        creation: {
          isCreating: true,
        },
      };
    case ActionTypes.CreateLiquidityRequestSuccess:
      return {
        ...state,
        creation: {
          isCreating: false,
          data: { ...action.payload },
        },
      };
    case ActionTypes.CreateLiquidityRequestFail:
      return {
        ...state,
        creation: {
          isCreating: false,
        },
      };

    case ActionTypes.CreateLiquiditySharing:
      return {
        ...state,
        creationSharing: {
          data: {
            ...state.creationSharing.data,
          },
          loading: true,
        },
      };
    case ActionTypes.CreateLiquiditySharingSuccess:
      return {
        ...state,
        creationSharing: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.CreateLiquiditySharingFail:
      return {
        ...state,
        creationSharing: {
          data: { ...state.creationSharing.data },
          loading: false,
        },
      };

    case ActionTypes.GetLiquiditySharings:
      return {
        ...state,
        singleSharings: {
          data: {
            ...state.singleSharings.data,
          },
          loading: true,
        },
      };
    case ActionTypes.GetLiquiditySharingsSuccess:
      return {
        ...state,
        singleSharings: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.GetLiquiditySharingsFail:
      return {
        ...state,
        singleSharings: {
          data: { ...state.singleSharings.data },
          loading: false,
        },
      };

    case ActionTypes.ResendSharingInvitation:
      return {
        ...state,
        resendSharingInvitation: {
          loading: true,
        },
      };
    case ActionTypes.ResendSharingInvitationSuccess:
    case ActionTypes.ResendSharingInvitationFail:
      return {
        ...state,
        resendSharingInvitation: {
          loading: false,
        },
      };

    case ActionTypes.GetClientLiquiditiesAlerts:
      return {
        ...state,
        clientAlerts: {
          data: {
            ...state.clientAlerts.data,
          },
          loading: true,
        },
      };
    case ActionTypes.GetClientLiquiditiesAlertsSuccess:
      return {
        ...state,
        clientAlerts: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.GetClientLiquiditiesAlertsFail:
      return {
        ...state,
        clientAlerts: {
          data: {
            ...state.clientAlerts.data,
          },
          loading: false,
        },
      };

    case ActionTypes.GetSingleLiquidityAlerts:
      return {
        ...state,
        singleAlerts: {
          data: {
            ...state.singleAlerts.data,
          },
          loading: true,
        },
      };
    case ActionTypes.GetSingleLiquidityAlertsSuccess:
      return {
        ...state,
        singleAlerts: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.GetSingleLiquidityAlertsFail:
      return {
        ...state,
        singleAlerts: {
          data: { ...state.singleAlerts.data },
          loading: false,
        },
      };

    case ActionTypes.ReadSingleLiquidityAlert:
      return {
        ...state,
        singleAlerts: {
          data: {
            ...state.singleAlerts.data,
            items: [...action.payload.items],
          },
          loading: true,
        },
      };
    case ActionTypes.ReadSingleLiquidityAlertSuccess:
      return {
        ...state,
        singleAlerts: {
          data: { ...state.singleAlerts.data },
          loading: false,
        },
      };
    case ActionTypes.ReadSingleLiquidityAlertFail:
      return {
        ...state,
        singleAlerts: {
          data: { ...state.singleAlerts.data },
          loading: false,
        },
      };

    default:
      return state;
  }
};
