import { Urls } from "common/lib/constants";
import { TypeRedirectionPathParams } from "./types";
import { CommonUrlParams } from "common/lib/constants/params";

export const getRedirectionPath = (params: TypeRedirectionPathParams) => {
  const { redirectedFrom, resetToken } = params;

  if (!redirectedFrom) {
    return null;
  }

  const addParams = `${CommonUrlParams.withCommonExpiredMessage}=true`;

  if (redirectedFrom === "verifySharingPage") {
    return `${Urls.AUTH.RESEND_SHARING_INVITATION_OUT}?${addParams}`;
  }

  if (redirectedFrom === "verifyIdentityPage") {
    return `${Urls.AUTH.RESEND_WELCOME_EMAIL}?${addParams}`;
  }

  if (redirectedFrom === "verifyResetPasswordPage" && resetToken) {
    return `${Urls.AUTH.RESET_PASSWORD_EXPIRED}?token=${resetToken}&resetMode=true&${addParams}`;
  }

  return null;
};
