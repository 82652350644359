import React, { useEffect } from "react";
import { Trans } from "@lingui/macro";
import { navigate, RouteComponentProps } from "@reach/router";
import { useDispatch } from "react-redux";
import { sessionService } from "common/shared";
import { BenInfo, Urls } from "common/lib/constants";
import logo from "common/assets/images/ben-logo-header.svg";
import { getVersion } from "common/shared-store";

interface MaintenanceProps extends RouteComponentProps {
  interval?: number;
}

function MaintenancePage(props: MaintenanceProps) {
  if (!sessionService.isMaintenance()) {
    navigate(Urls.AUTH.SIGN_IN);
  }
  const dispatch = useDispatch();
  const { interval = 30000 } = props;

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getVersion());
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, interval]);

  const title = (
    <Trans id="maintenance.page.title">
      Ben Alt Access is currently unavailable due to site maintenance.
    </Trans>
  );
  const message1 = <Trans id="maintenance.page.message1">In the meantime, contact us at</Trans>;
  const message2 = (
    <Trans id="maintenance.page.message2">for any urgent needs. Please visit us again later.</Trans>
  );

  const message = (
    <span>
      {message1} <a href={`mailto:${BenInfo.EMAILS.portalHelp}`}>{BenInfo.EMAILS.portalHelp}</a>{" "}
      {message2}
    </span>
  );

  return (
    <div className="ben-container">
      <div className="ben-text-center">
        <section className="ben-main-section ben-pt-5 ben-pb-5">
          <div className="ben-panel ben-pb-2 ben-text-center">
            <div className=" ben-pt-4 ben-pb-3">
              <img src={logo} alt="BEN logo" />
            </div>
            <div className="ben-pb-5 ben-mb-4 ben-border-bottom">
              {title && <h1 className="ben-mb-1">{title}</h1>}
              {message && (
                <p className="ben-mb-4" style={{ fontSize: 12 }}>
                  {message}
                </p>
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MaintenancePage;
