import React from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useQueryParam, StringParam } from "use-query-params";

import { ResetPasswordForm } from "domains/authentication";
import { PublicPageWrapper } from "../components";
import { Urls } from "common/lib/constants";
import { tokenDecodeUtils } from "common/shared";
import { useGetAppVersion } from "common/shared-hooks";

// TODO: maybe, this page is not used. Can be deleted
function ResetPasswordPage() {
  const [resetToken] = useQueryParam("token", StringParam);
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  // check token expiration
  // redirect to resendLink if expired
  if (tokenDecodeUtils.isTokenExpired(resetToken || "")) {
    return (
      <Redirect
        from=""
        to={`${Urls.AUTH.RESET_PASSWORD_EXPIRED}?token=${resetToken}&resetMode=true`}
        noThrow={true}
      />
    );
  }

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <ResetPasswordForm
        resetToken={resetToken || null}
        resetMode={true}
        preventSendingEmail={false}
        isAcceptedTermsAgreement={null}
      />
    </PublicPageWrapper>
  );
}

export default ResetPasswordPage as React.ComponentType<RouteComponentProps>;
