import { pushAction } from "common/shared-store/actions/actionsStream";
import { PatchRequestItemData } from "common/shared/interfaces/requests";
import User from "./user.model";
import Institution from "./institution.model";
import PasswordChanges from "common/shared/interfaces/password-changes";
import UserLight from "./user.light.model";
import {
  ParamsGetLiquidityRequestCounts,
  GetLiquidityRequestCountsResponseData,
  GetClientsAlertsResponseData,
  GetClientsResponseData,
  TypeGetClientsParams,
} from "domains/clients/shared/types";

export const ActionTypes = {
  // TODO clean up GetClient & GetAccount actions and all relative stuff
  GetClient: "[Dashboard] Get Client",
  GetClientFail: "[Dashboard] Get Client Fail",
  GetClientSuccess: "[Dashboard] Get Client Success",
  ResetClient: "[Dashboard] Reset Client",

  GetAccount: "[Profile] Get Account",
  GetAccountFail: "[Profile] Get Account Fail",
  GetAccountSuccess: "[Profile] Get Account Success",

  GetClients: "[Dashboard] Get Clients",
  GetClientsFail: "[Dashboard] Get Clients Fail",
  GetClientsSuccess: "[Dashboard] Get Clients Success",

  ChangeClientsRequestParams: "[Dashboard] Change Clients Request Params",

  AddClient: "[Client] Add Client",
  AddClientFail: "[Client] Add Client Fail",
  AddClientSuccess: "[Client] Add Client Success",
  AddIndividualClient: "[Client] Add Client Individual",
  AddIndividualClientFail: "[Client] Add Client Individual Fail",
  AddIndividualClientSuccess: "[Client] Add Client Individual Success",
  AddClientByForce: "[Client] Add Client By Force",

  AddClientToList: "[Client] Add Client To List",

  EditInstitutionalClient: "[Dashboard] Edit Institutional Client",
  EditInstitutionalClientFail: "[Dashboard] Edit Institutional Client Fail",
  EditInstitutionalClientSuccess: "[Dashboard] Edit Institutional Client Success",

  EditPrincipalClient: "[Dashboard] Edit Principal Client",
  EditPrincipalClientFail: "[Dashboard] Edit Principal Client Fail",
  EditPrincipalClientSuccess: "[Dashboard] Edit Principal Client Success",

  EditInstitutionalPrimary: "[Dashboard] Edit InstitutionalPrimary",
  EditInstitutionalPrimaryFail: "[Dashboard] Edit InstitutionalPrimary Fail",
  EditInstitutionalPrimarySuccess: "[Dashboard] Edit InstitutionalPrimary Success",

  UpdateAccount: "[Profile] Update Account",
  UpdateAccountFail: "[Profile] Update Account Fail",
  UpdateAccountSuccess: "[Profile] Update Account Success",

  ChangePassword: "[Profile] Change Password",
  ChangePasswordFail: "[Profile] Change Password Fail",
  ChangePasswordSuccess: "[Profile] Change Password Success",

  GetAccountUsers: "[Profile] Get Account Users",
  GetAccountUsersFail: "[Profile] Get Account Users Fail",
  GetAccountUsersSuccess: "[Profile] Get Account Users Success",

  GetProfile: "[Profile] Get Profile",
  GetProfileFail: "[Profile] Get Profile Fail",
  GetProfileSuccess: "[Profile] Get Profile Success",

  GetLRCount: "[Dashboard] Get LR Count Account List",
  GetLRCountFail: "[Dashboard] Get LR Count Account List Fail",
  GetLRCountSuccess: "[Dashboard] Get LR Count Account List Success",

  GetAlerts: "[Dashboard] Get Alerts",
  GetAlertsFail: "[Dashboard] Get Alerts Fail",
  GetAlertsSuccess: "[Dashboard] Get Alerts Success",
};

export const getClients = (data?: TypeGetClientsParams) => {
  return {
    type: ActionTypes.GetClients,
    payload: data,
  };
};

export const getClientsSuccess = (data: GetClientsResponseData) => {
  const action = {
    type: ActionTypes.GetClientsSuccess,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const getClientsFail = () => {
  const action = {
    type: ActionTypes.GetClientsFail,
  };
  pushAction(action);

  return action;
};

export const changeClientsRequestParams = (data?: TypeGetClientsParams) => {
  return {
    type: ActionTypes.ChangeClientsRequestParams,
    payload: data,
  };
};

export const getClient = (data: any) => {
  return {
    type: ActionTypes.GetClient,
    payload: data,
  };
};

export const getClientSuccess = (data: any) => {
  return {
    type: ActionTypes.GetClientSuccess,
    payload: data,
  };
};

export const getClientFail = () => {
  return {
    type: ActionTypes.GetClientFail,
  };
};

export const resetClient = () => {
  return {
    type: ActionTypes.ResetClient,
    payload: null,
  };
};

export const addIndividualClient = (data: any) => {
  return {
    type: ActionTypes.AddIndividualClient,
    payload: data,
  };
};

export const addIndividualClientSuccess = (accountId: string) => {
  const action = {
    type: ActionTypes.AddIndividualClientSuccess,
    payload: accountId,
  };
  pushAction(action);

  return action;
};

export const addIndividualClientFail = () => {
  const action = {
    type: ActionTypes.AddIndividualClientFail,
  };
  pushAction(action);

  return action;
};

export const addClientToList = (data: any) => {
  return {
    type: ActionTypes.AddClientToList,
    payload: data,
  };
};

export const addClient = (data: any) => {
  return {
    type: ActionTypes.AddClient,
    payload: data,
  };
};

export const addClientSuccess = (accountId: string) => {
  const action = {
    type: ActionTypes.AddClientSuccess,
    payload: accountId,
  };
  pushAction(action);

  return action;
};

export const addClientFail = () => {
  const action = {
    type: ActionTypes.AddClientFail,
  };
  pushAction(action);

  return action;
};

export const editInstitutionalClient = (data: {
  data: PatchRequestItemData[];
  editedClient: Institution;
  accountId: string;
}) => {
  return {
    type: ActionTypes.EditInstitutionalClient,
    payload: data,
  };
};

export const addClientByForce = (data: any) => {
  const action = {
    type: ActionTypes.AddClientByForce,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const editInstitutionalClientSuccess = () => {
  const action = {
    type: ActionTypes.EditInstitutionalClientSuccess,
  };
  pushAction(action);

  return action;
};

export const editInstitutionalClientFail = (showError?: boolean) => {
  const action = {
    type: ActionTypes.EditInstitutionalClientFail,
    payload: showError,
  };
  pushAction(action);

  return action;
};

export const editInstitutionalPrimary = (data: {
  data: PatchRequestItemData[];
  editedClient: Institution;
  accountId: string;
  userId: string;
}) => {
  return {
    type: ActionTypes.EditInstitutionalPrimary,
    payload: data,
  };
};

export const editInstitutionalPrimarySuccess = () => {
  const action = {
    type: ActionTypes.EditInstitutionalPrimarySuccess,
  };
  pushAction(action);

  return action;
};

export const editInstitutionalPrimaryFail = (showError?: boolean) => {
  const action = {
    type: ActionTypes.EditInstitutionalPrimaryFail,
    payload: showError,
  };
  pushAction(action);

  return action;
};

export const editPrincipalClient = (data: {
  data: PatchRequestItemData[];
  accountId: string;
  userId: string;
}) => {
  return {
    type: ActionTypes.EditPrincipalClient,
    payload: data,
  };
};

export const editPrincipalClientSuccess = () => {
  const action = {
    type: ActionTypes.EditPrincipalClientSuccess,
  };
  pushAction(action);

  return action;
};

export const editPrincipalClientFail = (error: any) => {
  const action = {
    type: ActionTypes.EditPrincipalClientFail,
    payload: error,
  };
  pushAction(action);

  return action;
};

export const updateAccount = (data: any) => {
  return {
    type: ActionTypes.UpdateAccount,
    payload: data,
  };
};

export const updateAccountSuccess = (data: any) => {
  const action = {
    type: ActionTypes.UpdateAccountSuccess,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const updateAccountFail = (error: any) => {
  const action = {
    type: ActionTypes.UpdateAccountFail,
    payload: error,
  };
  pushAction(action);

  return action;
};

export const getAccount = (accountId: string) => {
  return {
    type: ActionTypes.GetAccount,
    payload: accountId,
  };
};

export const getAccountSuccess = (data: Institution | User) => {
  return {
    type: ActionTypes.GetAccountSuccess,
    payload: data,
  };
};

export const getAccountFail = () => {
  return {
    type: ActionTypes.GetAccountFail,
  };
};

export const changePassword = (data: PasswordChanges) => {
  const action = {
    type: ActionTypes.ChangePassword,
    payload: data,
  };
  pushAction(action);
  return action;
};

export const changePasswordSuccess = () => {
  const action = {
    type: ActionTypes.ChangePasswordSuccess,
  };
  pushAction(action);
  return action;
};

export const changePasswordFail = () => {
  const action = {
    type: ActionTypes.ChangePasswordFail,
  };
  pushAction(action);
  return action;
};

export const getAccountUsers = (accountId: string) => {
  return {
    type: ActionTypes.GetAccountUsers,
    payload: accountId,
  };
};

export const getAccountUsersSuccess = (data: UserLight[]) => {
  return {
    type: ActionTypes.GetAccountUsersSuccess,
    payload: data,
  };
};

export const getAccountUsersFail = () => {
  return {
    type: ActionTypes.GetAccountUsersFail,
  };
};

export const getProfile = () => {
  return {
    type: ActionTypes.GetProfile,
  };
};

export const getProfileSuccess = (data: { user: User; rawUser: any }) => {
  return {
    type: ActionTypes.GetProfileSuccess,
    payload: data,
  };
};

export const getProfileFail = () => {
  return {
    type: ActionTypes.GetProfileFail,
  };
};

export const getLRCount = (data: ParamsGetLiquidityRequestCounts) => {
  return {
    type: ActionTypes.GetLRCount,
    payload: data,
  };
};

export const getLRCountSuccess = (data: GetLiquidityRequestCountsResponseData) => {
  return {
    type: ActionTypes.GetLRCountSuccess,
    payload: data,
  };
};

export const getLRCountFail = () => {
  return {
    type: ActionTypes.GetLRCountFail,
  };
};

export const getAlerts = () => {
  return {
    type: ActionTypes.GetAlerts,
  };
};

export const getAlertsSuccess = (data: GetClientsAlertsResponseData) => {
  return {
    type: ActionTypes.GetAlertsSuccess,
    payload: data,
  };
};

export const getAlertsFail = () => {
  return {
    type: ActionTypes.GetAlertsFail,
  };
};
