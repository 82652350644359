import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Button, Modal } from "antd";
import { Trans } from "@lingui/macro";

import { Layout } from "common/lib/constants";
import { actions$ } from "common/shared-store/actions/actionsStream";

import { AddHoldingForm } from "domains/liquidity/forms";
import { ParamsAddHoldings, TypeCreateAssetData } from "domains/liquidity/shared/types";
import { ActionTypes, addHoldings, getLiquidity } from "domains/liquidity/store/liquidity.actions";

import { PropsAddHoldingModal } from "./types";
import { PermittedLiquidityStatuses } from "./constants";
import "./add-holding-modal.scss";

const BtnTitle = <Trans id="addHoldingModal.btn.title">Add Holdings</Trans>;

const AddHoldingModal: React.FC<PropsAddHoldingModal> = (props): React.ReactElement | null => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isModalLoading, setModalLoading] = useState<boolean>(false);
  const dispatch = useDispatch();

  const {
    isClient,
    isLiquidityLoading,
    accountId,
    accountName,
    accountDisplayId,
    requestId,
    isSensitiveData,
    liquidityStatus,
  } = props;

  const handleModalCancel = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.AddHoldingsSuccess).subscribe(() => {
        dispatch(getLiquidity({ requestId }));
        setModalLoading(false);
        handleModalCancel();
      }),
      actions$.ofType(ActionTypes.AddHoldingsFail).subscribe(() => {
        setModalLoading(false);
        handleModalCancel();
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [dispatch, handleModalCancel, requestId]);

  const submitCallback = useCallback(
    (assets: TypeCreateAssetData[]) => {
      setModalLoading(true);

      const params: ParamsAddHoldings = {
        assets,
        accountId,
        accountDisplayId,
        accountName,
        assetId: "",
        isSensitive: isSensitiveData,
        requestId,
      };
      dispatch(addHoldings(params));
    },
    [dispatch, accountId, accountName, accountDisplayId, requestId, isSensitiveData]
  );

  if (!liquidityStatus || !PermittedLiquidityStatuses.includes(liquidityStatus)) {
    return null;
  }

  return (
    <div className="add-holding-modal-wrapper">
      <Button
        type="link"
        onClick={handleModalOpen}
        id={"addHoldingModalTrigger"}
        className="ben-light-btn add-holding-modal-trigger"
        disabled={isLiquidityLoading}
      >
        {BtnTitle}
      </Button>

      <Modal
        visible={isModalOpen}
        centered={true}
        width={"100%"}
        style={{
          maxWidth: Layout.MaxWidthModalWindow.large,
        }}
        wrapClassName="ben-modal-container"
        footer={null}
        onCancel={handleModalCancel}
        destroyOnClose={true}
        maskClosable={false}
        closable={!isModalLoading}
      >
        <div>
          <div className="ben-modal-content ben-pt-0">
            <AddHoldingForm
              submitCallback={submitCallback}
              isLiquiditiesExist={true}
              isClient={isClient}
              isLoading={isModalLoading}
              currentClientLRPage=""
              sharingConfig={null}
              cancelCallback={handleModalCancel}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddHoldingModal;
