import React from "react";
import { Router, RouteComponentProps, Redirect } from "@reach/router";
import { lazyLoadClients, lazyLoadClient } from "common/shared/lazy/pages";
import { Urls } from "common/lib/constants";
import { ProtectedRoute } from "common/lib/components";

import { rolesService, PERMISSION_TYPES } from "common/shared/services/roles/roles.service";

function AdvisorDashboardWrapper() {
  return (
    <Router primary={false}>
      <Redirect
        from="/"
        to={`${Urls.PROTECTED.DASHBOARD}${Urls.PROTECTED.CLIENTS}`}
        noThrow={true}
      />
      <ProtectedRoute
        path={Urls.PROTECTED.CLIENTS}
        checkPermissions={() => rolesService.hasPermission(PERMISSION_TYPES.ReadAdvisorClients)}
        lazyLoadComponent={lazyLoadClients}
      />
      <ProtectedRoute
        path={`${Urls.PROTECTED.CLIENTS}/:accountId/`}
        checkPermissions={() => rolesService.hasPermission(PERMISSION_TYPES.ReadAdvisorClients)}
        lazyLoadComponent={lazyLoadClient}
      />
      <Redirect from="*" to={Urls.PROTECTED.ERROR_404} noThrow={true} />
    </Router>
  );
}

export default AdvisorDashboardWrapper as React.ComponentType<RouteComponentProps>;
