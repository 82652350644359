export default class UserLight {
  id?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  fullName: string;
  phoneNumber?: string;
  email: string;
  countryCode?: string;

  nickName?: string;
  suffix?: string;
  timeZoneAbbreviation?: string;
  phoneType?: string;

  constructor({
    id = "",
    userId = "",
    firstName = "",
    middleName = "",
    lastName = "",
    phoneNumber = "",
    email = "",
    countryCode = "",
    nickName = "",
    suffix = "",
    timeZoneAbbreviation = "",
    phoneType = "",
  }) {
    this.id = id || userId;
    this.firstName = firstName;
    this.middleName = middleName;
    this.lastName = lastName;
    this.fullName = this.getFullName();
    this.phoneNumber = phoneNumber;
    this.email = email;
    this.countryCode = countryCode;

    this.nickName = nickName;
    this.suffix = suffix;
    this.timeZoneAbbreviation = timeZoneAbbreviation;
    this.phoneType = phoneType;
  }

  getFullName() {
    return `${this.firstName || ""} ${this.middleName ? this.middleName + " " : ""}${this
      .lastName || ""}`;
  }
}
