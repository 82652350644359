import React from "react";
import { Alert } from "antd";

import IconCustom from "../icon-custom";

import { PropsBenAlertMessage } from "./types";
import "./ben-alert-message.scss";

const BenAlertMessage: React.FC<PropsBenAlertMessage> = (props): React.ReactElement | null => {
  const { message, handleOnAfterClose } = props;
  if (!message || !handleOnAfterClose) {
    return null;
  }

  return (
    <div className="ben-alert-message ben-mb-3">
      <Alert
        message={message}
        afterClose={handleOnAfterClose}
        type="warning"
        closable={true}
        closeText={<IconCustom type="cancel" style={{ fontSize: 26 }} />}
        showIcon={true}
        icon={<IconCustom type="attention-invert" style={{ fontSize: 20 }} />}
      />
    </div>
  );
};

export default BenAlertMessage;
