import { BreadcrumbItem } from "common/shared/interfaces/breadcrumb";
export const ActionTypes = {
  ChangeItems: "[breadcrumb] Change Items",
};

export const breadcrumbChangeItems = (items: BreadcrumbItem[]) => {
  return {
    type: ActionTypes.ChangeItems,
    payload: { items },
  };
};
