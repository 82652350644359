import React, { Fragment, useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { Col, Row } from "antd";

import { Urls } from "common/lib/constants";
import { sessionService } from "common/shared";
import { useGetAppVersion } from "common/shared-hooks";

import { DescriptionSignin, RegistrationForm, SigninForm } from "domains/authentication/index";

import { PublicPageWrapper } from "pages/components";

import "./SigninPage.scss";

interface PropsSigninPage extends RouteComponentProps {}

const SigninPage: React.FC<PropsSigninPage> = (): React.ReactElement | null => {
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  useEffect(() => {
    const isUserLoggedIn = sessionService.isAuthenticated();

    if (isUserLoggedIn) {
      navigate(Urls.PROTECTED.BASE);
    }
  }, []);

  return (
    <PublicPageWrapper isPanelStyle={false}>
      <Fragment>
        <Row gutter={16}>
          <Col sm={24}>
            <div className="ben-pb-3">
              <DescriptionSignin />
            </div>
          </Col>
        </Row>
        <Row gutter={16} className="signin-page-forms-wrapper">
          <Col xs={24} sm={24} md={12}>
            <div className="ben-panel ben-w-100 signin-page-form-wrapper">
              <SigninForm />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <div className="ben-panel ben-w-100 signin-page-form-wrapper">
              <RegistrationForm />
            </div>
          </Col>
        </Row>
      </Fragment>
    </PublicPageWrapper>
  );
};

export default SigninPage;
