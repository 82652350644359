import React from "react";

import { InstitutionInfoText } from "domains/clients/components";
import { ProfileUpdateInstitutionForm } from "domains/clients/forms";

import { PropsInstitutionContent } from "./types";

const InstitutionContent: React.FC<PropsInstitutionContent> = (
  props
): React.ReactElement | null => {
  const { institution, isEditMode, isUpdating, editInstitutionFormConfig } = props;

  if (!institution) {
    return null;
  }

  return isEditMode ? (
    <ProfileUpdateInstitutionForm
      accountId={institution.accountId}
      accountName={institution.name}
      config={editInstitutionFormConfig}
      isUpdating={isUpdating}
    />
  ) : (
    <InstitutionInfoText institution={institution} />
  );
};

export default InstitutionContent;
