import { navigate } from "@reach/router";

interface RoutesUtilsParamsBlockedRoutes {
  routesToRedirect: { [key: string]: string };
  route: string;
}

export class RoutesUtils {
  redirectIfRouteIsBlocked(params: RoutesUtilsParamsBlockedRoutes): void {
    const { routesToRedirect, route } = params;
    if (!routesToRedirect[route]) {
      return;
    }

    navigate(routesToRedirect[route], {
      replace: true,
    });
  }

  removeBlockedRoute(
    params: RoutesUtilsParamsBlockedRoutes
  ): RoutesUtilsParamsBlockedRoutes["routesToRedirect"] {
    const { routesToRedirect, route } = params;
    if (!routesToRedirect[route]) {
      return routesToRedirect;
    }
    const updatedRoutesToRedirect = { ...routesToRedirect };
    delete updatedRoutesToRedirect[route];
    return updatedRoutesToRedirect;
  }

  /**
   *  It is used when there are may be several routes
   *  which is blocked and should be redirected to current route
   */
  removeBlockedRoutesConnectedToCurrent(
    params: RoutesUtilsParamsBlockedRoutes
  ): RoutesUtilsParamsBlockedRoutes["routesToRedirect"] {
    const { routesToRedirect, route } = params;
    const updatedRoutesToRedirect = { ...routesToRedirect };
    for (const key in updatedRoutesToRedirect) {
      if (updatedRoutesToRedirect[key] === route) {
        delete updatedRoutesToRedirect[key];
      }
    }

    return updatedRoutesToRedirect;
  }
}

const routesUtils = new RoutesUtils();
export default routesUtils;
