import React from "react";
import { Trans } from "@lingui/macro";
import { Icon } from "antd";

import { useClientData } from "domains/clients/hooks";
import Institution from "domains/clients/store/institution.model";
import User from "domains/clients/store/user.model";
import { BackBtn } from "common/lib/components";

function ClientDetailsTitle(): React.ReactElement | null {
  const { client, loading } = useClientData();

  let title: string | React.ReactElement = (
    <Trans id="dashboard.client.info">Client Information</Trans>
  );

  if (loading) {
    return <h1>{title}</h1>;
  }

  if (client instanceof User) {
    title = client.fullName;
  } else if (client instanceof Institution) {
    title = client.accountName;
  }

  return (
    <h1>
      <BackBtn type="link" className="ben-btn-reset ben-d-none ben-d-sm-block">
        <Icon type="left" style={{ fontSize: 22 }} />
      </BackBtn>
      {title}
    </h1>
  );
}

export default ClientDetailsTitle;
