import { CurrencyStore, CurrencyItemData } from "common/shared/interfaces/currency";
import { ActionTypes } from "common/shared-store/actions/currency";
import { portalEnvConstants } from "common/shared";
import { ArrayUtils } from "common/shared/utils";

export const initialState: CurrencyStore = {
  list: {
    data: {
      items: [],
    },
    loading: true,
  },

  defaultCurrency: {
    currencyCode: "",
    currencyName: "",
    currencySign: "",
  },
};

export const currency = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.GetCurrencies:
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
          },
          loading: true,
        },
      };
    case ActionTypes.GetCurrenciesSuccess:
      return {
        ...state,
        list: {
          data: { ...action.payload },
          loading: false,
        },
        defaultCurrency: getDefaultCurrency(state, action.payload && action.payload.items),
      };
    case ActionTypes.GetCurrenciesFail:
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
          },
          loading: false,
        },
      };

    default:
      return state;
  }
};

function getDefaultCurrency(state: CurrencyStore, currencyList: CurrencyItemData[]) {
  const defaultCurrency = { ...state.defaultCurrency };
  if (ArrayUtils.isValidArrayData(currencyList)) {
    const newDefaultCurrency = currencyList.find(
      (currencyItem: CurrencyItemData) =>
        currencyItem.currencyCode === portalEnvConstants.defaultCurrency
    );

    return newDefaultCurrency || defaultCurrency;
  }

  return defaultCurrency;
}
