import { UserIdentity } from "common/shared/interfaces/user-identity";
import { pushAction } from "common/shared-store/actions/actionsStream";
import { rolesService } from "common/shared/services/roles/roles.service";
import { ParamsLogoutAction } from "../shared/types";

export const ActionTypes = {
  CreateRegistrationRequest: "[Identity] CreateRegistration",
  CreateRegistrationSuccess: "[Identity] CreateRegistration Success",
  CreateRegistrationFail: "[Identity] CreateRegistration Fail",
  GetIdentity: "[Identity] Get Identity",
  GetIdentitySuccess: "[Identity] Get Identity Success",
  GetIdentityFail: "[Identity] Get Identity Fail",
  GetPermissions: "[Identity] Get Permissions",
  GetPermissionsSuccess: "[Identity] Get Permissions Success",
  GetPermissionsFail: "[Identity] Get Permissions Fail",
  RemovePermissions: "[Identity] Remove Permissions",
  Init: "[Identity] Init",
  Logout: "[Identity] Logout",
  LogoutSuccess: "[Identity] Logout Success",
  LogoutFail: "[Identity] Logout Fail",
};

export const removePermissions = () => {
  rolesService.removePermissions();

  return {
    type: ActionTypes.RemovePermissions,
  };
};

export const createRegistrationRequest = (data: any) => {
  return {
    type: ActionTypes.CreateRegistrationRequest,
    payload: data,
  };
};

export const createRegistrationSuccess = (data: any) => {
  const action = {
    type: ActionTypes.CreateRegistrationSuccess,
    payload: data,
  };
  pushAction(action);
  return action;
};

export const createRegistrationFail = () => {
  const action = {
    type: ActionTypes.CreateRegistrationFail,
  };
  pushAction(action);
  return action;
};

export const getIdentity = (username?: string) => {
  return {
    type: ActionTypes.GetIdentity,
    payload: username,
  };
};

export const getIdentitySuccess = (identity: UserIdentity) => {
  return {
    type: ActionTypes.GetIdentitySuccess,
    payload: identity,
  };
};

export const getIdentityFail = () => {
  return {
    type: ActionTypes.GetIdentityFail,
  };
};

export const getPermissions = (role?: string) => {
  return {
    type: ActionTypes.GetPermissions,
    payload: role,
  };
};

export const getPermissionsSuccess = (permissions: any) => {
  return {
    type: ActionTypes.GetPermissionsSuccess,
    payload: permissions,
  };
};

export const getPermissionsFail = () => {
  return {
    type: ActionTypes.GetPermissionsFail,
  };
};

export const init = () => {
  return {
    type: ActionTypes.Init,
  };
};

export const logout = (params?: ParamsLogoutAction) => {
  return {
    type: ActionTypes.Logout,
    payload: params,
  };
};

export const logoutFail = (urlToRedirectTo?: string) => {
  const action = {
    type: ActionTypes.LogoutFail,
    payload: urlToRedirectTo,
  };
  pushAction(action);
  return action;
};

export const logoutSuccess = (urlToRedirectTo?: string) => {
  const action = {
    type: ActionTypes.LogoutSuccess,
    payload: urlToRedirectTo,
  };
  pushAction(action);
  return action;
};
