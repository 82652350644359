import React, { Fragment } from "react";

import { ArrayUtils } from "common/shared/utils";

import { DocumentItemData } from "domains/document/shared/types";
import { BenDocument } from "domains/document/components";

import { PropsOtherTransactionDocuments } from "./types";

const OtherTransactionDocuments: React.FC<PropsOtherTransactionDocuments> = (
  props
): React.ReactElement | null => {
  const {
    otherDocuments,
    handleDownload,
    accountId,
    liquidityRequestId,
    disableUpload,
    onReUploadSuccess,
    onReUploadError,
    onReUploadStart,
    onReUploadCancel,
    disableActions,
  } = props;

  if (!ArrayUtils.isValidArrayData(otherDocuments)) {
    return null;
  }

  return (
    <Fragment>
      {otherDocuments.map((item: DocumentItemData) => {
        return (
          <BenDocument
            withDragger={true}
            openFileDialogOnClick={true}
            key={item.displayId}
            title={item.documentName}
            statusIcon="complete"
            size="small"
            uploadConfig={{
              newVersionOfUniqueDocumentConfirmed: true,
              documentId: item.id,
              accountId,
              liquidityRequestId,
            }}
            handleDownload={handleDownload(item.id)}
            disableUpload={disableUpload}
            onUploadSuccess={onReUploadSuccess}
            onUploadError={onReUploadError}
            disableActions={disableActions}
            onUploadStart={onReUploadStart}
            onUploadCancel={onReUploadCancel}
          />
        );
      })}
    </Fragment>
  );
};

export default OtherTransactionDocuments;
