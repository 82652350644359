import React, { Fragment } from "react";
import { Trans } from "@lingui/macro";

const PasswordHelpMessage: React.FC = (): React.ReactElement => {
  return (
    <Fragment>
      <Trans id="lib.passwordHelpMessage.text">
        {
          "Password must be within 8 and 20 characters and contain upper case (A-Z), lower case (a-z), numbers and at least one special character: "
        }
      </Trans>{" "}
      {"!\"#$%&'()*+,-./:;<=>?[]^_\\{|}~`@"}
    </Fragment>
  );
};

export default PasswordHelpMessage;
