import React, { Fragment } from "react";

import { DocumentItemData } from "domains/document/shared/types";
import { BenDocument } from "domains/document/components";

import { PropsOtherHoldingDocuments } from "./types";

const OtherHoldingDocuments: React.FC<PropsOtherHoldingDocuments> = (
  props
): React.ReactElement | null => {
  const {
    otherDocuments,
    disableUpload,
    disableDontHaveIcon,
    onUploadSuccess,
    accountId,
    liquidityRequestId,
    handleDownload,
    onUploadStart,
    onUploadCancel,
    onUploadError,
  } = props;

  return (
    <Fragment>
      {otherDocuments.map((doc: DocumentItemData) => (
        <BenDocument
          key={doc.displayId}
          withDragger={true}
          openFileDialogOnClick={true}
          title={doc.documentName}
          statusIcon="complete"
          size="small"
          handleDownload={handleDownload(doc)}
          disableUpload={disableUpload}
          disableDontHaveIcon={disableDontHaveIcon}
          onUploadSuccess={onUploadSuccess}
          uploadConfig={{
            documentId: doc.id,
            newVersionOfUniqueDocumentConfirmed: true,
            accountId,
            liquidityRequestId,
          }}
          onUploadStart={onUploadStart}
          onUploadCancel={onUploadCancel}
          onUploadError={onUploadError}
        />
      ))}
    </Fragment>
  );
};

export default OtherHoldingDocuments;
