export class FunctionUtils {
  debounce(func: any, wait: number, immediate?: boolean) {
    let timeout: any;
    return (...sourceArgs: any[]) => {
      const context: any = {};
      const args = sourceArgs;
      const later = () => {
        timeout = null;
        if (!immediate) {
          func.apply(context, args);
        }
      };
      const callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) {
        func.apply(context, args);
      }
    };
  }
}

const functionUtils = new FunctionUtils();

export default functionUtils;
