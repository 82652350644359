import User from "domains/clients/store/user.model";

export default class Institution {
  accountId?: string;
  name: string;
  accountName: string;
  accountType: string;
  street1?: string;
  street2?: string;
  state?: string;
  city?: string;
  region?: string;
  postalCode?: string;
  phone: string;
  phoneType: string;
  email: string;
  countryCode: string;
  country?: string;
  addresses?: any[];
  accountDisplayId: string;
  primaryContactUser: User | null;

  constructor({
    name = "",
    accountName = "",
    accountType = "",
    street1 = "",
    street2 = "",
    state = "",
    city = "",
    region = "",
    postalCode = "",
    phone = "",
    phoneType = "",
    email = "",
    countryCode = "",
    country = "",
    accountId = "",
    accountDisplayId = "",
    primaryContactUser = null,
  }) {
    this.name = name;
    this.accountName = accountName;
    this.accountType = accountType;
    this.street1 = street1;
    this.street2 = street2;
    this.state = state;
    this.city = city;
    this.region = region;
    this.postalCode = postalCode;
    this.phone = phone;
    this.phoneType = phoneType;
    this.email = email;
    this.countryCode = countryCode;
    this.country = country;
    this.accountId = accountId;
    this.accountDisplayId = accountDisplayId;
    this.primaryContactUser = primaryContactUser ? new User(primaryContactUser || {}) : null;
  }

  getClientUserId(): string {
    if (this.primaryContactUser && this.primaryContactUser.userId) {
      return this.primaryContactUser.userId;
    }
    return "";
  }

  getClientUserFullName(): string {
    if (this.primaryContactUser && this.primaryContactUser.fullName) {
      return this.primaryContactUser.fullName;
    }
    return "";
  }

  getClientUserEmail(): string {
    if (this.primaryContactUser && this.primaryContactUser.email) {
      return this.primaryContactUser.email;
    }
    return "";
  }
}
