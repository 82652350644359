import { Trans } from "@lingui/macro";
import React from "react";

export const ModalTitle = <Trans id="documents.uncategorized.modal.title">Documents</Trans>;
export const ModalBtnClose = <Trans id="documents.uncategorized.modal.btn.close">Close</Trans>;
export const TriggerBtnTitle = (
  <Trans id="documents.uncategorized.btn.trigger.title">Documents</Trans>
);
export const EmptyListTitle = (
  <Trans id="documents.uncategorized.modal.emptyList.message">
    This is the list of all documents you have provided. As our Underwriting team reviews these
    files, they will also categorize them by holding.
  </Trans>
);
