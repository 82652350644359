import React, { Suspense } from "react";
import { RouteComponentProps, Redirect } from "@reach/router";

import { Urls } from "common/lib/constants";
import Spinner from "../spinner";

interface ProtectedRouteProps {
  checkPermissions: () => boolean;
  checkAuth: () => boolean;
  lazyLoadComponent: React.ComponentType<RouteComponentProps>;
  Component: React.ComponentType<RouteComponentProps>;
}

function ProtectedRoute({
  checkPermissions,
  checkAuth,
  lazyLoadComponent,
  Component,
  ...rest
}: ProtectedRouteProps) {
  if (checkAuth && !checkAuth()) {
    return <Redirect from="" to={Urls.AUTH.SIGN_IN} noThrow={true} />;
  }

  if (checkPermissions()) {
    if (lazyLoadComponent) {
      const LazyLoadedComponent = lazyLoadComponent;

      return (
        <Suspense fallback={<Spinner spinning={true} />}>
          <LazyLoadedComponent {...rest} />
        </Suspense>
      );
    }
    return <Component {...rest} />;
  }

  return <Redirect from="" to={Urls.PROTECTED.BASE} noThrow={true} />;
}

export default ProtectedRoute as React.ComponentType<RouteComponentProps<ProtectedRouteProps>>;
