import React, { useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch } from "react-redux";

import { actions$ } from "common/shared-store/actions/actionsStream";
import { addRoutesToRedirect } from "common/shared-store/actions/bootstrap";
import { useBootstrapRoutesToRedirectData } from "common/shared-hooks";

import { ArrayUtils, RoutesUtils } from "common/shared/utils";
import { Urls } from "common/lib/constants";
import { Spinner } from "common/lib/components";

import { rolesService, PERMISSION_TYPES } from "common/shared/services/roles/roles.service";

import {
  ActionTypes,
  getLiquidities,
  LiquidityRequestList,
  LiquidityBtnNew,
  useLiquiditiesListData,
} from "domains/liquidity";
import { useProfileData } from "domains/clients";
import {
  LiquiditiesListDataProps,
  TypeInvitedObject,
  LiquidityRequestsListResponseData,
} from "domains/liquidity/shared/types";

interface PropsClientDashboardPage extends RouteComponentProps {}

// It is a client dashboard visible by client
// This page can be reachable from 2 routes: /dashboard and /dashboard/clients/accountId
const ClientDashboardPage: React.FC<PropsClientDashboardPage> = (props): React.ReactElement => {
  const dispatch = useDispatch();
  const { user, loading: userLoading } = useProfileData();
  const {
    list: liquiditiesList,
    loading: liquiditiesLoading,
  }: LiquiditiesListDataProps = useLiquiditiesListData();
  const { routesToRedirect } = useBootstrapRoutesToRedirectData();

  const currentPath: string = (props.location && props.location.pathname) || "";

  const isBlockedRoute = Boolean(routesToRedirect[currentPath]);
  let accountId: string | null = null;
  if (user) {
    accountId = user.accountId;
  }
  const clientUserId = (user && user.userId) || "";
  const isLiquiditiesExist = ArrayUtils.isValidArrayData(liquiditiesList);
  const routeToRedirect = `${Urls.PROTECTED.NEW_LIQUIDITY}/${accountId}`;
  // As sharing is available only for advisor, we know that if LR shared, it is shared by advisor
  const sharedByObjectType: TypeInvitedObject = "advisor";
  const sessionRole = rolesService.getRole();

  // Checking if current route is blocked
  useEffect(() => {
    RoutesUtils.redirectIfRouteIsBlocked({ routesToRedirect, route: currentPath });
  }, [currentPath, routesToRedirect]);

  // Request for Liquidities
  useEffect(() => {
    if (!accountId || !clientUserId || isBlockedRoute) {
      return;
    }

    dispatch(getLiquidities({ accountId, sharedWithUserId: clientUserId }));
  }, [dispatch, accountId, clientUserId, isBlockedRoute]);

  // Redirection if there is no liquidities in response
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType(ActionTypes.GetLiquiditiesSuccess)
        .subscribe(
          (actionData: { type: string; payload: LiquidityRequestsListResponseData | null }) => {
            if (!actionData.payload || !ArrayUtils.isValidArrayData(actionData.payload.items)) {
              dispatch(addRoutesToRedirect({ [currentPath]: routeToRedirect }));
              navigate(routeToRedirect, { replace: true });
            }
          }
        )
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [dispatch, currentPath, accountId, routeToRedirect]);

  return (
    <div className="ben-client-dashboard">
      {isLiquiditiesExist && accountId && rolesService.hasPermission(PERMISSION_TYPES.CreateLr) && (
        <div className="ben-main-section-btn-holder">
          <LiquidityBtnNew accountId={accountId} />
        </div>
      )}

      {(userLoading || liquiditiesLoading) && <Spinner />}
      {isLiquiditiesExist && (
        <LiquidityRequestList
          list={liquiditiesList}
          groupsToShow={["active", "archived", "closed"]}
          currentUserId={clientUserId}
          currentAccountId={accountId || ""}
          sharedByObjectType={sharedByObjectType}
          sessionRole={sessionRole}
        />
      )}
    </div>
  );
};

export default ClientDashboardPage;
