import React from "react";
import { Icon } from "antd";
import { Trans } from "@lingui/react";

import { PropsClientBtnNew } from "./types";
import { Urls } from "common/lib/constants";
import { LinkCustom } from "common/lib/components";

const ClientBtnNew: React.FC<PropsClientBtnNew> = (): React.ReactElement => {
  return (
    <LinkCustom
      id="benBtnNewClient"
      type="primary"
      shape="round"
      to={`${Urls.PROTECTED.ADD_CLIENT}`}
    >
      <Trans id="clients.btn.new">Create a New Client</Trans>{" "}
      <Icon type="plus" style={{ fontSize: 20 }} />
    </LinkCustom>
  );
};

export default ClientBtnNew;
