import "babel-polyfill";
import "core-js/features/promise";
import "core-js/stable/string/starts-with";
import "react-app-polyfill/ie11";
import "es6-promise/auto";
import "babel-polyfill";
import smoothscroll from "smoothscroll-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Location, LocationContext } from "@reach/router";

import { Bootstrap } from "domains/common";

import App from "App";
import { store } from "./root";

declare global {
  interface Window {
    dataLayer: any;
  }
}

ReactDOM.render(
  <Location>
    {({ location }: LocationContext): React.ReactElement => {
      smoothscroll.polyfill();
      return (
        <Provider store={store}>
          <Bootstrap>
            <App location={location} />
          </Bootstrap>
        </Provider>
      );
    }}
  </Location>,
  document.getElementById("root")
);
