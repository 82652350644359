import React from "react";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import { Modal, Button } from "antd";

import { Urls } from "common/lib/constants";
import { localeService } from "common/shared";

import { logout } from "domains/identity/store/identity.actions";

const LogoutBtn = () => {
  const dispatch = useDispatch();
  const { confirm } = Modal;
  function handleLogout() {
    dispatch(logout({ urlToRedirectTo: Urls.AUTH.LOGOUT, sendLogoutRequest: true }));
  }

  const handleConfirm = () => {
    confirm({
      title: localeService.i18n._("logout.confirm.message"),
      okText: localeService.i18n._("confirm.yes"),
      cancelText: localeService.i18n._("confirm.no"),
      onOk() {
        handleLogout();
      },
    });
  };

  return (
    <Button id="benBtnSignout" type="primary" shape="round" ghost={true} onClick={handleConfirm}>
      <Trans id="logout.actionButton.submit">Sign out</Trans>
    </Button>
  );
};

export default LogoutBtn;
