import React, { useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import { Form, Input, Button } from "antd";

import { useForm } from "common/shared-hooks";
import { Spinner, BenPhone } from "common/lib/components";

import { getValidationErrors, localeService } from "common/shared";
import { actions$ } from "common/shared-store/actions/actionsStream";

import { LoginFooter } from "domains/authentication";
import { useLoginData } from "domains/authentication/hooks";
import { forgotPassword, ActionTypes } from "domains/authentication/store/auth.actions";

import {
  ForgotPasswordFormSchema,
  forgotPasswordValidationSchema,
} from "./forgot-password.form.config";

interface ForgotPasswordFormProps {
  resetMode: boolean;
  onCancel: () => void;
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = (
  props
): React.ReactElement | null => {
  const dispatch = useDispatch();

  const formSchema = new ForgotPasswordFormSchema();
  const { state, isValid, handleOnChange, handleOnBlur, handleOnSubmit, resetForm } = useForm(
    formSchema,
    forgotPasswordValidationSchema,
    submitCallback
  );

  const { forgotPassLoading } = useLoginData();
  const { resetMode, onCancel } = props;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.ForgotPasswordSuccess).subscribe(() => {
        reset();
      })
    );

    return function cleanup() {
      resetForm();
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  function submitCallback(data: any) {
    dispatch(forgotPassword({ userEmail: data.username, resetMode }));
  }

  function reset() {
    resetForm();
    onCancel();
  }

  return (
    <div className="ben-pt-3">
      <div className="ben-pb-4 ben-mb-4 ben-border-bottom">
        <Fragment>
          <p>
            <Trans id="forgotpass.form.info1">
              To reset your password, please submit your email in field below. Check your mailbox
              for instruction on password resetting
            </Trans>
          </p>
          <p className="ben-mb-0">
            <Trans id="forgotpass.form.info2" values={{ phoneNumber: <BenPhone /> }} />
          </p>
        </Fragment>
      </div>
      {forgotPassLoading ? (
        <Spinner />
      ) : (
        <Form>
          <Form.Item
            {...getValidationErrors(
              state.username.errors,
              localeService.i18n._("forgotpass.email.label")
            )}
          >
            <Input
              name="username"
              placeholder={localeService.i18n._("forgotpass.email.label")}
              value={state.username.value}
              onChange={handleOnChange}
              onBlur={handleOnBlur}
            />
          </Form.Item>
          <div className="ben-pb-3 ben-mb-4 ben-border-bottom">
            <LoginFooter alignLeft={true} />
          </div>

          <Button
            id="benForgotPasswordBtnSubmit"
            type="primary"
            shape="round"
            size="large"
            className="ben-w-100 ben-mb-3"
            disabled={!isValid}
            onClick={handleOnSubmit}
          >
            <Trans id="actionButtons.send">Send</Trans>
          </Button>

          <Button
            id="benForgotPasswordBtnCancel"
            type="primary"
            shape="round"
            ghost={true}
            size="large"
            className="ben-w-100"
            onClick={reset}
          >
            <Trans id="actionButtons.cancel">Cancel</Trans>
          </Button>
        </Form>
      )}
    </div>
  );
};

export default ForgotPasswordForm;
