import React, { Fragment } from "react";
import { Form } from "antd";
import { SelectValue } from "antd/lib/select";

import { Options, Select } from "common/lib/components";
import { Constants } from "common/lib/constants";
import { localeService } from "common/shared";
import FormConfig from "common/shared/interfaces/form-config";
import { useOnLocaleChange } from "common/shared-hooks";

interface PropsAddressForm {
  config: FormConfig;
  placeholder: string;
}

function AddressForm({ config, placeholder }: PropsAddressForm) {
  const { state, handleOnChange, validateState } = config;
  validateState();
  const countries = useOnLocaleChange(Options.countries);

  // todo extend useForm hook to work with select
  const handleSelectChange = (name: string) => (value: SelectValue) => {
    handleOnChange({ target: { name, value } });
  };

  const setDefaultCountry = () => {
    handleOnChange({ target: { name: "country", value: Constants.DEFAULT_COUNTRY } });
    return Constants.DEFAULT_COUNTRY;
  };

  return (
    <Fragment>
      {/* <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._(`form.address.street1`)}
          {...getValidationErrors(
            state.street1.errors,
            localeService.i18n._(`form.address.street1`)
          )}
        >
          <Input
            name="street1"
            value={state.street1.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.street1.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._(`form.address.street2`)}
          {...getValidationErrors(
            state.street2.errors,
            localeService.i18n._(`form.address.street2`)
          )}
        >
          <Input
            name="street2"
            value={state.street2.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.street2.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._(`form.address.city`)}
          {...getValidationErrors(state.city.errors, localeService.i18n._(`form.address.city`))}
        >
          <Input
            name="city"
            value={state.city.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.city.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._(`form.address.state`)}
        >
          <Select
            showSearch={true}
            id="region"
            style={{ width: "100%" }}
            defaultValue={state.region.value || placeholder}
            onChange={handleSelectChange("region")}
            disabled={state.region.isDisabled || Constants.DEFAULT_COUNTRY !== state.country.value}
            optionFilterProp="children"
            filterOption={Options.filterByStartsWith}
          >
            {Options.usaStates}
          </Select>
        </Form.Item>
      </div>
      <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._(`form.address.zip`)}
          {...getValidationErrors(
            state.postalCode.errors,
            localeService.i18n._(`form.address.zip`)
          )}
        >
          <Input
            name="postalCode"
            value={state.postalCode.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.postalCode.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div> */}
      <div>
        <Form.Item
          className="ben-form-label-field ben-required-field"
          label={localeService.i18n._(`form.address.country`)}
        >
          <Select
            showSearch={true}
            id="country"
            style={{ width: "100%" }}
            defaultValue={state.country.value || setDefaultCountry()}
            onChange={handleSelectChange("country")}
            disabled={state.country.isDisabled}
            optionFilterProp="children"
            filterOption={Options.filterByStartsWith}
          >
            {countries}
          </Select>
        </Form.Item>
      </div>
    </Fragment>
  );
}

export default AddressForm as React.ComponentType<any>;
