import React, { useCallback, useState } from "react";

import { Button, Icon, Modal } from "antd";

import { Layout } from "common/lib/constants";

import { PropsInstructionalVerbiage } from "./types";
import { Messages } from "./constants";
import { Trans } from "@lingui/react";
import "./instructional-verbiage.scss";

const InstructionalVerbiage: React.FC<PropsInstructionalVerbiage> = (): React.ReactElement | null => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const handleModalCancel = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleModalOpen = useCallback(() => {
    setModalOpen(true);
  }, []);

  return (
    <div className="instructional-verbiage-wrapper">
      <Button
        type="link"
        className="instructional-verbiage-info-trigger"
        onClick={handleModalOpen}
        id={"instructionalVerbiageInfoTrigger"}
      >
        <p style={{ fontSize: "13px" }}>
          <Icon style={{ margin: "0px 6px" }} type="info-circle" />
          <strong className="ben-required-label">
            <Trans id="Holding.tooltip.verbiage">
              {" "}
              Please provide documentation for each investment you have submitted as part of this
              liquidity request.
            </Trans>
          </strong>
        </p>
      </Button>
      {isModalOpen ? (
        <Modal
          title={Messages.ModalTitle}
          visible={isModalOpen}
          centered={true}
          width={"100%"}
          style={{
            maxWidth: Layout.MaxWidthModalWindow.standard,
          }}
          wrapClassName="ben-modal-container"
          footer={null}
          onCancel={handleModalCancel}
          destroyOnClose={true}
          maskClosable={false}
        >
          <div>
            <div className="ben-modal-content ben-pt-0">
              <div className="ben-required-text ben-mb-3">
                <p className="ben-mb-1">
                  <strong className="ben-required-label">{Messages.Title}</strong>
                </p>
              </div>

              <ul>
                <li>{Messages.DocumentPPM}</li>
                <li>{Messages.DocumentSubscriptionAgreement}</li>
                <li>{Messages.DocumentLPA}</li>
                <li>{Messages.DocumentK1}</li>
                <li>{Messages.DocumentAudited}</li>
                <li>{Messages.DocumentQuarterlyStatements}</li>
                <li>{Messages.DocumentCapitalStatements}</li>
                <li>{Messages.DocumentOther}</li>
              </ul>
            </div>
            <Button
              type="primary"
              shape="round"
              size="large"
              className="ben-w-100 ben-mb-3"
              onClick={handleModalCancel}
              disabled={false}
              id="instructionalVerbiageInfoOk"
            >
              {Messages.ModalBtnOk}
            </Button>
          </div>
        </Modal>
      ) : null}
    </div>
  );
};

export default InstructionalVerbiage;
