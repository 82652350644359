import {
  BaseActivityWatcherService,
  TypeMessageEventsListenersMap,
} from "../baseActivityWatcher/baseActivityWatcher.service";
import {
  crossTabMessageService,
  CrossTabMessageService,
} from "../crossTabMessage/crossTabMessage.service";
import { CROSS_TAB_EVENTS } from "../crossTabMessage/constants";
import { sessionService, SessionService, TypeSessionData } from "../session/session.service";

const {
  OPEN_SESSION_MODAL,
  USER_ACTIVITY,
  UPDATE_SESSION,
  NEW_TAB_OPENED,
  CLOSE_SESSION,
  SEND_SESSION_DATA,
} = CROSS_TAB_EVENTS;

export class SessionActivityWatcherService extends BaseActivityWatcherService {
  protected readonly maxInactivity: number = 60 * 28;
  protected readonly maxFreezeInactivityFor: number = 120;
  protected messageEventsListenersMap: TypeMessageEventsListenersMap = {
    [USER_ACTIVITY]: this.userActivityListener,
    [UPDATE_SESSION]: this.updateSessionListener,
    [NEW_TAB_OPENED]: this.userActivityListener,
    [OPEN_SESSION_MODAL]: this.openSessionModalListener,
  };

  private sessionService: SessionService;

  constructor(messageService: CrossTabMessageService, sessionDataService: SessionService) {
    super(messageService);
    this.sessionService = sessionDataService;
  }

  protected makeActionOnSessionExpired() {
    this.messageService.emitEvent(CLOSE_SESSION, "makeActionOnSessionExpired", true);
  }

  protected makeActionOnActivity() {
    this.messageService.emitEvent(USER_ACTIVITY);
  }

  protected makeActionOnStartInactivity(expiredIn: number) {
    this.sessionService.startExpirationProcess(this.maxFreezeInactivityFor);

    const sessionData: TypeSessionData | null = this.sessionService.getSessionData();
    if (!sessionData) {
      return;
    }
    // Pass session data to all tabs to be in sync
    this.messageService.emitEvent(SEND_SESSION_DATA, JSON.stringify(sessionData));
    this.messageService.emitEvent(OPEN_SESSION_MODAL, String(expiredIn), true);
  }

  protected makeActionOnContinueInactivity(expiredIn: number) {
    this.messageService.emitEvent(OPEN_SESSION_MODAL, String(expiredIn), false);
  }

  /**
   * Handles USER_ACTIVITY, NEW_TAB_OPENED message on the current tab
   */
  private userActivityListener(): void {
    this.resetLastActivityTime();
  }

  /**
   * Handles UPDATE_SESSION message on the current tab
   */
  private updateSessionListener(): void {
    this.init();
    this.sessionService.stopExpirationProcess();
  }

  /**
   * Handles OPEN_SESSION_MODAL message on the current tab
   */
  private openSessionModalListener(): void {
    this.reset();
  }
}

export const sessionActivityWatcherService = new SessionActivityWatcherService(
  crossTabMessageService,
  sessionService
);
