export class PhoneUtils {
  public MASK = "(999)999-9999999999999999";

  maskPhone(phoneSrc: string | undefined) {
    if (!phoneSrc) {
      return phoneSrc;
    }

    const phone = this.getNumericValue(phoneSrc);

    const p1 = phone.slice(0, 3);
    const p2 = phone.slice(3, 6);
    const p3 = phone.slice(6);

    return `(${p1})${p2}-${p3}`;
  }

  getNumericValue(phone: string) {
    return phone.replace(/\D/g, "");
  }
}

const phoneUtils = new PhoneUtils();
export default phoneUtils;
