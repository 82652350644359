import { RefObject } from "react";
import { Constants } from "common/lib/constants";

export class DomUtils {
  scrollToRef(ref: RefObject<any>): void {
    window.scrollTo(0, ref.current.offsetTop);
  }

  scroll(x: number, y: number): void {
    window.scrollTo(x, y);
  }

  scrollIntoView(node: any): void {
    // scroll to the element
    node.scrollIntoView(true);

    // adjust for fixed header
    const scrolledY = window.scrollY;

    if (scrolledY) {
      window.scroll(0, scrolledY - Constants.HEADER_HEIGHT);
    }
  }

  reload(): void {
    window.location.reload(true);
  }

  elementInViewport(id: string, options: any = {}): boolean {
    let el: any = document.getElementById(id);
    const { pageYOffsetDeflection = 0, heightDeflection = 0 } = options;

    if (!el) {
      return false;
    }

    let top = el.offsetTop;
    let left = el.offsetLeft;
    const width = el.offsetWidth;
    const height = el.offsetHeight;

    while (el.offsetParent) {
      el = el.offsetParent;
      top += el.offsetTop;
      left += el.offsetLeft;
    }

    return (
      top < window.pageYOffset + window.innerHeight &&
      left < window.pageXOffset + window.innerWidth &&
      top + height + heightDeflection > window.pageYOffset + pageYOffsetDeflection + 20 &&
      left + width > window.pageXOffset
    );
  }
}

const domUtils = new DomUtils();
export default domUtils;
