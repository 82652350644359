import { validatorTypes } from "common/shared";
import FormInput from "common/shared/interfaces/form-input";
import { Constants } from "common/lib/constants";

export class PhoneFormSchema {
  public countryCode = new FormInput();
  public phoneNumber = new FormInput();
  public phoneType = new FormInput();

  constructor(data?: any, disabledFields?: { [key: string]: boolean }) {
    this.countryCode.value = Constants.DEFAULT_COUNTRY_CODE.code;

    if (data) {
      this.countryCode.value = data.countryCode || Constants.DEFAULT_COUNTRY_CODE.code;
      this.phoneNumber.value = data.phoneNumber || "";
      this.phoneType.value = data.phoneType || "";
    }
    if (disabledFields) {
      this.countryCode.isDisabled = disabledFields.countryCode || false;
      this.phoneNumber.isDisabled = disabledFields.phoneNumber || false;
      this.phoneType.isDisabled = disabledFields.phoneType || false;
    }
  }
}

export interface PhoneFormReturnedData {
  countryCode: string;
  phoneNumber: string;
  phoneType: string;
}

export const phoneValidationSchema = {
  countryCode: [],
  phoneNumber: [validatorTypes.phone("countryCode")],
  phoneType: [validatorTypes.needOneCorrectLetter],
};

export const impersonatePhoneDisabledConfig = {
  countryCode: true,
  phoneNumber: true,
  phoneType: true,
};

export const phoneValidationSchemaDeps = {
  countryCode: ["phoneNumber"],
  options: {
    validateOnChange: true,
  },
};
