export class ApiTransformUtils {
  camelCaseToUnderscore = (s: string): string => {
    return s.replace(/(?:^|\.?)([A-Z])/g, (x, y) => "_" + y.toLowerCase()).replace(/^_/, "");
  };

  transformObjectToBackendFormat(obj: any): any {
    return Object.keys(obj).reduce((accumulator, key: string) => {
      const transformedField = this.camelCaseToUnderscore(key);
      return { ...accumulator, [transformedField]: obj[key] };
    }, {});
  }

  isEmptyValuesInObjectData = (obj: { [key: string]: string | number | null }): boolean => {
    return !Object.values(obj).some((value) => {
      return Boolean(value) !== false;
    });
  };

  getCleanedObject(data: { [key: string]: any }): { [key: string]: string | number } {
    const copy: { [key: string]: string } = {};
    for (const key in data) {
      if (data[key]) {
        copy[key] = data[key];
      }
    }
    return copy;
  }
}

const apiTransformUtils = new ApiTransformUtils();
export default apiTransformUtils;
