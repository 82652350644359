import React, { useEffect, useState, Fragment, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { Trans } from "@lingui/react";

import { BrowsersUtils } from "common/shared/utils";
import { actions$ } from "common/shared-store";
import { useIsMountedRef } from "common/shared-hooks";

import {
  UploadDocument,
  BenDocumentStatusIcon,
  DocumentTitle,
  DontHaveButton,
  DownloadButton,
  UploadButton,
} from "domains/document/components";
import { ActionTypes } from "domains/document/store/document.actions";
import { setIdontHaveThis } from "domains/document/store/document.actions";

import { PropsBenDocument, TypeUploadingMethod } from "./types";
import { getUniqueBtnIds } from "./helpers";
import { showSetIdontHaveThisSuccess, showSetIdontHaveThisFail } from "./constants";
import "./ben-document.scss";

const BenDocument: React.FC<PropsBenDocument> = (props): React.ReactElement | null => {
  const {
    title,
    withDragger,
    openFileDialogOnClick,
    status,
    text,
    statusIcon,
    handleDownload,
    uploadConfig,
    onUploadStart,
    onUploadCancel,
    onUploadSuccess,
    onUploadError,
    disableUpload,
    disableDontHaveIcon,
    isPlaceholder,
    size,
    disableActions,
  } = props;

  const [isLoading, setLoading] = useState<boolean>(false);
  const [isClickedFileLoading, setClickedFileLoading] = useState<boolean>(false);
  const [isDraggedFileLoading, setDraggedFileLoading] = useState<boolean>(false);

  const [isUploadError, setUploadError] = useState<boolean>(false);
  const [doNotHaveThis, setDoNotHaveThis] = useState<boolean>(!!props.doNotHaveThis);
  const [isUploadBtnTooltipHidden, setUploadBtnTooltipHidden] = useState<boolean>(false);

  const dispatch = useDispatch();

  /**
   * Fix: isMountedRef - React state update on an unmounted component
   */
  const isMountedRef = useIsMountedRef();

  const handleOnClickUploadBtnTooltip = useCallback(() => {
    // Fix only for Safari. 'Upload' tooltips is displayed constantly after clicking the Upload button
    if (BrowsersUtils.isMobile() || !BrowsersUtils.isSafari()) {
      return;
    }
    setUploadBtnTooltipHidden(true);
    setTimeout(() => {
      setUploadBtnTooltipHidden(false);
    }, 100);
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (uploadConfig) {
      const { assetId, documentType } = uploadConfig;

      const subscriptions: any[] = [];
      subscriptions.push(
        actions$
          .ofType(ActionTypes.SetIdontHaveThisSuccess + assetId + documentType)
          .subscribe(() => {
            showSetIdontHaveThisSuccess();
            setLoading(false);
            handleUploadSuccess(null)();
          }),
        actions$.ofType(ActionTypes.SetIdontHaveThisFail + assetId + documentType).subscribe(() => {
          showSetIdontHaveThisFail();
          setLoading(false);
          if (onUploadError) {
            onUploadError();
          }
        })
      );

      return function cleanup() {
        subscriptions.forEach((subscription) => subscription.unsubscribe());
      };
    }
  }, [uploadConfig]);

  const handleUploadSuccess = (type: TypeUploadingMethod | null) => () => {
    if (onUploadSuccess) {
      onUploadSuccess();
    }

    if (!isMountedRef.current) {
      return;
    }

    if (type === "clicked") {
      setClickedFileLoading(false);
    }
    if (type === "dragged") {
      setDraggedFileLoading(false);
    }

    setLoading(false);
    setUploadError(false);
  };

  const handleUploadError = (type: TypeUploadingMethod) => () => {
    if (onUploadError) {
      onUploadError();
    }
    if (type === "clicked") {
      setClickedFileLoading(false);
    }
    if (type === "dragged") {
      setDraggedFileLoading(false);
    }
    setUploadError(true);
    setLoading(false);
  };

  const handleUploadCancel = (type: TypeUploadingMethod) => () => {
    if (onUploadCancel) {
      onUploadCancel();
    }
    if (type === "clicked") {
      setClickedFileLoading(false);
    }
    if (type === "dragged") {
      setDraggedFileLoading(false);
    }
    setLoading(false);
    setUploadError(false);
  };

  const handleUploadStart = (type: TypeUploadingMethod) => () => {
    if (onUploadStart) {
      onUploadStart();
    }
    if (type === "clicked") {
      setClickedFileLoading(true);
    }
    if (type === "dragged") {
      setDraggedFileLoading(true);
    }
    setLoading(true);
    setUploadError(false);
  };

  const markDocAsEmpty = () => {
    if (disableActions) {
      return;
    }
    dispatch(setIdontHaveThis(uploadConfig));
    setDoNotHaveThis(true);
  };

  if (!title || !statusIcon) {
    return null;
  }
  const classes =
    `${withDragger ? "ben-document-content-with-dragger " : ""}` +
    "ben-document-content " +
    (size === "small" ? "ben-document-content-small" : "");
  const statusTitle = status ? <Fragment>({status})</Fragment> : "";
  const { assetId = "", documentType = "" } = uploadConfig || {};
  const uploadConfigDocumentId = uploadConfig ? uploadConfig.documentId : "";
  const documentId = props.documentId || uploadConfigDocumentId;

  const { dontHaveId, downloadId, uploadId } = getUniqueBtnIds({
    title,
    assetId,
    documentType,
    documentId,
  });

  return (
    <Row type="flex" className={classes}>
      <Col md={1} xs={3}>
        <BenDocumentStatusIcon
          doNotHaveThis={doNotHaveThis}
          isUploadError={isUploadError}
          isLoading={isLoading}
          statusIcon={statusIcon}
        />
      </Col>
      <Col
        md={isClickedFileLoading || isUploadError ? 13 : 20}
        xs={14}
        className={`${withDragger && uploadConfig ? "ben-dragged-wrapper" : ""}`}
      >
        {withDragger &&
        uploadConfig &&
        !BrowsersUtils.isMobile() &&
        (isDraggedFileLoading || !disableUpload) ? (
          <UploadDocument
            isDragger={true}
            isShowUploadingName={true}
            isAddToRequestDocumentName={false}
            typeOfNameToShowOnSuccess="file"
            openFileDialogOnClick={openFileDialogOnClick}
            documentName={title}
            documentConfig={uploadConfig}
            disableUpload={disableUpload}
            onUploadSuccess={handleUploadSuccess("dragged")}
            onUploadError={handleUploadError("dragged")}
            onUploadCancel={handleUploadCancel("dragged")}
            onUploadStart={handleUploadStart("dragged")}
          >
            <DocumentTitle
              isDragger={true}
              withClickAction={openFileDialogOnClick}
              isShowFileHelpMessage={true}
              title={title}
              statusTitle={statusTitle}
              isUploadError={isUploadError}
              text={text}
            />
          </UploadDocument>
        ) : (
          <DocumentTitle
            isDragger={false}
            withClickAction={false}
            isShowFileHelpMessage={false}
            title={title}
            statusTitle={statusTitle}
            isUploadError={isUploadError}
            text={text}
          />
        )}
      </Col>
      <Col
        md={isClickedFileLoading || isUploadError ? 10 : 3}
        xs={7}
        className="ben-document-actions ben-d-sm-none"
      >
        {isUploadError && (
          <span className="ben-error-text ben-pr-2">
            <Trans id="documents.fileUpload.fail" />
          </span>
        )}
        {/* TODO: button is hidden by class - "ben-d-sm-none" (for mobile state) */}
        {isPlaceholder && !doNotHaveThis && (
          <DontHaveButton
            disableDontHaveIcon={disableDontHaveIcon}
            handleOnClick={markDocAsEmpty}
            uniqueBtnId={dontHaveId}
          />
        )}
        {handleDownload && (
          <DownloadButton uniqueBtnId={downloadId} handleOnClick={handleDownload} />
        )}
        {!doNotHaveThis && uploadConfig && <span className="ben-divider">|</span>}
        {uploadConfig && (
          <UploadDocument
            isDragger={false}
            openFileDialogOnClick={true}
            isShowUploadingName={false}
            isAddToRequestDocumentName={false}
            typeOfNameToShowOnSuccess="file"
            documentConfig={uploadConfig}
            disableUpload={disableUpload}
            onUploadSuccess={handleUploadSuccess("clicked")}
            onUploadError={handleUploadError("clicked")}
            onUploadCancel={handleUploadCancel("clicked")}
            onUploadStart={handleUploadStart("clicked")}
          >
            <UploadButton
              disableUpload={disableUpload}
              uniqueBtnId={uploadId}
              handleOnClick={handleOnClickUploadBtnTooltip}
              isTooltipHidden={isUploadBtnTooltipHidden}
            />
          </UploadDocument>
        )}
      </Col>
    </Row>
  );
};

export default BenDocument;
