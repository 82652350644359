import { validatorTypes } from "common/shared";
import FormInput from "common/shared/interfaces/form-input";

export class AddressFormSchema {
  public country = new FormInput();
  public street1 = new FormInput();
  public street2 = new FormInput();
  public city = new FormInput();
  public region = new FormInput();
  public postalCode = new FormInput();

  constructor(data?: any, disabledFields?: { [key: string]: boolean }) {
    if (data) {
      this.country.value = data.country || "";
      this.street1.value = data.street1 || "";
      this.street2.value = data.street2 || "";
      this.city.value = data.city || "";
      this.region.value = data.region || "";
      this.postalCode.value = data.postalCode || "";
    }

    if (disabledFields) {
      this.country.isDisabled = disabledFields.country || false;
      this.street1.isDisabled = disabledFields.street1 || false;
      this.street2.isDisabled = disabledFields.street2 || false;
      this.city.isDisabled = disabledFields.city || false;
      this.region.isDisabled = disabledFields.region || false;
      this.postalCode.isDisabled = disabledFields.postalCode || false;
    }
  }
}

export interface AddressFormReturnedData {
  country: string;
  street1: string;
  street2: string;
  city: string;
  region: string;
  postalCode: string;
}

export const addressValidationSchema = {
  country: [],
  street1: [validatorTypes.streetPattern, validatorTypes.needOneCorrectLetter],
  street2: [validatorTypes.streetPattern, validatorTypes.needOneCorrectLetter],
  city: [validatorTypes.cityPattern, validatorTypes.needOneCorrectLetter],
  region: [],
  postalCode: [validatorTypes.postalCodePattern],
};

export const impersonateAddressDisabledConfig = {
  country: true,
  street1: true,
  street2: true,
  city: true,
  region: true,
  postalCode: true,
};
