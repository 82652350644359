import React, { useState } from "react";
import { Form, Input } from "antd";
import { SelectValue } from "antd/lib/select";

import { Options, Spinner, BenFormRowInfo, Select } from "common/lib/components";
import { Constants } from "common/lib/constants";

import { getValidationErrors, localeService } from "common/shared";
import { useOnLocaleChange } from "common/shared-hooks";
import FormConfig from "common/shared/interfaces/form-config";

import { UsersAutoComplete, PrimaryContactDetails } from "domains/clients/components";
import UserLight from "domains/clients/store/user.light.model";

interface PropsProfileUpdateInstitutionForm {
  config: FormConfig;
  accountId?: string;
  accountName: string;
  isUpdating: boolean;
}

const ProfileUpdateInstitutionForm: React.FC<PropsProfileUpdateInstitutionForm> = (
  props
): React.ReactElement | null => {
  const { config, accountId, accountName, isUpdating } = props;
  const { state, handleOnChange, handleOnBlur } = config;
  const [primaryContactUser, setPrimaryContactUser] = useState(state.primaryContactUser.value);
  const countries = useOnLocaleChange(Options.countries);

  const handleSelectChange = (name: string) => (value: SelectValue) => {
    handleOnChange({ target: { name, value } });
  };

  const handleUserSelect = (user: UserLight | null) => {
    if (!user) {
      return;
    }
    setPrimaryContactUser(user);
    handleOnChange({
      target: { name: "primaryContactUser", value: user },
    });
  };
  const handleUserBlur = (userId: string) => {
    if (!userId) {
      setPrimaryContactUser(null);
    }
    handleOnBlur({
      target: { name: "primaryContactUser", value: userId },
    });
  };

  const isGeneralFormValidationShown = () => {
    return config.isDirty && config.hasDirtyInvalidField();
  };

  return (
    <Spinner spinning={isUpdating}>
      <div className="ben-form-holder ben-form-editable-mode">
        <BenFormRowInfo type="changePrimary" />
        <BenFormRowInfo
          type="validation"
          classNames={
            `ben-form-top-validation ben-bottom-border ` +
            (isGeneralFormValidationShown() ? "ben-form-top-validation-show" : "")
          }
        />

        <Form>
          <div>
            <Form.Item
              className="ben-form-label-field"
              label={localeService.i18n._("form.institution.name")}
            >
              <Input name="accountName" value={accountName} disabled={true} />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              className="ben-form-label-field"
              label={localeService.i18n._("profile.contact.update.address1")}
              {...getValidationErrors(
                state.street1.errors,
                localeService.i18n._("profile.contact.update.address1")
              )}
            >
              <Input
                name="street1"
                value={state.street1.value}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                disabled={state.street1.isDisabled}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              className="ben-form-label-field"
              label={localeService.i18n._("profile.contact.update.address2")}
              {...getValidationErrors(
                state.street2.errors,
                localeService.i18n._("profile.contact.update.address2")
              )}
            >
              <Input
                name="street2"
                value={state.street2.value}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                disabled={state.street2.isDisabled}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              className="ben-form-label-field"
              label={localeService.i18n._("profile.contact.update.city")}
              {...getValidationErrors(
                state.city.errors,
                localeService.i18n._("profile.contact.update.city")
              )}
            >
              <Input
                name="city"
                value={state.city.value}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                disabled={state.city.isDisabled}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              className="ben-form-label-field"
              label={localeService.i18n._("profile.contact.update.state")}
            >
              <Select
                showSearch={true}
                id="region"
                style={{ width: "100%" }}
                defaultValue={state.region.value}
                onChange={handleSelectChange("region")}
                disabled={
                  state.region.isDisabled || Constants.DEFAULT_COUNTRY !== state.country.value
                }
                optionFilterProp="children"
                filterOption={Options.filterByStartsWith}
              >
                {Options.usaStates}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              className="ben-form-label-field"
              label={localeService.i18n._("profile.contact.update.zip")}
              {...getValidationErrors(
                state.postalCode.errors,
                localeService.i18n._("profile.contact.update.zip")
              )}
            >
              <Input
                name="postalCode"
                value={state.postalCode.value}
                onChange={handleOnChange}
                onBlur={handleOnBlur}
                disabled={state.postalCode.isDisabled}
              />
            </Form.Item>
          </div>
          <div>
            <Form.Item
              className="ben-form-label-field"
              label={localeService.i18n._("profile.contact.update.country")}
            >
              <Select
                showSearch={true}
                id="country"
                style={{ width: "100%" }}
                defaultValue={state.country.value}
                onChange={handleSelectChange("country")}
                disabled={state.country.isDisabled}
                optionFilterProp="children"
                filterOption={Options.filterByStartsWith}
              >
                {countries}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item
              className="ben-form-label-field"
              label={localeService.i18n._("profile.account.primaryContact")}
              {...getValidationErrors(
                state.primaryContactUser.errors,
                localeService.i18n._("profile.client.primaryContact"),
                "primaryContact.validation"
              )}
            >
              <UsersAutoComplete
                accountId={accountId}
                handleOnSelect={handleUserSelect}
                handleOnBlur={handleUserBlur}
                value={state.primaryContactUser.value}
                disabled={state.primaryContactUser.isDisabled}
              />
            </Form.Item>
          </div>
          <PrimaryContactDetails primaryContactUser={primaryContactUser} />
        </Form>
      </div>
    </Spinner>
  );
};

export default ProfileUpdateInstitutionForm;
