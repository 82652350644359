import { ofType } from "redux-observable";
import { mergeMap, map, switchMap, concatMap, catchError } from "rxjs/operators";
import { from, forkJoin } from "rxjs";

import {
  ActionTypes,
  getClientSuccess,
  getClientFail,
  addIndividualClientSuccess,
  addIndividualClientFail,
  addClientSuccess,
  addClientFail,
  addClientByForce,
  editInstitutionalClientSuccess,
  editInstitutionalClientFail,
  editPrincipalClientSuccess,
  editPrincipalClientFail,
  updateAccountFail,
  updateAccountSuccess,
  getAccount,
  getAccountFail,
  getAccountSuccess,
  changePasswordFail,
  changePasswordSuccess,
  getAccountUsersFail,
  getAccountUsersSuccess,
  getProfileFail,
  getProfileSuccess,
  editInstitutionalPrimarySuccess,
  editInstitutionalPrimaryFail,
  addClientToList,
  getAlertsSuccess,
  getAlertsFail,
  getLRCountSuccess,
  getLRCountFail,
  getClientsSuccess,
  getClientsFail,
} from "./clients.actions";
import clientsAPIService from "../shared/clients.api.service";
import User from "domains/clients/store/user.model";
import Institution from "domains/clients/store/institution.model";
import { messageService, localeService } from "common/shared";
import { ArrayUtils } from "common/shared/utils";
import { t } from "@lingui/macro";
import liquidityAPIService from "../../liquidity/shared/liquidity.api.service";
import {
  ParamsGetLiquidityRequestCounts,
  GetLiquidityRequestCountsResponseData,
  GetClientsAlertsResponseData,
  GetClientsResponseData,
} from "domains/clients/shared/types";
import { TypeAlertsResponseData } from "domains/liquidity";
import ClientsApiParser from "domains/clients/shared/clients.api.parser";

export const getClientsEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.GetClients),
    mergeMap(async (data: any) => {
      try {
        const clients = await clientsAPIService.getAccountClients(data.payload);
        const processedClientsList: GetClientsResponseData = ClientsApiParser.parseClientsList(
          clients
        );
        return getClientsSuccess(processedClientsList);
      } catch (error) {
        return getClientsFail();
      }
    })
  );

export const getClientEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.GetClient),
    mergeMap((data: any) => {
      return clientsAPIService
        .getClient(data.payload)
        .then(async (response: any) => {
          if (response.user) {
            const user = new User({ ...response.user });
            return getClientSuccess(user);
          }
          const institution = new Institution(response);
          return getClientSuccess(institution);
        })
        .catch(() => {
          return getClientFail();
        });
    })
  );

export const addClientEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.AddClient),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      return from(clientsAPIService.addClient(payload)).pipe(
        concatMap(async (response: any) => {
          await clientsAPIService.attacheClientToAdvisor({ client_id: response.account_id });
          return response;
        }),
        switchMap((response: any) => {
          messageService.success("toaster.accountAdd.success");
          const { account_id, account_display_id } = response;
          const newClient = {
            accountName: payload.account_name,
            accountDisplayId: account_display_id,
            id: account_id,
          };

          return [addClientSuccess(account_id), addClientToList(newClient)];
        }),
        catchError((err) => {
          if (err.response.status === 400) {
            if (err.response.data.error_details.error_code === "1004") {
              messageService.error("toaster.conflictEmailAddress.fail");
            } else if (err.response.data.error_details.error_code === "1003") {
              return [
                addClientByForce({ client_id: err.response.data.error_details.error_code_args[0] }),
              ];
            }
          }
          return [addClientFail()];
        })
      );
    })
  );

export const addClientByForceEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.AddClientByForce),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      return from(clientsAPIService.addClientByForce(payload)).pipe(
        switchMap((response: any) => {
          messageService.success("toaster.accountAdd.success");
          const { client_id, user_id } = response;
          const newClient = {
            accountName: "", //payload.account_name,
            accountDisplayId: user_id,
            id: client_id,
          };

          return [addClientSuccess(client_id), addClientToList(newClient)];
        }),
        catchError((err) => {
          if (err.response.status === 400) {
            messageService.error("toaster.conflictEmailAddress.fail");
          }

          return [addClientFail()];
        })
      );
    })
  );

export const addIndividualClientEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.AddIndividualClient),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      return from(clientsAPIService.addClient(payload)).pipe(
        concatMap((response: any) => {
          const accountId = response.account_id;
          return forkJoin(
            clientsAPIService.attacheClientToAdvisor({ client_id: accountId }),
            clientsAPIService.addUser(response.account_id, payload).catch(async (err) => {
              await clientsAPIService.deleteClient(accountId);
              return Promise.reject(err);
            })
          );
        }),
        mergeMap((response: any) => {
          const accountId =
            response && ArrayUtils.isValidArrayData(response) && response[0].client_id;
          messageService.success("toaster.accountAdd.success");
          return [addIndividualClientSuccess(accountId)];
        }),
        catchError((err) => {
          messageService.error("toaster.accountAdd.fail");
          return [addIndividualClientFail()];
        })
      );
    })
  );

export const editInstitutionalClientEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.EditInstitutionalClient),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      const { accountId, data, editedClient } = payload;
      return from(clientsAPIService.updateAccount(data, accountId)).pipe(
        switchMap((response: any) => {
          return [editInstitutionalClientSuccess(), getClientSuccess(editedClient)];
        }),
        catchError((err) => {
          if (!err.response) {
            return [editInstitutionalClientFail(true)];
          }
          return [editInstitutionalClientFail()];
        })
      );
    })
  );

export const editInstitutionalPrimaryEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.EditInstitutionalPrimary),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      const { accountId, userId, data, editedClient } = payload;
      return from(clientsAPIService.updatePrincipal(data, accountId, userId)).pipe(
        switchMap((response: any) => {
          return [editInstitutionalPrimarySuccess(), getClientSuccess(editedClient)];
        }),
        catchError((err) => {
          if (!err.response) {
            return [editInstitutionalPrimaryFail(true)];
          }
          return [editInstitutionalPrimaryFail()];
        })
      );
    })
  );

export const editPrincipalClientEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.EditPrincipalClient),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      const { accountId, userId, data } = payload;
      return from(clientsAPIService.updatePrincipal(data, accountId, userId)).pipe(
        concatMap((asset: any) => clientsAPIService.getUser({ accountId, userId })),
        switchMap((response: any) => {
          const user = new User(response.user);
          messageService.success("toaster.editPrincipalClient.success");
          return [editPrincipalClientSuccess(), getClientSuccess(user)];
        }),
        catchError((err) => {
          return [editPrincipalClientFail(err)];
        })
      );
    })
  );

export const editInstitutionInfoEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.UpdateAccount),
    map((action: any) => action.payload),
    switchMap((payload: any) => {
      const { accountId, data } = payload;

      return from(clientsAPIService.updateAccount(data, accountId)).pipe(
        switchMap((response: any) => {
          messageService.success("toaster.updateInstitutionInfo.success");
          return [getAccount(accountId), updateAccountSuccess(response)];
        }),
        catchError((error) => {
          messageService.error(localeService.i18n._(t`toaster.updateInstitutionInfo.fail`));
          return [updateAccountFail(error)];
        })
      );
    })
  );

export const getAccountEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.GetAccount),
    map((action: any) => action.payload),
    mergeMap((accountId: string) => {
      return clientsAPIService
        .getAccount(accountId)
        .then((account) => getAccountSuccess(account))
        .catch((error) => getAccountFail());
    })
  );

export const changePasswordEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.ChangePassword),
    mergeMap((action: any) =>
      clientsAPIService
        .changePassword(action.payload)
        .then((response) => {
          messageService.success("toaster.changePassword.success");
          return changePasswordSuccess();
        })
        .catch((error) => {
          return changePasswordFail();
        })
    )
  );

export const getAccountUsersEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.GetAccountUsers),
    map((action: any) => action.payload),
    mergeMap((accountId: string) =>
      clientsAPIService
        .getAccountUsers(accountId)
        .then((userData) => getAccountUsersSuccess(userData))
        .catch((error) => getAccountUsersFail())
    )
  );

export const getProfileEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.GetProfile),
    switchMap(() => {
      return from(clientsAPIService.getProfileData()).pipe(
        switchMap((userData: any) => {
          return [getProfileSuccess(userData)];
        }),
        catchError((err) => {
          return [getProfileFail()];
        })
      );
    })
  );

export const getLiquidityRequestCounts = (action$: any, state: any) =>
  action$.pipe(
    ofType(ActionTypes.GetLRCount),
    map((action: { type: string; payload?: ParamsGetLiquidityRequestCounts }) => action.payload),
    mergeMap(async (params?: ParamsGetLiquidityRequestCounts) => {
      try {
        const response: GetLiquidityRequestCountsResponseData = await clientsAPIService.getLiquidityRequestCounts(
          params
        );
        return getLRCountSuccess(response);
      } catch (error) {
        return getLRCountFail();
      }
    })
  );

export const getAlertsEpic = (action$: any, state: any) =>
  action$.pipe(
    ofType(ActionTypes.GetAlerts),
    mergeMap(async () => {
      try {
        const response: TypeAlertsResponseData = await liquidityAPIService.getAlerts();
        const responseParsed: GetClientsAlertsResponseData = ClientsApiParser.getClientToAlertMap(
          response
        );
        return getAlertsSuccess(responseParsed);
      } catch (error) {
        return getAlertsFail();
      }
    })
  );
