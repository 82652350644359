import React, { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";

import { BenCardContent } from "common/lib/components";

import { TypeReferenceType } from "domains/document/shared/types";
import { InstructionalVerbiage, HoldingsDocumentsModal } from "domains/document";
import { UploadDirectoryModule } from "domains/document/modules";
import { getUncategorizedDocuments } from "domains/document/store/document.actions";

import { LiquidityHoldings } from "domains/liquidity/components";
import { AddHoldingModal } from "domains/liquidity/modals";

import { PropsHoldingsSection } from "./types";
import "./holdings-section.scss";

const HoldingsSection: React.FC<PropsHoldingsSection> = (props): React.ReactElement | null => {
  const dispatch = useDispatch();

  const {
    requestId,
    accountId,
    liquidity,
    isClient,
    blockTitle,
    isLoading,
    isSensitiveData,
    currentUserId,
    userDisplayId,
    currentUserFullName,
    currentUserIp,
    locale,
  } = props;

  const getDocuments = useCallback(() => {
    if (!requestId || !accountId) {
      return;
    }

    dispatch(getUncategorizedDocuments({ accountId, requestId }));
  }, [dispatch, requestId, accountId]);

  useEffect(() => {
    getDocuments();
  }, [getDocuments]);

  const onUploadSuccess = useCallback(() => {
    getDocuments();
  }, [getDocuments]);

  return (
    <BenCardContent
      title={blockTitle}
      titleElements={
        liquidity
          ? [
              <AddHoldingModal
                key="addHoldingModal"
                isClient={isClient}
                isLiquidityLoading={Boolean(isLoading)}
                accountId={liquidity.accountId}
                accountDisplayId={liquidity.accountDisplayId}
                accountName={liquidity.accountName}
                requestId={liquidity.id}
                isSensitiveData={isSensitiveData}
                liquidityStatus={liquidity.status}
              />,
              <HoldingsDocumentsModal
                key="holdingsDocumentsModal"
                accountId={liquidity.accountId}
                isLiquidityLoading={Boolean(isLoading)}
                currentUserId={currentUserId}
                userDisplayId={userDisplayId}
                currentUserFullName={currentUserFullName}
                currentUserIp={currentUserIp}
                locale={locale}
              />,
            ]
          : null
      }
      isLoading={isLoading}
      className="ben-liquidity-holdings"
    >
      <LiquidityHoldings
        liquidity={liquidity}
        currentUserId={currentUserId}
        userDisplayId={userDisplayId}
        currentUserFullName={currentUserFullName}
        currentUserIp={currentUserIp}
        locale={locale}
      />

      <div className="holdings-section-instructional-verbiage ben-d-sm-none">
        <InstructionalVerbiage />
      </div>

      {liquidity ? (
        <UploadDirectoryModule
          accountId={liquidity.accountId}
          onUploadSuccess={onUploadSuccess}
          documentsConfig={{
            liquidityRequestId: liquidity.id,
            referenceType: "liquidity_request" as TypeReferenceType,
          }}
          uniqueId={liquidity.id}
        />
      ) : null}
    </BenCardContent>
  );
};

export default HoldingsSection;
