import { useRef, useEffect, MutableRefObject } from "react";

export default function useIsMountedRef(): MutableRefObject<boolean | null> {
  const isMountedRef = useRef<boolean | null>(null);
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  });
  return isMountedRef;
}
