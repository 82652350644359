export const PERMISSION_TYPES = {
  CreateAccount: "CreateAccount",
  ReadAdvisorClients: "ReadAdvisorClients",
  CreateAdvisorClients: "CreateAdvisorClients",
  DeleteAdvisorClients: "DeleteAdvisorClients",
  RunMigrations: "RunMigrations",
  ChangePassword: "ChangePassword",
  CreateResetPasswordNotification: "CreateResetPasswordNotification",
  AcceptInvitation: "AcceptInvitation",
  Impersonate: "Impersonate",
  ReadRolePermissions: "ReadRolePermissions",
  ReadLrAlerts: "ReadLrAlerts",
  MarkLrAlertsAsRead: "MarkLrAlertsAsRead",
  ReadCurrencies: "ReadCurrencies",
  SetPassword: "SetPassword",
  EditAccount: "EditAccount",
  DeleteAccount: "DeleteAccount",
  CreateAccountUser: "CreateAccountUser",
  EditAccountUser: "EditAccountUser",
  CreateAsset: "CreateAsset",
  EditAsset: "EditAsset",
  CreateInvestment: "CreateInvestment",
  EditInvestment: "EditInvestment",
  CreateLr: "CreateLr",
  EditLr: "EditLr",
  EditLrCount: "EditLrCount",
  CreateLrSharing: "CreateLrSharing",
  EditLrSharing: "EditLrSharing",
  DeleteLrSharing: "DeleteLrSharing",
  CreateDocumentVersion: "CreateDocumentVersion",
  CreateDocument: "CreateDocument",
  CreateDocumentConfirmation: "CreateDocumentConfirmation",
  UnmaskSensitiveData: "UnmaskSensitiveData",
};

export const ROLE_TYPES = {
  ADMIN: "admin",
  ADVISOR: "advisor",
  CLIENT: "client",
};

export class RolesService {
  private permissions: null | { [key: string]: boolean } = null;
  private role = "";

  public setPermissions({
    permissions,
    role,
  }: {
    permissions: { [key: string]: boolean };
    role: string;
  }) {
    this.permissions = permissions;
    this.role = role;
  }

  public removePermissions() {
    this.permissions = null;
  }

  public setRole(role: string) {
    this.role = role;
  }

  public getRole() {
    return this.role;
  }

  public clearAll() {
    this.removeRole();
    this.removePermissions();
  }

  public isAdvisor() {
    return this.checkActiveRole(ROLE_TYPES.ADVISOR);
  }

  public isClient() {
    return this.checkActiveRole(ROLE_TYPES.CLIENT);
  }

  public hasPermission(permissionType: string) {
    return !!this.permissions && !!this.permissions[permissionType];
  }

  private checkActiveRole(role: string) {
    return this.role === role;
  }

  private removeRole() {
    this.role = "";
  }
}

export const rolesService = new RolesService();
