import {
  ParamsGetLiquidities,
  LiquidityRequestsListResponseData,
  ParamsGetLiquidity,
  LiquidityRequestResponseData,
  ParamsCreateLiquiditySharing,
  CreateLiquiditySharingResponseData,
  ParamsCreateLiquidity,
  CreateLiquidityResponseData,
  ParamsGetLiquiditySharings,
  LiquiditySharingsResponseData,
  ParamsUpdateLiquiditySharing,
  ParamsResendSharingInvitation,
  LiquidityRequestToAlertMap,
  ParamsCreateSharingAcceptance,
  ParamsClientLiquidityRequestAlerts,
  ParamsSingleLiquidityRequestAlerts,
  LiquidityRequestAlertsResponseData,
  ParamsReadSingleLiquidityAlert,
  ParamsAddHoldings,
} from "domains/liquidity/shared/types";
import { pushAction } from "common/shared-store/actions/actionsStream";

export const ActionTypes = {
  GetLiquidities: "[Dashboard] Get Liquidities",
  GetLiquiditiesFail: "[Dashboard] Get Liquidities Fail",
  GetLiquiditiesSuccess: "[Dashboard] Get Liquidities Success",

  CreateLiquidityRequest: "[Dashboard] Create LiquidityRequest",
  CreateLiquidityRequestFail: "[Dashboard] Create LiquidityRequest Fail",
  CreateLiquidityRequestSuccess: "[Dashboard] Create LiquidityRequest Success",

  GetLiquidity: "[Dashboard] Get Liquidity",
  GetLiquidityFail: "[Dashboard] Get Liquidity Fail",
  GetLiquiditySuccess: "[Dashboard] Get Liquidity Success",

  CreateLiquiditySharing: "[Dashboard] Create LiquiditySharing",
  CreateLiquiditySharingFail: "[Dashboard] Create LiquiditySharing Fail",
  CreateLiquiditySharingSuccess: "[Dashboard] Create LiquiditySharing Success",

  GetLiquiditySharings: "[Dashboard] Get LiquiditySharings",
  GetLiquiditySharingsFail: "[Dashboard] Get LiquiditySharings Fail",
  GetLiquiditySharingsSuccess: "[Dashboard] Get LiquiditySharings Success",

  UpdateLiquiditySharing: "[Dashboard] Update LiquiditySharing",
  UpdateLiquiditySharingFail: "[Dashboard] Update LiquiditySharing Fail",
  UpdateLiquiditySharingSuccess: "[Dashboard] Update LiquiditySharing Success",

  ResendSharingInvitation: "[Dashboard] ResendSharingInvitation",
  ResendSharingInvitationFail: "[Dashboard] ResendSharingInvitation Fail",
  ResendSharingInvitationSuccess: "[Dashboard] ResendSharingInvitation Success",

  GetClientLiquiditiesAlerts: "[Dashboard] Get ClientLiquiditiesAlerts",
  GetClientLiquiditiesAlertsFail: "[Dashboard] Get ClientLiquiditiesAlerts Fail",
  GetClientLiquiditiesAlertsSuccess: "[Dashboard] Get ClientLiquiditiesAlerts Success",

  GetSingleLiquidityAlerts: "[Dashboard] Get LiquidityAlerts",
  GetSingleLiquidityAlertsFail: "[Dashboard] Get LiquidityAlerts Fail",
  GetSingleLiquidityAlertsSuccess: "[Dashboard] Get LiquidityAlerts Success",

  ReadSingleLiquidityAlert: "[Dashboard] Read LiquidityAlert",
  ReadSingleLiquidityAlertFail: "[Dashboard] Read LiquidityAlert Fail",
  ReadSingleLiquidityAlertSuccess: "[Dashboard] Read LiquidityAlert Success",

  CreateSharingAcceptance: "[Dashboard] CreateSharingAcceptance",

  AddHoldings: "[Dashboard] Liquidity AddHoldings",
  AddHoldingsFail: "[Dashboard] Liquidity AddHoldings Fail",
  AddHoldingsSuccess: "[Dashboard] Liquidity AddHoldings Success",
};

// GET Liquidities
export const getLiquidities = (params: ParamsGetLiquidities) => {
  return {
    type: ActionTypes.GetLiquidities,
    payload: params,
  };
};

export const getLiquiditiesSuccess = (data: LiquidityRequestsListResponseData | null) => {
  const action = {
    type: ActionTypes.GetLiquiditiesSuccess,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const getLiquiditiesFail = () => {
  const action = {
    type: ActionTypes.GetLiquiditiesFail,
  };
  pushAction(action);

  return action;
};

// GET Liquidity
export const getLiquidity = (params: ParamsGetLiquidity) => {
  return {
    type: ActionTypes.GetLiquidity,
    payload: params,
  };
};

export const getLiquiditySuccess = (data: LiquidityRequestResponseData | null) => {
  return {
    type: ActionTypes.GetLiquiditySuccess,
    payload: data,
  };
};

export const geLiquidityFail = () => {
  return {
    type: ActionTypes.GetLiquidityFail,
  };
};

export const createLiquidityRequest = (params: ParamsCreateLiquidity) => {
  return {
    type: ActionTypes.CreateLiquidityRequest,
    payload: params,
  };
};

export const createLiquidityRequestSuccess = (data: CreateLiquidityResponseData) => {
  const action = {
    type: ActionTypes.CreateLiquidityRequestSuccess,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const createLiquidityRequestFail = (data?: any) => {
  const action = {
    type: ActionTypes.CreateLiquidityRequestFail,
    payload: data,
  };
  pushAction(action);

  return action;
};

// POST Liquidity Sharing
export const createLiquiditySharing = (params: ParamsCreateLiquiditySharing) => {
  return {
    type: ActionTypes.CreateLiquiditySharing,
    payload: params,
  };
};

export const createLiquiditySharingSuccess = (data: CreateLiquiditySharingResponseData | null) => {
  return {
    type: ActionTypes.CreateLiquiditySharingSuccess,
    payload: data,
  };
};

export const createLiquiditySharingFail = () => {
  return {
    type: ActionTypes.CreateLiquiditySharingFail,
  };
};

// GET Liquidity Sharing
export const getLiquiditySharings = (params: ParamsGetLiquiditySharings) => {
  return {
    type: ActionTypes.GetLiquiditySharings,
    payload: params,
  };
};

export const getLiquiditySharingsSuccess = (data: LiquiditySharingsResponseData | null) => {
  return {
    type: ActionTypes.GetLiquiditySharingsSuccess,
    payload: data,
  };
};

export const geLiquiditySharingsFail = () => {
  return {
    type: ActionTypes.GetLiquiditySharingsFail,
  };
};

// Update / Patch Liquidity Sharing

export const updateLiquiditySharing = (data: ParamsUpdateLiquiditySharing) => {
  return {
    type: ActionTypes.UpdateLiquiditySharing,
    payload: data,
  };
};

export const updateLiquiditySharingSuccess = () => {
  const action = {
    type: ActionTypes.UpdateLiquiditySharingSuccess,
  };
  pushAction(action);

  return action;
};

export const updateLiquiditySharingFail = () => {
  const action = {
    type: ActionTypes.UpdateLiquiditySharingFail,
  };
  pushAction(action);

  return action;
};

// POST Liquidity Sharing Invitation
export const resendSharingInvitation = (params: ParamsResendSharingInvitation) => {
  const action = {
    type: ActionTypes.ResendSharingInvitation,
    payload: params,
  };
  pushAction(action);

  return action;
};

export const resendSharingInvitationSuccess = () => {
  const action = {
    type: ActionTypes.ResendSharingInvitationSuccess,
  };
  pushAction(action);

  return action;
};

export const resendSharingInvitationFail = () => {
  const action = {
    type: ActionTypes.ResendSharingInvitationFail,
  };
  pushAction(action);

  return action;
};

// POST Liquidity Sharing/Invitation Acceptance
export const createSharingAcceptance = (params: ParamsCreateSharingAcceptance) => {
  // response and token is handled in bootstrap reducer
  return {
    type: ActionTypes.CreateSharingAcceptance,
    payload: params,
  };
};

// GET Alerts for specific client
export const getClientLiquiditiesAlerts = (params: ParamsClientLiquidityRequestAlerts) => {
  return {
    type: ActionTypes.GetClientLiquiditiesAlerts,
    payload: params,
  };
};

export const getClientLiquiditiesAlertsSuccess = (data: {
  itemsMapped: LiquidityRequestToAlertMap | null;
}) => {
  return {
    type: ActionTypes.GetClientLiquiditiesAlertsSuccess,
    payload: data,
  };
};

export const geClientLiquiditiesAlertsFail = () => {
  return {
    type: ActionTypes.GetClientLiquiditiesAlertsFail,
  };
};

// GET Alerts for specific LR
export const getSingleLiquidityAlerts = (params: ParamsSingleLiquidityRequestAlerts) => {
  return {
    type: ActionTypes.GetSingleLiquidityAlerts,
    payload: params,
  };
};

export const getSingleLiquidityAlertsSuccess = (data: LiquidityRequestAlertsResponseData) => {
  return {
    type: ActionTypes.GetSingleLiquidityAlertsSuccess,
    payload: data,
  };
};

export const getSingleLiquidityAlertsFail = () => {
  return {
    type: ActionTypes.GetSingleLiquidityAlertsFail,
  };
};

// POST - read Alert for specific LR

export const readSingleLiquidityAlert = (params: ParamsReadSingleLiquidityAlert) => {
  return {
    type: ActionTypes.ReadSingleLiquidityAlert,
    payload: params,
  };
};

export const readSingleLiquidityAlertSuccess = () => {
  return {
    type: ActionTypes.ReadSingleLiquidityAlertSuccess,
  };
};

export const readSingleLiquidityAlertFail = () => {
  return {
    type: ActionTypes.ReadSingleLiquidityAlertFail,
  };
};

/**
 * Add Holding:
 *
 * POST /v1/assets - Creates new Asset.
 * POST /v1/investments - Creates new Investment.
 * PATCH /v1/investments/{id} - Updates Investment's properties.
 */
export const addHoldings = (params: ParamsAddHoldings) => {
  return {
    type: ActionTypes.AddHoldings,
    payload: params,
  };
};

export const addHoldingsSuccess = () => {
  const action = {
    type: ActionTypes.AddHoldingsSuccess,
  };
  pushAction(action);

  return action;
};

export const addHoldingsFail = () => {
  const action = {
    type: ActionTypes.AddHoldingsFail,
  };
  pushAction(action);

  return action;
};
