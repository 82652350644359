import React from "react";
import { Trans } from "@lingui/macro";

export const ResetPasswordTitle = <Trans id="resetpass.form.title">Reset Password</Trans>;
export const SetPasswordTitle = <Trans id="setpass.form.title">Set Password</Trans>;
export const ReceivedMessage = (
  <Trans id="resetpass.confirmPassword.receivedMessage">
    We’ve received a request to reset your password.
  </Trans>
);
