import React, { Fragment } from "react";
import { Form, Input } from "antd";
//import { Options} from "common/lib/components";
import { getValidationErrors, localeService } from "common/shared";
import FormConfig from "common/shared/interfaces/form-config";
//import { useOnLocaleChange } from "common/shared-hooks";

interface PropsPhoneForm {
  config: FormConfig;
  placeholder: string;
}

function PersonalForm({ config, placeholder }: PropsPhoneForm) {
  const { state, handleOnChange, handleOnBlur } = config;
  // const timeZones = useOnLocaleChange(Options.timeZones);

  // todo extend useForm hook to work with select
  // function onSelectChange(e: any) {
  //   handleOnChange({ target: { name: "timeZoneAbbreviation", value: e } });
  // }

  return (
    <Fragment>
      <div>
        <Form.Item
          className="ben-form-label-field ben-required-field"
          label={localeService.i18n._("form.personal.firstName")}
          {...getValidationErrors(
            state.firstName.errors,
            localeService.i18n._("form.personal.firstName")
          )}
        >
          <Input
            name="firstName"
            value={state.firstName.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.firstName.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div>
      {/* <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._("form.personal.middleName")}
          {...getValidationErrors(
            state.middleName.errors,
            localeService.i18n._("form.personal.middleName")
          )}
        >
          <Input
            name="middleName"
            value={state.middleName.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.middleName.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div> */}
      <div>
        <Form.Item
          className="ben-form-label-field ben-required-field"
          label={localeService.i18n._("form.personal.lastName")}
          {...getValidationErrors(
            state.lastName.errors,
            localeService.i18n._("form.personal.lastName")
          )}
        >
          <Input
            name="lastName"
            value={state.lastName.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.lastName.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div>
      {/* <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._("form.personal.suffix")}
          {...getValidationErrors(
            state.suffix.errors,
            localeService.i18n._("form.personal.suffix")
          )}
        >
          <Input
            name="suffix"
            value={state.suffix.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.suffix.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div> */}
      {/* <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._("form.personal.nickname")}
          {...getValidationErrors(
            state.nickName.errors,
            localeService.i18n._("form.personal.nickname")
          )}
        >
          <Input
            name="nickName"
            value={state.nickName.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.nickName.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._("form.personal.timezone")}
          {...getValidationErrors(
            state.timeZoneAbbreviation.errors,
            localeService.i18n._("form.personal.timezone")
          )}
        >
          <Select
            showSearch={true}
            optionFilterProp="children"
            filterOption={Options.filterByStartsWith}
            id="timeZoneAbbreviation"
            style={{ width: "100%" }}
            defaultValue={state.timeZoneAbbreviation.value || placeholder}
            onChange={onSelectChange}
            disabled={state.timeZoneAbbreviation.isDisabled}
          >
            {timeZones}
          </Select>
        </Form.Item>
      </div> */}
      <div>
        <Form.Item
          className="ben-form-label-field ben-required-field"
          label={localeService.i18n._("form.personal.email")}
          {...getValidationErrors(state.email.errors, localeService.i18n._("form.personal.email"))}
        >
          <Input
            name="email"
            value={state.email.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            disabled={state.email.isDisabled}
            placeholder={placeholder}
          />
        </Form.Item>
      </div>
    </Fragment>
  );
}

export default PersonalForm as React.ComponentType<any>;
