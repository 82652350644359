import * as jsonpatch from "fast-json-patch";

import { ArrayUtils } from "common/shared/utils";
import { PatchRequestItemData } from "common/shared/interfaces/requests";
import { apiTransformUtils } from "common/shared";

import {
  LiquidityRequestResponseData,
  LiquidityRequestsListResponseData,
  LiquidityRequestItemData,
  HoldingItemData,
  CreateLiquiditySharingResponseData,
  SharingItemData,
  TypeInviteStatus,
  CreateLiquidityResponseData,
  ParamsCreateLiquidity,
  RequestBodyCreateLiquidity,
  RequestBodyInvestmentItem,
  ParamsCreateLiquiditySharing,
  RequestBodyCreateLiquiditySharing,
  LiquiditySharingsResponseData,
  CreateSharingAcceptanceResponseData,
  LiquidityRequestAlertsResponseData,
  TypeLiquidityStatusCode,
  BackendLikeLiquiditySharingData,
  LiquiditySharingToUpdate,
  TypeCreateAssetData,
  RequestBodyCreateAsset,
  TypeCreateAssetResponseData,
  TypeCreateInvestmentData,
  RequestBodyCreateInvestment,
  TypeCreateInvestmentResponseData,
  TypeUpdateLiquidityInvestmentsData,
  RequestBodyUpdateLiquidityInvestments,
  TypeUpdateLiquidityInvestmentsResponseData,
} from "./types";

interface InvestmentItemRaw {
  asset_id: string;
  investment_id: string;
  fund_name: string;
  sponsor: string;
  status: string;
  initial_commitment_investment_amount: number;
  remaining_commitment_investment_amount: number;
  requested_percentage: number | null;
  currency: string;
}

interface SharingItemRaw {
  shared_with_user_id: string;
  shared_with_user_name: string;
  shared_with_user_email: string;
  status: TypeInviteStatus;
  created_at: string;
}

interface LiquidityRequestItemRaw {
  id: string;
  display_id: string;
  holdings: InvestmentItemRaw[];
  status: TypeLiquidityStatusCode;
  is_active: boolean;
  account_id: string;
  account_name: string;
  account_display_id: string;
  created_at: string;
  created_by: string;
  created_by_name: string;
  previous_status: TypeLiquidityStatusCode | null;
}

interface LiquidityRequestAlertItemRaw {
  alert_id: number;
  created_at: string;
  alert_type: string;
  account_id: string;
  liquidity_request_id: string;
  user_id: string;
  has_been_read: boolean;
}

export function parseLiquidityRequestListResponseData(
  data: any
): LiquidityRequestsListResponseData {
  const result: LiquidityRequestsListResponseData = {
    total: 0,
    page: {
      index: 0,
      size: 0,
    },
    items: [],
  };

  if (!data || typeof data !== "object") {
    return result;
  }

  const isItemsExist = ArrayUtils.isValidArrayData(data.items);
  const parsedItems: LiquidityRequestItemData[] = isItemsExist
    ? data.items.map((item: LiquidityRequestItemRaw) => {
        return {
          id: item.id,
          displayId: item.display_id,
          holdings: parseLiquidityRequestHoldings(item.holdings),
          status: item.status,
          isActive: item.is_active,
          accountId: item.account_id,
          accountName: item.account_name,
          accountDisplayId: item.account_display_id,
          createdAt: item.created_at,
          createdBy: item.created_by,
          // TODO: can be removed item.created_by
          createdByName: item.created_by_name || item.created_by,
          previousStatus: item.previous_status,
        };
      })
    : [];

  result.total = data.total || 0;
  result.page.index = (data.page && data.page.index) || 0;
  result.page.size = data.page && data.page.size;
  result.items = parsedItems;

  return result;
}

export function parseLiquidityRequestResponseData(data: any): LiquidityRequestResponseData {
  const result: LiquidityRequestResponseData = {
    id: "",
    displayId: "",
    accountId: "",
    accountName: "",
    accountDisplayId: "",
    createdAt: "",
    createdBy: "",
    createdByName: "",
    cancellationReason: null,
    status: "ben_rejected_client",
    previousStatus: null,
    isActive: false,
    bottomUpPricing: 0,
    barqPricing: 0,
    topDownPricing: 0,
    finalAdvanceRate: 0,
    holdings: [],
  };

  if (!data || typeof data !== "object" || !data.id) {
    return result;
  }

  result.id = data.id;
  result.displayId = data.display_id;
  result.accountId = data.account_id;
  result.accountName = data.account_name;
  result.accountDisplayId = data.account_display_id;
  result.createdAt = data.created_at;
  result.createdBy = data.created_by;
  result.createdByName = data.created_by_name;
  result.cancellationReason = data.cancellation_reason;
  result.status = data.status;
  result.previousStatus = data.previous_status;
  result.isActive = data.is_active;
  result.bottomUpPricing = data.bottom_up_pricing;
  result.barqPricing = data.barq_pricing;
  result.topDownPricing = data.top_down_pricing;
  result.finalAdvanceRate = data.final_advance_rate;
  result.holdings = parseLiquidityRequestHoldings(data.holdings);

  return result;
}

export function parseCreateLiquidityResponseData(data: any): CreateLiquidityResponseData {
  const result: CreateLiquidityResponseData = {
    id: "",
  };

  if (!data || typeof data !== "object" || !data.id) {
    return result;
  }

  result.id = data.id;

  return result;
}

export function parseCreateLiquiditySharingResponseData(
  data: any
): CreateLiquiditySharingResponseData {
  const result: CreateLiquiditySharingResponseData = {
    id: "",
  };

  if (!data || typeof data !== "object" || !data.id) {
    return result;
  }

  result.id = data.id;

  return result;
}

export function prepareLiquidityRequestArgs(
  data: ParamsCreateLiquidity
): RequestBodyCreateLiquidity {
  const {
    accountId,
    accountDisplayId,
    accountName,
    isSensitive,
    createdBy,
    createdByName,
    assets,
  } = data;

  const investments: RequestBodyInvestmentItem[] = assets.map((asset: any) => ({
    account_id: accountId,
    /*** default "-' hardcoded for old accounts ***/
    account_name: accountName || "-",
    asset: {
      sponsor: asset.sponsor,
      fund_name: asset.fundName,
      account_id: accountId,
    },
    initial_commitment_investment_amount: asset.initialCommitment,
    remaining_commitment_investment_amount: asset.remainingCommitment,
    requested_percentage: asset.liquidity,
    /*** default hardcoded - required for BE ***/
    status: "in_portfolio",
    is_active: true,
    is_sensitive: isSensitive,
    /*** --- ***/
  }));

  const liquidityRequestArgs = {
    account_id: accountId,
    account_display_id: accountDisplayId,
    created_by: createdBy,
    created_by_name: createdByName,
    /*** default "-' hardcoded for old accounts ***/
    account_name: accountName || "-",
    investments,
    /*** default hardcoded - required for BE ***/
    status: "in_verification",
    is_active: true,
    is_sensitive: isSensitive,
    /*** --- ***/
  };

  return liquidityRequestArgs;
}

export function prepareLiquidityRequestSharingArgs(
  data: ParamsCreateLiquiditySharing
): RequestBodyCreateLiquiditySharing {
  const {
    sharedWithAccountId,
    sharedWithUserId,
    sharedWithUserName,
    sharedWithUserEmail,
    status,
  } = data;
  const requestBody = {
    shared_with_account_id: sharedWithAccountId,
    shared_with_user_id: sharedWithUserId,
    shared_with_user_name: sharedWithUserName,
    shared_with_user_email: sharedWithUserEmail,
    status,
  };

  return requestBody;
}

export function parseLiquiditySharingsResponseData(
  data: any,
  requestId: string
): LiquiditySharingsResponseData {
  const result: LiquiditySharingsResponseData = {
    requestId,
    sharings: [],
  };

  if (!data || typeof data !== "object" || !data.items) {
    return result;
  }

  result.sharings = parseLiquidityRequestSharings(data.items);

  return result;
}

export function prepareAssetRequestArgs(
  data: TypeCreateAssetData,
  accountId: string
): RequestBodyCreateAsset {
  const requestBody = {
    fund_name: data.fundName,
    sponsor: data.sponsor,
    account_id: accountId,
  };

  return requestBody;
}

export function parseAssetRequestResponseData(data: any): TypeCreateAssetResponseData {
  const result: TypeCreateAssetResponseData = {
    id: "",
  };

  if (!data || typeof data !== "object" || !data.id) {
    return result;
  }

  result.id = data.id;

  return result;
}

export function prepareInvestmentRequestArgs(
  data: TypeCreateInvestmentData
): RequestBodyCreateInvestment {
  const requestBody = {
    account_id: data.accountId,
    account_name: data.accountName,
    asset_id: data.assetId,
    account_display_id: data.accountDisplayId,
    /*** default hardcoded - required for BE ***/
    status: "in_portfolio",
    is_active: true,
    is_sensitive: data.isSensitive,
    /*** --- ***/
  };

  return requestBody;
}

export function parseInvestmentRequestResponseData(data: any): TypeCreateInvestmentResponseData {
  const result: TypeCreateInvestmentResponseData = {
    id: "",
  };

  if (!data || typeof data !== "object" || !data.id) {
    return result;
  }

  result.id = data.id;

  return result;
}

export function prepareLiquidityInvestmentsRequestArgs(
  data: TypeUpdateLiquidityInvestmentsData
): RequestBodyUpdateLiquidityInvestments {
  const liquidityOriginal = {
    investment_ids: [],
  };
  const liquidityEdited = {
    investment_ids: data.investmentIds,
  };
  const patchData = jsonpatch.compare(liquidityOriginal, liquidityEdited);

  return patchData;
}

export function parseLiquidityInvestmentsRequestResponseData(): TypeUpdateLiquidityInvestmentsResponseData {
  const result: TypeUpdateLiquidityInvestmentsResponseData = {
    ok: true,
  };

  return result;
}

export function parseCreateSharingAcceptanceResponseData(
  data: any
): CreateSharingAcceptanceResponseData {
  const result: CreateSharingAcceptanceResponseData = {
    token: null,
  };

  if (!data || typeof data !== "object" || !data.token) {
    return result;
  }

  result.token = data.token;

  return result;
}

export function prepareLiquiditySharingEditPatch(
  editedData: any,
  originalData: any
): PatchRequestItemData[] | null {
  const editedPreparedData = prepareBackendLikeLiquiditySharing(editedData);
  const originalPreparedData = prepareBackendLikeLiquiditySharing(originalData);
  let patchData = jsonpatch.compare(originalPreparedData, editedPreparedData);
  if (!patchData.length) {
    return null;
  }

  patchData = patchData.map((patchRule) => {
    return {
      ...patchRule,
      path: apiTransformUtils.camelCaseToUnderscore(patchRule.path),
    };
  });

  return patchData;
}

export function parseAlerts(alerts: any): LiquidityRequestAlertsResponseData {
  const result: LiquidityRequestAlertsResponseData = {
    items: [],
  };

  if (!alerts || !ArrayUtils.isValidArrayData(alerts)) {
    return result;
  }

  result.items = alerts.map((alert: LiquidityRequestAlertItemRaw) => ({
    accountId: alert.account_id,
    alertId: alert.alert_id,
    alertType: alert.alert_type,
    createdAt: alert.created_at,
    hasBeenRead: alert.has_been_read,
    liquidityRequestId: alert.liquidity_request_id,
    userId: alert.user_id,
  }));

  return result;
}

// Inner functions-helpers START

function parseLiquidityRequestHoldings(holdings: InvestmentItemRaw[]): HoldingItemData[] {
  return ArrayUtils.isValidArrayData(holdings)
    ? holdings.map((holding: InvestmentItemRaw) => {
        return {
          assetId: holding.asset_id || "",
          investmentId: holding.investment_id || "",
          fundName: holding.fund_name || "",
          sponsor: holding.sponsor || "",
          status: holding.status || "",
          initialCommitmentInvestmentAmount: holding.initial_commitment_investment_amount || 0,
          remainingCommitmentInvestmentAmount: holding.remaining_commitment_investment_amount || 0,
          requestedPercentage: holding.requested_percentage || 0,
          currency: holding.currency,
        };
      })
    : [];
}

function parseLiquidityRequestSharings(sharings: SharingItemRaw[]): SharingItemData[] {
  return ArrayUtils.isValidArrayData(sharings)
    ? sharings.map((sharing: SharingItemRaw) => {
        return {
          sharedWithUserId: sharing.shared_with_user_id || "",
          sharedWithUserName: sharing.shared_with_user_name || "",
          sharedWithUserEmail: sharing.shared_with_user_email || "",
          createdAt: sharing.created_at || "",
          status: sharing.status || "none",
        };
      })
    : [];
}

function prepareBackendLikeLiquiditySharing(
  sharing: LiquiditySharingToUpdate
): BackendLikeLiquiditySharingData {
  const backendLikeSharing: BackendLikeLiquiditySharingData = {
    status: sharing.status || "",
  };

  return backendLikeSharing;
}

// Inner functions-helpers END
