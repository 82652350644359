import React, { Fragment } from "react";
import { Alert } from "antd";
import { Trans } from "@lingui/react";

import { ArrayUtils } from "common/shared/utils";

import { STANDARD_LIQUIDITY_DOCUMENT_LIST } from "domains/document/shared/constants";
import { DocumentTypeDisplaySetting, TypeDocumentType } from "domains/document/shared/types";
import documentService from "domains/document/shared/document.service";
import { BenDocument } from "domains/document/components";
import { OtherAppianTransactionDocuments } from "domains/document/documents-list";

import { PropsAppianTransactionDocuments } from "./types";

const TitleEmptyList = <Trans id="documents.emptyList.title">No documents here</Trans>;

const AppianTransactionDocuments: React.FC<PropsAppianTransactionDocuments> = (
  props
): React.ReactElement | null => {
  const { standardDocuments, otherAppianDocuments, liquidityStatus, handleDownload } = props;
  const isOtherAppianDocumentsExists = ArrayUtils.isValidArrayData(otherAppianDocuments);
  let isStandardDocumentsExists = false;

  return (
    <Fragment>
      {STANDARD_LIQUIDITY_DOCUMENT_LIST.map((docType: TypeDocumentType) => {
        const standardDoc: any = standardDocuments[docType];
        if (
          !standardDoc ||
          standardDoc.length <= 0 ||
          (standardDoc.length > 0 && !standardDoc[0].id && !standardDoc[0].documentType)
        ) {
          return null;
        }
        isStandardDocumentsExists = true;
        let typeSettingsData: DocumentTypeDisplaySetting = { title: "" };
        if (standardDoc && standardDoc.length > 0 && standardDoc[0].documentType) {
          const { typeSettings } = documentService.getDisplaySettingsByType(
            standardDoc[0].documentType
          );
          typeSettingsData = typeSettings;
        } else {
          return null;
        }
        const statusSettings = documentService.getDisplaySettings({
          liquidityStatus,
          documentType: standardDoc[0].documentType,
        });

        return (
          <BenDocument
            withDragger={true}
            openFileDialogOnClick={true}
            key={standardDoc[0].id}
            documentId={standardDoc[0].id}
            title={typeSettingsData.title}
            size="small"
            text={statusSettings.text}
            status={statusSettings.status}
            statusIcon={statusSettings.iconPath}
            handleDownload={handleDownload(standardDoc[0].id)}
          />
        );
      })}

      {isOtherAppianDocumentsExists ? (
        <OtherAppianTransactionDocuments
          otherAppianDocuments={otherAppianDocuments}
          handleDownload={handleDownload}
        />
      ) : null}

      {!isStandardDocumentsExists && !isOtherAppianDocumentsExists && (
        <Alert message={TitleEmptyList} type="warning" showIcon={true} />
      )}
    </Fragment>
  );
};

export default AppianTransactionDocuments;
