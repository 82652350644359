import React from "react";

import { Trans } from "@lingui/macro";
import { navigate } from "@reach/router";

import { localeService } from "common/shared";
import { IconCustom } from "common/lib/components";
import { Urls } from "common/lib/constants";

import { PropsClientCard } from "./types";
import "./client-card.scss";

const ClientCard: React.FC<PropsClientCard> = (props): React.ReactElement | null => {
  const { account, countLR, hasAlert } = props;
  const { accountName, accountId, accountDisplayId } = account;

  const isCountLR = typeof countLR === "number";
  const goToClientPage = () =>
    navigate(`${Urls.PROTECTED.DASHBOARD}${Urls.PROTECTED.CLIENTS}/${accountId}`);

  const accountNameArr = accountName.split(" ");

  let accountNameResult;

  if (accountNameArr.length > 2) {
    accountNameResult = () => {
      return <div className="ben-name">{accountName}</div>;
    };
  } else {
    accountNameResult = () => {
      return (
        <div className="ben-name">
          {accountNameArr[0]}
          <br></br>
          {accountNameArr[1]}
        </div>
      );
    };
  }

  return (
    <div className="ben-client-card" id={`benClient-${accountId}`} onClick={goToClientPage}>
      {hasAlert && (
        <span className="ben-alert">
          <IconCustom type="attention" />
        </span>
      )}
      {accountNameResult()}
      <div className="ben-requests ben-vertical ben-d-flex">
        <span>
          <Trans id="dashboard.advisor.clientId">Client ID</Trans>: {accountDisplayId}
        </span>
        {isCountLR && (
          // TODO: can be added animation "fade in"
          <span>
            {localeService.i18n._(
              `${localeService.plural({
                value: countLR,
                zero: "dashboard.advisor.countLR.zero",
                one: "dashboard.advisor.countLR.one",
                other: "dashboard.advisor.countLR.other",
              })}`,
              { count: countLR }
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default ClientCard;
