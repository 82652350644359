import { useEffect } from "react";
import TagManager from "react-gtm-module";

import { portalEnvConstants } from "common/shared";

interface GoogleTagManagerTrackerType {
  location: { pathname: string };
}

export const GoogleTagManagerTracker = ({ location }: GoogleTagManagerTrackerType) => {
  useEffect(() => {
    if (!portalEnvConstants.googleTagManagerId) {
      return;
    }

    // initialize Google Tag Manager
    const tagManagerArgs = {
      gtmId: portalEnvConstants.googleTagManagerId,
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  // push event to GTM on location change
  useEffect(() => {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({
      pageView: location.pathname,
    });
  }, [location]);

  return null;
};
