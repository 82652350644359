import { Constants } from "common/lib/constants";

/* eslint-disable no-control-regex */
const validationConstants = {
  /* eslint-disable no-useless-escape */
  einPattern: /^\d{2}[\-]\d{7}$/,
  emailPattern: /^((([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([A-Za-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-(?!-)|\.(?!\.)|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([A-Za-z]|\d|-(?!-)|\.(?!\.)|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([A-Za-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/,
  userNamePattern: /^$|^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]){1}[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\- ]{1,49}$/,
  middleNamePattern: /^$|^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]){1}[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\- ]{0,49}$/,
  phonePattern: /^[0-9\\(\\)\\-\\. ]{10,25}$/,
  usaPhonePattern: /^\d{10}$/,
  defaultPhonePattern: /^\D*(?:\d[+ ()-]*){9,25}$/,
  postalCodePattern: /^$|^[A-Z0-9\\ -]{2,11}$/,
  suffixPattern: /^$|^(^[a-zA-Z])[a-zA-Z., ]{1,49}$/,
  streetPattern: /^$|^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\-# ]{1,50}$/,
  cityPattern: /^$|^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\-# ]{2,50}$/,
  needOneCorrectLetter: /[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]{1,}/,
  passMaxLength: 20,
  passMinLength: 8,
  passPattern: /^(?=^.{8,20}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"#@$%&'()*+,-./:;<=>?[\]^_{|}~]).*$/,
  organizationNamePattern: /^$|^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\-#!\\"#$%&'()*+,-./:;<=>?[\]^_{|}~ ]{1,150}$/,
  fundNamePattern: /^$|^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\-#!\\"#$%&'()*+,-./:;<=>?[\]^_{|}~ ]{1,50}$/,
  sponsorNamePattern: /^$|^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\-#!\\"#$%&'()*+,-./:;<=>?[\]^_{|}~ ]{1,50}$/,
  accountName: /^$|^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\-#!\\"#$%&'()*+,-./:;<=>?[\]^_{|}~ ]{1,50}$/,
  numbers: /^[0-9]+$/,
  documentName: /^$|^[0-9a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð.,'\-#!\\"#$%&'()*+,-./:;<=>?[\]^_{|}~ ]{1,50}$/,
  max2Decimals: /^\d*\.?(?:\d{1,2})?$/,
  MAX_DIGITS: 13,
  ssnPattern: /^(?!(000|666|9))(\d{3}-?(?!(00))\d{2}-?(?!(0000))\d{4})$/,
  // For now it is unused validator. Can be removed along with src\domains\clients\components\individual-data-form-unused
  forbiddenSsn: ["078-05-1120", "219-09-9999", "123-45-6789"],
};

const validatorTypes = {
  confirm,
  differValue,
  min,
  max,
  minLength,
  maxLength,
  pattern,
  required,
  emailPattern: pattern(validationConstants.emailPattern),
  namePattern: pattern(validationConstants.userNamePattern),
  middleNamePattern: pattern(validationConstants.middleNamePattern),
  einPattern: einValidate,
  suffixPattern: pattern(validationConstants.suffixPattern),
  passMaxLength: maxLength(validationConstants.passMaxLength),
  passMinLength: minLength(validationConstants.passMinLength),
  passPattern: pattern(validationConstants.passPattern),

  phonePattern: pattern(validationConstants.phonePattern),
  postalCodePattern: pattern(validationConstants.postalCodePattern),
  streetPattern: pattern(validationConstants.streetPattern),
  cityPattern: pattern(validationConstants.cityPattern),
  needOneCorrectLetter: pattern(validationConstants.needOneCorrectLetter),

  fundNamePattern: pattern(validationConstants.fundNamePattern),
  sponsorNamePattern: pattern(validationConstants.sponsorNamePattern),
  accountName: pattern(validationConstants.accountName),
  organizationName: pattern(validationConstants.organizationNamePattern),
  number: number(),
  positive: min(0),
  numbers: pattern(validationConstants.numbers),
  documentNamePattern: pattern(validationConstants.documentName),
  max2DecimalsPattern: pattern(validationConstants.max2Decimals),
  maxDigits: maxDigits(),
  phone: phoneValidate,
  ssnPattern: ssnValidate,
};

function minLength(minV: number) {
  return (value: string) => (value.length < minV ? { minLength: true } : null);
}

function maxLength(maxV: number) {
  return (value: string) => (value.length > maxV ? { maxLength: true } : null);
}

function pattern(patternExp: RegExp) {
  return (value: string) => {
    if (!value) {
      return null;
    }

    return patternExp.test(value) ? null : { pattern: true };
  };
}

function required(value: string) {
  return !value ? { required: true } : null;
}

function confirm(fieldNameToCompareWith: string) {
  return (value: string, formValue: any) => {
    if (!value && !formValue[fieldNameToCompareWith].value) {
      return null;
    }

    return !value || value !== formValue[fieldNameToCompareWith].value ? { confirm: true } : null;
  };
}

function differValue(fieldNameToCompareWith: string) {
  return (value: string, formValue: any) => {
    if (!value && !formValue[fieldNameToCompareWith].value) {
      return null;
    }

    if (value === formValue[fieldNameToCompareWith].value) {
      return { differValue: true };
    }

    return null;
  };
}

function number() {
  return (value: string) => (isNaN(Number(value)) ? { pattern: true } : null);
}

function maxDigits() {
  return (value: string) => {
    const parts = value.toString().split(".");
    const decimalLength = parts[1] ? parts[1].length : 0;

    if (parts[0].length + decimalLength > validationConstants.MAX_DIGITS) {
      return { pattern: true };
    }

    return null;
  };
}

function max(maxValue: number) {
  return (value: string) => (Number(value) <= maxValue ? null : { pattern: true });
}

function min(minValue: number) {
  return (value: string) => (Number(value) >= minValue ? null : { pattern: true });
}

function einValidate(value: string) {
  if (!value) {
    return null;
  }

  if (!validationConstants.einPattern.test(value)) {
    return { pattern: true };
  }
  const einId = value.split("-")[0];
  return einRange.indexOf(einId) >= 0 ? null : { pattern: true };
}

/**
 * For now it is unused validator.
 * Can be removed along with src\domains\clients\components\individual-data-form-unused
 */
function ssnValidate(value: string) {
  if (!value) {
    return null;
  }

  if (!validationConstants.ssnPattern.test(value)) {
    return { pattern: true };
  }

  if (validationConstants.forbiddenSsn.some((el: string) => el === value)) {
    return { pattern: true };
  }
  return null;
}

function phoneValidate(countryCode: string) {
  return (value: string, formValue: any) => {
    switch (formValue[countryCode].value) {
      case Constants.DEFAULT_COUNTRY_CODE.code:
        return pattern(validationConstants.usaPhonePattern)(value);
      default:
        return pattern(validationConstants.defaultPhonePattern)(value);
    }
  };
}

const einRange = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "50",
  "51",
  "52",
  "53",
  "54",
  "55",
  "56",
  "57",
  "58",
  "59",
  "60",
  "61",
  "62",
  "63",
  "64",
  "65",
  "66",
  "67",
  "68",
  "71",
  "72",
  "73",
  "74",
  "75",
  "76",
  "77",
  "80",
  "81",
  "82",
  "83",
  "84",
  "85",
  "86",
  "87",
  "88",
  "90",
  "91",
  "92",
  "93",
  "94",
  "95",
  "98",
  "99",
];

export default validatorTypes;
