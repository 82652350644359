import { useSelector } from "react-redux";

import { Bootstrap } from "common/shared-store/reducers/bootstrap";

interface BootstrapRedirectAfterLoginData {
  navigateToAfterLogin: string;
}

function useBootstrapRedirectAfterLoginData(): BootstrapRedirectAfterLoginData {
  const bootstrap: Bootstrap = useSelector((storeState: any) => {
    return storeState.bootstrap;
  });

  return {
    navigateToAfterLogin: bootstrap.navigateToAfterLogin,
  };
}

export default useBootstrapRedirectAfterLoginData;
