import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, navigate } from "@reach/router";
import { Drawer } from "antd";

import {
  Logo,
  Footer,
  Spinner,
  MainNav,
  LanguageSelector,
  BenAppSpinner,
} from "common/lib/components";
import { Urls } from "common/lib/constants";
import { useLocaleSetter, useSelectedLocaleData } from "common/shared-hooks";
import { actions$ } from "common/shared-store";

import { rolesService } from "common/shared/services/roles/roles.service";

import {
  LoginProfileData,
  LogoutBtn,
  init,
  logout,
  ActionTypes,
  useLogoutData,
  usePermissionsRequest,
} from "domains/identity";
import { GreetingProfile, useUserSettingsData } from "domains/clients";

import logo from "common/assets/images/ben-logo-header.svg";
import logoMobile from "common/assets/images/ben-logo-header-m.svg";

import "./MainWrapper.scss";
import { SessionModal } from "domains/authentication";

interface PropsMainWrapper extends RouteComponentProps {
  checkAuth: () => boolean;
}

const MainWrapper: React.FC<PropsMainWrapper> = (props): React.ReactElement | null => {
  const { children, checkAuth } = props;
  const dispatch = useDispatch();
  // Get setting from the user data
  const {
    locale: userLocale,
    isCompletedUserRequest: isUserCompletedUserRequest,
  } = useUserSettingsData();
  const isAccessForbidden = checkAuth && !checkAuth();
  const isLoggingOut = useLogoutData();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.LogoutSuccess, ActionTypes.LogoutFail])
        .subscribe((action: any) => {
          const urlToRedirectTo = action.payload || Urls.AUTH.SIGN_IN;
          navigate(urlToRedirectTo);
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isAccessForbidden) {
      dispatch(logout());
      // we DO NOT NEED to get user's identity if logout is dispatched
      return;
    }

    dispatch(init());
  }, []);

  useEffect(() => {
    if (!userLocale) {
      return;
    }
    dispatch(selectLocale(userLocale));
  }, [userLocale]);

  const { lang, locale } = useSelectedLocaleData();
  const { loading: permissionsLoading } = usePermissionsRequest();
  const selectLocale = useLocaleSetter();
  const [visibleDrawerState, setVisibleDrawer] = useState(false);

  if (isAccessForbidden) {
    return null;
  }

  let mainUrl = "";

  // Main URL for different roles
  if (rolesService.isAdvisor()) {
    mainUrl = Urls.PROTECTED.DASHBOARD + Urls.PROTECTED.CLIENTS;
  } else {
    mainUrl = Urls.PROTECTED.DASHBOARD;
  }

  const handleDrawer = () => {
    setVisibleDrawer(!visibleDrawerState);
  };

  const closeDrawerOnLink = () => {
    setVisibleDrawer(false);
  };

  const isMainSpinning = Boolean(!isUserCompletedUserRequest && !userLocale);
  const localeClassName = lang
    ? `ben-locale-${lang} ${lang !== "en" && "ben-locale-not-default"}`
    : "";

  return permissionsLoading ? (
    <BenAppSpinner />
  ) : (
    <Spinner
      spinning={isMainSpinning || isLoggingOut}
      className={isMainSpinning ? "ben-spinner-load-settings" : ""}
    >
      <Fragment>
        <header className={`ben-header-main ${localeClassName}`}>
          <div className="ben-container">
            {/* tablet/desktop state */}
            <div className="ben-d-sm-none ben-header-desktop">
              <div className="ben-header-main-top ben-d-flex ben-justify-content-end">
                <div className="ben-hidden">
                  <LanguageSelector selectedLocale={locale} setLocale={selectLocale} />
                </div>
                <GreetingProfile />
                <LogoutBtn />
              </div>
              <div className="ben-header-main-bottom">
                <Logo
                  to={mainUrl}
                  src={logo}
                  width={275}
                  alt="Ben, The Beneficient Company Group, LP"
                />
                <MainNav mainUrl={mainUrl} closeDrawerOnLInk={closeDrawerOnLink} />
              </div>
            </div>

            {/* mobile state */}
            <div className="ben-d-none ben-d-sm-block ben-align-self-center">
              <div className="ben-header-main-mobile">
                <Logo
                  to={mainUrl}
                  closeDrawerOnLInk={closeDrawerOnLink}
                  src={logoMobile}
                  width={100}
                  alt="Ben, The Beneficient Company Group, LP"
                />
                <button
                  id="benBurgerBtn"
                  className={"ben-btn-burger " + (visibleDrawerState ? "active" : "")}
                  onClick={handleDrawer}
                >
                  <span />
                  <span />
                  <span />
                </button>
              </div>

              <Drawer
                className="ben-header-menu-drawer"
                placement="top"
                closable={false}
                onClose={handleDrawer}
                visible={visibleDrawerState}
              >
                <Spinner spinning={isLoggingOut}>
                  <Fragment>
                    <GreetingProfile />
                    <LoginProfileData />
                    <MainNav mainUrl={mainUrl} closeDrawerOnLInk={closeDrawerOnLink} />
                    <LogoutBtn />
                  </Fragment>
                </Spinner>
              </Drawer>
            </div>
          </div>
        </header>

        <div className={`ben-main-container ${localeClassName}`}>
          <div className="ben-container">{children}</div>
        </div>
        <SessionModal />
        <Footer logoUrl={mainUrl} />
      </Fragment>
    </Spinner>
  );
};

export default MainWrapper;
