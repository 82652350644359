import React from "react";

import "./icon-custom.scss";

const IconCustom = (props: any) => {
  const { type, className, style } = props;

  return (
    <span className={`ben-icon icon-${type} ` + (className || "")} style={style}>
      <span className="path1" />
      <span className="path2" />
      <span className="path3" />
    </span>
  );
};

export default IconCustom as React.ComponentType<any>;
