import Numeral from "numeral";
import "numeral/locales";

import { Constants } from "common/lib/constants";
import { portalEnvConstants } from "common/shared";

export class NumberUtils {
  private numeralService: Numeral;
  private locale: string = "";

  constructor(numeralService: Numeral) {
    this.numeralService = numeralService;
  }

  public formatPercentToFE(percent: string | number): string {
    const value: number = this.getParsedNumber(percent) / 100;
    return new Intl.NumberFormat(portalEnvConstants.defaultLocale, {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  }

  public formatPriceToFE(
    amount: string | number,
    selectedLocale: string,
    currencyISO: string = Constants.DEFAULT_CURRENCY.currencyCode
  ): string {
    const value: number = this.getParsedNumber(amount);
    this.setLocale(selectedLocale);
    const formattedValue = this.numeralService(value).format();

    if (currencyISO === Constants.DEFAULT_CURRENCY.currencyCode) {
      return `${Constants.DEFAULT_CURRENCY.currencySign}${formattedValue}`;
    }

    return formattedValue;
  }

  public parseMoney(selectedLocale: string) {
    return (value?: string) => {
      if (!value) {
        return "";
      }
      this.setLocale(selectedLocale);
      return String(this.numeralService(value).value());
    };
  }

  public formatMoney(currencySign: string, selectedLocale: string) {
    return (value?: number | string) => {
      if (!value) {
        return "";
      }
      this.setLocale(selectedLocale);
      const formattedValue = this.numeralService(value).format();
      return `${currencySign}${formattedValue}`;
    };
  }

  public parsePercent(value = "") {
    const parsedValue = value.replace("%", "");

    return Number(parsedValue).toString();
  }

  public formatPercent(value?: number | string) {
    return value ? `${value}%` : "";
  }

  public getIntFromString(value: string): number | null {
    if (!value) {
      return null;
    }

    const numbers = value.match(/\d/g);
    if (!numbers) {
      return null;
    }
    return parseInt(numbers.join(""));
  }

  private getParsedNumber(value: string | number): number {
    return typeof value === "string" ? parseFloat(value) : value;
  }

  private setLocale(selectedLocale: string): void {
    if (this.locale !== selectedLocale) {
      this.locale = selectedLocale;
      this.numeralService.locale(selectedLocale);
    }
  }
}

const numberUtils = new NumberUtils(Numeral);
export default numberUtils;
