import { validatorTypes } from "common/shared";
import FormInput from "common/shared/interfaces/form-input";

export class InstitutionalFormSchema {
  public name = new FormInput();

  constructor(data?: any, disabledFields?: { [key: string]: boolean }) {
    if (data) {
      this.name.value = data.name || "";
    }

    if (disabledFields) {
      this.name.isDisabled = disabledFields.name || false;
    }
  }
}

export const institutionalValidationSchema = {
  name: [validatorTypes.required, validatorTypes.organizationName],
};

export const impersonateInstitutionalDisabledConfig = {
  name: true,
};
