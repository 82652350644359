import { validatorTypes } from "common/shared";
import FormInput from "common/shared/interfaces/form-input";

export class UploadFileFormSchema {
  public documentName: FormInput = {
    value: "",
    isDirty: false,
  };
}

export const uploadFileValidationSchema = {
  documentName: [validatorTypes.required, validatorTypes.documentNamePattern],
};
