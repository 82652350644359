import { pushAction } from "common/shared-store/actions/actionsStream";

import {
  ParamsGetDocuments,
  ParamsDocumentsByHoldings,
  DocumentListResponseData,
  ParamsGetDocumentUrl,
  DocumentUrlResponseData,
  ParamsConfirmUpload,
  ParamsAddTransactionDocument,
  ParamsGetPublicDocumentUrl,
} from "domains/document/shared/types";

export const ActionTypes = {
  GetDocumentsByHolding: "[Documents] Get Holding Documents",
  GetDocumentsByHoldingFail: "[Documents] Get Holding Documents Fail",
  GetDocumentsByHoldingSuccess: "[Documents] Get Holding Documents Success",

  RefreshHoldingDocuments: "[Documents] RefreshHoldingDocuments",

  SetIdontHaveThis: "[Documents] Set I Don't Have This",
  SetIdontHaveThisFail: "[Documents] Set I Don't Have This Fail",
  SetIdontHaveThisSuccess: "[Documents] Set I Don't Have This Success",

  GetTransactionDocuments: "[Documents] GetTransactionDocuments",
  GetTransactionDocumentsFail: "[Documents] GetTransactionDocuments Fail",
  GetTransactionDocumentsSuccess: "[Documents] GetTransactionDocuments Success",

  GetUncategorizedDocuments: "[Documents] GetUncategorizedDocuments",
  GetUncategorizedDocumentsFail: "[Documents] GetUncategorizedDocuments Fail",
  GetUncategorizedDocumentsSuccess: "[Documents] GetUncategorizedDocuments Success",

  AddTransactionDocument: "[Documents] AddTransactionDocument",

  GetDocumentUrl: "[Documents] Get Document Url",
  GetDocumentUrlFail: "[Documents] Get Document Url Fail",
  GetDocumentUrlSuccess: "[Documents] Get Document Url Success",

  ConfirmUpload: "[Documents] Confirm Upload Document",
  ConfirmUploadFail: "[Documents] Confirm Upload Document Fail",
  ConfirmUploadSuccess: "[Documents] Confirm Upload Document Success",

  CancelUpload: "[Documents] Cancel Upload",

  GetPublicDocumentUrl: "[Documents] Get Public Document Url",
  GetPublicDocumentUrlFail: "[Documents] Get Public Document Url Fail",
  GetPublicDocumentUrlSuccess: "[Documents] Get Public Document Url Success",
};

// GET Documents
export const getTransactionDocuments = (params: ParamsGetDocuments) => {
  return {
    type: ActionTypes.GetTransactionDocuments,
    payload: params,
  };
};

export const getTransactionDocumentsSuccess = (data: DocumentListResponseData | null) => {
  return {
    type: ActionTypes.GetTransactionDocumentsSuccess,
    payload: data,
  };
};

export const getTransactionDocumentsFail = () => {
  return {
    type: ActionTypes.GetTransactionDocumentsFail,
  };
};

// GET Uncategorized (unknown) Documents
export const getUncategorizedDocuments = (params: ParamsGetDocuments) => {
  return {
    type: ActionTypes.GetUncategorizedDocuments,
    payload: params,
  };
};

export const getUncategorizedDocumentsSuccess = (data: DocumentListResponseData | null) => {
  return {
    type: ActionTypes.GetUncategorizedDocumentsSuccess,
    payload: data,
  };
};

export const getUncategorizedDocumentsFail = () => {
  return {
    type: ActionTypes.GetUncategorizedDocumentsFail,
  };
};

export const addTransactionDocument = (params: ParamsAddTransactionDocument) => {
  return {
    type: ActionTypes.AddTransactionDocument,
    payload: { newItem: params.newItem },
  };
};

// GET Documents by holdings
export const getDocumentsByHolding = (params: ParamsDocumentsByHoldings) => {
  return {
    type: ActionTypes.GetDocumentsByHolding,
    payload: params,
  };
};

export const getDocumentsByHoldingSuccess = (data: DocumentListResponseData | null) => {
  return {
    type: ActionTypes.GetDocumentsByHoldingSuccess,
    payload: data,
  };
};

export const getDocumentsByHoldingFail = () => {
  return {
    type: ActionTypes.GetDocumentsByHoldingFail,
  };
};

export const refreshHoldingDocuments = () => {
  const action = {
    type: ActionTypes.RefreshHoldingDocuments,
  };
  pushAction(action);
  return action;
};

// GET Document URL
export const getDocumentUrl = (params: ParamsGetDocumentUrl) => {
  return {
    type: ActionTypes.GetDocumentUrl,
    payload: params,
  };
};

export const getDocumentUrlSuccess = (data: DocumentUrlResponseData) => {
  const action = {
    type: ActionTypes.GetDocumentUrlSuccess,
    payload: data,
  };
  pushAction(action);
  return action;
};

export const getDocumentUrlFail = () => {
  const action = {
    type: ActionTypes.GetDocumentUrlFail,
  };
  pushAction(action);
  return action;
};

// GET Public Document URL
export const getPublicDocumentUrl = (params: ParamsGetPublicDocumentUrl) => {
  return {
    type: ActionTypes.GetPublicDocumentUrl,
    payload: params,
  };
};

export const getPublicDocumentUrlSuccess = (data: DocumentUrlResponseData) => {
  const action = {
    type: ActionTypes.GetPublicDocumentUrlSuccess,
    payload: data,
  };
  pushAction(action);
  return action;
};

export const getPublicDocumentUrlFail = () => {
  const action = {
    type: ActionTypes.GetPublicDocumentUrlFail,
  };
  pushAction(action);
  return action;
};

// Confirm Upload File
export const confirmUpload = (params: ParamsConfirmUpload) => {
  return {
    type: ActionTypes.ConfirmUpload,
    payload: params,
  };
};

export const confirmUploadSuccess = (config: any) => {
  const action = {
    type: ActionTypes.ConfirmUploadSuccess,
    payload: config,
  };
  pushAction(action);
  return action;
};

export const confirmUploadFail = (config: any) => {
  const action = {
    type: ActionTypes.ConfirmUploadFail,
    payload: config,
  };
  pushAction(action);
  return action;
};

export const cancelUpload = () => {
  const action = {
    type: ActionTypes.CancelUpload,
  };
  pushAction(action);
  return action;
};

export const setIdontHaveThis = (config: any) => {
  const action = {
    type: ActionTypes.SetIdontHaveThis,
    payload: config,
  };
  pushAction(action);
  return action;
};

export const setIdontHaveThisSuccess = (assetId: any, documentType: any) => {
  const action = {
    type: ActionTypes.SetIdontHaveThisSuccess + assetId + documentType,
  };
  pushAction(action);
  return action;
};

export const setIdontHaveThisFail = (assetId: any, documentType: any) => {
  const action = {
    type: ActionTypes.SetIdontHaveThisFail + assetId + documentType,
  };
  pushAction(action);
  return action;
};
