import useForm from "./use-form";
import useLocaleSetter from "./use-locale-setter";
import useBreadcrumbItems from "./use-breadcrumb-items";
import useBootstrapSharingData from "./use-bootstrap-sharing-data";
import useBootstrapTokenForPassData from "./use-bootstrap-token-for-pass";
import useBootstrapRoutesToRedirectData from "./use-bootstrap-routes-to-redirect-data";
import useHtmlTitle from "./use-html-title";
import useCurrencyData from "./use-currency-data";
import useBootstrapAcceptIdentityData from "./use-bootstrap-accept-identity-data";
import useSelectedLocaleData from "./use-selected-locale";
import useOnLocaleChange from "./use-on-locale-change";
import useGetAppVersion from "./use-get-app-version";
import useBootstrapRedirectAfterLoginData from "./use-bootstrap-redirect-after-login-data";
import useIsMountedRef from "./use-is-mounted-ref";

export {
  useForm,
  useLocaleSetter,
  useBreadcrumbItems,
  useBootstrapSharingData,
  useBootstrapTokenForPassData,
  useBootstrapRoutesToRedirectData,
  useBootstrapAcceptIdentityData,
  useHtmlTitle,
  useCurrencyData,
  useSelectedLocaleData,
  useOnLocaleChange,
  useGetAppVersion,
  useBootstrapRedirectAfterLoginData,
  useIsMountedRef,
};
