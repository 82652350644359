import { combineEpics } from "redux-observable";

import {
  forgotPasswordEpic,
  loginEpic,
  acceptIdentityEpic,
  resendWelcomeEmailEpic,
  resetPasswordEpic,
  refreshAccessTokenEpic,
  acceptResetPasswordEpic,
} from "domains/authentication/store/auth.epics";
import {
  changePasswordEpic,
  getAccountEpic,
  getClientEpic,
  addClientByForceEpic,
  getAccountUsersEpic,
  addIndividualClientEpic,
  addClientEpic,
  editInstitutionalClientEpic,
  editPrincipalClientEpic,
  editInstitutionInfoEpic,
  getProfileEpic,
  editInstitutionalPrimaryEpic,
  getLiquidityRequestCounts,
  getAlertsEpic,
  getClientsEpic,
} from "domains/clients/store/clients.epics";
import {
  getIdentityEpic,
  getPermissionsEpic,
  initEpic,
  logoutEpic,
  registrationEpic,
} from "domains/identity";
import {
  getLiquidityEpic,
  getLiquiditiesEpic,
  createLiquidityRequestEpic,
  createLiquiditySharingEpic,
  getLiquiditySharingsEpic,
  updateLiquiditySharingEpic,
  resendSharingInvitationEpic,
  getClientLiquiditiesAlertsEpic,
  createSharingAcceptanceEpic,
  getSingleLiquidityAlertsEpic,
  readSingleLiquidityAlertEpic,
  addHoldingsEpic,
} from "domains/liquidity";
import {
  getTransactionDocumentsEpic,
  getUncategorizedDocumentsEpic,
  getDocumentsByHoldingEpic,
  getDocumentUrlEpic,
  confirmUploadEpic,
  setIdontHaveThisEpic,
  getPublicDocumentUrlEpic,
} from "domains/document";
import { getCurrenciesEpic, getVersionEpic } from "common/shared-store";

export const rootEpic = combineEpics(
  forgotPasswordEpic,
  loginEpic,
  initEpic,
  logoutEpic,
  addIndividualClientEpic,
  addClientEpic,
  addClientByForceEpic,
  getAccountEpic,
  getAccountUsersEpic,
  getClientEpic,
  getProfileEpic,
  changePasswordEpic,
  editInstitutionInfoEpic,
  getLiquiditiesEpic,
  getLiquidityEpic,
  getTransactionDocumentsEpic,
  getDocumentsByHoldingEpic,
  createLiquidityRequestEpic,
  createLiquiditySharingEpic,
  getDocumentUrlEpic,
  getPublicDocumentUrlEpic,
  confirmUploadEpic,
  registrationEpic,
  editInstitutionalClientEpic,
  editPrincipalClientEpic,
  getLiquiditySharingsEpic,
  updateLiquiditySharingEpic,
  setIdontHaveThisEpic,
  resendSharingInvitationEpic,
  editInstitutionalPrimaryEpic,
  createSharingAcceptanceEpic,
  getIdentityEpic,
  getPermissionsEpic,
  getLiquidityRequestCounts,
  getAlertsEpic,
  getClientLiquiditiesAlertsEpic,
  getSingleLiquidityAlertsEpic,
  readSingleLiquidityAlertEpic,
  getClientsEpic,
  getCurrenciesEpic,
  acceptIdentityEpic,
  resendWelcomeEmailEpic,
  resetPasswordEpic,
  getVersionEpic,
  refreshAccessTokenEpic,
  acceptResetPasswordEpic,
  addHoldingsEpic,
  getUncategorizedDocumentsEpic
);
