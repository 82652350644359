import React from "react";
import { Button, Tooltip } from "antd";
import { Trans } from "@lingui/react";

import { BrowsersUtils } from "common/shared/utils";
import { IconCustom } from "common/lib/components";

import { PropsClientControls } from "./types";

const ClientControls: React.FC<PropsClientControls> = (props): React.ReactElement | null => {
  const {
    isEdit,
    isDisabled,
    isDisabledCancel,
    btnId,
    handleClickSave,
    handleClickCancel,
    handleClickEdit,
  } = props;

  const editBtnTooltip = <Trans id="tooltip.btn.edit">Edit</Trans>;
  const saveBtnTooltip = <Trans id="tooltip.btn.save">Save</Trans>;
  const closeBtnTooltip = <Trans id="tooltip.btn.close">Close</Trans>;
  const trigger = BrowsersUtils.isMobile() ? "click" : "hover";

  return isEdit ? (
    <div className="ben-edit-btn-controls">
      <Tooltip placement="left" title={saveBtnTooltip} trigger={trigger}>
        <Button
          id={`ben${btnId}BtnUpdate`}
          type="link"
          disabled={isDisabled}
          onClick={handleClickSave}
          className="ben-btn-reset"
        >
          <IconCustom type="save" />
        </Button>
      </Tooltip>
      <span className="ben-divider">|</span>
      <Tooltip placement="left" title={closeBtnTooltip} trigger={trigger}>
        <Button
          id={`ben${btnId}BtnCancel`}
          type="link"
          disabled={isDisabledCancel}
          className="ben-btn-reset"
          onClick={handleClickCancel}
        >
          <IconCustom type="cancel" />
        </Button>
      </Tooltip>
    </div>
  ) : (
    <Tooltip placement="left" title={editBtnTooltip} trigger={trigger}>
      <Button
        id={`ben${btnId}BtnEdit`}
        className="ben-btn-reset"
        type="link"
        onClick={handleClickEdit}
      >
        <IconCustom type="edit" style={{ fontSize: 20 }} />
      </Button>
    </Tooltip>
  );
};

export default ClientControls;
