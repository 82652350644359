import { StringUtils } from "common/shared/utils";
import localeService from "common/shared/services/locale/locale.service";

interface ErrorProps {
  validateStatus: "success" | "error";
  help?: string;
}

const expectedSubErrorCodeMap: { [key: string]: string[] } = {
  NotEqualValidator: ["ProposedPassword"],
};

export function getValidationErrors(
  errors: any,
  fieldName: string,
  errorMsgKeyPrefix?: string
): ErrorProps {
  let help = "";
  fieldName = fieldName.toLowerCase();
  errorMsgKeyPrefix = errorMsgKeyPrefix || "validation";

  if (!errors) {
    return {
      validateStatus: "success",
    };
  }

  if (errors.pattern || errors.required) {
    help = `${errorMsgKeyPrefix}.required`;
  } else if (errors.confirm) {
    help = `${errorMsgKeyPrefix}.confirm`;
  } else if (errors.differValue) {
    help = `${errorMsgKeyPrefix}.differValue`;
  }

  help = StringUtils.capitalize(localeService.i18n._(help, { fieldName }));

  return {
    help,
    validateStatus: help ? "error" : "success",
  };
}

export function translateError(error: {
  error_message: string;
  error_code: string;
  error_code_args?: string[];
}): string {
  const errorArgs = error.error_code_args;
  if (!errorArgs || !errorArgs.length) {
    const errorTextByCode = localeService.i18n._(error.error_code);
    if (errorTextByCode === error.error_code) {
      return "";
    }
    return errorTextByCode;
  }
  const values = errorArgs.reduce((acc: any, item: any, i: any) => {
    // remove spaces for generate correct ids
    const translatedArg = localeService.i18n._(item.replace(/\s+/g, ""));
    return {
      ...acc,
      [i]: translatedArg,
    };
  }, {});
  const subErrorCode = (values && values[1]) || null;

  if (isSubErrorCodeExpected(error.error_code, subErrorCode)) {
    return localeService.i18n._(`${error.error_code}_${subErrorCode}`, values);
  }

  return localeService.i18n._(error.error_code, values);
}

function isSubErrorCodeExpected(errorCode: string, subErrorCode: string): boolean {
  if (!errorCode || !subErrorCode) {
    return false;
  }
  if (
    expectedSubErrorCodeMap[errorCode] &&
    expectedSubErrorCodeMap[errorCode].some((element: string) => subErrorCode === element)
  ) {
    return true;
  }
  return false;
}
