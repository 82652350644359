import { useSelector } from "react-redux";
import { LiquiditiesCreationDataProps } from "../shared/types";

function useLiquiditiesCreationData(): LiquiditiesCreationDataProps {
  const result = useSelector(
    (state: any): LiquiditiesCreationDataProps => {
      const { isCreating } = state.liquidity.creation;
      return { isCreating };
    }
  );

  return { ...result };
}

export default useLiquiditiesCreationData;
