export const AUTH = {
  BASE: "/",
  SIGN_IN: "/signin",
  LOGOUT: "/logout",
  RESEND_PASSWORD_LINK: "/resend",
  RESET_PASSWORD: "/resetpass",
  RESET_PASSWORD_EXPIRED: "/reset-expired",
  SET_PASSWORD: "/setpass",
  // gets from BE. Change with BE
  VERIFY_SHARING: "/verifySharing",
  // gets from BE. Change with BE
  VERIFY_IDENTITY: "/verifyIdentity",
  // gets from BE. Change with BE
  VERIFY_IMPERSONATE: "/verifypostalcode",
  // gets from BE. Change with BE
  VERIFY_RESET_PASSWORD: "/verifyResetPassword",
  RESEND_SHARING_INVITATION_IN: "/resend-sharing-invitation-in",
  RESEND_SHARING_INVITATION_OUT: "/resend-sharing-invitation-out",
  RESEND_WELCOME_EMAIL: "/resend-welcome-email",
  HELP: "/login-help",
  ERROR: "/public-error",
  ERROR_404: "/public-404",
};

export const PUBLIC = {
  TERMS: "/terms",
  MAINTENANCE: "/maintenance",
};

export const PROTECTED = {
  BASE: "/dashboard",
  PROFILE: "/account/",
  DASHBOARD: "/dashboard",
  RESOURCES: "/resources",
  ADD_CLIENT_DOC: "/addclientdoc",
  CLIENTS: "/clients",
  ADD_CLIENT: "/dashboard/add-client/",
  LIQUIDITY_REQUEST: "/dashboard/liquidity-request",
  NEW_LIQUIDITY: "/dashboard/new-liquidity",
  MESSAGES: "/messages",
  HELP: "/portal-help",
  ERROR: "/error",
  ERROR_404: "/404",
};

export default {
  AUTH,
  PUBLIC,
  PROTECTED,
};
