import { ofType } from "redux-observable";
import { from } from "rxjs";
import { mergeMap, map, switchMap, catchError } from "rxjs/operators";
import { t } from "@lingui/macro";

import { messageService, sessionService, localeService, tokenDecodeUtils } from "common/shared";
import { TypeActionEpicParam } from "common/shared/interfaces/redux";

import {
  ActionTypes,
  forgotPasswordSuccess,
  forgotPasswordFail,
  signInSuccess,
  signInFail,
  signInFailUserLocked,
  acceptIdentitySuccess,
  acceptIdentityFail,
  resendWelcomeEmailFail,
  resendWelcomeEmailSuccess,
  resetPasswordSuccess,
  resetPasswordFail,
  refreshAccessTokenFail,
  refreshAccessTokenSuccess,
  acceptResetPasswordSuccess,
  acceptResetPasswordFail,
} from "./auth.actions";
import authService from "../shared/authentication.service";
import {
  ParamsUserLogin,
  AcceptIdentityResponseData,
  ParamsAcceptIdentity,
  ParamsResendWelcomeEmail,
  ResendWelcomeEmailResponseData,
  ParamsRefreshAccessToken,
  ResponseDataRefreshAccessToken,
  ParamsAcceptResetPassword,
  AcceptResetPasswordResponse,
} from "../shared/types";

export const loginEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.SignIn),
    map((action: { type: string; payload: ParamsUserLogin }) => action.payload),
    switchMap((data: ParamsUserLogin) => {
      const username = data.username;

      return from(authService.login(data)).pipe(
        switchMap(({ tokens, clientId }: any) => {
          sessionService.setTokens(tokens, clientId, sessionService.getGeneratedBrowserSessionId());
          sessionService.setUserName(username);
          sessionService.syncBrowserSessionId();
          return [signInSuccess(tokens)];
        }),
        catchError((e) => {
          if (e.response && e.response.status === 403) {
            return [signInFailUserLocked()];
          }
          return [signInFail()];
        })
      );
    })
  );

export const forgotPasswordEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.ForgotPassword),
    map((action: any) => action.payload),
    mergeMap(async (data: any) => {
      try {
        await authService.forgotPassword(data);
        const { resetMode } = data;
        const successMsg = resetMode
          ? "toaster.forgotPassword.success"
          : "toaster.resendPasswordLink.success";
        messageService.success(successMsg);
        return forgotPasswordSuccess();
      } catch (error) {
        messageService.error("toaster.forgotPassword.fail");
        return forgotPasswordFail();
      }
    })
  );

export const resetPasswordEpic = (action$: any) =>
  action$.pipe(
    ofType(ActionTypes.ResetPassword),
    map((action: any) => action.payload),
    mergeMap(async (data: any) => {
      try {
        const { resetToken, resetMode } = data;
        const decodedTokenData = tokenDecodeUtils.decodeResetPassToken(resetToken);
        await authService.resetPassword({
          ...data,
          userEmail: decodedTokenData && decodedTokenData.userEmail,
        });
        const successMsg = resetMode
          ? "toaster.resetPassword.success"
          : "toaster.setPassword.success";
        messageService.success(successMsg);
        return resetPasswordSuccess();
      } catch (error) {
        return resetPasswordFail();
      }
    })
  );

export const acceptIdentityEpic = (action$: TypeActionEpicParam) =>
  action$.pipe(
    ofType(ActionTypes.AcceptIdentity),
    map((action: { type: string; payload?: ParamsAcceptIdentity }) => action.payload),
    mergeMap(async (params?: ParamsAcceptIdentity) => {
      try {
        const response: AcceptIdentityResponseData = await authService.acceptIdentity(params);
        return acceptIdentitySuccess(response);
      } catch (error) {
        return acceptIdentityFail(error);
      }
    })
  );

export const acceptResetPasswordEpic = (action$: TypeActionEpicParam) =>
  action$.pipe(
    ofType(ActionTypes.AcceptResetPassword),
    map((action: { type: string; payload?: ParamsAcceptResetPassword }) => action.payload),
    mergeMap(async (params?: ParamsAcceptResetPassword) => {
      try {
        const response: AcceptResetPasswordResponse = await authService.acceptResetPassword(params);
        return acceptResetPasswordSuccess(response);
      } catch (error) {
        return acceptResetPasswordFail(error);
      }
    })
  );

export const resendWelcomeEmailEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.ResendWelcomeEmail),
    map((action: { type: string; payload?: ParamsResendWelcomeEmail }) => action.payload),
    mergeMap(async (params?: ParamsResendWelcomeEmail) => {
      try {
        const response: ResendWelcomeEmailResponseData = await authService.resendWelcomeEmail(
          params
        );
        if (!response || !response.ok) {
          messageService.error(localeService.i18n._(t`resendWelcomeEmail.request.fail`));
          return resendWelcomeEmailFail();
        }
        return resendWelcomeEmailSuccess();
      } catch (error) {
        if (!error.response) {
          messageService.error(localeService.i18n._(t`resendWelcomeEmail.request.fail`));
        }
        return resendWelcomeEmailFail();
      }
    })
  );
};

export const refreshAccessTokenEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.RefreshAccessToken),
    map((action: { type: string; payload?: ParamsRefreshAccessToken }) => action.payload),
    mergeMap(async (params?: ParamsRefreshAccessToken) => {
      try {
        const response: ResponseDataRefreshAccessToken = await authService.refreshAccessToken(
          params
        );
        if (!response) {
          return refreshAccessTokenFail();
        }
        return refreshAccessTokenSuccess(response);
      } catch (error) {
        return refreshAccessTokenFail();
      }
    })
  );
};
