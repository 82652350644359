import { useSelector } from "react-redux";
import { Bootstrap } from "common/shared-store/reducers/bootstrap";

interface BootstrapRoutesToRedirectData {
  routesToRedirect: { [key: string]: string };
}

function useBootstrapRoutesToRedirectData(): BootstrapRoutesToRedirectData {
  const result = useSelector(
    (state: any): BootstrapRoutesToRedirectData => {
      const bootstrapState: Bootstrap = state.bootstrap;
      const { routesToRedirect } = bootstrapState;

      return {
        routesToRedirect,
      };
    }
  );

  return result;
}

export default useBootstrapRoutesToRedirectData;
