import * as React from "react";
import { Select as AntSelect } from "antd";

import { Options, Select } from "common/lib/components";

interface PropsLocaleSelector {
  selectedLocale: string;
  setLocale: (locale: string) => void;
}

const { Option } = AntSelect;
const locales: { [key: string]: any } = {
  "en-US": "English",
  "es-ES": "Spanish",
};

const localeKeys = Object.keys(locales);
const options = localeKeys.map(
  (key: string): JSX.Element => (
    <Option key={key} value={key}>
      {locales[key]}
    </Option>
  )
);

const LanguageSelector: React.FC<PropsLocaleSelector> = (props): React.ReactElement | null => {
  const { selectedLocale, setLocale } = props;

  const handleChange = (value: string) => {
    setLocale(value);
  };

  return (
    <Select
      showSearch={true}
      optionFilterProp="children"
      filterOption={Options.filterByStartsWith}
      id="lang"
      value={selectedLocale}
      onChange={handleChange}
    >
      {options}
    </Select>
  );
};

export default LanguageSelector;
