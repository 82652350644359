import React from "react";

import { RequestStatusesList } from "domains/liquidity/components";

import { PropsRequestProgressBar } from "./types";

import "./request-progress-bar.scss";

const RequestProgressBar: React.FC<PropsRequestProgressBar> = (
  props
): React.ReactElement | null => {
  const { currentStep, currentStepCode, handleModalVisibility } = props;

  return (
    <div className="ben-request-progress-bar-content">
      <RequestStatusesList
        currentStep={currentStep}
        currentStepCode={currentStepCode}
        handleModalVisibility={handleModalVisibility}
      />
    </div>
  );
};

export default RequestProgressBar;
