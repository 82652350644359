import { Subject } from "rxjs";

export interface PortalEnv {
  defaultPhoneNumber: string;
  defaultTimezone: string;
  defaultCurrency: string;
  defaultLocale: string;
  defaultLanguage: string;
  googleTagManagerId: string;
  envSubscription$: Subject<any>;

  getLanguageFromLocale: (locale: string) => string | null;
  setData: (data: PortalEnvParams) => void;
}

export interface PortalEnvParams {
  DefaultPhoneNumber: string;
  DefaultTimezone: string;
  DefaultCurrency: string;
  DefaultLocale: string;
  GoogleTagManagerId: string;
}

export class PortalEnvConstants {
  public defaultPhoneNumber = "";
  public defaultTimezone = "";
  public defaultCurrency = "";
  public defaultLocale = "";
  public defaultLanguage = "";
  public googleTagManagerId = "";
  public envSubscription$ = new Subject();

  public setData(data: PortalEnvParams) {
    if (!data) {
      return;
    }
    this.setDefaultPhoneNumber(data);
    this.setDefaultTimezone(data);
    this.setDefaultCurrency(data);
    this.setDefaultLocale(data);
    this.googleTagManagerId = data.GoogleTagManagerId || "";

    this.envSubscription$.next(data);
  }

  public getLanguageFromLocale(locale: string): string | null {
    if (!locale) {
      return null;
    }
    const parsedArr = locale.split("-");
    if (!parsedArr[0]) {
      return null;
    }
    return parsedArr[0];
  }

  private setDefaultPhoneNumber(data: PortalEnvParams): void {
    if (data.hasOwnProperty("DefaultPhoneNumber") && data.DefaultPhoneNumber) {
      this.defaultPhoneNumber = data.DefaultPhoneNumber;
    }
  }

  private setDefaultTimezone(data: PortalEnvParams): void {
    if (data.hasOwnProperty("DefaultTimezone") && data.DefaultTimezone) {
      this.defaultTimezone = data.DefaultTimezone;
    }
  }

  private setDefaultCurrency(data: PortalEnvParams): void {
    if (data.hasOwnProperty("DefaultCurrency") && data.DefaultCurrency) {
      this.defaultCurrency = data.DefaultCurrency;
    }
  }

  private setDefaultLocale(data: PortalEnvParams): void {
    if (data.hasOwnProperty("DefaultLocale") && data.DefaultLocale) {
      this.defaultLocale = data.DefaultLocale;
      const lang = this.getLanguageFromLocale(data.DefaultLocale);
      if (!lang) {
        this.defaultLanguage = "en";
        return;
      }
      this.defaultLanguage = lang;
    }
  }
}

const portalEnvConstants: PortalEnv = new PortalEnvConstants();
export default portalEnvConstants;
