import React from "react";
import { RouteComponentProps, navigate } from "@reach/router";

import { ForgotPasswordForm } from "domains/authentication";
import { PublicPageWrapper } from "pages/components";
import { Urls } from "common/lib/constants";
import { useGetAppVersion } from "common/shared-hooks";

function ResendPasswordLinkPage(props: RouteComponentProps) {
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <ForgotPasswordForm
        onCancel={() => {
          navigate(Urls.AUTH.SIGN_IN);
        }}
        resetMode={false}
      />
    </PublicPageWrapper>
  );
}

export default ResendPasswordLinkPage;
