import { useSelector } from "react-redux";
import { LiquidityStore, LiquidityRequestAlertsDataProps } from "../shared/types";

function useClientLiquiditiesAlertsData(): LiquidityRequestAlertsDataProps {
  const result = useSelector(
    (state: any): LiquidityRequestAlertsDataProps => {
      const liquidityState: LiquidityStore = state.liquidity;
      const {
        clientAlerts: { data, loading },
      } = liquidityState;

      if (!data || !data.itemsMapped) {
        return {
          itemsMapped: null,
          loading,
        };
      }

      return {
        itemsMapped: data.itemsMapped,
        loading,
      };
    }
  );

  return result;
}

export default useClientLiquiditiesAlertsData;
