export const getUniqueBtnId = ({
  btnType,
  title,
  assetId = "",
  documentType = "",
  documentId = "",
}: {
  btnType: string;
  title: string | React.ReactElement;
  assetId: string | null;
  documentType?: string;
  documentId?: string;
}) => {
  if (documentId) {
    return `ben${btnType}Btn${documentId}`;
  }
  if (assetId && documentType) {
    return `ben${btnType}Btn${documentType}${assetId}`;
  }

  return `ben${btnType}Btn${title}`;
};

export const getUniqueBtnIds = ({
  title,
  assetId = "",
  documentType = "",
  documentId = "",
}: {
  title: string | React.ReactElement;
  assetId: string | null;
  documentType?: string;
  documentId?: string;
}): { dontHaveId: string; downloadId: string; uploadId: string } => {
  return {
    dontHaveId: getUniqueBtnId({
      btnType: "DontHave",
      title,
      assetId,
      documentType,
      documentId,
    }),
    downloadId: getUniqueBtnId({
      btnType: "Download",
      title,
      assetId,
      documentType,
      documentId,
    }),
    uploadId: getUniqueBtnId({
      btnType: "Upload",
      title,
      assetId,
      documentType,
      documentId,
    }),
  };
};
