import { ActionTypes } from "./clients.actions";
import UserLight from "./user.light.model";
import User from "./user.model";
import Institution from "./institution.model";
import {
  ClientToAlertMap,
  ClientToLiquidityRequestCountsMap,
  TypeGetClientsParams,
} from "domains/clients/shared/types";
import { GettingClientsParamsDefault } from "domains/clients/shared/constants";

export interface ClientState {
  accountUsers: UserLight[];
  accountUsersLoading: boolean;
  list: {
    ids: string[];
    entitiesMap: any;
    liquidityRequestCounts: ClientToLiquidityRequestCountsMap;
    alerts: ClientToAlertMap;
    requestParams: TypeGetClientsParams;
  };
  isCreating: boolean;
  loading: boolean;
  clientsLoading: boolean;
  loadingUpdatePass: boolean;
  accountLoading: boolean;
  client: null;
  account: Institution | null;
  getProfileAttempts: number;
  user: User | null;
  isCompletedUserRequest: boolean;
  rawUser: any;
  currentUserIp: string | null;
}

const initialState: ClientState = {
  accountUsers: [],
  accountUsersLoading: false,
  list: {
    ids: [],
    entitiesMap: {},
    liquidityRequestCounts: {},
    alerts: {},
    requestParams: {
      ...GettingClientsParamsDefault,
    },
  },
  loading: false,
  clientsLoading: false,
  isCreating: false,
  client: null,
  loadingUpdatePass: false,
  account: null,
  accountLoading: false,

  getProfileAttempts: 0,
  user: null,
  isCompletedUserRequest: false,
  rawUser: null,
  currentUserIp: null,
};

const clients = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.GetClients:
      return {
        ...state,
        clientsLoading: true,
      };
    case ActionTypes.GetClientsSuccess:
      return {
        ...state,
        clientsLoading: false,
        list: {
          ...state.list,
          ...action.payload,
        },
      };
    case ActionTypes.GetClientsFail:
      return {
        ...state,
        clientsLoading: false,
      };
    case ActionTypes.ChangeClientsRequestParams:
      return {
        ...state,
        list: {
          ...state.list,
          requestParams: {
            ...state.list.requestParams,
            ...action.payload,
          },
        },
      };
    case ActionTypes.GetClient:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GetClientSuccess: {
      if (state.user && state.user.userId === action.payload.userId) {
        return {
          ...state,
          loading: false,
          user: action.payload.user,
          rawUser: action.payload.rawUser,
        };
      }

      return {
        ...state,
        loading: false,
        client: action.payload,
      };
    }
    case ActionTypes.AddClient:
    case ActionTypes.AddIndividualClient:
      return {
        ...state,
        isCreating: true,
      };
    case ActionTypes.AddClientSuccess:
    case ActionTypes.AddClientFail:
    case ActionTypes.AddIndividualClientSuccess:
    case ActionTypes.AddIndividualClientFail:
      return {
        ...state,
        isCreating: false,
      };
    case ActionTypes.GetClientFail:
      return {
        ...state,
        loading: false,
      };

    case ActionTypes.ResetClient:
      return {
        ...state,
        client: null,
        loading: false,
      };

    case ActionTypes.GetAccount:
      return {
        ...state,
        accountLoading: true,
      };
    case ActionTypes.GetAccountSuccess:
      return {
        ...state,
        accountLoading: false,
        account: action.payload,
      };
    case ActionTypes.GetAccountFail:
      return {
        ...state,
        accountLoading: false,
      };

    case ActionTypes.ChangePassword:
      return {
        ...state,
        loadingUpdatePass: true,
      };
    case ActionTypes.ChangePasswordSuccess:
    case ActionTypes.ChangePasswordFail:
      return {
        ...state,
        loadingUpdatePass: false,
      };

    case ActionTypes.GetAccountUsers:
      return {
        ...state,
        accountUsersLoading: true,
      };
    case ActionTypes.GetAccountUsersSuccess:
      return {
        ...state,
        accountUsers: action.payload,
        accountUsersLoading: false,
      };

    case ActionTypes.GetProfile:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.GetProfileSuccess:
      return {
        ...state,
        loading: false,
        isCompletedUserRequest: true,
        getProfileAttempts: 0,
        user: action.payload.user,
        rawUser: action.payload.rawUser,
        currentUserIp: action.payload.currentUserIp,
      };
    case ActionTypes.GetProfileFail:
      return {
        ...state,
        isCompletedUserRequest: true,
        getProfileAttempts: state.getProfileAttempts + 1,
        currentUserIp: null,
        loading: false,
      };

    case ActionTypes.AddClientToList:
      return {
        ...state,
        list: {
          ...state.list,
          ids: [action.payload.id, ...state.list.ids],
          entitiesMap: {
            ...state.list.entitiesMap,
            [action.payload.id]: {
              accountId: action.payload.id,
              accountDisplayId: action.payload.accountDisplayId,
              accountName: action.payload.accountName,
              countLR: 0,
            },
          },
        },
      };

    case ActionTypes.GetLRCount:
    case ActionTypes.GetAlerts:
    case ActionTypes.GetAlertsFail:
    case ActionTypes.GetLRCountFail:
      return {
        ...state,
        list: {
          ...state.list,
        },
      };
    case ActionTypes.GetLRCountSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          liquidityRequestCounts: action.payload.items,
        },
      };

    case ActionTypes.GetAlertsSuccess:
      return {
        ...state,
        list: {
          ...state.list,
          alerts: action.payload.items,
        },
      };

    default:
      return state;
  }
};

export { initialState, clients };
