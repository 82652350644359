import { useSelector } from "react-redux";

interface LoadingUpdatePassDataProps {
  loading: boolean;
}

function useLoadingUpdatePassData(): LoadingUpdatePassDataProps {
  const response: LoadingUpdatePassDataProps = useSelector((storeState: any) => {
    return {
      loading: storeState.clients.loadingUpdatePass,
    };
  });

  return response;
}

export default useLoadingUpdatePassData;
