import { RcFile } from "antd/lib/upload/interface";

import { FileUploaderService, fileService, apiService } from "common/shared";
import { MAX_FILE_SIZE } from "common/shared/services/file/fileUploader.constants";

import {
  CreateDocumentResponseData,
  ParamsCreateDocument,
  TypeUploadDirectoryDocument,
  TypeUploadingFileData,
} from "domains/document/shared/types";

import { Config } from "./constants";
import {
  TypeCancelTokenSourcesMap,
  TypeDirectoryDocumentsConfig,
  TypeFilteredFiles,
} from "./types";

const isValidMaxFoldersLevel = (item: RcFile): boolean => {
  const levels = item.webkitRelativePath.split("/");

  // in the levels there is a file name level
  if (levels.length - 1 > Config.maxNestedFoldersAmount) {
    return false;
  }
  return true;
};

const getFilteredFiles = (items: RcFile[]): TypeFilteredFiles => {
  let isValidLevelsAmount = true;
  let isValidFilesAmount = true;

  const filteredFiles = items.filter((item: RcFile) => {
    if (FileUploaderService.isSystemFile(item)) {
      return false;
    }

    if (isValidLevelsAmount && !isValidMaxFoldersLevel(item)) {
      isValidLevelsAmount = false;
    }
    return true;
  });

  if (filteredFiles.length > Config.maxUploadingFilesAmount) {
    isValidFilesAmount = false;
  }

  const result = {
    filteredFiles,
    isValidLevelsAmount,
    isValidFilesAmount,
  };

  return result;
};

const getFilteredFilesByFileId = (items: RcFile[], fileId: string): RcFile[] => {
  return items.filter((item: RcFile) => {
    if (item.uid === fileId) {
      return false;
    }
    return true;
  });
};

const getFileNameByFileId = (items: TypeUploadDirectoryDocument[], fileId: string): string => {
  return items.filter((item: TypeUploadDirectoryDocument) => {
    if (item.fileId === fileId) {
      return true;
    }
    return false;
  })[0].fileName;
};

const getDisplayedFiles = (
  items: RcFile[]
): { itemsToDisplay: TypeUploadDirectoryDocument[]; isValidationErrors: boolean } => {
  const validationConfig = {
    maxSize: MAX_FILE_SIZE,
    formats: fileService.defaultDocFormats,
    validateName: true,
  };
  let isValidationErrors = false;

  const itemsToDisplay = items.map((item: RcFile) => {
    const fileErrors = fileService.getValidationFileErrors(item, validationConfig);

    if (fileErrors && !isValidationErrors) {
      isValidationErrors = true;
    }

    const itemToDisplay: TypeUploadDirectoryDocument = {
      fileName: item.name,
      fileId: item.uid,
      fileErrors,
    };
    return itemToDisplay;
  });

  return { itemsToDisplay, isValidationErrors };
};

const getUploadingFileProgress = (percent: number): number => {
  const progress = Math.ceil(percent) || Config.progress.create;
  return progress;
};

const getCreationDocumentConfig = (
  file: RcFile,
  accountId: string,
  documentsConfig: TypeDirectoryDocumentsConfig
): ParamsCreateDocument => {
  const name = fileService.getRawFileName(file.name) || "***";
  return {
    documentName: name,
    fileName: file.name,
    fileSize: file.size,
    investmentId: documentsConfig.investmentId || "",
    assetId: documentsConfig.assetId || "",
    liquidityRequestId: documentsConfig.liquidityRequestId || "",
    accountId,
    newVersionOfUniqueDocumentConfirmed: true,
    referenceType: documentsConfig.referenceType || "",
    documentType: "unknown",
    filePath: file.webkitRelativePath,
  };
};

const getUploadUrlFromCreatedDocument = (
  createdDocumentResult: CreateDocumentResponseData
): string | null => {
  return createdDocumentResult && createdDocumentResult.uploadUrl
    ? createdDocumentResult.uploadUrl
    : null;
};

const getCancelTokenSourcesMap = (fileList: RcFile[]): TypeCancelTokenSourcesMap => {
  const sources: TypeCancelTokenSourcesMap = {};
  for (const file of fileList) {
    sources[file.uid] = apiService.getCancelToken();
  }

  return sources;
};

const callActionOnFilesCreatedData = (
  uploadedFiles: TypeUploadingFileData,
  actionFunc: (createdDocument: CreateDocumentResponseData, fileId: string) => void
): number => {
  let filesNumber = 0;

  for (const uploadedFileId in uploadedFiles) {
    if (!uploadedFiles.hasOwnProperty(uploadedFileId)) {
      continue;
    }
    const createdData = uploadedFiles[uploadedFileId].createdData;
    const isCancelled = uploadedFiles[uploadedFileId].isCancelled;

    if (!createdData || isCancelled) {
      continue;
    }

    filesNumber++;
    actionFunc(createdData, uploadedFileId);
  }

  return filesNumber;
};

export const Helpers = {
  getFilteredFiles,
  getFilteredFilesByFileId,
  getFileNameByFileId,
  getDisplayedFiles,
  getUploadingFileProgress,
  getCreationDocumentConfig,
  getUploadUrlFromCreatedDocument,
  getCancelTokenSourcesMap,
  callActionOnFilesCreatedData,
};
