import React, { useCallback, useState, Fragment, useEffect } from "react";
import { Modal, Button, Row, Col } from "antd";

import { verifyInvestorService } from "common/shared";
import { Spinner } from "common/lib/components";
import { Layout } from "common/lib/constants";
import {
  AccreditationPreviewTitle,
  AccreditationPreviewContent,
  AccreditationStartBtnTitle,
  AccreditationModalTitle,
  AccreditationCancelBtnTitle,
  AccreditationAgreeBtnTitle,
  AccreditationModalContent1,
  AccreditationModalContent2,
  AccreditationModalContent3,
  AccreditationModalContent4,
  VerifyInvestorModalCancelBtnTitle,
  VerifyInvestorModalTitle,
  VerifyInvestorModalLoadErrorText,
  AccreditationPreviewMobile,
} from "./constants";

import "./verify-investor-modal.scss";

const VerifyInvestorModal = (): React.ReactElement | null => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isVerifyInvestorInitiated, setVerifyInvestorInitiated] = useState<boolean>(false);
  const [isVerifyInvestorOpened, setVerifyInvestorOpened] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isShowAccreditationPreview, setShowAccreditationPreview] = useState<boolean>(false);
  const [isError, setError] = useState<boolean>(false);

  useEffect(() => {
    setShowAccreditationPreview(verifyInvestorService.isReady());
  }, []);

  useEffect(() => {
    if (isError) {
      setVerifyInvestorInitiated(false);
    }
  }, [isError]);

  useEffect(() => {
    return function cleanup() {
      verifyInvestorService.reset();
    };
  }, []);

  const verifyInvestorCallBackSuccess = useCallback(() => {
    setLoading(false);
    setError(false);
  }, []);

  const verifyInvestorCallBackError = useCallback(() => {
    setLoading(false);
    setError(true);
  }, []);

  const handleVerifyInvestorInitiating = useCallback(() => {
    setVerifyInvestorOpened(true);
    if (!isVerifyInvestorInitiated) {
      setLoading(true);
      verifyInvestorService.init({
        callBackSuccess: verifyInvestorCallBackSuccess,
        callBackError: verifyInvestorCallBackError,
      });
      setVerifyInvestorInitiated(true);
    } else {
      verifyInvestorService.continueListen();
    }
  }, [
    setVerifyInvestorInitiated,
    isVerifyInvestorInitiated,
    verifyInvestorCallBackSuccess,
    verifyInvestorCallBackError,
  ]);

  const handleCloseVerifyInvestor = useCallback(() => {
    setModalOpen(false);
    setVerifyInvestorOpened(false);
    setError(false);
    verifyInvestorService.stopListen();
  }, []);

  const handleModalVisibility = useCallback(() => {
    setModalOpen((value: boolean) => !value);
  }, []);

  if (!isShowAccreditationPreview) {
    return null;
  }

  return (
    <div className="ben-pt-2">
      <div className="ben-header">
        <h3>{AccreditationPreviewTitle}</h3>
      </div>
      <p>{AccreditationPreviewContent}</p>
      {/* TODO: button is hidden by class - "ben-d-sm-none" (for mobile state) */}
      <Button
        type="primary"
        shape="round"
        size="default"
        id="verifyInvestorModalOpen"
        onClick={handleModalVisibility}
        className="ben-d-sm-none"
      >
        {AccreditationStartBtnTitle}
      </Button>

      <p className="accreditation-preview-mobile">{AccreditationPreviewMobile}</p>

      <Modal
        visible={isModalOpen}
        title={isVerifyInvestorOpened ? VerifyInvestorModalTitle : AccreditationModalTitle}
        centered={true}
        width={"100%"}
        style={{
          maxWidth: Layout.MaxWidthModalWindow.large,
        }}
        maskClosable={false}
        wrapClassName="ben-modal-container verify-investor-modal-container"
        footer={null}
        onCancel={isVerifyInvestorOpened ? handleCloseVerifyInvestor : handleModalVisibility}
      >
        <Spinner spinning={isLoading}>
          <div className="ben-modal-content verify-investor-modal-body">
            {!isLoading && isError ? (
              <div className="accreditation-modal-content">
                <h3
                  id="verifyInvestorModalLoadErrorText"
                  className="ben-highlighted-text ben-text-center"
                >
                  {VerifyInvestorModalLoadErrorText}
                </h3>
              </div>
            ) : null}

            {isVerifyInvestorInitiated && !isError ? (
              <iframe
                title={verifyInvestorService.getIFrameId()}
                className="verify-investor-iframe"
                style={{
                  border: "0px #ffffff none",
                  margin: "0px",
                  display: isVerifyInvestorOpened ? "block" : "none",
                }}
                id={verifyInvestorService.getIFrameId()}
                name={verifyInvestorService.getIFrameId()}
                scrolling="true"
                width="100%"
              />
            ) : null}

            {!isVerifyInvestorOpened && !isError ? (
              <div id="verifyInvestorModalPreviewContent" className="accreditation-modal-content">
                <p>{AccreditationModalContent1}</p>
                <p>{AccreditationModalContent2}</p>
                <p>{AccreditationModalContent3}</p>
                <p>{AccreditationModalContent4}</p>
              </div>
            ) : null}
          </div>
        </Spinner>

        <Row gutter={15} type="flex" justify="end">
          {isVerifyInvestorOpened ? (
            <Col>
              <Button
                type="primary"
                shape="round"
                ghost={true}
                size="default"
                id={"verifyInvestorModalAPIClose"}
                onClick={handleCloseVerifyInvestor}
              >
                {VerifyInvestorModalCancelBtnTitle}
              </Button>
            </Col>
          ) : (
            <Fragment>
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  ghost={true}
                  size="default"
                  onClick={handleModalVisibility}
                  id="verifyInvestorModalPreviewCancel"
                >
                  {AccreditationCancelBtnTitle}
                </Button>
              </Col>
              <Col>
                <Button
                  type="primary"
                  shape="round"
                  size="default"
                  onClick={handleVerifyInvestorInitiating}
                  id="verifyInvestorModalPreviewOk"
                >
                  {AccreditationAgreeBtnTitle}
                </Button>
              </Col>
            </Fragment>
          )}
        </Row>
      </Modal>
    </div>
  );
};

export default VerifyInvestorModal;
