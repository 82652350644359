import React, { Fragment, useCallback, useEffect } from "react";
import { Form } from "antd";
import InputMask from "react-input-mask";
import { SelectValue } from "antd/lib/select";

import { Constants } from "common/lib/constants";
import { Options, Select } from "common/lib/components";
import { PhoneUtils } from "common/shared/utils";
import { getValidationErrors, localeService } from "common/shared";
import { useOnLocaleChange } from "common/shared-hooks";
import FormConfig from "common/shared/interfaces/form-config";

interface PropsPhoneForm {
  config: FormConfig;
  placeholder: string;
}

function PhoneForm({ config, placeholder }: PropsPhoneForm) {
  const { state, handleOnChange, handleOnBlur, resetForm } = config;
  const isCountryCodeExist = Boolean(state.countryCode.value);
  const isPhoneTypeExist = Boolean(state.phoneType.value);
  const phoneTypes = useOnLocaleChange(Options.phoneTypes);
  const countriesCodes = useOnLocaleChange(Options.countriesCodes);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    resetForm();
  }, []);

  const handlePhoneNumberBlur = useCallback(
    (event: any) => {
      const phoneNumericValue = PhoneUtils.getNumericValue(event.target.value);
      handleOnBlur({ target: { name: "phoneNumber", value: phoneNumericValue } });
    },
    [handleOnBlur, isCountryCodeExist, handleOnChange]
  );

  const handlePhoneNumberChange = useCallback(
    (event: any) => {
      const phoneNumericValue = PhoneUtils.getNumericValue(event.target.value);
      if (state.phoneNumber.value !== phoneNumericValue) {
        handleOnChange({ target: { name: "phoneNumber", value: phoneNumericValue } });
      }
    },
    [handleOnChange]
  );

  // todo extend useForm hook to work with select
  const handleSelectChange = (name: string) => (value: SelectValue) => {
    handleOnChange({ target: { name, value } });
  };

  const setDefaultCountryCode = () => {
    handleOnChange({ target: { name: "countryCode", value: Constants.DEFAULT_COUNTRY_CODE.code } });
    return localeService.i18n._(Constants.DEFAULT_COUNTRY_CODE.name);
  };

  const phoneTypeValueProps = isPhoneTypeExist ? { value: state.phoneType.value } : {};
  // props "key" on Select is used for refreshing the selected value

  return (
    <Fragment>
      <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._("form.phone.countryCode")}
        >
          <Select
            showSearch={true}
            id="countryCode"
            key={isCountryCodeExist ? 1 : 2}
            style={{ width: "100%" }}
            defaultValue={state.countryCode.value || setDefaultCountryCode()}
            onChange={handleSelectChange("countryCode")}
            disabled={state.countryCode.isDisabled}
            optionFilterProp="children"
            filterOption={(input: any, option: any) => Options.filterByIncludes(input, option)}
          >
            {countriesCodes}
          </Select>
        </Form.Item>
      </div>
      <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._("form.phone.phoneNumber")}
          {...getValidationErrors(
            state.phoneNumber.errors,
            localeService.i18n._("form.phone.errorLabel.phoneNumber")
          )}
        >
          <InputMask
            className="ant-input"
            mask={PhoneUtils.MASK}
            name="phoneNumber"
            value={state.phoneNumber.value}
            onChange={handlePhoneNumberChange}
            onBlur={handlePhoneNumberBlur}
            disabled={state.phoneNumber.isDisabled}
            placeholder={placeholder}
            maskChar={null}
          />
        </Form.Item>
      </div>
      <div>
        <Form.Item
          className="ben-form-label-field"
          label={localeService.i18n._("form.phone.phoneType")}
          {...getValidationErrors(
            state.phoneType.errors,
            localeService.i18n._("form.phone.phoneType")
          )}
        >
          <Select
            showSearch={true}
            id="phoneType"
            key={isPhoneTypeExist ? 1 : 2}
            style={{ width: "100%" }}
            placeholder={placeholder}
            onChange={handleSelectChange("phoneType")}
            disabled={state.phoneType.isDisabled}
            optionFilterProp="children"
            filterOption={(input: any, option: any) => Options.filterByStartsWith(input, option)}
            {...phoneTypeValueProps}
          >
            {phoneTypes}
          </Select>
        </Form.Item>
      </div>
    </Fragment>
  );
}

export default PhoneForm as React.ComponentType<any>;
