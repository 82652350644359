import React from "react";

import { Trans } from "@lingui/macro";

import { TypeAnchorLink } from "common/lib/components/anchored-menu/types";

export const TitleLiquidityRequestHoldings = (
  <Trans id="liquidityRequestDetails.holdings.title">Holdings</Trans>
);

export const AnchoredIdsInitial = {
  overview: "overview",
  holdings: "holdings",
  transaction: "transaction",
  invites: "invites",
};

export const MenuItemsInitial: TypeAnchorLink[] = [
  {
    id: AnchoredIdsInitial.overview,
    title: <Trans id="liquidityRequest.menu.overview.title">Overview</Trans>,
  },
  {
    id: AnchoredIdsInitial.holdings,
    title: <Trans id="liquidityRequest.menu.holdings.title">Holdings</Trans>,
  },
  {
    id: AnchoredIdsInitial.transaction,
    title: <Trans id="liquidityRequest.menu.transaction.title">Transaction</Trans>,
  },
  {
    id: AnchoredIdsInitial.invites,
    title: <Trans id="liquidityRequest.menu.sharing.title">Invites</Trans>,
  },
];
