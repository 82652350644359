import { ArrayUtils } from "common/shared/utils";
import {
  DocumentListResponseData,
  DocumentItemData,
  TypeReferenceType,
  TypeDocumentType,
  DocumentUrlResponseData,
  CreateDocumentResponseData,
  TypeDocumentSource,
} from "./types";

interface DocumentItemRaw {
  id: string;
  investment_id: string;
  asset_id: string;
  liquidity_request_id: string;
  date_provided: string;
  reference_type: TypeReferenceType;
  document_type: TypeDocumentType;
  document_name: string;
  file_name: string;
  period_ending_date: string;
  do_not_have_this_document: boolean;
  source: TypeDocumentSource;
  display_id: string;
}

export function parseDocumentsListResponseData(data: any): DocumentListResponseData {
  const result: DocumentListResponseData = {
    total: 0,
    page: {
      index: 0,
      size: 0,
    },
    items: [],
  };

  if (!data || typeof data !== "object") {
    return result;
  }

  const isItemsExist = ArrayUtils.isValidArrayData(data.items);
  const parsedItems: DocumentItemData[] = isItemsExist
    ? data.items.map((item: DocumentItemRaw) => {
        return {
          id: item.id,
          investmentId: item.investment_id,
          assetId: item.asset_id,
          liquidityRequestId: item.liquidity_request_id,
          dateProvided: item.date_provided,
          referenceType: item.reference_type,
          documentType: item.document_type,
          documentName: item.document_name,
          fileName: item.file_name,
          periodEndingDate: item.period_ending_date,
          doNotHaveThis: item.do_not_have_this_document,
          source: item.source,
          displayId: item.display_id,
        };
      })
    : [];

  result.total = data.total || 0;
  result.page.index = (data.page && data.page.index) || 0;
  result.page.size = (data.page && data.page.size) || 0;
  result.items = parsedItems;

  return result;
}

export function parseDocumentUrlResponseData(data: any): DocumentUrlResponseData {
  const result: DocumentUrlResponseData = {
    documentId: "",
    version: 0,
    fileName: "",
    url: "",
  };

  if (!data || typeof data !== "object") {
    return result;
  }

  result.documentId = data.document_id || "";
  result.version = data.version || 0;
  result.fileName = data.file_name || "";
  result.url = data.url || "";

  return result;
}

export function parseCreateDocumentResponseData(data: any): CreateDocumentResponseData | null {
  if (!data || typeof data !== "object") {
    return null;
  }

  return {
    documentId: data.document_id || "",
    versionId: data.version_id || "",
    uploadUrl: data.upload_url || "",
  };
}

export function prepareAssetIds(ids: string[]): string {
  return ids.map((id) => id.replace("AST-", "")).join(",");
}
