import React, { useState, Fragment, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Alert, Table, Collapse, Button } from "antd";
import { Trans } from "@lingui/macro";

import { ArrayUtils } from "common/shared/utils";
import { Spinner } from "common/lib/components";

import downIcon from "common/assets/images/icons/collapse-arrow-down.svg";
import upIcon from "common/assets/images/icons/collapse-arrow-up.svg";

import { HoldingsDocumentsDataProps } from "domains/document/shared/types";
import { useHoldingDocumentsData } from "domains/document/hooks";
import { getDocumentsByHolding } from "domains/document/store/document.actions";

import { HoldingItemData } from "domains/liquidity/shared/types";

import { PropsLiquidityHoldings } from "./types";
import { getLiquidityHoldingsHeaders, getLiquidityHoldingsRows } from "./helpers";
import "./liquidity-holdings.scss";

const { Panel } = Collapse;
const currentCollapseKey = "1";
const activeRowsAmount = 4;

const LiquidityHoldings: React.FC<PropsLiquidityHoldings> = (props): React.ReactElement | null => {
  const dispatch = useDispatch();

  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [activeAssetId, setActiveAssetId] = useState<string | null>(null);

  const {
    liquidity,
    currentUserId,
    userDisplayId,
    currentUserFullName,
    currentUserIp,
    locale,
  } = props;
  const accountId = liquidity ? liquidity.accountId : "";
  let holdings: HoldingItemData[] | null = [];
  if (liquidity) {
    holdings = liquidity.holdings;
  }

  const { holdings: holdingsWithDocument }: HoldingsDocumentsDataProps = useHoldingDocumentsData();

  const getDocuments = useCallback(() => {
    if (!accountId || !holdings) {
      return;
    }

    const assetIds = holdings.map((holding: HoldingItemData) => holding.assetId);

    if (!assetIds.length) {
      return;
    }
    dispatch(getDocumentsByHolding({ accountId, assetIds }));
  }, [dispatch, accountId, holdings]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    getDocuments();
  }, []);

  const toggleCollapse = useCallback(
    () => setActiveKey(activeKey.length === 0 ? [currentCollapseKey] : []),
    [activeKey]
  );

  const toggleActiveAssetCollapse = useCallback((assetId: string) => {
    setActiveAssetId(assetId);
  }, []);

  const isHoldingsExist = Boolean(ArrayUtils.isValidArrayData(holdings));
  const holdingsNumber = isHoldingsExist && holdings ? holdings.length : 0;
  const headers = getLiquidityHoldingsHeaders(holdingsNumber);
  const { activeRows, collapsedRows } = getLiquidityHoldingsRows({
    holdings: holdingsWithDocument,
    toggleActiveAssetCollapse,
    activeAssetId,
    activeAmount: activeRowsAmount,
    currentUserId,
    userDisplayId,
    currentUserFullName,
    currentUserIp,
    locale,
  });
  const isCollapseMode = Boolean(collapsedRows && collapsedRows.length);

  const ActiveTable = activeRows ? (
    <Table columns={headers} dataSource={activeRows} pagination={false} />
  ) : (
    <Spinner spinning={true} />
  );

  return (
    <div className="ben-liquidity-holdings">
      {!isHoldingsExist && (
        <Alert
          message={<Trans id="liquidityHoldings.emptyList.title">No holdings here</Trans>}
          type="warning"
          showIcon={true}
        />
      )}

      {isHoldingsExist &&
        (isCollapseMode ? (
          <Fragment>
            <Collapse activeKey={activeKey}>
              <Panel header={ActiveTable} showArrow={false} key={currentCollapseKey}>
                {collapsedRows && (
                  <Table
                    columns={headers}
                    showHeader={false}
                    dataSource={collapsedRows}
                    pagination={false}
                  />
                )}
              </Panel>
            </Collapse>
            <Button
              id="LiquidityHoldingsIdBtnCollapse"
              size="small"
              className="collapse"
              type="primary"
              shape="circle"
              onClick={toggleCollapse}
            >
              <img src={activeKey.length === 0 ? downIcon : upIcon} alt="Logo" />
            </Button>
          </Fragment>
        ) : (
          ActiveTable
        ))}
    </div>
  );
};

export default LiquidityHoldings;
