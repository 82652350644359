import {
  CreateDocumentResponseData,
  TypeFilesListUploadingResult,
  TypeUploadDirectoryDocument,
  TypeUploadingFileData,
  TypeUploadingFileItemData,
} from "domains/document/shared/types";

/**
 * It includes if file has 100 percent of progress
 *  or if it has an error
 *  or if documents is empty after start of uploading (cancellation)
 */
export const isFilesListUploadingFinished = (
  documents: TypeUploadDirectoryDocument[],
  uploadingFilesUpdated: TypeUploadingFileData,
  maxUploadingProgress: number
): { isFinished: boolean; result: TypeFilesListUploadingResult } => {
  const allDocumentsNumber = documents.length;
  const uploadingDocuments = Object.values(uploadingFilesUpdated);
  const uploadingDocumentsNumber = uploadingDocuments.length;

  if (allDocumentsNumber === 0) {
    return { isFinished: true, result: "allCancelled" };
  }

  if (allDocumentsNumber !== uploadingDocumentsNumber) {
    return { isFinished: false, result: "inProcess" };
  }

  const successful = uploadingDocuments.filter((item: TypeUploadingFileItemData) => {
    if (item.progress === maxUploadingProgress) {
      return true;
    }
    return false;
  });

  if (successful.length === allDocumentsNumber) {
    return { isFinished: true, result: "allSuccess" };
  }

  const failed = uploadingDocuments.filter((item: TypeUploadingFileItemData) => {
    if (item.errors) {
      return true;
    }
    return false;
  });

  if (failed.length === allDocumentsNumber) {
    return { isFinished: true, result: "allErrors" };
  }

  const partially = uploadingDocuments.filter((item: TypeUploadingFileItemData) => {
    if (item.errors || item.progress === maxUploadingProgress) {
      return true;
    }
    return false;
  });

  if (partially.length === allDocumentsNumber) {
    return { isFinished: true, result: "hasSomeErrors" };
  }

  return { isFinished: false, result: "inProcess" };
};

export const getUpdatedUploadingFiles = (
  uploadingFiles: TypeUploadingFileData,
  uploadingFile: TypeUploadingFileData
): TypeUploadingFileData => {
  const fileId = Object.keys(uploadingFile)[0];
  let uploadingFilesUpdated: TypeUploadingFileData = {};

  const createdDocument: CreateDocumentResponseData | null = uploadingFiles[fileId]
    ? uploadingFiles[fileId].createdData
    : null;

  if (uploadingFiles[fileId] && createdDocument) {
    uploadingFilesUpdated = {
      ...uploadingFiles,
      [fileId]: { ...uploadingFile[fileId], createdData: { ...createdDocument } },
    };
  } else {
    uploadingFilesUpdated = { ...uploadingFiles, ...uploadingFile };
  }

  if (uploadingFile[fileId] && uploadingFile[fileId].isCancelled) {
    delete uploadingFilesUpdated[fileId];
  }

  return uploadingFilesUpdated;
};
