import { liquidityService } from "domains/liquidity/shared";

import {
  DocumentTypesDisplayMap,
  DocumentStatusesDisplay,
  DocumentInfo,
  DefaultDocumentInfo,
  DocumentInfoByMappedStatus,
} from "./constants";
import {
  TypeDocumentType,
  DocumentTypeDisplaySetting,
  TypeDocumentStatus,
  DocumentStatusDisplaySetting,
  DisplaySettingsParams,
  NormalizedDocuments,
  DocumentItemData,
} from "./types";

export class DocumentService {
  getDisplaySettingsByType = (
    documentType: TypeDocumentType
  ): {
    typeSettings: DocumentTypeDisplaySetting;
  } => {
    const result = {
      typeSettings: DocumentTypesDisplayMap.fallback,
    };
    if (!documentType || !DocumentTypesDisplayMap[documentType]) {
      return result;
    }
    result.typeSettings = DocumentTypesDisplayMap[documentType];
    return result;
  };

  getDisplaySettings = ({
    documentType,
    liquidityStatus,
  }: DisplaySettingsParams): DocumentStatusDisplaySetting => {
    const convertedLiquidityStatus = liquidityService.getMappedStatus(liquidityStatus);

    if (!DocumentInfo[documentType] && !DocumentInfoByMappedStatus[documentType]) {
      return DefaultDocumentInfo;
    }

    // info by FE converted LiquidityStatus
    const documentInfoByMappedStatus = DocumentInfoByMappedStatus[documentType];

    if (documentInfoByMappedStatus && convertedLiquidityStatus) {
      return (
        documentInfoByMappedStatus[convertedLiquidityStatus] ||
        documentInfoByMappedStatus.fallback ||
        DefaultDocumentInfo
      );
    }

    const documentInfoByType = DocumentInfo[documentType] || {};
    return (
      documentInfoByType[liquidityStatus] || documentInfoByType.fallback || DefaultDocumentInfo
    );
  };

  getDisplaySettingsByStatus = (
    documentStatus: TypeDocumentStatus
  ): {
    statusSettings: DocumentStatusDisplaySetting;
  } => {
    const result = {
      statusSettings: DocumentStatusesDisplay.fallback,
    };
    if (!documentStatus || !DocumentStatusesDisplay[documentStatus]) {
      return result;
    }
    result.statusSettings = DocumentStatusesDisplay[documentStatus];
    return result;
  };

  getNormalizedDocuments = (
    documents: DocumentItemData[],
    standardDocumentsName: TypeDocumentType[]
  ): NormalizedDocuments => {
    const standardDocuments = standardDocumentsName.reduce((acc: any, docType: string) => {
      const standardDocument = documents.filter(
        (doc: DocumentItemData) => doc.documentType === docType
      );

      return {
        ...acc,
        [docType]: standardDocument,
      };
    }, {});

    const otherClientDocuments = documents.filter((doc: DocumentItemData) => {
      if (doc.documentType === "other" && doc.source === "client_portal") {
        return true;
      }

      return false;
    });

    const otherAppianDocuments = documents.filter(
      (doc: DocumentItemData) =>
        doc.documentType === "other" && (doc.source === "appian" || doc.source === "system")
    );

    return {
      otherDocuments: otherClientDocuments,
      standardDocuments,
      otherAppianDocuments,
    };
  };
}

const documentService = new DocumentService();
export default documentService;
