import { Trans } from "@lingui/macro";
import React from "react";

export const HoldingsModalTitle = (
  <Trans id="documents.uploadDirectory.holdings.modal.title">Holding Documents</Trans>
);

export const HoldingsModalMessage = (
  <Trans id="documents.uploadDirectory.modal.message">
    Please confirm you would like to upload these documents.
  </Trans>
);

export const HoldingsModalBtnClose = (
  <Trans id="documents.uploadDirectory.modal.btn.close">Close</Trans>
);
export const HoldingsModalBtnOk = <Trans id="documents.uploadDirectory.modal.btn.ok">OK</Trans>;
export const HoldingsModalBtnCancel = (
  <Trans id="documents.uploadDirectory.modal.btn.cancel">Cancel</Trans>
);
