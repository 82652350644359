import React from "react";

import { Trans } from "@lingui/react";

import { Sticky, AnchoredMenu } from "common/lib/components";
import { Constants } from "common/lib/constants";
import { TypeAnchorLink } from "common/lib/components/anchored-menu/types";

import { PublicDocumentsList } from "domains/document";

import { useProfileData } from "domains/clients";
import { useSelectedLocaleData } from "common/shared-hooks";
import { portalEnvConstants } from "common/shared";

import { PropsResourcesPage } from "./types";
import "./resources-page.scss";

const anchoredIdsInitial = {
  documents: "documents",
};

const menuItemsInitial: TypeAnchorLink[] = [
  {
    id: anchoredIdsInitial.documents,
    title: <Trans id="resourcesPage.menu.documents.title">Documents</Trans>,
  },
];

const ResourcesPage: React.FC<PropsResourcesPage> = (): React.ReactElement | null => {
  const { user, currentUserIp } = useProfileData();
  const { locale } = useSelectedLocaleData();

  const currentUserFullName = (user && user.fullName) || null;
  const currentUserId = (user && user.userId) || null;
  const userDisplayId = (user && user.userDisplayId) || null;
  const timezone = portalEnvConstants.defaultTimezone;

  return (
    <div className="ben-sidebar-container">
      <Sticky topOffset={Constants.HEADER_HEIGHT}>
        <div className="ben-sidebar">
          <AnchoredMenu items={menuItemsInitial} />
        </div>
      </Sticky>
      <div className="ben-content resources-page-content">
        <section className="ben-main-section">
          <PublicDocumentsList
            anchoredIds={anchoredIdsInitial}
            currentUserId={currentUserId || ""}
            userDisplayId={userDisplayId || ""}
            currentUserFullName={currentUserFullName || ""}
            currentUserIp={currentUserIp || ""}
            timezone={timezone}
            locale={locale}
          />
        </section>
      </div>
    </div>
  );
};

export default ResourcesPage;
