import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getVersion } from "common/shared-store";

// Get version of the app if on the page there is no request to BE
function useGetAppVersion(): boolean {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getVersion());
  }, [dispatch]);

  return true;
}

export default useGetAppVersion;
