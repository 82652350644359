import React from "react";
import { RouteComponentProps } from "@reach/router";
import AddIntialDocuments from "domains/liquidity/components/add-intial-documents";
import { TypeReferenceType } from "domains/document/shared/types";

interface AddClientDocPage extends RouteComponentProps {
  accountId: string;
  requestId: string;
  onUploadSuccess: () => void;
}

function AddClientDocPage({ accountId, requestId, onUploadSuccess }: AddClientDocPage) {
  return (
    <div>
      <AddIntialDocuments
        accountId={accountId}
        documentsConfig={{
          liquidityRequestId: requestId,
          referenceType: "liquidity_request" as TypeReferenceType,
        }}
        onUploadSuccess={onUploadSuccess}
        uniqueId={requestId}
      />
    </div>
  );
}
export default AddClientDocPage as React.ComponentType<RouteComponentProps>;
