import { TypeGetUserParams, TypeGetClientParams } from "./types";
import publicIp from "public-ip";

import { apiEnvs, apiService, sessionService } from "common/shared";

import { ApiEnv } from "common/shared/interfaces/apiEnv";
import { PatchRequestItemData, TypeResponseData } from "common/shared/interfaces/requests";
import PasswordChanges from "common/shared/interfaces/password-changes";
import { ArrayUtils } from "common/shared/utils";

import ClientsApiParser from "domains/clients/shared/clients.api.parser";
import User from "domains/clients/store/user.model";
import Institution from "domains/clients/store/institution.model";
import UserLight from "domains/clients/store/user.light.model";
import ProfileApiTransformHelper from "domains/clients/shared/profile.api.transform.helper";
import {
  ParamsGetLiquidityRequestCounts,
  TypeGetClientsParams,
} from "domains/clients/shared/types";
import { GettingClientsParamsDefault } from "domains/clients/shared/constants";

export class ClientsAPIService {
  getAccountClients = async (params?: TypeGetClientsParams) => {
    return apiEnvs
      .getApiEnv()
      .then((envs: any) => {
        const { accountsAPI } = envs;
        const { accountId, userId } = sessionService.getSessionIds();
        const mergedParams: any = { ...GettingClientsParamsDefault, ...params };
        const parameters = Object.keys(mergedParams)
          .map((key) => {
            return key + "=" + encodeURIComponent(mergedParams[key]);
          })
          .join("&");

        return apiService.getResource(
          `${accountsAPI}/v1/accounts/${accountId}/users/${userId}/clients?${parameters}`
        );
      })
      .then((data: any) => {
        if (!data.items.length) {
          return new Promise((resolve) => resolve([]));
        }

        return data.items.map((acc: any) => ({
          accountId: acc.account_id,
          accountName: acc.account_name,
          accountDisplayId: acc.account_display_id,
        }));
      });
  };

  addClient = async (accountData: any) => {
    return apiEnvs.getApiEnv().then((envs: ApiEnv) => {
      const { accountsAPI, whitelabel } = envs;
      return apiService.postResource(`${accountsAPI}/v2/accounts`, {
        ...accountData,
        user: {
          ...accountData.user,
          white_label_instance: whitelabel,
        },
      });
    });
  };

  addClientByForce = async (accountData: any) => {
    return apiEnvs.getApiEnv().then((envs: ApiEnv) => {
      const { accountsAPI, whitelabel } = envs;
      const { accountId, userId } = sessionService.getSessionIds();
      return apiService.postResource(
        `${accountsAPI}/v1/accounts/${accountId}/users/${userId}/clients`,
        {
          ...accountData,
          user: {
            ...accountData.user,
            white_label_instance: whitelabel,
          },
        }
      );
    });
  };

  attacheClientToAdvisor = async (data: { client_id: string }) => {
    return apiEnvs.getApiEnv().then((envs: ApiEnv) => {
      const { accountsAPI } = envs;
      const { accountId, userId } = sessionService.getSessionIds();
      return apiService.postResource(
        `${accountsAPI}/v1/accounts/${accountId}/users/${userId}/clients`,
        data
      );
    });
  };

  deleteClient = async (accountId: string) => {
    return apiEnvs.getApiEnv().then((envs: ApiEnv) => {
      const { accountsAPI } = envs;
      return apiService.deleteResource(`${accountsAPI}/v1/accounts/${accountId}`);
    });
  };

  addUser = async (accountId: string, data: any) => {
    return apiEnvs.getApiEnv().then((envs: ApiEnv) => {
      const { accountsAPI } = envs;
      const userData = ClientsApiParser.prepareUserData(data);
      return apiService.postResource(`${accountsAPI}/v1/accounts/${accountId}/users`, userData);
    });
  };

  getLiquidityRequestCounts = async (params?: ParamsGetLiquidityRequestCounts): Promise<any> => {
    if (!params || !params.accounts) {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;

    const response: TypeResponseData = await apiService.getResource(
      `${holdingsAPI}/v1/liquidity_request_counts?accounts=${params.accounts}`
    );

    return response;
  };

  getClient = async (data?: TypeGetClientParams): Promise<any> => {
    if (!data) {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { accountsAPI } = envs;
    const { accountId } = data;

    if (!accountId) {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }
    const account: any = await apiService.getResource(`${accountsAPI}/v1/accounts/${accountId}`);

    if (account.account_type === "institution") {
      const institution = ClientsApiParser.parseClientInstitutionResponseData(account);
      if (!institution) {
        return new Promise((resolve) => {
          resolve(undefined);
        });
      }
      if (institution.primaryContactUser && institution.primaryContactUser.id) {
        const responsePrimary: any = await apiService.getResource(
          `${accountsAPI}/v1/accounts/${accountId}/users/${institution.primaryContactUser.id}`
        );

        if (responsePrimary) {
          institution.primaryContactUser = ClientsApiParser.parseClientUserResponseData(
            responsePrimary
          );
        }
      }

      return institution;
    }

    if (!account.principal_data) {
      return new Promise((resolve) => {
        resolve(undefined);
      });
    }

    const response: any = await apiService.getResource(
      `${accountsAPI}/v1/accounts/${accountId}/users/${account.principal_data.user_id}`
    );

    return {
      user: ClientsApiParser.parseClientUserResponseData(response),
      rawUser: response,
    };
  };

  getAccount = async (accountId: string) => {
    return apiEnvs.getApiEnv().then((envs: any) => {
      const { accountsAPI } = envs;
      return apiService
        .getResource(`${accountsAPI}/v1/accounts/${accountId}`)
        .then((response: any) => {
          return new Institution(ProfileApiTransformHelper.buildAccount(response));
        });
    });
  };

  getUser = async (data: TypeGetUserParams) => {
    return apiEnvs.getApiEnv().then((envs: any) => {
      const { accountsAPI } = envs;
      const { accountId, userId } = data;
      if (!accountId || !userId) {
        return {
          user: new User(ProfileApiTransformHelper.buildUser(null)),
        };
      }
      return apiService
        .getResource(`${accountsAPI}/v1/accounts/${accountId}/users/${userId}`)
        .then((response: any) => {
          return {
            user: new User(ProfileApiTransformHelper.buildUser(response)),
          };
        });
    });
  };

  updateAccount = async (patchData: PatchRequestItemData[], accountId: string) => {
    return apiEnvs.getApiEnv().then((envs: ApiEnv) => {
      const { accountsAPI } = envs;
      const institutionData = patchData.map((patchItem: any) => {
        return {
          ...patchItem,
          path:
            patchItem.path !== "/account_name"
              ? `/institution_data${patchItem.path}`
              : patchItem.path,
        };
      });

      return apiService.patchResource(`${accountsAPI}/v1/accounts/${accountId}`, institutionData);
    });
  };

  updatePrincipal = async (
    patchData: PatchRequestItemData[],
    accountId: string,
    userId: string
  ) => {
    return apiEnvs.getApiEnv().then((envs: ApiEnv) => {
      const { accountsAPI } = envs;
      return apiService.patchResource(
        `${accountsAPI}/v1/accounts/${accountId}/users/${userId}`,
        patchData
      );
    });
  };

  getProfileData = async () => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { accountsAPI } = envs;
    const { accountId, userId } = sessionService.getSessionIds();
    if (!accountId || !userId) {
      return {
        user: new User(ProfileApiTransformHelper.buildUser(null)),
        rawUser: null,
        currentUserIp: null,
      };
    }

    const [response, currentUserIp] = await Promise.all([
      apiService.getResource(`${accountsAPI}/v1/accounts/${accountId}/users/${userId}`),
      publicIp.v4(),
    ]);

    return {
      user: new User(ProfileApiTransformHelper.buildUser(response)),
      rawUser: response,
      currentUserIp,
    };
  };

  getAccountUsers = async (accountId: string) => {
    return apiEnvs.getApiEnv().then((envs: any) => {
      const { accountsAPI } = envs;
      return apiService
        .getResource(`${accountsAPI}/v1/accounts/${accountId}/users`)
        .then((response: any) => {
          const users = response.items;
          return ArrayUtils.isValidArrayData(users)
            ? users.map(
                (user: any) => new UserLight(ProfileApiTransformHelper.buildUserLight(user))
              )
            : [];
        });
    });
  };

  changePassword = async (data: PasswordChanges) => {
    const passData = {
      previous_password: data.currentPassword,
      proposed_password: data.newPassword,
    };
    return apiEnvs.getApiEnv().then((envs: any) => {
      const { identityAPI } = envs;
      return apiService.postResource(`${identityAPI}/v1/identity/change_password`, passData);
    });
  };
}

const clientsAPIService = new ClientsAPIService();
export default clientsAPIService;
