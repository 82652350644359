import React from "react";
import { Modal, Button } from "antd";
import { Trans } from "@lingui/macro";

import { TypeInvitedObject } from "domains/liquidity/shared/types";
import { Layout } from "common/lib/constants";

import { PropsInviteModal } from "./types";

const InvitedObjectsModalTitlesMap: {
  [key in TypeInvitedObject]: React.ReactElement;
} = {
  you: <Trans id="inviteModal.invitedObjects.you.title">You can't share with yourself</Trans>,
  client: <Trans id="inviteModal.invitedObjects.client.title">Share with a Client</Trans>,
  advisor: <Trans id="inviteModal.invitedObjects.advisor.title">Share with an Advisor</Trans>,
};

const InviteModal: React.FC<PropsInviteModal> = (props): React.ReactElement | null => {
  const {
    handleModalClose,
    handleSubmit,
    disableSubmit,
    isVisible,
    invitedObjectType,
    invitedAccount,
  } = props;

  if (!handleModalClose || !handleSubmit || !invitedAccount) {
    return null;
  }

  return (
    <div>
      <Modal
        title={InvitedObjectsModalTitlesMap[invitedObjectType] || ""}
        visible={isVisible}
        onCancel={handleModalClose}
        footer={null}
        centered={true}
        width={"100%"}
        style={{
          maxWidth: Layout.MaxWidthModalWindow.standard,
        }}
        wrapClassName="ben-modal-container"
      >
        <div className="ben-modal-content">
          <h4>{invitedAccount.displayName}</h4>
          {invitedAccount.email && <p>{invitedAccount.email}</p>}
        </div>

        <Button
          type="primary"
          shape="round"
          ghost={true}
          size="large"
          className="ben-w-100 ben-mb-3"
          onClick={handleModalClose}
          id="benInviteModalActionCancel"
        >
          <Trans id="inviteModal.action.cancel.title">Cancel</Trans>
        </Button>
        <Button
          type="primary"
          shape="round"
          size="large"
          className="ben-w-100"
          onClick={handleSubmit}
          disabled={disableSubmit}
          id="benInviteModalActionSubmit"
        >
          <Trans id="inviteModal.action.submit.title">Submit</Trans>
        </Button>
      </Modal>
    </div>
  );
};

export default InviteModal;
