import { validatorTypes } from "common/shared";
import FormInput from "common/shared/interfaces/form-input";

export class RegistrationFormSchema {
  public accountType = new FormInput();
  public firstName = new FormInput();
  public lastName = new FormInput();
  public email = new FormInput();
  // institutionName
  public name = new FormInput();
  constructor(data?: any, disabledFields?: { [key: string]: boolean }) {
    if (data) {
      this.accountType.value = data.accountType || "";
      this.firstName.value = data.firstName || "";
      this.lastName.value = data.lastName || "";
      this.email.value = data.email || "";
      this.name.value = data.name || "";
    }

    if (disabledFields) {
      this.accountType.isDisabled = disabledFields.accountType || false;
      this.firstName.isDisabled = disabledFields.firstName || false;
      this.lastName.isDisabled = disabledFields.lastName || false;
      this.email.isDisabled = disabledFields.email || false;
      this.name.isDisabled = disabledFields.name || false;
    }
  }
}

export interface RegistrationFormReturnedData {
  accountType: string;
  firstName: string;
  lastName: string;
  email: string;
  name: string;
}

export const IndividualValidationSchema = {
  accountType: [validatorTypes.required],
  firstName: [validatorTypes.required, validatorTypes.namePattern],
  lastName: [validatorTypes.required, validatorTypes.namePattern],
  email: [validatorTypes.required, validatorTypes.emailPattern],
  name: [],
};

export const InstitutionValidationSchema = {
  accountType: [validatorTypes.required],
  firstName: [validatorTypes.required, validatorTypes.namePattern],
  lastName: [validatorTypes.required, validatorTypes.namePattern],
  email: [validatorTypes.required, validatorTypes.emailPattern],
  name: [validatorTypes.required, validatorTypes.organizationName],
};

export const RegistrationDisabledConfig = {
  firstName: true,
  lastName: true,
  email: true,
  name: true,
};

export const RegistrationValidationSchemaDeps = {
  accountType: ["name"],
  options: {
    validateOnChange: true,
  },
};
