import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import { navigate, RouteComponentProps } from "@reach/router";

import { Urls } from "common/lib/constants";
import { CardErrorSection } from "common/lib/components";
import { sessionService } from "common/shared";
import { useGetAppVersion } from "common/shared-hooks";
import { PagesInfo } from "pages/constants";

import image from "common/assets/images/404-error.svg";

interface PropsError404Page extends RouteComponentProps {}

export const Error404Page = (props: PropsError404Page) => {
  const [token] = useState(sessionService.getTokens());

  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  if (!token) {
    // redirect to page "/puplic-404" in case if the user isn't authorized
    navigate(Urls.AUTH.ERROR_404);
  }

  const handleRedirectTo = () => {
    navigate(Urls.PROTECTED.BASE);
  };

  const btnText = <Trans id="page404.btnText">Return to Dashboard</Trans>;

  return (
    <CardErrorSection
      title={PagesInfo.Error404Info.title}
      message={PagesInfo.Error404Info.message}
      loading={false}
      handleOnClick={handleRedirectTo}
      btnText={btnText}
      image={image}
    />
  );
};

export default Error404Page;
