import React from "react";
import { Trans } from "@lingui/macro";

import {
  DocumentTypeDisplaySetting,
  TypeDocumentType,
  TypeDocumentStatus,
  DocumentStatusDisplaySetting,
  DocumentTypeUpload,
} from "./types";
import { TypeLiquidityStatusCode, TypeLiquidityTypeCode } from "domains/liquidity/shared/types";

const iconPathComplete = "complete";

export const DocumentStatusesDisplay: {
  [key in TypeDocumentStatus | "fallback"]: DocumentStatusDisplaySetting;
} = {
  success: {
    iconPath: iconPathComplete,
    text: (
      <Trans id="documents.statuses.success.text">
        This document has been signed and counter-signed.
      </Trans>
    ),
  },

  fallback: {
    iconPath: iconPathComplete,
    text: <Trans id="documents.statuses.fallback.text">Unknown document status.</Trans>,
  },
};

export const DocumentTypesDisplayMap: {
  [key in TypeDocumentType | "fallback"]: DocumentTypeDisplaySetting;
} = {
  private_placement_memorandum: {
    title: (
      <Trans id="documents.types.private_placement_memorandum.title">
        Private Placement Memorandum
      </Trans>
    ),
  },
  annual_report: {
    title: <Trans id="documents.types.annual_report.title">Annual Report</Trans>,
  },
  quarterly_report: {
    title: <Trans id="documents.types.quarterly_report.title">Quarterly Report</Trans>,
  },
  limited_partnership_agreement: {
    title: (
      <Trans id="documents.types.limited_partnership_agreement.title">
        Limited Partnership Agreement
      </Trans>
    ),
  },
  subscription_document: {
    title: <Trans id="documents.types.subscription_document.title">Subscription Document</Trans>,
  },
  k1_tax: {
    title: <Trans id="documents.types.k1_tax.title">K1 Tax</Trans>,
  },
  capital_call_notice: {
    title: <Trans id="documents.types.capital_call_notice.title">Capital Call Notice</Trans>,
  },
  distribution_notice: {
    title: <Trans id="documents.types.distribution_notice.title">Distribution Notice</Trans>,
  },
  other: {
    title: <Trans id="documents.types.other.title">Other</Trans>,
  },
  custom_other_investment: {
    title: <Trans id="documents.types.other.title">Other</Trans>,
  },
  custom_other_liquidity_request: {
    title: <Trans id="documents.types.other.title">Other</Trans>,
  },
  bottom_up_model: {
    title: <Trans id="documents.types.bottom_up_model.title">Bottom Up Model</Trans>,
  },
  barq_model: {
    title: <Trans id="documents.types.barq_model.title">Barq Model</Trans>,
  },
  top_down_model: {
    title: <Trans id="documents.types.top_down_model.title">Top Down Model</Trans>,
  },
  z_score_model: {
    title: <Trans id="documents.types.z_score_model.title">Z-score Model</Trans>,
  },
  commitment_packet: {
    title: <Trans id="documents.types.commitment_packet.title">Commitment Packet</Trans>,
  },
  term_sheet: {
    title: <Trans id="documents.types.term_sheet.title">Binding Term Sheet</Trans>,
  },
  purchase_and_sale: {
    title: <Trans id="documents.types.purchase_and_sale.title">Purchase Agreement</Trans>,
  },
  liquid_trust: {
    title: <Trans id="documents.types.liquid_trust.title">Liquid Trust Agreement</Trans>,
  },
  lp_capital_statement: {
    title: <Trans id="documents.types.lp_capital_statement.title">LP Capital Statement</Trans>,
  },
  unknown: {
    title: <Trans id="documents.types.unknown.title">Unknown</Trans>,
  },

  fallback: {
    title: <Trans id="documents.types.fallback.title">Unknown document type</Trans>,
  },

  audited_financials: {
    title: <Trans id="documents.types.audited_financials.title">Audited Financials</Trans>,
  },

  exalt_plan: {
    title: <Trans id="documents.types.exalt_plan.title">Exalt Plan</Trans>,
  },
};

const termSheetDocumentAccepted: DocumentStatusDisplaySetting = {
  iconPath: iconPathComplete,
  text: <Trans id="documents.statuses.accepted.text">This document has been accepted</Trans>,
  status: <Trans id="documents.statuses.accepted.title">Accepted</Trans>,
};

const reviewTermSheetInfo = {
  iconPath: iconPathComplete,
  text: <Trans id="documents.term.sheet.defaultInfo">Please review.</Trans>,
};

export const DefaultDocumentInfo = {
  iconPath: iconPathComplete,
  text: (
    <Trans id="documents.statuses.success.text">
      This document has been signed and counter-signed.
    </Trans>
  ),
};

export const DocumentInfoByMappedStatus: {
  [key in TypeDocumentType]?: {
    [k in TypeLiquidityTypeCode | "fallback"]?: DocumentStatusDisplaySetting;
  };
} = {
  term_sheet: {
    in_verification: reviewTermSheetInfo,
    in_underwriting: reviewTermSheetInfo,
    in_proposal: reviewTermSheetInfo,
    client_signed: termSheetDocumentAccepted,
    cancelled: termSheetDocumentAccepted,
    closed: termSheetDocumentAccepted,
    fallback: DefaultDocumentInfo,
  },
};

export const DocumentInfo: {
  [key in TypeDocumentType]?: {
    [k in TypeLiquidityStatusCode | "fallback"]?: DocumentStatusDisplaySetting;
  };
} = {
  liquid_trust: {
    client_signed: {
      iconPath: iconPathComplete,
      text: (
        <Trans id="documents.purchase.statuses.client_signed">The Document has been signed.</Trans>
      ),
      status: <Trans id="documents.statuses.client_signed.title">signed</Trans>,
    },
  },
  purchase_and_sale: {
    client_signed: {
      iconPath: iconPathComplete,
      text: <Trans id="documents.purchase.statuses.client_signed" />,
      status: <Trans id="documents.statuses.client_signed.title" />,
    },
  },
};

export const DocumentTypeUploadConfigs: {
  [key in TypeDocumentType]: DocumentTypeUpload;
} = {
  other: {
    referenceType: "investment",
    documentType: "other",
  },
  custom_other_investment: {
    referenceType: "investment",
    documentType: "other",
  },
  custom_other_liquidity_request: {
    referenceType: "liquidity_request",
    documentType: "other",
  },
  private_placement_memorandum: {
    referenceType: "asset",
    documentType: "private_placement_memorandum",
  },
  annual_report: { referenceType: "asset", documentType: "annual_report" },
  quarterly_report: { referenceType: "asset", documentType: "quarterly_report" },
  limited_partnership_agreement: {
    referenceType: "investment",
    documentType: "limited_partnership_agreement",
  },
  subscription_document: { referenceType: "investment", documentType: "subscription_document" },
  k1_tax: { referenceType: "investment", documentType: "k1_tax" },
  capital_call_notice: { referenceType: "investment", documentType: "capital_call_notice" },
  distribution_notice: { referenceType: "investment", documentType: "distribution_notice" },
  bottom_up_model: { referenceType: "liquidity_request", documentType: "bottom_up_model" },
  barq_model: { referenceType: "liquidity_request", documentType: "barq_model" },
  top_down_model: { referenceType: "liquidity_request", documentType: "top_down_model" },
  z_score_model: { referenceType: "liquidity_request", documentType: "z_score_model" },
  commitment_packet: { referenceType: "liquidity_request", documentType: "commitment_packet" },
  term_sheet: { referenceType: "liquidity_request", documentType: "term_sheet" },
  purchase_and_sale: { referenceType: "liquidity_request", documentType: "purchase_and_sale" },
  liquid_trust: { referenceType: "liquidity_request", documentType: "liquid_trust" },
  lp_capital_statement: { referenceType: "investment", documentType: "lp_capital_statement" },
  unknown: {
    referenceType: "investment",
    documentType: "unknown",
  },
  audited_financials: { referenceType: "asset", documentType: "audited_financials" },
  exalt_plan: { referenceType: "asset", documentType: "exalt_plan" },
};

export const STANDARD_INVESTMENT_DOCUMENT_LIST: TypeDocumentType[] = [
  "annual_report",
  "term_sheet",
  "quarterly_report",
  "limited_partnership_agreement",
  "lp_capital_statement",
  "audited_financials",
  "private_placement_memorandum",
  "subscription_document",
  "exalt_plan",
  "unknown",
  "k1_tax",
];

export const STANDARD_LIQUIDITY_DOCUMENT_LIST: TypeDocumentType[] = [
  "bottom_up_model",
  "barq_model",
  "top_down_model",
  "z_score_model",
  "commitment_packet",
  "term_sheet",
  "purchase_and_sale",
  "liquid_trust",
  "lp_capital_statement",
  "unknown",
  "k1_tax",
];
