import { useSelector } from "react-redux";

import Institution from "../store/institution.model";

interface AccountDataProps {
  account: Institution;
  accountLoading: boolean;
}

function useAccountData(): AccountDataProps {
  const accountData = useSelector(
    (state: any): AccountDataProps => {
      const { account, accountLoading } = state.clients;
      return {
        account,
        accountLoading,
      };
    }
  );

  return { ...accountData };
}

export default useAccountData;
