import { ApiEnv } from "common/shared/interfaces/apiEnv";
import { TypeResponseData } from "common/shared/interfaces/requests";

import { apiEnvs, apiService, apiTransformUtils } from "common/shared";
import { NumberUtils } from "common/shared/utils";
import { BenInfo } from "common/lib/constants";

import {
  DocumentListResponseData,
  ParamsGetDocuments,
  ParamsCreateDocument,
  ParamsGetDocumentUrl,
  CreateDocumentResponseData,
  DocumentUrlResponseData,
  ParamsConfirmUpload,
  ParamsDocumentsByHoldings,
  TypeGetWatermarkURLParams,
  ParamsGetPublicDocumentUrl,
} from "./types";
import {
  parseDocumentsListResponseData,
  parseDocumentUrlResponseData,
  parseCreateDocumentResponseData,
  prepareAssetIds,
} from "./document.parser";

export class DocumentAPIService {
  /**
   * @description - API call to get list of documents data from BE
   */
  getTransactionDocuments = async (data: ParamsGetDocuments): Promise<DocumentListResponseData> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { documentsAPI } = envs;
    const { accountId, requestId } = data;
    const requestIdParam = NumberUtils.getIntFromString(requestId);
    if (!accountId || !requestIdParam) {
      return this.getErrorResponse();
    }

    const filter = `&filter[0][field]=liquidity_request_id&filter[0][operator]=equal&filter[0][value]=${requestIdParam}`;
    const page = "page[size]=1000";
    const referenceType =
      "&filter[1][field]=reference_type&filter[1][operator]=equal&filter[1][value]=liquidity_request";
    const docType =
      "&filter[2][field]=document_type&filter[2][operator]=not_equal&filter[2][value]=unknown";

    const response: TypeResponseData = await apiService.getResource(
      `${documentsAPI}/v1/accounts/${accountId}/documents?${page}${filter}${referenceType}${docType}`
    );
    const responseParsed: DocumentListResponseData = parseDocumentsListResponseData(response);

    return responseParsed;
  };

  getUncategorizedDocuments = async (
    data: ParamsGetDocuments
  ): Promise<DocumentListResponseData> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { documentsAPI } = envs;
    const { accountId, requestId } = data;
    const requestIdParam = NumberUtils.getIntFromString(requestId);
    if (!accountId || !requestIdParam) {
      return this.getErrorResponse();
    }

    const filter = `&filter[0][field]=liquidity_request_id&filter[0][operator]=equal&filter[0][value]=${requestIdParam}`;
    const page = "page[size]=1000";
    const referenceType =
      "&filter[1][field]=reference_type&filter[1][operator]=equal&filter[1][value]=liquidity_request";
    const docType =
      "&filter[2][field]=document_type&filter[2][operator]=equal&filter[2][value]=unknown";

    const response: TypeResponseData = await apiService.getResource(
      `${documentsAPI}/v1/accounts/${accountId}/documents?${page}${filter}${referenceType}${docType}`
    );

    const responseParsed: DocumentListResponseData = parseDocumentsListResponseData(response);

    return responseParsed;
  };

  /**
   * @description - API call to get document url for downloading from BE
   */
  getDocumentUrl = async (data: ParamsGetDocumentUrl): Promise<DocumentUrlResponseData> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { documentsAPI } = envs;
    const {
      accountId,
      documentId,
      currentUserId,
      userDisplayId,
      currentUserFullName,
      currentUserIp,
      locale,
      timezone,
      version,
    } = data;
    const versionParam = version || "latest";
    if (!accountId || !documentId) {
      return this.getErrorResponse();
    }

    const watermarkParams = this.getWatermarkURLParams({
      currentUserId,
      currentUserFullName,
      currentUserIp,
      userDisplayId,
      locale,
      timezone,
    });
    const response: TypeResponseData = await apiService.getResource(
      `${documentsAPI}/v1/accounts/${accountId}/documents/${documentId}/download_url/${versionParam}${watermarkParams}`
    );
    const responseParsed: DocumentUrlResponseData = parseDocumentUrlResponseData(response);

    return responseParsed;
  };

  /**
   * @description - API call to get public document url for downloading from BE
   */
  getPublicDocumentUrl = async (
    data: ParamsGetPublicDocumentUrl
  ): Promise<DocumentUrlResponseData> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { documentsAPI } = envs;
    const {
      fileName,
      /*  currentUserId,
      userDisplayId,
      currentUserFullName,
      currentUserIp,
      locale,
      timezone,
      version, */
    } = data;
    if (!fileName) {
      return this.getErrorResponse();
    }

    // TODO: restore this to have watermark from BE
    /* const versionParam = version;
    const watermarkParams = this.getWatermarkURLParams({
      currentUserId,
      currentUserFullName,
      currentUserIp,
      userDisplayId,
      locale,
      timezone,
    }); */

    const response: TypeResponseData = await apiService.getResource(
      `${documentsAPI}/v1/documents/${fileName}/download_url`
    );

    const responseParsed: DocumentUrlResponseData = parseDocumentUrlResponseData(response);

    return responseParsed;
  };

  /**
   * @description - API call to get list of documents data by holdings
   */
  getDocumentsByHoldings = async (
    data: ParamsDocumentsByHoldings
  ): Promise<DocumentListResponseData> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { documentsAPI } = envs;
    const { accountId, assetIds } = data;

    const filter = `&filter[0][field]=asset_id&filter[0][operator]=equal&filter[0][value]=${prepareAssetIds(
      assetIds
    )}`;
    const page = "page[size]=1000";
    const docType =
      "&filter[1][field]=document_type&filter[1][operator]=not_equal&filter[1][value]=unknown";

    const response: TypeResponseData = await apiService.getResource(
      `${documentsAPI}/v1/accounts/${accountId}/documents?${page}${filter}${docType}`
    );

    const responseParsed: DocumentListResponseData = parseDocumentsListResponseData(response);

    return responseParsed;
  };

  /**
   * @description - API call to get document url for uploading from BE
   */
  createDocument = async (
    data: ParamsCreateDocument,
    params?: any
  ): Promise<CreateDocumentResponseData | null> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { documentsAPI } = envs;
    const { accountId } = data;
    const response: TypeResponseData = await apiService.postResource(
      `${documentsAPI}/v1/accounts/${accountId}/documents`,
      apiTransformUtils.transformObjectToBackendFormat(data),
      params
    );
    const responseParsed: CreateDocumentResponseData | null = parseCreateDocumentResponseData(
      response
    );

    return responseParsed;
  };

  updateDocument = async (
    data: ParamsCreateDocument,
    params?: any
  ): Promise<CreateDocumentResponseData | null> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { documentsAPI } = envs;
    const { accountId, documentId } = data;
    const response: TypeResponseData = await apiService.postResource(
      `${documentsAPI}/v1/accounts/${accountId}/documents/${documentId}`,
      apiTransformUtils.transformObjectToBackendFormat(data),
      params
    );
    const responseParsed: CreateDocumentResponseData | null = parseCreateDocumentResponseData(
      response
    );

    return responseParsed;
  };

  confirmUpload = async (data: ParamsConfirmUpload): Promise<any> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { documentsAPI } = envs;
    const { accountId, ...params } = data;
    if (!accountId) {
      return this.getErrorResponse();
    }
    const customConfigs = {
      headers: {
        [BenInfo.CUSTOM_HTTP_HEADERS.shouldNotRedirect]: "yes",
      },
    };

    return apiService.postResource(
      `${documentsAPI}/v1/accounts/${accountId}/upload_document_confirmation`,
      apiTransformUtils.transformObjectToBackendFormat(params),
      customConfigs
    );
  };

  private getErrorResponse(): Promise<any> {
    return new Promise((resolve) => {
      resolve(undefined);
    });
  }

  private getWatermarkURLParams(params: TypeGetWatermarkURLParams): string {
    const {
      currentUserId,
      currentUserFullName,
      currentUserIp,
      userDisplayId,
      locale,
      timezone,
    } = params;
    let watermarkParams = "";
    if (currentUserId && currentUserFullName && currentUserIp && userDisplayId) {
      const localeParam = (locale && `&locale=${locale}`) || "";
      const timezoneParam = (timezone && `&timezone=${timezone}`) || "";
      watermarkParams = `?name=${currentUserFullName}&user_id=${currentUserId}&user_display_id=${userDisplayId}&ip=${currentUserIp}${localeParam}${timezoneParam}`;
    }
    return watermarkParams;
  }
}

const documentAPIService = new DocumentAPIService();
export default documentAPIService;
