import moment from "moment-timezone";

import { portalEnvConstants } from "common/shared";
import { Constants } from "common/lib/constants";

export class DateUtils {
  private momentService: any;
  private locale: string = "";
  private dateFormats: { [key: string]: string } = {
    en: "MM/DD/YYYY",
    other: "DD/MM/YYYY",
    BELike: "MM-DD-YY",
  };
  private timeFormats: { [key: string]: string } = {
    en: "hh:mm a",
    other: "HH:mm",
  };

  constructor(momentService: any) {
    this.momentService = momentService;
  }

  public formatDate(stringDate: string, selectedLocale: string, timezone?: string): string {
    const format = this.getCurrentDateFormat(selectedLocale);

    return this.format(stringDate, selectedLocale, format, timezone);
  }

  public formatTime(stringDate: string, selectedLocale: string, timezone?: string): string {
    const format = this.timeFormats[selectedLocale] || this.timeFormats.other;

    return this.format(stringDate, selectedLocale, format, timezone);
  }

  public getCurrentDateFormat(selectedLocale: string): string {
    return this.dateFormats[selectedLocale] || this.dateFormats.other;
  }

  public getFormatedDateWithoutTimeZone(stringDate: string, selectedLocale: string) {
    const format = this.getCurrentDateFormat(selectedLocale);
    return this.momentService(stringDate).format(format);
  }

  public getBEDateFormat(): string {
    return this.dateFormats.BELike;
  }

  private setLocale(selectedLocale: string): void {
    if (this.locale !== selectedLocale) {
      this.locale = selectedLocale;
      this.momentService.locale(selectedLocale);
    }
  }

  private format(
    stringDate: string,
    selectedLocale: string,
    format: string,
    timezone?: string
  ): string {
    if (!stringDate || !selectedLocale) {
      return "";
    }
    this.setLocale(selectedLocale);
    const { defaultTimezone } = portalEnvConstants;
    const tzName =
      (timezone && Constants.TZ_FULL_NAMES[timezone]) ||
      Constants.TZ_FULL_NAMES[defaultTimezone] ||
      defaultTimezone;

    return this.momentService(stringDate)
      .utc(stringDate)
      .tz(tzName)
      .format(format);
  }
}

const dateUtils = new DateUtils(moment);
export default dateUtils;
