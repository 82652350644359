import React from "react";
import { Trans } from "@lingui/react";

const TermsOfUseContent: React.FC = (): React.ReactElement => {
  return (
    <div className="terms-agreement-modal-body">
      <p className="agreement-textIndent">
        <Trans id="Terms.Row.Heading">
          This Terms of Use Agreement (“TOU”) sets forth the terms, conditions and guidelines for
          use of the www.trustben.com websites (collectively, the “Site”) and the services offered
          on it. This TOU constitutes a legally binding contract between the user (“you” or “your”),
          on the one hand, and The Beneficient Company, L.P. (“Beneficient”) and its affiliated
          entities and individuals (collectively “we”, “us” or “our”), on the other hand. Please
          read this TOU carefully before using the Site. By accessing and using the Site in any way,
          you acknowledge that you have read this TOU, understand it and agree to be bound by its
          terms, conditions and guidelines, as we may amend from time to time by posting any revised
          TOU on the Site.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        1.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading1">Privacy</Trans>
        </span>
        <Trans id="Terms.Row1">
          {" "}
          Our use of your personal information collected on the Site is governed by the terms of the
          Beneficient Website Privacy Policy. The Beneficient Website Privacy Policy is posted on
          the Site and sets forth our sole obligations with respect to user data and privacy.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        2.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading2">Children</Trans>
        </span>
        <Trans id="Terms.Row2">
          {" "}
          You must be 18 years of age or older to use the Site. The Site is not directed at
          children, and we do not knowingly collect the personal information from anyone under the
          age of 13.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        3.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading3">Intellectual Property</Trans>
        </span>
        <Trans id="Terms.Row3">
          Trademarks. This web site contains logos, service marks, and trademarks owned by The
          Beneficient Company, L.P. (the “Marks”). The Marks may not be used for any purpose without
          our express written permission.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        Copyrights. We retain all right, title and interest in and to all content (and all
        compilations thereof), including without limitation, any text, music, sound, photographs,
        video, images, illustrations, icons, graphics, headers, typefaces, data, inventory
        information, databases or software, that appears on the Site (the “Content”). The Content is
        protected by U.S. and international copyright law. No right, title or interest to any of the
        Content is transferred to you by your use of the Site. You may access and view the Content
        solely for personal, noncommercial purposes. You may copy limited portions of the Content
        solely by page caching or printing and solely for personal, noncommercial use, provided that
        (i) you do not make such Content available to any third party and (ii) you do not remove any
        proprietary notice from such Content, or alter the Content in any way. You may not: modify,
        delete, add to, or create derivative works of the Content. You may not publish, distribute,
        transmit, broadcast or frame the Content in any manner; copy or post the Content for public
        display; collect, re-purpose or reuse any data or product listings contained in the Content;
        sell or attempt to sell the Content; exploit the Content for your own purposes; or make
        derivative use of the Site or its Content. In addition, you agree not to rely on any Content
        created or posted by us.
      </p>
      <br />
      <p className="agreement-textIndent">
        4.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading4">Content of Site</Trans>
        </span>
        <Trans id="Terms.Row4">
          We do not guarantee the accuracy, completeness or reliability of any information appearing
          on the Site and such information is subject to change without warning. The Site may
          contain errors and omissions relating to service and product descriptions, pricing and
          availability. We reserve the right to correct or update errors or omissions and to change
          information at our complete discretion without prior notice. By using the Site, you agree
          to hold us harmless from any claims relating to errors or omission on our Site.
          Furthermore, there may be information and links to the third party entities and websites
          that are not affiliated with us. Any trademarks or other intellectual property rights
          associated with those entities are solely owned by such third party and use of such site
          is governed by the Terms and Conditions of that site. We expressly disclaim any liability
          for any information contained on any third party site, and do not guarantee the accuracy,
          completeness, or reliability of such third-party information.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        5.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading5">Prohibited Uses</Trans>
        </span>
        <Trans id="Terms.Row5">
          You agree to use the Site and all related services only for lawful purposes. You further
          agree to abide by the terms and conditions of any third-party assisting with the hosting
          or administration of this Site in addition to these TOU.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="Terms.Row5.P2">
          Use of the Site in violation of any applicable law, regulation, ordinance, or other rule
          imposed by any governmental authority is prohibited. We expressly prohibit any
          unauthorized third party linking to the Site, including links to the home page or any
          other page on the Site domain.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        6.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading6">Indemnity</Trans>
        </span>
        <Trans id="Terms.Row6">
          {" "}
          You agree to indemnify and hold us and our affiliated entities, partners,
          telecommunications providers, service providers, officers, employees, and agents harmless
          from any claim or demand, including attorneys’ fees, made by any third party due to or
          arising out of your use of the Site or any related services, your violation of the TOU, or
          your violation of any third-party rights, including without limitation, the infringement
          by you, or any other user of an account you may have with us, of any intellectual property
          rights, privacy rights, or other rights of any person or entity.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        7.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading7">Limitation of Liability</Trans>
        </span>{" "}
        <Trans id="Terms.Row7">
          In no event will we, our affiliated entities, service providers, or other third parties
          affiliated with us be liable for any indirect, incidental, extraordinary, exemplary,
          punitive or consequential damages whatsoever (including without limitation those resulting
          from lost profits, lost data or business interruption) arising out of the use or inability
          to use the site or related services, the results of such services, or any information
          contained on the site, or in such services, whether such damages are based on warranty,
          contract, tort or other any other legal theory and whether or not such party is advised of
          the possibility of such damages. Our aggregate liability and the aggregate liability of
          any of our affiliated entities, service providers, or other third parties affiliated with
          us arising from or relating to this TOU (regardless of the form of action or claim, e.g.,
          contract, warranty, tort, strict liability, negligence, malpractice, fraud or any other
          legal theory) is limited to one-hundred dollars ($100). Applicable law may not allow the
          exclusion or limitation of incidental or consequential damages, so the above limitation or
          exclusion may not apply to you.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        8.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading8">Site Use and Disclaimer of Warranties</Trans>
        </span>
        <Trans id="Terms.Row8">
          {" "}
          THE SITE AND ALL RELATED SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND
          WITH ALL FAULTS, AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND
          EFFORT IS WITH THE USER. EXCEPT FOR THE EXPRESS WARRANTIES EXPLICITLY PROVIDED HEREIN,
          NEITHER WE NOR OUR AFFILIATED ENTITIES, PARTNERS, OR SERVICE PROVIDERS MAKE ANY
          REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED AS TO THE OPERATION OF THE
          SITE, THE CONTENT, ANY INFORMATION MADE AVAILABLE THROUGH THE SITE, OR ANY TRANSACTIONS
          ENTERED INTO BY USE OR THROUGH THE SITE. TO THE EXTENT PERMITTED BY LAW, WE AND OUR
          AFFILIATED ENTITIES, PARTNERS, AND SERVICE PROVIDERS DISCLAIM ALL WARRANTIES, EXPRESS OR
          IMPLIED, INCLUDING BY NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, ACCURACY,
          TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT AND QUIET ENJOYMENT. NEITHER WE
          NOR OUR AFFILIATED ENTITIES, PARTNERS, OR SERVICE PROVIDERS WARRANT THAT USE OF THE SITE
          OR ANY RELATED SERVICES WILL BE UNINTERRUPTED, AVAILABLE AT ANY TIME OR FROM ANY LOCATION,
          SECURE OR ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. NEITHER WE NOR OUR
          AFFILIATED ENTITIES, PARTNERS, OR SERVICE PROVIDERS WARRANT THAT THE SITE WILL MEET YOUR
          REQUIREMENTS OR THAT ANY DEFECTS WILL BE CORRECTED. FURTHERMORE, NEITHER WE NOR OUR
          AFFILIATED ENTITIES, PARTNERS, OR SERVICE PROVIDERS MAKE ANY WARRANTY AS TO THE RESULTS
          THAT MAY BE OBTAINED FROM USE OF THE SITE, OR AS TO THE ACCURACY, COMPLETENESS,
          RELIABILITY OR TIMELINESS OF ANY INFORMATION AVAILABLE ON OR THROUGH THE SITE.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="Terms.Row8.P2">
          YOU UNDERSTAND AND AGREE THAT ANY MATERIAL AND/OR DATA DOWNLOADED OR OTHERWISE OBTAINED
          THROUGH THE USE OF THE SITE IS AT YOUR DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT MAY RESULT FROM
          THE DOWNLOADING OF SUCH MATERIAL AND/OR DATA.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="Terms.Row8.P3">
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH THE
          SITE SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        9.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading9">Violation of TOU</Trans>
        </span>
        <Trans id="Terms.Row9">
          Please also note that at any time, if we determine that you have abused or violated any of
          the provisions of this TOU, or any applicable law, we reserve the right to terminate or
          suspend your access to the Site and all related services, initiate an investigation,
          remove materials from our servers, issue a warning, block any prohibited activity, and
          take any other responsive action. Users who violate this TOU may additionally incur
          criminal and/or civil liability.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        10.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading10">Modifications</Trans>
        </span>
        <Trans id="Terms.Row10">
          Please note that we may change this TOU at any time by posting the modified TOU on the
          Site, or by otherwise notifying you of such change. Use of the Site after such changes are
          posted constitutes acceptance of all changes.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        11.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading11">Applicable Law</Trans>
        </span>
        <Trans id="Terms.Row11">
          The TOU shall be governed by and construed in accordance with the laws of the state of
          Texas as it is applied to contracts entered into by residents of the state of Texas.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="Terms.Row11.P2">
          This TOU (including the Beneficient Holdings Website Privacy Policy, incorporated herein
          by reference) constitute the entire agreement between you and us with respect to the
          subject matter herein and supersede any and all prior or contemporaneous oral or written
          agreements. You may not assign this agreement to any other party.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        12.
        <span className="ben-important-text section-Margin">
          <Trans id="Terms.Row.Heading12">Disputes</Trans>
        </span>
        <Trans id="Terms.Row12">
          {" "}
          Any dispute relating in any way to your visit to the Site shall be submitted to
          confidential arbitration in Dallas, Texas, except that, to the extent you have in any
          manner violated or threatened to violate Beneficient’s intellectual property rights,
          Beneficient may seek injunctive or other appropriate relief in any state or federal court
          in the state of Texas, and you consent to exclusive jurisdiction and venue in such courts.
          Arbitration under this TOU shall be conducted by a single arbitrator under the rules then
          prevailing under the Commercial Arbitration Rules of the American Arbitration Association.
          The arbitrator’s award shall be binding and may be entered as a judgment in any court of
          competent jurisdiction. To the fullest extent permitted by applicable law, no arbitration
          under this TOU shall be joined to an arbitration involving any other party subject to this
          Agreement, whether through class arbitration proceedings or otherwise.
        </Trans>
      </p>
    </div>
  );
};
export default TermsOfUseContent;
