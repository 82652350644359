import React from "react";
import { Trans } from "@lingui/macro";

export const AccreditationPreviewTitle = (
  <Trans id="accreditation.preview.title">Accreditation</Trans>
);
export const AccreditationPreviewContent = (
  <Trans id="accreditation.preview.content">
    Applicable securities laws require that we verify your status as an Accredited Investor under
    Regulation D of the Securities Act of 1933. Please click the link below to access our
    third-party Accredited Investor verification provider, InvestReady. You will be asked to create
    a separate login to use their service, but will not need to leave Ben Alt Access to do so.
  </Trans>
);
export const AccreditationPreviewMobile = (
  <Trans id="accreditation.preview.mobile">
    Please login to the desktop version of Ben Alt Access to begin your accreditation process.
  </Trans>
);
export const AccreditationStartBtnTitle = <Trans id="accreditation.btn.start.title">Begin</Trans>;
export const AccreditationAgreeBtnTitle = <Trans id="accreditation.btn.agree.title">OK</Trans>;
export const AccreditationCancelBtnTitle = (
  <Trans id="accreditation.btn.cancel.title">Cancel</Trans>
);
export const AccreditationModalTitle = (
  <Trans id="accreditation.modal.title">
    Attention: You are leaving Ben’s website and going to a third-party website
  </Trans>
);
export const AccreditationModalContent1 = (
  <Trans id="accreditation.modal.content1">
    You are now leaving our website and entering a website owned by a third party. The third-party
    website is not owned, controlled or operated by The Beneficient Company Group, L.P. or its
    affiliates (collectively, “Ben”).
  </Trans>
);
export const AccreditationModalContent2 = (
  <Trans id="accreditation.modal.content2">
    Ben does not own or control the content, products or services contained on third-party websites
    and does not control any transactions that occur on or through third-party websites. Ben makes
    no representations about, and does not guarantee or endorse, the content, products or services
    offered on third-party websites. Ben is not responsible or liable for damages relating to or
    arising from the use of the third-party website, or any content, products or services offered by
    the third-party.
  </Trans>
);
export const AccreditationModalContent3 = (
  <Trans id="accreditation.modal.content3">
    Please review the third-party website’s privacy and security policies, as they may differ from
    those of our website and may provide less security than this website.
  </Trans>
);

export const AccreditationModalContent4 = (
  <Trans id="accreditation.modal.content4">
    Click OK below to proceed to the third-party website”.
  </Trans>
);

export const VerifyInvestorModalCancelBtnTitle = (
  <Trans id="verifyInvestor.modal.btn.cancel.title">Cancel</Trans>
);
export const VerifyInvestorModalTitle = <Trans id="verifyInvestor.modal.title">InvestReady</Trans>;

export const VerifyInvestorModalLoadErrorText = (
  <Trans id="verifyInvestor.modal.load.error.text">
    Our link to InvestReady is unavailable right now. We apologize for the inconvenience, please try
    again later.
  </Trans>
);
