import * as React from "react";
import { Trans } from "@lingui/react";

interface TextRowInfoProps {
  label: string;
  className?: string;
  children?: JSX.Element | string;
}

const TextRowInfo = ({ label, className = "", children }: TextRowInfoProps) => {
  return (
    <div className={`ben-horisontal ben-text-row ${className}`}>
      <label>
        <Trans id={label} />
      </label>
      <span className="ben-text-row-text">{children}</span>
    </div>
  );
};

export default TextRowInfo;
