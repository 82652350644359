import { BreadcrumbItem } from "common/shared/interfaces/breadcrumb";
import { BreadcrumbDashboardTitle } from "common/shared/constants/breadcrumb";
import { Urls } from "common/lib/constants";

export const getLiquidityDetailsBreadcrumbItems = ({
  displayName,
  accountId,
  requestId,
}: {
  displayName: string | null;
  accountId: string;
  requestId: string | React.ReactElement;
}): BreadcrumbItem[] => {
  const columns = [
    {
      id: "dashboard",
      title: BreadcrumbDashboardTitle,
      path: Urls.PROTECTED.DASHBOARD,
    },
    {
      id: "client",
      title: displayName || accountId,
      path: `${Urls.PROTECTED.DASHBOARD}${Urls.PROTECTED.CLIENTS}/${accountId}`,
    },
    {
      id: "request",
      title: requestId,
      path: "",
    },
  ];

  return columns;
};
