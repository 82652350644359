import React, { Fragment, useState, useCallback } from "react";
import { Trans } from "@lingui/react";
import { Button, Modal } from "antd";

import { AgreementContent } from "domains/authentication/components";

import { PropsAgreementModal } from "./types";

const agreementModalTitle = <Trans id="Agreement.Modal.title">CONFIDENTIALITY AGREEMENT</Trans>;

const AgreementModal: React.FC<PropsAgreementModal> = (props): React.ReactElement => {
  const {
    isAgreementModalVisible,
    handleAgreementModalBtn,
    handleAgreementModalVisibility,
  } = props;

  const [isAgreeDisabled, setAgreeDisabled] = useState<boolean>(true);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLElement>) => {
      if (!isAgreeDisabled) {
        return;
      }

      const showBeforePx = 42;
      const isBottom =
        e.currentTarget.scrollTop + e.currentTarget.clientHeight >=
        e.currentTarget.scrollHeight - showBeforePx;

      if (!isBottom) {
        return;
      }
      setAgreeDisabled(false);
    },
    [isAgreeDisabled]
  );

  return (
    <Modal
      centered={true}
      title={agreementModalTitle}
      visible={isAgreementModalVisible}
      width={"100%"}
      style={{
        maxWidth: "620px",
      }}
      wrapClassName="ben-modal-container"
      footer={
        <Fragment>
          <Button id={"modelCancelBtn"} onClick={handleAgreementModalBtn(false)}>
            <Trans id="Agreement.Modal.Cancel">Cancel</Trans>
          </Button>
          <Button
            id={"termsAgreeBtn"}
            type="primary"
            disabled={isAgreeDisabled}
            onClick={handleAgreementModalBtn(true)}
          >
            <Trans id="Agreement.Modal.Agree">Agree</Trans>
          </Button>
        </Fragment>
      }
      onCancel={handleAgreementModalVisibility(false)}
    >
      <div className="terms-agreement-modal-body" onScroll={handleScroll}>
        <AgreementContent />
      </div>
    </Modal>
  );
};

export default AgreementModal;
