import React from "react";

import Spinner from "../spinner";

import { PropsBenCardContent } from "./types";
import "./ben-card-content.scss";

const BenCardContent: React.FC<PropsBenCardContent> = (props): React.ReactElement | null => {
  const { children, title, className, isLoading, titleElements } = props;

  return (
    <section className={`ben-card-content ben-mb-3 ${className || ""}`}>
      {title && (
        <div className="ben-header ben-pb-2">
          <h3>{title}</h3>
          {titleElements ? (
            <div className="ben-card-content-title-elements">
              {titleElements.map((item: React.ReactElement | null) => {
                return item;
              })}
            </div>
          ) : null}
        </div>
      )}

      <div className="ben-card-main"> {isLoading ? <Spinner /> : children}</div>
    </section>
  );
};

export default BenCardContent;
