import React, { useCallback, useEffect, useState } from "react";
import { Collapse } from "antd";

import { BenRowData } from "common/lib/components";

import { CategorizedDocuments } from "domains/document/components";

import { PropsLiquidityAssetInfo } from "./types";
import "./liquidity-asset-info.scss";

const { Panel } = Collapse;
const currentCollapseKey = "1";

const LiquidityAssetInfo: React.FC<PropsLiquidityAssetInfo> = (
  props
): React.ReactElement | null => {
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const {
    toggleActiveAssetCollapse,
    activeAssetId,
    holdingData,
    currentUserId,
    userDisplayId,
    currentUserFullName,
    currentUserIp,
    locale,
  } = props;

  const { fundName, sponsor, assetId } = holdingData;

  const toggleCollapse = useCallback(
    () => setActiveKey(activeKey.length === 0 ? [currentCollapseKey] : []),
    [activeKey]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!activeAssetId || !activeKey.length || activeAssetId === assetId) {
      return;
    }

    toggleCollapse();
  }, [activeAssetId]);

  const toggleCallback = useCallback(() => {
    toggleCollapse();
    toggleActiveAssetCollapse(assetId);
  }, [assetId, toggleCollapse, toggleActiveAssetCollapse]);

  return (
    <Collapse
      activeKey={activeKey}
      onChange={toggleCallback}
      className="liquidity-asset-info collapse"
    >
      <Panel
        header={<BenRowData title={fundName} info={sponsor} withBottomBorder={false} />}
        showArrow={false}
        key={currentCollapseKey}
      >
        {holdingData ? (
          <CategorizedDocuments
            holdingData={holdingData}
            currentUserId={currentUserId}
            userDisplayId={userDisplayId}
            currentUserFullName={currentUserFullName}
            currentUserIp={currentUserIp}
            locale={locale}
          />
        ) : null}
      </Panel>
    </Collapse>
  );
};

export default LiquidityAssetInfo;
