import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Spinner } from "common/lib/components";

import { ArrayUtils } from "common/shared/utils";
import { portalEnvConstants, sessionService } from "common/shared";
import { actions$ } from "common/shared-store/actions/actionsStream";

import { DocumentItemData, CreateDocumentResponseData } from "domains/document/shared/types";
import {
  ActionTypes,
  getDocumentUrl,
  refreshHoldingDocuments,
} from "domains/document/store/document.actions";

import { AddDocument } from "domains/document/components";
import {
  StandardUploadedHoldingDocuments,
  OtherHoldingDocuments,
  StandardHoldingDocuments,
} from "domains/document/documents-list";

import { PropsHoldingDocuments } from "./types";
import "./holding-documents.scss";
import {
  HeaderUploadDocumentsList,
  HeaderViewDocumentsList,
  HeaderEmptyDocumentsList,
} from "./constants";

// TODO: BCP-1073: remove this component, we don't need it.
// TODO: BCP-1073: check all nested components.
const HoldingDocuments: React.FC<PropsHoldingDocuments> = (props): React.ReactElement | null => {
  const {
    holding,
    showTitle,
    currentUserId,
    userDisplayId,
    currentUserFullName,
    currentUserIp,
    locale,
  } = props;
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isDocUploading, setDocUploading] = useState<boolean>(false);
  const [otherDocuments, setOtherDocuments] = useState<DocumentItemData[]>(holding.otherDocuments);

  const dispatch = useDispatch();

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.GetDocumentUrlFail, ActionTypes.GetDocumentUrlSuccess])
        .subscribe(() => {
          setLoading(false);
        })
    );

    subscriptions.push(
      actions$.ofType(ActionTypes.SetIdontHaveThis).subscribe(() => {
        setLoading(true);
        setDocUploading(true);
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const handleDownload = useCallback(
    (document: DocumentItemData) => () => {
      setLoading(true);

      const params = {
        accountId: holding.accountId,
        documentId: document.id,
        currentUserId,
        userDisplayId,
        currentUserFullName,
        currentUserIp,
        locale,
        timezone: portalEnvConstants.defaultTimezone,
        version: "latest",
      };
      dispatch(getDocumentUrl(params));
    },
    [dispatch, holding, currentUserId, userDisplayId, currentUserFullName, currentUserIp, locale]
  );

  const handleUploadSuccess = ({ documentName, documentId }: CreateDocumentResponseData) => {
    setDocUploading(false);

    if (documentId && documentName) {
      setOtherDocuments([
        ...otherDocuments,
        {
          referenceType: "investment",
          documentType: "other",
          documentName,
          id: documentId,
          investmentId: holding.investmentId,
          assetId: holding.investmentId,
          liquidityRequestId: "",
          dateProvided: "",
          periodEndingDate: "",
          doNotHaveThis: false,
          source: "client_portal",
          displayId: "",
        },
      ]);
    }
  };

  const onUploadSuccess = () => {
    setDocUploading(false);
    dispatch(refreshHoldingDocuments());
  };

  const onUploadStart = () => {
    setDocUploading(true);
  };

  const onUploadCancel = () => {
    setDocUploading(false);
  };

  const onUploadError = () => {
    setDocUploading(false);
    setLoading(false);
  };

  const onUploadAddDocumentError = () => {
    setDocUploading(false);
  };

  const getDownloadHandler = (standardDoc: DocumentItemData) => {
    if (!standardDoc.doNotHaveThis) {
      return handleDownload(standardDoc);
    }
  };

  const isStandardUploadedDocumentsExist = ArrayUtils.hasTruthyArrayData(
    Object.values(holding.standardDocuments)
  );
  const isAllStandardUploadedDocumentsExist = ArrayUtils.hasAllTruthyArrayData(
    Object.values(holding.standardDocuments)
  );
  const isOtherHoldingDocumentsExist = ArrayUtils.isValidArrayData(otherDocuments);

  return (
    <div className="ben-holding-content ben-mb-4">
      {isLoading && (
        <div className="ben-holding-backdrop">
          <Spinner />
        </div>
      )}

      {showTitle && holding.fundName ? (
        <h4 className="holding-fund-name">{holding.fundName}</h4>
      ) : null}

      <div>
        {isAllStandardUploadedDocumentsExist ? null : (
          <h4 id="headerUploadDocumentsList">{HeaderUploadDocumentsList}</h4>
        )}

        <StandardHoldingDocuments
          holding={holding}
          onUploadStart={onUploadStart}
          onUploadSuccess={onUploadSuccess}
          onUploadError={onUploadError}
          onUploadCancel={onUploadCancel}
          disableActions={!!sessionService.isAppian}
          disableUpload={sessionService.isAppian || isDocUploading}
          disableDontHaveIcon={sessionService.isAppian || isDocUploading}
        />

        <h4
          id="headerUploadedDocumentsList"
          className={`${isAllStandardUploadedDocumentsExist ? "" : "ben-pt-2"}`}
        >
          {isStandardUploadedDocumentsExist || isOtherHoldingDocumentsExist
            ? HeaderViewDocumentsList
            : HeaderEmptyDocumentsList}
        </h4>

        <StandardUploadedHoldingDocuments
          holding={holding}
          onUploadStart={onUploadStart}
          onUploadSuccess={onUploadSuccess}
          onUploadCancel={onUploadCancel}
          onUploadError={onUploadError}
          disableUpload={sessionService.isAppian || isDocUploading}
          disableDontHaveIcon={sessionService.isAppian || isDocUploading}
          getDownloadHandler={getDownloadHandler}
        />

        <OtherHoldingDocuments
          otherDocuments={otherDocuments}
          handleDownload={handleDownload}
          disableUpload={sessionService.isAppian || isDocUploading}
          disableDontHaveIcon={sessionService.isAppian || isDocUploading}
          onUploadStart={onUploadStart}
          onUploadSuccess={onUploadSuccess}
          onUploadCancel={onUploadCancel}
          onUploadError={onUploadError}
          accountId={holding.accountId}
          liquidityRequestId={holding.liquidityRequestId}
        />

        <AddDocument
          onUploadStart={onUploadStart}
          onUploadSuccess={handleUploadSuccess}
          onUploadCancel={onUploadCancel}
          onUploadError={onUploadAddDocumentError}
          accountId={holding.accountId}
          investmentId={holding.investmentId}
          assetId={holding.assetId}
          liquidityRequestId={holding.liquidityRequestId}
          disableUpload={sessionService.isAppian || isDocUploading}
          referenceType="investment"
        />
      </div>
    </div>
  );
};

export default HoldingDocuments;
