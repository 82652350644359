import React from "react";
import { Icon, Spin } from "antd";
import "./spinner.scss";

const Spinner = ({
  spinning = true,
  children = null,
  className,
}: {
  spinning?: boolean;
  children?: JSX.Element | null;
  className?: string;
}) => {
  const antIcon = <Icon type="loading" style={{ fontSize: 24 }} />;

  return (
    <Spin
      indicator={antIcon}
      style={{ width: "100%" }}
      spinning={spinning}
      className={className || ""}
    >
      {children}
    </Spin>
  );
};

export default Spinner;
