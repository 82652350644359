import {
  ActionTypes,
  createLiquidityRequest,
  createLiquiditySharing,
  createSharingAcceptance,
  getLiquidities,
} from "./store/liquidity.actions";

import {
  getLiquidityEpic,
  getLiquiditiesEpic,
  createLiquidityRequestEpic,
  createLiquiditySharingEpic,
  getLiquiditySharingsEpic,
  updateLiquiditySharingEpic,
  resendSharingInvitationEpic,
  createSharingAcceptanceEpic,
  getClientLiquiditiesAlertsEpic,
  getSingleLiquidityAlertsEpic,
  readSingleLiquidityAlertEpic,
  addHoldingsEpic,
} from "./store/liquidity.epics";
import {
  LiquidityBtnNew,
  AssetCard,
  HoldingsSection,
  LiquidityRequestDetails,
  ResendSharingInvitation,
  LiquidityRequestList,
  InvitesList,
} from "./components";
import {
  ParamsUpdateLiquiditySharing,
  ParamsCreateSharingAcceptance,
  LiquidityRequestAlertItem,
  LiquidityRequestAlertsResponseData,
} from "./shared/types";
import {
  useLiquiditiesListData,
  useLiquidityDetailsData,
  useLiquiditiesCreationData,
  useLiquidityHoldingsData,
} from "./hooks";
import { AddHoldingForm } from "./forms";

// Can be removed after removing acceptance on the LR details page
export interface AcceptRequestSharingData extends ParamsUpdateLiquiditySharing {}

export interface AcceptRequestSharingInvitationData extends ParamsCreateSharingAcceptance {}
export interface TypeLiquidityRequestAlertItem extends LiquidityRequestAlertItem {}
export interface TypeAlertsResponseData extends LiquidityRequestAlertsResponseData {}
export {
  ActionTypes,
  createLiquidityRequest,
  createLiquiditySharing,
  getLiquidities,
  LiquidityRequestList,
  LiquidityBtnNew,
  LiquidityRequestDetails,
  getLiquidityEpic,
  getLiquiditiesEpic,
  createLiquidityRequestEpic,
  createLiquiditySharingEpic,
  AssetCard,
  InvitesList,
  getLiquiditySharingsEpic,
  updateLiquiditySharingEpic,
  ResendSharingInvitation,
  resendSharingInvitationEpic,
  getClientLiquiditiesAlertsEpic,
  createSharingAcceptanceEpic,
  createSharingAcceptance,
  getSingleLiquidityAlertsEpic,
  readSingleLiquidityAlertEpic,
  useLiquiditiesListData,
  useLiquidityDetailsData,
  useLiquiditiesCreationData,
  useLiquidityHoldingsData,
  HoldingsSection,
  AddHoldingForm,
  addHoldingsEpic,
};
