import React from "react";
import { AutoComplete, Radio, Select } from "antd";
import { OptionProps } from "antd/lib/select";

import { localeService } from "common/shared";
import { Constants } from "common/lib/constants";

export const accountTypes = () =>
  Constants.ACCOUNT_TYPES_ARRAY.map(
    ({ title, value }): JSX.Element => (
      <Select.Option key={value}>{localeService.i18n._(title)}</Select.Option>
    )
  );

export const accountTypesRadio = () =>
  Constants.ACCOUNT_TYPES_ARRAY.map(
    ({ title, value }): JSX.Element => (
      <Radio key={value} value={value}>
        {localeService.i18n._(title)}
      </Radio>
    )
  );

export const externalAccountTypesRadio = () =>
  Constants.EXTERNAL_ACCOUNT_TYPES_ARRAY.map(
    ({ title, value }): JSX.Element => (
      <Radio key={value} value={value}>
        {localeService.i18n._(title)}
      </Radio>
    )
  );

export const countries = () =>
  Constants.COUNTRIES.map(
    (country): JSX.Element => (
      <AutoComplete.Option key={country.code}>
        {localeService.i18n._(country.name)}
      </AutoComplete.Option>
    )
  );

export const countriesCodes = () =>
  Constants.COUNTRIES_CODES.map(
    (countryCode): JSX.Element => {
      return (
        <Select.Option key={countryCode.name} value={countryCode.code}>
          {localeService.i18n._(countryCode.name)}
        </Select.Option>
      );
    }
  );

export const phoneTypes = () =>
  Constants.PHONE_TYPES.map(
    ({ title, value }): JSX.Element => (
      <Select.Option key={value}>{localeService.i18n._(title)}</Select.Option>
    )
  );

export const timeZones = () =>
  Constants.TIME_ZONES.map(
    (timeZone): JSX.Element => (
      <Select.Option key={timeZone.value} value={timeZone.value}>
        {localeService.i18n._(timeZone.title)}
      </Select.Option>
    )
  );

export const usaStates = Constants.USA_STATES.map(
  (key: string): JSX.Element => (
    <Select.Option key={key} value={key}>
      {key}
    </Select.Option>
  )
);

export const filterByStartsWith = (
  input: string,
  option: React.ReactElement<OptionProps>
): boolean => {
  const title: string = option.props.children ? option.props.children.toString().toUpperCase() : "";
  return title.startsWith(input.toUpperCase());
};

export const filterByIncludes = (
  input: string,
  option: React.ReactElement<OptionProps>
): boolean => {
  const title: string = option.props.children ? option.props.children.toString().toUpperCase() : "";
  return title.indexOf(input.toUpperCase()) > -1;
};
