import { useSelector } from "react-redux";
import { Bootstrap } from "common/shared-store/reducers/bootstrap";

interface BootstrapTokenForPassData {
  tokenForPass: string | null;
  isTokenForPassCompletedRequest: boolean;
  isTokenForPassSuccess: boolean;
  isTokenForPassWasUsed: boolean;
}

function useBootstrapTokenForPassData(): BootstrapTokenForPassData {
  const result = useSelector(
    (state: any): BootstrapTokenForPassData => {
      const bootstrapState: Bootstrap = state.bootstrap;
      const {
        tokenForPass,
        isTokenForPassCompletedRequest,
        isTokenForPassSuccess,
        isTokenForPassWasUsed,
      } = bootstrapState;

      return {
        tokenForPass,
        isTokenForPassCompletedRequest,
        isTokenForPassSuccess,
        isTokenForPassWasUsed,
      };
    }
  );

  return result;
}

export default useBootstrapTokenForPassData;
