import React, { useState, Fragment, useEffect } from "react";
import { navigate } from "@reach/router";
import { Trans } from "@lingui/macro";

import { Urls } from "common/lib/constants";
import { CardErrorSection, BenPhone } from "common/lib/components";
import { sessionService } from "common/shared";
import { error500Store } from "common/shared-store";

import image from "common/assets/images/505-error.svg";

const title = <Trans id="page500.title">Oops something went wrong</Trans>;
const btnText = <Trans id="page500.btnText">Return to Dashboard</Trans>;

const Error500 = () => {
  const [token] = useState(sessionService.getTokens());
  // only for autotest
  const error500Data = error500Store.getError();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    return () => {
      error500Store.setError(null);
    };
  }, []);

  const handleRedirectTo = () => {
    navigate(Urls.PROTECTED.BASE);
  };

  return (
    <Fragment>
      <CardErrorSection
        title={title}
        message={<Trans id="page500.message" values={{ phoneNumber: <BenPhone /> }} />}
        loading={false}
        handleOnClick={handleRedirectTo}
        btnText={btnText}
        image={image}
        noPanel={!token}
      />
      {/* only for autotest, is hidden on UI */}
      {error500Data && (
        <div className="ben-d-none">
          <span id="benRequestUrlError">{error500Data.url}</span>
          <span id="benRequestMethodError">{error500Data.method}</span>
          <span id="benRequestDataError">{error500Data.data}</span>
        </div>
      )}
    </Fragment>
  );
};

export default Error500;
