import { ActionTypes } from "./document.actions";
import { DocumentStore } from "../shared/types";

export const initialState: DocumentStore = {
  transactionDocument: {
    data: {
      items: [],
      page: {
        index: 0,
        size: 0,
      },
      total: 0,
    },
    loading: true,
  },
  holdingDocuments: {
    data: {
      items: [],
      page: {
        index: 0,
        size: 0,
      },
      total: 0,
    },
    loading: true,
  },
  uncategorizedDocuments: {
    data: {
      items: [],
      page: {
        index: 0,
        size: 0,
      },
      total: 0,
    },
    loading: true,
  },
};

export const document = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.GetTransactionDocuments:
      return {
        ...state,
        transactionDocument: {
          data: {
            ...state.transactionDocument.data,
          },
          loading: true,
        },
      };
    case ActionTypes.GetTransactionDocumentsSuccess:
      return {
        ...state,
        transactionDocument: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.GetTransactionDocumentsFail:
      return {
        ...state,
        transactionDocument: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.AddTransactionDocument:
      return {
        ...state,
        transactionDocument: {
          data: {
            ...state.transactionDocument.data,
            items: [...state.transactionDocument.data.items, { ...action.payload.newItem }],
            total: state.transactionDocument.data.total + 1,
          },
          loading: false,
        },
      };

    case ActionTypes.GetDocumentsByHolding:
      return {
        ...state,
        holdingDocuments: {
          data: {
            ...state.holdingDocuments.data,
          },
          loading: true,
        },
      };
    case ActionTypes.GetDocumentsByHoldingSuccess:
      return {
        ...state,
        holdingDocuments: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.GetDocumentsByHoldingFail:
      return {
        ...state,
        holdingDocuments: {
          ...state.holdingDocuments,
          loading: false,
        },
      };
    case ActionTypes.GetUncategorizedDocuments:
      return {
        ...state,
        uncategorizedDocuments: {
          data: {
            items: [],
            page: {
              index: 0,
              size: 0,
            },
            total: 0,
          },
          loading: true,
        },
      };
    case ActionTypes.GetUncategorizedDocumentsSuccess:
      return {
        ...state,
        uncategorizedDocuments: {
          data: { ...action.payload },
          loading: false,
        },
      };
    case ActionTypes.GetUncategorizedDocumentsFail:
      return {
        ...state,
        uncategorizedDocuments: {
          data: { ...action.payload },
          loading: false,
        },
      };

    default:
      return state;
  }
};
