import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Form, Button, Row, Col } from "antd";
import { Trans } from "@lingui/macro";

import { BenFormRowInfo } from "common/lib/components";
import { Constants } from "common/lib/constants";
import { sessionService } from "common/shared";
import { useForm } from "common/shared-hooks";

import ClientsApiParser from "../../shared/clients.api.parser";
import {
  AddressForm,
  AddressFormSchema,
  addressValidationSchema,
  impersonateAddressDisabledConfig,
  PersonalForm,
  PersonalFormSchema,
  personalValidationSchema,
  impersonatePersonalDisabledConfig,
  PhoneFormSchema,
  phoneValidationSchema,
  impersonatePhoneDisabledConfig,
  phoneValidationSchemaDeps,
} from "domains/clients/forms";

import { addClient } from "domains/clients/store/clients.actions";

interface IndividualFormProps {
  handleCancel: () => void;
}

const IndividualForm: React.FC<IndividualFormProps> = (props): React.ReactElement => {
  const { handleCancel } = props;
  const dispatch = useDispatch();

  // address form initialisation
  const addressFormDisableConfig =
    (sessionService.isAppian && impersonateAddressDisabledConfig) || {};
  const addressFormSchema = new AddressFormSchema(null, addressFormDisableConfig);
  let addressData: any = null;
  const getAddressData = (data: any) => {
    addressData = { ...data };
  };
  const addressFormConfig = useForm(addressFormSchema, addressValidationSchema, getAddressData);

  // personal form initialisation
  const personalFormDisableConfig =
    (sessionService.isAppian && impersonatePersonalDisabledConfig) || {};
  const personalFormSchema = new PersonalFormSchema(null, personalFormDisableConfig);
  let personalData: any = null;
  const getPersonalData = (data: any) => {
    personalData = { ...data };
  };
  const personalFormConfig = useForm(personalFormSchema, personalValidationSchema, getPersonalData);

  // phone form initialisation
  const phoneFormDisableConfig = (sessionService.isAppian && impersonatePhoneDisabledConfig) || {};
  const phoneFormSchema = new PhoneFormSchema(null, phoneFormDisableConfig);
  let phoneData: any = null;
  const getPhoneData = (data: any) => {
    phoneData = { ...data };
  };
  const phoneFormConfig = useForm(
    phoneFormSchema,
    phoneValidationSchema,
    getPhoneData,
    phoneValidationSchemaDeps
  );

  const submit = () => {
    if (sessionService.isAppian) {
      return;
    }

    if (
      !addressFormConfig.isDisabledSubmit() &&
      !personalFormConfig.isDisabledSubmit() &&
      !phoneFormConfig.isDisabledSubmit()
    ) {
      addressFormConfig.handleOnSubmit();
      personalFormConfig.handleOnSubmit();
      phoneFormConfig.handleOnSubmit();
      const account = ClientsApiParser.prepareBackendLikeIndividual({
        ...personalData,
        clientType: Constants.ACCOUNT_TYPES.INDIVIDUAL,
      });
      const user = ClientsApiParser.prepareIndividualUserData({
        ...addressData,
        ...personalData,
        ...phoneData,
      });
      dispatch(addClient({ ...account, user }));
    }
  };

  const isGeneralFormValidationShown = () => {
    return (
      (personalFormConfig.isDirty && personalFormConfig.hasDirtyInvalidField()) ||
      (addressFormConfig.isDirty && addressFormConfig.hasDirtyInvalidField()) ||
      (phoneFormConfig.isDirty && phoneFormConfig.hasDirtyInvalidField())
    );
  };

  // Form should be 9 inputs height. Each input is 65px. 9 * 65 + 1px= 586px

  return (
    <Fragment>
      <BenFormRowInfo
        type="validation"
        classNames={
          `ben-form-top-validation ` +
          (isGeneralFormValidationShown() ? "ben-form-top-validation-show" : "")
        }
      />

      <div className="ben-form-holder">
        <Form>
          <Row gutter={30} type="flex" className="ben-pb-2">
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <PersonalForm config={personalFormConfig} />
            </Col>
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <AddressForm config={addressFormConfig} />
              {/* <PhoneForm config={phoneFormConfig} /> */}
            </Col>
          </Row>
        </Form>
      </div>

      <Row gutter={30} className="ben-form-actions">
        <Col md={{ span: 12 }} xs={{ span: 24 }} className="ben-pb-2">
          <Button
            id="benIndividualFormBtnCancel"
            type="primary"
            shape="round"
            size="large"
            ghost={true}
            className="ben-w-100"
            onClick={handleCancel}
          >
            <Trans id="actionButtons.cancel">Cancel</Trans>
          </Button>
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 24 }} className="ben-pb-2">
          <Button
            id="benIndividualFormBtnSubmit"
            type="primary"
            shape="round"
            size="large"
            className="ben-w-100"
            disabled={
              sessionService.isAppian ||
              personalFormConfig.isDisabledSubmit() ||
              addressFormConfig.isDisabledSubmit() ||
              phoneFormConfig.isDisabledSubmit()
            }
            onClick={submit}
          >
            <Trans id="actionButtons.submit">Submit</Trans>
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default IndividualForm;
