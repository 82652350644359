import React from "react";
import { Trans } from "@lingui/macro";

import {
  TypeLiquidityStatusCode,
  TypeLiquidityTypeCode,
  LiquidityRequestStatusesSetting,
  LiquidityRequestDisplaySetting,
  TypeLiquidityGroup,
  TypeInvitedObject,
  TypeLiquidityRequestAlertType,
  LiquidityRequestAlertTypeDisplaySetting,
  TypeLiquidityRequestDisplayWithNameMap,
} from "./types";

export const LiquidityRequestStatusesMap: {
  [key in TypeLiquidityStatusCode]: LiquidityRequestStatusesSetting;
} = {
  // In Review
  in_verification: {
    type: "in_verification",
    group: "active",
  },
  // In Underwriting
  in_valuation: {
    type: "in_underwriting",
    group: "active",
  },
  in_pricing: {
    type: "in_underwriting",
    group: "active",
  },
  in_commitment: {
    type: "in_underwriting",
    group: "active",
  },
  in_proposal_pending: {
    type: "in_underwriting",
    group: "active",
  },
  ben_rejected_lr_pending: {
    type: "in_underwriting",
    group: "active",
  },
  // In Proposal
  in_proposal: {
    type: "in_proposal",
    group: "active",
  },
  // Client Signed
  client_signed: {
    type: "client_signed",
    group: "active",
  },
  in_counter_signatures: {
    type: "client_signed",
    group: "active",
  },
  counter_signed: {
    type: "client_signed",
    group: "active",
  },
  in_bd_signatures: {
    type: "client_signed",
    group: "active",
  },
  bd_signed: {
    type: "client_signed",
    group: "active",
  },
  // Cancelled
  client_rejected_offering: {
    type: "cancelled",
    group: "archived",
  },
  ben_rejected_lr: {
    type: "cancelled",
    group: "archived",
  },
  ben_rejected_client: {
    type: "cancelled",
    group: "archived",
  },
  // Closed
  closed: {
    type: "closed",
    group: "closed",
  },
};

export const LiquidityRequestGroupDefault = "archived";

export const LiquidityRequestDisplayMap: {
  [key in TypeLiquidityTypeCode | "fallback"]: LiquidityRequestDisplaySetting;
} = {
  in_verification: {
    step: 1,
  },
  in_underwriting: {
    step: 2,
  },
  in_proposal: {
    step: 3,
  },
  client_signed: {
    step: 4,
  },
  closed: {
    step: 5,
  },
  cancelled: {
    step: 0,
  },

  fallback: {
    step: 0,
  },
};

export const LiquidityRequestDisplayWithNameMap: TypeLiquidityRequestDisplayWithNameMap = {
  base: {
    in_verification: {
      title: <Trans id="liquidityRequest.in_verification.title">In Review</Trans>,
      step: 1,
      code: "in_verification",
    },
    in_underwriting: {
      title: <Trans id="liquidityRequest.in_underwriting.title">In Underwriting</Trans>,
      step: 2,
      code: "in_underwriting",
    },
    in_proposal: {
      title: <Trans id="liquidityRequest.proposal.title">In Proposal</Trans>,
      step: 3,
      code: "in_proposal",
    },
    client_signed: {
      title: <Trans id="liquidityRequest.client_signed.title">Client Signed</Trans>,
      step: 4,
      code: "client_signed",
    },
  },
  final: {
    closed: {
      title: <Trans id="liquidityRequest.closed.title">Closed</Trans>,
      step: 5,
      code: "closed",
    },
    cancelled: {
      title: <Trans id="liquidityRequest.cancelled.title">Cancelled</Trans>,
      step: 5,
      code: "cancelled",
    },
  },
  fallback: {
    title: <Trans id="liquidityRequest.fallback.title">No status assigned</Trans>,
    step: 0,
    code: null,
  },
};

export const LiquidityRequestGroupTitlesMap: {
  [key in TypeLiquidityGroup]: React.ReactElement;
} = {
  active: <Trans id="liquidityRequestList.group.active.title">Active Liquidity Requests</Trans>,
  archived: (
    <Trans id="liquidityRequestList.group.archived.title">Archived Liquidity Requests</Trans>
  ),
  closed: <Trans id="liquidityRequestList.group.closed.title">Closed Liquidity Requests</Trans>,
};

export const LiquidityRequestDisplayNamePrefix = (
  <Trans id="liquidityRequest.displayNamePrefix">LR</Trans>
);

export const InvitedObjectsTitlesMap: {
  [key in TypeInvitedObject]: React.ReactElement;
} = {
  you: <Trans id="invitedObjects.you.title">You</Trans>,
  client: <Trans id="invitedObjects.client.title">Client</Trans>,
  advisor: <Trans id="invitedObjects.advisor.title">Advisor</Trans>,
};

export const LiquidityRequestAlertTypeDisplayMap: {
  [key in TypeLiquidityRequestAlertType | "fallback"]: LiquidityRequestAlertTypeDisplaySetting;
} = {
  additional_documents_requested: {
    title: (
      <Trans id="liquidityRequest.alert.additional_documents_requested.title">
        Additional documents appreciated.
      </Trans>
    ),
  },
  deal_documents_available_for_review: {
    title: (
      <Trans id="liquidityRequest.alert.deal_documents_available_for_review.title">
        Deal Documents are available for review.
      </Trans>
    ),
  },
  new_deal_documents_available_for_review: {
    title: (
      <Trans id="liquidityRequest.alert.new_deal_documents_available_for_review.title">
        New Deal Documents are available for review.
      </Trans>
    ),
  },
  lr_submission_key_documents: {
    title: (
      <Trans id="liquidityRequest.alert.lr_submission_key_documents.title">
        Please provide the key documents outlined below.
      </Trans>
    ),
  },
  new_term_sheet_is_available_for_review: {
    title: (
      <Trans id="liquidityRequest.alert.new_term_sheet_is_available_for_review.title">
        New Term Sheet is available for review.
      </Trans>
    ),
  },
  term_sheet_is_available_for_review: {
    title: (
      <Trans id="liquidityRequest.alert.term_sheet_is_available_for_review.title">
        Term Sheet is available for review.
      </Trans>
    ),
  },

  fallback: {
    title: <Trans id="liquidityRequest.alert.fallback.title">Cannot decode a message</Trans>,
  },
};
