export default class FormInput {
  value: any;
  isDirty: boolean;
  isDisabled?: boolean;
  errors?: any;

  constructor({ value = "", isDirty = false, isDisabled = false } = {}) {
    this.value = value;
    this.isDirty = isDirty;
    this.isDisabled = isDisabled;
  }
}
