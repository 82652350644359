import React from "react";
import { Trans } from "@lingui/react";

export const HeaderEmptyDocumentsList = (
  <Trans id="categorizedDocuments.list.empty.title">
    Documents for this holding have not yet been categorized.
  </Trans>
);
export const HeaderDocumentTitle = (
  <Trans id="categorizedDocuments.list.header.document">Documents</Trans>
);
export const HeaderDateTitle = <Trans id="categorizedDocuments.list.header.date">As Of Date</Trans>;
