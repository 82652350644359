import React, { Fragment } from "react";

import { TextRowInfo, BenCombinedData } from "common/lib/components";

import User from "domains/clients/store/user.model";
import Institution from "domains/clients/store/institution.model";
import { TypeClientCombinedItem } from "domains/clients/shared/types";
import clientsService from "domains/clients/shared/clients.service";

interface PropsAddressInfo {
  user: User | Institution;
  placeholder: string;
  isShowCombinedAddress: boolean;
}

const AddressInfo: React.FC<PropsAddressInfo> = (props): React.ReactElement | null => {
  const { user, placeholder, isShowCombinedAddress } = props;

  const countryName = clientsService.getCountryNameFromUser(user);
  const addressCombinedData: TypeClientCombinedItem[] = clientsService.getAddressCombinedData(user);

  if (isShowCombinedAddress) {
    return (
      <TextRowInfo label="profile.common.address">
        <BenCombinedData items={addressCombinedData} />
      </TextRowInfo>
    );
  }
  return (
    <Fragment>
      {/* <TextRowInfo label="profile.contact.street1">{user.street1 || placeholder}</TextRowInfo>
      <TextRowInfo label="profile.contact.street2">{user.street2 || placeholder}</TextRowInfo>
      <TextRowInfo label="profile.contact.city">{user.city || placeholder}</TextRowInfo>
      <TextRowInfo label="profile.contact.state">{user.region || placeholder}</TextRowInfo>
      <TextRowInfo label="profile.contact.zip">{user.postalCode || placeholder}</TextRowInfo> */}
      <TextRowInfo label="profile.contact.country">{countryName || placeholder}</TextRowInfo>
    </Fragment>
  );
};

export default AddressInfo;
