import React, { Fragment } from "react";

import { DateUtils } from "common/shared/utils";

import { STANDARD_INVESTMENT_DOCUMENT_LIST } from "domains/document/shared/constants";
import { DocumentItemData, TypeDocumentType } from "domains/document/shared/types";
import documentService from "domains/document/shared/document.service";
import { SimpleDocument } from "domains/document/components";

import { PropsStandardCategorizedHoldingDocuments } from "./types";

const StandardCategorizedHoldingDocuments: React.FC<PropsStandardCategorizedHoldingDocuments> = (
  props
): React.ReactElement | null => {
  const { standardDocuments, handleDownload, lang } = props;

  return (
    <Fragment>
      {STANDARD_INVESTMENT_DOCUMENT_LIST.map((docType: TypeDocumentType) => {
        const standardDoc: DocumentItemData[] = standardDocuments[docType] as any;
        if (standardDoc) {
          const { typeSettings: docSettings } = documentService.getDisplaySettingsByType(docType);
          return standardDoc.map((stdDoc) => {
            return (
              <SimpleDocument
                key={docType + stdDoc.displayId}
                uniqueBtnId={docType}
                title={docSettings.title}
                date={DateUtils.getFormatedDateWithoutTimeZone(stdDoc.periodEndingDate, lang)}
                handleDownload={handleDownload(stdDoc.id)}
              />
            );
          });
        }

        return null;
      })}
    </Fragment>
  );
};

export default StandardCategorizedHoldingDocuments;
