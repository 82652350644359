import { useSelector, useDispatch } from "react-redux";

import User from "../store/user.model";
import { getProfile } from "domains/clients/store/clients.actions";

interface ProfileDataProps {
  user: User;
  loading: boolean;
  isCompletedUserRequest: boolean;
  getProfileAttempts?: number;
  currentUserIp: string | null;
}

function useProfileData(): ProfileDataProps {
  const profileData = useSelector(
    (state: any): ProfileDataProps => {
      return state.clients;
    }
  );

  const dispatch = useDispatch();
  const { user, loading, getProfileAttempts, isCompletedUserRequest, currentUserIp } = profileData;
  if (!profileData || (!user && !loading)) {
    if (getProfileAttempts && getProfileAttempts > 5) {
      return { user: new User({}), loading: false, isCompletedUserRequest, currentUserIp };
    }

    dispatch(getProfile());
  }

  return { ...profileData };
}

export default useProfileData;
