import React from "react";

import { Row, Col } from "antd";

import { DocumentTitle, DownloadButton } from "domains/document/components";

import { PropsSimpleDocument } from "./types";
import "./simple-document.scss";

const SimpleDocument: React.FC<PropsSimpleDocument> = (props): React.ReactElement | null => {
  const { title, uniqueBtnId, handleDownload, date } = props;

  if (!title || !uniqueBtnId || !handleDownload) {
    return null;
  }

  return (
    <Row type="flex" className="simple-document-content">
      <Col xs={18} sm={20} md={17}>
        <DocumentTitle
          isDragger={false}
          withClickAction={false}
          isShowFileHelpMessage={false}
          title={title}
          statusTitle={""}
          isUploadError={false}
        />
      </Col>
      <Col xs={6} sm={4} md={4} className="simple-document-date">
        {date ? date : null}
      </Col>
      <Col xs={0} sm={0} md={3} className="simple-document-actions ben-d-sm-none">
        <DownloadButton uniqueBtnId={uniqueBtnId} handleOnClick={handleDownload} />
      </Col>
    </Row>
  );
};

export default SimpleDocument;
