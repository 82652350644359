import React from "react";

import { Spinner } from "common/lib/components";

const BenAppSpinner: React.FC = (): React.ReactElement => {
  return (
    <div
      style={{ display: "flex", height: "100%", alignItems: "center", justifyContent: "center" }}
    >
      <Spinner />
    </div>
  );
};

export default BenAppSpinner;
