import React, { useCallback, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Collapse, Form, Input } from "antd";
import { t } from "@lingui/macro";

import { localeService, sessionService, messageService } from "common/shared";
import { useForm } from "common/shared-hooks";
import { actions$ } from "common/shared-store";
import { PatchRequestItemData } from "common/shared/interfaces/requests";
import { Spinner, TextRowInfo, BenFormRowInfo } from "common/lib/components";

import { rolesService, PERMISSION_TYPES } from "common/shared/services/roles/roles.service";
import Institution from "domains/clients/store/institution.model";
import UserLight from "domains/clients/store/user.light.model";
import { AddressForm, impersonateAddressDisabledConfig } from "domains/clients/forms";
import ProfileApiTransformHelper from "domains/clients/shared/profile.api.transform.helper";
import {
  editInstitutionalClient,
  editInstitutionalPrimary,
  ActionTypes,
} from "domains/clients/store/clients.actions";
import {
  AddressFormSchema,
  addressValidationSchema,
  AddressFormReturnedData,
  PersonalForm,
  PersonalFormSchema,
  personalValidationSchema,
  PersonalFormReturnedData,
  impersonatePersonalDisabledConfig,
  PhoneFormSchema,
  phoneValidationSchema,
  PhoneFormReturnedData,
  impersonatePhoneDisabledConfig,
  phoneValidationSchemaDeps,
} from "domains/clients/forms";
import { ClientControls, PersonalInfo, AddressInfo } from "domains/clients/components";

import { PropsInstitutionInformation } from "./types";
import {
  InstitutionInformationDisabledFields,
  InstitutionInformationInputPlaceholder,
} from "./constants";

const { Panel } = Collapse;

function InstitutionInformation({
  client,
  isEdit,
  isUpdating,
  activeKey,
  handleSwitchUpdateStatus,
  handleSwitchEditMode,
  handleOnEditClick,
  currentCollapseKey,
}: PropsInstitutionInformation) {
  const dispatch = useDispatch();
  /*---- synchronize API calls - editInstitutionalAction with editPrimaryAction
   ** editInstitutionalSuccess + editPrimarySuccess -> show ONE success toaster
   ** editInstitutionalSuccess + editPrimaryFail(without specific server error) -> show ONE error toaster
   ** editInstitutionalFail(without specific server error) + editPrimarySuccess -> show ONE error toaster
  ---*/

  // Case 1:
  // InstitutionalAction && PrimaryAction - if either is failed, show ONE failing message
  // InstitutionalAction && PrimaryAction - if both are success, show ONE success message
  const [clientEditStatus, setClientEditStatus] = useState({
    isFinished: false,
    isSuccess: false,
    showError: false,
  });

  const [primaryEditStatus, setPrimaryEditStatus] = useState({
    isFinished: false,
    isSuccess: false,
    showError: false,
  });

  const resetState = () => {
    setPrimaryEditStatus({
      isFinished: false,
      isSuccess: false,
      showError: false,
    });
    setClientEditStatus({
      isFinished: false,
      isSuccess: false,
      showError: false,
    });
  };

  useEffect(() => {
    if (
      clientEditStatus.isFinished &&
      primaryEditStatus.isFinished &&
      clientEditStatus.isSuccess &&
      primaryEditStatus.isSuccess
    ) {
      messageService.success(localeService.i18n._(t`toaster.editInstitutionalClient.success`));
      resetState();
    }
    if (
      clientEditStatus.isFinished &&
      primaryEditStatus.isFinished &&
      ((!clientEditStatus.isSuccess && clientEditStatus.showError) ||
        (!primaryEditStatus.isSuccess && primaryEditStatus.showError))
    ) {
      messageService.error(localeService.i18n._(t`clients.update.error`));
      resetState();
    }
  }, [clientEditStatus, primaryEditStatus]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.EditInstitutionalClientFail).subscribe((action: any) => {
        setClientEditStatus({
          isFinished: true,
          isSuccess: false,
          showError: !!action.payload,
        });
      }),
      actions$.ofType(ActionTypes.EditInstitutionalPrimaryFail).subscribe((action: any) => {
        setPrimaryEditStatus({
          isFinished: true,
          isSuccess: false,
          showError: !!action.payload,
        });
      }),
      actions$
        .ofType([
          ActionTypes.EditInstitutionalClientSuccess,
          ActionTypes.EditInstitutionalPrimarySuccess,
        ])
        .subscribe((action: any) => {
          if (action.type === ActionTypes.EditInstitutionalClientSuccess) {
            setClientEditStatus({ ...clientEditStatus, isFinished: true, isSuccess: true });
            return;
          }
          if (action.type === ActionTypes.EditInstitutionalPrimarySuccess) {
            setPrimaryEditStatus({ ...primaryEditStatus, isFinished: true, isSuccess: true });
            return;
          }
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  let personalData: PersonalFormReturnedData | null = null;
  const getPersonalData = (data: PersonalFormReturnedData) => {
    personalData = { ...data };
  };

  let addressData: AddressFormReturnedData | null = null;
  const getAddressData = (data: AddressFormReturnedData) => {
    addressData = { ...data };
  };

  let phoneData: PhoneFormReturnedData | null = null;
  const getPhoneData = (data: PhoneFormReturnedData) => {
    phoneData = { ...data };
  };

  const primaryContactUser = (client && client.primaryContactUser) || null;

  const personalDisabledConfig = sessionService.isAppian
    ? impersonatePersonalDisabledConfig
    : InstitutionInformationDisabledFields;
  const personalFormSchema = new PersonalFormSchema(primaryContactUser, personalDisabledConfig);
  const personalFormConfig = useForm(personalFormSchema, personalValidationSchema, getPersonalData);

  const addressDisabledConfig = sessionService.isAppian
    ? impersonateAddressDisabledConfig
    : InstitutionInformationDisabledFields;
  const addressFormSchema = new AddressFormSchema(client, addressDisabledConfig);
  const addressFormConfig = useForm(addressFormSchema, addressValidationSchema, getAddressData);

  const phoneDisabledConfig = sessionService.isAppian
    ? impersonatePhoneDisabledConfig
    : InstitutionInformationDisabledFields;
  const phoneFormSchema = new PhoneFormSchema(primaryContactUser, phoneDisabledConfig);
  const phoneFormConfig = useForm(
    phoneFormSchema,
    phoneValidationSchema,
    getPhoneData,
    phoneValidationSchemaDeps
  );

  const isDisabled = (): boolean => {
    return (
      sessionService.isAppian ||
      isUpdating ||
      (addressFormConfig.isDirty && !addressFormConfig.isValid) ||
      (personalFormConfig.isDirty && !personalFormConfig.isValid) ||
      (phoneFormConfig.isDirty && !phoneFormConfig.isValid) ||
      (!addressFormConfig.isStateChanged() &&
        !personalFormConfig.isStateChanged() &&
        !phoneFormConfig.isStateChanged())
    );
  };

  const isGeneralFormValidationShown = () => {
    return (
      (personalFormConfig.isDirty && personalFormConfig.hasDirtyInvalidField()) ||
      (addressFormConfig.isDirty && addressFormConfig.hasDirtyInvalidField()) ||
      (phoneFormConfig.isDirty && phoneFormConfig.hasDirtyInvalidField())
    );
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    addressFormConfig.resetForm();
    personalFormConfig.resetForm();
    phoneFormConfig.resetForm();
  }, [isEdit]);

  const update = (
    patchInstitution: PatchRequestItemData[] | null,
    patchPrincipal: PatchRequestItemData[] | null,
    editedClient: Institution
  ) => {
    if (!client.accountId) {
      return;
    }
    handleSwitchUpdateStatus(true);
    const isPatchPrincipalNeeded = !!(
      patchPrincipal &&
      primaryContactUser &&
      primaryContactUser.userId
    );
    const isPatchInstitutionNeeded = !!patchInstitution;

    // Case 2:
    // if only InstitutionalAction - set primaryEditStatus to true
    // if only PrimaryAction - set institutionEditStatus to true
    setClientEditStatus({
      ...clientEditStatus,
      isFinished: !isPatchInstitutionNeeded,
      isSuccess: !isPatchInstitutionNeeded,
    });
    setPrimaryEditStatus({
      ...primaryEditStatus,
      isFinished: !isPatchPrincipalNeeded,
      isSuccess: !isPatchPrincipalNeeded,
    });

    if (patchInstitution) {
      dispatch(
        editInstitutionalClient({
          data: patchInstitution,
          accountId: client.accountId,
          editedClient,
        })
      );
    }

    if (patchPrincipal && primaryContactUser && primaryContactUser.userId) {
      dispatch(
        editInstitutionalPrimary({
          data: patchPrincipal,
          accountId: client.accountId,
          userId: primaryContactUser.userId,
          editedClient,
        })
      );
    }
  };

  const handleCancelForm = useCallback(() => {
    handleSwitchEditMode();
  }, [handleSwitchEditMode, addressFormConfig, personalFormConfig, phoneFormConfig]);

  function onSubmit() {
    if (sessionService.isAppian) {
      return;
    }

    if (isDisabled() || !primaryContactUser) {
      return;
    }

    personalFormConfig.handleOnSubmit();
    addressFormConfig.handleOnSubmit();
    phoneFormConfig.handleOnSubmit();

    const editedInstitution: { [key: string]: string | UserLight | undefined | null | any[] } = {
      ...client,
      ...addressData,
    };

    const editedPrincipal: { [key: string]: string } = {
      ...personalData,
      ...phoneData,
    };

    const patchInstitution:
      | PatchRequestItemData[]
      | null = ProfileApiTransformHelper.prepareInstitutionalEditPatch(editedInstitution, client);

    const patchPrincipal:
      | PatchRequestItemData[]
      | null = ProfileApiTransformHelper.preparePrincipalEditPatch(
      editedPrincipal,
      primaryContactUser
    );

    if (!patchInstitution && !patchPrincipal) {
      // TODO: No changes detected - add action here
      return;
    }

    const editedPrimaryContactUser: any = {
      ...primaryContactUser,
      ...editedPrincipal,
    };
    const editedClient = new Institution({
      ...client,
      ...editedInstitution,
      primaryContactUser: editedPrimaryContactUser,
    });
    update(patchInstitution, patchPrincipal, editedClient);
  }

  return (
    <Collapse expandIconPosition="right" activeKey={activeKey}>
      <Panel
        header={<span className="ben-header">Client ID: {client.accountDisplayId}</span>}
        extra={
          rolesService.hasPermission(PERMISSION_TYPES.EditAccount) && (
            <ClientControls
              isDisabled={isDisabled()}
              isDisabledCancel={isUpdating}
              isEdit={isEdit}
              btnId="InstitutionInformation"
              handleClickSave={onSubmit}
              handleClickCancel={handleCancelForm}
              handleClickEdit={handleOnEditClick}
            />
          )
        }
        showArrow={false}
        key={currentCollapseKey}
      >
        {isEdit ? (
          <Spinner spinning={isUpdating}>
            <div className="ben-form-holder ben-form-editable-mode">
              <BenFormRowInfo type="changePrimary" />
              <BenFormRowInfo
                type="validation"
                classNames={
                  `ben-form-top-validation ben-bottom-border ` +
                  (isGeneralFormValidationShown() ? "ben-form-top-validation-show" : "")
                }
              />

              <Form className="institution-form">
                {/* Not editable field accountName */}
                <Form.Item
                  className="ben-required-field ben-form-label-field"
                  label={localeService.i18n._("form.institution.name")}
                >
                  <Input
                    name="accountName"
                    value={client.accountName}
                    disabled={InstitutionInformationDisabledFields.accountName}
                    placeholder={InstitutionInformationInputPlaceholder}
                  />
                </Form.Item>

                <PersonalForm
                  config={personalFormConfig}
                  placeholder={InstitutionInformationInputPlaceholder}
                />
                <AddressForm
                  config={addressFormConfig}
                  placeholder={InstitutionInformationInputPlaceholder}
                />
                {/* <PhoneForm
                  config={phoneFormConfig}
                  placeholder={InstitutionInformationInputPlaceholder}
                /> */}
              </Form>
            </div>
          </Spinner>
        ) : (
          primaryContactUser && (
            <div className="info-section institution">
              <TextRowInfo label={localeService.i18n._("form.institution.name")}>
                {client.accountName}
              </TextRowInfo>
              <PersonalInfo
                user={primaryContactUser}
                isShowCombinedName={true}
                placeholder={InstitutionInformationInputPlaceholder}
              />
              <AddressInfo
                user={client}
                isShowCombinedAddress={false}
                placeholder={InstitutionInformationInputPlaceholder}
              />
              {/* <PhoneInfo
                user={primaryContactUser}
                isShowCombinedPhone={false}
                placeholder={InstitutionInformationInputPlaceholder}
              /> */}
            </div>
          )
        )}
      </Panel>
    </Collapse>
  );
}

export default InstitutionInformation;
