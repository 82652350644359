import React from "react";
import { Trans } from "@lingui/macro";

export const Error404Info = {
  title: <Trans id="page404.title">We can’t find that page</Trans>,
  message: (
    <Trans id="page404.message">You may have mistyped the address or the page doesn’t exist.</Trans>
  ),
};

export default {
  Error404Info,
};
