import React, { useEffect, useState, useCallback, useRef } from "react";
import { Button } from "antd";
import { Trans } from "@lingui/react";
import { t } from "@lingui/macro";
import { DomUtils } from "common/shared/utils";

import downIcon from "common/assets/images/icons/collapse-arrow-down.svg";
import upIcon from "common/assets/images/icons/collapse-arrow-up.svg";
import { actions$ } from "common/shared-store/actions/actionsStream";
import { messageService, localeService } from "common/shared";

import { PrincipalInformation } from "domains/clients/components";
import { ActionTypes } from "domains/clients/store/clients.actions";
import User from "domains/clients/store/user.model";

interface PropsProfilePersonalSection {
  user: User;
  shouldTurnOffEditMode: boolean;
  handleOnChangeMode: (isEditMode: boolean) => void;
}

const personalTitle = <Trans id="profile.personal.view.title" />;
const currentCollapseKey = "1";

const ProfilePersonalSection = (props: PropsProfilePersonalSection): React.ReactElement => {
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [isEditMode, setEditMode] = useState(false);
  const [isUpdating, setUpdateStatus] = useState(false);
  const panelRef = useRef<any>();

  const { user, shouldTurnOffEditMode, handleOnChangeMode } = props;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.EditPrincipalClientFail).subscribe((action: any) => {
        if (!action.payload.response) {
          messageService.error(localeService.i18n._(t`profile.update.error`));
        }
        setUpdateStatus(false);
      }),
      actions$.ofType(ActionTypes.EditPrincipalClientSuccess).subscribe(() => {
        toggleEditMode();
        setUpdateStatus(false);
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  // Possibility from the parent to change edit mode
  useEffect(() => {
    if (isEditMode && shouldTurnOffEditMode) {
      setEditMode(false);
    }
  }, [isEditMode, shouldTurnOffEditMode]);

  const toggleCollapse = useCallback(() => {
    setActiveKey(activeKey.length === 0 ? [currentCollapseKey] : []);
  }, [activeKey.length]);

  const toggleEditMode = useCallback(() => {
    handleOnChangeMode(!isEditMode);
    setEditMode((editMode: boolean) => !editMode);
  }, [handleOnChangeMode, isEditMode]);

  const handleCollapseClick = useCallback(() => {
    if (isEditMode) {
      setEditMode(false);
    }

    if (activeKey.length) {
      DomUtils.scrollIntoView(panelRef.current);
    }
    toggleCollapse();
  }, [toggleCollapse, activeKey.length, isEditMode]);

  const onEditClick = () => {
    toggleEditMode();
    setActiveKey([currentCollapseKey]);
  };

  return (
    <div className="ben-panel ben-pb-0 ben-mb-4" ref={panelRef}>
      <div className="ben-client-details">
        <PrincipalInformation
          client={user}
          isEdit={isEditMode}
          isUpdating={isUpdating}
          isOwnProfile={true}
          handleSwitchUpdateStatus={setUpdateStatus}
          handleSwitchEditMode={toggleEditMode}
          handleOnEditClick={onEditClick}
          activeKey={activeKey}
          currentCollapseKey={currentCollapseKey}
          header={personalTitle}
          isShowCombinedAddress={true}
          isShowCombinedName={true}
          isShowCombinedPhone={true}
        />
        <Button
          id="benPersonalSectionBtnCollapse"
          size="small"
          className="collapse"
          type="primary"
          shape="circle"
          onClick={handleCollapseClick}
        >
          <img src={activeKey.length === 0 ? downIcon : upIcon} alt="Logo" />
        </Button>
      </div>
    </div>
  );
};

export default ProfilePersonalSection;
