import React from "react";
import { RouteComponentProps } from "@reach/router";

import { useGetAppVersion } from "common/shared-hooks";

import { ResendSharingInvitation } from "domains/liquidity";
import { PublicPageWrapper } from "../components";

interface PropsResendSharingLoggedOutPage extends RouteComponentProps {}

const ResendSharingLoggedOutPage: React.FC<PropsResendSharingLoggedOutPage> = (): React.ReactElement | null => {
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <ResendSharingInvitation noPanel={true} />
    </PublicPageWrapper>
  );
};

export default ResendSharingLoggedOutPage;
