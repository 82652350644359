import FormInput from "common/shared/interfaces/form-input";
import { validatorTypes } from "common/shared";

export class PersonalFormSchema {
  public firstName = new FormInput();
  public middleName = new FormInput();
  public lastName = new FormInput();
  public suffix = new FormInput();
  public nickName = new FormInput();
  public timeZoneAbbreviation = new FormInput();
  public email = new FormInput();

  constructor(data?: any, disabledFields?: { [key: string]: boolean }) {
    if (data) {
      this.firstName.value = data.firstName || "";
      this.middleName.value = data.middleName || "";
      this.lastName.value = data.lastName || "";
      this.suffix.value = data.suffix || "";
      this.nickName.value = data.nickName || "";
      this.timeZoneAbbreviation.value = data.timeZoneAbbreviation || null;
      this.email.value = data.email || "";
    }

    if (disabledFields) {
      this.firstName.isDisabled = disabledFields.firstName || false;
      this.middleName.isDisabled = disabledFields.middleName || false;
      this.lastName.isDisabled = disabledFields.lastName || false;
      this.suffix.isDisabled = disabledFields.suffix || false;
      this.nickName.isDisabled = disabledFields.nickName || false;
      this.timeZoneAbbreviation.isDisabled = disabledFields.timeZoneAbbreviation || false;
      this.email.isDisabled = disabledFields.email || false;
    }
  }
}

export interface PersonalFormReturnedData {
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  nickName: string;
  timeZoneAbbreviation: string;
  email: string;
}

export const personalValidationSchema = {
  firstName: [validatorTypes.required, validatorTypes.namePattern],
  middleName: [validatorTypes.middleNamePattern],
  lastName: [validatorTypes.required, validatorTypes.namePattern],
  suffix: [validatorTypes.suffixPattern],
  nickName: [validatorTypes.organizationName],
  timeZoneAbbreviation: [],
  email: [validatorTypes.required, validatorTypes.emailPattern],
};

export const impersonatePersonalDisabledConfig = {
  firstName: true,
  middleName: true,
  lastName: true,
  suffix: true,
  nickName: true,
  timeZoneAbbreviation: true,
  email: true,
};
