import { TypeLiquidityStatusCode } from "domains/liquidity/shared/types";

export const PermittedLiquidityStatuses: TypeLiquidityStatusCode[] = [
  "in_verification",
  "in_valuation",
  "in_pricing",
  "in_commitment",
  "in_proposal_pending",
  "ben_rejected_lr_pending",
  "in_proposal",
];
