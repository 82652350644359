import React, { useState, useEffect, Fragment } from "react";
import { useDispatch } from "react-redux";
import { Button, Icon, Modal, Form, Input } from "antd";
import { Trans } from "@lingui/react";

import {
  UploadDocument,
  UploadedDocumentRow,
  DragFileHelpMessage,
} from "domains/document/components";
import { ParamsCreateDocument, CreateDocumentResponseData } from "domains/document/shared/types";
import { UploadFileFormSchema, uploadFileValidationSchema } from "./upload-file.config";
import { useForm } from "common/shared-hooks";
import { localeService, getValidationErrors, sessionService } from "common/shared";
import { IconCustom, Spinner } from "common/lib/components";
import { cancelUpload, confirmUpload, ActionTypes } from "domains/document/store/document.actions";
import { actions$ } from "common/shared-store";

import { PropsAddDocument, CreatedDocument } from "./types";
import {
  TitleErrorSameFileNameUpload,
  TitleBtnSave,
  TitleBtnCancel,
  showMessageFileUploadSuccess,
  ReferenceTypeToUploadConfigsMap,
  TitleAddDocument,
} from "./constants";
import { Layout } from "common/lib/constants";

const AddDocument = ({
  onUploadSuccess,
  onUploadStart,
  onUploadCancel,
  accountId,
  liquidityRequestId,
  investmentId,
  assetId,
  referenceType,
  disableUpload,
}: PropsAddDocument) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [uploadingFileName, setUploadingFileName] = useState<string>("");
  const [isConfirming, setConfirmingFlag] = useState<boolean>(false);
  const [isUploadError, setUploadError] = useState<boolean>(false);
  const [createdDocument, setCreatedDocument] = useState<CreatedDocument | null>();
  const [originalDocumentName, setOriginalDocumentName] = useState<string>("");

  const formSchema = new UploadFileFormSchema();
  const dispatch = useDispatch();

  const submitCallback = (data: { documentName: string }) => {
    if (!createdDocument) {
      return;
    }
    setOriginalDocumentName(createdDocument.documentName || "");
    setConfirmingFlag(true);
    setCreatedDocument({ ...createdDocument, ...data });
    dispatch(
      confirmUpload({
        documentId: createdDocument.documentId,
        versionId: createdDocument.versionId,
        ...data,
        accountId,
      })
    );
  };
  const {
    state,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    isDisabledSubmit,
    resetForm,
  } = useForm(formSchema, uploadFileValidationSchema, submitCallback);

  const getDocumentConfig = (): ParamsCreateDocument => {
    return {
      documentName: state.documentName.value,
      accountId,
      liquidityRequestId,
      investmentId,
      assetId,
      ...ReferenceTypeToUploadConfigsMap[referenceType],
    };
  };

  const handleUploadStart = (fileName: string) => {
    setUploadingFileName(fileName);
    setUploadError(false);
    onUploadStart();
  };

  const handleUploadSuccess = (doc: CreateDocumentResponseData) => {
    setCreatedDocument(doc);
  };

  const handleUploadSuccessDraggedFile = (doc: CreateDocumentResponseData) => {
    setModalOpen(true);
    setCreatedDocument(doc);
  };

  const handleConfirmUploadSuccessDraggedFile = (doc: CreateDocumentResponseData) => {
    onUploadSuccess(doc);
    resetFile();
    handleModalClose();
  };

  const handleUploadError = () => {
    setUploadError(true);
    resetForm();
  };

  const handleCancelUpload = () => {
    dispatch(cancelUpload());
  };

  const handleModalCancel = () => {
    handleCancelUpload();
    removeFile();
    handleModalClose();
    setUploadError(false);
  };

  const handleModalClose = () => {
    setConfirmingFlag(false);
    resetForm();
    setModalOpen(false);
    setUploadError(false);
  };

  const resetFile = () => {
    setCreatedDocument(null);
    setUploadingFileName("");
  };

  const removeFile = () => {
    if (createdDocument) {
      dispatch(
        confirmUpload({
          documentId: createdDocument.documentId,
          versionId: createdDocument.versionId,
          reject: true,
          accountId,
        })
      );
    }
    resetFile();
    onUploadCancel();
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.ConfirmUploadSuccess).subscribe(() => {
        // check if subscription is of activated upload
        if (createdDocument) {
          showMessageFileUploadSuccess(createdDocument.documentName);
          onUploadSuccess(createdDocument);
          resetFile();
          handleModalClose();
        }
      })
    );
    subscriptions.push(
      actions$.ofType(ActionTypes.ConfirmUploadFail).subscribe(() => {
        // handling error for another document in modal window
        if (!isModalOpen) {
          return;
        }
        handleUploadError();

        // return to its original name
        if (createdDocument) {
          setCreatedDocument({ ...createdDocument, documentName: originalDocumentName });
        }

        setConfirmingFlag(false);
        setOriginalDocumentName("");
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [createdDocument, isModalOpen, originalDocumentName]);

  return (
    <div className="ben-d-sm-none">
      {/* TODO: button is hidden by class - "ben-d-sm-none" (for mobile state), also need to remove DIV */}
      <div className="ben-document-content ben-document-content-small">
        <UploadDocument
          isDragger={true}
          openFileDialogOnClick={false}
          typeOfNameToShowOnSuccess="document"
          onUploadStart={handleUploadStart}
          onUploadSuccess={handleUploadSuccessDraggedFile}
          onUploadError={handleUploadError}
          onUploadCancel={resetFile}
          onConfirmUploadSuccess={handleConfirmUploadSuccessDraggedFile}
          isShowUploadingName={true}
          isAddToRequestDocumentName={true}
          customFlow={true}
          documentConfig={getDocumentConfig()}
          disableUpload={disableUpload}
        >
          <Button
            id={`benDocumentBtnAnotheDocument${assetId || liquidityRequestId}`}
            type="link"
            className="ben-upload-link ben-btn-reset ben-dragged-trigger"
            disabled={sessionService.isAppian || disableUpload}
            onClick={() => setModalOpen(true)}
          >
            <Icon type="plus" style={{ fontSize: 20 }} /> {TitleAddDocument}
            {disableUpload ? null : <DragFileHelpMessage withClickAction={true} />}
          </Button>
        </UploadDocument>
      </div>

      <Modal
        title={TitleAddDocument}
        visible={isModalOpen}
        centered={true}
        width={"100%"}
        style={{
          maxWidth: Layout.MaxWidthModalWindow.standard,
        }}
        wrapClassName="ben-modal-container"
        footer={null}
        onCancel={handleModalCancel}
        destroyOnClose={true}
      >
        <div className="ben-modal-content ben-pt-0 ben-file-upload-wrapper">
          <div className="ben-required-text ben-mb-3">
            <p className="ben-mb-1">
              <strong className="ben-required-label">
                <Trans id="required.helper" />
              </strong>
            </p>
          </div>
          <Spinner spinning={isConfirming}>
            {createdDocument && (
              <Fragment>
                <UploadedDocumentRow
                  createdDocument={createdDocument}
                  handleRemoveFile={removeFile}
                />
                <Form>
                  <Form.Item
                    className="ben-form-label-field ben-required-field"
                    label={localeService.i18n._("form.uploadFile.documentName")}
                    {...getValidationErrors(
                      state.documentName.errors,
                      localeService.i18n._("form.uploadFile.documentName")
                    )}
                  >
                    <Input
                      name="documentName"
                      value={state.documentName.value}
                      onChange={handleOnChange}
                      onBlur={handleOnBlur}
                    />
                  </Form.Item>
                </Form>
              </Fragment>
            )}
          </Spinner>
          {!createdDocument && (
            <UploadDocument
              isDragger={false}
              openFileDialogOnClick={true}
              onUploadSuccess={handleUploadSuccess}
              onUploadStart={handleUploadStart}
              onUploadError={handleUploadError}
              onUploadCancel={resetFile}
              isShowUploadingName={true}
              isAddToRequestDocumentName={true}
              customFlow={true}
              documentConfig={getDocumentConfig()}
            >
              <Form.Item
                className="ben-form-label-field ben-required-field ben-file-name-wrapper"
                label={localeService.i18n._("form.uploadFile.fileName")}
              >
                <Input
                  id={`benUploadOther${assetId || liquidityRequestId}`}
                  name="fileName"
                  value={uploadingFileName || ""}
                  onClick={handleCancelUpload}
                />
                <IconCustom type="folder" style={{ fontSize: 20 }} />
              </Form.Item>
            </UploadDocument>
          )}
          {isUploadError && <span className="ben-error-text">{TitleErrorSameFileNameUpload}</span>}
        </div>
        <Button
          type="primary"
          shape="round"
          size="large"
          className="ben-w-100 ben-mb-3"
          onClick={handleOnSubmit}
          disabled={isDisabledSubmit()}
          id="benUploadAnotherActionSubmit"
        >
          {TitleBtnSave}
        </Button>
        <Button
          type="primary"
          shape="round"
          ghost={true}
          size="large"
          className="ben-w-100"
          onClick={handleModalCancel}
          id="benUploadAnotherActionCancel"
        >
          {TitleBtnCancel}
        </Button>
      </Modal>
    </div>
  );
};

export default AddDocument;
