import { LoginInfoCard, LoginFooter, ResendWelcomeEmail, DescriptionSignin } from "./components";
import {
  acceptIdentityEpic,
  resendWelcomeEmailEpic,
  acceptResetPasswordEpic,
} from "domains/authentication/store/auth.epics";

import {
  ActionTypes,
  acceptIdentity,
  acceptResetPassword,
} from "domains/authentication/store/auth.actions";
import {
  ParamsAcceptIdentity,
  ParamsResendWelcomeEmail,
  ParamsAcceptResetPassword,
  AcceptResetPasswordResponse,
} from "domains/authentication/shared/types";
import { ForgotPasswordForm, RegistrationForm, ResetPasswordForm, SigninForm } from "./forms";
import { ForgotPasswordModal, SessionModal, VerifyInvestorModal } from "./modals";

export interface AcceptIdentityData extends ParamsAcceptIdentity {}
export interface ResendWelcomeEmailData extends ParamsResendWelcomeEmail {}
export interface AcceptResetPasswordData extends ParamsAcceptResetPassword {}
export interface AcceptResetPasswordResponseData extends AcceptResetPasswordResponse {}

export {
  ForgotPasswordForm,
  ForgotPasswordModal,
  LoginInfoCard,
  LoginFooter,
  ResetPasswordForm,
  SigninForm,
  ResendWelcomeEmail,
  acceptIdentityEpic,
  ActionTypes,
  acceptIdentity,
  resendWelcomeEmailEpic,
  SessionModal,
  acceptResetPassword,
  acceptResetPasswordEpic,
  VerifyInvestorModal,
  DescriptionSignin,
  RegistrationForm,
};
