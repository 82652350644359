import React from "react";
import { RouteComponentProps } from "@reach/router";
import { Trans } from "@lingui/macro";

import { portalEnvConstants } from "common/shared";
import { CardHelp, ContactInfo } from "common/lib/components";
import { BenInfo } from "common/lib/constants";
import { useGetAppVersion } from "common/shared-hooks";

import { PublicPageWrapper } from "../components";

function LoginHelpPage(props: RouteComponentProps) {
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  const title = <Trans id="loginHelp.title">Help</Trans>;
  const subtitle = (
    <Trans id="loginHelp.subtitle">
      For assistance accessing the Ben portal, please choose one of the following
    </Trans>
  );

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <CardHelp title={title} subtitle={subtitle} noPanel>
        <ContactInfo
          isFullEmailLabelto={true}
          isFullPhoneLabel={true}
          tel={portalEnvConstants.defaultPhoneNumber}
          email={BenInfo.EMAILS.portalHelp}
        />
      </CardHelp>
    </PublicPageWrapper>
  );
}

export default LoginHelpPage;
