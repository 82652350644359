import { useSelector } from "react-redux";
import { LiquidityStore, CreateLiquiditySharingDataProps } from "../shared/types";

function useCreateLiquiditySharingData(): CreateLiquiditySharingDataProps {
  const result = useSelector(
    (state: any): CreateLiquiditySharingDataProps => {
      const liquidityState: LiquidityStore = state.liquidity;
      const {
        creationSharing: { data, loading },
      } = liquidityState;

      if (!data || !data.id) {
        return {
          data: null,
          loading,
        };
      }

      const response = {
        data: {
          ...data,
        },
        loading,
      };

      return response;
    }
  );

  return result;
}

export default useCreateLiquiditySharingData;
