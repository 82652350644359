import TabexService, { ICrossTabClient, IEventListener } from "tabex";
import { ArrayUtils } from "common/shared/utils";

import { CROSS_TAB_EVENTS } from "./constants";

export type TCrossTabMessageEventHandler = (message?: string) => void;

// https://github.com/nodeca/tabex
export class CrossTabMessageService {
  private messageService: ICrossTabClient;
  private activeEvents: string[] = [];

  constructor(messageService: ICrossTabClient) {
    this.messageService = messageService;
  }

  public isMaster(): boolean {
    const currentNodeId = this.messageService.__router__.__node_id__;
    const currentMasterId = this.messageService.__router__.__master_id__;
    const isMaster = Boolean(currentNodeId === currentMasterId);
    return isMaster;
  }

  public addListener(event: string, handler: TCrossTabMessageEventHandler): boolean {
    if (!this.isValidEvent(event) || !handler || typeof handler !== "function") {
      return false;
    }

    const result: IEventListener = this.messageService.on(event, handler);
    if (!result || typeof result !== "object" || !result.__node_id__) {
      return false;
    }

    this.activeEvents.push(event);
    return true;
  }

  public removeListeners(event: string): boolean {
    if (!this.isValidEvent(event)) {
      return false;
    }

    this.messageService.off(event);
    this.activeEvents = this.activeEvents.filter((activeEvent: string) => activeEvent !== event);
    return true;
  }

  public removeAllListeners(): boolean {
    if (!ArrayUtils.isValidArrayData(this.activeEvents)) {
      return false;
    }

    this.activeEvents.forEach((event: string) => this.removeListeners(event));
    this.activeEvents = [];
    return true;
  }

  // Broadcast message to subscribed clients in all tabs
  public emitEvent(event: string, message?: string, self: boolean = false): boolean {
    if (!this.isValidEvent(event)) {
      return false;
    }

    this.messageService.emit(event, message, self);
    return true;
  }

  private isValidEvent(event: string): boolean {
    if (!event || !CROSS_TAB_EVENTS[event]) {
      return false;
    }
    return true;
  }
}

export const crossTabMessageService = new CrossTabMessageService(TabexService.client());
