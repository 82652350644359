import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Row, Col } from "antd";
import { Trans } from "@lingui/macro";

import { BenFormRowInfo } from "common/lib/components";
import { Constants } from "common/lib/constants";
import { getValidationErrors, localeService, sessionService } from "common/shared";
import { useForm } from "common/shared-hooks";

import ClientsApiParser from "domains/clients/shared/clients.api.parser";
import { addClient } from "domains/clients/store/clients.actions";

import {
  PhoneFormSchema,
  phoneValidationSchema,
  impersonatePhoneDisabledConfig,
  phoneValidationSchemaDeps,
} from "domains/clients/forms/phone-form";
import {
  PersonalForm,
  PersonalFormSchema,
  personalValidationSchema,
  impersonatePersonalDisabledConfig,
} from "domains/clients/forms/personal-form";
import {
  AddressForm,
  AddressFormSchema,
  addressValidationSchema,
  impersonateAddressDisabledConfig,
} from "domains/clients/forms/address-form";

import {
  InstitutionalFormSchema,
  institutionalValidationSchema,
  impersonateInstitutionalDisabledConfig,
} from "./institutional-form.config";

interface InstitutionalFormProps {
  handleCancel: () => void;
}

const InstitutionalForm: React.FC<InstitutionalFormProps> = (props): React.ReactElement => {
  const { handleCancel } = props;

  const addressFormDisableConfig =
    (sessionService.isAppian && impersonateAddressDisabledConfig) || {};
  const addressFormSchema = new AddressFormSchema(null, addressFormDisableConfig);
  let addressData: any = null;
  const getAddressData = (data: any) => {
    addressData = { ...data };
  };
  const addressFormConfig = useForm(addressFormSchema, addressValidationSchema, getAddressData);

  // personal form initialisation
  const personalFormDisableConfig =
    (sessionService.isAppian && impersonatePersonalDisabledConfig) || {};
  const personalFormSchema = new PersonalFormSchema(null, personalFormDisableConfig);
  let personalData: any = null;
  const getPersonalData = (data: any) => {
    personalData = { ...data };
  };
  const personalFormConfig = useForm(personalFormSchema, personalValidationSchema, getPersonalData);

  // phone form initialisation
  const phoneFormDisableConfig = (sessionService.isAppian && impersonatePhoneDisabledConfig) || {};
  const phoneFormSchema = new PhoneFormSchema(null, phoneFormDisableConfig);
  let phoneData: any = null;
  const getPhoneData = (data: any) => {
    phoneData = { ...data };
  };
  const phoneFormConfig = useForm(
    phoneFormSchema,
    phoneValidationSchema,
    getPhoneData,
    phoneValidationSchemaDeps
  );

  const institutionalFormDisableConfig =
    (sessionService.isAppian && impersonateInstitutionalDisabledConfig) || {};
  const institutionalFormSchema = new InstitutionalFormSchema(null, institutionalFormDisableConfig);

  const dispatch = useDispatch();
  const {
    state,
    isDisabledSubmit,
    isDirty,
    hasDirtyInvalidField,
    handleOnSubmit,
    handleOnChange,
    handleOnBlur,
  } = useForm(institutionalFormSchema, institutionalValidationSchema, submitCallback);

  function submitCallback(data: any) {
    if (sessionService.isAppian) {
      return;
    }

    if (
      !isDisabledSubmit() &&
      !addressFormConfig.isDisabledSubmit() &&
      !personalFormConfig.isDisabledSubmit() &&
      !phoneFormConfig.isDisabledSubmit()
    ) {
      addressFormConfig.handleOnSubmit();
      personalFormConfig.handleOnSubmit();
      phoneFormConfig.handleOnSubmit();
      const account = ClientsApiParser.prepareBackendLikeInstitution({
        ...data,
        ...addressData,
        clientType: Constants.ACCOUNT_TYPES.INSTITUTION,
      });
      const user = ClientsApiParser.prepareInstitutionUserData({ ...personalData, ...phoneData });
      dispatch(addClient({ ...account, user }));
    }
  }

  const isGeneralFormValidationShown = () => {
    return (
      (isDirty && hasDirtyInvalidField()) ||
      (personalFormConfig.isDirty && personalFormConfig.hasDirtyInvalidField()) ||
      (addressFormConfig.isDirty && addressFormConfig.hasDirtyInvalidField()) ||
      (phoneFormConfig.isDirty && phoneFormConfig.hasDirtyInvalidField())
    );
  };

  // Form should be 10 inputs height. Each input is 65px. 10 * 65 + 1px = 651px

  return (
    <Fragment>
      <BenFormRowInfo
        type="validation"
        classNames={
          `ben-form-top-validation ` +
          (isGeneralFormValidationShown() ? "ben-form-top-validation-show" : "")
        }
      />

      <div className="ben-form-holder">
        <Form>
          <Row gutter={30} type="flex" className="ben-pb-2">
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <div>
                <Form.Item
                  className="ben-form-label-field ben-required-field"
                  label={localeService.i18n._("form.institution.name")}
                  {...getValidationErrors(
                    state.name.errors,
                    localeService.i18n._("form.institution.name")
                  )}
                >
                  <Input
                    name="name"
                    value={state.name.value}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    disabled={state.name.isDisabled}
                  />
                </Form.Item>
              </div>
              <PersonalForm config={personalFormConfig} />
            </Col>
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
              <AddressForm config={addressFormConfig} />
              {/* <PhoneForm config={phoneFormConfig} /> */}
            </Col>
          </Row>
        </Form>
      </div>

      <Row gutter={30} className="ben-form-actions">
        <Col md={{ span: 12 }} xs={{ span: 24 }} className="ben-pb-2">
          <Button
            id="benInstitutionFormBtnCancel"
            type="primary"
            shape="round"
            size="large"
            ghost={true}
            className="ben-w-100"
            onClick={handleCancel}
          >
            <Trans id="actionButtons.cancel">Cancel</Trans>
          </Button>
        </Col>
        <Col md={{ span: 12 }} xs={{ span: 24 }} className="ben-pb-2">
          <Button
            id="benInstitutionFormBtnSubmit"
            type="primary"
            shape="round"
            size="large"
            className="ben-w-100"
            disabled={
              sessionService.isAppian ||
              isDisabledSubmit() ||
              addressFormConfig.isDisabledSubmit() ||
              personalFormConfig.isDisabledSubmit() ||
              phoneFormConfig.isDisabledSubmit()
            }
            onClick={handleOnSubmit}
          >
            <Trans id="actionButtons.submit">Submit</Trans>
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default InstitutionalForm;
