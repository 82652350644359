import React, { useEffect, useState, useCallback } from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useDispatch } from "react-redux";
import { useQueryParams, StringParam } from "use-query-params";
import { t, Trans } from "@lingui/macro";

import { BenVerifyScreen } from "common/lib/components";
import { Urls } from "common/lib/constants";
import { tokenDecodeUtils, messageService, localeService, sessionService } from "common/shared";
import {
  setNavigateToAfterLogin,
  setAcceptedRequestSharingData,
} from "common/shared-store/actions/bootstrap";
import { useBootstrapTokenForPassData } from "common/shared-hooks";

import { LiquiditySharingToUpdate } from "domains/liquidity/shared/types";
import { createSharingAcceptance, AcceptRequestSharingInvitationData } from "domains/liquidity";

import { TypeRedirectedFrom } from "pages/SetPasswordPage/types";

interface PropsVerifySharingPage extends RouteComponentProps {}
const verifyTitle = <Trans id="verifySharingPage.verification.message.process">Verifying...</Trans>;
const redirectedFrom: TypeRedirectedFrom = "verifySharingPage";

const VerifySharingPage: React.FC<PropsVerifySharingPage> = (): React.ReactElement | null => {
  const [isBrokenLinkErrorShow, setBrokenLinkErrorShow] = useState(false);
  const [isBlockedErrorShowing, setBlockedErrorShowing] = useState(false);
  const [query] = useQueryParams({
    token: StringParam,
    liquidity_request_id: StringParam,
    sharing_id: StringParam,
  });
  const dispatch = useDispatch();

  const {
    tokenForPass,
    isTokenForPassCompletedRequest,
    isTokenForPassSuccess,
  } = useBootstrapTokenForPassData();

  const isUserLoggedIn = sessionService.isAuthenticated();
  const isNewUser = Boolean(tokenForPass);
  const { token, liquidity_request_id: liquidityRequestId, sharing_id: sharingId } = query;
  const decodedTokenData = tokenDecodeUtils.decodeJwt(token || "");
  const acceptedAgreementValue =
    (decodedTokenData && decodedTokenData["custom:accepted_agreement"]) || null;

  const blockErrorShowing = useCallback(() => {
    if (!isBlockedErrorShowing) {
      setBlockedErrorShowing(true);
    }
  }, [isBlockedErrorShowing]);

  useEffect(() => {
    if (!liquidityRequestId || !sharingId) {
      return;
    }

    const path = `${Urls.PROTECTED.LIQUIDITY_REQUEST}/${liquidityRequestId}`;
    dispatch(setNavigateToAfterLogin({ path }));

    const editedData: LiquiditySharingToUpdate = {
      status: "accepted",
    };

    // TODO: get original data from the BE in future. Now it is hardcoded.
    const originalData: LiquiditySharingToUpdate = {
      status: "submitted",
    };

    // setup data needed to accept LR sharing invitation after user is redirected to LR details page
    dispatch(
      setAcceptedRequestSharingData({
        editedData,
        originalData,
        requestId: liquidityRequestId,
        sharingId,
      })
    );

    // send request to create user
    if (token && !tokenDecodeUtils.isTokenExpired(token)) {
      const payload: AcceptRequestSharingInvitationData = {
        requestId: liquidityRequestId,
        sharingId,
        token,
      };

      dispatch(createSharingAcceptance(payload));
    }
  }, [dispatch, liquidityRequestId, sharingId, token]);

  // Show notification
  useEffect(() => {
    if (isBrokenLinkErrorShow && !isBlockedErrorShowing) {
      messageService.warn(
        localeService.i18n._(t`verifySharingPage.verification.message.brokenLink`)
      );
    }
  }, [isBrokenLinkErrorShow, isBlockedErrorShowing]);

  /*---- Logic of the redirection START ----*/
  /*---- Common flow START ----*/
  // Case 1: Broken link
  if (!liquidityRequestId || !sharingId) {
    if (!isBrokenLinkErrorShow) {
      setBrokenLinkErrorShow(true);
    }

    // Case 1.1: Broken link AND user IS LoggedIn
    if (isUserLoggedIn) {
      return <Redirect to={Urls.AUTH.RESEND_SHARING_INVITATION_IN} noThrow={true} />;
    } else {
      // Case 1.2: Broken link AND user is NOT LoggedIn
      return <Redirect to={Urls.AUTH.RESEND_SHARING_INVITATION_OUT} noThrow={true} />;
    }
  }
  /*---- Common flow END ----*/

  /*---- Flow - There IS token in link START ----*/

  if (token) {
    // Case 2: Token Expired
    if (tokenDecodeUtils.isTokenExpired(token)) {
      // Case 2.1: Token Expired AND user IS LoggedIn
      if (isUserLoggedIn) {
        return <Redirect to={Urls.AUTH.RESEND_SHARING_INVITATION_IN} noThrow={true} />;
      } else {
        // Case 2.2: Token Expired AND user is NOT LoggedIn
        return <Redirect to={Urls.AUTH.RESEND_SHARING_INVITATION_OUT} noThrow={true} />;
      }
    }

    // Case 3: request for tokenForPass failed
    // TODO: Should be redirection to Some Error page. Now we do NOT have it
    if (isTokenForPassCompletedRequest) {
      if (!isTokenForPassSuccess) {
        // Case 3.1: Request for tokenForPass failed AND user IS LoggedIn
        if (isUserLoggedIn) {
          return <Redirect to={Urls.AUTH.RESEND_SHARING_INVITATION_IN} noThrow={true} />;
        } else {
          // Case 3.2:  Request for tokenForPass failed AND user is NOT LoggedIn
          return <Redirect to={Urls.AUTH.RESEND_SHARING_INVITATION_OUT} noThrow={true} />;
        }
      }

      // Case 4: Link is valid AND Token is valid AND User is new in the system
      if (isNewUser) {
        return (
          <Redirect
            to={`${Urls.AUTH.SET_PASSWORD}?token=${tokenForPass}${
              acceptedAgreementValue ? `&acceptedAgreementValue=${acceptedAgreementValue}` : ""
            }&redirectedFrom=${redirectedFrom}`}
            noThrow={true}
          />
        );
      }
    }
  }
  /*---- Flow - There IS token in link END ----*/

  /*---- Flow - There is NO token in link START ----*/

  if (!token) {
    // Case 5: Link is valid AND Token is valid AND User is existing AND User is NOT LoggedIn
    if (!isUserLoggedIn) {
      /**
       * blocks showing error message.
       * Because URL has changed and this component is still running.
       * New URL does not have liquidityRequestId  and sharingId parameters.
       * `Case 1: Broken link` is used after redirection.
       */
      blockErrorShowing();
      return <Redirect to={Urls.AUTH.SIGN_IN} noThrow={true} />;
    }

    // Case 6: Link is valid AND Token is valid AND User is existing AND User IS LoggedIn
    if (isUserLoggedIn) {
      return (
        <Redirect to={`${Urls.PROTECTED.LIQUIDITY_REQUEST}/${liquidityRequestId}`} noThrow={true} />
      );
    }
  }
  /*---- Flow - There is NO token in link END ----*/

  /*---- Logic of the redirection END ----*/

  // Case 7: Process of verifying
  return <BenVerifyScreen title={verifyTitle} />;
};

export default VerifySharingPage;
