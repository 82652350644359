import React, { Fragment } from "react";

import { TextRowInfo, BenCombinedData } from "common/lib/components";

import { TypeClientCombinedItem } from "domains/clients/shared/types";
import clientsService from "domains/clients/shared/clients.service";
import User from "domains/clients/store/user.model";

interface PropsPersonalInfo {
  user: User;
  placeholder: string;
  isShowCombinedName: boolean;
}

const PersonalInfo: React.FC<PropsPersonalInfo> = (props): React.ReactElement | null => {
  const { user, placeholder, isShowCombinedName } = props;

  //const timeZoneTitle = clientsService.getTimeZoneTitle(user);
  const nameCombinedData: TypeClientCombinedItem[] = clientsService.getNameCombinedData(user);

  return (
    <Fragment>
      {isShowCombinedName ? (
        <TextRowInfo label="profile.common.name">
          <BenCombinedData items={nameCombinedData} />
        </TextRowInfo>
      ) : (
        <Fragment>
          <TextRowInfo label="profile.personal.firstName">
            {user.firstName || placeholder}
          </TextRowInfo>
          {/* <TextRowInfo label="profile.personal.middleName">
            {user.middleName || placeholder}
          </TextRowInfo> */}
          <TextRowInfo label="profile.personal.lastName">
            {user.lastName || placeholder}
          </TextRowInfo>
          {/* <TextRowInfo label="profile.personal.suffix">{user.suffix || placeholder}</TextRowInfo> */}
        </Fragment>
      )}

      {/* <TextRowInfo label="profile.personal.nickname">{user.nickName || placeholder}</TextRowInfo>
      <TextRowInfo label="profile.personal.timeZone">{timeZoneTitle || placeholder}</TextRowInfo> */}
      <TextRowInfo label="profile.contact.email" className="ben-word-break-all">
        {user.email || placeholder}
      </TextRowInfo>
    </Fragment>
  );
};

export default PersonalInfo;
