import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Row, Col } from "antd";
import { navigate } from "@reach/router";

import { actions$ } from "common/shared-store";
import { localeService } from "common/shared";
import { useForm, useBootstrapRedirectAfterLoginData } from "common/shared-hooks";
import { Spinner } from "common/lib/components";
import { DomUtils, ObjectUtils } from "common/shared/utils";
import { Urls } from "common/lib/constants";
import { setNavigateToAfterLogin, setTokenForPass } from "common/shared-store/actions/bootstrap";

import { LoginFooter, ForgotPasswordModal } from "domains/authentication";
import { signIn, resetLoginError, ActionTypes } from "domains/authentication/store/auth.actions";
import { useLoginData } from "domains/authentication/hooks";
import { SigninValidationErrorMessage } from "domains/authentication/components";

import { SignInFormSchema, signInValidationSchema } from "./signin.form.config";
import { TitleForm, BtnTitle } from "./constants";
import "./signin-form.scss";

function SigninForm() {
  const [isUserLocked, lockUser] = useState(false);
  const formSchema = new SignInFormSchema();
  const {
    state,
    isDisabledSubmit,
    resetIsDirty,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
  } = useForm(formSchema, signInValidationSchema, login);
  const dispatch = useDispatch();

  const { loginError, loading } = useLoginData();
  // navigateToAfterLogin - shows where to redirect a user after login, saved in bootstrap reducer
  const { navigateToAfterLogin } = useBootstrapRedirectAfterLoginData();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.SignInSuccess).subscribe(() => {
        if (navigateToAfterLogin) {
          navigate(navigateToAfterLogin);
          dispatch(setNavigateToAfterLogin({ path: "" }));
          dispatch(setTokenForPass({ token: null, success: false }));
          return;
        }

        navigate(Urls.PROTECTED.BASE);
      })
    );
    subscriptions.push(
      actions$.ofType(ActionTypes.SignInFail).subscribe(() => {
        handleOnChange({ target: { name: "password", value: "" } });
      })
    );
    subscriptions.push(
      actions$.ofType(ActionTypes.SignInFailUserLocked).subscribe(() => {
        lockUser(true);
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  // on componentUnmount reset loginAPIError
  useEffect(() => {
    return () => {
      dispatch(resetLoginError());
    };
  }, [dispatch]);

  const onSubmit = useCallback(
    (e?: any) => {
      handleOnSubmit(e);
      DomUtils.scroll(0, 0);
    },
    [handleOnSubmit]
  );

  function login(data: any) {
    dispatch(signIn(data));
    resetIsDirty();
  }

  const onKeyPress = useCallback(
    (event: any) => {
      if (event.key === "Enter") {
        onSubmit();
      }
    },
    [onSubmit]
  );

  const isUsernameValid = () => {
    const userNameErrors = state.username.isDirty && state.username.errors;

    return ObjectUtils.isEmpty(userNameErrors);
  };
  const isPasswordValid = () => {
    const passwordErrors = state.password.isDirty && state.password.errors;

    return ObjectUtils.isEmpty(passwordErrors);
  };
  const hasError = () => {
    return loginError || !isUsernameValid() || !isPasswordValid();
  };

  return (
    <div className="signin-form-wrapper">
      <div className="ben-panel-header">
        <h3 id="titleSigninForm">{TitleForm}</h3>
      </div>

      {loading ? (
        <Spinner />
      ) : (
        <Form className="signin-form">
          <span className="ben-d-sm-none">
            <SigninValidationErrorMessage isUserLocked={isUserLocked} isHasError={hasError()} />
          </span>
          <div className="ben-border-bottom ben-mb-4 ben-d-flex ben-vertical">
            <Row gutter={30}>
              <Col sm={24} md={24}>
                <Form.Item>
                  <Input
                    id="beSigninFormTextUsername"
                    name="username"
                    placeholder={localeService.i18n._("signin.username.label")}
                    value={state.username.value}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    disabled={isUserLocked}
                    autoFocus={true}
                  />
                </Form.Item>
              </Col>
              <Col sm={24} md={24}>
                <Form.Item>
                  <Input
                    id="benSigninFormTextPassword"
                    name="password"
                    type="password"
                    placeholder={localeService.i18n._("signin.password.label")}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    disabled={isUserLocked}
                    onKeyPress={onKeyPress}
                  />
                </Form.Item>
              </Col>
            </Row>
            <span className="ben-d-none ben-d-sm-block">
              <SigninValidationErrorMessage isUserLocked={isUserLocked} isHasError={hasError()} />
            </span>
            <Row>
              <Col sm={24} md={12}>
                <ForgotPasswordModal />
              </Col>
              <Col sm={24} md={12} className="ben-pb-4">
                <LoginFooter />
              </Col>
            </Row>
          </div>
          <Row gutter={30}>
            <Col md={{ span: 24 }} className="ben-pb-2">
              <Button
                id="benSigninFormBtnLogin"
                className="ben-w-100"
                shape="round"
                size="large"
                type="primary"
                disabled={isDisabledSubmit() || isUserLocked}
                onClick={onSubmit}
              >
                {BtnTitle}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
}

export default SigninForm;
