import React, { useEffect } from "react";
import { AutoComplete } from "antd";
import { useDispatch } from "react-redux";
import { SelectValue } from "antd/lib/select";

import { apiValidators } from "common/shared";
import { Spinner } from "common/lib/components";

import { getAccountUsers } from "domains/clients/store/clients.actions";
import UserLight from "domains/clients/store/user.light.model";
import { useAccountUsersData } from "domains/clients/hooks";

const { Option } = AutoComplete;

interface PropsUsersAutoComplete {
  accountId?: string;
  handleOnSelect: (user: UserLight | null) => void;
  handleOnBlur: (userId: string) => void;
  value: UserLight;
  disabled?: boolean;
}

function UsersAutoComplete({
  accountId = "",
  handleOnSelect,
  handleOnBlur,
  value,
  disabled = false,
}: PropsUsersAutoComplete) {
  const dispatch = useDispatch();

  const { accountUsers, accountUsersLoading } = useAccountUsersData();

  useEffect(() => {
    dispatch(getAccountUsers(accountId));
  }, [dispatch, accountId]);

  const handleOnChange = (userId: SelectValue) => {
    const selectedUser = accountUsers.find((user: UserLight) => user.id === userId);

    if (!selectedUser) {
      handleOnSelect(null);
      return;
    }
    value = selectedUser;
    handleOnSelect(selectedUser);
  };

  const handleOnSelectBlur = (userId: SelectValue) => {
    if (value && value.fullName === userId) {
      return;
    }

    if (!apiValidators.isValidSystemId(apiValidators.ID_TYPES.user, String(userId))) {
      handleOnBlur("");
      return;
    }
    handleOnBlur(String(userId));
  };

  if (accountUsersLoading) {
    return <Spinner />;
  }

  const options = accountUsers.map((opt) => (
    <Option key={opt.email} value={opt.id}>
      {opt.fullName}
    </Option>
  ));

  return (
    <AutoComplete
      defaultValue={value && value.fullName}
      style={{ width: "100%" }}
      dataSource={options}
      onChange={(v) => {
        handleOnChange(v);
      }}
      onBlur={handleOnSelectBlur}
      disabled={disabled}
    />
  );
}

export default UsersAutoComplete;
