import React, { Fragment } from "react";

import {
  STANDARD_INVESTMENT_DOCUMENT_LIST,
  DocumentTypeUploadConfigs,
} from "domains/document/shared/constants";
import { TypeDocumentType } from "domains/document/shared/types";
import documentService from "domains/document/shared/document.service";
import { BenDocument } from "domains/document/components";

import { PropsStandardUploadedHoldingDocuments } from "./types";

const StandardUploadedHoldingDocuments: React.FC<PropsStandardUploadedHoldingDocuments> = (
  props
): React.ReactElement | null => {
  const {
    holding,
    disableUpload,
    disableDontHaveIcon,
    onUploadSuccess,
    getDownloadHandler,
    onUploadStart,
    onUploadCancel,
    onUploadError,
  } = props;

  return (
    <Fragment>
      {STANDARD_INVESTMENT_DOCUMENT_LIST.map((docType: TypeDocumentType) => {
        const standardDoc = holding.standardDocuments[docType];
        if (standardDoc) {
          const { typeSettings: docSettings } = documentService.getDisplaySettingsByType(docType);
          const newUploadConfig = standardDoc.doNotHaveThis
            ? {
                investmentId: holding.investmentId,
                assetId: holding.assetId,
                ...DocumentTypeUploadConfigs[docType],
              }
            : {};

          return (
            <BenDocument
              key={docType}
              withDragger={true}
              openFileDialogOnClick={true}
              title={docSettings.title}
              statusIcon="complete"
              size="small"
              handleDownload={getDownloadHandler(standardDoc)}
              uploadConfig={{
                documentId: standardDoc.id,
                newVersionOfUniqueDocumentConfirmed: !standardDoc.doNotHaveThis,
                doNotHaveThisDocument: standardDoc.doNotHaveThis,
                ...newUploadConfig,
                accountId: holding.accountId,
                liquidityRequestId: holding.liquidityRequestId,
              }}
              disableUpload={disableUpload}
              disableDontHaveIcon={disableDontHaveIcon}
              doNotHaveThis={standardDoc.doNotHaveThis}
              onUploadSuccess={onUploadSuccess}
              onUploadStart={onUploadStart}
              onUploadCancel={onUploadCancel}
              onUploadError={onUploadError}
            />
          );
        }

        return null;
      })}
    </Fragment>
  );
};

export default StandardUploadedHoldingDocuments;
