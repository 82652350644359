import { useSelector } from "react-redux";
import { portalEnvConstants } from "common/shared";

interface SelectedLocaleData {
  locale: string;
  lang: string;
}

function useSelectedLocaleData(): SelectedLocaleData {
  const locale = useSelector((storeState: any) => {
    return storeState.shared.locale;
  });

  return {
    locale: locale.selectedLocale,
    lang:
      portalEnvConstants.getLanguageFromLocale(locale.selectedLocale) ||
      portalEnvConstants.defaultLanguage,
  };
}

export default useSelectedLocaleData;
