import React from "react";
import { Trans } from "@lingui/react";

export const HeaderBenSection = (
  <Trans id="documentsList.documents.title">View Ben-provided Documents</Trans>
);

export const HeaderClientSection = (
  <Trans id="transactionDocuments.clientSection.title">Client Documents</Trans>
);

export const DocumentsVerbiage = (
  <Trans id="transactionDocuments.DocumentsVerbiage.title">
    When requested, please provide a copy of your personal identification here.
  </Trans>
);
