import { LoginProfileData, LogoutBtn } from "./components";
import { useLastLogin, useLogoutData, usePermissionsRequest } from "./hooks";
import { ActionTypes, init, logout, removePermissions } from "./store/identity.actions";
import {
  getIdentityEpic,
  getPermissionsEpic,
  initEpic,
  logoutEpic,
  registrationEpic,
} from "./store/identity.epics";

export {
  ActionTypes,
  getIdentityEpic,
  registrationEpic,
  getPermissionsEpic,
  init,
  logout,
  removePermissions,
  initEpic,
  logoutEpic,
  LoginProfileData,
  LogoutBtn,
  useLastLogin,
  useLogoutData,
  usePermissionsRequest,
};
