const defaultConfig = {
  handlerEnabled: true,
  isPublic: false,
};

export const isHandlerEnabled = (config = defaultConfig) => {
  return config.hasOwnProperty("handlerEnabled") ? !!config.handlerEnabled : true;
};

export const isPublic = (config = defaultConfig) => {
  return config.hasOwnProperty("isPublic") ? !!config.isPublic : false;
};

export const logAPIError = (error: any) => {
  if (error) {
    if (error.browserErrorMessage) {
      console.error(`LOGOUT ERROR MESSAGE: ${error.browserErrorMessage}`);
    }
    if (error.request && error.request.status) {
      console.error(`LOGOUT ERROR STATUS: ${error.request.status}`);
    }
    if (error.request && error.request.statusText) {
      console.error(`LOGOUT ERROR STATUS_TEXT: ${error.request.statusText}`);
    }
    if (error.response && error.response.data && error.response.data.message) {
      console.error(`LOGOUT ERROR RESPONSE: ${error.response.data.message}`);
    }
  }
};
