import { combineReducers } from "redux";

import { authentication } from "domains/authentication/store/auth.reducer";
import { clients } from "domains/clients/store/clients.reducer";
import { identity } from "domains/identity/store/identity.reducer";
import { liquidity } from "domains/liquidity/store/liquidity.reducer";
import { document } from "domains/document/store/document.reducer";
import { ActionTypes } from "domains/identity";
import { shared } from "common/shared-store/reducers";
import { bootstrap, Bootstrap } from "common/shared-store/reducers/bootstrap";
import { LocaleState } from "common/shared-store/reducers/locale";

const appReducer = combineReducers({
  authentication,
  clients,
  identity,
  liquidity,
  document,
  shared,
  bootstrap,
});

const rootReducer = (state: any, action: any) => {
  let bootstrapSaved: Bootstrap = {
    navigateToAfterLogin: "",
    acceptRequestSharingData: null,
    acceptIdentityData: null,
    isAcceptIdentityLinkWasUsed: false,
    tokenForPass: null,
    isTokenForPassCompletedRequest: false,
    isTokenForPassSuccess: false,
    isTokenForPassWasUsed: false,
    routesToRedirect: {},
  };

  let localeSaved: LocaleState = {
    selectedLocale: "",
  };

  if (action.type === ActionTypes.LogoutSuccess) {
    bootstrapSaved = {
      ...state.bootstrap,
    };
    localeSaved = {
      ...state.shared.locale,
    };

    // TODO: it is not obvious to reset data in such way
    // state = undefined;
    state.authentication = undefined;
    state.clients = undefined;
    state.liquidity = undefined;
    state.document = undefined;
    state.shared = {
      locale: { ...localeSaved },
      breadcrumb: undefined,
      currency: undefined,
    };
    state.bootstrap = { ...bootstrapSaved };
  }

  return appReducer(state, action);
};

export default rootReducer;
