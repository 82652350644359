import UserLight from "./user.light.model";

export default class User extends UserLight {
  nickName: string;
  suffix: string;
  timeZoneAbbreviation: string;
  locale: string;
  lastLogin: string;
  street1: string;
  street2: string;
  state: string;
  city: string;
  region: string;
  postalCode: string;
  phoneType: string;
  country: string;
  accountId: string;
  accountName: string;
  accountType: string;
  userType: string;
  userId: string;
  accountDisplayId: string;
  userDisplayId: string;

  constructor({
    firstName = "",
    middleName = "",
    lastName = "",
    nickName = "",
    suffix = "",
    timeZoneAbbreviation = "",
    locale = "",
    lastLogin = "",
    street1 = "",
    street2 = "",
    state = "",
    city = "",
    region = "",
    postalCode = "",
    phoneNumber = "",
    phoneType = "",
    email = "",
    countryCode = "",
    country = "",
    accountId = "",
    accountName = "",
    accountType = "",
    userType = "",
    userId = "",
    accountDisplayId = "",
    userDisplayId = "",
  }) {
    super({ firstName, middleName, lastName, phoneNumber, email, countryCode });
    this.nickName = nickName;
    this.suffix = suffix;
    this.timeZoneAbbreviation = timeZoneAbbreviation;
    this.locale = locale;
    this.lastLogin = lastLogin;
    this.street1 = street1;
    this.street2 = street2;
    this.state = state;
    this.city = city;
    this.region = region;
    this.postalCode = postalCode;
    this.phoneType = phoneType;
    this.country = country;
    this.accountId = accountId;
    this.accountName = accountName;
    this.accountType = accountType;
    this.userType = userType;
    this.userId = userId;
    this.accountDisplayId = accountDisplayId;
    this.userDisplayId = userDisplayId;
  }

  getClientUserId(): string {
    return this.userId;
  }

  getClientUserFullName(): string {
    return this.fullName;
  }

  getClientUserEmail(): string {
    return this.email;
  }
}
