import React, { Fragment } from "react";

import { ArrayUtils } from "common/shared/utils";
import { IconCustom, BenCardContent, BenRowData, Spinner } from "common/lib/components";
import { Link, navigate } from "@reach/router";
import { Urls } from "common/lib/constants";

import { RequestProgressBar, SharedByItem } from "domains/liquidity/components";
import { InvestmentItemData } from "domains/liquidity/shared/types";

import { PropsLiquidityRequestCard } from "./types";

const LiquidityRequestCard: React.FC<PropsLiquidityRequestCard> = (
  props
): React.ReactElement | null => {
  const {
    cardTitle,
    requestId,
    holdings,
    isShowAlert,
    handleModalVisibility,
    step,
    withLink,
    showSharedInfo,
    isLoading,
    currentStepCode,
  } = props;
  const title = cardTitle || requestId;

  if (!title) {
    return null;
  }

  function clickableSection() {
    navigate(`${Urls.PROTECTED.LIQUIDITY_REQUEST}/${requestId}/`);
  }
  return (
    <BenCardContent>
      <div className="ben-header" onClick={clickableSection} style={{ cursor: "pointer" }}>
        <h3>
          {withLink && requestId ? (
            <Link
              id={`${requestId}`}
              to={`${Urls.PROTECTED.LIQUIDITY_REQUEST}/${requestId}/`}
              className="ben-link"
            >
              {title}
            </Link>
          ) : (
            title
          )}
          {isShowAlert && (
            <span className="ben-alert">
              <IconCustom type="attention" />
            </span>
          )}
        </h3>
      </div>

      {isLoading ? (
        <Spinner />
      ) : (
        <Fragment>
          <RequestProgressBar
            currentStep={step}
            currentStepCode={currentStepCode}
            handleModalVisibility={handleModalVisibility}
          />

          {holdings && ArrayUtils.isValidArrayData(holdings) ? (
            <Fragment>
              {holdings.map((item: InvestmentItemData) => {
                return (
                  <div
                    key={item.investmentId}
                    onClick={clickableSection}
                    style={{ cursor: "pointer" }}
                  >
                    <BenRowData key={item.investmentId} title={item.fundName} info={item.sponsor} />
                  </div>
                );
              })}
            </Fragment>
          ) : null}

          {showSharedInfo && (
            <SharedByItem
              title={showSharedInfo.createdByName}
              sharedByObjectType={showSharedInfo.sharedByObjectType}
            />
          )}
        </Fragment>
      )}
    </BenCardContent>
  );
};

export default LiquidityRequestCard;
