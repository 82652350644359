import { CurrencyListResponseData } from "common/shared/interfaces/currency";

export const ActionTypes = {
  GetCurrencies: "[Shared] GetCurrencies",
  GetCurrenciesFail: "[Shared] GetCurrencies Fail",
  GetCurrenciesSuccess: "[Shared] GetCurrencies Success",
};

// GET Currencies
export const getCurrencies = () => {
  return {
    type: ActionTypes.GetCurrencies,
  };
};

export const getCurrenciesSuccess = (data: CurrencyListResponseData) => {
  return {
    type: ActionTypes.GetCurrenciesSuccess,
    payload: data,
  };
};

export const getCurrenciesFail = () => {
  return {
    type: ActionTypes.GetCurrenciesFail,
  };
};
