import React from "react";
import { Link } from "@reach/router";

import { PropsLogo } from "./types";
import "./logo.scss";

const Logo: React.FC<PropsLogo> = (props): React.ReactElement | null => {
  const { src, width, alt, to, className, closeDrawerOnLInk } = props;
  const classes = "ben-logo " + (className || "");

  if (!src) {
    return null;
  }

  return (
    <Link className={classes} to={to} onClick={closeDrawerOnLInk}>
      <img src={src} width={width} alt={alt} />
    </Link>
  );
};

export default Logo;
