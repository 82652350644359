import React, { useEffect, useCallback, useState, useRef } from "react";
import { Trans } from "@lingui/react";
import { Form, Input, Button, Row, Col, Tooltip } from "antd";

import { useForm } from "common/shared-hooks";
import { getValidationErrors, localeService, sessionService } from "common/shared";
import { IconCustom } from "common/lib/components";
import { DomUtils } from "common/shared/utils";

import { PropsAssetCard } from "./types";
import {
  AssetFormSchema,
  assetValidationSchema,
  impersonateAssetDisabledConfig,
} from "./asset-card.form.config";
import "./asset-card.scss";

const removeBtnTooltip = <Trans id="tooltip.btn.remove">Remove</Trans>;

const AssetCard = ({ onStateChange, onDelete, deleteDisabled, idAttr }: PropsAssetCard) => {
  const formDisableConfig = (sessionService.isAppian && impersonateAssetDisabledConfig) || {};
  const formSchema = new AssetFormSchema({}, formDisableConfig);
  const {
    state,
    handleOnChange,
    handleOnBlur,
    hasDirtyInvalidField,
    isDisabledSubmit,
    isDirty,
  } = useForm(formSchema, assetValidationSchema, () => ({}));

  const [assetHeight, setAssetHeight] = useState(0);
  const assetRef = useRef<any>();
  useEffect(() => {
    if (assetRef) {
      setAssetHeight(assetRef.current.clientHeight);
    }
  }, []);

  const isDisabled = isDisabledSubmit();
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    onStateChange({ state, isDisabled, isDirty, hasDirtyInvalidField: hasDirtyInvalidField() });
  }, [state, isDisabled, isDirty, hasDirtyInvalidField]);

  const handleOnClick = useCallback(() => {
    onDelete();
    DomUtils.scroll(0, window.scrollY - assetHeight);
  }, [onDelete]);

  return (
    <div className="ben-form-holder asset-card-form" id={idAttr} ref={assetRef}>
      <Form>
        <Row gutter={30}>
          <Col md={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item
                className="ben-required-field ben-form-label-field"
                label={localeService.i18n._("liquidityRequest.form.fundName")}
                {...getValidationErrors(
                  state.fundName.errors,
                  localeService.i18n._("liquidityRequest.form.fundName")
                )}
              >
                <Input
                  name="fundName"
                  value={state.fundName.value}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  disabled={state.fundName.isDisabled}
                />
              </Form.Item>
            </div>
          </Col>
          <Col md={{ span: 12 }} xs={{ span: 24 }}>
            <div>
              <Form.Item
                className="ben-required-field ben-form-label-field"
                label={localeService.i18n._("liquidityRequest.form.sponsor")}
                {...getValidationErrors(
                  state.sponsor.errors,
                  localeService.i18n._("liquidityRequest.form.sponsor")
                )}
              >
                <Input
                  name="sponsor"
                  value={state.sponsor.value}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  disabled={state.sponsor.isDisabled}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>

      {!deleteDisabled && (
        <div className="ben-d-flex ben-justify-content-end">
          <Tooltip placement="left" title={removeBtnTooltip}>
            <Button
              id={`benAssetCardBtnDelete-${idAttr}`}
              type="link"
              className="ben-btn-reset"
              onClick={handleOnClick}
            >
              <IconCustom type="trash" style={{ fontSize: 20 }} />
            </Button>
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default AssetCard;
