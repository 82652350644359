import { ArrayUtils } from "common/shared/utils";

export const ID_TYPES = {
  account: "ACC",
  asset: "AST",
  document: "DOC",
  investment: "INV",
  liquidity: "LIQ",
  user: "USR",
};

export const isValidSystemId = (prefix: string, id: string): boolean => {
  const idParts = id.split("-");
  if (ArrayUtils.isValidArrayData(idParts)) {
    if (!idParts[0] || idParts[0] !== prefix) {
      return false;
    }
    if (!idParts[1] || !/^[0-9]{6}$/.test(idParts[1])) {
      return false;
    }
    return true;
  }
  return false;
};
