import AgreementModal from "./agreement-modal";
import ForgotPasswordModal from "./forgot-password-modal";
import PrivacyModal from "./privacy-modal";
import SessionModal from "./session-modal";
import TermsOfUseModal from "./terms-of-use-modal";
import VerifyInvestorModal from "./verify-investor-modal";

export {
  SessionModal,
  TermsOfUseModal,
  PrivacyModal,
  VerifyInvestorModal,
  ForgotPasswordModal,
  AgreementModal,
};
