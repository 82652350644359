import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";

import { ArrayUtils } from "common/shared/utils";

import { TypeUploadDirectoryDocument, TypeUploadingFileData } from "domains/document/shared/types";

import { Document } from "domains/document/modules/upload-directory";

import { PropsDocumentsList } from "./types";
import { getUpdatedUploadingFiles, isFilesListUploadingFinished } from "./helpers";

import "./documents-list.scss";

const EmptyListMessage = (
  <Trans id="documents.uploadDirectory.list.empty.message">Nothing to upload</Trans>
);

const DocumentsList: React.FC<PropsDocumentsList> = (props): React.ReactElement | null => {
  const [uploadingFiles, setUploadingFiles] = useState<TypeUploadingFileData>({});
  const [isProcessCompleted, setProcessCompleted] = useState<boolean>(false);

  const {
    documents,
    isProcessStarted,
    handleCancelUpload,
    handleRemoveFile,
    uploadingFile,
    maxUploadingProgress,
    handleFinishUploading,
    isMaxFilesError,
    isMaxLevelsError,
    maxFiles,
    maxNestedFolders,
  } = props;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isMaxFilesError || isMaxLevelsError) {
      return;
    }

    const uploadingFilesUpdated = getUpdatedUploadingFiles(uploadingFiles, uploadingFile);
    setUploadingFiles(uploadingFilesUpdated);

    const { isFinished, result } = isFilesListUploadingFinished(
      documents,
      uploadingFilesUpdated,
      maxUploadingProgress
    );

    if (isFinished && !isProcessCompleted) {
      setProcessCompleted(true);
      handleFinishUploading(result, uploadingFiles);
    }
  }, [uploadingFile, documents, handleFinishUploading, isMaxFilesError, isMaxLevelsError]);

  const isDocumentsExist = ArrayUtils.isValidArrayData(documents);

  return (
    <div className="upload-directory--documents-list">
      {isMaxFilesError ? (
        <p className="info-message" id="maxUploadingFilesMessage">
          <Trans
            id="documents.uploadDirectory.error.maxUploadingFiles.message"
            values={{ max: maxFiles }}
          />
        </p>
      ) : null}

      {isMaxLevelsError ? (
        <p className="info-message" id="maxNestedFoldersMessage">
          <Trans
            id="documents.uploadDirectory.error.maxUploadingFolders.message"
            values={{ max: maxNestedFolders }}
          />
        </p>
      ) : null}

      {!isMaxFilesError && !isMaxLevelsError && !isDocumentsExist ? (
        <p className="info-message" id="emptyListMessage">
          {EmptyListMessage}
        </p>
      ) : null}

      {!isMaxFilesError && !isMaxLevelsError && isDocumentsExist
        ? documents.map((item: TypeUploadDirectoryDocument) => {
            const uploadingFileErrors =
              uploadingFiles[item.fileId] && uploadingFiles[item.fileId].errors
                ? uploadingFiles[item.fileId].errors
                : null;

            const isCancelEnabledForItem = Boolean(
              uploadingFiles[item.fileId] &&
                uploadingFiles[item.fileId].createdData &&
                uploadingFiles[item.fileId].progress < maxUploadingProgress
            );

            return (
              <Document
                key={item.fileId}
                fileName={item.fileName}
                fileId={item.fileId}
                fileErrors={item.fileErrors || uploadingFileErrors}
                progress={uploadingFiles[item.fileId] ? uploadingFiles[item.fileId].progress : 0}
                isProcessStarted={isProcessStarted}
                isProcessCompleted={isProcessCompleted}
                isCancelEnabled={isCancelEnabledForItem}
                maxUploadingProgress={maxUploadingProgress}
                handleCancelUpload={handleCancelUpload(item.fileId)}
                handleRemoveFile={handleRemoveFile}
              />
            );
          })
        : null}
    </div>
  );
};

export default DocumentsList;
