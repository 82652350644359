import React from "react";
import { Trans } from "@lingui/macro";

import { IdUtils } from "common/shared/utils";

import { TypeInvitedObject, TypeCreateAssetData } from "domains/liquidity/shared/types";
import { AssetScheme } from "domains/liquidity/components/asset-card/types";

export const getInvitedObjectsCheckboxesMap = (
  clientName: string
): {
  [key in TypeInvitedObject]: React.ReactElement;
} => {
  return {
    you: <Trans id="newLiquidity.invitedObjects.you.title">You can't share with yourself</Trans>,
    client: <Trans id="newLiquidity.invitedObjects.client.title" values={{ clientName }} />,
    advisor: <Trans id="newLiquidity.invitedObjects.advisor.title">Share with an Advisor</Trans>,
  };
};

export const getParamsCreateAssets = (assets: AssetScheme[]): TypeCreateAssetData[] => {
  return assets.map((asset: AssetScheme) => ({
    fundName: asset.state.fundName.value,
    sponsor: asset.state.sponsor.value,
  }));
};

export const getNewAssetDefault = (): AssetScheme => {
  return {
    id: IdUtils.uuidv4(),
    state: null,
    isDisabled: true,
    isDirty: false,
    hasDirtyInvalidField: false,
  };
};

export const isDisabledFormSubmit = (isAppian: boolean, assets: AssetScheme[]) => {
  return (
    isAppian || !!assets.find((asset: AssetScheme) => asset.isDisabled) || !(assets.length > 0)
  );
};

export const isShowGeneralFormValidation = (assets: AssetScheme[]) => {
  return assets.length > 0 && !!assets.find((asset: AssetScheme) => asset.hasDirtyInvalidField);
};
