import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Row } from "antd";

import { Spinner } from "common/lib/components";
import { ArrayUtils } from "common/shared/utils";
import { portalEnvConstants } from "common/shared";
import { actions$ } from "common/shared-store/actions/actionsStream";

import { ActionTypes, getDocumentUrl } from "domains/document/store/document.actions";

import {
  OtherCategorizedHoldingDocuments,
  StandardCategorizedHoldingDocuments,
} from "domains/document/documents-list";

import { PropsCategorizedDocuments } from "./types";
import "./categorized-documents.scss";
import { HeaderEmptyDocumentsList, HeaderDocumentTitle, HeaderDateTitle } from "./constants";

// TODO: BCP-1073: to write tests use HoldingDocuments
const CategorizedDocuments: React.FC<PropsCategorizedDocuments> = (
  props
): React.ReactElement | null => {
  const {
    holdingData,
    currentUserId,
    userDisplayId,
    currentUserFullName,
    currentUserIp,
    locale,
  } = props;

  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState<boolean>(false);

  const { otherDocuments, standardDocuments, accountId } = holdingData;

  const lang =
    portalEnvConstants.getLanguageFromLocale(locale) || portalEnvConstants.defaultLanguage;

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.GetDocumentUrlFail, ActionTypes.GetDocumentUrlSuccess])
        .subscribe(() => {
          setLoading(false);
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const handleDownload = useCallback(
    (documentId: string) => () => {
      setLoading(true);

      const params = {
        accountId,
        documentId,
        currentUserId,
        userDisplayId,
        currentUserFullName,
        currentUserIp,
        locale,
        timezone: portalEnvConstants.defaultTimezone,
        version: "latest",
      };
      dispatch(getDocumentUrl(params));
    },
    [dispatch, accountId, currentUserId, userDisplayId, currentUserFullName, currentUserIp, locale]
  );

  const isStandardUploadedDocumentsExist = ArrayUtils.hasTruthyArrayData(
    Object.values(standardDocuments)
  );
  const isOtherHoldingDocumentsExist = ArrayUtils.isValidArrayData(otherDocuments);
  const isDocumentsExists = isStandardUploadedDocumentsExist || isOtherHoldingDocumentsExist;

  return (
    <div className="categorized-documents">
      <Spinner spinning={isLoading}>
        <div>
          {isDocumentsExists ? null : <p className="info-message">{HeaderEmptyDocumentsList}</p>}

          {/* col's sizes are from SimpleDocument component */}
          {isDocumentsExists ? (
            <Row type="flex" className="categorized-documents-table-header">
              <Col xs={18} sm={20} md={17}>
                <h4>{HeaderDocumentTitle}</h4>
              </Col>
              <Col xs={6} sm={4} md={4}>
                <h4>{HeaderDateTitle}</h4>
              </Col>
              <Col xs={0} sm={0} md={3} className="ben-d-sm-none" />
            </Row>
          ) : null}

          <div className="categorized-documents-table-content">
            <StandardCategorizedHoldingDocuments
              standardDocuments={standardDocuments}
              handleDownload={handleDownload}
              lang={lang}
            />
            <OtherCategorizedHoldingDocuments
              otherDocuments={otherDocuments}
              handleDownload={handleDownload}
              lang={lang}
            />
          </div>
        </div>
      </Spinner>
    </div>
  );
};

export default CategorizedDocuments;
