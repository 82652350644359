import React, { useEffect, useState } from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useDispatch } from "react-redux";
import { useQueryParams, StringParam } from "use-query-params";
import { Trans } from "@lingui/macro";

import { BenVerifyScreen } from "common/lib/components";
import { Urls } from "common/lib/constants";
import { tokenDecodeUtils } from "common/shared";
import { actions$ } from "common/shared-store/actions/actionsStream";

import {
  ActionTypes,
  acceptResetPassword,
  AcceptResetPasswordData,
  AcceptResetPasswordResponseData,
} from "domains/authentication";

import { TypeRedirectedFrom } from "pages/SetPasswordPage/types";

interface PropsVerifyResetPasswordPage extends RouteComponentProps {}
const verifyTitle = (
  <Trans id="verifyResetPasswordPage.verification.message.process">Verifying...</Trans>
);
const redirectedFrom: TypeRedirectedFrom = "verifyResetPasswordPage";

const VerifyResetPasswordPage: React.FC<PropsVerifyResetPasswordPage> = (): React.ReactElement | null => {
  const [query] = useQueryParams({ token: StringParam });
  const [redirectTo, setRedirectTo] = useState("");
  const dispatch = useDispatch();
  const { token } = query;

  const decodedTokenData = tokenDecodeUtils.decodeJwt(token || "");
  const email = (decodedTokenData && decodedTokenData["custom:email"]) || null;
  const isTokenExpired = token && tokenDecodeUtils.isTokenExpired(token);

  /**
   *  We should not detect changes of email, token, isTokenExpired.
   *  They are all from param "token". "token" is used during redirection to
   *  Urls.AUTH.SET_PASSWORD. But these two tokens are different.
   */

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!token || isTokenExpired || !email) {
      return;
    }

    // send request to get token for setting a password
    const payload: AcceptResetPasswordData = { email, token };
    dispatch(acceptResetPassword(payload));
  }, [dispatch]);

  useEffect(() => {
    const subscriptions: any[] = [];
    const { AcceptResetPasswordFail, AcceptResetPasswordSuccess } = ActionTypes;

    subscriptions.push(
      actions$.ofType([AcceptResetPasswordFail]).subscribe(() => {
        // if error status 401, token was already used - we do not have pages or messages fo this case
        setRedirectTo(`${Urls.AUTH.RESET_PASSWORD_EXPIRED}?token=${token}&resetMode=true`);
      })
    );

    subscriptions.push(
      actions$
        .ofType([AcceptResetPasswordSuccess])
        .subscribe(({ payload }: { payload: AcceptResetPasswordResponseData }) => {
          const { tokenForPass, accepted_terms } = payload;

          if (!tokenForPass) {
            setRedirectTo(Urls.AUTH.ERROR);
            return;
          } else {
            setRedirectTo(
              `${
                Urls.AUTH.SET_PASSWORD
              }?token=${tokenForPass}&preventSendingEmail=false&redirectedFrom=${redirectedFrom}${`&acceptedAgreementValue=${accepted_terms}`}`
            );
            return;
          }
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  if (!token || !email) {
    // redirect to error page. The path was not specified for this case.
    return <Redirect to={Urls.AUTH.ERROR_404} noThrow={true} />;
  }

  if (isTokenExpired) {
    return (
      <Redirect
        to={`${Urls.AUTH.RESET_PASSWORD_EXPIRED}?token=${token}&resetMode=true`}
        noThrow={true}
      />
    );
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} noThrow={true} />;
  }
  // Process of verifying
  return <BenVerifyScreen title={verifyTitle} />;
};

export default VerifyResetPasswordPage;
