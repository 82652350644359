import { useState, useEffect, useCallback } from "react";

import { ObjectUtils } from "common/shared/utils";
import FormInput from "common/shared/interfaces/form-input";
import FormConfig from "common/shared/interfaces/form-config";
import { validatorTypes } from "common/shared";

// stateSchema - {formInputName: {value, isDirty, errors}}
// validationSchema - {formInputName: validatorFunction[]}
function useForm(
  stateSchema: any,
  validationSchema: any,
  callback: any,
  validationSchemaDeps: any = {}
): FormConfig {
  const [state, setState] = useState(stateSchema);
  const [defaultState, setDefaultState] = useState(stateSchema);
  //   const [disable, setDisable] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [isValid, setIsValid] = useState(false);

  // Set form validity to false in initial render.
  useEffect(() => {
    setIsValid(false);
  }, []);

  // Used to disable submit button if there's an error in state
  // or the required field in state has no value.
  // Wrapped in useCallback to cached the function to avoid intensive memory leaked
  // in every re-render in component
  const validateState = useCallback(() => {
    const hasErrorInState = Object.keys(state).some((key) => {
      return state[key].errors;
    });

    return !hasErrorInState;
  }, [state]);

  /**
   * Newest version of checking if submit button should be disabled
   */
  const isDisabledSubmit = useCallback((): boolean => {
    const hasEmptyRequiredValuesInState = Object.keys(state).some((key) => {
      const isRequiredFuncExists = validationSchema[key].some((func: () => boolean) => {
        return func.prototype === validatorTypes.required.prototype;
      });

      if (
        (validationSchema[key] && isRequiredFuncExists && !state[key].value) ||
        state[key].errors
      ) {
        return true;
      }

      return false;
    });

    return hasEmptyRequiredValuesInState;
  }, [state, validationSchema]);

  /**
   * Check if state was changed
   */
  const isStateChanged = useCallback((): boolean => {
    return Object.keys(state).some((key) => {
      return state[key].value !== defaultState[key].value;
    });
  }, [state, defaultState]);

  /**
   * Check if errors in dirty fields
   */
  const hasDirtyInvalidField = useCallback((): boolean => {
    const hasDirtyInvalidFieldInState = Object.keys(state).some((key) => {
      return state[key].isDirty && state[key].errors && !ObjectUtils.isEmpty(state[key].errors);
    });

    return hasDirtyInvalidFieldInState;
  }, [state]);
  // For every changed in our state this will be fired
  // To be able to disable the button
  useEffect(() => {
    if (isDirty) {
      setIsValid(validateState());
    }
  }, [state, isDirty, validateState]);

  const resetForm = () => {
    setState(stateSchema);
    setIsValid(false);
    setIsDirty(false);
  };

  const validateField = (validators: any[], value: any, formValue: any) => {
    let errors = {};

    validators.forEach((validator) => {
      const ruleError = validator(value, formValue);

      if (ruleError) {
        errors = { ...errors, ...ruleError };
      }
    });

    if (ObjectUtils.isEmpty(errors)) {
      return null;
    }

    return errors;
  };

  const validateFieldDependencies = useCallback(
    (name: string, value: string) => {
      const errors: any[] = [];
      if (validationSchemaDeps) {
        const dependencies = validationSchemaDeps[name];
        if (dependencies) {
          dependencies.forEach((depsName: string) => {
            // todo
            // commented due to the BEN-2437
            // need discussion with the team
            // if (state[depsName].isDirty) {
            const depsError = validateField(validationSchema[depsName], state[depsName].value, {
              [name]: { value },
            });
            errors.push({
              name: depsName,
              errors: depsError,
            });
            // }
          });
        }
        return errors;
      }
      return errors;
    },
    [validationSchemaDeps, validationSchema, state]
  );

  // Used to handle every changes in every input
  const handleOnChange = useCallback(
    (event) => {
      setIsDirty(true);

      const name = event.target.name;
      const value = event.target.value;

      const errors = validateField(validationSchema[name], value, state);

      // if errors exists set to empty object not to display error on input itself
      // but validate submit button while input is onFocus
      setState((prevState: any) => ({
        ...prevState,
        [name]: { ...prevState[name], value, errors: errors === null ? null : {}, isDirty: true },
      }));

      const dependencyErrors = validateFieldDependencies(name, value);

      dependencyErrors.forEach((error) => {
        let updatedErrors: null | any[] = error.errors;
        // onChange validate only dependent fields
        if (error.name === name) {
          updatedErrors = null;
        }

        const { options = {} } = validationSchemaDeps;
        const { validateOnChange } = options;
        if (validateOnChange) {
          setState((prevState: any) => ({
            ...prevState,
            [error.name]: { ...prevState[error.name], errors: updatedErrors },
          }));
        }
      });
    },
    [validationSchema, state, validateFieldDependencies, validationSchemaDeps]
  );

  // Used to handle blur event in every input
  const handleOnBlur = useCallback(
    (event) => {
      setIsDirty(true);

      const name = event.target.name;
      let value = event.target.value;
      value = value.replace(/  +/g, " ").trim();

      const errors = validateField(validationSchema[name], value, state);

      setState((prevState: any) => ({
        ...prevState,
        [name]: { value, errors, isDirty: true },
      }));

      const dependencyErrors = validateFieldDependencies(name, value);

      dependencyErrors.forEach((error) => {
        setState((prevState: any) => {
          if (!prevState[error.name].isDirty && error.errors) {
            error.errors = {};
          }
          return {
            ...prevState,
            [error.name]: { ...prevState[error.name], errors: error.errors },
          };
        });
      });
    },
    [validationSchema, state, validateFieldDependencies]
  );

  const handleOnSubmit = useCallback(
    (event?: any) => {
      if (event) {
        event.preventDefault();
      }

      if (validateState()) {
        const submitData: any = {};
        Object.keys(state).forEach((key) => {
          submitData[key] = state[key].value;
        });
        setDefaultState(state);
        callback(submitData);
      }
    },
    [state, callback, validateState]
  );

  const isFieldValid = (field: FormInput) => {
    return isDirty && !field.errors;
  };

  const resetIsDirty = () => {
    setIsDirty(false);
  };

  const resetField = (fieldName: string) => {
    setState((prevState: any) => ({
      ...prevState,
      [fieldName]: { ...defaultState[fieldName] },
    }));
  };

  return {
    state,
    resetForm,
    resetField,
    isValid,
    isFieldValid,
    isDirty,
    resetIsDirty,
    handleOnChange,
    handleOnBlur,
    handleOnSubmit,
    validateState,
    isDisabledSubmit,
    hasDirtyInvalidField,
    isStateChanged,
  };
}

export default useForm;
