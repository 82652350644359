import React from "react";
import { Trans } from "@lingui/macro";
import BenPhone from "../ben-phone";

import "./ben-form-row-info.scss";

export type FormRowInfoType = "required" | "validation" | "changePrimary";

interface FormRowInfoProps {
  type: FormRowInfoType;
  classNames?: string;
}

export const BenFormRowInfo: React.FC<FormRowInfoProps> = ({
  type,
  classNames,
}): React.ReactElement => {
  const requiredIndicatorText = <Trans id="required.helper">* Indicates required fields</Trans>;
  const validationErrorText = (
    <Trans id="form.validation.general">Please, correct the highlighted fields below.</Trans>
  );

  const changePrimaryText = (
    <Trans
      id="benChangePrimaryInfo.message"
      values={{
        phoneNumber: <BenPhone />,
      }}
    >
      To make changes to your primary contact information please contact Ben at
    </Trans>
  );

  const getTextByType = () => {
    switch (type) {
      case "required":
        return requiredIndicatorText;
      case "validation":
        return validationErrorText;
      case "changePrimary":
        return changePrimaryText;
    }
  };

  return (
    <div className={`ben-required-text ${classNames}`}>
      <p>
        <strong className="ben-required-label">{getTextByType()}</strong>
      </p>
    </div>
  );
};

export default BenFormRowInfo;
