import React from "react";

import { BenBreadcrumb } from "common/lib/components";
import { ArrayUtils } from "common/shared/utils";
import { useBreadcrumbItems } from "common/shared-hooks";
import { BreadcrumbItem } from "common/shared/interfaces/breadcrumb";
import { LoginProfileData } from "domains/identity";

import { PropsDashboardHeader } from "./types";

const DashboardHeader: React.FC<PropsDashboardHeader> = () => {
  const { data: breadcrumbItems }: { data: BreadcrumbItem[] } = useBreadcrumbItems();
  const isShowBreadcrumbs = ArrayUtils.isValidArrayData(breadcrumbItems);

  return (
    <div className="ben-account-header ben-d-sm-none">
      {isShowBreadcrumbs && <BenBreadcrumb items={breadcrumbItems} />}

      <LoginProfileData />
    </div>
  );
};

export default DashboardHeader;
