import React, { useState, useCallback, useEffect } from "react";
import { Trans } from "@lingui/macro";
import { Form, Checkbox, Col, Row } from "antd";

import { PrivacyTermsInfo } from "domains/authentication/components";
import { AgreementModal } from "domains/authentication/modals";

import { PropsTermsAgreement } from "./types";
import "./terms-agreement.scss";

const TermsAgreement: React.FC<PropsTermsAgreement> = (props): React.ReactElement => {
  const [isAgreementModalVisible, setAgreementModalVisible] = useState<boolean>(false);
  const [isAgreementTermsChecked, setAgreementTermsChecked] = useState<boolean>(false);
  const [isSecondaryAgreementChecked, setSecondaryAgreementChecked] = useState<boolean>(false);

  const { isAcceptedTermsAgreement, setAcceptedAgreementValid } = props;

  useEffect(() => {
    if (isAcceptedTermsAgreement !== false) {
      setAcceptedAgreementValid(true);
      return;
    }
    if (
      isAcceptedTermsAgreement === false &&
      isAgreementTermsChecked &&
      isSecondaryAgreementChecked
    ) {
      setAcceptedAgreementValid(true);
      return;
    }
    setAcceptedAgreementValid(false);
    return;
  }, [
    isAcceptedTermsAgreement,
    isSecondaryAgreementChecked,
    isAgreementTermsChecked,
    setAcceptedAgreementValid,
  ]);

  const handleAgreementModalVisibility = useCallback(
    (value: boolean) => () => {
      setAgreementModalVisible(value);
    },
    [setAgreementModalVisible]
  );

  const handleSecondaryAgreementChecking = useCallback(() => {
    setSecondaryAgreementChecked(!isSecondaryAgreementChecked);
  }, [isSecondaryAgreementChecked]);

  const handleAgreementModalBtn = useCallback(
    (value: boolean) => () => {
      setAgreementTermsChecked(value);
      setAgreementModalVisible(false);
    },
    []
  );

  return (
    <Row>
      {isAcceptedTermsAgreement === false ? (
        <Col sm={{ span: 12, offset: 12 }}>
          <Form.Item>
            <Checkbox
              id={"agreementTermCheckbox"}
              checked={isAgreementTermsChecked}
              onClick={handleAgreementModalVisibility(true)}
            >
              <Trans id="Agreement.checkbox.title">I agree to the </Trans>{" "}
              <span className="ben-important-text">
                <Trans id="AgreemenTerm.Modal.title">Confidentiality Agreement</Trans>
              </span>
            </Checkbox>
          </Form.Item>
          <Form.Item>
            <Checkbox
              checked={isSecondaryAgreementChecked}
              id={"termOfUseCheckbox"}
              onClick={handleSecondaryAgreementChecking}
            >
              <Trans id="TermsOfUse.checkbox.label">I have read and agree to the</Trans>{" "}
              <PrivacyTermsInfo withUnderline={true} />
            </Checkbox>
          </Form.Item>
        </Col>
      ) : null}
      <AgreementModal
        isAgreementModalVisible={isAgreementModalVisible}
        handleAgreementModalBtn={handleAgreementModalBtn}
        handleAgreementModalVisibility={handleAgreementModalVisibility}
      />
    </Row>
  );
};

export default TermsAgreement;
