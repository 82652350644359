import React from "react";
import { Trans } from "@lingui/macro";

import { ProcessModalDescription } from "./types";
import { TypeLiquidityTypeCode } from "domains/liquidity/shared/types";

export const ProcessModalDescriptions: {
  [key in TypeLiquidityTypeCode]: ProcessModalDescription;
} = {
  in_verification: {
    title: <Trans id="processModal.description.in_verification.title">In Review</Trans>,
    text: (
      <Trans id="processModal.description.in_verification.text">
        Thank you for submitting your request for liquidity. Our Underwriting team is reviewing the
        information you have provided and may request additional details or documents. We will be in
        touch soon with next steps. In the meantime, please feel free to contact our Originations
        team at any time.
      </Trans>
    ),
  },
  in_underwriting: {
    title: <Trans id="processModal.description.in_underwriting.title">In Underwriting</Trans>,
    text: (
      <Trans id="processModal.description.in_underwriting.text">
        At this stage, Ben is conducting research on the holdings submitted for liquidity in order
        to provide the best proposal possible.
      </Trans>
    ),
  },
  in_proposal: {
    title: <Trans id="processModal.description.in_proposal.title">In Proposal</Trans>,
    text: (
      <Trans id="processModal.description.in_proposal.text">
        At this stage, Ben has provided a Binding Term Sheet, including a proposal, for your review
        and acceptance. This document includes Client Information forms for you to complete for our
        client onboarding requirements.
      </Trans>
    ),
  },
  client_signed: {
    title: <Trans id="processModal.description.client_signed.title">Client Signed</Trans>,
    text: (
      <Trans id="processModal.description.client_signed.text">
        At this stage, you have accepted our proposal and Ben is taking action towards closing the
        transaction.
      </Trans>
    ),
  },
  cancelled: {
    title: <Trans id="processModal.description.cancelled.title">Cancelled</Trans>,
    text: (
      <Trans id="processModal.description.cancelled.text">
        The Liquidity Request has not been completed.
      </Trans>
    ),
  },
  closed: {
    title: <Trans id="processModal.description.closed.title">Closed</Trans>,
    text: (
      <Trans id="processModal.description.closed.text">
        At this stage, the transaction has closed. If this liquidity request does not lead to a
        closed transaction for any reason, this status will read as Cancelled.
      </Trans>
    ),
  },
};
