import React from "react";
import { Trans } from "@lingui/react";
import { useProfileData } from "domains/clients/hooks";
import "./greeting-profile.scss";

const GreetingProfile: React.FC = (): React.ReactElement | null => {
  const { user } = useProfileData();

  return (
    <div className="ben-greetings">
      {user && user.firstName && (
        <div className="ben-greetings-name">
          <Trans id="profile.greetingProfile.title">Welcome</Trans>, {user.firstName}
        </div>
      )}
    </div>
  );
};

export default GreetingProfile;
