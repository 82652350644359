import axios, { AxiosResponse, CancelTokenSource } from "axios";

import { crossTabMessageService, portalEnvConstants, CROSS_TAB_EVENTS } from "common/shared";

import {
  errorHandler,
  successHandler,
} from "common/shared/services/api.interceptors/api.interceptors";
import ApiEnvConstants from "common/shared/services/api.env.constants/api.env.constants";
import { isHandlerEnabled } from "common/shared/services/api.helpers/api.helpers";
import {
  getImpersonationHeaders,
  getPrivateHeaders,
} from "common/shared/services/api.headers/api.headers";
import { showRefreshModal } from "common/shared-store/actions/version";
import { pushAction } from "common/shared-store/actions/actionsStream";
import { sessionService } from "common/shared";

const VERSION_URL = "/version.txt";

export class ApiService {
  static readonly TIMEOUT = 60000;

  constructor() {
    // set request interceptors to add common logic (Auth header, etc.)
    // is disabled while axios has issue with custom configs, should be fixed in axios 0.19.1
    // axios.interceptors.request.use(requestHandler);
    /*
     set response interceptors to add common logic
     (parse json response, show error msg, etc.)
    */
    axios.interceptors.response.use(
      (response: AxiosResponse<any>) => successHandler(response),
      (error) => errorHandler(error)
    );

    // Add a request interceptor
    axios.interceptors.request.use((config) => {
      config.headers.Pragma = "no-cache";
      return config;
    });

    axios.interceptors.request.use(async (config: any) => {
      if (!config.url.includes(VERSION_URL)) {
        let newVersion: any;
        try {
          newVersion = await this.getVersion();
        } catch (e) {
          return config;
        }

        const currentVersion = sessionService.getVersion();
        if (currentVersion !== newVersion) {
          sessionService.setVersion(newVersion);
          if (currentVersion) {
            crossTabMessageService.emitEvent(CROSS_TAB_EVENTS.VERSION_UPDATED, "", false);
            pushAction(showRefreshModal());
            throw new axios.Cancel("Operation canceled due to the app version was updated.");
          }
        }
      }

      return config;
    });

    this.getEndpoints().then(
      (env: any) => {
        ApiEnvConstants.setApiEnv(env);
      },
      () => {
        ApiEnvConstants.setApiEnv(null);
      }
    );
  }

  getVersion = async () => {
    return await axios.get(`${VERSION_URL}?timestamp=${Date.now()}`, {
      timeout: ApiService.TIMEOUT,
    });
  };

  getCancelToken(): CancelTokenSource {
    const cancelToken = axios.CancelToken;
    return cancelToken.source();
  }

  cancel(cancelToken: any, message?: string) {
    cancelToken.cancel(message);
    return this.getCancelToken();
  }

  getCancelFn() {
    return axios.isCancel;
  }

  async getResource(path: string, params?: any) {
    // is hardcoded instead od interceptor while axios has issue with custom configs, should be fixed in axios 0.19.1
    if (isHandlerEnabled(params)) {
      const impersonationHeaders = getImpersonationHeaders();
      const privateHeaders = getPrivateHeaders();
      params = {
        ...params,
        headers: { ...impersonationHeaders, ...privateHeaders },
      };
    }
    return axios.get(path, params);
  }

  async postResource(path: string, data: any, config: any = {}) {
    // is hardcoded instead od interceptor while axios has issue with custom configs, should be fixed in axios 0.19.1
    if (isHandlerEnabled(config)) {
      const impersonationHeaders = getImpersonationHeaders();
      const privateHeaders = getPrivateHeaders();
      const customHeaders = (config && config.headers) || {};
      config = {
        ...config,
        headers: { ...impersonationHeaders, ...privateHeaders, ...customHeaders },
      };
    }
    return axios.post(path, data, { timeout: ApiService.TIMEOUT, ...config });
  }

  async putResource(path: string, data: any, config: any = {}) {
    // is hardcoded instead od interceptor while axios has issue with custom configs, should be fixed in axios 0.19.1
    if (isHandlerEnabled(config)) {
      config = { ...config, headers: getPrivateHeaders() };
    }
    return axios.put(path, data, { timeout: ApiService.TIMEOUT, ...config });
  }

  async patchResource(path: string, data: any, config?: any) {
    // is hardcoded instead od interceptor while axios has issue with custom configs, should be fixed in axios 0.19.1
    if (isHandlerEnabled(config)) {
      const impersonationHeaders = getImpersonationHeaders();
      const privateHeaders = getPrivateHeaders();
      const customHeaders = (config && config.headers) || {};
      config = {
        ...config,
        headers: { ...impersonationHeaders, ...privateHeaders, ...customHeaders },
      };
    }
    return axios.patch(path, data, config);
  }

  async deleteResource(path: string, params?: any) {
    // is hardcoded instead od interceptor while axios has issue with custom configs, should be fixed in axios 0.19.1
    if (isHandlerEnabled(params)) {
      params = { ...params, headers: getPrivateHeaders() };
    }
    return axios.delete(path, params);
  }

  async getEndpoints() {
    const result: any = await axios.get("/env.json", { timeout: ApiService.TIMEOUT });
    portalEnvConstants.setData(result);
    return result;
  }
}

const apiService = new ApiService();
export default apiService;

export { VERSION_URL };
