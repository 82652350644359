import { validatorTypes } from "common/shared";
import FormInput from "common/shared/interfaces/form-input";

export class ForgotPasswordFormSchema {
  public username: FormInput = {
    value: "",
    isDirty: false,
  };
}

export const forgotPasswordValidationSchema = {
  username: [validatorTypes.required, validatorTypes.emailPattern],
};
