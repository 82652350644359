export class ObjectUtils {
  isEmpty(obj: Object) {
    if (!obj) {
      return true;
    }

    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }
}

const objUtils = new ObjectUtils();
export default objUtils;
