import { useSelector } from "react-redux";
import { DocumentItemData, HoldingsDocumentsDataProps } from "../shared/types";
import { HoldingItemData } from "domains/liquidity/shared/types";
import { STANDARD_INVESTMENT_DOCUMENT_LIST } from "domains/document/shared/constants";
import documentService from "../shared/document.service";

function useHoldingDocumentsData(): HoldingsDocumentsDataProps {
  const result = useSelector(
    (state: any): HoldingsDocumentsDataProps => {
      const data = {
        holdings: [],
        loading: true,
      };

      const {
        data: { items },
        loading: holdingDocumentsLoading,
      } = state.document.holdingDocuments;

      const {
        single: { data: liquidityData, loading: liquidityLoading },
      } = state.liquidity;

      if (liquidityLoading || holdingDocumentsLoading) {
        return data;
      }

      data.loading = false;
      data.holdings = liquidityData.holdings.map((holding: HoldingItemData) => {
        const documents = items.filter(
          (document: DocumentItemData) => document.assetId === holding.assetId
        );

        const { standardDocuments, otherDocuments } = documentService.getNormalizedDocuments(
          documents,
          STANDARD_INVESTMENT_DOCUMENT_LIST
        );

        return {
          ...holding,
          accountId: liquidityData.accountId,
          liquidityRequestId: liquidityData.id,
          standardDocuments,
          otherDocuments,
        };
      });

      return data;
    }
  );

  return result;
}

export default useHoldingDocumentsData;
