import React, { Fragment } from "react";
import { localeService } from "common/shared";

const AssetTitle: React.FC<{ quantity: number }> = (props): React.ReactElement => {
  const { quantity } = props;
  return (
    <Fragment>
      {localeService.i18n._(
        `${localeService.plural({
          value: quantity,
          one: "asset.count.one",
          other: "asset.count.other",
        })}`,
        { count: quantity }
      )}
    </Fragment>
  );
};

export default AssetTitle;
