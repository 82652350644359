import React from "react";
import { PropsBenVerifyScreen } from "./types";
import Spinner from "../spinner";

const BenVerifyScreen: React.FC<PropsBenVerifyScreen> = (props): React.ReactElement | null => {
  const { title } = props;

  if (!title) {
    return null;
  }

  return (
    <Spinner spinning={true}>
      <section className="ben-main-section">
        <div className="ben-panel">
          <h1 className="ben-text-center">{title}</h1>
        </div>
      </section>
    </Spinner>
  );
};

export default BenVerifyScreen;
