import { impersonateService, sessionService, apiEnvs } from "common/shared";

interface TypeAuthorizationHeader {
  Authorization: string;
}
interface TypeImpersonationHeaders {
  "bcp-impersonation-payload": string;
  "bcp-impersonation-iv": string;
  "bcp-user-context"?: string;
}
interface TypeTenantHeader {
  "bcp-whitelabel": string;
}
interface TypePrivateHeaders extends TypeTenantHeader {
  Authorization?: string;
}

export const getAuthorizationHeader = (): TypeAuthorizationHeader | null => {
  const tokens = sessionService.getTokens();
  return (tokens && { Authorization: `Bearer ${tokens.access_token}` }) || null;
};

export const getImpersonationHeaders = (): TypeImpersonationHeaders | {} | undefined => {
  if (!sessionService.isAppian) {
    return {};
  }

  const impersonateData = impersonateService.getImpersonationPayload();
  if (impersonateData) {
    const { encryptedPayload, initVector, userPayload } = impersonateData;
    const userContextHeaders = userPayload ? { "bcp-user-context": userPayload } : {};
    return {
      ...userContextHeaders,
      "bcp-impersonation-payload": encryptedPayload,
      "bcp-impersonation-iv": initVector,
    };
  }

  return;
};

export const getTenantHeader = (): TypeTenantHeader => {
  return { "bcp-whitelabel": apiEnvs.whitelabel };
};

export const getPrivateHeaders = (): TypePrivateHeaders => {
  const authHeader = getAuthorizationHeader();
  if (authHeader) {
    return { ...authHeader, ...getTenantHeader() };
  }

  return { ...getTenantHeader() };
};
