import React from "react";
import { PropsBenRowData } from "./types";

import "./ben-row-data.scss";

const BenRowData: React.FC<PropsBenRowData> = (props): React.ReactElement | null => {
  const { title, info, withBottomBorder } = props;

  if (!title) {
    return null;
  }

  const styleBorderBottom = withBottomBorder === false ? { borderBottom: "none" } : {};
  const styles = {
    ...styleBorderBottom,
  };

  return (
    <div className="ben-row-data" style={styles}>
      <h4>{title}</h4>
      {info && <p>{info}</p>}
    </div>
  );
};

export default BenRowData;
