import React, { useEffect } from "react";
import { RouteComponentProps, navigate } from "@reach/router";
import { useDispatch } from "react-redux";
import { StringParam, useQueryParams } from "use-query-params";
import { Trans } from "@lingui/macro";

import { Urls } from "common/lib/constants";
import { CardErrorSection } from "common/lib/components";
import { messageService, tokenDecodeUtils } from "common/shared";
import image from "common/assets/images/404-lock.svg";
import { actions$ } from "common/shared-store";
import { useGetAppVersion } from "common/shared-hooks";
import { CommonUrlParams } from "common/lib/constants/params";
import { CommonMessages } from "common/lib/constants/messages";

import { forgotPassword, ActionTypes } from "domains/authentication/store/auth.actions";

import { PublicPageWrapper } from "pages/components";

interface PropsResetPasswordExpiredPage extends RouteComponentProps {}
const titleDefault = <Trans id="resetPasswordExpired.title">Your request has expired.</Trans>;
const messageDefault = (
  <Trans id="resetPasswordExpired.message">
    For your security, requests expire after 24 hours. Please request a new email to reset your
    password.
  </Trans>
);
const btnText = <Trans id="resetPasswordExpired.btnText">Reset Your Password</Trans>;

const ResetPasswordExpiredPage: React.FC<PropsResetPasswordExpiredPage> = (): React.ReactElement => {
  const [query] = useQueryParams({
    token: StringParam,
    resetMode: StringParam,
    [CommonUrlParams.withCommonExpiredMessage]: StringParam,
  });
  const {
    token,
    resetMode,
    [CommonUrlParams.withCommonExpiredMessage]: withCommonExpiredMessage,
  } = query;

  const dispatch = useDispatch();
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  const handleRedirectTo = () => {
    navigate(Urls.AUTH.BASE);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$
        .ofType([ActionTypes.ForgotPasswordSuccess, ActionTypes.ForgotPasswordFail])
        .subscribe(() => {
          handleRedirectTo();
        })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  const handleInvalidToken = () => {
    messageService.error("toaster.resetPassword.tokenExpired");
    handleRedirectTo();
  };

  const resendResetLink = () => {
    if (!token) {
      handleInvalidToken();
      return;
    }

    const decodedTokenData = tokenDecodeUtils.decodeJwt(token);
    if (!decodedTokenData || !decodedTokenData["custom:email"]) {
      handleInvalidToken();
      return;
    }
    dispatch(forgotPassword({ userEmail: decodedTokenData["custom:email"], resetMode }));
  };

  let title;
  let message;
  if (withCommonExpiredMessage) {
    title = CommonMessages.expiredLink;
  } else {
    title = titleDefault;
    message = messageDefault;
  }

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <CardErrorSection
        title={title}
        message={message}
        loading={false}
        handleOnClick={resendResetLink}
        btnText={btnText}
        image={image}
        noPanel={true}
      />
    </PublicPageWrapper>
  );
};

export default ResetPasswordExpiredPage;
