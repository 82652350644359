import { DocumentItemData, Holding } from "domains/document/shared/types";

export const getParsedCategorizedDocuments = (
  holdingsWithDocuments: Holding[]
): DocumentItemData[] => {
  let categorizedDocuments: DocumentItemData[] = [];
  holdingsWithDocuments.forEach((holding: Holding) => {
    let standardDocuments = new Array<DocumentItemData>();

    Object.values(holding.standardDocuments).filter((item: any) => {
      return item.forEach((doc: any) => {
        standardDocuments.push(doc);
      });
    });

    categorizedDocuments = categorizedDocuments.concat(holding.otherDocuments, standardDocuments);
  });

  return categorizedDocuments;
};
