import React from "react";
import { Link } from "@reach/router";
import classnames from "classnames";

interface PropsLinkCustom {
  size?: string;
  type: string;
  shape: string;
  ghost?: boolean;
  className?: string;
  id?: string;
  to: string;
  children: React.ReactNode;
  disabled?: Boolean;
}

const LinkCustom = (props: PropsLinkCustom): React.ReactElement => {
  const size = (props.size === "large" && "lg") || (props.size === "small" && "sm");

  const params = {
    [`ant-btn-${props.type}`]: props.type,
    [`ant-btn-${props.shape}`]: props.shape,
    [`ant-btn-${size}`]: props.size,
    "ant-btn-background-ghost": props.ghost,
  };
  if (props.className) {
    params[props.className] = props.className;
  }
  const classes = classnames("ant-btn", params);

  return (
    <Link className={classes} id={props.id} to={props.to}>
      {props.children}
    </Link>
  );
};

export default LinkCustom;
