import React from "react";
import { portalEnvConstants } from "common/shared";

const BenPhone: React.FC = (): React.ReactElement | null => {
  return (
    <a href={`tel:${portalEnvConstants.defaultPhoneNumber}`} className="ben-phone">
      {portalEnvConstants.defaultPhoneNumber}
    </a>
  );
};

export default BenPhone;
