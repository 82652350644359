import React, { useState, Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Upload } from "antd";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";

import { FileUploaderService, sessionService } from "common/shared";
import { CANCEL_MSG } from "common/shared/services/file/fileUploader.constants";
import { ParamUploadDocumentRequest } from "common/shared/services/file/fileUploader.types";
import { actions$ } from "common/shared-store";
import apiService from "common/shared/services/api.service/api.service";
import { useIsMountedRef } from "common/shared-hooks";

import { confirmUpload, ActionTypes } from "domains/document/store/document.actions";
import { CreateDocumentResponseData } from "domains/document/shared/types";
import { UploadingDocumentRow } from "domains/document/components";

import "./upload-document.scss";
import { PropsUploadDocument } from "./types";

const { Dragger } = Upload;

const UploadDocument: React.FC<PropsUploadDocument> = (props): React.ReactElement | null => {
  const {
    documentConfig,
    isDragger,
    openFileDialogOnClick,
    typeOfNameToShowOnSuccess,
    isAddToRequestDocumentName,
    onUploadSuccess,
    onUploadCancel,
    onUploadStart,
    onUploadError,
    onConfirmUploadSuccess,
    documentName,
    disableUpload = false,
    isShowUploadingName,
    customFlow = false,
    children,
  } = props;

  const [uploadProgress, setUploadProgress] = useState<null | number>(null);
  const [fileName, setFileName] = useState<null | string>(null);
  const [fileExtension, setFileExtension] = useState<null | string>(null);
  const [progressClassName, setProgressClassName] = useState<string>("");
  const [cancelSource, setCancelSource] = useState(apiService.getCancelToken());
  const [createdDocument, setCreatedDocument] = useState<CreateDocumentResponseData | null>(null);
  // True when exactly  file upload starts (the second request on flow)
  const [isCancelEnabled, setIsCancelEnabled] = useState<boolean>(false);

  const dispatch = useDispatch();
  /**
   * Fix: isMountedRef - React state update on an unmounted component
   */
  const isMountedRef = useIsMountedRef();

  const { doNotHaveThisDocument, ...config } = documentConfig;

  const resetFile = () => {
    if (!isMountedRef.current) {
      return;
    }
    setCreatedDocument(null);
    setFileName(null);
    setFileExtension(null);
    setUploadProgress(0);
    setProgressClassName("");
    setIsCancelEnabled(false);
  };

  const setUploadFileAttributes = (name: string, extension: string) => {
    setUploadProgress(1);
    setProgressClassName("ben-uploading");
    setFileName(name);
    setFileExtension(`application/${extension}`);
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.ConfirmUploadSuccess).subscribe((action: any) => {
        // check if subscription is of activated upload
        if (!fileName) {
          return;
        }
        const { cancelConfirmation } = action.payload;
        if (!cancelConfirmation) {
          let name = fileName;
          if (typeOfNameToShowOnSuccess === "document" && config.documentName) {
            name = config.documentName;
          }

          FileUploaderService.showFileUploadSuccessMessage(name);

          if (createdDocument) {
            onUploadSuccess({
              ...createdDocument,
              documentName: config.documentName || fileName,
            });
          }

          if (onConfirmUploadSuccess && createdDocument) {
            onConfirmUploadSuccess({
              ...createdDocument,
              documentName: config.documentName || fileName,
            });
          }
        }
        resetFile();
      }),
      actions$.ofType(ActionTypes.ConfirmUploadFail).subscribe(() => {
        // check if subscription is of activated upload
        if (!fileName) {
          return;
        }

        handleUploadError();
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [fileName, config, createdDocument]);

  function handleUploadError() {
    resetFile();
    onUploadError();
  }

  const onUploadFileWithCustomFlowSuccessHandler = () => {
    if (!createdDocument) {
      return;
    }
    onUploadSuccess({
      ...createdDocument,
      documentName: fileName || "",
    });
  };

  const onUploadFileSuccessHandler = () => {
    if (!createdDocument) {
      return;
    }
    dispatch(
      confirmUpload({
        documentId: createdDocument.documentId,
        versionId: createdDocument.versionId,
        accountId: config.accountId,
      })
    );
  };

  const onCancelUploadError = () => {
    if (!createdDocument) {
      return;
    }
    dispatch(
      confirmUpload({
        documentId: createdDocument.documentId,
        versionId: createdDocument.versionId,
        accountId: config.accountId,
        doNotHaveThisDocument,
        reject: true,
      })
    );
  };

  const onBeforeUploadHandler = (file: File) => {
    if (sessionService.isAppian) {
      return false;
    }

    const paramToValidate = {
      file,
      docFormats: null,
      isValidateName: isShowUploadingName,
    };

    if (!FileUploaderService.canUploadFile(paramToValidate)) {
      return false;
    }

    const { name } = file;
    const extension = FileUploaderService.getExtensionFromFile(file);
    // show progress before upload starts while waiting for createDocumentResponse
    setUploadFileAttributes(name, extension);
    onUploadStart(name);

    const param: ParamUploadDocumentRequest = {
      config,
      file,
      setCreatedDocumentHandler: setCreatedDocument,
      uploadErrorHandler: handleUploadError,
      cancelToken: cancelSource.token,
    };

    if (config.newVersionOfUniqueDocumentConfirmed) {
      return FileUploaderService.updateDocumentRequest(param);
    }

    // update DocumentConfig with a custom name
    if (isAddToRequestDocumentName) {
      config.documentName = name;
    }

    return FileUploaderService.createDocumentRequest(param);
  };

  const onUploadProgressHandler = (event: { percent: number }) => {
    const progress = Math.ceil(event.percent) || 1; // minimal progress on upload
    setUploadProgress(progress);
  };

  const onUploadErrorHandler = (event: Error, body?: object) => {
    handleUploadError();
  };

  const customRequestHandler = ({ onProgress, onError, file, headers }: RcCustomRequestOptions) => {
    const uploadUrl =
      createdDocument && createdDocument.uploadUrl ? createdDocument.uploadUrl : null;
    FileUploaderService.uploadFileRequest({
      file,
      headers,
      onProgress,
      onError,
      uploadUrl,
      fileExtension,
      cancelToken: cancelSource.token,
      isCustomFlow: customFlow,
      cancelEnablingHandler: setIsCancelEnabled,
      onCustomFlowSuccessCallbackHandler: onUploadFileWithCustomFlowSuccessHandler,
      onSuccessCallbackHandler: onUploadFileSuccessHandler,
      onCancelUploadError,
    });
  };

  const handleCancelUpload = () => {
    if (!fileName) {
      return;
    }

    onUploadCancel();
    setCancelSource(apiService.cancel(cancelSource, CANCEL_MSG));
    FileUploaderService.showFileUploadCanceledMessage(fileName);
  };

  const uploadHandlersProps = {
    beforeUpload: onBeforeUploadHandler,
    onProgress: onUploadProgressHandler,
    onError: onUploadErrorHandler,
    customRequest: customRequestHandler,
  };

  const UploadComponent = isDragger ? Dragger : Upload;

  return (
    <Fragment>
      <UploadComponent
        {...uploadHandlersProps}
        name="file"
        headers={{ "Content-Type": `${fileExtension}` }}
        showUploadList={false}
        className={`ben-upload-document ${
          isDragger ? "ben-dragged-content" : ""
        } ${progressClassName}`}
        disabled={disableUpload}
        openFileDialogOnClick={openFileDialogOnClick}
      >
        {children}
      </UploadComponent>
      <UploadingDocumentRow
        fileName={fileName}
        documentName={documentName}
        isShowUploadingName={isShowUploadingName}
        uploadProgress={uploadProgress}
        isCancelEnabled={isCancelEnabled}
        handleCancelUpload={handleCancelUpload}
      />
    </Fragment>
  );
};

export default UploadDocument;
