import { ArrayUtils } from "common/shared/utils";

import Institution from "domains/clients/store/institution.model";
import User from "domains/clients/store/user.model";

import {
  LiquidityRequestStatusesMap,
  LiquidityRequestDisplayMap,
  LiquidityRequestAlertTypeDisplayMap,
} from "./constants";
import {
  TypeLiquidityGroup,
  LiquidityRequestsFiltered,
  LiquidityRequestItemData,
  LiquidityRequestStatusesSetting,
  TypeLiquidityStatusCode,
  LiquidityRequestDisplaySetting,
  LiquidityRequestAlertItem,
  LiquidityRequestToAlertMap,
  TypeLiquidityRequestAlertType,
  LiquidityRequestAlertTypeDisplaySetting,
  TypeLiquidityTypeCode,
} from "./types";

const STATUSES: {
  [key in TypeLiquidityStatusCode]: number;
} = {
  in_verification: 1,
  // in underwriting
  in_valuation: 2,
  in_pricing: 2,
  in_commitment: 2,
  in_proposal_pending: 2,
  ben_rejected_lr_pending: 2,
  // in proposal
  in_proposal: 3,
  // client signed
  client_signed: 4,
  in_counter_signatures: 4,
  counter_signed: 4,
  in_bd_signatures: 4,
  bd_signed: 4,
  // closed
  closed: 0,
  // cancelled
  client_rejected_offering: 0,
  ben_rejected_lr: 0,
  ben_rejected_client: 0,
};

export class LiquidityService {
  private static statusComparator(a: LiquidityRequestItemData, b: LiquidityRequestItemData) {
    const aValue = STATUSES[a.status] || Number.MAX_VALUE;
    const bValue = STATUSES[b.status] || Number.MAX_VALUE;

    if (aValue > bValue) {
      return 1;
    }
    if (aValue < bValue) {
      return -1;
    }

    return 0;
  }

  /**
   * @description - returns object with filtered items by groups or null
   */
  filterListByGroups = (
    list: LiquidityRequestItemData[] | null,
    alertItems: LiquidityRequestToAlertMap | null
  ): LiquidityRequestsFiltered | null => {
    if (!list || !this.checkListItemsExists(list)) {
      return null;
    }

    const result: LiquidityRequestsFiltered = {
      active: [],
      archived: [],
      closed: [],
    };

    list.forEach((item: LiquidityRequestItemData) => {
      if (
        !item.status ||
        !LiquidityRequestStatusesMap[item.status] ||
        !result[LiquidityRequestStatusesMap[item.status].group]
      ) {
        return;
      }

      if (alertItems && alertItems[item.id]) {
        item.alertData = alertItems[item.id];
      }

      result[LiquidityRequestStatusesMap[item.status].group].push(item);
    });

    return {
      ...result,
      active: [...result.active].sort(LiquidityService.statusComparator),
    };
  };

  getLiquidityRequestToAlertMap = (
    alertItems: LiquidityRequestAlertItem[]
  ): LiquidityRequestToAlertMap | null => {
    if (!ArrayUtils.isValidArrayData(alertItems)) {
      return null;
    }

    const result: LiquidityRequestToAlertMap = {};

    alertItems.forEach((item: LiquidityRequestAlertItem) => {
      result[item.liquidityRequestId] = item;
    });

    return result;
  };

  checkListItemsExists = (list: LiquidityRequestItemData[] | null): boolean => {
    if (!list || !ArrayUtils.isValidArrayData(list)) {
      return false;
    }
    return true;
  };

  checkGroupsToShowExists = (groupsToShow: TypeLiquidityGroup[] | null): boolean => {
    if (!groupsToShow || !ArrayUtils.isValidArrayData(groupsToShow)) {
      return false;
    }
    return true;
  };

  getProgressBarSettings = (
    status: TypeLiquidityStatusCode | null,
    prevStatus?: TypeLiquidityStatusCode | null
  ): {
    statusSettings: LiquidityRequestStatusesSetting | null;
    displaySettings: LiquidityRequestDisplaySetting | null;
  } => {
    const statusSettings: LiquidityRequestStatusesSetting | null =
      status && LiquidityRequestStatusesMap[status] ? LiquidityRequestStatusesMap[status] : null;

    const displaySettings: LiquidityRequestDisplaySetting =
      statusSettings && LiquidityRequestDisplayMap[statusSettings.type]
        ? LiquidityRequestDisplayMap[statusSettings.type]
        : LiquidityRequestDisplayMap.fallback;

    if (
      statusSettings &&
      statusSettings.type === "cancelled" &&
      prevStatus &&
      LiquidityRequestStatusesMap[prevStatus]
    ) {
      const prevType = LiquidityRequestStatusesMap[prevStatus].type;
      displaySettings.step = LiquidityRequestDisplayMap[prevType].step;
    }

    return {
      statusSettings,
      displaySettings,
    };
  };

  getAlertMessageDisplaySettings = (
    status: TypeLiquidityRequestAlertType | null
  ): LiquidityRequestAlertTypeDisplaySetting => {
    const displaySettings: LiquidityRequestAlertTypeDisplaySetting =
      status && LiquidityRequestAlertTypeDisplayMap[status]
        ? LiquidityRequestAlertTypeDisplayMap[status]
        : LiquidityRequestAlertTypeDisplayMap.fallback;

    return displaySettings;
  };

  getMappedStatus = (status: TypeLiquidityStatusCode | null): TypeLiquidityTypeCode | null => {
    return status && LiquidityRequestStatusesMap[status]
      ? LiquidityRequestStatusesMap[status].type
      : null;
  };

  isSensitiveDataLiquidityRequest = (
    client: User | Institution | null,
    user: User | null
  ): boolean => {
    let isSensitive = true;
    if (
      (client && client.accountType === "institution") ||
      (user && user.userType === "employee")
    ) {
      isSensitive = false;
    }

    return isSensitive;
  };
}

const liquidityService = new LiquidityService();
export default liquidityService;
