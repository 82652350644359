import * as React from "react";
import { I18nProvider } from "@lingui/react";

import { localeService, portalEnvConstants } from "common/shared";
import { useSelectedLocaleData } from "common/shared-hooks";
import { Spinner } from "common/lib/components";

interface LocaleWrapperProps {
  children?: JSX.Element;
}

const LocaleWrapper = ({ children }: LocaleWrapperProps) => {
  const { i18n } = localeService;
  const { lang } = useSelectedLocaleData();
  const defaultLang = portalEnvConstants.defaultLanguage;

  if (!lang && !defaultLang) {
    return (
      <div style={{ textAlign: "center", justifyContent: "center" }}>
        <Spinner />
      </div>
    );
  }

  i18n.activate(lang || defaultLang);

  return (
    <I18nProvider i18n={i18n} language={lang}>
      {children}
    </I18nProvider>
  );
};

export default LocaleWrapper;
