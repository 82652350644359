import React, { useEffect, useState } from "react";
import { RouteComponentProps, Redirect } from "@reach/router";
import { useQueryParams, StringParam } from "use-query-params";
import { Trans, t } from "@lingui/macro";

import Tokens from "common/shared/interfaces/tokens";
import { BenVerifyScreen } from "common/lib/components";
import { Urls } from "common/lib/constants";
import {
  tokenDecodeUtils,
  sessionService,
  apiEnvs,
  impersonateService,
  messageService,
  ImpersonatePayloadType,
  localeService,
} from "common/shared";

interface PropsVerifyImpersonatePage extends RouteComponentProps {}
const verifyTitle = (
  <Trans id="verifyImpersonatePage.verification.message.process">Verifying...</Trans>
);

const VerifyImpersonatePage: React.FC<PropsVerifyImpersonatePage> = (): React.ReactElement | null => {
  const [query] = useQueryParams({
    at: StringParam,
    region: StringParam,
    country: StringParam,
    postal_code: StringParam,
    phone: StringParam,
  });
  const [redirectTo, setRedirectTo] = useState("");
  const [authClientId, setAuthClientId] = useState("");
  const {
    at: accessToken,
    region: userEmail,
    country: initVector,
    postal_code: encryptedPayload,
    phone: userPayload,
  } = query;

  const isUserLoggedIn = sessionService.isAuthenticated();
  const isTokenExpired = accessToken && tokenDecodeUtils.isTokenExpired(accessToken);

  async function getApiEnvParams() {
    try {
      const { oAuthClientId } = await apiEnvs.getApiEnv();
      if (!oAuthClientId) {
        return;
      }

      setAuthClientId(oAuthClientId);
    } catch (error) {
      messageService.error(localeService.i18n._(t`toaster.impersonate.fail`));
    }
  }

  useEffect(() => {
    getApiEnvParams();
  }, []);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (
      !accessToken ||
      isTokenExpired ||
      !userEmail ||
      !encryptedPayload ||
      !initVector ||
      !authClientId
    ) {
      return;
    }

    if (isUserLoggedIn) {
      sessionService.clearSession();
    }

    sessionService.setUserName(userEmail);
    const tokensToSet: Tokens = {
      id_token: "",
      access_token: accessToken,
      refresh_token: "",
      expires_in: 3600,
    };

    sessionService.setTokens(
      tokensToSet,
      authClientId,
      sessionService.getGeneratedBrowserSessionId()
    );
    sessionService.syncBrowserSessionId();
    const impersonateData: ImpersonatePayloadType = { encryptedPayload, initVector, userPayload };
    impersonateService.setImpersonatePayload(impersonateData);
    sessionService.setImpersonatePayloadToStore(impersonateData);

    setRedirectTo(Urls.AUTH.BASE);
  }, [authClientId]);

  if (!accessToken || !userEmail || isTokenExpired || !encryptedPayload || !initVector) {
    return <Redirect to={Urls.AUTH.ERROR} noThrow={true} />;
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} noThrow={true} />;
  }
  // Process of verifying
  return <BenVerifyScreen title={verifyTitle} />;
};

/**
 * How to start develop:
 * 1. Get accessToken for impersonate (submitamount)
 *    POST /test/v1/oauth2/token
 *    bcp-whitelabel: default
 *    BODY {"grant_type": "client_credentials", "client_id": "${client_id}", "client_secret": "${client_secret}" }
 *
 * 2. POST /v1/submitamount
 *    authorization: Bearer ${accessToken}
 *    bcp-whitelabel: default
 *    BODY {email: "${email}", white_label_instance: "default"}
 *
 * 3. In the response of /v1/submitamount will be a link.
 */

export default VerifyImpersonatePage;
