import React from "react";
import { Trans } from "@lingui/macro";
import { navigate, RouteComponentProps } from "@reach/router";

import { Urls } from "common/lib/constants";
import { CardErrorSection } from "common/lib/components";
import { PublicPageWrapper } from "../components";
import { PagesInfo } from "pages/constants";

import image from "common/assets/images/404-error.svg";
import { useGetAppVersion } from "common/shared-hooks";

interface PropsPublicError404Page extends RouteComponentProps {}
const btnText = <Trans id="page404.btnTextSignIn">Return to Sign In</Trans>;

const PublicError404Page = (props: PropsPublicError404Page) => {
  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  const handleRedirectTo = () => {
    navigate(Urls.AUTH.SIGN_IN);
  };

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <CardErrorSection
        title={PagesInfo.Error404Info.title}
        message={PagesInfo.Error404Info.message}
        loading={false}
        handleOnClick={handleRedirectTo}
        btnText={btnText}
        image={image}
        noPanel={true}
      />
    </PublicPageWrapper>
  );
};

export default PublicError404Page;
