import { ActionTypes } from "../actions/bootstrap";
import { AcceptRequestSharingData } from "domains/liquidity";
import { ResendWelcomeEmailData } from "domains/authentication";

export interface Bootstrap {
  navigateToAfterLogin: string;
  acceptRequestSharingData: AcceptRequestSharingData | null;
  acceptIdentityData: ResendWelcomeEmailData | null;
  isAcceptIdentityLinkWasUsed: boolean;
  tokenForPass: string | null;
  isTokenForPassCompletedRequest: boolean;
  isTokenForPassSuccess: boolean;
  isTokenForPassWasUsed: boolean;
  routesToRedirect: { [key: string]: string };
}
export const initialState: Bootstrap = {
  navigateToAfterLogin: "",
  acceptRequestSharingData: null,
  acceptIdentityData: null,
  isAcceptIdentityLinkWasUsed: false,
  tokenForPass: null,
  isTokenForPassCompletedRequest: false,
  isTokenForPassSuccess: false,
  isTokenForPassWasUsed: false,
  routesToRedirect: {},
};

export const bootstrap = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.SetNavigateToAfterLogin:
      return {
        ...state,
        navigateToAfterLogin: action.payload.path,
      };

    case ActionTypes.SetAcceptedRequestSharingData:
      return {
        ...state,
        acceptRequestSharingData: action.payload,
      };

    case ActionTypes.SetAcceptedIdentityData:
      return {
        ...state,
        acceptIdentityData: action.payload,
      };

    case ActionTypes.SetTokenForPass:
      return {
        ...state,
        // tokenForPass can be null
        tokenForPass: action.payload.token,
        isTokenForPassCompletedRequest: true,
        isTokenForPassSuccess: action.payload.success,
        isTokenForPassWasUsed: Boolean(action.payload.wasUsed),
      };
    case ActionTypes.SetAcceptIdentityLinkWasUsed:
      return {
        ...state,
        isAcceptIdentityLinkWasUsed: action.payload.wasUsed,
      };
    case ActionTypes.AddRoutesToRedirect:
      return {
        ...state,
        routesToRedirect: { ...state.routesToRedirect, ...action.payload },
      };

    case ActionTypes.UpdateRoutesToRedirect:
      return {
        ...state,
        routesToRedirect: { ...action.payload },
      };

    default:
      return state;
  }
};
