import React, { Fragment } from "react";
import { Trans } from "@lingui/macro";
import { Button, Row, Col } from "antd";
import { navigate } from "@reach/router";

import { Urls } from "common/lib/constants";

import { LoginFooter } from "domains/authentication";

import "./login-info-card.scss";

interface LoginInfoCardProps {
  pathToNavigate?: string;
}

const LoginInfoCard: React.FC<LoginInfoCardProps> = (props): React.ReactElement => {
  const { pathToNavigate } = props;

  function handleOnCancel() {
    navigate(pathToNavigate || Urls.AUTH.SIGN_IN);
  }

  return (
    <Fragment>
      <div className="ben-panel-header">
        <h3>
          <Trans id="logout.title">Logout Success</Trans>
        </h3>
      </div>

      <div className="ben-pb-3">
        <p>
          <Trans id="logout.info">You have been logged out successfully</Trans>
        </p>
      </div>

      <div className="ben-border-bottom ben-border-top ben-pt-4 ben-pb-4 ben-mb-4">
        <LoginFooter />
      </div>

      <Row gutter={30}>
        <Col md={{ span: 12, offset: 12 }} className="ben-pb-2">
          <Button
            id="benSigninFormBtnBackToLogin"
            type="primary"
            className="ben-w-100"
            shape="round"
            size="large"
            onClick={handleOnCancel}
          >
            <Trans id="forgotname.actionButton.back">Back to Login</Trans>
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default LoginInfoCard;
