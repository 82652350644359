import React from "react";
import { Breadcrumb, Icon } from "antd";
import { Link } from "@reach/router";

import { ArrayUtils } from "common/shared/utils";
import { BreadcrumbItem } from "common/shared/interfaces/breadcrumb";

import { PropsBenBreadcrumb } from "./types";
import "./ben-breadcrumb.scss";

const BenBreadcrumb: React.FC<PropsBenBreadcrumb> = (props): React.ReactElement | null => {
  if (!ArrayUtils.isValidArrayData(props.items)) {
    return null;
  }

  return (
    <div className="ben-breadcrumb">
      <Breadcrumb separator={"|"}>
        {props.items.map((item: BreadcrumbItem, index) => (
          <Breadcrumb.Item key={item.id}>
            {index === 0 && <Icon type="left" className="ben-breadcrumb-icon" />}
            {item.path ? (
              <Link
                to={item.path}
                className="ben-breadcrumb-item"
                title={typeof item.title === "string" ? item.title : undefined}
              >
                {item.title}
              </Link>
            ) : (
              <span className="ben-breadcrumb-item">{item.title}</span>
            )}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
};

export default BenBreadcrumb;
