export interface ImpersonatePayload {
  encryptedPayload: string;
  initVector: string;
  userPayload?: string;
}

export class ImpersonationService {
  private impersonateData: ImpersonatePayload | null = null;

  public getImpersonationPayload(): ImpersonatePayload | null {
    return this.impersonateData ? { ...this.impersonateData } : null;
  }

  public setImpersonatePayload(impersonateData: ImpersonatePayload) {
    this.impersonateData = impersonateData;
  }
}

const impersonateService = new ImpersonationService();
export default impersonateService;
