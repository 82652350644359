import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";

import { useForm } from "common/shared-hooks";
import { actions$ } from "common/shared-store/actions/actionsStream";
import { Constants } from "common/lib/constants";
import { sessionService } from "common/shared";
import { DomUtils } from "common/shared/utils";

import downIcon from "common/assets/images/icons/collapse-arrow-down.svg";
import upIcon from "common/assets/images/icons/collapse-arrow-up.svg";

import { ActionTypes, updateAccount } from "domains/clients/store/clients.actions";
import { InstitutionSection } from "domains/clients/components";
import {
  ProfileInstitutionFormSchema,
  institutionValidationSchema,
  impersonateInstitutionDisabledConfig,
} from "domains/clients/forms";
import ProfileApiTransformHelper from "domains/clients/shared/profile.api.transform.helper";
import Institution from "domains/clients/store/institution.model";

interface PropsProfileInstitutionSection {
  institution: Institution;
  shouldTurnOffEditMode: boolean;
  handleOnChangeMode: (isEditMode: boolean) => void;
}

const currentCollapseKey = "1";

const ProfileInstitutionSection = (props: PropsProfileInstitutionSection) => {
  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [isEditMode, setEditMode] = useState(false);
  const [isUpdating, setUpdateStatus] = useState(false);
  const panelRef = useRef<any>();
  const dispatch = useDispatch();

  const { institution, shouldTurnOffEditMode, handleOnChangeMode } = props;

  const formDisabledConfig = sessionService.isAppian ? impersonateInstitutionDisabledConfig : {};
  const formSchema = new ProfileInstitutionFormSchema(institution, formDisabledConfig);
  const editInstitutionFormConfig = useForm(
    formSchema,
    institutionValidationSchema,
    submitCallback
  );
  const { resetForm } = editInstitutionFormConfig;

  const toggleEditMode = useCallback(() => {
    handleOnChangeMode(!isEditMode);
    setEditMode((editMode: boolean) => !editMode);
  }, [handleOnChangeMode, isEditMode]);

  // Possibility from the parent to change edit mode
  useEffect(() => {
    if (isEditMode && shouldTurnOffEditMode) {
      setEditMode(false);
    }
  }, [isEditMode, shouldTurnOffEditMode]);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.UpdateAccountFail).subscribe(() => {
        setUpdateStatus(false);
      }),
      actions$.ofType(ActionTypes.UpdateAccountSuccess).subscribe(() => {
        setUpdateStatus(false);
        toggleEditMode();
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [toggleEditMode]);

  const toggleCollapse = useCallback(() => {
    setActiveKey(activeKey.length === 0 ? [currentCollapseKey] : []);
  }, [activeKey.length]);

  const handleCollapseClick = useCallback(() => {
    if (isEditMode) {
      setEditMode(false);
    }

    if (activeKey.length) {
      DomUtils.scrollIntoView(panelRef.current);
    }
    toggleCollapse();
  }, [toggleCollapse, activeKey.length, isEditMode]);

  const onEditClick = () => {
    toggleEditMode();
    setActiveKey([currentCollapseKey]);
  };

  function submitCallback(data: any) {
    if (sessionService.isAppian) {
      return;
    }

    // reset state if country isn't USA
    if (Constants.DEFAULT_COUNTRY !== data.country) {
      data.region = "";
    }

    const patch = ProfileApiTransformHelper.prepareInstitutionalEditPatch(data, institution);

    setUpdateStatus(true);
    dispatch(updateAccount({ data: patch, accountId: institution.accountId }));
  }

  const handleCancelForm = useCallback(() => {
    resetForm();
    toggleEditMode();
  }, [toggleEditMode, resetForm]);

  return (
    <div className="ben-panel ben-pb-0 ben-mb-4" ref={panelRef}>
      <div className="ben-client-details">
        <InstitutionSection
          activeKey={activeKey}
          isUpdating={isUpdating}
          isEditMode={isEditMode}
          handleCancelForm={handleCancelForm}
          onEditClick={onEditClick}
          currentCollapseKey={currentCollapseKey}
          institution={institution}
          editInstitutionFormConfig={editInstitutionFormConfig}
        />
        <Button
          id="benInstitutionSectionBtnCollapse"
          size="small"
          className="collapse"
          type="primary"
          shape="circle"
          onClick={handleCollapseClick}
        >
          <img src={activeKey.length === 0 ? downIcon : upIcon} alt="Logo" />
        </Button>
      </div>
    </div>
  );
};

export default ProfileInstitutionSection;
