import { ApiEnv } from "common/shared/interfaces/apiEnv";

export class ApiEnvConstants {
  public authAPI = "";
  public accountsAPI = "";
  public holdingsAPI = "";
  public oAuthClientId = "";
  public whitelabel = "";
  public identityAPI = "";
  public documentsAPI = "";
  public verifyInvestorToken = "";
  public isProductionEnv = false;

  private resolve: any;
  private envs: Promise<ApiEnv>;

  constructor() {
    this.envs = new Promise((resolve, reject) => {
      this.resolve = resolve;
    });
  }

  public setApiEnv(envAPIs: any) {
    envAPIs = envAPIs || {};
    this.authAPI = envAPIs.AuthAPI_url || "";
    this.accountsAPI = envAPIs.AccountsAPI_url || "";
    this.holdingsAPI = envAPIs.HoldingsAPI_url || "";
    this.oAuthClientId = envAPIs.OAuthClient_id || "";
    this.whitelabel = envAPIs.WhiteLabel || "";
    this.identityAPI = envAPIs.IdentityAPI_url || "";
    this.documentsAPI = envAPIs.DocumentsAPI_url || "";
    this.verifyInvestorToken = envAPIs.VerifyInvestor_token || "";
    this.isProductionEnv = envAPIs.IsProductionEnv || false;

    this.resolve(this);
  }

  public getApiEnv(): Promise<ApiEnv> {
    return this.envs;
  }
}

const apiEnvs = new ApiEnvConstants();
export default apiEnvs;
