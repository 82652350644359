import React from "react";
import { Trans } from "@lingui/react";
import { Row, Col, Button, Tooltip } from "antd";

import { IconCustom } from "common/lib/components";
import { InvitedObjectsTitlesMap } from "domains/liquidity/shared/constants";

import { PropsInviteItem } from "./types";
import "./invite-item.scss";

const shareBtnTooltip = <Trans id="tooltip.btn.share">Share</Trans>;

const InviteItem: React.FC<PropsInviteItem> = (props): React.ReactElement | null => {
  const { title, invitedObjectType, handleInitSharing } = props;

  const btnId = title.replace(/\s+/g, "");

  return (
    <Row type="flex" className="ben-invite-item">
      <Col className="ben-flex-fill">
        <strong className="ben-invite-item-title">
          {title} (
          {InvitedObjectsTitlesMap[invitedObjectType] && InvitedObjectsTitlesMap[invitedObjectType]}
          )
        </strong>
      </Col>

      <div className="ben-d-sm-none">
        {/* TODO: button is hidden by class - "ben-d-sm-none" (for mobile state), also need to remove DIV */}
        {handleInitSharing && (
          <Col>
            <Tooltip placement="left" title={shareBtnTooltip}>
              <Button
                id={`benInviteActionBtn${btnId}`}
                type="link"
                disabled={false}
                className="ben-btn-reset"
                onClick={handleInitSharing}
                style={{ display: "none" }}
              >
                <IconCustom type="invite-user" style={{ fontSize: 22 }} />
              </Button>
            </Tooltip>
          </Col>
        )}
      </div>
    </Row>
  );
};

export default InviteItem;
