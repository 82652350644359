import { useSelector } from "react-redux";
import { UserIdentity } from "common/shared/interfaces/user-identity";

function useLastLogin(): string {
  const result = useSelector((state: { identity: { identity: null | UserIdentity } }): string => {
    return (state.identity.identity && state.identity.identity.lastLogin) || "";
  });

  return result;
}

export default useLastLogin;
