import React, { useCallback, useEffect, useState } from "react";
import { RcFile } from "antd/lib/upload";
import { useDispatch } from "react-redux";
import { CancelTokenSource } from "axios";
import { navigate } from "@reach/router";
import { Urls } from "common/lib/constants";

import { actions$ } from "common/shared-store";
import { apiService, FileUploaderService, messageService } from "common/shared";
import { ParamUploadDirectoryDocumentRequest } from "common/shared/services/file/fileUploader.types";
import { CANCEL_MSG } from "common/shared/services/file/fileUploader.constants";
import { BrowsersUtils } from "common/shared/utils";

import { confirmUpload, ActionTypes } from "domains/document/store/document.actions";
import {
  CreateDocumentResponseData,
  ParamsCreateDocument,
  TypeFilesListUploadingResult,
  TypeUploadDirectoryDocument,
  TypeUploadingFileData,
} from "domains/document/shared/types";
import {
  UploadTrigger,
  DocumentsList,
  UploadModal,
} from "domains/document/modules/upload-directory";

import {
  PropsModule,
  TypeCancelTokenSourcesMap,
  TypeGlobalCancellationFilesNumber,
  TypeGlobalErrors,
  TypeProgressEvent,
} from "./types";
import { Messages, Config } from "./constants";
import { Helpers } from "./helpers";

const Module: React.FC<PropsModule> = (props): React.ReactElement | null => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [isProcessStarted, setProcessStarted] = useState<boolean>(false);
  const [isProcessCompleted, setProcessCompleted] = useState<boolean>(false);
  const [isFileListValid, setFileListValid] = useState<boolean>(false);
  const [globalErrors, setGlobalErrors] = useState<TypeGlobalErrors>({
    isFilesAmount: false,
    isLevelsAmount: false,
  });
  const [documentsToUpload, setDocumentsToUpload] = useState<TypeUploadDirectoryDocument[]>([]);
  const [files, setFiles] = useState<RcFile[]>([]);
  const [uploadingFile, setUploadingFile] = useState<TypeUploadingFileData>({});
  const [cancelTokenSources, setCancelTokenSources] = useState<TypeCancelTokenSourcesMap>({});

  const [isGlobalCancellationLoading, setGlobalCancellationLoading] = useState<boolean>(false);
  const [globalCancellationFilesNumber, setGlobalCancellationFilesNumber] = useState<
    TypeGlobalCancellationFilesNumber
  >({ total: 0, completed: 0 });

  const dispatch = useDispatch();

  const { accountId, documentsConfig, onUploadSuccess, uniqueId } = props;

  const handleUploadingReject = useCallback(
    (createdDocument: CreateDocumentResponseData, fileId: string) => {
      // Clear data from the BE about the document
      dispatch(
        confirmUpload({
          documentId: createdDocument.documentId,
          versionId: createdDocument.versionId,
          reject: true,
          accountId,
          fileId,
        })
      );
    },
    [dispatch, accountId]
  );

  const handleCommonUploadingError = useCallback(
    (fileId: string, createdDocument: CreateDocumentResponseData | null) => {
      let isCancelled = false;

      if (createdDocument) {
        handleUploadingReject(createdDocument, fileId);
        isCancelled = true;
      }

      setUploadingFile({
        [fileId]: {
          progress: Config.progress.error,
          isCancelled,
          errors: [{ id: "uploadingFileError", message: Messages.CommonFileUploadMessage }],
          createdData: createdDocument,
        },
      });
    },
    [handleUploadingReject]
  );

  const resetGlobalCancellationData = useCallback(() => {
    setGlobalCancellationLoading(false);
    setGlobalCancellationFilesNumber({ total: 0, completed: 0 });
  }, []);

  const resetProcessData = useCallback(() => {
    setProcessStarted(false);
    setProcessCompleted(false);
  }, []);

  const resetFilesData = useCallback(() => {
    setFiles([]);
    setDocumentsToUpload([]);
    setCancelTokenSources({});
    setUploadingFile({});
  }, []);

  const finishGlobalCancellationProcess = useCallback(() => {
    resetGlobalCancellationData();
    resetProcessData();
    setModalOpen(false);
  }, [resetGlobalCancellationData, resetProcessData]);

  useEffect(() => {
    // Step 4. Global cancellation - check if all cancellation requests were passed
    const { total, completed } = globalCancellationFilesNumber;
    if (!completed || total !== completed) {
      return;
    }
    finishGlobalCancellationProcess();
  }, [globalCancellationFilesNumber, finishGlobalCancellationProcess]);

  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.ConfirmUploadSuccess).subscribe((action: any) => {
        const { cancelConfirmation, fileId } = action.payload;

        // Success uploading confirmation flow. cancelConfirmation - is a flag for canceling uploading
        if (!cancelConfirmation && fileId) {
          setUploadingFile({
            [fileId]: {
              progress: Config.progress.confirm,
              isCancelled: false,
              errors: null,
              createdData: null,
            },
          });
        }

        // cancelling confirmation uploading flow.
        if (cancelConfirmation && globalCancellationFilesNumber.total) {
          // Step 3. Global cancellation - counter on success request for cancellation
          setGlobalCancellationFilesNumber((oldData) => {
            return { total: oldData.total, completed: oldData.completed + 1 };
          });
          setUploadingFile({
            [fileId]: {
              progress: Config.progress.min,
              isCancelled: true,
              errors: null,
              createdData: null,
            },
          });
        }
      }),
      actions$.ofType(ActionTypes.ConfirmUploadFail).subscribe((action: any) => {
        const { cancelConfirmation, fileId } = action.payload;
        // confirmation uploading flow.
        if (!cancelConfirmation && fileId) {
          handleCommonUploadingError(fileId, null);
        }
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, [handleCommonUploadingError, globalCancellationFilesNumber]);

  const handleFinishUploading = useCallback(
    (result: TypeFilesListUploadingResult, uploadedFiles: TypeUploadingFileData): void => {
      setProcessCompleted(true);

      if (result === "allSuccess") {
        navigate(`${Urls.PROTECTED.LIQUIDITY_REQUEST}/${documentsConfig.liquidityRequestId}`);
        messageService.success(Messages.FinishMessageAllSuccess.props.id);
      } else if (result === "allErrors") {
        messageService.error(Messages.FinishMessageAllErrors.props.id);
      } else if (result === "hasSomeErrors") {
        messageService.warn(Messages.FinishMessageHasSomeErrors.props.id);
      } else if (result === "allCancelled") {
        // Step 2. Global cancellation - rejecting files
        const filesNumber = Helpers.callActionOnFilesCreatedData(
          uploadedFiles,
          handleUploadingReject
        );
        if (filesNumber) {
          setGlobalCancellationFilesNumber({ total: filesNumber, completed: 0 });
        } else {
          finishGlobalCancellationProcess();
        }
        messageService.warn(Messages.FinishMessageAllCancelled.props.id);
      }
    },
    [handleUploadingReject, finishGlobalCancellationProcess, documentsConfig.liquidityRequestId]
  );

  const handleFilesData = useCallback((fileList: RcFile[]) => {
    setFiles(fileList);
    const { itemsToDisplay, isValidationErrors } = Helpers.getDisplayedFiles(fileList);
    setDocumentsToUpload(itemsToDisplay);
    setFileListValid(!isValidationErrors);
  }, []);

  const handleModalCancel = useCallback(() => {
    resetFilesData();
    resetProcessData();
    setModalOpen(false);
  }, [resetFilesData, resetProcessData]);

  const handleModalCloseAfterCompletion = useCallback(() => {
    setModalOpen(false);
    resetProcessData();
    onUploadSuccess();
  }, [onUploadSuccess, resetProcessData]);

  const handleModalOpen = useCallback(
    (fileList: RcFile[]): boolean => {
      if (isModalOpen) {
        return false;
      }
      setUploadingFile({});
      const { filteredFiles, isValidFilesAmount, isValidLevelsAmount } = Helpers.getFilteredFiles(
        fileList
      );

      if (!isValidFilesAmount || !isValidLevelsAmount) {
        handleFilesData([]);
      } else {
        handleFilesData(filteredFiles);
      }

      setGlobalErrors({
        isFilesAmount: !isValidFilesAmount,
        isLevelsAmount: !isValidLevelsAmount,
      });

      setModalOpen(true);
      return true;
    },
    [isModalOpen, handleFilesData]
  );

  const createDocumentErrorHandler = useCallback(
    (fileId: string) => () => {
      handleCommonUploadingError(fileId, null);
    },
    [handleCommonUploadingError]
  );

  const onProgressUploadingHandler = useCallback(
    (createdDocument: CreateDocumentResponseData) => (event: TypeProgressEvent, file: File) => {
      const fileData = file as RcFile;
      setUploadingFile({
        [fileData.uid]: {
          progress: Helpers.getUploadingFileProgress(event.percent),
          isCancelled: false,
          errors: null,
          createdData: createdDocument,
        },
      });
    },
    []
  );

  const onUploadFileRequestError = useCallback(
    (createdDocument: CreateDocumentResponseData, fileId: string) => (error: Error) => {
      handleCommonUploadingError(fileId, createdDocument);
    },
    [handleCommonUploadingError]
  );

  const onUploadFileSuccessHandler = useCallback(
    (createdDocument: CreateDocumentResponseData, fileId: string) => () => {
      // Step 3: confirm in the BE database that a file was uploaded
      dispatch(
        confirmUpload({
          documentId: createdDocument.documentId,
          versionId: createdDocument.versionId,
          accountId,
          fileId,
        })
      );
    },
    [dispatch, accountId]
  );

  const handleRemoveFile = useCallback(
    (fileId: string) => () => {
      setUploadingFile({
        [fileId]: {
          progress: Config.progress.min,
          isCancelled: true,
          errors: null,
          createdData: null,
        },
      });

      const filteredFiles = Helpers.getFilteredFilesByFileId(files, fileId);
      // it in unnecessary to remove cancelTokenSource for the current item
      handleFilesData(filteredFiles);
    },
    [files, handleFilesData]
  );

  const onCancelUploadError = useCallback(
    (createdDocument: CreateDocumentResponseData, fileId: string) => () => {
      setUploadingFile({
        [fileId]: {
          progress: Config.progress.min,
          isCancelled: true,
          errors: null,
          createdData: createdDocument,
        },
      });

      handleUploadingReject(createdDocument, fileId);
    },
    [handleUploadingReject]
  );

  const handleCancelUpload = useCallback(
    (fileId: string) => () => {
      apiService.cancel(cancelTokenSources[fileId], CANCEL_MSG);
      const fileName = Helpers.getFileNameByFileId(documentsToUpload, fileId);
      handleRemoveFile(fileId)();
      FileUploaderService.showFileUploadCanceledMessage(fileName);
    },
    [cancelTokenSources, handleRemoveFile, documentsToUpload]
  );

  const handleGlobalCancelUpload = useCallback(() => {
    // Step 1. Global cancellation - calls cancel tokens and reset files list
    setGlobalCancellationLoading(true);
    for (const file of files) {
      apiService.cancel(cancelTokenSources[file.uid], CANCEL_MSG);
    }
    resetFilesData();
  }, [files, cancelTokenSources, resetFilesData]);

  const createDocument = useCallback(
    async (file: RcFile, cancelSource: CancelTokenSource) => {
      setUploadingFile({
        [file.uid]: {
          progress: Config.progress.create,
          isCancelled: false,
          errors: null,
          createdData: null,
        },
      });

      // Step 1: create a document data in BE database
      const config: ParamsCreateDocument = Helpers.getCreationDocumentConfig(
        file,
        accountId,
        documentsConfig
      );
      const paramToCreateDocument: ParamUploadDirectoryDocumentRequest = {
        config,
        file,
        uploadErrorHandler: createDocumentErrorHandler(file.uid),
        cancelToken: cancelSource.token,
      };
      const createdDocumentResult = await FileUploaderService.createDocumentRequestWithResponse(
        paramToCreateDocument
      );

      if (!createdDocumentResult) {
        return;
      }

      // Step 2: upload a file to the AWS storage
      await FileUploaderService.uploadFileRequest({
        file,
        headers: { "Content-Type": file.type },
        onProgress: onProgressUploadingHandler(createdDocumentResult),
        onError: onUploadFileRequestError(createdDocumentResult, file.uid),
        uploadUrl: Helpers.getUploadUrlFromCreatedDocument(createdDocumentResult),
        fileExtension: FileUploaderService.getExtensionFromFile(file),
        cancelToken: cancelSource.token,
        isCustomFlow: false,
        onSuccessCallbackHandler: onUploadFileSuccessHandler(createdDocumentResult, file.uid),
        onCancelUploadError: onCancelUploadError(createdDocumentResult, file.uid),
      });
    },
    [
      documentsConfig,
      accountId,
      onUploadFileSuccessHandler,
      onProgressUploadingHandler,
      onUploadFileRequestError,
      createDocumentErrorHandler,
      onCancelUploadError,
    ]
  );

  const processFiles = useCallback(
    async (fileList: RcFile[], cancelSources: TypeCancelTokenSourcesMap) => {
      for (const file of fileList) {
        await createDocument(file, cancelSources[file.uid]);
      }
    },
    [createDocument]
  );

  const handleOnSubmit = useCallback(async () => {
    setProcessStarted(true);
    const cancelSources = Helpers.getCancelTokenSourcesMap(files);
    setCancelTokenSources(cancelSources);
    processFiles(files, cancelSources);
  }, [files, processFiles]);

  let listErrorMessage: string | React.ReactElement = "";

  if (!isFileListValid) {
    listErrorMessage = Messages.ListErrorsTitle;
  }

  if (BrowsersUtils.isIE()) {
    return null;
  }

  return (
    <div className="upload-directory-module ben-document-content ben-document-content-small ben-d-sm-none">
      {/* TODO: button is hidden by class - "ben-d-sm-none" (for mobile state) */}
      <UploadTrigger
        handleModalOpen={handleModalOpen}
        isModalOpen={isModalOpen}
        uniqueId={uniqueId}
      />
      <UploadModal
        isModalOpen={isModalOpen}
        handleModalCancel={handleModalCancel}
        handleOnSubmit={handleOnSubmit}
        handleModalCloseAfterCompletion={handleModalCloseAfterCompletion}
        handleGlobalCancelUpload={handleGlobalCancelUpload}
        isDocumentsExist={Boolean(documentsToUpload.length)}
        isProcessCompleted={isProcessCompleted}
        isProcessStarted={isProcessStarted}
        isGlobalCancellationLoading={isGlobalCancellationLoading}
        listErrorMessage={listErrorMessage}
      >
        <DocumentsList
          documents={documentsToUpload}
          isProcessStarted={isProcessStarted}
          handleCancelUpload={handleCancelUpload}
          handleRemoveFile={handleRemoveFile}
          uploadingFile={uploadingFile}
          maxUploadingProgress={Config.progress.max}
          handleFinishUploading={handleFinishUploading}
          isMaxFilesError={globalErrors.isFilesAmount}
          isMaxLevelsError={globalErrors.isLevelsAmount}
          maxFiles={Config.maxUploadingFilesAmount}
          maxNestedFolders={Config.maxNestedFoldersAmount}
        />
      </UploadModal>
    </div>
  );
};

export default Module;
