import React, { Fragment } from "react";

import { TextRowInfo, BenCombinedData } from "common/lib/components";

import Institution from "domains/clients/store/institution.model";
import { TypeClientCombinedItem } from "domains/clients/shared/types";
import clientsService from "domains/clients/shared/clients.service";

interface PropsInstitutionInfoText {
  institution: Institution;
}

const InstitutionInfoText: React.FC<PropsInstitutionInfoText> = (
  props
): React.ReactElement | null => {
  const { institution } = props;

  if (!institution) {
    return null;
  }

  const { primaryContactUser } = institution;
  const addressCombinedData: TypeClientCombinedItem[] = clientsService.getAddressCombinedData(
    institution
  );

  return (
    <Fragment>
      <TextRowInfo label="profile.account.name">{institution.name}</TextRowInfo>
      <TextRowInfo label="profile.common.address">
        <BenCombinedData items={addressCombinedData} />
      </TextRowInfo>
      {primaryContactUser ? (
        <Fragment>
          <TextRowInfo label="profile.account.primaryContact">
            {primaryContactUser.fullName}
          </TextRowInfo>
          <TextRowInfo label="profile.common.phone">
            <BenCombinedData items={clientsService.getPhoneCombinedData(primaryContactUser)} />
          </TextRowInfo>
          <TextRowInfo label="profile.account.email" className="ben-word-break-all">
            {primaryContactUser.email}
          </TextRowInfo>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default InstitutionInfoText;
