import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Trans } from "@lingui/macro";
import { Form, Button, Input, Tooltip, Row, Col } from "antd";
import { navigate } from "@reach/router";

import { BrowsersUtils } from "common/shared/utils";
import { Spinner, PasswordHelpMessage } from "common/lib/components";
import { Urls } from "common/lib/constants";
import { useForm } from "common/shared-hooks";
import { localeService, getValidationErrors } from "common/shared";
import { actions$ } from "common/shared-store";

import { resetPassword, ActionTypes } from "domains/authentication/store/auth.actions";
import { TermsAgreement, LoginFooter } from "domains/authentication/components";
import { useLoginData } from "domains/authentication/hooks";

import {
  ResetPasswordFormSchema,
  resetPasswordValidationSchema,
  resetPasswordValidationSchemaDeps,
} from "./reset-password.form.config";

import { ResetPasswordTitle, SetPasswordTitle, ReceivedMessage } from "./constants";
import { ResetPasswordFormProps } from "./types";

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = (props): React.ReactElement | null => {
  const dispatch = useDispatch();
  const [isAcceptedAgreementValid, setAcceptedAgreementValid] = useState<boolean>(false);

  const { resetToken, resetMode, preventSendingEmail, isAcceptedTermsAgreement } = props;

  const formSchema = new ResetPasswordFormSchema();
  const { state, isDisabledSubmit, handleOnChange, handleOnBlur, handleOnSubmit } = useForm(
    formSchema,
    resetPasswordValidationSchema,
    submitCallback,
    resetPasswordValidationSchemaDeps
  );

  const { forgotPassLoading: loading } = useLoginData();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const subscriptions: any[] = [];
    subscriptions.push(
      actions$.ofType(ActionTypes.ResetPasswordSuccess).subscribe(() => {
        navigate(Urls.AUTH.SIGN_IN);
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  function submitCallback(data: any) {
    const resetData = { ...data, resetToken, resetMode, preventSendingEmail };
    dispatch(resetPassword(resetData));
  }

  function handleOnCancel() {
    navigate(Urls.AUTH.SIGN_IN);
  }

  const formTitle = resetMode ? ResetPasswordTitle : SetPasswordTitle;
  const trigger = BrowsersUtils.isMobile() ? "click" : "hover";

  return (
    <Fragment>
      <div className="ben-panel-header">
        <h3 id="formTitle">{formTitle}</h3>
      </div>
      <div className="ben-pb-3">
        {resetMode ? (
          <p id="resetMode">
            {ReceivedMessage}
            <br />
            <strong className="ben-required-label">
              <Trans id="required.helper" />
            </strong>
          </p>
        ) : (
          false
        )}
      </div>
      {loading ? (
        <Spinner />
      ) : (
        <Form className="ben-form-field-col-2">
          <div className="ben-border-bottom ben-border-top ben-pt-4 ben-mb-4">
            <div>
              <Form.Item
                label={localeService.i18n._("resetpass.password.label")}
                className="ben-form-label-field ben-required-field"
                {...getValidationErrors(
                  state.password.errors,
                  localeService.i18n._("resetpass.password.label"),
                  "validation.password"
                )}
              >
                <Input
                  name="password"
                  type="password"
                  value={state.password.value}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                  suffix={
                    <Tooltip
                      title={<PasswordHelpMessage />}
                      trigger={trigger}
                      className="password-tooltip"
                    >
                      <span className="ben-icon icon-info" />
                    </Tooltip>
                  }
                />
              </Form.Item>
              <Form.Item
                label={localeService.i18n._("resetpass.confirmPassword.label")}
                className="ben-form-label-field ben-required-field"
                {...getValidationErrors(
                  state.confirmPassword.errors,
                  localeService.i18n._("resetpass.confirmPassword.error.label")
                )}
              >
                <Input
                  name="confirmPassword"
                  type="password"
                  value={state.confirmPassword.value}
                  onChange={handleOnChange}
                  onBlur={handleOnBlur}
                />
              </Form.Item>
              <TermsAgreement
                isAcceptedTermsAgreement={isAcceptedTermsAgreement}
                setAcceptedAgreementValid={setAcceptedAgreementValid}
              />
            </div>

            <div className="ben-pb-4">
              <LoginFooter />
            </div>
          </div>
          <Row gutter={30}>
            <Col sm={24} md={12} className="ben-pb-2">
              <Button
                id="benResetPasswordBtnCancel"
                type="primary"
                className="ben-w-100"
                shape="round"
                size="large"
                ghost={true}
                onClick={handleOnCancel}
              >
                <Trans id="actionButtons.cancel">Cancel</Trans>
              </Button>
            </Col>
            <Col sm={24} md={12} className="ben-pb-2">
              <Button
                id="benResetPasswordBtnSubmit"
                type="primary"
                className="ben-w-100"
                shape="round"
                size="large"
                disabled={isDisabledSubmit() || !isAcceptedAgreementValid}
                onClick={handleOnSubmit}
              >
                <Trans id="actionButtons.submit">Submit</Trans>
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Fragment>
  );
};

export default ResetPasswordForm;
