import { useSelector } from "react-redux";

import { Bootstrap } from "common/shared-store/reducers/bootstrap";

interface BootstrapAcceptIdentityData {
  isAcceptIdentityLinkWasUsed: boolean;
  email: string | null;
}

function useBootstrapAcceptIdentityData(): BootstrapAcceptIdentityData {
  const bootstrap: Bootstrap = useSelector((storeState: any) => {
    return storeState.bootstrap;
  });

  return {
    email: (bootstrap.acceptIdentityData && bootstrap.acceptIdentityData.email) || null,
    isAcceptIdentityLinkWasUsed: bootstrap.isAcceptIdentityLinkWasUsed,
  };
}

export default useBootstrapAcceptIdentityData;
