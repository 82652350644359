import { BackendLikePrincipalData, ParamFormDataPrincipal } from "./types";
import { Constants } from "common/lib/constants";
import { ArrayUtils } from "common/shared/utils";
import * as jsonpatch from "fast-json-patch";
import { PatchRequestItemData } from "common/shared/interfaces/requests";
import { apiTransformUtils } from "common/shared";

export default class ProfileApiTransformHelper {
  static buildUserLight(data: any) {
    return {
      id: data.user_id,
      firstName: data.first_name,
      middleName: data.middle_name,
      lastName: data.last_name,
      email: data.email,
      countryCode: data.country_code,
      phoneNumber: data.phone_number,
    };
  }

  static buildUser(data: any) {
    return {
      firstName: data.first_name,
      middleName: data.middle_name,
      lastName: data.last_name,
      nickName: data.nick_name,
      suffix: data.suffix,
      timeZoneAbbreviation: data.time_zone_abbreviation,
      locale: data.locale,
      street1: data.addresses[0] ? data.addresses[0].street1 : "",
      street2: data.addresses[0] ? data.addresses[0].street2 : "",
      city: data.addresses[0] ? data.addresses[0].city : "",
      region: data.addresses[0] ? data.addresses[0].region : "",
      postalCode: data.addresses[0] ? data.addresses[0].postal_code : "",
      countryCode: data.phones[0] ? data.phones[0].country_code : "",
      country: data.addresses[0] ? data.addresses[0].country : "",
      phoneNumber: data.phones[0] ? data.phones[0].phone_number : "",
      phoneType: data.phones[0] ? data.phones[0].phone_type : "",
      email: data.email_addresses[0].email,
      accountId: data.account_id,
      accountName: data.account_name,
      userType: data.user_type,
      userId: data.user_id,
      accountDisplayId: data.account_display_id,
      userDisplayId: data.user_display_id,
    };
  }

  static buildAccount(data: any) {
    if (!data || !data.account_type) {
      return data;
    }

    if (data.account_type === Constants.ACCOUNT_TYPES.INSTITUTION.toLowerCase()) {
      if (!data.institution_data) {
        return data;
      }
      const institution = data.institution_data;
      const address = ArrayUtils.getFirstElement(institution.addresses);
      const primaryContactUser = institution.primary_contact_user;
      if (primaryContactUser) {
        // primaryContactUser.email still here
        primaryContactUser.firstName = primaryContactUser.first_name;
        primaryContactUser.lastName = primaryContactUser.last_name;
        primaryContactUser.middleName = primaryContactUser.middle_name;
        primaryContactUser.phoneNumber = primaryContactUser.phone_number;
        primaryContactUser.countryCode = primaryContactUser.country_code;
        primaryContactUser.userId = primaryContactUser.user_id;
      }
      return {
        accountId: data.account_id,
        name: data.account_name,
        country: address.country,
        street1: address.street1,
        street2: address.street2,
        city: address.city,
        region: address.region,
        postalCode: address.postal_code,
        primaryContactUser,
      };
    }
  }

  static prepareBackendLikeInstitutionEdit(institution: any) {
    const rawData = {
      ...institution,
    };
    const backendLikeInstitution: any = {
      addresses: [],
      primaryContactUserId: institution.primaryContactUser,
    };

    const addressData = {
      street1: rawData.street1,
      street2: rawData.street2,
      city: rawData.city,
      country: rawData.country,
      postal_code: rawData.postalCode,
      region: rawData.region,
      is_default: true,
    };

    backendLikeInstitution.addresses.push(addressData);

    return backendLikeInstitution;
  }

  static filterClientData(clientData: { [key: string]: string }): any {
    const copy = { ...clientData };

    // reset state if country isn't USA
    if (copy.hasOwnProperty("country") && Constants.DEFAULT_COUNTRY !== copy.country) {
      copy.region = "";
    }

    return copy;
  }

  static prepareBackendLikePrincipalEdit(
    principal: ParamFormDataPrincipal,
    isEditedObject: boolean
  ): BackendLikePrincipalData {
    const rawData = {
      ...principal,
    };

    const backendLikePrincipal: BackendLikePrincipalData = {
      addresses: [],
      phones: [],
      email_addresses: [],
      first_name: rawData.firstName,
      last_name: rawData.lastName,
      middle_name: rawData.middleName || "",
      suffix: rawData.suffix,
      nick_name: rawData.nickName,
      time_zone_abbreviation: rawData.timeZoneAbbreviation,
    };

    const addressData = {
      street1: rawData.street1,
      street2: rawData.street2,
      city: rawData.city,
      country: rawData.country,
      postal_code: rawData.postalCode,
      region: rawData.region,
    };
    const isEmptyAddress = apiTransformUtils.isEmptyValuesInObjectData(addressData);

    if (!isEmptyAddress) {
      backendLikePrincipal.addresses.push({ ...addressData, is_default: true });
    }

    const phonesData = {
      phone_number: rawData.phoneNumber,
      phone_type: rawData.phoneType || "",
      country_code: rawData.countryCode,
    };
    const isEmptyPhones = apiTransformUtils.isEmptyValuesInObjectData(phonesData);

    if (!isEmptyPhones) {
      backendLikePrincipal.phones.push({ ...phonesData, is_default: true });
    }

    // reset country code and phone type if there is no phone number
    // We can't use [{ op: "remove", path: "/phones/0" }], only  [{ op: "remove", path: "/phones" }]
    if (isEditedObject && !phonesData.phone_number) {
      delete backendLikePrincipal.phones;
    }

    const emailData = {
      email: rawData.email,
    };

    if (rawData.email) {
      backendLikePrincipal.email_addresses.push({ ...emailData, is_default: true });
    }

    return backendLikePrincipal;
  }

  static prepareInstitutionalEditPatch(data: any, account: any): PatchRequestItemData[] | null {
    const filteredClientData = ProfileApiTransformHelper.filterClientData(data);
    const editedPreparedData = ProfileApiTransformHelper.prepareBackendLikeInstitutionEdit(
      filteredClientData
    );
    const transformedInstitution = ProfileApiTransformHelper.prepareBackendLikeInstitutionEdit(
      account
    );
    const transformedInstitutionUpdated = {
      ...transformedInstitution,
      ...editedPreparedData,
    };
    let patchData = jsonpatch.compare(transformedInstitution, transformedInstitutionUpdated);
    patchData = patchData.map((patchRule) => {
      return {
        ...patchRule,
        path: apiTransformUtils.camelCaseToUnderscore(patchRule.path),
      };
    });

    return patchData;
  }

  static preparePrincipalEditPatch(
    editedData: any,
    originalData: any
  ): PatchRequestItemData[] | null {
    const filteredClientData = ProfileApiTransformHelper.filterClientData(editedData);
    const editedPreparedData = ProfileApiTransformHelper.prepareBackendLikePrincipalEdit(
      filteredClientData,
      true
    );
    const originalPreparedData = ProfileApiTransformHelper.prepareBackendLikePrincipalEdit(
      originalData,
      false
    );
    let patchData = jsonpatch.compare(originalPreparedData, editedPreparedData);
    if (!patchData.length) {
      return null;
    }

    patchData = patchData.map((patchRule) => {
      if (patchRule.op && patchRule.op === "add") {
        patchRule.value = apiTransformUtils.getCleanedObject(patchRule.value);
      }
      return {
        ...patchRule,
        path: apiTransformUtils.camelCaseToUnderscore(patchRule.path),
      };
    });

    return patchData;
  }
}
