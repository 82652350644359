import React from "react";
import { Trans } from "@lingui/macro";

import { ContactInfoProps } from "./types";

const ContactInfo: React.FC<ContactInfoProps> = (props): React.ReactElement | null => {
  const { tel, email, organization, isFullPhoneLabel, isFullEmailLabelto } = props;

  const telText = isFullPhoneLabel ? (
    <Trans id="contacts.tel">Call us at</Trans>
  ) : (
    <Trans id="contacts.phone">Phone:</Trans>
  );
  const emailText = isFullEmailLabelto ? (
    <Trans id="contacts.email">Email us at </Trans>
  ) : (
    <Trans id="contacts.email.label">Email: </Trans>
  );
  const organizationText = <Trans id="contacts.organization">Contact your </Trans>;

  return (
    <ul className="ben-mb-0">
      {organization && (
        <li>
          {organizationText} {organization}
        </li>
      )}
      {email && (
        <li>
          {emailText} <a href={`mailto:${email}`}>{email}</a>
        </li>
      )}

      {tel && (
        <li>
          {telText} <a href={`tel:${tel}`}>{tel}</a>
        </li>
      )}
    </ul>
  );
};

export default ContactInfo;
