const USA_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const TIME_ZONES = [
  {
    value: "AST",
    title: "select.timezone.ast",
  },
  {
    value: "EST",
    title: "select.timezone.est",
  },
  {
    value: "CST",
    title: "select.timezone.cst",
  },
  {
    value: "MST",
    title: "select.timezone.mst",
  },
  {
    value: "PST",
    title: "select.timezone.pst",
  },
  {
    value: "AKST",
    title: "select.timezone.akst",
  },
  {
    value: "HST",
    title: "select.timezone.hsr",
  },
];

const TZ_FULL_NAMES: any = {
  CST: "America/Chicago",
  AST: "America/Santiago",
  EST: "America/New_York",
  MST: "America/Denver",
  PST: "America/Los_Angeles",
  AKST: "America/Anchorage",
  HST: "America/Adak",
};

const ACCOUNT_TYPES = {
  INDIVIDUAL: "Individual",
  INSTITUTION: "Institution",
};

const EXTERNAL_ACCOUNT_TYPES = {
  INDIVIDUAL: "Individual",
  INSTITUTION: "Institution",
};

const ACCOUNT_TYPES_ARRAY = [
  {
    value: ACCOUNT_TYPES.INDIVIDUAL,
    title: "account.type.individual",
  },
  {
    value: ACCOUNT_TYPES.INSTITUTION,
    title: "account.type.institution",
  },
];

const EXTERNAL_ACCOUNT_TYPES_ARRAY = [
  {
    value: EXTERNAL_ACCOUNT_TYPES.INDIVIDUAL,
    title: "account.type.external.individual",
  },

  {
    value: EXTERNAL_ACCOUNT_TYPES.INSTITUTION,
    title: "account.type.external.institution",
  },
];

const USER_TYPES = {
  EMPLOYEE: "employee",
  ADVISOR: "advisor",
  PRINCIPAL: "principal",
};

const COUNTRIES = [
  {
    name: "select.country.united states of america",
    code: "USA",
  },
];

const COUNTRIES_CODES = [
  {
    name: "select.country.code.1 - united states",
    code: "1",
  },
];

const PHONE_TYPES = [
  {
    value: "home",
    title: "select.phone.type.home",
  },
  {
    value: "work",
    title: "select.phone.type.work",
  },
  {
    value: "mobile",
    title: "select.phone.type.mobile",
  },
  {
    value: "fax",
    title: "select.phone.type.fax",
  },
];

const OPERATIONS = {
  Add: "add",
  Replace: "replace",
};

const DEFAULT_COUNTRY = "USA";
const DEFAULT_COUNTRY_CODE = COUNTRIES_CODES[0];

/**
 * For now USD is the main default currency in the app.
 * If we have currency from the tenant level -
 * it will be set up separately and used in those parts of the app where it is needed.
 */
const DEFAULT_CURRENCY = {
  currencyCode: "USD",
  currencyName: "US Dollar",
  currencySign: "$",
};

const SCROLL_TO_LR = "tolr";
const HEADER_HEIGHT = 115;

export default {
  ACCOUNT_TYPES,
  ACCOUNT_TYPES_ARRAY,
  EXTERNAL_ACCOUNT_TYPES_ARRAY,
  EXTERNAL_ACCOUNT_TYPES,
  DEFAULT_COUNTRY,
  DEFAULT_COUNTRY_CODE,
  COUNTRIES,
  COUNTRIES_CODES,
  OPERATIONS,
  PHONE_TYPES,
  TIME_ZONES,
  TZ_FULL_NAMES,
  USA_STATES,
  USER_TYPES,
  SCROLL_TO_LR,
  DEFAULT_CURRENCY,
  HEADER_HEIGHT,
};
