import { TypeResponseData } from "common/shared/interfaces/requests";

import { apiEnvs, apiService } from "common/shared";
import { ApiEnv } from "common/shared/interfaces/apiEnv";

import { CurrencyListResponseData } from "common/shared/interfaces/currency";
import { parseCurrencyRequestsListResponseData } from "common/shared/services.api/currency.parser";

export class CurrencyAPIService {
  /**
   * @description - API call to get list of currencies from BE
   */
  getCurrencies = async (): Promise<CurrencyListResponseData> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const page = "page[size]=100";

    const response: TypeResponseData = await apiService.getResource(
      `${holdingsAPI}/v1/currencies?${page}`
    );

    const responseParsed: CurrencyListResponseData = parseCurrencyRequestsListResponseData(
      response
    );
    return responseParsed;
  };
}

const currencyAPIService = new CurrencyAPIService();
export default currencyAPIService;
