import React from "react";
import { Trans } from "@lingui/macro";

export const TitleSentSuccess = (
  <Trans id="resendWelcomeEmail.title.sentSuccess">Your invitation has been sent.</Trans>
);
export const MessageSentSuccess = (
  <Trans id="resendWelcomeEmail.message.sentSuccessMessage">Please check your mailbox.</Trans>
);
export const TitleInvalidData = (
  <Trans id="resendWelcomeEmail.title.invalidData">Invalid data received.</Trans>
);
export const MessageInvalidData = (
  <Trans id="resendWelcomeEmail.message.invalidData">
    Something wrong with data. Please click the invitation link again.
  </Trans>
);
export const TitleGetNewInvitation = (
  <Trans id="resendWelcomeEmail.title.getNewInvitation">
    Please Click the Resend button to receive a new invitation.
  </Trans>
);
export const TitleExpired = (
  <Trans id="resendWelcomeEmail.title">Your invitation has expired.</Trans>
);
export const MessageExpired = (
  <Trans id="resendWelcomeEmail.message.info">
    For your security, invitations expire after 24 hours. Please request a new link to set your
    password.
  </Trans>
);
export const TitleBtnResend = (
  <Trans id="resendWelcomeEmail.action.submit.title">Resend Invitation</Trans>
);
