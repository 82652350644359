const EMAILS = {
  loginHelp: "Support@beneficient.com",
  portalHelp: "Originations@beneficient.com",
};

const COMPANY_NAME = {
  portalHelp: "Ben Originations professional",
};

const CUSTOM_HTTP_HEADERS = {
  shouldSaveSession: "BEN-Should-Save-Session",
  shouldNotRedirect: "BEN-Should-Not-Redirect",
};

export default {
  EMAILS,
  COMPANY_NAME,
  CUSTOM_HTTP_HEADERS,
};
