export class ArrayUtils {
  getFirstElement(arr: any[]) {
    if (!this.isValidArrayData(arr)) {
      return null;
    }

    return arr[0];
  }

  isValidArrayData(data: any): boolean {
    return !!(data && Array.isArray(data) && data.length);
  }

  hasTruthyArrayData(data: any): boolean {
    if (!this.isValidArrayData(data)) {
      return false;
    }

    const truthyData = data.filter((el: any) => Boolean(el) === true);
    return Boolean(truthyData.length);
  }

  hasAllTruthyArrayData(data: any): boolean {
    if (!this.isValidArrayData(data)) {
      return false;
    }

    const truthyData = data.filter((el: any) => Boolean(el) === true);
    return Boolean(truthyData.length === data.length);
  }

  splitChunksFromArray(perChunk: number, inputArray: any[]): any[][] {
    let result: any[][] = [];

    result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, result);

    return result;
  }
}

const arrayUtils = new ArrayUtils();
export default arrayUtils;
