import React from "react";
import { Tooltip } from "antd";
import { Trans } from "@lingui/react";

import { IconCustom } from "common/lib/components";

import { PropsBenDocumentStatusIcon } from "./types";

const UploadErrorBtnTooltip = (
  <Trans id="documents.fileUpload.fail">Upload has been failed. Please try again</Trans>
);
const DoNotHaveBtnTooltip = <Trans id="documents.donthave" />;

const BenDocumentStatusIcon: React.FC<PropsBenDocumentStatusIcon> = (
  props
): React.ReactElement | null => {
  const { doNotHaveThis, isUploadError, isLoading, statusIcon } = props;

  if (doNotHaveThis) {
    return (
      <Tooltip placement="right" title={DoNotHaveBtnTooltip}>
        <IconCustom type="declined" style={{ fontSize: 20 }} />
      </Tooltip>
    );
  }
  if (isUploadError) {
    return (
      <Tooltip placement="right" title={UploadErrorBtnTooltip}>
        <IconCustom type="declined" style={{ fontSize: 20 }} />
      </Tooltip>
    );
  }
  return <IconCustom type={isLoading ? "pending" : statusIcon} style={{ fontSize: 20 }} />;
};

export default BenDocumentStatusIcon;
