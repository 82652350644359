import { ActionTypes } from "../actions/locale";

export interface LocaleState {
  selectedLocale: string;
}

export const initialState = {
  selectedLocale: "",
};

export const locale = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.SelectLocale:
      return {
        ...state,
        selectedLocale: action.payload,
      };

    default:
      return state;
  }
};
