import React, { useState } from "react";
import { navigate, RouteComponentProps } from "@reach/router";

import { Urls } from "common/lib/constants";
import { Error500 } from "common/lib/components";
import { PublicPageWrapper } from "pages/components";
import { sessionService } from "common/shared";
import { useGetAppVersion } from "common/shared-hooks";

interface PropsPublicErrorPage extends RouteComponentProps {}

const PublicErrorPage = (props: PropsPublicErrorPage) => {
  const [token] = useState(sessionService.getTokens());

  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  // redirect to page "/error" in case if the user is authorized
  if (!!token) {
    navigate(Urls.PROTECTED.ERROR);
  }

  return (
    <PublicPageWrapper isPanelStyle={true}>
      <Error500 />
    </PublicPageWrapper>
  );
};

export default PublicErrorPage;
