import { pushAction } from "./actionsStream";

export const ActionTypes = {
  GetVersion: "[Shared] GetVersion",
  GetVersionSuccess: "[Shared] GetVersion Success",
  GetVersionFail: "[Shared] GetVersion Fail",
  ShowRefreshModal: "[Shared] Show Refresh Modal",
};

export const getVersion = () => {
  return {
    type: ActionTypes.GetVersion,
  };
};

export const getVersionSuccess = (data: string) => {
  const action = {
    type: ActionTypes.GetVersionSuccess,
    payload: data,
  };
  pushAction(action);

  return action;
};

export const getVersionFail = () => {
  return {
    type: ActionTypes.GetVersionFail,
  };
};

export const showRefreshModal = () => {
  return {
    type: ActionTypes.ShowRefreshModal,
  };
};
