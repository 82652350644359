import React from "react";
import { Trans } from "@lingui/react";

const PrivacyContent: React.FC = (): React.ReactElement => {
  return (
    <div className="terms-agreement-modal-body">
      <p className="agreement-textIndent">
        <Trans id="Privacy.Row">
          The Beneficient Company, L.P. (“Beneficient”) is firmly committed to protecting your
          privacy while visiting our Internet site. Beneficient is sensitive to privacy issues on
          the Internet and we believe it is important you know how we treat the information we
          receive about you.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="Privacy.Row.P1">
          Your use of the web sites located at www.trustben.com (collectively, the “Site”) indicates
          your acceptance of and agreement with the terms and conditions of Beneficient’s Privacy
          Policy.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        1.
        <span className="ben-important-text section-Margin">
          <Trans id="Privacy.Row.Heading1">Personal Information</Trans>
        </span>
        <Trans id="Privacy.Row1">
          {" "}
          In general, you can visit Beneficient on the World Wide Web without telling us who you are
          or revealing any information about yourself. Our web servers collect only the IP address,
          not the e-mail addresses or domain names, of visitors. This information may be compiled to
          measure the number of visits, average time spent on the site, pages viewed, etc.
          Beneficient may use this information to measure the use of our site and to improve its
          ease of use.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="Privacy.Row1.P2">
          There are times, however, when we may need information from you, such as your name,
          address, and company information (information that is essential for Beneficient to provide
          the services and/or products that are requested by you). Any personal information that we
          may collect from our Site will be used only by us to respond to your inquiry or process a
          request. The amount of personal information you disclose to Beneficient is completely at
          your discretion. It is never mandatory for you to answer any question, although the extent
          of your answers may limit the services and products that Beneficient can offer you. Please
          be advised that any message you send to us over the Internet via email will not be
          encrypted and consequently, could be intercepted by a third party. We do not guarantee the
          security of information transmitted electronically over the Internet, and thus we advise
          you to not communicate confidential information such as an account number, Social Security
          number, password, or other non-public information by e-mail. We are not liable for any
          loss, injury, or damage, whether direct or indirect, caused by the use of electronic
          communication
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        <Trans id="Privacy.Row1.P3">
          Beneficient does not plan to divulge, sell or lease any personal information, customer
          listings or aggregate data gathered through the Site to any third party. However,
          Beneficient may share the personal information with any party that it deems has a
          reasonable need to know the information, such as any employee or representative of
          Beneficient, anyone affiliated with or working on behalf of Beneficient, including without
          limitation any third-party service provider providing assistance with the development,
          implementation, operation, support and maintenance of the Site or provision of the
          services offered on the Site, our Federal and state regulators or auditors, to respond to
          a subpoena or court order, judicial process, or law enforcement agency, to protect against
          or prevent fraud and to manage risk, or any third party with whom you specifically
          authorize us to share your information.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        2.
        <span className="ben-important-text section-Margin">
          <Trans id="Privacy.Row.Heading2">Cookies</Trans>
        </span>
        <Trans id="Privacy.Row2">
          {" "}
          There are various technologies, including “cookies,” that can be used to give you tailored
          information from a web site. A cookie is an element of data that a web site can send to
          your browser, which may then store it on your system. Some pages within the Site may use
          cookies so that we can better serve you when you return to our Site. You can set your
          browser to notify you when you receive a cookie, giving you the chance to decide whether
          to accept it or not.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        3.
        <span className="ben-important-text section-Margin">
          <Trans id="Privacy.Row.Heading3">How to Contact us</Trans>
        </span>
        <Trans id="Privacy.Row3">
          {" "}
          If you have questions or concerns regarding our Privacy Policy please contact us via
          e-mail at privacypolicy@beneficient.com or by direct mail at 325 N. Saint Paul Street,
          Suite 4850, Dallas, Texas 75201.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        4.
        <span className="ben-important-text section-Margin">
          <Trans id="Privacy.Row.Heading4">Links to Other Web Sites</Trans>
        </span>
        <Trans id="Privacy.Row4">
          {" "}
          On our Site, we may have external links to other web sites. We are not responsible for the
          content on these web sites or the privacy policies and practices of the operators of these
          web sites. Therefore, we recommend that you review the terms of use and privacy policy for
          each third-party website.
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        5.
        <span className="ben-important-text section-Margin">
          <Trans id="Privacy.Row.Heading5">Our Commitment to Children’s Privacy</Trans>
        </span>
        <Trans id="Privacy.Row5">
          {" "}
          Our Website is not intended to be directed at children, and we have no intention of
          collecting any personal information from individuals under the age of thirteen (13).
        </Trans>
      </p>
      <br />
      <p className="agreement-textIndent">
        6.
        <span className="ben-important-text section-Margin">
          <Trans id="Privacy.Row.Heading6">Changes in Our Privacy Policy</Trans>
        </span>
        <Trans id="Privacy.Row6">
          {" "}
          We will periodically make changes to our privacy policy; therefore, we recommend that you
          visit this Site periodically.
        </Trans>
      </p>
    </div>
  );
};

export default PrivacyContent;
