import React, { useCallback, useEffect, useState } from "react";
import { Button, Modal } from "antd";
import { Trans } from "@lingui/macro";

import {
  CROSS_TAB_EVENTS,
  crossTabMessageService,
  localeService,
  sessionService,
} from "common/shared";
import { DomUtils } from "common/shared/utils";
import { actions$ } from "common/shared-store";
import { ActionTypes } from "common/shared-store/actions/version";
import { Layout } from "common/lib/constants";

const { VERSION_UPDATED, REFRESH } = CROSS_TAB_EVENTS;

const RefreshModal: React.FC = (): React.ReactElement => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  const onRefreshClick = useCallback(() => {
    crossTabMessageService.emitEvent(REFRESH, "", true);
  }, []);

  useEffect(() => {
    const subscriptions: any[] = [];

    subscriptions.push(
      actions$.ofType(ActionTypes.ShowRefreshModal).subscribe(() => {
        setModalOpen(true);
      })
    );

    subscriptions.push(
      actions$.ofType(ActionTypes.GetVersionSuccess).subscribe((action: any) => {
        const newVersion = action.payload;
        const currentVersion = sessionService.getVersion();
        if (currentVersion !== newVersion) {
          sessionService.setVersion(newVersion);
          if (currentVersion) {
            crossTabMessageService.emitEvent(CROSS_TAB_EVENTS.VERSION_UPDATED, "", true);
          }
        }
      })
    );

    return function cleanup() {
      subscriptions.forEach((subscription) => subscription.unsubscribe());
    };
  }, []);

  useEffect(() => {
    crossTabMessageService.addListener(VERSION_UPDATED, () => {
      setModalOpen(true);
    });
    crossTabMessageService.addListener(REFRESH, () => {
      DomUtils.reload();
    });

    return function cleanup() {
      crossTabMessageService.removeListeners(VERSION_UPDATED);
      crossTabMessageService.removeListeners(REFRESH);
    };
  }, []);

  return (
    <Modal
      title={localeService.i18n._("refresh.modal.title")}
      visible={isModalOpen}
      centered={true}
      width={"100%"}
      style={{
        maxWidth: Layout.MaxWidthModalWindow.standard,
      }}
      wrapClassName="ben-modal-container"
      footer={null}
      onCancel={onRefreshClick}
      closable={false}
    >
      <div className="ben-mt-4 ben-mb-4">
        <Trans id="refresh.modal.text">Please refresh your browser page to continue</Trans>
      </div>
      <Button
        id="benBtnRefresh"
        type="primary"
        className="ben-w-100"
        shape="round"
        size="large"
        ghost={true}
        onMouseDown={onRefreshClick}
      >
        <Trans id="refresh.modal.refresh.btn.title">Refresh</Trans>
      </Button>
    </Modal>
  );
};

export default RefreshModal;
