import React from "react";

import { ArrayUtils } from "common/shared/utils";

import { ClientCombinedItem } from "domains/clients";

import { PropsBenCombinedData } from "./types";
import "./ben-combined-data.scss";

const BenCombinedData: React.FC<PropsBenCombinedData> = (props): React.ReactElement | null => {
  if (!ArrayUtils.isValidArrayData(props.items)) {
    return null;
  }

  return (
    <span className="ben-combined-data">
      {props.items.map((item: ClientCombinedItem) => {
        if (!item.data) {
          return null;
        }
        return <span key={item.id}>{item.data}</span>;
      })}
    </span>
  );
};

export default BenCombinedData;
