import React from "react";
import { Row, Col } from "antd";
import { Trans } from "@lingui/react";

import { Logo } from "common/lib/components";

import logo from "common/assets/images/ben-logo-footer.svg";

import { PrivacyTermsInfo } from "domains/authentication/components";

import "./footer.scss";
import { PropsFooter } from "./types";

const Footer: React.FC<PropsFooter> = (props): React.ReactElement | null => {
  const { logoUrl } = props;
  const currentYear = new Date().getFullYear();

  return (
    <footer className="ben-footer-main">
      <div className="ben-container">
        <Logo to={logoUrl} src={logo} width={130} alt="Ben" />

        <Row>
          <Col xs={24} sm={12}>
            <p>
              <strong>
                © 2011-{currentYear}{" "}
                <Trans id="footer.copyright">The Beneficient Company Group, L.P.</Trans>
              </strong>
            </p>
          </Col>
          <Col xs={24} sm={12}>
            <p className="footer-privacy-terms-info">
              <PrivacyTermsInfo withUnderline={false} />
            </p>
          </Col>
        </Row>

        <Row className="ben-footer-cols" gutter={14}>
          <Col xs={24} sm={12}>
            <p>
              <Trans id="footer.col1.p1">
                Trust services will be provided by Beneficient Trust Company, LTA (“BTC”) and
                Beneficient Trust and Clearing Company, LTA (“BTCC”). BTC and BTCC are wholly-owned
                subsidiaries of The Beneficient Company Group, L.P. (“Ben”).
              </Trans>
            </p>

            <p>
              <Trans id="footer.col1.p2">
                Securities Products are offered through Emerson Equity LLC. Member FINRA SIPC. Ben
                and Emerson Equity LLC are separate, unaffiliated entities.
              </Trans>
            </p>

            <p>
              <Trans id="footer.col1.p3">
                Ben does not provide investment advisory services or tax, accounting or legal
                advice. The products and services described on this website may not be suitable for
                all users and you should consult with your investment advisor, and tax, accounting,
                legal, or other advisors prior to taking any action relating to the subject matter
                contained on this website. The contents hereof do not constitute an offer or
                solicitation to buy or sell any securities, financial instruments, products or
                services.
              </Trans>
            </p>

            <p>
              <Trans id="footer.col1.p4">
                Ben’s products and services are not insured by the FDIC or any other government
                agency, are not guaranteed by Ben, its subsidiaries or affiliates, may lose value,
                and are not considered a Bank Deposit under applicable law.
              </Trans>
            </p>
          </Col>
          <Col xs={24} sm={12}>
            <p>
              <strong>
                <Trans id="footer.col2.p1">
                  Important Information About Procedures for Establishing a Relationship with Ben
                </Trans>
              </strong>
            </p>

            <p>
              <Trans id="footer.col2.p2">
                To help the government fight the funding of terrorism and money laundering
                activities, federal law requires all financial institutions to obtain, verify and
                record information that identifies each person who establishes a relationship or
                carries out a transaction with a financial institution.
              </Trans>
            </p>

            <p>
              <Trans id="footer.col2.p3">
                What this means for you: When you establish a relationship or carry out a
                transaction with us, we will ask for your name, address, date of birth and other
                information that will allow us to identify you (or similar identifying information
                for entities). We may also ask to see or that you provide us with copies of your
                driver’s license or other identifying documents.
              </Trans>
            </p>

            <p>
              <Trans id="footer.col2.p4">
                If you are unable or unwilling to provide this information, we may not be able to
                establish a relationship or carry out a transaction with you. We thank you for your
                patience and hope that you will support the financial industry’s efforts to deny
                terrorists and money launderers access to America’s financial system.
              </Trans>
            </p>
          </Col>
        </Row>
      </div>
    </footer>
  );
};

export default Footer;
