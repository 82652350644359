import React from "react";
import { Button } from "antd";
import classnames from "classnames";

import { BackBtnProps } from "./types";

const BackBtn: React.FC<BackBtnProps> = (props): React.ReactElement => {
  const { children, className, type } = props;

  const backBtnHandle = () => {
    window.history.back();
  };

  const classes = classnames({
    [`${className}`]: className,
  });

  return (
    <Button id="benBackLinkButton" type={type} className={classes} onClick={backBtnHandle}>
      {children}
    </Button>
  );
};

export default BackBtn;
