import React, { useEffect, useState, useCallback } from "react";

import { getStickyOffset } from "./helpers";
import { PropsSticky } from "./types";
import "./sticky.scss";

// hide side menu when footer tries to overlap it
const Sticky: React.FC<PropsSticky> = (props) => {
  const { children, topOffset } = props;
  const [topOffsetState, setOffset] = useState(topOffset);

  const handleStickyScroll = useCallback(() => {
    const paddingBottom = 50;
    const currentScrollPos = window.pageYOffset;
    const footerHeight = document.getElementsByClassName("ben-footer-main")[0].clientHeight;
    const menuHeight = document.getElementsByClassName("ben-sidebar-menu")[0].clientHeight;
    const pageHeight = document.documentElement.scrollHeight;

    const offset = getStickyOffset({
      pageHeight,
      currentScrollPos,
      footerHeight,
      topOffset,
      menuHeight,
      paddingBottom,
    });

    setOffset(offset);
  }, [topOffset]);

  useEffect(() => {
    window.addEventListener("scroll", handleStickyScroll);

    // unmounting
    return () => {
      window.removeEventListener("scroll", handleStickyScroll);
    };
  }, [handleStickyScroll]);

  return (
    <div className="ben-sticky ben-d-sm-none ben-d-block" style={{ top: topOffsetState }}>
      {children}
    </div>
  );
};

export default Sticky;
