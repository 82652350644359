import React, { useState } from "react";
import { navigate } from "@reach/router";

import { Urls } from "common/lib/constants";
import { Error500 } from "common/lib/components";
import { sessionService } from "common/shared";
import { useGetAppVersion } from "common/shared-hooks";

const ErrorPage = () => {
  const [token] = useState(sessionService.getTokens());

  // Get version of the app if on the page there is no request to BE
  useGetAppVersion();

  // redirect to page "/public-error" in case if the user isn't authorized
  if (!token) {
    navigate(Urls.AUTH.ERROR);
  }

  return <Error500 />;
};

export default ErrorPage;
