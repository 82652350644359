import { IdentityResponseData } from "./types";

export function parseIdentityResponse(identity: IdentityResponseData) {
  return {
    permissions: identity.permissions,
    role: identity.role,
    userId: identity.user_id,
    accountId: identity.account_id,
    isAppian: identity.is_appian,
    lastLogin: identity.last_login_time,
  };
}

export function parsePermissions(permissions: any) {
  return permissions.reduce((acumulator: any, permission: any) => {
    return { ...acumulator, [permission.permission_name]: true };
  }, {});
}
