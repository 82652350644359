import { useEffect, useState } from "react";

import { Urls } from "common/lib/constants";
import { localeService } from "common/shared";
import { useSelectedLocaleData } from "common/shared-hooks";

const titles = {
  [`${Urls.PROTECTED.DASHBOARD}${Urls.PROTECTED.CLIENTS}`]: "html.title.dashboard",
  [Urls.PROTECTED.ADD_CLIENT]: "html.title.add.client",
  [Urls.PROTECTED.CLIENTS]: "html.title.clients",
  [Urls.PROTECTED.LIQUIDITY_REQUEST]: "html.title.liquidity.request",
  [Urls.PROTECTED.PROFILE]: "html.title.profile",
  [Urls.PROTECTED.HELP]: "html.title.help",
};

function useHtmlTitle(route: string) {
  const [title, setTitle] = useState("");
  const { lang } = useSelectedLocaleData();

  useEffect(() => {
    let result = "";
    switch (true) {
      case route.includes(Urls.PROTECTED.NEW_LIQUIDITY):
        result = "html.title.new.liquidity.request";
        break;
      case route.includes(`${Urls.PROTECTED.CLIENTS}/ACC`):
        result = "html.title.client";
        break;
      case route.includes(`${Urls.PROTECTED.LIQUIDITY_REQUEST}/LIQ`):
        result = "html.title.liquidity.request";
        break;
      case route.includes(Urls.PROTECTED.ADD_CLIENT):
        result = "html.title.add.client";
        break;
      case route.includes(Urls.PROTECTED.DASHBOARD):
        result = "html.title.dashboard";
        break;

      default:
        result = titles[route] || "html.title.profile";
    }

    setTitle(localeService.i18n._(result));
  }, [route, lang]);

  return title;
}

export default useHtmlTitle;
