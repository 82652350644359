import React from "react";

import { IconCustom } from "common/lib/components";

import { SessionModalContent, SessionModalTimerText, SessionModalTimerTime } from "./constants";
import { PropsSessionModalMessage } from "./types";
import "./session-modal-message.scss";

const SessionModalMessage: React.FC<PropsSessionModalMessage> = (
  props
): React.ReactElement | null => {
  const { countdown } = props;

  return (
    <div className="session-modal-message  ben-modal-content">
      <div className="message-wrapper">
        <div className="icon-wrapper ">
          <IconCustom type="attention" style={{ fontSize: 46 }} />
        </div>
        <div className="message-content">
          <p>{SessionModalContent}</p>
          <p>
            {SessionModalTimerText} <span className="ben-highlighted-text">{countdown}</span>{" "}
            {SessionModalTimerTime}.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SessionModalMessage;
