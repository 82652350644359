import { apiEnvs, apiService, sessionService } from "common/shared";

import { parseIdentityResponse, parsePermissions } from "./identity.parser";

export class IdentityService {
  getIdentity = async (username?: string) => {
    return apiEnvs.getApiEnv().then(async (envs: any) => {
      username = username || sessionService.getUserName();
      if (!username) {
        return {
          permissions: "",
          role: "",
          userId: "",
          accountId: "",
          isAppian: "",
          lastLogin: "",
        };
      }
      const { identityAPI } = envs;
      const data: any = await apiService.getResource(`${identityAPI}/v1/identity/${username}`);
      return parseIdentityResponse(data);
    });
  };

  getPermissions = async (role?: string) => {
    return apiEnvs.getApiEnv().then(async (envs: any) => {
      if (!role) {
        return Promise.resolve(undefined);
      }

      const { identityAPI } = envs;
      const permissions: any = await apiService.getResource(
        `${identityAPI}/v1/roles/${role}/permissions`
      );
      return parsePermissions(permissions);
    });
  };

  logout = async (email: string, customConfigs?: any) => {
    return apiEnvs.getApiEnv().then(async (envs: any) => {
      const { authAPI } = envs;
      return apiService.postResource(`${authAPI}/v1/logout`, { email }, customConfigs);
    });
  };

  createRegistrationRequest = async (data: any) => {
    return apiEnvs.getApiEnv().then(async (envs: any) => {
      const { identityAPI } = envs;
      return apiService.postResource(`${identityAPI}/v1/identity/register`, { ...data });
    });
  };
}

const identityService = new IdentityService();
export default identityService;
