import { useMemo } from "react";
import { useSelectedLocaleData } from "./index";

/* eslint-disable react-hooks/exhaustive-deps */
function useOnLocaleChange(render: () => JSX.Element[]) {
  const { lang } = useSelectedLocaleData();

  return useMemo(() => {
    return render();
  }, [lang, render]);
}

export default useOnLocaleChange;
