import React, { Fragment } from "react";

import { Form, Input } from "antd";

import { localeService } from "common/shared";
import { PhoneUtils } from "common/shared/utils";

import clientsService from "domains/clients/shared/clients.service";

import { PropsPrimaryContactDetails } from "./types";

const PrimaryContactDetails: React.FC<PropsPrimaryContactDetails> = (
  props
): React.ReactElement | null => {
  const { primaryContactUser } = props;

  if (!primaryContactUser) {
    return null;
  }

  const countryCodeName = clientsService.getCountryCodeNameFromUser(primaryContactUser);

  return (
    <Fragment>
      <Form.Item
        className="ben-form-label-field"
        label={localeService.i18n._("form.phone.countryCode")}
      >
        <Input name="countryCode" value={countryCodeName} disabled={true} />
      </Form.Item>

      <Form.Item
        className="ben-form-label-field"
        label={localeService.i18n._("form.phone.phoneNumber")}
      >
        <Input
          name="phoneNumber"
          value={PhoneUtils.maskPhone(primaryContactUser.phoneNumber)}
          disabled={true}
        />
      </Form.Item>

      <Form.Item
        className="ben-form-label-field"
        label={localeService.i18n._("form.personal.email")}
      >
        <Input name="email" value={primaryContactUser.email} disabled={true} />
      </Form.Item>
    </Fragment>
  );
};

export default PrimaryContactDetails;
