import { ofType } from "redux-observable";
import { map, mergeMap } from "rxjs/operators";
import { t } from "@lingui/macro";

import { messageService, localeService } from "common/shared";
import { TypeActionEpicParam } from "common/shared/interfaces/redux";

import { setTokenForPass } from "common/shared-store/actions/bootstrap";

import {
  ActionTypes,
  getLiquiditiesSuccess,
  getLiquiditiesFail,
  getLiquiditySuccess,
  geLiquidityFail,
  createLiquidityRequestFail,
  createLiquidityRequestSuccess,
  createLiquiditySharingSuccess,
  createLiquiditySharingFail,
  getLiquiditySharingsSuccess,
  geLiquiditySharingsFail,
  updateLiquiditySharingSuccess,
  updateLiquiditySharingFail,
  resendSharingInvitationSuccess,
  resendSharingInvitationFail,
  getClientLiquiditiesAlertsSuccess,
  geClientLiquiditiesAlertsFail,
  getSingleLiquidityAlertsSuccess,
  getSingleLiquidityAlertsFail,
  readSingleLiquidityAlertSuccess,
  readSingleLiquidityAlertFail,
  addHoldingsFail,
  addHoldingsSuccess,
} from "domains/liquidity/store/liquidity.actions";
import liquidityAPIService from "domains/liquidity/shared/liquidity.api.service";
import {
  ParamsGetLiquidity,
  LiquidityRequestResponseData,
  ParamsGetLiquidities,
  LiquidityRequestsListResponseData,
  ParamsCreateLiquiditySharing,
  CreateLiquiditySharingResponseData,
  ParamsCreateLiquidity,
  CreateLiquidityResponseData,
  ParamsGetLiquiditySharings,
  LiquiditySharingsResponseData,
  ParamsUpdateLiquiditySharing,
  UpdateLiquiditySharingResponseData,
  ParamsResendSharingInvitation,
  ResendSharingInvitationResponseData,
  LiquidityRequestAlertsResponseData,
  LiquidityRequestToAlertMap,
  ParamsCreateSharingAcceptance,
  CreateSharingAcceptanceResponseData,
  ParamsGetLiquidityRequestAlerts,
  ParamsReadSingleLiquidityAlert,
  ParamsAddHoldings,
  AddHoldingsResponseData,
} from "domains/liquidity/shared/types";
import liquidityService from "domains/liquidity/shared/liquidity.service";

export const getLiquiditiesEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.GetLiquidities),
    map((action: { type: string; payload?: ParamsGetLiquidities }) => action.payload),
    mergeMap(async (params?: ParamsGetLiquidities) => {
      try {
        const response: LiquidityRequestsListResponseData = await liquidityAPIService.getLiquidities(
          params
        );
        if (!response) {
          messageService.error(localeService.i18n._(t`liquidity.getLiquidities.fail`));
          return getLiquiditiesFail();
        }
        response.items = response.items ? [...response.items].reverse() : response.items;
        return getLiquiditiesSuccess(response);
      } catch (error) {
        return getLiquiditiesFail();
      }
    })
  );
};

export const getLiquidityEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.GetLiquidity),
    map((action: { type: string; payload?: ParamsGetLiquidity }) => action.payload),
    mergeMap(async (params?: ParamsGetLiquidity) => {
      try {
        const response: LiquidityRequestResponseData = await liquidityAPIService.getLiquidity(
          params
        );
        return getLiquiditySuccess(response);
      } catch (error) {
        return geLiquidityFail();
      }
    })
  );
};

export const createLiquidityRequestEpic = (action$: TypeActionEpicParam) =>
  action$.pipe(
    ofType(ActionTypes.CreateLiquidityRequest),
    map((action: { type: string; payload?: ParamsCreateLiquidity }) => action.payload),
    mergeMap(async (params?: ParamsCreateLiquidity) => {
      try {
        if (!window.navigator.onLine) {
          messageService.error(localeService.i18n._(t`liquidityRequest.new.fail`));
          return createLiquidityRequestFail();
        }
        const response: CreateLiquidityResponseData = await liquidityAPIService.createLiquidityRequest(
          params
        );
        if (!response) {
          messageService.error(localeService.i18n._(t`liquidityRequest.new.fail`));
          return createLiquidityRequestFail();
        }
        messageService.success(localeService.i18n._(t`liquidityRequest.new.success`));
        return createLiquidityRequestSuccess(response);
      } catch (error) {
        messageService.error(localeService.i18n._(t`liquidityRequest.new.fail`));
        return createLiquidityRequestFail();
      }
    })
  );

export const createLiquiditySharingEpic = (action$: TypeActionEpicParam) =>
  action$.pipe(
    ofType(ActionTypes.CreateLiquiditySharing),
    map((action: { type: string; payload?: ParamsCreateLiquiditySharing }) => action.payload),
    mergeMap(async (params?: ParamsCreateLiquiditySharing) => {
      try {
        const response: CreateLiquiditySharingResponseData = await liquidityAPIService.createLiquiditySharing(
          params
        );
        if (!response) {
          messageService.error(
            localeService.i18n._(t`liquidityRequest.sharing.new.fail.missedData`)
          );
          return createLiquiditySharingFail();
        }
        messageService.success(localeService.i18n._(t`liquidityRequest.sharing.new.success`));
        return createLiquiditySharingSuccess(response);
      } catch (error) {
        messageService.error(localeService.i18n._(t`liquidityRequest.sharing.new.fail`));
        return createLiquiditySharingFail();
      }
    })
  );

export const getLiquiditySharingsEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.GetLiquiditySharings),
    map((action: { type: string; payload?: ParamsGetLiquiditySharings }) => action.payload),
    mergeMap(async (params?: ParamsGetLiquiditySharings) => {
      try {
        const response: LiquiditySharingsResponseData = await liquidityAPIService.getLiquiditySharings(
          params
        );
        return getLiquiditySharingsSuccess(response);
      } catch (error) {
        return geLiquiditySharingsFail();
      }
    })
  );
};

export const updateLiquiditySharingEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.UpdateLiquiditySharing),
    map((action: { type: string; payload?: ParamsUpdateLiquiditySharing }) => action.payload),
    mergeMap(async (params?: ParamsUpdateLiquiditySharing) => {
      try {
        const response: UpdateLiquiditySharingResponseData = await liquidityAPIService.updateLiquiditySharing(
          params
        );
        if (!response || !response.ok) {
          messageService.error(localeService.i18n._(t`liquidityRequest.sharing.update.fail`));
          return updateLiquiditySharingFail();
        }
        return updateLiquiditySharingSuccess();
      } catch (error) {
        messageService.error(localeService.i18n._(t`liquidityRequest.sharing.update.fail`));
        return updateLiquiditySharingFail();
      }
    })
  );
};

export const resendSharingInvitationEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.ResendSharingInvitation),
    map((action: { type: string; payload?: ParamsResendSharingInvitation }) => action.payload),
    mergeMap(async (params?: ParamsResendSharingInvitation) => {
      try {
        const response: ResendSharingInvitationResponseData = await liquidityAPIService.resendSharingInvitation(
          params
        );
        if (!response || !response.ok) {
          messageService.error(
            localeService.i18n._(t`liquidityRequest.sharing.invitation.resend.fail`)
          );
          return resendSharingInvitationFail();
        }
        return resendSharingInvitationSuccess();
      } catch (error) {
        messageService.error(
          localeService.i18n._(t`liquidityRequest.sharing.invitation.resend.fail`)
        );
        return resendSharingInvitationFail();
      }
    })
  );
};

export const getClientLiquiditiesAlertsEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.GetClientLiquiditiesAlerts),
    map((action: { type: string; payload?: ParamsGetLiquidityRequestAlerts }) => action.payload),
    mergeMap(async (params?: ParamsGetLiquidityRequestAlerts) => {
      try {
        const response: LiquidityRequestAlertsResponseData = await liquidityAPIService.getAlerts(
          params
        );
        const parsedResponse: LiquidityRequestToAlertMap | null = liquidityService.getLiquidityRequestToAlertMap(
          response.items
        );
        return getClientLiquiditiesAlertsSuccess({ itemsMapped: parsedResponse });
      } catch (error) {
        return geClientLiquiditiesAlertsFail();
      }
    })
  );
};

export const getSingleLiquidityAlertsEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.GetSingleLiquidityAlerts),
    map((action: { type: string; payload?: ParamsGetLiquidityRequestAlerts }) => action.payload),
    mergeMap(async (params?: ParamsGetLiquidityRequestAlerts) => {
      try {
        const response: LiquidityRequestAlertsResponseData = await liquidityAPIService.getAlerts(
          params
        );
        return getSingleLiquidityAlertsSuccess(response);
      } catch (error) {
        return getSingleLiquidityAlertsFail();
      }
    })
  );
};

export const readSingleLiquidityAlertEpic = (action$: TypeActionEpicParam) => {
  return action$.pipe(
    ofType(ActionTypes.ReadSingleLiquidityAlert),
    map((action: { type: string; payload?: ParamsReadSingleLiquidityAlert }) => action.payload),
    mergeMap(async (params?: ParamsReadSingleLiquidityAlert) => {
      try {
        await liquidityAPIService.readSingleLiquidityAlert(params);
        return readSingleLiquidityAlertSuccess();
      } catch (error) {
        return readSingleLiquidityAlertFail();
      }
    })
  );
};

export const createSharingAcceptanceEpic = (action$: TypeActionEpicParam) =>
  action$.pipe(
    ofType(ActionTypes.CreateSharingAcceptance),
    map((action: { type: string; payload?: ParamsCreateSharingAcceptance }) => action.payload),
    mergeMap(async (params?: ParamsCreateSharingAcceptance) => {
      try {
        const response: CreateSharingAcceptanceResponseData = await liquidityAPIService.createSharingAcceptance(
          params
        );

        if (!response) {
          messageService.error(
            localeService.i18n._(t`liquidityRequest.sharing.accept.fail.missedData`)
          );
          return setTokenForPass({ token: null, success: false });
        }

        return setTokenForPass({ token: response.token, success: true });
      } catch (error) {
        if (error.response && error.response.status && error.response.status === 401) {
          // Can be tested on localhost via running a browser without CORS
          // the current link was previously used.
          return setTokenForPass({
            token: null,
            success: false,
            wasUsed: true,
          });
        }
        return setTokenForPass({
          token: null,
          success: false,
        });
      }
    })
  );

export const addHoldingsEpic = (action$: TypeActionEpicParam) =>
  action$.pipe(
    ofType(ActionTypes.AddHoldings),
    map((action: { type: string; payload?: ParamsAddHoldings }) => action.payload),
    mergeMap(async (params?: ParamsAddHoldings) => {
      try {
        const response: AddHoldingsResponseData = await liquidityAPIService.addHoldingsRequest(
          params
        );

        if (!response || !response.ok) {
          messageService.error(localeService.i18n._(t`liquidityRequest.addHoldings.fail`));
          return addHoldingsFail();
        }

        return addHoldingsSuccess();
      } catch (error) {
        messageService.error(localeService.i18n._(t`liquidityRequest.addHoldings.fail`));
        return addHoldingsFail();
      }
    })
  );
