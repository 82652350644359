import { BreadcrumbItem } from "common/shared/interfaces/breadcrumb";
import { ActionTypes } from "../actions/breadcrumb";

export interface BreadcrumbStore {
  items: BreadcrumbItem[];
}

export const initialState: BreadcrumbStore = {
  items: [],
};

export const breadcrumb = (state = initialState, action: any) => {
  switch (action.type) {
    case ActionTypes.ChangeItems:
      return {
        ...state,
        items: [...action.payload.items],
      };

    default:
      return state;
  }
};
