import React from "react";
import { Icon } from "antd";
import { Trans } from "@lingui/react";

import { PropsLiquidityBtnNew } from "./types";
import { Urls } from "common/lib/constants";
import { LinkCustom } from "common/lib/components";

const LiquidityBtnNew: React.FC<PropsLiquidityBtnNew> = ({ accountId }): React.ReactElement => {
  return (
    <LinkCustom
      id="benBtnNewLiquidityRequest"
      type="primary"
      shape="round"
      to={`${Urls.PROTECTED.NEW_LIQUIDITY}/${accountId}`}
    >
      <Trans id="liquidityRequest.btn.new">New Liquidity Request</Trans>{" "}
      <Icon type="plus" style={{ fontSize: 20 }} />
    </LinkCustom>
  );
};

export default LiquidityBtnNew;
