import { useSelector } from "react-redux";

import { AcceptRequestSharingData } from "domains/liquidity";

function useBootstrapSharingData(): AcceptRequestSharingData | null {
  const { acceptRequestSharingData } = useSelector((storeState: any) => {
    return storeState.bootstrap;
  });

  return acceptRequestSharingData;
}

export default useBootstrapSharingData;
