import { Constants } from "common/lib/constants";
import { localeService } from "common/shared";

import { PhoneUtils } from "common/shared/utils";

import Institution from "domains/clients/store/institution.model";
import User from "domains/clients/store/user.model";

import { TypeClientCombinedItem } from "./types";

interface TypeTimeZone {
  value: string;
  title: string;
}

export class ClientsService {
  getTimeZoneFromUser = (user: User): TypeTimeZone | undefined => {
    return Constants.TIME_ZONES.find((c) => c.value === user.timeZoneAbbreviation);
  };

  getTimeZoneTitle = (user: User): string | null => {
    const timeZone = this.getTimeZoneFromUser(user);
    if (!timeZone) {
      return null;
    }
    return localeService.i18n._(timeZone.title);
  };

  getPhoneTypeFromUser = (user: User): TypeTimeZone | undefined => {
    return Constants.PHONE_TYPES.find((c) => c.value === user.phoneType);
  };

  getPhoneTypeTitle = (user: User): string | null => {
    const phoneType = this.getPhoneTypeFromUser(user);
    return phoneType ? localeService.i18n._(phoneType.title) : "";
  };

  getCountryNameFromUser = (user: Institution | User): string | undefined => {
    if (!user) {
      return "";
    }
    const country = Constants.COUNTRIES.find((c) => c.code === user.country);

    return country ? localeService.i18n._(country.name) : user.country;
  };

  getCountryCodeFromUser = (user: User | null) => {
    if (!user) {
      return "";
    }

    return Constants.COUNTRIES_CODES.find((c) => c.code === user.countryCode);
  };

  getCountryCodeNameFromUser = (user: User | null): string | undefined => {
    if (!user) {
      return "";
    }

    const countryCode = this.getCountryCodeFromUser(user);

    if (countryCode) {
      return localeService.i18n._(countryCode.name);
    }

    if (user.countryCode) {
      return user.countryCode;
    }

    return localeService.i18n._(Constants.DEFAULT_COUNTRY_CODE.name);
  };

  getAddressCombinedData = (user: Institution | User): TypeClientCombinedItem[] => {
    const countryName = this.getCountryNameFromUser(user);
    const { street1, street2, city, region, postalCode } = user;
    const street1ToShow = `${street1 ? street1 : ""}`;
    const street2ToShow = `${street2 ? street2 : ""}`;
    const cityToShow = `${city ? `${city},` : ""}`;
    const regionToShow = `${region ? `${city ? " " : ""}${region}` : ""}`;
    const postalCodeToShow = `${postalCode ? `${region || city ? " " : ""}${postalCode}` : ""}`;

    const addressLine1 = `${street1ToShow}`;
    const addressLine2 = `${street2ToShow}`;
    const addressLine3 = `${cityToShow}${regionToShow}${postalCodeToShow}`;
    const addressLine4 = `${countryName ? countryName : ""}`;

    const addressCombinedData: TypeClientCombinedItem[] = [
      {
        id: "1",
        data: addressLine1,
      },
      {
        id: "2",
        data: addressLine2,
      },
      {
        id: "3",
        data: addressLine3,
      },
      {
        id: "4",
        data: addressLine4,
      },
    ];

    return addressCombinedData;
  };

  getNameCombinedData = (user: User): TypeClientCombinedItem[] => {
    const { lastName, suffix, firstName, middleName } = user;

    const suffixToShow = `${suffix ? suffix : ""}`;
    const firstNameToShow = `${firstName ? `${suffix ? " " : ""}${firstName}` : ""}`;
    const middleNameToShow = `${middleName ? `${firstName ? " " : ""}${middleName}` : ""}`;
    const lastNameToShow = `${lastName ? `${middleName || firstName ? " " : ""}${lastName}` : ""}`;

    const nameCombinedData: TypeClientCombinedItem[] = [
      {
        id: "1",
        data: `${suffixToShow}${firstNameToShow}${middleNameToShow}${lastNameToShow}`,
      },
    ];

    return nameCombinedData;
  };

  getPhoneCombinedData = (user: User): TypeClientCombinedItem[] => {
    const { phoneNumber } = user;

    const countryCodeName = this.getCountryCodeNameFromUser(user);
    const phoneNumberToShow = `${phoneNumber ? `${PhoneUtils.maskPhone(phoneNumber)}` : ""}`;

    const line1 = `${phoneNumberToShow}`;
    const line2 = `${countryCodeName ? countryCodeName : ""}`;

    const nameCombinedData: TypeClientCombinedItem[] = [
      {
        id: "1",
        data: `${line1}`,
      },
      {
        id: "2",
        data: `${line2}`,
      },
    ];

    return nameCombinedData;
  };
}

const clientsService = new ClientsService();
export default clientsService;
