import { validatorTypes } from "common/shared";
import FormInput from "common/shared/interfaces/form-input";

export class ResetPasswordFormSchema {
  public password = new FormInput();
  public confirmPassword = new FormInput();
}

export const resetPasswordValidationSchema = {
  password: [validatorTypes.required, validatorTypes.passPattern],
  confirmPassword: [validatorTypes.confirm("password"), validatorTypes.required],
};

export const resetPasswordValidationSchemaDeps = {
  password: ["confirmPassword"],
};
