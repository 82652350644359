import { useSelector } from "react-redux";
import Institution from "domains/clients/store/institution.model";
import User from "domains/clients/store/user.model";

interface ClientDataProps {
  client: User | Institution;
  loading: boolean;
  isCreating: boolean;
}

function useClientData(): ClientDataProps {
  const clientData = useSelector(
    (state: any): ClientDataProps => {
      const { client, loading, isCreating } = state.clients;
      return {
        client,
        loading,
        isCreating,
      };
    }
  );

  return { ...clientData };
}

export default useClientData;
