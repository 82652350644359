import apiEnvs from "common/shared/services/api.env.constants/api.env.constants";
import { TypeAvailableSourcesMap, TypeVerifyInvestorServiceInit, TypePingMessage } from "./types";

// Token for localhost "ySiw52fSRqSj4kr7yVI9jXjn8ubI13DzVW61ZlTQ"
/**
 * VerifyInvestorService is using the Investready API
 * https://developer.investready.com/
 * https://demo.investready.com/docs/InvestReady_iFrame_advanced.pdf
 */

export class VerifyInvestorService {
  private token: string = "";
  private source: string = "";
  private isActive: boolean = false;
  private isStopped: boolean = false;
  private readonly codeToExecuteSource: string = "window.IR = IR || {};";
  private readonly iFrameId: string = "InvestReadyiFrame";
  private readonly availableSources: TypeAvailableSourcesMap = {
    prod: "https://cdn.investready.com/IRiFrame.js",
    test: "https://s3.amazonaws.com/investready-testiframe/IRiFrame.js",
  };

  constructor() {
    this.setEnvData();
  }

  public isReady(): boolean {
    return Boolean(this.token);
  }

  public init(params: TypeVerifyInvestorServiceInit) {
    this.addScripts(params);
  }

  /**
   *  Revert IR functionality to send messages
   */
  public continueListen(): boolean {
    if (!this.isActive || !this.isStopped) {
      return false;
    }

    this.startPing();
    this.isStopped = false;
    return true;
  }

  /**
   * IR sends every 500 ms a ping method.
   * When the iFrame is destroyed, ping method continues working and creates errors in console.
   * That's why we need to stop it.
   */
  public stopListen(): boolean {
    if (!this.isActive || this.isStopped) {
      return false;
    }

    this.reset();
    this.isStopped = true;
    return true;
  }

  public reset(): boolean {
    if (!window.IR || !window.IR.haltListener) {
      return false;
    }

    window.IR.haltListener();
    return true;
  }

  public getIFrameId(): string {
    return this.iFrameId;
  }

  private async setEnvData() {
    const { verifyInvestorToken, isProductionEnv } = await apiEnvs.getApiEnv();
    this.setSource(isProductionEnv);
    this.setToken(verifyInvestorToken);
  }

  private setSource(isProductionEnv: boolean): void {
    if (isProductionEnv) {
      this.source = this.availableSources.prod;
      return;
    }
    this.source = this.availableSources.test;
  }

  private setToken(verifyInvestorToken: string): void {
    if (verifyInvestorToken) {
      this.token = verifyInvestorToken;
    }
  }

  private addScripts({ callBackSuccess, callBackError }: TypeVerifyInvestorServiceInit) {
    const sourceScript = document.createElement("script");
    sourceScript.setAttribute("src", this.source);
    document.body.appendChild(sourceScript);

    sourceScript.onload = () => {
      this.addCodeToExecuteSource();
      this.initAPI();
      callBackSuccess();
    };

    sourceScript.onerror = () => {
      callBackError();
    };
  }

  private initAPI(): boolean {
    if (!this.token) {
      return false;
    }

    if (!window.IR || !window.IR.init) {
      return false;
    }

    window.IR.init(this.token);
    this.isActive = true;
    return true;
  }

  private addCodeToExecuteSource(): boolean {
    if (!this.codeToExecuteSource) {
      return false;
    }
    const codeScript = document.createElement("script");
    codeScript.setAttribute("type", "text/javascript");
    codeScript.innerHTML = this.codeToExecuteSource;
    document.body.appendChild(codeScript);
    return true;
  }

  /**
   *  This function is a copy from IRiFrame.js - inner functionality from window.IR.init
   */
  private startPing(): void {
    window.IR.pinger = window.setInterval(() => {
      const message: TypePingMessage = { app_id: window.IR.app_id };
      // send the message and target URI
      window.IR.iFrame.contentWindow.postMessage(message, window.IR.domain);
    }, 500);
  }
}

export const verifyInvestorService = new VerifyInvestorService();
