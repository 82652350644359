import { useSelector } from "react-redux";
import { LiquidityStore, LiquiditySharingsDataProps } from "../shared/types";

function useLiquiditySharingsData(): LiquiditySharingsDataProps {
  const result = useSelector(
    (state: any): LiquiditySharingsDataProps => {
      const liquidityState: LiquidityStore = state.liquidity;
      const {
        singleSharings: { data, loading },
      } = liquidityState;

      if (!data || !data.requestId) {
        return {
          requestId: "",
          sharings: null,
          loading,
        };
      }

      return {
        requestId: data.requestId,
        sharings: data.sharings,
        loading,
      };
    }
  );

  return { ...result };
}

export default useLiquiditySharingsData;
