import React, { Fragment, useState } from "react";
import { Button, Modal } from "antd";
import { Trans } from "@lingui/macro";

import { localeService } from "common/shared";
import { ForgotPasswordForm } from "domains/authentication";
import { Layout } from "common/lib/constants";

const ForgotPasswordModal: React.FC = (): React.ReactElement => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);

  return (
    <Fragment>
      <Button
        id="benBtnForgotPassword"
        type="link"
        className="ben-btn-reset"
        style={{ marginLeft: 12 }}
        onMouseDown={() => setModalOpen(true)}
      >
        <Trans id="signin.username.forgot_link">Forgot Username or Forgot Password</Trans>
      </Button>
      <Modal
        title={localeService.i18n._("resetpass.form.title")}
        visible={isModalOpen}
        centered={true}
        width={"100%"}
        style={{
          maxWidth: Layout.MaxWidthModalWindow.standard,
        }}
        wrapClassName="ben-modal-container"
        footer={null}
        onCancel={() => setModalOpen(false)}
      >
        {/* To unmount Modal content onClose */}
        <div key={`key${!!isModalOpen}`}>
          <ForgotPasswordForm resetMode={true} onCancel={() => setModalOpen(false)} />
        </div>
      </Modal>
    </Fragment>
  );
};

export default ForgotPasswordModal;
