import { TypeStickyOffsetParam } from "./types";

export const getStickyOffset = (params: TypeStickyOffsetParam): number => {
  const {
    pageHeight,
    currentScrollPos,
    footerHeight,
    topOffset,
    menuHeight,
    paddingBottom,
  } = params;

  const diff = pageHeight - currentScrollPos;
  const freeSpace = footerHeight + topOffset + menuHeight + paddingBottom;

  let offset;
  if (diff < freeSpace) {
    offset = footerHeight - diff;
  } else {
    offset = topOffset;
  }

  return offset;
};
