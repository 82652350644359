import React from "react";
import { Modal } from "antd";

import { LiquidityProcessDescription } from "domains/liquidity/components";
import { Layout } from "common/lib/constants";

import { PropsProcessModal } from "./types";
import { ProcessModalDescriptions } from "./constants";

const ProcessModal: React.FC<PropsProcessModal> = (props): React.ReactElement | null => {
  const { handleModalClose, currentProcessId } = props;

  if (!handleModalClose || !currentProcessId || !ProcessModalDescriptions[currentProcessId]) {
    return null;
  }

  return (
    <div>
      <Modal
        title={ProcessModalDescriptions[currentProcessId].title}
        visible={Boolean(currentProcessId)}
        onCancel={handleModalClose}
        footer={null}
        centered={true}
        width={"100%"}
        style={{
          maxWidth: Layout.MaxWidthModalWindow.standard,
        }}
        wrapClassName="ben-modal-container"
      >
        <div className="ben-modal-content ben-border-bottom-0 ben-mb-0">
          <LiquidityProcessDescription text={ProcessModalDescriptions[currentProcessId].text} />
        </div>
      </Modal>
    </div>
  );
};

export default ProcessModal;
