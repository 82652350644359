import { Trans } from "@lingui/macro";
import React from "react";

export const Config = {
  maxUploadingFilesAmount: 1000,
  maxNestedFoldersAmount: 2,
  progress: {
    min: 0,
    create: 1,
    upload: 1000,
    confirm: 1001,
    max: 1001,
    error: 0,
  },
};

const CommonFileUploadMessage = <Trans id="documents.fileUpload.fail" />;

const FinishMessageAllSuccess = (
  <Trans id="documents.uploadDirectory.finish.message.allSuccess">
    Uploading successfully completed!
  </Trans>
);
const FinishMessageAllErrors = (
  <Trans id="documents.uploadDirectory.finish.message.allErrors">Uploading failed!</Trans>
);
const FinishMessageHasSomeErrors = (
  <Trans id="documents.uploadDirectory.finish.message.hasSomeErrors">
    Uploading partially completed!
  </Trans>
);
const FinishMessageAllCancelled = (
  <Trans id="documents.uploadDirectory.finish.message.allCancelled">Uploading was cancelled!</Trans>
);

const ListErrorsTitle = (
  <Trans id="documents.uploadDirectory.listErrors.title">The list has validation errors</Trans>
);

export const Messages = {
  CommonFileUploadMessage,
  FinishMessageAllSuccess,
  FinishMessageAllErrors,
  FinishMessageHasSomeErrors,
  FinishMessageAllCancelled,
  ListErrorsTitle,
};
