import { TypeResponseData, PatchRequestItemData } from "common/shared/interfaces/requests";
import { apiEnvs, apiService } from "common/shared";
import { ApiEnv } from "common/shared/interfaces/apiEnv";
import { BenInfo } from "common/lib/constants";
import { ArrayUtils } from "common/shared/utils";

import {
  ParamsGetLiquidities,
  ParamsGetLiquidity,
  LiquidityRequestsListResponseData,
  LiquidityRequestResponseData,
  ParamsCreateLiquiditySharing,
  CreateLiquiditySharingResponseData,
  ParamsCreateLiquidity,
  CreateLiquidityResponseData,
  RequestBodyCreateLiquidity,
  ParamsGetLiquiditySharings,
  LiquiditySharingsResponseData,
  ParamsUpdateLiquiditySharing,
  UpdateLiquiditySharingResponseData,
  ParamsResendSharingInvitation,
  ResendSharingInvitationResponseData,
  ParamsCreateSharingAcceptance,
  CreateSharingAcceptanceResponseData,
  LiquidityRequestAlertsResponseData,
  ParamsGetLiquidityRequestAlerts,
  ParamsRequestReadSingleLiquidityAlert,
  ReadSingleLiquidityAlertResponseData,
  ParamsAddHoldings,
  AddHoldingsResponseData,
  TypeCreateAssetData,
  TypeCreateAssetResponseData,
  RequestBodyCreateAsset,
  TypeCreateInvestmentData,
  TypeCreateInvestmentResponseData,
  RequestBodyCreateInvestment,
  TypeUpdateLiquidityInvestmentsData,
  RequestBodyUpdateLiquidityInvestments,
  TypeUpdateLiquidityInvestmentsResponseData,
} from "./types";
import {
  parseLiquidityRequestListResponseData,
  parseLiquidityRequestResponseData,
  parseCreateLiquiditySharingResponseData,
  parseCreateLiquidityResponseData,
  prepareLiquidityRequestArgs,
  prepareLiquidityRequestSharingArgs,
  parseLiquiditySharingsResponseData,
  parseCreateSharingAcceptanceResponseData,
  parseAlerts,
  prepareLiquiditySharingEditPatch,
  prepareAssetRequestArgs,
  parseAssetRequestResponseData,
  prepareInvestmentRequestArgs,
  parseInvestmentRequestResponseData,
  prepareLiquidityInvestmentsRequestArgs,
  parseLiquidityInvestmentsRequestResponseData,
} from "./liquidity.parser";

export class LiquidityAPIService {
  /**
   * @description - API call to get liquidities data from BE
   */
  getLiquidities = async (
    data?: ParamsGetLiquidities
  ): Promise<LiquidityRequestsListResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }

    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const { accountId, sharedWithUserId } = data;
    if (!accountId || !sharedWithUserId) {
      return this.getErrorResponse();
    }

    const filter = `&filter[0][field]=account_id&filter[0][operator]=equal&filter[0][value]=${accountId}`;
    const page = "&page[size]=100";

    const response: TypeResponseData = await apiService.getResource(
      `${holdingsAPI}/v1/liquidity_requests?shared_with=${sharedWithUserId}${page}${filter}`
    );
    const responseParsed: LiquidityRequestsListResponseData = parseLiquidityRequestListResponseData(
      response
    );
    return responseParsed;
  };

  /**
   * @description - API call to get single liquidity data from BE
   */

  getLiquidity = async (data?: ParamsGetLiquidity): Promise<LiquidityRequestResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const { requestId } = data;
    if (!requestId) {
      return this.getErrorResponse();
    }

    const response: TypeResponseData = await apiService.getResource(
      `${holdingsAPI}/v1/liquidity_requests/${requestId}`
    );

    const responseParsed: LiquidityRequestResponseData = parseLiquidityRequestResponseData(
      response
    );
    return responseParsed;
  };

  createLiquidityRequest = async (
    data?: ParamsCreateLiquidity
  ): Promise<CreateLiquidityResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }

    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const liquidityRequestArgs: RequestBodyCreateLiquidity = prepareLiquidityRequestArgs(data);

    const response: TypeResponseData = await apiService.postResource(
      `${holdingsAPI}/v2/liquidity_requests`,
      liquidityRequestArgs
    );

    const responseParsed: CreateLiquidityResponseData = parseCreateLiquidityResponseData(response);
    return responseParsed;
  };

  /**
   * @description - API call to create liquidity sharing
   */

  createLiquiditySharing = async (
    data?: ParamsCreateLiquiditySharing
  ): Promise<CreateLiquiditySharingResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const {
      requestId,
      sharedWithAccountId,
      sharedWithUserId,
      sharedWithUserName,
      sharedWithUserEmail,
      status,
    } = data;
    if (
      !requestId ||
      !sharedWithAccountId ||
      !sharedWithUserId ||
      !sharedWithUserName ||
      !sharedWithUserEmail ||
      !status
    ) {
      return this.getErrorResponse();
    }
    const requestBody = prepareLiquidityRequestSharingArgs(data);

    const response: TypeResponseData = await apiService.postResource(
      `${holdingsAPI}/v1/liquidity_requests/${requestId}/sharings`,
      requestBody
    );

    const responseParsed: CreateLiquiditySharingResponseData = parseCreateLiquiditySharingResponseData(
      response
    );
    return responseParsed;
  };

  /**
   * @description - API call to get sharings data of liquidity from BE
   */

  getLiquiditySharings = async (
    data?: ParamsGetLiquiditySharings
  ): Promise<LiquiditySharingsResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const { requestId } = data;
    if (!requestId) {
      return this.getErrorResponse();
    }

    const response: TypeResponseData = await apiService.getResource(
      `${holdingsAPI}/v1/liquidity_requests/${requestId}/sharings`
    );

    const responseParsed: LiquiditySharingsResponseData = parseLiquiditySharingsResponseData(
      response,
      requestId
    );
    return responseParsed;
  };

  /**
   * @description - API call to update liquidity request sharing
   */

  updateLiquiditySharing = async (
    data?: ParamsUpdateLiquiditySharing
  ): Promise<UpdateLiquiditySharingResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const { requestId, sharingId, editedData, originalData } = data;
    if (!requestId || !sharingId || !editedData || !originalData) {
      return this.getErrorResponse();
    }

    const patch: PatchRequestItemData[] | null = prepareLiquiditySharingEditPatch(
      editedData,
      originalData
    );
    if (!patch) {
      // TODO: No changes detected - add action here
      return { ok: false };
    }
    await apiService.patchResource(
      `${holdingsAPI}/v1/liquidity_requests/${requestId}/sharings/${sharingId}`,
      patch
    );
    return { ok: true };
  };

  /**
   * @description - API call to resend liquidity sharing invitation
   */

  resendSharingInvitation = async (
    data?: ParamsResendSharingInvitation
  ): Promise<ResendSharingInvitationResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const { requestId, sharingId } = data;
    if (!requestId || !sharingId) {
      return this.getErrorResponse();
    }

    await apiService.postResource(
      `${holdingsAPI}/v1/liquidity_requests/${requestId}/sharings/${sharingId}/send_invitation`,
      {}
    );
    return { ok: true };
  };

  /**
   * @description - API call to create liquidity Sharing/Invitation acceptance
   */

  createSharingAcceptance = async (
    data?: ParamsCreateSharingAcceptance
  ): Promise<CreateSharingAcceptanceResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const { requestId, sharingId, token } = data;
    if (!requestId || !sharingId || !token) {
      return this.getErrorResponse();
    }

    const customConfigs = {
      headers: {
        Authorization: `Bearer ${token}`,
        [BenInfo.CUSTOM_HTTP_HEADERS.shouldSaveSession]: "yes",
      },
    };

    const response: TypeResponseData = await apiService.postResource(
      `${holdingsAPI}/v1/liquidity_requests/${requestId}/sharings/${sharingId}/accept`,
      null,
      customConfigs
    );

    const responseParsed: CreateSharingAcceptanceResponseData = parseCreateSharingAcceptanceResponseData(
      response
    );
    return responseParsed;
  };

  getAlerts = async (
    data?: ParamsGetLiquidityRequestAlerts
  ): Promise<LiquidityRequestAlertsResponseData> => {
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;

    const accountIdParam = (data && data.accountId && `account_id=${data.accountId}`) || "";
    const requestIdParam =
      (data && data.requestId && `liquidity_request_id=${data.requestId}`) || "";
    const paramsStart = accountIdParam || requestIdParam ? "?" : "";

    const response: TypeResponseData = await apiService.getResource(
      `${holdingsAPI}/v1/liquidity_request_alerts${paramsStart}${accountIdParam}${requestIdParam}`
    );

    const responseParsed: LiquidityRequestAlertsResponseData = parseAlerts(response);

    return responseParsed;
  };

  /**
   * @description - API call to Mark specified Alert as Read.
   */
  readSingleLiquidityAlert = async (
    data?: ParamsRequestReadSingleLiquidityAlert
  ): Promise<ReadSingleLiquidityAlertResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }
    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const { alertId } = data;
    if (!alertId) {
      return this.getErrorResponse();
    }

    await apiService.postResource(
      `${holdingsAPI}/v1/liquidity_request_alerts/${alertId}/mark_as_read`,
      null
    );

    return { ok: true };
  };

  /**
   * @description - API call to create new assets, investments and add them to LR.
   */
  addHoldingsRequest = async (data?: ParamsAddHoldings): Promise<AddHoldingsResponseData> => {
    if (!data || !data.requestId) {
      return this.getErrorResponse();
    }

    const { requestId } = data;
    const investmentIds: string[] = await this.processCreateHoldings(data, 4);
    const updateLiquidityResult = await this.updateLiquidityInvestmentsRequest({
      investmentIds,
      requestId,
    });

    return { ok: updateLiquidityResult.ok };
  };

  processCreateHolding = (paramsData: ParamsAddHoldings, investmentIds: string[]) => async (
    asset: TypeCreateAssetData
  ) => {
    const assetResult = await this.createAssetRequest(asset, paramsData.accountId);
    const investmentResult = await this.createInvestmentRequest({
      ...paramsData,
      assetId: assetResult.id,
    });

    investmentIds.push(investmentResult.id);
  };

  processCreateHoldings = async (data: ParamsAddHoldings, perChunk: number): Promise<string[]> => {
    const investmentIds: string[] = [];
    const parts = ArrayUtils.splitChunksFromArray(perChunk, data.assets);
    for (const part of parts) {
      const promises = part.map(this.processCreateHolding(data, investmentIds));
      await Promise.all(promises);
    }
    return investmentIds;
  };

  /**
   * @description - API call to create new asset.
   */
  createAssetRequest = async (
    data: TypeCreateAssetData,
    accountId: string
  ): Promise<TypeCreateAssetResponseData> => {
    if (!data || !accountId) {
      return this.getErrorResponse();
    }

    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const assetRequestArgs: RequestBodyCreateAsset = prepareAssetRequestArgs(data, accountId);

    const response: TypeResponseData = await apiService.postResource(
      `${holdingsAPI}/v1/assets`,
      assetRequestArgs
    );

    const responseParsed: TypeCreateAssetResponseData = parseAssetRequestResponseData(response);

    return responseParsed;
  };

  /**
   * @description - API call to create new investment.
   */
  createInvestmentRequest = async (
    data?: TypeCreateInvestmentData
  ): Promise<TypeCreateInvestmentResponseData> => {
    if (!data) {
      return this.getErrorResponse();
    }

    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const investmentRequestArgs: RequestBodyCreateInvestment = prepareInvestmentRequestArgs(data);

    const response: TypeResponseData = await apiService.postResource(
      `${holdingsAPI}/v1/investments`,
      investmentRequestArgs
    );

    const responseParsed: TypeCreateInvestmentResponseData = parseInvestmentRequestResponseData(
      response
    );

    return responseParsed;
  };

  /**
   * @description - API call to update Liquidity Request investment property.
   */
  updateLiquidityInvestmentsRequest = async (
    data?: TypeUpdateLiquidityInvestmentsData
  ): Promise<TypeUpdateLiquidityInvestmentsResponseData> => {
    if (!data || !data.requestId) {
      return this.getErrorResponse();
    }

    const envs: ApiEnv = await apiEnvs.getApiEnv();
    const { holdingsAPI } = envs;
    const { requestId } = data;
    const liquidityInvestmentsRequestArgs: RequestBodyUpdateLiquidityInvestments = prepareLiquidityInvestmentsRequestArgs(
      data
    );

    await apiService.patchResource(
      `${holdingsAPI}/v1/liquidity_requests/${requestId}`,
      liquidityInvestmentsRequestArgs
    );

    const responseParsed: TypeUpdateLiquidityInvestmentsResponseData = parseLiquidityInvestmentsRequestResponseData();

    return responseParsed;
  };

  private getErrorResponse(): Promise<any> {
    return new Promise((resolve) => {
      resolve(undefined);
    });
  }
}

const liquidityAPIService = new LiquidityAPIService();
export default liquidityAPIService;
