export const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

export const MaxWidthModalWindow = {
  standard: "520px",
  large: "1020px",
};

export default {
  formItemLayout,
  tailFormItemLayout,
  MaxWidthModalWindow,
};
