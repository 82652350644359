import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, Redirect } from "@reach/router";

import { apiValidators } from "common/shared";
import { Urls, Constants } from "common/lib/constants";
import { AnchoredMenu, Sticky } from "common/lib/components";

import { rolesService, ROLE_TYPES } from "common/shared/services/roles/roles.service";
import { breadcrumbChangeItems } from "common/shared-store";
import { useSelectedLocaleData } from "common/shared-hooks";

import { getClient, useClientData, useProfileData, User } from "domains/clients";
import { TransactionSection } from "domains/document";
import {
  LiquidityRequestDetails,
  InvitesList,
  HoldingsSection,
  useLiquidityDetailsData,
} from "domains/liquidity";
import { LiquidityDataProps, TypeLiquidityStatusCode } from "domains/liquidity/shared/types";
import { getLiquidity } from "domains/liquidity/store/liquidity.actions";
import { liquidityService } from "domains/liquidity/shared";

import { DashboardHeader } from "pages/components";

import { AnchoredIdsInitial, MenuItemsInitial, TitleLiquidityRequestHoldings } from "./constants";
import { getLiquidityDetailsBreadcrumbItems } from "./helpers";

interface PropsLiquidityRequestPage extends RouteComponentProps {
  requestId?: string;
}

const LiquidityRequestPage: React.FC<PropsLiquidityRequestPage> = (props): React.ReactElement => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.requestId) {
      return;
    }
    dispatch(getLiquidity({ requestId: props.requestId }));
  }, [dispatch, props.requestId]);

  const { liquidity, loading: liquidityLoading }: LiquidityDataProps = useLiquidityDetailsData();
  const { client, loading: clientLoading } = useClientData();
  const { user, loading: userLoading, currentUserIp } = useProfileData();
  const { locale } = useSelectedLocaleData();

  const { requestId } = props;
  const sessionRole = rolesService.getRole();
  const isClient = sessionRole !== ROLE_TYPES.ADVISOR;
  const accountId = liquidity ? liquidity.accountId : null;
  const createdByName = liquidity ? liquidity.createdByName : null;
  const createdById = liquidity ? liquidity.createdBy : null;
  const accountName = (client && client.accountName) || null;
  const currentUserFullName = (user && user.fullName) || null;
  const clientFullName = client && client instanceof User ? client.fullName : null;
  const clientDisplayName = clientFullName || accountName;
  const currentUserId = (user && user.userId) || null;
  const userDisplayId = (user && user.userDisplayId) || null;
  const liquidityDisplayId = (liquidity && liquidity.displayId) || "";
  const isSensitive = liquidityService.isSensitiveDataLiquidityRequest(client, user);

  useEffect(() => {
    if (!accountId || isClient) {
      return;
    }
    dispatch(getClient({ accountId }));
  }, [dispatch, accountId, isClient]);

  // create breadcrumbs
  useEffect(() => {
    if (!accountId || !liquidityDisplayId || isClient) {
      dispatch(breadcrumbChangeItems([]));
      return;
    }

    const breadcrumbs = getLiquidityDetailsBreadcrumbItems({
      accountId,
      displayName: clientDisplayName || accountName,
      requestId: liquidityDisplayId,
    });
    dispatch(breadcrumbChangeItems(breadcrumbs));

    // Clean store
    return function cleanup() {
      dispatch(breadcrumbChangeItems([]));
    };
  }, [dispatch, accountId, accountName, liquidityDisplayId, isClient, clientDisplayName]);

  const liquidityStatus: TypeLiquidityStatusCode = liquidity ? liquidity.status : "in_proposal";

  if (!requestId || !apiValidators.isValidSystemId(apiValidators.ID_TYPES.liquidity, requestId)) {
    return <Redirect to={Urls.PROTECTED.ERROR_404} noThrow={true} />;
  }

  const possibleInvitedAccounts = client && [
    {
      displayName: client.getClientUserFullName() || accountName || "",
      id: client.getClientUserId(),
      accountId: accountId || "",
      email: client.getClientUserEmail(),
    },
  ];

  return (
    <div className="ben-sidebar-container">
      <Sticky topOffset={Constants.HEADER_HEIGHT}>
        <div className="ben-sidebar">
          <AnchoredMenu items={MenuItemsInitial} />
        </div>
      </Sticky>
      <div className="ben-content">
        <section className="ben-main-section">
          <DashboardHeader />

          <div id={AnchoredIdsInitial.overview}>
            <LiquidityRequestDetails
              liquidity={liquidity}
              liquidityLoading={liquidityLoading}
              requestId={requestId}
              sessionRole={sessionRole}
              clientDisplayName={clientFullName || accountName}
            />
          </div>

          <div id={AnchoredIdsInitial.holdings}>
            <HoldingsSection
              accountId={accountId}
              requestId={requestId}
              currentUserId={currentUserId || ""}
              userDisplayId={userDisplayId || ""}
              currentUserFullName={currentUserFullName || ""}
              currentUserIp={currentUserIp || ""}
              locale={locale}
              liquidity={liquidity}
              isClient={isClient}
              blockTitle={TitleLiquidityRequestHoldings}
              isLoading={liquidityLoading}
              isSensitiveData={isSensitive}
            />
          </div>

          <div id={AnchoredIdsInitial.transaction}>
            <TransactionSection
              accountId={accountId}
              requestId={requestId}
              liquidityStatus={liquidityStatus}
              liquidityLoading={liquidityLoading}
              liquidity={liquidity}
            />
          </div>

          <div id={AnchoredIdsInitial.invites}>
            <InvitesList
              accountId={accountId}
              requestId={requestId}
              liquidityLoading={liquidityLoading}
              possibleInvitedAccounts={possibleInvitedAccounts}
              possibleInvitedAccountsLoading={clientLoading}
              sessionRole={sessionRole}
              userFullName={currentUserFullName}
              userLoading={userLoading}
              requestAuthorName={createdByName}
              requestAuthorId={createdById}
            />
          </div>
        </section>
      </div>
    </div>
  );
};

export default LiquidityRequestPage;
