import React from "react";
import { Trans } from "@lingui/react";

import { Modal } from "antd";

import { TermsOfUseContent } from "domains/authentication/components";

import { PropsTermsOfUseModal } from "./types";

const termOfUse = <Trans id="TermsOfUse.Modal.title">Terms of Use </Trans>;

const TermsOfUseModal: React.FC<PropsTermsOfUseModal> = (props): React.ReactElement => {
  const { isTermsOfUseModalVisible, handleTermsOfUseModalVisibility } = props;

  return (
    <Modal
      centered={true}
      title={termOfUse}
      visible={isTermsOfUseModalVisible}
      width={"100%"}
      style={{
        maxWidth: "620px",
      }}
      footer={false}
      wrapClassName="ben-modal-container"
      onCancel={handleTermsOfUseModalVisibility(false)}
    >
      <TermsOfUseContent />
    </Modal>
  );
};
export default TermsOfUseModal;
