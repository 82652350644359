import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Card } from "antd";

import { Spinner } from "common/lib/components";
import { Constants } from "common/lib/constants";

import { getAccount } from "domains/clients/store/clients.actions";
import { useAccountData } from "domains/clients/hooks";
import { ProfileInstitutionSection } from "domains/clients/components";

interface PropsInstitutionSectionWrapper {
  accountId: string;
  shouldTurnOffEditMode: boolean;
  handleOnChangeMode: (isEditMode: boolean) => void;
  userType: string;
}

const InstitutionSectionWrapper: React.FC<PropsInstitutionSectionWrapper> = (
  props
): React.ReactElement | null => {
  const { accountId = "", shouldTurnOffEditMode, handleOnChangeMode, userType } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAccount(accountId));
  }, [dispatch, accountId]);

  const { account, accountLoading } = useAccountData();

  if (!userType) {
    return null;
  }

  if (userType !== Constants.USER_TYPES.ADVISOR && userType !== Constants.USER_TYPES.EMPLOYEE) {
    return null;
  }

  if (accountLoading) {
    return (
      <Card className="ben-margin-vertical-20">
        <Spinner />
      </Card>
    );
  }

  if (!account) {
    return null;
  }

  return (
    <ProfileInstitutionSection
      institution={account}
      shouldTurnOffEditMode={shouldTurnOffEditMode}
      handleOnChangeMode={handleOnChangeMode}
    />
  );
};

export default InstitutionSectionWrapper;
